import React from 'react';
import {Button, message} from "antd";
import {Modal} from 'ui'
import {FormattedMessage, useIntl} from "react-intl";
import StudentManual from "../../pages/System/StudentManual";


interface StudentManualModalInterface {
    open: boolean;
    onCancel: React.Dispatch<React.SetStateAction<boolean>>;
}

const StudentManualModal: React.FC<StudentManualModalInterface> = ({open, onCancel}) => {
    const intl = useIntl();

    return (
        <Modal
            open={open}
            maskClosable={false}
            width={'85%'}
            onCancel={() => onCancel(false)}
            title={intl.formatMessage({id: 'system.student_manual'})}
            destroyOnClose={true}
            footer={
                <Button onClick={() => onCancel(false)}>
                    <FormattedMessage id='general.close'/>
                </Button>
            }
        >
            <StudentManual />
        </Modal>
    )
};

export default StudentManualModal;
