import React, {useState, useEffect, useRef} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useNavigate, useParams} from 'react-router-dom';
import {Button, Transfer, Divider, Input as AntdInput, FormInstance, Tag, Alert, Popover} from 'antd';
import apiClient from "utils/apiClient";
import useHandleError from "utils/useHandleError";
import {RequestSelect, Title, Switch, Select} from "components/Form";
import {config} from 'config/config';
import {MenuOutlined, PlusOutlined} from "@ant-design/icons";

import {Table} from "ui";
import {connect} from "react-redux";
import '../styles.scss'
import helper from "../../../utils/generalHelpers";
import Input from "../../Form/Input";
import coursesApiClient from "../../../utils/coursesApiClient";
import {SortableContainer, SortableContainerProps, SortableElement, SortableHandle, SortEnd} from "react-sortable-hoc";
import arrayMove from "array-move";

interface RecordType {
    key: string;
    name: string;
}

interface CompetencesLearningProgramInterface {
    competence: any;
    locale?: string;
}

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale
    };
};

const SortableItem = SortableElement((props: React.HTMLAttributes<HTMLTableRowElement>) => (
    <tr {...props} />
));
const SortableBody = SortableContainer((props: React.HTMLAttributes<HTMLTableSectionElement>) => (
    <tbody {...props} />
));

const CompetencesLearningProgram: React.FC<CompetencesLearningProgramInterface> = ({
                                                                                       competence,
                                                                                       locale = config.api.defaultAppLanguage
                                                                                   }) => {
    const [selectedProgram, setSelectedProgram] = useState(0);
    const [reload, setReload] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [dataSource, setDataSource] = useState<any>([]);
    const [scenarioUpdate, setScenarioUpdate] = useState(false);
    const [displayWarn, setDisplayWarn] = useState(false);
    const [visibleTooltip, setVisibleTooltip] = useState<number | null>(null);
    const [anyProgram, setAnyProgram] = useState(false);
    const [sequenceSwitch, setSequenceSwitch] = useState(false)

    const intl = useIntl();
    const [handleError] = useHandleError();
    const navigate = useNavigate();
    const params = useParams();
    const competenceId = params.id;

    useEffect(() => {
        if (competence) {
            setAnyProgram(competence.completeAnyProgram)
            setSequenceSwitch(competence.forceSequence)

        }
    }, [competence])

    const handleVisibleChange = (visible: boolean, recordId: number) => {
        if (visible) {
            setVisibleTooltip(recordId);
        } else {
            setVisibleTooltip(null);
        }
    }

    const loadDelete = async (id: any) => {
        try {
            await coursesApiClient.request(`/api/v1/competences/options/${id}/program`, [], 'DELETE');
        } catch (error) {
            handleError(error);
        } finally {
            setReload(true);
        }
    }

    const PopoverContent = (row: any) => {
        return (
            <div className="custom-field-popover">
                <div className="popover-button" key='delete' onClick={() => {
                    loadDelete(row.data.learningCompetenceProgram);
                    setVisibleTooltip(null);
                }}><FormattedMessage id={'general.delete'}/></div>
            </div>
        )
    }

    const addLearningProgram = async (programId: number) => {
        if (programId === 0) {
            return;
        }

        const parsedValues = {
            programId: programId,
            competenceId: competenceId
        };

        try {
            setIsSubmitLoading(true)
            await apiClient.request('/api/v1/competences/options/program', parsedValues, 'POST');
            setReload(true)
        } catch (error) {
            handleError(error)
        } finally {
            setIsSubmitLoading(false)
        }
    }

    const updateProgramSettings = async (record: any, mandatory: boolean) => {
        setScenarioUpdate(true)

        let key: any = Object.entries(dataSource).filter((el: any) => el[1].learningCompetenceProgram === record.learningCompetenceProgram);

        try {
            dataSource[key[0][0]].mandatory = !record.mandatory
            setDataSource(dataSource);
            await apiClient.request(`/api/v1/competences/options/${record.learningCompetenceProgram}/program`, {mandatory}, 'PUT');

            hasMandatory({data: dataSource})
        } catch (error) {
            handleError(error)
        } finally {
            setScenarioUpdate(false)
        }
    }

    const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

    const columns = [
        {
            title: intl.formatMessage({ id: 'general.sort' }),
            width: 30,
            className: 'drag-visible',
            render: () => <DragHandle />,
        },
        {
            title: intl.formatMessage({id: 'general.title'}),
            render: (value: string, record: any) => {

                let title = '';
                if (record.title && record.title[locale && config.api.defaultAppLanguage]) {
                    title = record.title[locale && config.api.defaultAppLanguage];
                } else if (record.title) {
                    title = record.title[Object.keys(record.title)[0]];
                }

                return title
            }
        },
        {
            title: intl.formatMessage({id: 'general.activities'}),
            dataIndex: 'activitiesCount'
        },
        {
            title: intl.formatMessage({id: 'general.language'}),
            render: (_text: string, record: any) => {
                if (record.availableLanguages) {
                    const languageArray = record.availableLanguages.split(',')
                    return languageArray.filter(helper.onlyUnique).join(', ')
                } else {
                    return null;
                }
            }
        },
        {
            title: intl.formatMessage({id: 'general.mandatory'}),
            render: (_text: string, record: any) => {
                return (
                    <Switch
                        disabled={anyProgram}
                        checked={!!record.mandatory}
                        loading={isLoading}
                        onClick={() => updateProgramSettings(record, !record.mandatory)}
                    />
                )
            }
        },
        {
            title: intl.formatMessage({id: 'system.action'}),
            render: (title: string, record: any) => {
                return (
                    <Popover
                        open={record.learningCompetenceProgram === visibleTooltip}
                        onOpenChange={(visible) => handleVisibleChange(visible, record.learningCompetenceProgram)}
                        placement='bottom'
                        content={<PopoverContent data={record}/>}
                        arrowContent={false}
                        trigger='click'
                    >
                        <i className="fal fa-ellipsis-v" style={{fontSize: '16px'}}></i>
                    </Popover>
                )
            }
        }
    ];

    const hasMandatory = (response: any) => {
        let hasMandatory = false;
        Object.values(response.data).map((el: any) => {
            if (el.mandatory) {
                hasMandatory = true;
            }
        })

        setDisplayWarn(!hasMandatory);
    }

    const handleAnyProgramSwitch = async (value: any) => {
        setAnyProgram(value)

        const parsedValues = {
            title: competence.title,
            completeAnyProgram: value
        }

        try {
            await apiClient.request('/api/v1/competences/' + competence.id, parsedValues, 'PUT');
        } catch (error) {
            handleError(error)
        }
    }

    const handleSequenceSwitch = async (sequence: boolean) => {
        setIsLoading(true)
        setSequenceSwitch(sequence);

        const parsedValues = {
            forceSequence: sequence
        }

        try {
            await apiClient.request('/api/v1/competences/' + competence.id, parsedValues, 'PUT');
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    const DraggableContainer = (props: SortableContainerProps) => (
        <SortableBody
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    );

    const DraggableBodyRow: React.FC<any> = ({ className, style, ...restProps }) => {
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = dataSource.findIndex((x: any) => x.learningCompetenceProgram === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };

    const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMove(dataSource, oldIndex, newIndex)
            const sortIds = newData.map((item: any, key: number) => {
                return item.learningCompetenceProgram
            })
            setDataSource(newData);
            updateOrder(sortIds)
            setScenarioUpdate(true)
        }
    };

    const updateOrder = async (orderIds: any) => {
        setIsLoading(true)
        try {
            await coursesApiClient.request(`/api/v1/competences/options/${competence.id}/order`, { orderIds }, 'POST');
            setScenarioUpdate(false)
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className='competences-settings-wrapper'>
            <Title font='14px'>
                <FormattedMessage id='competences.settings'/>
            </Title>
            <div className={'flex justify-between gap-10'}>
                <div className='search-user-wrapper'>
                    <Select
                        allowClear
                        showSearch
                        isFormItem={false}
                        dataKey='programs'
                        url={'/api/v1/courses/learning-program/options/list'}
                        integerKey={true}
                        customLayout={true}
                        customObjLabel={(el: any) => el[locale] ? el[locale] : el[Object.keys(el)[0]]}
                        dropdownStyle={{minWidth: "385px"}}
                        onChange={(programId: number) => setSelectedProgram(programId)}
                        filterOption={(input: string, option: any) => {
                            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                        }}
                    />
                    <Button loading={isSubmitLoading} type="primary"
                            onClick={() => addLearningProgram(selectedProgram)}>
                        <PlusOutlined/> <FormattedMessage id="general.add"/>
                    </Button>
                </div>

                <div className={'flex gap-10 flex-column align-end'}>
                    <div className={'flex gap-10'}>
                        <FormattedMessage id="competences.any_program"/>
                        <Switch
                            checked={anyProgram}
                            loading={isLoading}
                            onClick={(value: boolean) => handleAnyProgramSwitch(value)}
                        />
                    </div>
                    <div className={'flex gap-10 strict-order'}>
                        <FormattedMessage id="program.sequence_parameter"/>
                        <Switch
                            loading={isLoading}
                            checked={sequenceSwitch}
                            onClick={(value: boolean) => handleSequenceSwitch(value)}
                        />
                    </div>
                </div>

            </div>

            {anyProgram ? false : displayWarn ?
              <Alert className={'mt-4'} message={intl.formatMessage({id: "competences.one_required"})} type="warning"/>
                : null
            }
            <Table
                columns={columns}
                url={`/api/v1/competences/options/${competenceId}/program`}
                setReload={setReload}
                reload={reload}
                rowSelection={false}
                rowKey={'learningCompetenceProgram'}
                manualDataUpdate={scenarioUpdate}
                updateData={dataSource}
                saveData={setDataSource}
                onLoadFunction={hasMandatory}
                components={{
                    body: {
                        wrapper: DraggableContainer,
                        row: DraggableBodyRow,
                    },
                }}
                scroll={{x: 800}}
            />
        </div>
    )
};

export default connect(mapStateToProps)(CompetencesLearningProgram);