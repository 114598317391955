import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useParams} from 'react-router-dom';
import {Button, Table} from 'ui';
import {usePhishing} from "hooks/Phishing/usePhishing";
import useHandleError from "utils/useHandleError";
import {RequestSelect} from "components/Form";
import FlexRow from "components/FlexRow";
import coursesApiClient from "utils/coursesApiClient";
import apiClient from "utils/apiClient";
import {FilterModal} from "components/Modals";
import generalHelpers from "utils/generalHelpers";
import {connect} from "react-redux";
import DefaultLayout from "components/DefaultLayout";

const mapStateToProps = (state: any) => {
    return {
        session: state.session
    }
};
const PhishingCampaignStudents: React.FC<any> = ({session, launchButton, campaign}) => {
    const {unEnroll} = usePhishing();
    const [reload, setReload] = useState(false);
    const [organizationsLoaded, setOrganizationsLoaded] = useState('')
    const [showUsersFilterModal, setShowUsersFilterModal] = useState(false);
    const [loading, setIsLoading] = useState(false);
    const [selectedUser, setSelectedUser] = useState('')
    const [buttonLoad, setButtonLoad] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<[]>([]);

    const [handleError] = useHandleError();
    const params = useParams();
    const campaignId = params.id;
    const intl = useIntl();

    const columns = [
        {
            title: intl.formatMessage({id: 'general.name'}),
            dataIndex: 'name',
        },
        {
            title: intl.formatMessage({id: 'general.surname'}),
            dataIndex: 'surname',
        },
        {
            title: intl.formatMessage({id: 'general.email'}),
            dataIndex: 'email',
        },
        {
            title: intl.formatMessage({id: 'general.organizational_unit'}),
            dataIndex: 'organizationalUnit',
        },
        {
            title: intl.formatMessage({id: 'general.department'}),
            dataIndex: 'departmentTitle',
        },
    ];

    useEffect(() => {
        managingOrganizations()
    }, [])

    const managingOrganizations = async () => {
        setIsLoading(true)
        if (session.active_user_type === 'CUSTOMER_ADMIN' || session.active_user_type === 'SUPERVISION') {
            setOrganizationsLoaded(session.organization.organization_id);
            setIsLoading(false)
            return;
        }

        try {
            const organizations = await apiClient.request('/api/v1/organizations/options/managing-organizations', [], 'GET');
            setOrganizationsLoaded(Object.keys(organizations.customers).toString())
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    const addUSerToProgram = async () => {
        try {
            await coursesApiClient.request(`/api/v1/courses/phishing/campaign/${campaignId}/enrollments`, {
                user_type_id: selectedUser,
                phishing_ids: [campaignId]
            }, 'POST');
            setReload(true)
        } catch (error) {
            handleError(error)
        }
    }

    const addUsers = async (usersToEnroll: string) => {
        setButtonLoad(true)
        try {
            await coursesApiClient.request(`/api/v1/enroll-users/${campaignId}/PHISHING?${usersToEnroll}`, [], 'POST');
            setReload(true);
        } catch (error) {
            handleError(error)
        } finally {
            setButtonLoad(false)
        }
    }

    const handleUnEnroll = async () => {
        await unEnroll(campaignId, selectedRowKeys)
        setReload(true)
        setSelectedRowKeys([])
    }

    return (
        <>
            <FlexRow
                left={
                    <>
                        {!campaign?.programId &&
                          <>
                            <Button loading={buttonLoad} type="primary" onClick={() => setShowUsersFilterModal(true)}>
                              <FormattedMessage id="campaign.enroll"/>
                            </Button>
                              {organizationsLoaded &&
                                <>
                                  <RequestSelect
                                    isForm={false}
                                    customLayout={true}
                                    selectClassName='request-select'
                                    url={`api/v1/edu/users/search-student`}
                                    param={[`customerId=${organizationsLoaded}&key=id&accountStatus=ACTIVE`]}
                                    onChange={(typeUuid: string) => setSelectedUser(typeUuid)}
                                    column
                                    showSearch
                                  />
                                  <Button type="primary" onClick={() => addUSerToProgram()}>
                                    <FormattedMessage id="general.add"/>
                                  </Button>
                                </>
                              }
                          </>
                        }

                    </>
                }
            />
            <FilterModal
                okProp={'campaign.enroll'}
                visible={showUsersFilterModal}
                onCancel={setShowUsersFilterModal}
                modules={{
                    customerSelect: {
                        mode: 'default',
                        visible: generalHelpers.isAdmin(session.active_user_type),
                    },
                    organizationalUnitId: {visible: true},
                    newUsers: {default: false, visible: true},
                    customerFilter: {mode: 'multiple', visible: true}
                }}
                submit={addUsers}
            />
            <Table
                columns={columns}
                url={`/api/v1/courses/phishing/campaign/${campaignId}/enrollments`}
                setReload={setReload}
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
                size={'small'}
                showTotalResults={true}
                reload={reload}
                rowKey={'enrollmentId'}
            />
            <DefaultLayout.PageFooter
                left={
                    <>
                        <Button onClick={() => handleUnEnroll()}>
                            <FormattedMessage id={'general.un_enroll'}/>
                        </Button>
                    </>
                }
                right={
                    <>
                        {launchButton}
                    </>
                }
            />
        </>
    )
}

export default connect(mapStateToProps)(PhishingCampaignStudents);
