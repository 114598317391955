import React, {useState, useEffect} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {Button, Form, message, InputNumber, Popover} from 'antd';
import {MinusOutlined, PlusOutlined, CloseOutlined} from '@ant-design/icons';
import Switch from 'components/Form/Switch';
import {Search, Table, Modal} from 'ui'
import { Input, Select } from 'components/Form';
import {config} from "config/config";
import apiClient from "utils/apiClient";
import useHandleError from "utils/useHandleError";
import Spinner from "components/Spinner";
import debounce from "lodash/debounce";

const UsersCustomFilters: React.FC<any> = ({}) => {
    const [searchString, setSearchString] = useState<string>('');
    const [reload, setReload] = useState<boolean>(false);
    const [showFieldModal, setShowFieldModal] = useState(false);
    const intl = useIntl();
    const [showEditTitleModal, setShowEditTitleModal] = useState(false);
    const [form] = Form.useForm();
    const [handleError] = useHandleError();
    const [visibleTooltip, setVisibleTooltip] = useState<number | null>(null);
    const [editField, setEditField] = useState<any>({});
    const [editTitle, setEditTitle] = useState<any>({});
    const [multipleChoice, setMultipleChoice] = useState<boolean>(false);
    const [multipleChoiceInputFields, setMultipleChoiceInputFields] = useState([
        {
            value: '',
        }
    ])
    const [sequenceLoading, setSequenceLoading] = useState(false);

    const addFields = () => {
        let newfield = {
            value: '',
        }
        setMultipleChoiceInputFields([...multipleChoiceInputFields, newfield])
    }

    const removeFields = (index:any) => {
        let data = [...multipleChoiceInputFields];
        data.splice(index, 1)
        setMultipleChoiceInputFields(data)
        let fieldValues: any = {}

        Object.values(data).map((el:any, index: number) => {
            const array = Object.entries(el)[0]

            fieldValues[index] = array[1]
        })

        form.setFieldsValue({
            multiple_choice_options: fieldValues
        })
    }

    const loadUpdate = async (data:any) => {
        setSequenceLoading(true)
        if   (showEditTitleModal) {
            setShowEditTitleModal(false);
        }
          try {
            const response = await apiClient.request('/api/v1/users/custom-fields/update', data, 'PUT');
                setReload(true);
        } catch (error) {
            handleError(error);
        } finally {
            setSequenceLoading(false)
            setEditField({})
            setEditTitle({})
        }
    }

    const loadDelete = async (data:any) => {
        try {
            const response = await apiClient.request(`/api/v1/users/custom-fields/${data.id}/delete`, [], 'DELETE');
        } catch (error) {
            handleError(error);
        } finally {
            setReload(true);
        }
    }

    const change = debounce((value: any) => {
        loadUpdate(value)
    }, 800);

    const submitForm = async (values: any) => {
        const parsedValues: any = {
            title: values.title,
            field_type: values.fieldType,
            status: values.status ? 1 : 0,
            multiple_choice_options: null,
        };

        if (values.multiple_choice_options) {
            parsedValues.multiple_choice_options = values.multiple_choice_options;
        }

        try {
            if (!!values?.id) {
                parsedValues.id = values.id
                await apiClient.request('/api/v1/users/custom-fields/update', parsedValues, 'PUT');
            } else {
                await apiClient.request('/api/v1/users/custom-fields', parsedValues, 'POST');
            }
        } catch (err) {
            message.error(intl.formatMessage({id: "error.data_load"}));
        } finally {
            setShowFieldModal(false);
            setMultipleChoice(false)
            setMultipleChoiceInputFields([
                {
                    value: '',
                }
            ])
            form.resetFields();
            setReload(true);
        }
    };

    useEffect(() => {
        form.setFieldsValue({
            fieldType: 'TEXT',
            status: 1,
        });
    },[]);

    useEffect(() => {
        form.setFieldsValue({
            id: editTitle.id,
            title: editTitle.title,
        });
    },[editTitle]);

    const PopoverContent = (row: any) => {
        return (
            <div className="custom-field-popover">
                <div className="popover-button pointer" key='edit' onClick={() => handleEdit(row)}>
                    <FormattedMessage id={'general.edit'}/>
                </div>
                <div className="popover-button pointer" key='delete' onClick={() => loadDelete({id: row.data.id})}>
                    <FormattedMessage id={'general.delete'}/>
                </div>
            </div>
        )
    }

    const columns = [
        {
            title: intl.formatMessage({id: 'system.field_name'}),
            dataIndex: 'title',
        },
        {
            title: intl.formatMessage({id: 'system.field_type'}),
            dataIndex: 'fieldType',
            render: (value: string) => {
                if (value !== "MULTIPLE_CHOICE") {
                    return (
                        intl.formatMessage({id: `system.custom_field_type_${value.toLowerCase()}`})
                    )
                }
                return (
                    intl.formatMessage({id: 'system.custom_field_type_multiple'})
                )
            }
        },
        {
            title: intl.formatMessage({id: 'general.status'}),
            dataIndex: 'status',
            render: (value: boolean, data:any) => {
                return (
                    <Switch
                        name='status'
                        defaultChecked={value}
                        onClick={(e) => {
                            setEditField((editField:any) => ({ ...editField, id: data.id, status: e ? 1 : 0}))
                        }}
                    />
                )
            }
        },
        {
            title: intl.formatMessage({id: 'system.field_display'}),
            dataIndex: 'display',
            render: (value: boolean, data:any) => {
                return (
                    <Switch
                        name='display'
                        defaultChecked={value}
                        onClick={(e) => {
                            setEditField((editField:any) => ({ ...editField, id: data.id, display: e ? 1 : 0}))
                        }}
                    />
                )
            }
        },
        {
            title: intl.formatMessage({id: 'system.display_order'}),
            dataIndex: 'sequence',
            render: (value: number, data: any) => {
                return (
                    <Spinner spinning={sequenceLoading}>
                        <InputNumber
                            addonBefore={
                                <MinusOutlined onClick={() => data.sequence > 2 ?  loadUpdate({id: data.id, sequence: data.sequence -= 1}) : null}/>
                            }
                            addonAfter={
                                <PlusOutlined
                                    onClick={() => loadUpdate({id: data.id, sequence: data.sequence += 1})}/>
                            }
                            min={1}
                            value={data.sequence}
                            onChange={(e: any) => change({id: data.id, sequence: e}) }
                            onBlur={(e: any) => e.preventDefault()}
                            controls={false}
                        />
                    </Spinner>
                )
            }
        },
        {
            title: intl.formatMessage({id: 'system.action'}),
            dataIndex: 'actions',
            render: (text: string, data: any) => {
                return (
                    <Popover
                        open={data.id === visibleTooltip}
                        onOpenChange={(visible) => handleVisibleChange(visible, data.id)}
                        placement='bottom'
                        content={<PopoverContent data={data} />}
                        arrowContent={false}
                        trigger='click'
                    >
                        <i className="fa-solid fa-ellipsis"></i>
                    </Popover>
                )
            }
        },

    ];

    const handleVisibleChange = (visible: boolean, recordId: number) => {
        if (visible) {
            setVisibleTooltip(recordId);
        } else {
            setVisibleTooltip(null);
        }
    }

    const handleEdit = (record: any) => {
        setVisibleTooltip(null);

        form.setFieldsValue(
            {
                ...record.data,
                status: !!record.data.status
            }
        );

        if (record.data.fieldType === 'MULTIPLE_CHOICE') {
            setMultipleChoice(true)

            let multipleInputFields: any = []

            let fieldValues: any = {}

            Object.entries(record.data.multipleChoiceOptions).map((el: any) => {
                multipleInputFields.push({[el[0]]: el[1]})
                fieldValues[el[0]] = el[1]
            })

            setMultipleChoiceInputFields(multipleInputFields)

            form.setFieldsValue({
                multiple_choice_options: fieldValues
            })

        } else {
            setMultipleChoice(false);
        }
        setShowFieldModal(true)
    }

    const handleModalClose = () => {
        setMultipleChoiceInputFields([
            {
                value: '',
            }
        ])

        form.resetFields()
        setMultipleChoice(false);
        setShowFieldModal(false)
    }

    return (
        <div className='custom-field-table'>
            <div className="search-bar-row">
                <Button type={'primary'} onClick={() => setShowFieldModal(true)}>
                    <FormattedMessage id={'system.add_new_custom_field'} />
                </Button>
                <div className="search">
                    <Search setSearchString={setSearchString} setReload={setReload} />
                </div>
            </div>
            <Table
                className="custom-field-table"
                columns={columns}
                url={'/api/v1/users/custom-fields'}
                setReload={setReload}
                reload={reload}
                rowKey={'id'}
                rowSelection={false}
                search={searchString}
                scroll={{ x: true }}
            />
            <Modal
                className="add-custom-field-modal"
                open={showFieldModal}
                title={intl.formatMessage({id: 'system.add_custom_field'})}
                onOk={() => form.submit()}
                destroyOnClose={true}
                onCancel={handleModalClose}
                okText={intl.formatMessage({id: 'general.submit'})}
                cancelText={intl.formatMessage({id: 'general.cancel'})}
            >
                <Form form={form} onFinish={submitForm}>
                    <div className={'hidden'}>
                        <Input name='id'/>
                    </div>
                    <Input
                        name='title'
                        label={intl.formatMessage({id: "system.field_name"})}
                        customRules={[{required: true, message: intl.formatMessage({id: "validation.field_required"})}]}
                    />
                    <Select
                        name='fieldType'
                        label={intl.formatMessage({id: "system.field_type"})}
                        url={config.api.routes.enums.customFieldTypes}
                        customObjLabel={(el: string) => intl.formatMessage({id: `system.custom_field_type_${el.toLowerCase()}`})}
                        onChange={(el: any) => {
                            el === 'MULTIPLE_CHOICE' ?
                                setMultipleChoice(true)
                                :
                                setMultipleChoice(false);
                        }}
                    />
                    {
                        multipleChoice ?
                            <div className={'multiple-choice'}>
                                {multipleChoiceInputFields.map((input, index) => {
                                    return (
                                        <Input
                                            key={index}
                                            name={['multiple_choice_options', index]}
                                            suffix={<CloseOutlined onClick={() => {
                                                removeFields(index)
                                            }}/>}/>
                                    )
                                })
                                }
                                <Button type="default" onClick={addFields}>+ <FormattedMessage
                                    id={'general.add'}/></Button>
                            </div>
                            : null
                    }
                    <Switch
                        name='status'
                        label={intl.formatMessage({id: 'general.status'})}
                        defaultChecked={true}
                        isFormItem
                    />
                </Form>
            </Modal>
        </div>
    )
}

export default UsersCustomFilters;