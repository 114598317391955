import React, {useEffect, useState} from 'react';
import {useOrganizations} from "hooks/Organizations/useOrganizations";
import FilterCheckBox from "../FilterCheckBox";
import {FormattedMessage} from "react-intl";
import FilterPopover from "../FilterPopover";
import {Button} from "ui";

interface OrganizationsFilterInterface {
    tagBuilder: Function;
    setCustomers: React.Dispatch<React.SetStateAction<string[]>>;
    setFilterValues: any;
    filterValues: any
    form: any;
    filterKey: string;
    setFilterKey: React.Dispatch<React.SetStateAction<string>>;
}

const OrganizationsFilter: React.FC<OrganizationsFilterInterface> = ({
                                                                         filterKey,
                                                                         setFilterKey,
                                                                         form,
                                                                         setFilterValues,
                                                                         filterValues,
                                                                         tagBuilder,
                                                                         setCustomers
                                                                     }) => {
    const {searchOrganizations, loading} = useOrganizations()
    const [data, setData] = useState([]);
    const [initialValues, setInitialValues] = useState(undefined)

    const key = 'organizations'

    useEffect(() => {
        onSearch()
    },[])

    const onSearch = async (value: string = '') => {
        const response = await searchOrganizations(value);
        setData(response);

        setFilterValues(
            {
                ...filterValues,
                customersUuid: {
                    ...filterValues.customersUuid,
                    ...response
                }
            }
        )
    };

    const content = (
        <>
            <FilterCheckBox setData={setCustomers} data={data} name={'customersUuid'} onSearch={onSearch}/>
            <div className={'flex justify-between p-1'}>
                <Button type={'link'} onClick={() => handleClose()}>
                    <FormattedMessage id={'general.cancel'}/>
                </Button>
                <Button type={'primary'} onClick={() => {
                    setFilterKey('')
                    handleOpen(false)
                }}>
                    <FormattedMessage id={'general.select'}/>
                </Button>
            </div>
        </>
    );

    const handleClose = () => {
        form.setFieldsValue(initialValues);
        setFilterKey('');
    }

    const handleOpen = (open: boolean) => {
        if (open) {
            setInitialValues(form.getFieldsValue())
            setFilterValues(
                {
                    ...filterValues,
                    customersUuid: {
                        ...filterValues.customersUuid,
                        ...data
                    }
                }
            )
        } else {
            tagBuilder()
        }
    }

    return (
        <FilterPopover
            popoverKey={key}
            filterKey={filterKey}
            setOpen={setFilterKey}
            handleOpen={handleOpen}
            content={content}
            loading={loading}
            title={'general.search_by_organization'}
            buttonTitle={'general.organization'}
        />
    );
};

export default OrganizationsFilter;