import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Form, Input, FormInstance, message} from 'antd';
import {Switch} from 'components/Form';
import coursesApiClient from 'utils/coursesApiClient';
import Spinner from 'components/Spinner';
import TemplateEditor from 'components/TemplateEditor';

const formItemLayout = {
    labelCol: {span: 4},
    wrapperCol: {span: 20},
};

type CertificateFormType = {
    type: 'CERTIFICATE',
    language: string,
    settings: {
        title: string;
        description: string;
        fileUpload: boolean;
        certificateValidation: boolean
    }
}

interface CertificateFormInterface {
    language: string;
    form: FormInstance;
    activityId: number;
    viewMaterial?: any;
    onCancel: any;
    reload: Function;
}

const CertificateForm: React.FC<CertificateFormInterface> = ({
                                                                 language,
                                                                 form,
                                                                 activityId,
                                                                 viewMaterial,
                                                                 onCancel,
                                                                 reload
                                                             }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [description, setDescription] = useState<string>('');
    const [editor, setEditor] = useState<any>();

    const intl = useIntl();

    useEffect(() => {
        if (viewMaterial) {

            const materialSettings = JSON.parse(viewMaterial.settings);

            if (viewMaterial.settings) {
                form.setFieldsValue({
                    title: materialSettings.title,
                    passRate: !!materialSettings.certificate_validation,
                    fileUpload: !!materialSettings.file_upload
                });
                setDescription(materialSettings.description ? materialSettings.description : '')
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewMaterial]);

    const submitForm = async (values: any) => {
        setIsLoading(true);
        try {
            const parsedValues: CertificateFormType = {
                type: 'CERTIFICATE',
                language: language,
                settings: {
                    title: values.title,
                    description: description,
                    fileUpload: !!values.fileUpload,
                    certificateValidation: !!values.certificateValidation
                }
            }
            if (viewMaterial) {
                await coursesApiClient.request(`/api/v1/courses/material/update/${viewMaterial.id}`, parsedValues, 'PUT');
            } else {
                await coursesApiClient.request(`/api/v1/courses/material/${activityId}/add-material`, parsedValues, 'POST');
            }

            onCancel();
            reload(activityId);
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({id: 'error.data_load'}));
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Form form={form} onFinish={submitForm}>
            <Spinner spinning={isLoading}>
                <Form.Item {...formItemLayout} name='title' label={intl.formatMessage({id: 'general.title'})}>
                    <Input/>
                </Form.Item>
                <TemplateEditor
                    height={300}
                    layout={formItemLayout}
                    label={intl.formatMessage({id: 'general.description'})}
                    html={description}
                    setHtml={(value: any) => setDescription(value ?? '')}
                    setEditor={setEditor}
                />
                <Switch isFormItem
                        name="fileUpload"
                        label={intl.formatMessage({id: 'general.file_upload'})}
                        valuePropName="checked"
                />
                <Switch isFormItem
                        name="certificateValidation"
                        label={intl.formatMessage({id: 'general.certificate_validation'})}
                        valuePropName="checked"
                />
            </Spinner>
        </Form>
    )
}
export default CertificateForm;
