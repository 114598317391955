import * as ActionTypes from 'store/actionTypes';
import apiClient from 'utils/apiClient';

export function loadLanguageDictionary() {
  return async (dispatch: any) => {
    dispatch({ type: ActionTypes.LANGUAGE_DICTIONARY_GET_IN_PROGRESS, payload: {} });
    
    try {
      let translations

      if (!sessionStorage.getItem('translations')) {
        const response = await apiClient.request('/api/v1/language-dictionary');

        sessionStorage.setItem('translations', JSON.stringify(response.translations));
        translations = response.translations;
      } else {
        let translationData: string | null = sessionStorage.getItem('translations')
        translations = translationData && JSON.parse(translationData);
      }
      dispatch({ type: ActionTypes.LANGUAGE_DICTIONARY_GET_SUCCESS, payload: translations });
    } catch(error) {
      dispatch({ type: ActionTypes.LANGUAGE_DICTIONARY_GET_FAILED, payload: {} });
    }
  }
}
