import {useState} from 'react'
import useHandleError from 'utils/useHandleError';
import apiClient from 'utils/apiClient';
import coursesApiClient from "utils/coursesApiClient";
import {useIntl} from "react-intl";
import generalHelpers from "../../utils/generalHelpers";


export const usePhishing = () => {
    const [handleError] = useHandleError();
    const [loading, setLoading] = useState<boolean>(false)
    const intl = useIntl();

    const getTemplate = async (id: string) => {
        setLoading(true)
        try {
            const response = await coursesApiClient.request(`/api/v1/courses/phishing/templates/${id}`, [], 'GET');

            if ("error" in response) {
                throw response;
            }

            if ("data" in response) {
                return response.data;
            }

            return null;
        } catch (error) {
            handleError(error);
            return null;
        } finally {
            setLoading(false)
        }
    }

    const saveTemplate = async (templateData: any, id: string | null = null): Promise<any | null> => {
        setLoading(true);
        try {
            const response = id ?
                await apiClient.request(`/api/v1/courses/phishing/templates/${id}`, templateData, 'PUT')
                : await apiClient.request(`/api/v1/courses/phishing/templates`, templateData, 'POST');

            if ("error" in response) {
                throw response;
            }

            if ("data" in response) {
                return response.data;
            }

            return null;
        } catch (error) {
            handleError(error);
            return null;
        } finally {
            setLoading(false);
        }
    }

    const getSimulations = async (id: string) => {
        setLoading(true)
        try {
            const response = await coursesApiClient.request(`/api/v1/courses/phishing/templates/${id}/simulations`, [], 'GET');

            if ("error" in response) {
                throw response;
            }

            if ("data" in response) {
                return response.data;
            }

            return null;
        } catch (error) {
            handleError(error);
            return null;
        } finally {
            setLoading(false)
        }
    }

    const saveSimulation = async (simulationData: any, id: string) => {
        setLoading(true)

        try {
            const response = simulationData?.id ?
                await apiClient.request(`/api/v1/courses/phishing/templates/${id}/simulations/${simulationData.id}`, simulationData, 'PUT')
                :
                await apiClient.request(`/api/v1/courses/phishing/templates/${id}/simulations`, simulationData, 'POST');

            if ("error" in response) {
                throw response;
            }

            if ("data" in response) {
                return response.data;
            }

            return null;
        } catch (error) {
            handleError(error);
            return null;
        } finally {
            setLoading(false)
        }
    }

    const getCampaign = async (id: string): Promise<any | null> => {
        setLoading(true)
        try {
            const response = await coursesApiClient.request(`/api/v1/courses/phishing/campaign/${id}`, [], 'GET');

            if ("error" in response) {
                throw response;
            }

            if ("data" in response) {
                return response.data;
            }

            return null;
        } catch (error) {
            handleError(error);
            return null;
        } finally {
            setLoading(false)
        }
    }

    const saveCampaign = async (campaignData: any, id: string | number |null = null): Promise<any | null> => {
        setLoading(true);
        try {
            const response = id ?
                await apiClient.request(`/api/v1/courses/phishing/campaign/${id}`, campaignData, 'PUT')
                : await apiClient.request(`/api/v1/courses/phishing/campaign`, campaignData, 'POST');

            if ("error" in response) {
                throw response;
            }

            if ("data" in response) {
                return response.data;
            }

            return null;
        } catch (error) {
            handleError(error);
            return null;
        } finally {
            setLoading(false);
        }
    }

    const saveCampaignSimulations = async (simulationsData: any, id: string | null = null): Promise<any | null> => {
        setLoading(true);
        try {

            const response = await apiClient.request(`/api/v1/courses/phishing/campaign/${id}/simulations`, simulationsData, 'POST');

            if ("error" in response) {
                throw response;
            }

            if ("data" in response) {
                return response.data;
            }

            return null;
        } catch (error) {
            handleError(error);
            return null;
        } finally {
            setLoading(false);
        }
    }

    const deleteSimulation = async (simulationId: number, id: string | null = null): Promise<any | null> => {
        setLoading(true);
        try {
            const response = await apiClient.request(`/api/v1/courses/phishing/campaign/${id}/simulations/${simulationId}`, [], 'DELETE');

            if ("error" in response) {
                throw response;
            }

            if ("data" in response) {
                return response.data;
            }

            return null;
        } catch (error) {
            handleError(error);
            return null;
        } finally {
            setLoading(false);
        }
    }

    const saveSimulationSchedule = async (id: string | null = null, data: any) => {
        setLoading(true);

        const simulationId = data.id;
        try {
            const response = await apiClient.request(`/api/v1/courses/phishing/campaign/${id}/simulations/update/${simulationId}`, data, 'PUT');

            if ("error" in response) {
                throw response;
            }

            if ("data" in response) {
                return response.data;
            }

            return null;
        } catch (error) {
            handleError(error);
            return null;
        } finally {
            setLoading(false);
        }
    }

    const launchPhishing = async (id: string | null = null) => {
        setLoading(true);

        try {
            const response = await apiClient.request(`/api/v1/courses/phishing/campaign/${id}/launch`, [], 'POST');

            if ("error" in response) {
                throw response;
            }

            if ("data" in response) {
                return response.data;
            }

            return null;
        } catch (error) {
            handleError(error);
            return null;
        } finally {
            setLoading(false);
        }
    }

    const getSummary = async (id: string | null = null) => {
        setLoading(true);

        try {
            const response = await apiClient.request(`/api/v1/courses/phishing/campaign/${id}/results/summary`, [], 'GET');

            const data = {
                labels: [
                    intl.formatMessage({id: 'phishing.success'}),
                    intl.formatMessage({id: 'phishing.failed'}),
                ],
                datasets: [{
                    data: [response.data.sent - response.data.clicked, response.data.clicked],
                    backgroundColor: [
                        'RGB(33, 208, 160)',
                        'RGB(244, 98, 100)'
                    ],
                    hoverBackgroundColor: [
                        'RGB(13, 188, 140)',
                        'RGB(224 ,68, 80)',
                    ]
                }]
            };

            if ("error" in response) {
                throw response;
            }

            if ("data" in response) {
                return data;
            }

            return [];
        } catch (error) {
            handleError(error);
            return [];
        } finally {
            setLoading(false);
        }
    }

    const getResultsByOrgUnit = async (id: string | null = null) => {
        setLoading(true);

        try {

            const response = await apiClient.request(`/api/v1/courses/phishing/campaign/${id}/results/group-results`, [], 'GET');

            const data = {
                labels: generalHelpers.pluck(response.data, 'title').map((title: string) => {

                    if (title) {
                        return title;
                    } else {
                        return intl.formatMessage({id: 'general.without_org_unit'});
                    }
                }),
                datasets: [
                    {
                        label: intl.formatMessage({id: 'phishing.email_sent'}),
                        backgroundColor: '#63CDA3',
                        hoverBackgroundColor: '#63CDA3',
                        data: generalHelpers.pluck(response.data, 'sent')
                    },
                    {
                        label: intl.formatMessage({id: 'phishing.email_opened'}),
                        backgroundColor: '#85A1DA',
                        hoverBackgroundColor: '#85A1DA',
                        data: generalHelpers.pluck(response.data, 'opened')
                    },
                    {
                        label: intl.formatMessage({id: 'phishing.link_opened'}),
                        backgroundColor: '#F2D943',
                        hoverBackgroundColor: '#F2D943',
                        data: generalHelpers.pluck(response.data, 'clicked')
                    },
                    {
                        label: intl.formatMessage({id: 'phishing.data_submitted'}),
                        backgroundColor: '#E36B69',
                        hoverBackgroundColor: '#E36B69',
                        data: generalHelpers.pluck(response.data, 'submitted_data')
                    },
                    {
                        label: intl.formatMessage({id: 'phishing.email_reported'}),
                        backgroundColor: '#94f7cf',
                        hoverBackgroundColor: '#94f7cf',
                        data: generalHelpers.pluck(response.data, 'email_reported')
                    }
                ]
            };

            if ("error" in response) {
                throw response;
            }

            if ("data" in response) {
                return data;
            }

            return [];
        } catch (error) {
            handleError(error);
            return null;
        } finally {
            setLoading(false);
        }
    }

    const unEnroll = async (id: string | null = null, enrollmentIds: number[]) => {
        setLoading(true);

        try {
            const response = await apiClient.request(`/api/v1/courses/phishing/campaign/${id}/enrollments/un-enroll`, {enrollmentIds: enrollmentIds}, 'POST');

            if ("error" in response) {
                throw response;
            }

            if ("data" in response) {
                return response.data;
            }

            return null;
        } catch (error) {
            handleError(error);
            return null;
        } finally {
            setLoading(false);
        }
    }

    return {
        loading,
        getTemplate,
        saveTemplate,
        getSimulations,
        saveSimulation,
        getCampaign,
        saveCampaign,
        saveCampaignSimulations,
        deleteSimulation,
        saveSimulationSchedule,
        launchPhishing,
        getSummary,
        getResultsByOrgUnit,
        unEnroll
    }
}