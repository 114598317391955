import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, message, Table, Input as AntDInput, Modal, Popover, Button, Space, Tag, Tooltip } from 'antd';
import Switch from 'components/Form/Switch';
import {ExclamationCircleOutlined, PlusOutlined} from '@ant-design/icons';
import moment from 'moment';
import DefaultLayout from 'components/DefaultLayout';
import { Input, Select } from 'components/Form';
import CoursesFilterModal from './CoursesFilterModal';
import apiClient from 'utils/apiClient';
import coursesApiClient from 'utils/coursesApiClient';
import './styles.scss';
import useHandleError from "../../../utils/useHandleError";

const mapStateToProps = (state: any) => {
  return {
    session: state.session,
    locale: state.locale.locale
  };
}

const formItemLayout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 },
};

const ManageContent: React.FC = ({ locale, session }: any) => {
  const [showAddNewCourse, setShowAddNewCourse] = useState(false);
  const [isCoursesLoading, setIsCoursesLoading] = useState(true);
  const [coursesList, setCoursesList] = useState([]);
  const [coursesListBeforeSearch, setCoursesListBeforeSearch] = useState([]);
  const [visibleTooltip, setVisibleTooltip] = useState<number|null>(null);
  const [isSwitchLoading, setIsSwitchLoading] = useState(false);
  const [selectLanguageList, setSelectLanguageList ] = useState({});
  const [showCoursesModal, setShowCoursesModal] = useState<boolean>(false);
  const [customerUrl, setCustomerUrl] = useState('');

  const [form] = Form.useForm();
  const intl = useIntl();
  const { Search } = AntDInput;
  const includeColumn = ['name'];
  const navigate = useNavigate();
  const [handleError] = useHandleError();
  const { confirm } = Modal;

  useEffect(() => {


      const loadLanguages = async () => {

        let languages = await apiClient.request(`/api/v1/language`, {}, 'GET');
        let languageListForSelect:any = {};
        Object.values(languages.languages).map((value:any) => {
          if (value['publish']) {
            languageListForSelect[value['code']] = value['name']
          }
          return languageListForSelect;
        })
        setSelectLanguageList(languageListForSelect);
      }

      if (session.active_user_type !== 'SUPER_ADMIN') {
        loadCourses();
      } else {
        setIsCoursesLoading(false);
      }

      loadLanguages();

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locale]);

    const loadCourses = async (type: 'default' | 'custom' = 'default') => {
      try {
        setIsCoursesLoading(true);

        let url = `/api/v1/courses/filter/${session.organization.organization_uuid}/${session.organization.organization}/${type}`

        if (customerUrl) {
          url = customerUrl + '/' + type
        }

        let courseList = await coursesApiClient.request(url, {}, 'GET');
        setCoursesList(courseList.courses);
        setCoursesListBeforeSearch(courseList.courses);

      } catch (err) {
        console.error(err);
        message.error(intl.formatMessage({id: "error.data_load"}));
      } finally {
        setIsCoursesLoading(false);
      }
    }

  const deleteCourse = async (id: number) => {
    try {
      const response = await coursesApiClient.request(`/api/v1/courses/courses/${id}/delete`, {}, 'DELETE');

      if (response.message) {
        message.success(intl.formatMessage({id: 'courses.course_deleted'}));
      } else {
        message.warning(intl.formatMessage({id: 'courses.course_in_use'}));
      }

    } catch (error) {
      handleError(error);
    } finally {
      loadCourses();
    }
  }

  const warnDeletion = async (id: number) => {
    confirm({
      title: intl.formatMessage({ id: 'general.attention' }),
      content: intl.formatMessage({ id: 'courses.delete_course' }),
      icon: <ExclamationCircleOutlined />,
      okText: intl.formatMessage({ id: 'general.yes' }),
      cancelText: intl.formatMessage({ id: 'general.no' }),

      onOk() {
        deleteCourse(id);
      }
    });
  }


    const columns = [
      {
        title: intl.formatMessage({id: 'courses.course_title'}),
        dataIndex: 'name',
        key: 'name',
        render: (text: string, record: any) => {
          return (
              <NavLink
                to={
                    `/courses/${record.id}/edit`
                }
              >
                {!!record.programId &&
                  <Tag>
                    <FormattedMessage id='program.tag' />
                  </Tag>
                }
                {record.nameTranslations ?
                  (record.nameTranslations[locale]
                    ? record.nameTranslations[locale]
                    : (record.nameTranslations['EN']
                      ? record.nameTranslations['EN']
                      : record.name
                    )
                  )
                : record.name}
              </NavLink>
            )
        }
      },
      {
        title: intl.formatMessage({id: 'courses.activities_added'}),
        dataIndex: 'activities ',
        key: 'activities ',
        render: (text: string, record: any) => record.activities
      },
      {
        title: intl.formatMessage({id: 'courses.date_created'}),
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text: string, record: any) => record.createdAt ? moment(record.updatedAt).format('DD.MM.YYYY') : '-'
      },
      {
        title: intl.formatMessage({id: 'courses.last_update'}),
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        render: (text: string, record: any) => record.updatedAt ? moment(record.updatedAt).format('DD.MM.YYYY') : '-'
      },
      {
        title: intl.formatMessage({id: 'general.languages'}),
        dataIndex: 'languages',
        key: 'languages',
        render: (text: string, record: any) => {
          if (record.availableLanguages === 0){
            return record.firstLanguage;
          }
          return (
            Object.entries(record.availableLanguages).map((value:any, index:any) => {
                return (
                  <div className="language-link">
                    <p> {value[1]}</p>
                  </div>
                )
            })
          )
        }
      },
      {
        title:
          <div>
            {intl.formatMessage({id: 'general.online'})}
            <Popover content={intl.formatMessage({id: 'courses.course_status_explanation'})}>
                <i style={{marginLeft: '3px'}} className="fal fa-question-circle"></i>
            </Popover>
          </div>,
        dataIndex: 'active',
        key: 'active',
        render: (_text: string, record: any) => {
          return (<Switch key={`course-switch-${record.id}`}  onChange={() => onSwitchChange(record)} defaultChecked={record.active} disabled={isSwitchLoading}/>)
        }
      },
      {
        key: 'actions',
        render: (text: string, record: any, index: number) => {
          const content = <>
            <NavLink
                to={
                  `/courses/${record.id}/edit`
                }
            >
              <div className="popover-item">
                <FormattedMessage id="general.edit"/>
              </div>
            </NavLink>
            <div onClick={()=>warnDeletion(record.id)} className="popover-item">
              <FormattedMessage id="general.delete"/>
            </div>
          </>;

          return (
              <Popover
                  open={index === visibleTooltip}
              content={content}
              trigger="click"
              placement="bottomRight"
              arrowPointAtCenter
              onOpenChange={(visible) => handleVisibleChange(visible, index)}
            >
              <div style={{width: '100%', cursor: 'pointer', textAlign: 'center'}}>
                <i className="fal fa-ellipsis-v" style={{fontSize: '16px'}} />
              </div>
            </Popover>
          )
        },
      }
    ];

  const handleVisibleChange = (visible: boolean, recordId: number) => {
    if (visible) {
      setVisibleTooltip(recordId);
    } else {
      setVisibleTooltip(null);
    }
  }

  const showTotal = (total: any, range: any) => {
    return `${range[0]}-${range[1]} of ${total}`
  };

  const onSwitchChange = async (record: any) => {
    try {
      setIsSwitchLoading(true);

      record.active = record.active ? 0 : 1;

      await coursesApiClient.request(`/api/v1/courses/courses/${record.id}`, record, 'PUT');

      const messageId = record.active ? 'courses.course_can_now_be_included' : 'courses.course_now_can_not_be_included';

      message.success(intl.formatMessage({id: messageId}), 7);
    } catch (err) {
      console.error(err);
      message.error(intl.formatMessage({id: 'error.action_failed'}));
    } finally {
      setIsSwitchLoading(false);
    }
  }

  const submitForm = async (values: any) => {

    let uuid:any;
    let parsedValues = {
      ...values,
      active: 0,
      organizationUuid: uuid
    }
    let responese = await coursesApiClient.request(`/api/v1/courses/courses`, parsedValues, 'POST');
    navigate(`/courses/${responese.course.id}/edit`);
  }

  const  filterBySearchString = (searchText: string, data: never[], includeColumn: string[]) => {
    return !searchText ? data : data.filter((data: any) => { return Object.keys(data).some(key => includeColumn.includes(key) ? data[key].toString().toLowerCase().includes(searchText.toLocaleLowerCase()) : false); });
  }

  const  filterBySearch = async (value: string) => {
    let data = filterBySearchString(value, coursesListBeforeSearch, includeColumn);
    setCoursesList(data);
  }

  const filterCourses = async (checked: boolean) => {
    if (checked) {
      loadCourses('custom')
    } else {
      loadCourses('default')
    }
  }

  return (
    <DefaultLayout.PageLayout>
      <DefaultLayout.PageHeader title={intl.formatMessage({ id: 'courses.manage_elearning_content' })} />
      <DefaultLayout.PageContent>
      <div className='courses-toolbar-wrapper'>
        <Space>
        <Button type="primary" icon={<PlusOutlined/>} onClick={() => setShowAddNewCourse(true)}>
          <FormattedMessage id="courses.add_new_course" />
        </Button>
        {session.active_user_type !== 'CUSTOMER_ADMIN' &&
          <Button type="primary" icon={<i className="fal fa-filter"></i>} onClick={() => setShowCoursesModal(true)}>
            <FormattedMessage id="general.filter" />
          </Button>
        }
        </Space>
          <div className='curses-toolbar-wrapper-right'>
            <Search
              className="table-search-field"
              placeholder={intl.formatMessage({ id: "general.search" })}
              onSearch={value => filterBySearch(value)}
            />
            <div className='courses-switch-filter'>
              {intl.formatMessage({ id: 'courses.show_default_courses' })}
              <Tooltip title={intl.formatMessage({ id: 'courses.custom_courses_filter_hint' })}>
                <i className='fal fa-question-circle header-item' />
              </Tooltip>:{' '}
              <Switch onChange={(checked: boolean) => filterCourses(checked)} />
            </div>
          </div>
        </div>
        <Modal
          width={530}
          className="add-new-course"
          open={showAddNewCourse}
          onCancel={() => setShowAddNewCourse(false)}
          title={intl.formatMessage({ id: 'courses.add_new_course' })}
          okText={intl.formatMessage({ id: 'general.submit' })}
          cancelText={intl.formatMessage({ id: 'general.back' })}
          onOk={form.submit}
        >
          <Form form={form} onFinish={submitForm}>
            <Input customLayout={formItemLayout} name="name" label={intl.formatMessage({ id: 'courses.course_title' })} validation={{ required: true }}/>
            <Select
              customLayout={formItemLayout}
              name="firstLanguage"
              label={intl.formatMessage({ id: 'courses.first_language_to_add' })}
              manualOptions={selectLanguageList}
              onChange={(value: any) => {form.setFieldsValue({ firstLanguage: value })}}
              customRules={[{ required: true, message: intl.formatMessage({ id: 'validation.field_required' }) }]}
            />
          </Form>
        </Modal>
        <Table
          locale={{ emptyText: intl.formatMessage({ id: 'general.found_no_data'}) }}
          loading={isCoursesLoading}
          style={{ margin: 0, marginTop: 15 }}
          columns={columns}
          dataSource={coursesList}
          size="middle"
          scroll={{ x: 800 }}
          pagination={{
            showTotal: showTotal,
            pageSizeOptions: ['10', '20'],
            showSizeChanger: true,
            locale: { items_per_page: intl.formatMessage({id: 'general.page'}), jump_to: intl.formatMessage({id: 'general.go_to'}) },
            showQuickJumper: true,
          }}
        />
      </DefaultLayout.PageContent>
      <CoursesFilterModal
        visible={showCoursesModal}
        onCancel={() => setShowCoursesModal(false)}
        setCoursesList={setCoursesList}
        setCoursesListBeforeSearch={setCoursesListBeforeSearch}
        setIsLoading={setIsCoursesLoading}
        setCustomerUrl={setCustomerUrl}
      />
    </DefaultLayout.PageLayout>
  );
}

export default connect(mapStateToProps)(ManageContent);
