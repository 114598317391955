import React from 'react';
import {Button, Space} from 'antd';
import {FormattedMessage} from 'react-intl';
import {MaterialType} from './types';
import './styles.scss';

interface MaterialButtonsInterface {
    setShowMaterialModal: React.Dispatch<React.SetStateAction<boolean>>;
    setType: React.Dispatch<React.SetStateAction<MaterialType>>;
    disable: {
        learn: boolean,
        test: boolean,
        learning_test: boolean,
        assignment: boolean,
        handouts: boolean,
        assessment: boolean,
        certificate: boolean
    }
}

const MaterialButtons: React.FC<MaterialButtonsInterface> = ({setShowMaterialModal, setType, disable}) => {
    const materialType = {
        first_row: {
            'SCORM_LEARNING': {
                type: 'SCORM_LEARNING',
                disabled: disable.learn,
                text: 'courses.learning_file',
                icon: <i className="fa-solid fa-book"></i>,
            },
            'SCORM_TEST': {
                type: 'SCORM_TEST',
                disabled: disable.test,
                text: 'courses.scorm_test_file',
                icon: <i className="fa-solid fa-check-double"></i>,
            },
            'LEARNING_TEST': {
                type: 'LEARNING_TEST',
                disabled: disable.test,
                text: 'learning_tests.learning_test',
                icon: <i className="fa-solid fa-circle-dot"></i>,
            },
        },
        second_row: {
            'ASSIGNMENT': {
                type: 'ASSIGNMENT',
                disabled: disable.assignment,
                text: 'courses.individual_work',
                icon: <i className="fa-solid fa-file-signature"></i>,
            },
            'ASSESSMENT': {
                type: 'ASSESSMENT',
                disabled: disable.assessment,
                text: 'assessment.practical_assessment',
                icon: <i className="fa-solid fa-file-user"></i>,
            },
            'CERTIFICATE': {
                type: 'CERTIFICATE',
                disabled: disable.certificate,
                text: 'courses.certificate',
                icon: <i className="fa-solid fa-file-user"></i>,
            }
        },
        third_row: {
            'FILE': {
                type: 'FILE',
                disabled: disable.handouts,
                text: 'courses.material',
                icon: <i className="fa-solid fa-file-lines"></i>,
                buttonType: 'outlined'
            }
        }
    };

    return (
        <>
            <div>
                <Space size={10}>
                    {
                        Object.values(materialType.first_row).map((material: any) => {
                            return (
                                <Button type={material?.buttonType ? 'default' : 'primary'}
                                        className={'material-type-button ' + (material?.buttonType && 'button-outlined')}
                                        key={material.type} disabled={material.disabled} onClick={() => {
                                    setType(material.type);
                                    setShowMaterialModal(true);
                                }}>
                                    {material.icon} <FormattedMessage id={material.text}/>
                                </Button>
                            )
                        })
                    }
                </Space>
            </div>
            <div>
                <Space size={10}>
                    {
                        Object.values(materialType.second_row).map((material: any) => {
                            return (
                                <Button type={material?.buttonType ? 'default' : 'primary'}
                                        className={'material-type-button ' + (material?.buttonType && 'button-outlined')}
                                        key={material.type} disabled={material.disabled} onClick={() => {
                                    setType(material.type);
                                    setShowMaterialModal(true);
                                }}>
                                    {material.icon} <FormattedMessage id={material.text}/>
                                </Button>
                            )
                        })
                    }
                </Space>
            </div>
            <div>
                <Space size={10}>
                    {
                        Object.values(materialType.third_row).map((material: any) => {
                            return (
                                <Button type={material?.buttonType ? 'default' : 'primary'}
                                        className={'material-type-button ' + (material?.buttonType && 'button-outlined')}
                                        key={material.type} disabled={material.disabled} onClick={() => {
                                    setType(material.type);
                                    setShowMaterialModal(true);
                                }}>
                                    {material.icon} <FormattedMessage id={material.text}/>
                                </Button>
                            )
                        })
                    }
                </Space>
            </div>
        </>
    );
}

export default MaterialButtons;
