import {useState} from 'react'
import useHandleError from 'utils/useHandleError';
import apiClient from 'utils/apiClient';
import coursesApiClient from "utils/coursesApiClient";
import {useIntl} from "react-intl";
import {UploadFile} from "antd";
import {RcFile} from "antd/lib/upload";


export const useRules = () => {
    const [handleError] = useHandleError();
    const [loading, setLoading] = useState<boolean>(false)
    const intl = useIntl();

    const getRule = async (id: string) => {
        setLoading(true)
        try {
            const response = await coursesApiClient.request(`/api/v1/rules/${id}`, [], 'GET');

            if ("error" in response) {
                throw response;
            }

            if ("data" in response) {
                return response.data;
            }

            return null;
        } catch (error) {
            handleError(error);
            return null;
        } finally {
            setLoading(false)
        }
    }

    const saveRule = async (rulesData: any, id: string | null = null): Promise<any | null> => {
        setLoading(true);
        try {
            const response = id ?
                await apiClient.request(`/api/v1/rules/${id}`, rulesData, 'PUT')
                : await apiClient.request(`/api/v1/rules`, rulesData, 'POST');

            if ("error" in response) {
                throw response;
            }

            if ("data" in response) {
                return response.data;
            }

            return null;
        } catch (error) {
            handleError(error);
            return null;
        } finally {
            setLoading(false);
        }
    }

    const getRuleFile = async (id: number): Promise<UploadFile[]> => {
        setLoading(true);

        try {
            const response = await apiClient.request(`/api/v1/rules/${id}/files`, [], 'GET');

            if (response && response.data && Array.isArray(response.data)) {
                return response.data as UploadFile[];
            }

            throw response;
        } catch (error) {
            handleError(error);
            setLoading(false);
            return [];
        } finally {
            setLoading(false);
        }
    }

    const storeRuleFile = async (id: number, file: RcFile): Promise<number | undefined> => {
        setLoading(true)

        try {
            const bodyFormData = new FormData();
            bodyFormData.append('file', file);
            const response = await apiClient.request(`/api/v1/rules/${id}/files`, bodyFormData, 'POST');

            if (!!response && typeof response === 'object' && !!response.id) {
                return response.id;
            }

            throw response;

        } catch (error) {
            handleError(error);
        } finally {
            setLoading(false);
        }
    }

    const downloadRuleFile = async (ruleId: number, fileId: number): Promise<null | Blob> => {
        setLoading(true);

        try {
            const response = await apiClient.request(`/api/v1/rules/${ruleId}/files/${fileId}`, [], 'GET', true, true);
            if (response && response.type) {
                if (response.type === 'application/json') {
                    const jsonResponse = await response.text();

                    if ("error" in jsonResponse) {
                        throw jsonResponse;
                    };

                    return response;
                }

                return response;
            }

            throw new Error();

        } catch (error) {
            handleError(error);
            return null;
        } finally {
            setLoading(false);
        }
    }

    const deleteRuleFile = async (ruleId: number, id: number): Promise<boolean> => {
        setLoading(true);

        try {
            const response = await apiClient.request(`/api/v1/rules/${ruleId}/files/${id}`, [], 'DELETE');

            if (!!response && !!response.deleted) {
                return true;
            }

            throw response;

        } catch (error) {
            handleError(error);
            return false;
        } finally {
            setLoading(false);
        }
    }

    const deleteRule = async (id: number): Promise<boolean> => {
        setLoading(true);
        try {
            const response = await apiClient.request(`/api/v1/rules/${id}`, [], 'DELETE');

            if ("error" in response) {
                throw response;
            }

            if ("deleted" in response) {
                return response.deleted;
            }

            return false;
        } catch (error) {
            handleError(error);
            return false;
        } finally {
            setLoading(false);
        }
    }

    const getStudentRule = async () => {
        setLoading(true)
        try {
            const response = await coursesApiClient.request('/api/v1/rules/confirmation/student', [], 'GET');

            if ("error" in response) {
                throw response;
            }

            if ("data" in response) {
                return response.data;
            }

            return null;
        } catch (error) {
            handleError(error);
            return null;
        } finally {
            setLoading(false)
        }
    }

    const acceptRule = async (id: number) => {
        setLoading(true)
        try {
            const response = await coursesApiClient.request(`/api/v1/rules/confirmation/${id}/student`, [], 'POST');

            if ("error" in response) {
                throw response;
            }

            if ("data" in response) {
                return response.data;
            }

            return null;
        } catch (error) {
            handleError(error);
            return null;
        } finally {
            setLoading(false)
        }
    }

    return {
        loading,
        saveRule,
        getRule,
        getRuleFile,
        storeRuleFile,
        deleteRuleFile,
        downloadRuleFile,
        deleteRule,
        getStudentRule,
        acceptRule
    }
}