import React, {useCallback} from 'react';
import FullCalendar, {EventInput} from '@fullcalendar/react'
import allLocales from '@fullcalendar/core/locales-all';
import apiClient from 'utils/apiClient';
import {connect} from 'react-redux';
import useHandleError from 'utils/useHandleError';

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale,
    }
};

const Calendar: React.FC<any> = ({ url, events, locales, locale, saveData, refetchEvents, ...restProps }) => {
    const [handleError] = useHandleError();

    const fetchEvents = useCallback((info: any, successCallback: (events: EventInput[]) => void, failureCallback: (error: { message: string; response?: any; }) => void) => {
        apiClient.request(url, info, 'GET').then((response: any) => {
            if ("error" in response) {
                throw response
            };

            successCallback(response.data);
        }).catch((error: any) => {
            handleError(error)
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetchEvents]);

    return <FullCalendar
        firstDay={1}
        eventTimeFormat={{ // like '14:30:00'
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
            meridiem: false
        }}
        locales={allLocales}
        locale={locale ?? 'en'}
        events={!!url ? fetchEvents : events}
        {...restProps} />
}

export default connect(mapStateToProps)(Calendar);

