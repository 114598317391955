import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Form } from 'antd';
import {Input, Select, InputNumber, TextArea} from 'components/Form';
import { connect } from 'react-redux';
import { InfoTable } from 'ui';
import {useDate} from 'hooks/useDate';

interface SubmissionDetailInterface {
    canGrade?: boolean;
    evaluationType?: string;
    submission: any;
}
const mapStateToProps = (state: any) => ({
    session: state.session,
});

const formItemLayout = {
    labelCol: {span: 5},
    wrapperCol: {span: 6},
};

const SubmissionDetail: React.FC<SubmissionDetailInterface> = ({
    canGrade = false,
    evaluationType,
    submission
}) => {
    const [submissionDetail, setSubmissionDetail] = useState<any>([]);

    const intl = useIntl();
    const {formatDate} = useDate();

    useEffect(() => {
        if (submission) {
            const rows = [
                {
                    label: intl.formatMessage({id: 'courses.due_date'}),
                    value: formatDate(submission.deadline)
                },
                {
                    label: intl.formatMessage({id: 'courses.submission_date'}),
                    value: submission.submission_date ? formatDate(submission.submission_date) : ''
                },
                {
                    label: intl.formatMessage({id: 'courses.grading_submitted'}),
                    value: submission.grade_by_type_id ? `${submission.grade_by_name} ${submission.grade_date ? formatDate(submission.grade_date) : ''}` : ''
                },
                {
                    label: intl.formatMessage({id: 'courses.grade'}),
                    value: renderGradeValue(evaluationType)
                },
                {
                    label: intl.formatMessage({id: 'courses.comments'}),
                    value: renderCommentsValue()
                },
            ]

            setSubmissionDetail(rows)
        }
    }, [submission?.submission_date])

    const renderGradeValue = (evaluationType: string|undefined) => {
        if(canGrade) {
            switch (evaluationType) {
                case 'EXAMINATION':
                    return(
                        <Select
                            name="grade"
                            validation={{required: false}}
                            customLayout={{labelCol: 0, wrapperCol: 24}}
                            defaultValue={submission.grade ?? 0}
                            manualOptions={{
                                PASSED: intl.formatMessage({id: 'general.passed'}),
                                FAILED: intl.formatMessage({id: 'general.failed'})
                            }}
                            style={{width: '118px', marginBottom: '0px'}}
                        />
                    )
                
                case 'RANGE':
                    return(
                        <InputNumber
                            name="grade"
                            validation={{required: false}}
                            customLayout={{labelCol: 0, wrapperCol: 24}}
                            style={{width: '118px', marginBottom: '0px'}}
                            min={0}
                            max={100}
                            formatter={(value: number) => `${value}%`}
                            parser={(value: string) => value!.replace('%', '')}
                        />
                    )
            }
        } else {
            return submission.status === 'RETURNED' ? intl.formatMessage({id: 'courses.topic_status.submission_returned'}) : submission.grade
        }
    }

    const renderCommentsValue = () => {
        if(canGrade) {
            return(
                <TextArea
                    name="grade_comments"
                    validation={{required: false}}
                    customLayout={{labelCol: 0, wrapperCol: 24}}
                    style={{width: '100%', marginBottom: '0px'}}
                    rows={1}
                />
            )
        } else {
            return submission.grade_comments
        }
    }

    return (
        <InfoTable 
            rows={submissionDetail}
        />
    )
}
export default connect(mapStateToProps)(SubmissionDetail);