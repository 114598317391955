import React, {useEffect, useState} from 'react';
import {Tabs} from "antd";
import {useIntl} from "react-intl";
import {useNavigate, useParams} from "react-router-dom";
import Users from "./Users";
import Settings from "./Settings";
import OrganizationalStructure from "./OrganizationalStructure";
import DefaultLayout from "components/DefaultLayout";
import {useCustomer} from "hooks/Customers/useCustomer";
import {CustomerProps} from "./types";
import Spinner from "components/Spinner";
import Events from "./Events";
import StructureUsage from "./StructureUsage";

interface CustomerInterface {
}

const Customer: React.FC<CustomerInterface> = ({}) => {
    const {getCustomer, loading} = useCustomer();
    const [activeTabKey, setActiveTabKey] = useState('users')
    const [customer, setCustomer] = useState<CustomerProps>();

    const intl = useIntl();
    const params = useParams();
    const customerId = params.id;
    const navigate = useNavigate();
    const tab = params.tab;

    useEffect(() => {
        if (customerId) {
            loadCustomer(customerId).then((data: CustomerProps | undefined) => {
                setCustomer(data)
            })
        }

        if (tab) {
            setActiveTabKey(tab)
        }
    }, [])

    const loadCustomer = async (id: string) => {
        return await getCustomer(id);
    };

    const tabChangeHandle = (tab: string) => {
        setActiveTabKey(tab)
        navigate(`/customers/${customerId}/${tab}`)
    }

    return (
        <Spinner spinning={loading}>
            <DefaultLayout.PageLayout>
                <DefaultLayout.PageHeader
                    title={customer?.name ? customer.name : ''}
                    breadcrumb={[{
                        name: intl.formatMessage({id: 'general.customers'}),
                        path: '/customers'
                    }]}
                />
                <DefaultLayout.PageContent withBottomMargin>
                    <Tabs destroyInactiveTabPane onChange={tabChangeHandle} activeKey={activeTabKey}
                          items={[
                              {
                                  label: intl.formatMessage({id: 'general.users'}),
                                  key: 'users',
                                  children: <Users/>,
                              },
                              {
                                  label: intl.formatMessage({id: 'general.settings'}),
                                  key: 'settings',
                                  children: <Settings/>,
                              },
                              {
                                  label: intl.formatMessage({id: 'general.structure_settings'}),
                                  key: 'structureUsage',
                                  children: <StructureUsage/>,
                              },
                              {
                                  label: intl.formatMessage({id: 'general.structure'}),
                                  key: 'structure',
                                  children: <OrganizationalStructure/>,
                              },
                              {
                                  label: intl.formatMessage({id: 'general.event_feed'}),
                                  key: 'events',
                                  children: <Events/>,
                              },
                          ]}
                    />
                </DefaultLayout.PageContent>
            </DefaultLayout.PageLayout>
        </Spinner>
    )
};

export default Customer;
