import React, {useState} from 'react';
import {NavLink, useParams} from 'react-router-dom';
import {useIntl} from 'react-intl';
import {Table} from "ui";

interface ReviewInterface {
}

const Review: React.FC<ReviewInterface> = ({}) => {
    const [reload, setReload] = useState(false);

    const params = useParams();
    const questionPoolId = params.id;
    const intl = useIntl();

    const columns = [
        {
            title: intl.formatMessage({id: 'general.title'}),
            dataIndex: 'title',
            render: (text: string, record: any) => {
                return (
                    <NavLink to={`/question-pools/question-banks/${questionPoolId}/edit/${record.id}`}>
                        {text}
                    </NavLink>
                )
            }
        },
        {
            title: intl.formatMessage({id: 'question_pools.times_appeared'}),
            key: 'times_appeared',
            sorter: true,
            dataIndex: 'timesAppeared',
        },
        {
            title: intl.formatMessage({id: 'question_pools.is_correct'}),
            key: 'is_correct',
            sorter: true,
            dataIndex: 'isCorrect',
        },
        {
            title: intl.formatMessage({id: 'question_pools.is_failed'}),
            key: 'is_failed',
            sorter: true,
            dataIndex: 'isFailed',
        },
        {
            title: intl.formatMessage({id: 'learning_tests.partly_correct'}),
            key: 'partly_correct',
            sorter: true,
            dataIndex: 'partlyCorrect',
        }
    ];

    return (
        <Table
            columns={columns}
            url={`/api/v1/courses/question-pools/question-banks/${questionPoolId}/review`}
            setReload={setReload}
            reload={reload}
            rowSelection={false}
            rowKey={'id'}
        />
    )
};

export default Review;
