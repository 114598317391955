import React, {useState} from 'react';
import {useIntl} from "react-intl";
import moment from "moment/moment";
import {Table} from "ui";
import {useParams} from "react-router-dom";

interface EventsTabProps {
    userTypeId: number;
}

const EventsTab: React.FC<EventsTabProps> = ({userTypeId}) => {

    const intl = useIntl();
    const [reload, setReload] = useState(false);
    const params = useParams();
    const customerId = params.id;

    const columns = [
        {
            title: intl.formatMessage({id: 'general.event_type'}),
            dataIndex: 'event_type'
        },
        {
            title: intl.formatMessage({id: 'general.event_subject_type'}),
            dataIndex: 'event_subject_type'
        },
        {
            title: intl.formatMessage({id: 'general.event_object_type'}),
            dataIndex: 'event_object_type'
        },
        {
            title: intl.formatMessage({id: 'general.event_initiator_type'}),
            dataIndex: 'event_initiator_type'
        },
        {
            title: intl.formatMessage({id: 'general.event_initiator'}),
            dataIndex: 'email'
        },
        {
            title: intl.formatMessage({id: 'general.created_at'}),
            render: (value: string, record: any) => moment(record.created_at).format('DD.MM.YYYY HH:mm:ss')
        },
    ];

    return (
        <Table
            columns={columns}
            url={`api/v1/customer-users/${userTypeId}/user-events`}
            setReload={setReload}
            rowSelection={false}
            reload={reload}
            rowKey={'id'}
            scroll={{x: 800}}
        />
    );
};

export default EventsTab;
