import React, { useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { message, Button, Form } from 'antd';
import {TextArea} from 'components/Form';
import { connect } from 'react-redux';
import useHandleError from "utils/useHandleError";
import apiClient from 'utils/apiClient';
import {Table} from "ui";
import '../styles.scss';
import moment from 'moment';


interface AssessmentOverviewInterface {
    activityFormData: any;
    assessmentSettings: any;
    submission: any;
    loadAssignment: Function;
    setIsSubmitLoading: React.Dispatch<React.SetStateAction<boolean>>;
    isSubmitLoading: boolean;
    getStatusTag: Function;
    session?: any;
}
const mapStateToProps = (state: any) => ({
    session: state.session,
});

const AssessmentOverview: React.FC<AssessmentOverviewInterface> = ({
    activityFormData,
    assessmentSettings,
    submission,
    loadAssignment,
    setIsSubmitLoading,
    isSubmitLoading,
    getStatusTag,
    session
}) => {
    const [overviewRows, setOverviewRows] = useState<any[]>([]);
    const [selectedInstructor, setSelectedInstructor] = useState<any>(null);

    const intl = useIntl();
    const [handleError] = useHandleError();
    const [form] = Form.useForm();

    useEffect(() => {
        if(submission && activityFormData) {
            const initiatedData = activityFormData.attemptData.initiateData;
            let overviewData = [{
                status: initiatedData.isInitiated,
                tagline: initiatedData.initiatedDate ?  (initiatedData.initiatedByUser ? `${initiatedData.initiatedByUser?.name} ${initiatedData.initiatedByUser?.surname} ` : '') + moment(initiatedData.initiatedDate).format('DD/MM/YYYY HH:mm') : null,
                title: initiatedData.isInitiated ? intl.formatMessage({id: 'assessment.assessment_initiated'}) : intl.formatMessage({id: 'assessment.waiting_initiate'}),
                comments: '',
                actions: initiatedData.canInitiate ? <Button type='primary' onClick={() => initiateAssessment()} loading={isSubmitLoading}><FormattedMessage id='assessment.initiate_assessment' /></Button> : null
            }]

            activityFormData.attemptData.instructorsData.map((instructorData: any) => {
                overviewData.push({
                    status: instructorData.isSubmitted,
                    tagline: instructorData.submittedDate ? moment(instructorData.submittedDate).format('DD/MM/YYYY HH:mm') : null,
                    title: instructorData.instructorUser?.name + ' ' + instructorData.instructorUser?.surname,
                    comments: instructorData.comments,
                    actions: instructorData.canSubmit ? <Button type='primary' loading={isSubmitLoading} onClick={() => setSelectedInstructor(instructorData)}><FormattedMessage id='assessment.submit_assessment' /></Button> : null
                })
            })

            setOverviewRows(overviewData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[activityFormData])

    const initiateAssessment = async () => {
        try {
            setIsSubmitLoading(true);
            let response = await apiClient.request(`/api/v1/courses/assessment-results/initiate/${submission.id}`, {}, 'POST');
            message.success(intl.formatMessage({id: "assessment.initiated_successfully"}));
            setIsSubmitLoading(false);
            loadAssignment()
        } catch (error) {
            handleError(error)
        } finally {
            setIsSubmitLoading(false);
        }
    };

    const submitInstructorForm = async (values: any) => {
        const formValues = form.getFieldsValue();
        const parsedValues = {
            instructorId: selectedInstructor.instructorUser.typeId,
            comments: formValues?.instructorComments,
        }

        try {
            setIsSubmitLoading(true);
            let response = await apiClient.request(`/api/v1/courses/assessment-results/submit-instructor/${submission.id}`, parsedValues, 'POST');
            message.success(intl.formatMessage({id: "courses.evaluation_submitted_success"}));
            setIsSubmitLoading(false);
            loadAssignment()
        } catch (error) {
            handleError(error)
        } finally {
            setIsSubmitLoading(false);
        }
    };

    const columns = [
        {
            title: intl.formatMessage({id: 'assessment.final_assessment'}),
            key: 'final_assessment',
            render: (text: string, record: any, index: number) => {
                return getStatusTag()
            },
        },
        {
            title: intl.formatMessage({id: 'grading.grade'}),
            key: 'grade',
            render: (text: string, record: any, index: number) => {
                return record.grade
            },
        },
        {
            title: intl.formatMessage({id: 'general.comments'}),
            key: 'comments',
            render: (text: string, record: any, index: number) => {
                return record.comments
            },
        }
    ];

    return (
        <div className='assessment-overview'>
            {selectedInstructor === null && overviewRows.map((item: any, key: number) => {
                return (
                    <div className={`assessment-row flex flex-align-center w-100 ${item.status ? 'is-submitted' : ''}`} key={key}>
                        <div className='row-icon'>
                            <i className="fa-light fa-circle-check"></i>
                        </div>
                        <div className='row-title'>
                            <h4>{item.title}</h4>
                            <div className='datetime'>{item.tagline}</div>
                        </div>
                        <div className='row-comments'>
                            {item.comments && <>
                                <h4><FormattedMessage id='general.comments' /></h4>
                                <div>{item.comments}</div>
                            </>}
                        </div>
                        <div className='row-actions'>
                            {item.actions}
                        </div>
                    </div>
                )
            })}
            {selectedInstructor !== null && (
                <Form form={form} onFinish={submitInstructorForm} initialValues={{
                    instructorComments: selectedInstructor.comments
                }}>
                    <label htmlFor='instructorComments' className='mb-1'><FormattedMessage id='general.comments' /></label>
                    <TextArea
                        name="instructorComments"
                        validation={{required: true}}
                        customLayout={{labelCol: 0, wrapperCol: 24}}
                        style={{width: '100%', marginBottom: '0px'}}
                        rows={2}
                    />
                    <div className='flex gap-10 mt-3'><Button type='primary' loading={isSubmitLoading} onClick={() => form.submit()}><FormattedMessage id='assessment.submit_assessment' /></Button> <Button type='ghost' htmlType='button' onClick={() => setSelectedInstructor(null)} className='mb-3'><FormattedMessage id='general.cancel' /></Button></div>
                </Form>
            )}
            {activityFormData.attemptData.finalAssessment.isSubmitted ? <div className='mt-10'>
                <h4><FormattedMessage id='assessment.final_assessment' /></h4>
                <div className='separator' />
                <Table
                    columns={columns}
                    dataSource={[activityFormData.attemptData.finalAssessment]}
                    rowSelection={false}
                    pagination={false}
                />
            </div> : null}
        </div >
    )
}
export default connect(mapStateToProps)(AssessmentOverview);