import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Button, Popover, Table, Modal, Input} from 'antd';
import moment from 'moment';
import DefaultLayout from 'components/DefaultLayout';
import {ExclamationCircleOutlined} from "@ant-design/icons";
import './styles.scss';

const PhishingEvents: React.FC = () => {
    const [visibleTooltip, setVisibleTooltip] = useState<number | null>(null);
    const intl = useIntl();
    const {confirm, info} = Modal;
    const [modalOpen, setModalOpen] = useState(false);
    const [viewData, setViewData] = useState<any>(null)

    const {TextArea} = Input;

    const [data, setData] = useState(
        [
            {
                "id": 595,
                "name": "Demo 10.08.2023",
                "status": "In progress",
                "results": [
                    {
                        "id": "Mu3rze4",
                        "status": "Email Sent",
                        "ip": "",
                        "latitude": 0,
                        "longitude": 0,
                        "send_date": "2023-06-13T08:48:32.487653168Z",
                        "reported": false,
                        "modified_date": "2023-06-13T08:48:32.487653168Z",
                        "email": "maris.igaunis@esynergy.lv",
                        "first_name": "Māris",
                        "last_name": "Igaunis",
                        "position": "TEST"
                    }
                ],
                "timeline": [
                    {
                        "campaign_id": 595,
                        "email": "",
                        "time": "2023-06-13T08:48:32.385459662Z",
                        "message": "Campaign Created",
                        "details": ""
                    },
                    {
                        "campaign_id": 595,
                        "email": "maris.igaunis@esynergy.lv",
                        "time": "2023-06-13T08:48:32.487653168Z",
                        "message": "Email Sent",
                        "details": ""
                    }
                ]
            },
            {
                "id": 599,
                "name": "Demo 10.08.2023",
                "status": "In progress",
                "results": [
                    {
                        "id": "RfQVTcm",
                        "status": "Email Opened",
                        "ip": "192.168.13.136",
                        "latitude": 0,
                        "longitude": 0,
                        "send_date": "2023-07-05T07:37:47.697698918Z",
                        "reported": false,
                        "modified_date": "2023-07-05T07:37:52.891496211Z",
                        "email": "maris.igaunis@esynergy.lv",
                        "first_name": "Māris",
                        "last_name": "Igaunis",
                        "position": "Developer"
                    }
                ],
                "timeline": [
                    {
                        "campaign_id": 599,
                        "email": "",
                        "time": "2023-07-05T07:37:46.892684563Z",
                        "message": "Campaign Created",
                        "details": ""
                    },
                    {
                        "campaign_id": 599,
                        "email": "maris.igaunis@esynergy.lv",
                        "time": "2023-07-05T07:37:47.697698918Z",
                        "message": "Email Sent",
                        "details": ""
                    },
                    {
                        "campaign_id": 599,
                        "email": "maris.igaunis@esynergy.lv",
                        "time": "2023-07-05T07:37:52.891496211Z",
                        "message": "Email Opened",
                        "details": "{\"payload\":{\"rid\":[\"RfQVTcm\"]},\"browser\":{\"address\":\"192.168.13.136\",\"user-agent\":\"Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36\"}}"
                    }
                ]
            },
            {
                "id": 598,
                "name": "Demo 10.08.2023",
                "status": "In progress",
                "results": [
                    {
                        "id": "zpIUjO5",
                        "status": "Submitted Data",
                        "ip": "192.168.12.14",
                        "latitude": 0,
                        "longitude": 0,
                        "send_date": "2023-07-05T07:25:12.801426148Z",
                        "reported": false,
                        "modified_date": "2023-07-05T07:26:22.727002353Z",
                        "email": "vitalijs.sevcenko@csolutions.lv",
                        "first_name": "Vitalijs",
                        "last_name": "Sevcenko",
                        "position": ""
                    }
                ],
                "timeline": [
                    {
                        "campaign_id": 598,
                        "email": "",
                        "time": "2023-07-05T07:25:11.826644167Z",
                        "message": "Campaign Created",
                        "details": ""
                    },
                    {
                        "campaign_id": 598,
                        "email": "vitalijs.sevcenko@csolutions.lv",
                        "time": "2023-07-05T07:25:12.801426148Z",
                        "message": "Email Sent",
                        "details": ""
                    },
                    {
                        "campaign_id": 598,
                        "email": "vitalijs.sevcenko@csolutions.lv",
                        "time": "2023-07-05T07:25:58.34730517Z",
                        "message": "Clicked Link",
                        "details": "{\"payload\":{\"rid\":[\"zpIUjO5\"]},\"browser\":{\"address\":\"192.168.12.14\",\"user-agent\":\"Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36\"}}"
                    },
                    {
                        "campaign_id": 598,
                        "email": "vitalijs.sevcenko@csolutions.lv",
                        "time": "2023-07-05T07:26:22.727002353Z",
                        "message": "Submitted Data",
                        "details": "{\"payload\":{\"rid\":[\"zpIUjO5\"]},\"browser\":{\"address\":\"192.168.12.14\",\"user-agent\":\"Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36\"}}"
                    }
                ]
            },
            {
                "id": 592,
                "name": "Demo 10.08.2023",
                "status": "In progress",
                "results": [
                    {
                        "id": "eHNP81u",
                        "status": "Clicked Link",
                        "ip": "192.168.13.130",
                        "latitude": 0,
                        "longitude": 0,
                        "send_date": "2023-05-26T08:04:56.362591594Z",
                        "reported": false,
                        "modified_date": "2023-05-26T08:05:12.046660339Z",
                        "email": "maris.igaunis@esynergy.lv",
                        "first_name": "Māris",
                        "last_name": "Igaunis",
                        "position": "Social Worker"
                    }
                ],
                "timeline": [
                    {
                        "campaign_id": 592,
                        "email": "",
                        "time": "2023-05-26T08:04:52.64274716Z",
                        "message": "Campaign Created",
                        "details": ""
                    },
                    {
                        "campaign_id": 592,
                        "email": "maris.igaunis@esynergy.lv",
                        "time": "2023-05-26T08:04:56.362591594Z",
                        "message": "Email Sent",
                        "details": ""
                    },
                    {
                        "campaign_id": 592,
                        "email": "maris.igaunis@esynergy.lv",
                        "time": "2023-05-26T08:05:12.046660339Z",
                        "message": "Clicked Link",
                        "details": "{\"payload\":{\"rid\":[\"eHNP81u\"]},\"browser\":{\"address\":\"192.168.13.130\",\"user-agent\":\"Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/113.0.0.0 Safari/537.36\"}}"
                    }
                ]
            },
            {
                "id": 600,
                "name": "-",
                "status": "In progress",
                "results": [
                    {
                        "id": "mMBEyaH",
                        "status": "Email Opened",
                        "ip": "192.168.12.14",
                        "latitude": 0,
                        "longitude": 0,
                        "send_date": "2023-07-05T08:04:01.554851417Z",
                        "reported": true,
                        "modified_date": "2023-07-05T08:05:19.490647594Z",
                        "email": "vitalijs.sevcenko@csolutions.lv",
                        "first_name": "Vitalijs",
                        "last_name": "Sevcenko",
                        "position": ""
                    }
                ],
                "timeline": [
                    {
                        "campaign_id": 600,
                        "email": "",
                        "time": "2023-07-05T08:04:01.358507574Z",
                        "message": "Campaign Created",
                        "details": ""
                    },

                    {
                        "campaign_id": 600,
                        "email": "vitalijs.sevcenko@csolutions.lv",
                        "time": "2023-07-05T08:04:01.554851417Z",
                        "message": "Email Sent",
                        "details": ""
                    },
                    {
                        "campaign_id": 600,
                        "email": "vitalijs.sevcenko@csolutions.lv",
                        "time": "2023-07-05T08:04:46.108938735Z",
                        "message": "Email Opened",
                        "details": "{\"payload\":{\"rid\":[\"mMBEyaH\"]},\"browser\":{\"address\":\"192.168.12.14\",\"user-agent\":\"Mozilla/4.0 (compatible; ms-office; MSOffice rmj)\"}}"
                    },
                    {
                        "campaign_id": 600,
                        "email": "vitalijs.sevcenko@csolutions.lv",
                        "time": "2023-07-05T08:05:19.490647594Z",
                        "message": "Email Opened",
                        "details": "{\"payload\":{\"rid\":[\"mMBEyaH\"]},\"browser\":{\"address\":\"192.168.12.14\",\"user-agent\":\"Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36\"}}"
                    }
                ]
            },
            {
                "id": 493,
                "name": "Demo 10.08.2023",
                "status": "Completed",
                "results": [
                    {
                        "id": "R4kUVKb",
                        "status": "Email Sent",
                        "ip": "172.22.0.1",
                        "latitude": 0,
                        "longitude": 0,
                        "send_date": "2021-11-12T08:46:36.102499471Z",
                        "reported": true,
                        "modified_date": "2021-11-12T08:56:11.89061143Z",
                        "email": "toms.silke@csystems.lv",
                        "first_name": "Toms",
                        "last_name": "Silke",
                        "position": ""
                    }
                ],
                "timeline": [
                    {
                        "campaign_id": 493,
                        "email": "",
                        "time": "2021-11-12T08:21:00.217706751Z",
                        "message": "Campaign Created",
                        "details": ""
                    },
                    {
                        "campaign_id": 493,
                        "email": "toms.silke@csystems.lv",
                        "time": "2021-11-12T08:46:36.102499471Z",
                        "message": "Email Sent",
                        "details": ""
                    },
                    {
                        "campaign_id": 493,
                        "email": "toms.silke@csystems.lv",
                        "time": "2021-11-12T08:56:11.89061143Z",
                        "message": "Email Reported",
                        "details": "{\"payload\":{\"rid\":[\"R4kUVKb\"]},\"browser\":{\"address\":\"172.22.0.1\",\"user-agent\":\"GuzzleHttp/7\"}}"
                    }
                ]
            }
        ]
    )

    const handleVisibleChange = (visible: boolean, rowIndex: number) => {
        if (visible) {
            setVisibleTooltip(rowIndex);
        } else {
            setVisibleTooltip(null);
        }
    }

    const sendingMails = [
        {
            mail: 'service@paymypal.info',
            subject: 'You have money in your PayPal account.',
            html: (
                <div>
                    <div>
                        Hello Vitalijs,
                    </div>
                    <p>
                        We noticed that you were using an outdated and vulnerable version of your web browser when you
                        last logged in. For your security, we encourage you to update your browser as soon as possible.
                    </p>
                    <p>
                        You can download the latest version of the browser here:
                        <a href={'#'}>www.browser.com</a> If you have any
                        questions or concerns, feel free to contact us.
                    </p>
                    <div>
                        Sincerely, Google Team
                    </div>
                </div>
            )
        },
        {
            mail: 'accounts.googlle.link',
            subject: 'Google Chrome browser security update',
            html: (
                <div>
                    <div>
                        Hello Vitalijs,
                    </div>
                    <p>
                        We noticed that you were using an outdated and vulnerable version of your web browser when you
                        last logged in. For your security, we encourage you to update your browser as soon as possible.
                    </p>
                    <p>
                        You can download the latest version of the browser here:
                        <a href={'#'}>www.browser.com</a> If you have any
                        questions or concerns, feel free to contact us.
                    </p>
                    <div>
                        Sincerely, Google Team
                    </div>
                </div>
            )
        },
        {
            mail: 'accounts.microsooft.net',
            subject: 'URGENT: Your Office 365 account is at risk',
            html: (
                <div>
                    <div>
                        Hello Vitalijs,
                    </div>
                    <p>
                        We noticed that you were using an outdated and vulnerable version of your web browser when you
                        last logged in. For your security, we encourage you to update your browser as soon as possible.
                    </p>
                    <p>
                        You can download the latest version of the browser here:
                        <a href={'#'}>www.browser.com</a> If you have any
                        questions or concerns, feel free to contact us.
                    </p>
                    <div>
                        Sincerely, Google Team
                    </div>
                </div>
            )
        },
        {
            mail: 'accounts.microsooft.net',
            subject: 'URGENT: Your Office 365 account is at risk',
            html: (
                <div>
                    <div>
                        Hello Vitalijs,
                    </div>
                    <p>
                        We noticed that you were using an outdated and vulnerable version of your web browser when you
                        last logged in. For your security, we encourage you to update your browser as soon as possible.
                    </p>
                    <p>
                        You can download the latest version of the browser here:
                        <a href={'#'}>www.browser.com</a> If you have any
                        questions or concerns, feel free to contact us.
                    </p>
                    <div>
                        Sincerely, Google Team
                    </div>
                </div>
            )
        },
        {
            mail: 'food@foodclub.lv',
            subject: 'Atgādinājums par pusdienu pasūtījumu',
            html: (
                <div>
                    <div>
                        Hello Vitalijs,
                    </div>
                    <p>
                        We noticed that you were using an outdated and vulnerable version of your web browser when you
                        last logged in. For your security, we encourage you to update your browser as soon as possible.
                    </p>
                    <p>
                        You can download the latest version of the browser here:
                        <a href={'#'}>www.browser.com</a> If you have any
                        questions or concerns, feel free to contact us.
                    </p>
                    <div>
                        Sincerely, Google Team
                    </div>
                </div>
            )
        },
        {
            mail: 'accounts.microsooft.net',
            subject: 'URGENT: Your Office 365 account is at risk',
            html: (
                <div>
                    <div>
                        Hello Vitalijs,
                    </div>
                    <p>
                        We noticed that you were using an outdated and vulnerable version of your web browser when you
                        last logged in. For your security, we encourage you to update your browser as soon as possible.
                    </p>
                    <p>
                        You can download the latest version of the browser here:
                        <a href={'#'}> www.browser.com </a> If you have any
                        questions or concerns, feel free to contact us.
                    </p>
                    <div>
                        Sincerely, Google Team
                    </div>
                </div>
            )
        },
    ];


    const columns = [
        {
            title: intl.formatMessage({id: 'general.email'}),
            key: 'email',
            render: (_string: string, record: any) => {
                return (
                    <>
                        {record.results[0].email}
                    </>
                )

            },
        },
        {
            title: intl.formatMessage({id: 'phishing.campaign'}),
            dataIndex: 'name'
        },
        {
            title: intl.formatMessage({id: 'general.sending_mail'}),
            dataIndex: 'sending_mail',
            render: (_string: string, record: any, index: any) => {
                const mailData = sendingMails[index]

                return (
                    <>
                        {mailData.mail}
                    </>
                )
            },
        },
        {
            title: intl.formatMessage({id: 'general.subject'}),
            dataIndex: 'subject',
            render: (_string: string, record: any, index: any) => {
                const mailData = sendingMails[index]

                return (
                    <>
                        {mailData.subject}
                    </>
                )
            },
        },
        {
            title: intl.formatMessage({id: 'general.last_activity'}),
            dataIndex: 'last_activity',
            render: (_string: string, record: any) => {

                let key = (record.results[0].status).toLowerCase().replace(' ', '_')
                return (
                    <>
                        <FormattedMessage id={'phishing.action_status_' + key}/>
                    </>
                )
            },
        },
        {
            title: intl.formatMessage({id: 'general.status'}),
            dataIndex: 'status',
            render: (_string: string, record: any) => {
                let key = 'phishing.simulation';
                if (record.results[0].reported) {
                    key = 'phishing.reported';
                }

                return (
                    <>
                        <FormattedMessage id={key}/>
                    </>
                )
            },
        },
        {
            key: 'actions',
            render: (_text: string, record: any, index: any) => {
                const content = (
                    <>
                        <div className="popover-item" onClick={() => handleOpen(record, index)}>
                            <FormattedMessage id="general.open"/>
                        </div>
                        <div className="popover-item" onClick={() => handleAccept(record)}>
                            <FormattedMessage id="general.accept"/>
                        </div>
                        <div className="popover-item" onClick={() => handleDelete(record)}>
                            <FormattedMessage id="general.delete"/>
                        </div>
                    </>
                );

                return (
                    <Popover
                        open={record.id === visibleTooltip}
                        content={content}
                        trigger="click"
                        placement="bottomRight"
                        arrowPointAtCenter
                        onOpenChange={(visible) => handleVisibleChange(visible, record.id)}
                    >
                        <div style={{width: '100%', cursor: 'pointer', textAlign: 'center'}}>
                            <i className="fal fa-ellipsis-v" style={{fontSize: '16px'}}/>
                        </div>
                    </Popover>
                )
            },
        },
    ];

    const handleOpen = (record: any, index: any) => {
        setVisibleTooltip(null);
        resultInfo(record, index)
    }

    const handleAccept = (record: any) => {
        setVisibleTooltip(null);
        warnAccept(record)
    }

    const handleDelete = (record: any) => {
        setVisibleTooltip(null);
        warnDeletion(record.id);

    }

    const resultInfo = (record: any, index: any) => {
        const mailData: any = sendingMails[index]

        setViewData(
            {
                mailData,
                record
            }
        )

        setModalOpen(true)

    }

    const warnAccept = async (record: any) => {
        confirm({
            title: intl.formatMessage({id: 'general.accept'}),
            content: (
                <>
                    <label>
                        {intl.formatMessage({id: "general.add_points"})}
                    </label>
                    <TextArea/>
                </>
            ),
            icon: <ExclamationCircleOutlined/>,
            okText: intl.formatMessage({id: 'general.save'}),
            cancelText: intl.formatMessage({id: 'general.cancel'}),
            onOk() {
            }
        });
    }


    const warnDeletion = async (dataId: number) => {
        confirm({
            title: intl.formatMessage({id: 'general.attention'}),
            content: intl.formatMessage({id: 'phishing.delete_results'}),
            icon: <ExclamationCircleOutlined/>,
            okText: intl.formatMessage({id: 'general.yes'}),
            cancelText: intl.formatMessage({id: 'general.no'}),

            onOk() {
                setData(data.filter((el: any) => el.id !== dataId))
            }
        });
    }

    const getReportDate = () => {
        const timeline = viewData.record.timeline.filter((el: any) => el.message === "Email Reported")

        return timeline.length ? moment(timeline[0].time).format('DD/MM/YYYY HH:mm') : '-'
    }

    return (
        <>
            <Table columns={columns} dataSource={data}/>
            <Modal
                width={'50%'}
                open={modalOpen}
                footer={
                    <Button onClick={() => setModalOpen(false)}>
                        <FormattedMessage id='general.close'/>
                    </Button>
                }
            >
                {viewData ?
                    <div className={'results-view'}>
                        <div>
                            <label>
                                <FormattedMessage id={'general.received'}/>:
                            </label>
                            <span>{moment(viewData.record.results[0].send_date).format('DD/MM/YYYY HH:mm')}</span>
                        </div>
                        <div>
                            <label>
                                <FormattedMessage id={'general.from'}/>:
                            </label>
                            <span>{viewData.mailData.mail}</span>
                        </div>
                        <div>
                            <label>
                                <FormattedMessage id={'general.to'}/>:
                            </label>
                            <span>{viewData.record.results[0].email}</span>
                        </div>
                        <div>
                            <label>
                                <FormattedMessage id={'general.cc'}/>:
                            </label>
                            <span></span>
                        </div>
                        <div>
                            <label>
                                <FormattedMessage id={'general.subject'}/>:
                            </label>
                            <span>{viewData.mailData.subject}</span>
                        </div>
                        <br/>
                        <span>{viewData.mailData.html}</span>
                        <br/>
                        <div>
                            <label>
                                <FormattedMessage id={'general.type'}/>:
                            </label>
                            <span>
                                <FormattedMessage
                                    // viewData.record.results[0].reported ? 'phishing.reported' :
                                    id={'phishing.simulation'}/>
                            </span>
                        </div>
                        <div>
                            <label>
                                <FormattedMessage id={'phishing.reported'}/>:
                            </label>
                            <span>
                            <span>{getReportDate()}</span>
                            </span>
                        </div>
                    </div>
                    : null
                }
            </Modal>
        </>
    )
};

export default PhishingEvents;
