import React, { useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Form, Button, Col, Row, Upload, FormInstance, message, Slider, InputNumber } from 'antd';
import { Select } from 'components/Form';
import coursesApiClient from 'utils/coursesApiClient';
import { FileProperties, ScormResultSelect, ScormVersionSelect } from 'ui';
import { MaterialDataType } from 'pages/LearningMaterial/types';
import Spinner from 'components/Spinner';

const formItemLayout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 },
};

interface TestMaterialFormInterface {
    language: string;
    form: FormInstance;
    activityId: number;
    viewMaterial?: any;
    onCancel: any;
    reload: Function;
}

const materialSelectType = {
    'SCORM_TEST': <FormattedMessage id='courses.material_type_scorm' />,
};

const TestMaterialForm: React.FC<TestMaterialFormInterface> = ({
    language, form, activityId, viewMaterial, onCancel, reload
}) => {
    const [fileTitle, setFileTitle] = useState('');
    const [isVersionsLoading, setIsVersionsLoading] = useState(false);
    const [isMaterialLoading, setIsMaterialLoading] = useState(false);

    const [versionList, setVersionList] = useState<any>([]);
    const [material, setMaterial] = useState<MaterialDataType | undefined>();
    const [passRate, setPassRate] = useState<number>(50);

    const intl = useIntl();

    useEffect(() => {
        if (viewMaterial) {

            setMaterial(viewMaterial);

            form.setFieldsValue({
                materialType: viewMaterial.type,
                version: viewMaterial.version
            })

            const materialSettings = JSON.parse(viewMaterial.settings);

            const materialFileSettings = JSON.parse(viewMaterial.fileSettings);

            const title = materialFileSettings.file_title ? materialFileSettings.file_title : 'Test Activity'
            setFileTitle(title);

            if (viewMaterial.settings) {
                form.setFieldsValue({
                    expectedTestResult: materialSettings.expected_test_result,
                    passRate: materialSettings.pass_rate
                });
                setPassRate(materialSettings.pass_rate);
            }

            if (viewMaterial.fileSettings) {
                form.setFieldsValue({
                    testFileScormVersion: materialFileSettings.test_file_scorm_version
                });
            }            
        } else {
            form.setFieldsValue({
                passRate: 50
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewMaterial])

    useEffect(() => {
        form.setFieldsValue({
            materialType: 'SCORM_TEST',
        });
        getVersionList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const uploadMaterial = async (file: any) => {
        setIsMaterialLoading(true);
        try {
            let bodyFormData = new FormData();

            if (file.file) {
                bodyFormData.append('file', file.file);
            } else {
                return;
            }

            bodyFormData.append('language', language);
            bodyFormData.append('type', 'SCORM_TEST');

            let response = await coursesApiClient.request(`/api/v1/courses/material/${activityId}/add-material`, bodyFormData, 'POST');
            setFileTitle(response.material.settings.testFileTitle);

            setMaterial(response.material.settings);

            const versions = await getVersionList();

            const lastVersion: any = Object.values(versions).pop();

            form.setFieldsValue({
                version: lastVersion + ''
            });

        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        } finally {
            setIsMaterialLoading(false);
        }
    }

    const handleVersionSelect = async (version: number) => {

        setIsMaterialLoading(true);
        clearTestForm();
        try {

            const parsedValues = {
                version: version,
                type: 'SCORM_TEST',
                language: language
            }

            const response = await coursesApiClient.request(`/api/v1/courses/material/get/${activityId}`, parsedValues, 'POST');

            const responseData = response.material;
            setMaterial(responseData);
            setFileTitle(response.material.testFileTitle);

            if (responseData.settings) {
                form.setFieldsValue({
                    passRate: responseData.settings.pass_rate,
                    expectedTestResult: responseData.settings.expected_test_result,
                    testFileScormVersion: responseData.file_settings.test_file_scorm_version
                });
            }


        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        } finally {
            setIsMaterialLoading(false);
        }
    }

    const submitForm = async (values: any) => {

        if (!material) {
            return;
        }

        try {
            const parsedValues: any = {
                type: 'SCORM_TEST',
                language: language,
                settings: {
                    passRate: values.passRate,
                },
                expectedTestResult: values.expectedTestResult,
                testFileScormVersion: values.testFileScormVersion
            }

            await coursesApiClient.request(`/api/v1/courses/material/update/${material.id}`, parsedValues, 'PUT');

            onCancel();
            setFileTitle('');
            setMaterial(undefined);
            form.resetFields();
            reload(activityId);
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        }
    }

    const getVersionList = async () => {
        setIsVersionsLoading(false);

        try {
            const response = await coursesApiClient.request(`/api/v1/courses/material/${activityId}/SCORM_TEST/versions/${language}`, {}, 'GET');

            const versions: any = {};
            Object.values(response.versions).map((el: any) => [
                versions[el.version] = el.version
            ])
            setVersionList(versions);

            return versions;
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        } finally {
            setIsVersionsLoading(false);
        }
    };

    const clearTestForm = () => {
        setFileTitle('');
        setMaterial(undefined);
        form.setFieldsValue({
            expectedTestResult: [],
            testFileScormVersion: [],
            passRate: null
        });
    }

    return (
        <Spinner spinning={isMaterialLoading}>
            <Form form={form} onFinish={submitForm} preserve={false}>
                <Select
                    customLayout={formItemLayout}
                    name='materialType'
                    label={intl.formatMessage({ id: 'courses.test_file_type' })}
                    manualOptions={materialSelectType}
                />
                <Row>
                    <Col className='file-settings-title' span={formItemLayout.labelCol.span} >
                        <FormattedMessage id='courses.test_file' />
                    </Col>
                    <Col span={formItemLayout.wrapperCol.span} className='file-settings-style'>
                        <Upload
                            name='file'
                            multiple={false}
                            customRequest={(file: any) => uploadMaterial(file)}
                            fileList={[]}
                        >
                            <Button>
                                <i className="fa-solid fa-arrow-up-from-bracket"></i> <FormattedMessage id='general.upload_files' />
                            </Button>
                        </Upload>
                    </Col>
                </Row>

                <FileProperties layout={formItemLayout} showSize={false} fileProperties={{
                    title: fileTitle,
                    titleLabel: 'courses.test_file_title',
                }} />

                <ScormResultSelect layout={formItemLayout} name='expectedTestResult' label='courses.expected_test_result' customRules={[{ required: true, message: intl.formatMessage({id: 'validation.field_required'}) }]} />
                <ScormVersionSelect layout={formItemLayout} name='testFileScormVersion' label='courses.scorm_version' customRules={[{ required: true, message: intl.formatMessage({id: 'validation.field_required'}) }]} />

                <Select
                    loading={isVersionsLoading}
                    customLayout={formItemLayout}
                    label={intl.formatMessage({ id: "general.version" })}
                    name='version'
                    manualOptions={versionList}
                    onChange={(_value: any, options: any) => handleVersionSelect(options.children)}
                />

                <Row>
                    <Col span={formItemLayout.labelCol.span} className='pdf-pass-rate-label'>
                        <FormattedMessage id='general.pass_rate' />
                    </Col>
                    <Col span={formItemLayout.wrapperCol.span}>
                        <div className='pdf-pass-rate-input' >
                            <Form.Item name="passRate" label=' '>
                                <Slider
                                    min={0}
                                    max={100}
                                    value={passRate}
                                    step={1}
                                    tipFormatter={value => `${value}%`}
                                    onChange={(value: any) => { form.setFieldsValue({ passRate: value }); setPassRate(value); }}
                                    style={{ width: 100 }}
                                />
                            </Form.Item>
                            <Form.Item name="passRate" label=' ' >
                                <InputNumber
                                    min={0}
                                    max={100}
                                    step={1}
                                    value={passRate}
                                    formatter={value => `${value}%`}
                                    onChange={(value: any) => setPassRate(value)}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Spinner>
    )
}
export default TestMaterialForm;
