import React, { useEffect, useState } from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button, message, Space, Upload, Modal, Form, FormInstance, Tag, Breadcrumb, Tabs } from 'antd';
import DefaultLayout from 'components/DefaultLayout';
import FlexRow from 'components/FlexRow';
import FileDownload from 'js-file-download';
import { DownloadOutlined, ExclamationCircleOutlined, UploadOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import apiClient from 'utils/apiClient';
import coursesApiClient from 'utils/coursesApiClient';
import { connect } from 'react-redux';
import AssignmentDetail from './AssignmentDetail';
import AssignmentSubmission from './AssignmentSubmission';
import { FileTable } from 'ui';
import './styles.scss';
import Spinner from 'components/Spinner';
import useHandleError from "utils/useHandleError";
import {useDate} from 'hooks/useDate';

import moment from 'moment';

interface AssignmentFormInterface {
    locale?: any;
    session?: any;
    learningActivityId: number;
    programActivityAttemptId: number;
    type: 'learn' | 'test';
    isFirstAttempt?: boolean;
    parentActivityId?: number;
    campaignId?: string;
    language?: string;
    version?: string;
}

const mapStateToProps = (state: any) => ({
    locale: state.locale.locale,
    session: state.session
});

type ServerRequestType = {
    learningActivityId: number;
    programActivityAttemptId: number;
    type: 'learn' | 'test' | 'larning_test';
    language?: string;
    version?: string;
}

const AssignmentForm: React.FC<AssignmentFormInterface> = ({ 
    locale, 
    session,
    learningActivityId, 
    programActivityAttemptId,
    type,
    isFirstAttempt, 
    parentActivityId, 
    campaignId, 
    language,
    version
 }) => {
    const [activeTabKey, setActiveTabKey] = useState<string>('assignment_detail');
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [isAssignmentLoading, setIsAssignmentLoading] = useState(false);
    const [files, setFiles] = useState<any>();
    const [assignmentSettings, setAssignmentSettings] = useState<any>();
    const [submission, setSubmission] = useState<any>();
    const [activityFormData, setActivityFormData] = useState<any>();

    const intl = useIntl();
    const [form] = Form.useForm();

    const [handleError] = useHandleError();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (submission && ['PASSED', 'FAILED', 'MISSED_DEADLINE'].includes(submission.status)) {
            setDisableSubmit(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submission])

    useEffect(() => {
        getAssignment();
          // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

      const consumeLicenceAccess = async () => {
        try {
          const values = {
            activityId: learningActivityId,
            userTypeId: session.active_user_type_id,
            customerId: session.organization.organization_id,
            parentActivityId: parentActivityId,
            campaignId: campaignId
          }
    
          return await apiClient.request('/api/v1/licences/consume', values, 'POST');
        } catch(error) {
          message.error(intl.formatMessage({ id: 'error.data_load' }));
          console.error(error);
        }
    }

    const getAssignment = async () => {
        setIsLoading(false);
        try {
            if (isFirstAttempt) {
                await consumeLicenceAccess();
            }

            let response = await coursesApiClient.request(`api/v1/courses/assignment-results/get-assignment/${programActivityAttemptId}`, {}, 'GET');
            let settings = response.material.settings ? JSON.parse(response.material.settings) : [];

            // Get assignment deadline
            let date: any;
            if (settings?.deadline) {
                date = moment(response.enrollment.enroll_date);
                date.add(settings.deadline, 'days').toString();
            } else {
                date = moment(response.enrollment.enroll_date);
            }
            
            let submission = response.submission;
            let deadline: any = moment(date).format('YYYY-MM-DD 00:00:00')
            let isMissedDeadline = moment().startOf('day') >= moment(deadline);
            if( isMissedDeadline && submission.status === 'NOT_SUBMITTED' ) submission.status = 'MISSED_DEADLINE';
            submission.deadline = deadline;
            submission.deadline_missed = isMissedDeadline;

            setFiles(response.material.file_settings ? JSON.parse(response.material.file_settings) : [])
            setAssignmentSettings(settings)
            setSubmission(submission)
            setActivityFormData(response)
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        } finally {
            setIsLoading(false);
        }
    }

    /**
     * 
     * @param statusType 
     * @returns 
     */
    const getStatusTag = (statusType: 'VALUE' | 'TAG' = 'TAG') => {
        let status: string = submission?.status
        let color = ''
        let messageId = ''

        switch (status) {
            case 'SUBMITTED':
                color = 'blue'
                messageId = 'courses.topic_status.pending_grading'
                break;
            case 'PASSED':
                color = 'green'
                messageId = 'courses.topic_status.passed'
                break;
            case 'FAILED':
                color = 'red'
                messageId = 'courses.topic_status.failed'
                break;
            case 'MISSED_DEADLINE':
                color = 'red'
                messageId = 'courses.topic_status.missed_deadline'
                break;
            case 'RETURNED':
                color = 'red'
                messageId = 'courses.topic_status.submission_returned'
                break;
            default:
                color = 'gold'
                messageId = 'courses.topic_status.not_submitted'
                break;
        }

        if (statusType === 'TAG') {
            return (
                <Tag color={color}>
                    <FormattedMessage id={messageId} />
                </Tag>
            )
        } else if (statusType === 'VALUE') {
            return status;
        }
    }

    const getProgramTitle = (program: any) => {
        return program.title[locale] ?? program.title[Object.keys(program.title)[0]];
    }

    return (
        <Spinner spinning={isLoading}>
            {activityFormData && 
            <DefaultLayout.PageLayout withoutPageHeader>
                <div className='assignment-form-wrapper'>
                    <Breadcrumb className="breadcrumb">
                        <Breadcrumb.Item>
                            <NavLink to='../'>
                                <FormattedMessage id={'student.my_courses'} />
                            </NavLink>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <NavLink to={`/materials/${activityFormData.program.id}`}>
                                {getProgramTitle(activityFormData.program)}
                            </NavLink>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{assignmentSettings.title ? <>{assignmentSettings.title}</> : <FormattedMessage id='courses.individual_work' />}</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="flex align-center title">
                        <NavLink to='../'>
                            <ArrowLeftOutlined className="arrow"/>
                        </NavLink>
                        <h1>{assignmentSettings.title ? <>{assignmentSettings.title}</> : <FormattedMessage id='courses.individual_work' />}</h1>
                    </div>
                    <DefaultLayout.PageContent style={{background: 'none', padding: '0px'}}>
                        <div className='assignment-card'>
                            <Tabs destroyInactiveTabPane
                                activeKey={activeTabKey}
                                onTabClick={key => setActiveTabKey(key)}
                                items={[
                                    {
                                        label: intl.formatMessage({ id: 'courses.assignment_detail' }),
                                        key: 'assignment_detail',
                                        children: <AssignmentDetail 
                                            activityFormData={activityFormData} 
                                            assignmentSettings={assignmentSettings} 
                                            submission={submission} 
                                            files={files}
                                            getStatusTag={getStatusTag}
                                            setActiveTabKey={setActiveTabKey}
                                            disableSubmit={disableSubmit}
                                        />,
                                    },
                                    {
                                        label: intl.formatMessage({ id: 'courses.assignment_submission' }),
                                        key: 'assignment_submission',
                                        children: <AssignmentSubmission 
                                            activityFormData={activityFormData} 
                                            assignmentSettings={assignmentSettings} 
                                            submission={submission} 
                                            files={files}
                                            getStatusTag={getStatusTag}
                                            getAssignment={getAssignment}
                                            disableSubmit={disableSubmit}
                                        />,
                                    },
                                ]}
                            />
                        </div >
                    </DefaultLayout.PageContent>
                </div>
            </DefaultLayout.PageLayout>
        }
        </Spinner>
    )
}
export default connect(mapStateToProps)(AssignmentForm);