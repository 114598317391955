import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Tag as AntDTag} from "antd";

export type TagStatusType = TagStatus

interface TagInterface {
    status: TagStatusType
    defaultStatus?: TagStatusType
}

export enum TagStatus {
    NOT_STARTED = 'NOT_STARTED',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    PASSED = 'PASSED',
    MISSED = 'MISSED',
    CANCELLED = 'CANCELLED',
    FAILED = 'FAILED',
    PLANNED = 'PLANNED',
    ATTENDED = 'ATTENDED',
    COMPLIANT = 'COMPLIANT',
    RETURNED = 'RETURNED',
    INVITE_ENROLLMENT = 'INVITE_ENROLLMENT',
    SELF_ENROLLMENT = 'SELF_ENROLLMENT',
    NOT_REGISTERED = 'NOT_REGISTERED'
}

const Tag: React.FC<TagInterface> = ({status, defaultStatus}) => {
    const parameters = {
        [TagStatus.IN_PROGRESS]: {
            color: 'blue',
            messageId: `general.${status?.toLowerCase()}`,
        },
        [TagStatus.COMPLETED]: {
            color: 'green',
            messageId: `general.${status ? status?.toLowerCase() : defaultStatus?.toLowerCase()}`,
        },
        [TagStatus.MISSED]: {
            color: 'red',
            messageId: `general.${status ? status?.toLowerCase() : defaultStatus?.toLowerCase()}`,
        },
        [TagStatus.CANCELLED]: {
            color: 'red',
            messageId: `general.${status ? status?.toLowerCase() : defaultStatus?.toLowerCase()}`,
        },
        [TagStatus.FAILED]: {
            color: 'red',
            messageId: `general.${status ? status?.toLowerCase() : defaultStatus?.toLowerCase()}`,
        },
        [TagStatus.RETURNED]: {
            color: 'blue',
            messageId: 'courses.topic_status.submission_returned',
        },
        [TagStatus.INVITE_ENROLLMENT]: {
            color: 'blue',
            messageId: `general.${status ? status?.toLowerCase() : defaultStatus?.toLowerCase()}`,
        },
        [TagStatus.SELF_ENROLLMENT]: {
            color: 'green',
            messageId: `general.${status ? status?.toLowerCase() : defaultStatus?.toLowerCase()}`,
        },
        [TagStatus.NOT_STARTED]: {
            color: 'gold',
            messageId: `general.${status ? status?.toLowerCase() : defaultStatus?.toLowerCase()}`,
        },
        [TagStatus.PASSED]: {
            color: 'green',
            messageId: `general.${status ? status?.toLowerCase() : defaultStatus?.toLowerCase()}`,
        },
        [TagStatus.PLANNED]: {
            color: 'geekblue',
            messageId: `general.${status ? status?.toLowerCase() : defaultStatus?.toLowerCase()}`,
        },
        [TagStatus.ATTENDED]: {
            color: 'green',
            messageId: `general.${status ? status?.toLowerCase() : defaultStatus?.toLowerCase()}`,
        },
        [TagStatus.COMPLIANT]: {
            color: 'lime',
            messageId: `general.${status ? status?.toLowerCase() : defaultStatus?.toLowerCase()}`,
        },
        [TagStatus.NOT_REGISTERED]: {
            color: 'orange',
            messageId: `general.${status ? status?.toLowerCase() : defaultStatus?.toLowerCase()}`,
        }
    }

    return (
        <AntDTag color={parameters[status ? status : (defaultStatus ? defaultStatus : 'NOT_STARTED')].color}>
            <FormattedMessage
                id={parameters[status ? status : (defaultStatus ? defaultStatus : 'NOT_STARTED')].messageId}/>
        </AntDTag>
    );
};

export default Tag;