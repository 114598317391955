import React, {useState} from 'react';
import {Popover} from 'antd';
import { connect } from 'react-redux';
import { changeLocale } from 'store/actions/locale';

const mapStateToProps = (state: any) => {
    return {
      locale: state.locale.locale,
      languageDictionary : state.languageDictionary
    }
  }
  
  const mapDispatchToProps = (dispatch: any) => ({
    changeLocale: (locale: string) => dispatch(changeLocale(locale)),
  })

const LanguageSwitch: React.FC<any> = ({locale, changeLocale, languageDictionary, isMobile}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOrganizationObject, setSelectedOrganizationObject] = useState(locale);
  
  const PopoverContent = () => {
    return (
      <>
        {
          Object.entries(languageDictionary).map((entries:any) => {
            if (entries[0] !== 'loading' && entries[0] !== 'failed') {
              return (
                <div key={`c${entries[0]}`} className='select-item' style={{paddingRight: 10, paddingLeft: 10}} onClick={() => {changeLocale(entries[0]); setSelectedOrganizationObject(entries[0]); setIsOpen(false)}}>{entries[0]}</div>
              )
            }
            return null;
          })
        }
      </>
    )
  };

  return <div className={isMobile ? 'session-switch-item-mobile' : 'session-switch-item'}>
    <Popover
      placement='bottom'
      content={<PopoverContent />}
      overlayClassName='session-switch-popover'
      arrowContent={false}
      open={isOpen}
      trigger='click'
      onOpenChange={() => setIsOpen(!isOpen)}
    >
      <div className='role-switch'>
        <i className='fal fa-globe with-accent' />
        {
          <span className='display-language-name'>
            {selectedOrganizationObject}
          </span>
        }
        <i className='fal fa-chevron-down' style={{fontSize: 10}} />
      </div>
    </Popover>
  </div>
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSwitch);
