import type { Identifier, XYCoord } from 'dnd-core'
import React, {useEffect} from 'react'
import { useDrag, useDrop } from 'react-dnd'
import {DragOutlined} from '@ant-design/icons';
import ThumbPreview from "../ThumbPreview";
import { getEmptyImage } from 'react-dnd-html5-backend';

export interface SortableElementProps {
  answer: any;
  sortableElement: any;
  attemptAnswer: any;
  sortableLetter: string;
  answerLetter: string;
  id: any
  index: number;
  moveSortableElement: (dragIndex: number, hoverIndex: number) => void
  isTestOverview: boolean
  hideCorrectAnswers: boolean
}

interface DragItem {
  index: number
  id: string
  type: string
}

export const SortableElement: React.FC<SortableElementProps> = ({ 
  answer, 
  sortableElement,
  attemptAnswer, 
  sortableLetter,
  answerLetter,
  id, 
  index, 
  moveSortableElement, 
  isTestOverview,
  hideCorrectAnswers
}) => {
  const staticRef = React.useRef<HTMLDivElement>(null)
  const sortableRef = React.useRef<HTMLDivElement>(null)
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: 'sortable-element',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem, monitor) {
      if (!sortableRef.current) {
        return
      }
      
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = sortableRef.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      moveSortableElement(dragIndex, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag, dragPreview] = useDrag({
    type: 'sortable-element',
    item: () => {
      return { 
        answer,
        sortableElement,
        attemptAnswer,
        sortableLetter,
        answerLetter,
        id,
        index,
        width: sortableRef.current ? sortableRef.current.clientWidth : 0,
        height: sortableRef.current ? sortableRef.current.clientHeight : 0
      }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1

  if( ! isTestOverview ) {
    drag(drop(sortableRef))
  }

  useEffect(() => {
      dragPreview(getEmptyImage())
  }, []);

  return (
    <div className='ma-answer-row' ref={sortableRef}>
      {!hideCorrectAnswers && isTestOverview && attemptAnswer !== undefined && attemptAnswer.is_correct ? <i className='fa fa-check correct-answer' /> : null}
      <div className='ma-static-item-wrapper'>
        <div ref={staticRef} className='ma-answer-item ma-static-item'>
          {answer.title && <span className='ma-answer-text'><span>{answer.title}</span></span>}
          {answer?.storageFiles?.thumbnail?.fileId && <ThumbPreview
              fileInfo={answer?.storageFiles?.thumbnail}
          />}
          {!hideCorrectAnswers && isTestOverview && <span className='ma-answer-number'>{sortableLetter}</span>}
        </div>
      </div>
      <div  style={{ opacity }} data-handler-id={handlerId} className='ma-answer-item ma-sortable-item'>
        {!isTestOverview && <span className='drag-handle'><DragOutlined style={{ cursor: 'grab', color: '#19A9CE' }} /></span>}
        {!hideCorrectAnswers && isTestOverview && <span className='ma-answer-number'>{answerLetter}</span>}
        {sortableElement.description && <span className='ma-answer-text'><span>{sortableElement.description}</span></span>}
        {sortableElement?.storageFiles?.thumbnail2?.fileId && <ThumbPreview
            fileInfo={sortableElement?.storageFiles?.thumbnail2}
        />}
      </div>
    </div>
  )
}
