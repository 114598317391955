import {useState} from 'react'
import useHandleError from 'utils/useHandleError';
import apiClient from 'utils/apiClient';
import {LectureNotificationsProps, LectureProps} from 'components/Lectures/types';
import {SessionLectorProps} from '../../components/Lectures/types';
import {UploadFile} from 'antd';
import {RcFile} from 'antd/lib/upload';
import generalHelpers from "../../utils/generalHelpers";

export const useLecture = () => {
    const [handleError] = useHandleError();
    const [lectureLoading, setLectureLoading] = useState<boolean>(false)

    const getLectures = async (): Promise<LectureProps[] | null> => {
        setLectureLoading(true);

        try {
            const response = await apiClient.request('api/v1/courses/lectures/search', [], 'GET');

            if (!response) {
                return null;
            }
            
            if ('error' in response) {
                throw response;
            }

            if (!generalHelpers.isEmpty(response)) {
                return response;
            }

            return null;
        } catch (error) {
            handleError(error);
            return null;
        } finally {
            setLectureLoading(false);
        }
    }

    const getLectureById = async (lectureId: number): Promise<LectureProps | null> => {
        setLectureLoading(true);

        try {
            const response = await apiClient.request(`/api/v1/courses/lectures/lecture/${lectureId}`, [], 'GET');

            if ('error' in response) {
                throw response;
            }
            ;

            if ('lecture' in response) {
                const lecture = response.lecture;
                lecture.isEditable = lecture.actions?.edit;
                delete lecture.actions;

                return lecture;
            }
            ;

            return null;
        } catch (error) {
            handleError(error);
            return null;
        } finally {
            setLectureLoading(false);
        }
    }

    const searchLectures = async (q: string) => {
        setLectureLoading(true)
        try {
            let requestUrl: string = 'api/v1/courses/lectures/search'

            requestUrl = generalHelpers.urlBuilder(requestUrl, q, 'q')

            const response = await apiClient.request(requestUrl, [], 'GET');

            if (response) {
                if (response) {
                    return response;
                } else {
                    return response;
                }
            }
        } catch (error) {
            handleError(error);
        } finally {
            setLectureLoading(false)
        }
    }

    const searchLectors= async (q: string) => {
        setLectureLoading(true)
        try {
            let requestUrl: string = '/api/v1/edu/users/search-lector?key=id'

            requestUrl = generalHelpers.urlBuilder(requestUrl, q, 'q')

            const response = await apiClient.request(requestUrl, [], 'GET');

            if (response) {
                if (response) {
                    return response;
                } else {
                    return response;
                }
            }
        } catch (error) {
            handleError(error);
        } finally {
            setLectureLoading(false)
        }
    }

    const searchLectorsDepartments= async (q: string) => {
        setLectureLoading(true)
        try {
            let requestUrl: string = '/api/v1/edu/users/lectors/departments'

            requestUrl = generalHelpers.urlBuilder(requestUrl, q, 'q')

            const response = await apiClient.request(requestUrl, [], 'GET');

            if (response) {
                if (response) {
                    return response;
                } else {
                    return response;
                }
            }
        } catch (error) {
            handleError(error);
        } finally {
            setLectureLoading(false)
        }
    }

    const saveLecture = async (lectureData: LectureProps): Promise<LectureProps | null> => {
        setLectureLoading(true);

        const lecture: any = {
            id: lectureData.id,
            title: lectureData.title,
            description: lectureData.description,
            completedType: lectureData.completedType,
            customFields: lectureData.customFields,
        };

        if (!lecture.id && lectureData.programId) {
            lecture.programId = lectureData.programId;
        }

        try {
            const response = lecture.id ?
                await apiClient.request(`api/v1/courses/lectures/${lecture.id}`, lecture, 'PUT')
                : await apiClient.request(`api/v1/courses/lectures`, (({id, ...object}) => object)(lecture), 'POST');

            if ('error' in response) {
                throw response;
            }
            ;

            if ('lecture' in response) {
                return response.lecture;
            }
            ;

            return null;
        } catch (error) {
            handleError(error);
            return null;
        } finally {
            setLectureLoading(false);
        }
    }

    const deleteLecture = async (lectureId: number): Promise<boolean | null> => {
        setLectureLoading(true);

        try {
            const response = await apiClient.request(`api/v1/courses/lectures/${lectureId}`, [], 'DELETE');

            if ('error' in response) {
                throw response;
            }
            ;

            if ('deleted' in response) {

                if (!response.deleted) {
                    handleError();
                }

                return response.deleted;
            }
            ;

            return null;
        } catch (error) {
            handleError(error);
            return null;
        } finally {
            setLectureLoading(false);
        }
    }

    const addLectureToLearningProgram = async (programId: number, lecturesIds: []): Promise<boolean | null> => {
        setLectureLoading(true);

        try {
            const response = await apiClient.request(`/api/v1/courses/learning-program/materials/${programId}/add`, {lectures: lecturesIds}, 'POST');

            if ('error' in response) {
                throw response;
            }
            ;

            return !!response;
        } catch (error) {
            handleError(error);
            return null;
        } finally {
            setLectureLoading(false);
        }
    }

    const getLectors = async (): Promise<SessionLectorProps[]> => {
        setLectureLoading(true);

        try {
            const response = await apiClient.request('/api/v1/edu/users/search-lector?key=id', [], 'GET');
            
            if (!response) {
                return [];
            };

            if ('error' in response) {
                throw response;
            };

            const result: SessionLectorProps[] = [];

            if (typeof response === 'object' && response !== null) {
                Object.entries(response).forEach(option => {
                    const [key, value] = option;

                    if (key) {
                        result.push({
                            id: +key,
                            name: String(value),
                        })
                    }
                });
            }

            return result;
        } catch (error) {
            handleError(error);
            return [];
        } finally {
            setLectureLoading(false);
        }
    }

    const getLectureFiles = async (lectureId: number): Promise<UploadFile[]> => {
        setLectureLoading(true);

        try {
            const response = await apiClient.request(`/api/v1/courses/lectures/${lectureId}/files`, [], 'GET');

            if (response && response.data && Array.isArray(response.data)) {
                return response.data as UploadFile[];
            }

            throw response;
        } catch (error) {
            handleError(error);
            return [];
        } finally {
            setLectureLoading(false);
        }
    }

    const storeLectureFile = async (lectureId: number, file: RcFile): Promise<number | undefined> => {
        setLectureLoading(true);

        try {
            const bodyFormData = new FormData();
            bodyFormData.append('file', file);
            const response = await apiClient.request(`/api/v1/courses/lectures/${lectureId}/files`, bodyFormData, 'POST');

            if (!!response && typeof response === 'object' && !!response.id) {
                return response.id;
            }

            throw response;

        } catch (error) {
            handleError(error);
        } finally {
            setLectureLoading(false);
        }
    }


    const deleteLectureFile = async (lectureId: number, id: number): Promise<boolean> => {
        setLectureLoading(true);

        try {
            const response = await apiClient.request(`/api/v1/courses/lectures/${lectureId}/files/${id}`, [], 'DELETE');

            if (!!response && !!response.deleted) {
                return true;
            }

            throw response;

        } catch (error) {
            handleError(error);
            return false;
        } finally {
            setLectureLoading(false);
        }
    }

    const downloadLectureFile = async (lectureId: number, fileId: number): Promise<null | Blob> => {
        setLectureLoading(true);

        try {
            const response = await apiClient.request(`/api/v1/courses/lectures/${lectureId}/files/${fileId}`, [], 'GET', true, true);
            if (response && response.type) {
                if (response.type === 'application/json') {
                    const jsonResponse = await response.text();

                    if ('error' in jsonResponse) {
                        throw jsonResponse;
                    }
                    ;

                    return response;
                }

                return response;
            }

            throw new Error();

        } catch (error) {
            handleError(error);
            return null;
        } finally {
            setLectureLoading(false);
        }
    }

    const getLectureNotifications = async (): Promise<LectureNotificationsProps> => {
        setLectureLoading(true);

        try {
            const response = await apiClient.request(`/api/v1/courses/lectures/notifications`, [], 'GET');

            if ('error' in response) {
                throw response;
            };

            if ('data' in response) {
                return response.data;
            };

            return {};
        } catch (error) {
            handleError(error);
            return {};
        } finally {
            setLectureLoading(false);
        }
    }

    return {
        lectureLoading,
        getLectures,
        saveLecture,
        deleteLecture,
        getLectureById,
        addLectureToLearningProgram,
        getLectors,
        getLectureFiles,
        storeLectureFile,
        deleteLectureFile,
        downloadLectureFile,
        getLectureNotifications,
        searchLectures,
        searchLectors,
        searchLectorsDepartments
    }
}