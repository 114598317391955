import { useState } from 'react'
import useHandleError from 'utils/useHandleError';
import apiClient from 'utils/apiClient';
import generalHelpers from "../../utils/generalHelpers";

export const useAudience = () => {
    const [handleError] = useHandleError();
    const [audienceLoading, setAudienceLoading] = useState<boolean>(false)

    const deleteAudienceRuleSet = async (ruleSetId: number): Promise<boolean | null> => {
        setAudienceLoading(true);

        try {
            const response = await apiClient.request(`/api/v1/audiences/rule-sets/${ruleSetId}`, [], 'DELETE');

            if ("error" in response) {
                throw response;
            };

            if ("deleted" in response) {

                if (!response.deleted) {
                    handleError();
                }

                return response.deleted;
            };

            return null;
        } catch (error) {
            handleError(error);
            return null;
        } finally {
            setAudienceLoading(false);
        }
    }

    const searchAudiences= async (q: string) => {
        setAudienceLoading(true)
        try {
            let requestUrl: string = '/api/v1/audiences/options/list'

            requestUrl = generalHelpers.urlBuilder(requestUrl, q, 'q')

            const response = await apiClient.request(requestUrl, [], 'GET');

            if (response) {
                if (response) {
                    return response;
                } else {
                    return response;
                }
            }
        } catch (error) {
            handleError(error);
        } finally {
            setAudienceLoading(false)
        }
    }

    return {
        audienceLoading,
        deleteAudienceRuleSet,
        searchAudiences
    }
}