import React, {useState, useEffect} from 'react';
import coursesApiClient from 'utils/coursesApiClient';
import {Modal, Radio} from "antd";
import Spinner from 'components/Spinner';
import useHandleError from 'utils/useHandleError';
import './styles.scss';
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {useIntl} from "react-intl";

interface CertificateBackgroundInterface {
    id: any;
    setBackground: React.Dispatch<React.SetStateAction<any>>
    reload?: Function;
    canDelete?: boolean;
    cert?: any;
}

const CertificateBackground: React.FC<CertificateBackgroundInterface> = ({cert, id, setBackground, reload, canDelete= false}) => {
    const [backgroundImage, setBackgroundImage] = useState<any>();
    const [isLoading, setIsLoading] = useState(true);
    const [handleError] = useHandleError();

    const {confirm} = Modal;
    const intl = useIntl();

    useEffect(() => {
        loadImage();
    }, [])

    useEffect(() => {
        if (cert && cert?.backgroundId === id && !!backgroundImage) {
            setBackground(backgroundImage)
        }
    }, [cert, backgroundImage])

    const loadImage = async () => {
        try {
            const certificate = await coursesApiClient.request(`/api/v1/courses/learning-program/certificates/${id}/background`, {}, 'GET', true, true);
            setBackgroundImage(URL.createObjectURL(certificate));

        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false);
        }
    };

    const warnDeletion = async () => {
        confirm({
            title: intl.formatMessage({id: 'general.attention'}),
            content: intl.formatMessage({id: 'program.certificate_background_delete'}),
            icon: <ExclamationCircleOutlined/>,
            okText: intl.formatMessage({id: 'general.yes'}),
            cancelText: intl.formatMessage({id: 'general.no'}),

            onOk() {
                handleDelete();
            }
        });
    }

    const handleDelete = async () => {
        try {
            await coursesApiClient.request(`/api/v1/courses/learning-program/certificates/${id}/background`, {}, 'DELETE');

            if (reload) {
                reload(true);
            }

        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Spinner spinning={isLoading}>
            <Radio.Button key={`key_${id}`} onChange={() => setBackground(backgroundImage)} value={id}>
                <span>
                    <img src={backgroundImage} alt='background-image'/>
                    {canDelete &&
                      <i onClick={()=> warnDeletion()} className="delete-icon fa-solid fa-trash fa-xl"></i>
                    }
                </span>
            </Radio.Button>
        </Spinner>
    )
};

export default CertificateBackground;
