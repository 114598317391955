import React, {useState, useEffect} from 'react';
import {AccessForm} from "../../AcessForm";
import generalHelpers from "../../../utils/generalHelpers";

interface CompetencesAccessRightsInterface {
    competenceId: any;
    competence: any;
}

const CompetencesAccessRights: React.FC<CompetencesAccessRightsInterface> = ({competenceId, competence}: any) => {
    const [targetKeys, setTargetKeys] = useState<string[]>([]);

    useEffect(() => {
        if (competence) {
            setFormItems()
        }
    }, [competence]);

    const setFormItems = () => {

        const customers = competence.customers.filter((el: any) => el.customerId);

        const target = generalHelpers.stringifyArray(generalHelpers.pluck(customers, 'customerId'));

        setTargetKeys(target)
    }

    return (
        <>
            {competenceId &&
                <>
                    <AccessForm
                        initialTargetKeys={targetKeys}
                        transferTitle={'competences.organization_permissions'}
                        tableTitle={'competences.users_management_access'}
                        url={
                            {
                                organizationUpdate: `/api/v1/competences/${competenceId}/organizations`,
                                C: `/api/v1/competences/admins/${competenceId}`,
                                R: `/api/v1/competences/admins/${competenceId}`,
                                U: `/api/v1/competences/admins/{id}/access`,
                                D: ``
                            }
                        }
                    />
                    {/*<Title font='14px'>*/}
                    {/*  <FormattedMessage id='competences.organization_permissions'/>*/}
                    {/*</Title>*/}
                    {/*<Form.Item label=' ' colon={false}>*/}
                    {/*  <Transfer*/}
                    {/*    dataSource={customers}*/}
                    {/*    showSearch*/}
                    {/*    targetKeys={targetKeys}*/}
                    {/*    onChange={handleChange}*/}
                    {/*    render={item => item.name}*/}
                    {/*  />*/}
                    {/*</Form.Item>*/}
                    {/*<Title font='14px'>*/}
                    {/*  <FormattedMessage id='competences.role_access'/>*/}
                    {/*</Title>*/}
                    {/*<Title font='14px'>*/}
                    {/*  <FormattedMessage id='competences.users_management_access'/>*/}
                    {/*</Title>*/}
                    {/*<CompetenceAccessTable customers={targetKeys}/>*/}
                </>
            }
        </>
    )
};

export default CompetencesAccessRights;