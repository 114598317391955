import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Modal, Form, Radio, Select as AntDSelect} from 'antd';
import {Select} from 'components/Form';
import useHandleError from 'utils/useHandleError';
import {config} from 'config/config';
import {connect} from "react-redux";
import generalHelpers from "utils/generalHelpers";
import apiClient from "utils/apiClient";

interface MailScenariosFilterModalInterface {
    visible: boolean;
    onCancel: React.Dispatch<React.SetStateAction<boolean>>;
    setFilter: React.Dispatch<React.SetStateAction<string>>;
    reload: React.Dispatch<React.SetStateAction<boolean>>;
    session?: any;
    locale?: any;
}

const mapStateToProps = (state: any) => {
    return {
        session: state.session,
        locale: state.locale
    }
};

const MailScenariosFilterModal: React.FC<MailScenariosFilterModalInterface> = ({
                                                                                   visible,
                                                                                   onCancel,
                                                                                   setFilter,
                                                                                   reload,
                                                                                   session,
                                                                                   locale
                                                                               }) => {
    const intl = useIntl();
    const [form] = Form.useForm();
    const [handleError] = useHandleError();
    const [filterBy, setFilterBy] = useState('CUSTOMER');
    const [organizationId, setOrganizationId] = useState();
    const [templates, setTemplates] = useState([]);
    const [isTemplateLoading, setIsTemplateLoading] = useState(false);


    useEffect(() => {
        form.setFieldsValue({filterBy: 'CUSTOMER'})

    }, [])


    const submitForm = async (values: any) => {
        const defaultValues = ['category', 'templateId', 'customerId', 'organizationId', 'filterBy'];

        try {
            let filter = '';

            Object.entries(values).map((el: any) => {
                if (defaultValues.includes(el[0])) {
                    if (Array.isArray(el[1]) && el[1].length && el[1] && el[0]) {
                        return (filter += `&filter[${el[0]}]=${el[1]}`);
                    } else if (!Array.isArray(el[1]) && el[1] && el[0]) {
                        return (filter += `&filter[${el[0]}]=${el[1]}`);
                    }
                } else {
                    if (Array.isArray(el[1]) && el[1].length && el[1] && el[0]) {
                        return (filter += `&customerFilter[${el[0]}]=${el[1]}`);
                    } else if (!Array.isArray(el[1]) && el[1] && el[0]) {
                        return (filter += `&customerFilter[${el[0]}]=${el[1]}`);
                    }
                }
            });

            setFilter(filter);
            reload(true);
            onCancel(false);
        } catch (error) {
            handleError(error)
        }
    }

    const displayOrganization = (type: 'CUSTOMER' | 'ORGANIZATION') => {
        let display = false;

        if (generalHelpers.isAdmin(session.active_user_type) && filterBy === type) {
            display = true
        }

        return display;
    }

    const loadEmailTemplates = async (categoryId: number) => {
        setIsTemplateLoading(true);
        form.setFieldsValue({templateId: null});
        try {
            let url

            switch (filterBy) {
                case 'ORGANIZATION':
                case 'RESELLER':
                case 'DISTRIBUTOR':
                    url = `/api/v1/organizations/${organizationId}/mail-templates/${categoryId}/options`
                    break;
                case 'CUSTOMER':
                    url = `/api/v1/customers/${organizationId}/mail-templates/${categoryId}/options`
                    break;
            }

            const response = await apiClient.request(url, 'GET');
            setTemplates(response.mailTemplates)
        } catch (error) {
            handleError(error);
        } finally {
            setIsTemplateLoading(false);
        }
    }
    const clearForm = () => {
        form.setFieldsValue({
            customerId: null,
            organizationId: null,
            category: null,
            templateId: null
        })
    }

    return (
        <Modal
            className="user-list-modal"
            open={visible}
            title={intl.formatMessage({id: 'emailing.scenarios.filter_e_mailing_events'})}
            onOk={() => form.submit()}
            onCancel={() => onCancel(false)}
            okText={intl.formatMessage({id: 'general.filter'})}
            cancelText={intl.formatMessage({id: 'general.cancel'})}
        >
            <Form form={form} onFinish={submitForm}>
                {generalHelpers.isAdmin(session.active_user_type) &&
                  <Form.Item
                    name="filterBy"
                    label={intl.formatMessage({id: 'general.filter_by'})}
                  >
                    <Radio.Group onChange={(el: any) => {
                        clearForm()
                        setFilterBy(el.target.value)
                    }}>
                      <Radio value="CUSTOMER"><FormattedMessage id='general.customer'/></Radio>
                      <Radio value="ORGANIZATION"><FormattedMessage id='general.reseller'/></Radio>
                    </Radio.Group>
                  </Form.Item>
                }
                {displayOrganization('CUSTOMER') &&
                  <Select
                    allowClear
                    showSearch
                    name='customerId'
                    dataKey='customers'
                    onChange={(id: any) => setOrganizationId(id)}
                    label={intl.formatMessage({id: "general.customer"})}
                    url={config.api.routes.backend.selectCustomers}
                    integerKey={true}
                    customLayout={true}
                    dropdownStyle={{minWidth: "385px"}}
                    filterOption={(input: string, option: any) => {
                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                    customRules={[{required: true, message: intl.formatMessage({id: "validation.field_required"})}]}
                  />
                }
                {displayOrganization('ORGANIZATION') &&
                  <Select
                    allowClear
                    showSearch
                    name='organizationId'
                    dataKey='organizations'
                    onChange={(id: any) => setOrganizationId(id)}
                    label={intl.formatMessage({id: "general.reseller"})}
                    url={config.api.routes.backend.selectOrganizations}
                    integerKey={true}

                    customLayout={true}
                    dropdownStyle={{minWidth: "385px"}}
                    filterOption={(input: string, option: any) => {
                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                    customRules={[{required: true, message: intl.formatMessage({id: "validation.field_required"})}]}
                  />
                }

                <Select
                    className='send-scenario-modal-select'
                    name='category'
                    showArrow
                    label={intl.formatMessage({id: "emailing.scenarios.e_mail_category"})}
                    url='/api/v1/mail-templates/options/categories'
                    integerKey={true}
                    customObjLabel={(el: string) => intl.formatMessage({id: `email_templates.categories.${el}`})}
                    onChange={(value: number) => loadEmailTemplates(value)}
                    withSystemName={true}
                    rules={[{
                        required: true,
                        message: intl.formatMessage({id: "validation.field_required"})
                    }]}
                    customLayout
                />
                <Form.Item
                    className='scenario-email-required'
                    name='templateId'
                    label={intl.formatMessage({id: 'emailing.scenarios.email_template'})}
                >
                    <AntDSelect showArrow loading={isTemplateLoading} className='default-select'>
                        {templates.map((el: any) =>
                            <AntDSelect.Option
                                value={parseInt(el.id)}>{el.name[locale] ? el.name[locale] : Object.values(el.name)[0]}</AntDSelect.Option>
                        )}
                    </AntDSelect>
                </Form.Item>
            </Form>
        </Modal>
    )
};

export default connect(mapStateToProps)(MailScenariosFilterModal);
