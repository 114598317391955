import {AudienceRuleData, AudienceRuleFunction} from 'components/Audiences/types';
import React, {useEffect, useState} from 'react';
import {Space} from 'antd';
import {useIntl} from 'react-intl';
import {Select, Input, Departments} from 'components/Form';
import {config} from 'config/config';
import {connect} from 'react-redux';


const mapStateToProps = (state: any) => {
    return {
        session: state.session,
    }
}

const AudienceRuleFormItems: React.FC<any> = ({
                                                  audience,
                                                  customFields,
                                                  selectedCustomerId,
                                                  setSelectedCustomerId,
                                                  session,
                                                  ruleSetId,
                                                  fields,
                                                  form,
                                                  remove,
                                                  key,
                                                  name,
                                                  ...restField
                                              }) => {
    const [data, setData] = useState<string>();
    const [funct, setFunct] = useState<string>();
    const [value, setValue] = useState<string>();
    const [orgUnitUrl, setOrgUnitUrl] = useState<string>();
    const [orgUnit, setOrgUnit] = useState(null)
    const [department, setDepartment] = useState(null)
    const intl = useIntl();

    const [dataOptions, setDataOptions] = useState({
        [AudienceRuleData.UserPosition]: intl.formatMessage({id: 'general.position'}),
        [AudienceRuleData.UserCustomer]: intl.formatMessage({id: 'general.customer'}),
        [AudienceRuleData.UserOrganizationalUnit]: intl.formatMessage({id: 'general.unit'}),
        [AudienceRuleData.Department]: intl.formatMessage({id: 'general.department'}),
    })

    useEffect(() => {
        if (customFields.length) {
            Object.values(customFields).map((el: any) => {
                setDataOptions(dataOptions => ({...dataOptions, [el.uuid]: el.title}));
            })
        }

    }, [customFields]);

    useEffect(() => {

        if (department) {
            const groupValues = form.getFieldValue(['rulesGroup', ruleSetId, 'rules'])[restField.fieldKey];
            if (department && groupValues.data === 'DEPARTMENT') {
                if (!department[groupValues.value]) {
                    const currentRules = form.getFieldValue(['rulesGroup', ruleSetId, 'rules']);

                    currentRules[restField.fieldKey].value = '0';

                    form?.setFieldsValue({
                        rulesGroup: {
                            [ruleSetId]: {
                                rules: [...currentRules]
                            }
                        }
                    });


                }
            }

            if (department && groupValues.data === 'USER_ORGANIZATIONAL_UNIT') {
                if (!department[groupValues.value]) {
                    const currentRules = form.getFieldValue(['rulesGroup', ruleSetId, 'rules']);

                    currentRules[restField.fieldKey].value = '0';

                    form?.setFieldsValue({
                        rulesGroup: {
                            [ruleSetId]: {
                                rules: [...currentRules]
                            }
                        }
                    });
                }
            }
        }

    }, [department, orgUnit]);


    useEffect(() => {
        const groupValues = form.getFieldValue(['rulesGroup', ruleSetId, 'rules'])[restField.fieldKey];

        if (groupValues) {
            const {data, value} = groupValues;
            const funct = groupValues['function'];

            setData(data);
            setFunct(funct);
            setValue(value);

            if (data === AudienceRuleData.UserCustomer) {
                setSelectedCustomerId(value);
            }

        }

        setOrgUnitUrl(getOrgUitUrl());

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setOrgUnitUrl(getOrgUitUrl());

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCustomerId]);


    const functionOptions = {
        [AudienceRuleFunction.Contains]: intl.formatMessage({id: 'audience.contains'}),
        [AudienceRuleFunction.DoesNotContain]: intl.formatMessage({id: 'audience.does_not_contain'}),
        [AudienceRuleFunction.EqualTo]: intl.formatMessage({id: 'audience.equal_to'}),
        [AudienceRuleFunction.NotEqualTo]: intl.formatMessage({id: 'audience.not_equal_to'}),
        [AudienceRuleFunction.StartsWith]: intl.formatMessage({id: 'audience.starts_with'}),
        [AudienceRuleFunction.EndsWith]: intl.formatMessage({id: 'audience.ends_with'}),
        [AudienceRuleFunction.Before]: intl.formatMessage({id: 'audience.before'}),
        [AudienceRuleFunction.After]: intl.formatMessage({id: 'audience.after'}),
        [AudienceRuleFunction.Boolean]: intl.formatMessage({id: 'audience.boolean'}),
    };

    const filterOperators = () => {
        if (data) {
            return Object.fromEntries(Object.entries(functionOptions).filter(([key]) => {
                switch (data) {
                    case AudienceRuleData.UserPosition:
                        return [
                            AudienceRuleFunction.Contains.toString(),
                            AudienceRuleFunction.DoesNotContain.toString(),
                            AudienceRuleFunction.EqualTo.toString(),
                            AudienceRuleFunction.NotEqualTo.toString(),
                            AudienceRuleFunction.Empty.toString(),
                            AudienceRuleFunction.StartsWith.toString(),
                            AudienceRuleFunction.EndsWith.toString(),
                        ].includes(key);
                    case AudienceRuleData.UserCustomer:
                    case AudienceRuleData.UserOrganizationalUnit:
                    case AudienceRuleData.Department:
                        return [
                            AudienceRuleFunction.EqualTo.toString(),
                            AudienceRuleFunction.NotEqualTo.toString(),
                        ].includes(key);
                    default:
                        return [
                            AudienceRuleFunction.Contains.toString(),
                            AudienceRuleFunction.DoesNotContain.toString(),
                            AudienceRuleFunction.EqualTo.toString(),
                            AudienceRuleFunction.NotEqualTo.toString(),
                            AudienceRuleFunction.Empty.toString(),
                            AudienceRuleFunction.StartsWith.toString(),
                            AudienceRuleFunction.EndsWith.toString(),
                        ].includes(key);
                }
            }));
        }

        return functionOptions;
    }

    const operators = filterOperators();

    const getOrgUitUrl = () => {
        if (session.active_user_type === 'CUSTOMER_ADMIN') {
            return '/api/v1/customers/options/organizational-unit-types';
        }

        let url: string = '';

        if (selectedCustomerId) {
            url = '/api/v1/customers/options/organizational-unit-types';
            if (
                session.active_user_type === 'SUPER_ADMIN' ||
                session.active_user_type === 'RESELLER_ADMIN' ||
                session.active_user_type === 'DISTRIBUTOR_ADMIN'
            ) {
                url = url + '?customerId=' + selectedCustomerId + '&isUuid=true';
            }
        }

        return url;
    }

    const handleDataChange = (value: any, options: any) => {
        setData(value);
        findAndSetCustomerId();

        const currentRules = form.getFieldValue(['rulesGroup', ruleSetId, 'rules']);

        currentRules[restField.fieldKey].value = undefined;

        form?.setFieldsValue({
            rulesGroup: {
                [ruleSetId]: {
                    rules: [...currentRules]
                }
            }
        });
    }

    const findAndSetCustomerId = () => {
        const addRulSetRules = form.getFieldValue(['rulesGroup', ruleSetId, 'rules']);

        const ruleSetCustomer = addRulSetRules.map((rule: any) => {
            if (rule.data === AudienceRuleData.UserCustomer) {
                return rule.value;
            }

            return undefined;
        }).filter((element: any) => {
            return element !== undefined;
        })[0];

        setSelectedCustomerId(ruleSetCustomer);
    }

    function checkIfValidUUID(str?: string) {
        if (!str) {
            return false
        }
        const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

        return regexExp.test(str);
    }

    return (
        <Space key={key} className="audience-rule-set" align="baseline">
            <div>#{restField.fieldKey + 1}</div>
            <Space className="audience-rule-set-item">
                <Select customLayout={{wrapperCol: {span: 24}}}
                        name={[name, 'data']}
                        manualOptions={dataOptions}
                        onChange={(value: any, options: any) => handleDataChange(value, options)}
                        disabled={!audience.isEditable()}
                        {...restField}
                />

                <Select customLayout={{wrapperCol: {span: 24}}}
                        name={[name, 'function']}
                        manualOptions={operators}
                        disabled={!audience.isEditable()}
                        {...restField}
                />

                {checkIfValidUUID(data) &&
                    <Input customLayout={{wrapperCol: {span: 24}}}
                           name={[name, 'value']}
                           disabled={!audience.isEditable()}
                           {...restField}
                    />}

                {(!data || data === AudienceRuleData.UserPosition) &&
                    <Input customLayout={{wrapperCol: {span: 24}}}
                           name={[name, 'value']}
                           disabled={!audience.isEditable()}
                           {...restField}
                    />}

                {data === AudienceRuleData.UserCustomer && <Select
                    name={[name, 'value']}
                    allowClear
                    showSearch
                    dataKey='customers'
                    url={config.api.routes.backend.selectCustomersUuid}
                    customLayout={{wrapperCol: {span: 24}}}
                    dropdownStyle={{minWidth: "385px"}}
                    filterOption={(input: string, option: any) => {
                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                    onChange={(value: any, options: any) => setSelectedCustomerId(options.value)}
                    disabled={!audience.isEditable()}
                    {...restField}
                />}

                {data === AudienceRuleData.UserOrganizationalUnit && <Select
                    name={[name, 'value']}
                    allowClear
                    showSearch
                    dataKey='organizationalUnits'
                    url={orgUnitUrl}
                    saveLoadedData={true}
                    setLoadedData={setOrgUnit}
                    customLayout={{wrapperCol: {span: 24}}}
                    dropdownStyle={{minWidth: "385px"}}
                    disabled={!audience.isEditable()}
                    {...restField}
                />}
                {data === AudienceRuleData.Department &&
                    <Departments
                        name={[name, 'value']}
                        isUuid
                        setLoadedData={setDepartment}
                        hideLabel
                        canCreate={false}
                        customerId={selectedCustomerId ? selectedCustomerId : ''}
                    />}
            </Space>

            {audience.isEditable() ? <i onClick={() => remove(name)} style={{opacity: .5, cursor: 'pointer'}}
                                        className="fa-regular fa-trash"></i> : <div></div>}

        </Space>
    );
};

export default connect(mapStateToProps)(AudienceRuleFormItems);
