import React, {useState, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {config} from 'config/config';
import DefaultLayout from "components/DefaultLayout";
import useHandleError from "utils/useHandleError";
import {Button, Checkbox, Radio, Form, Input as AntDInput, Row, Col, Popover, Divider, Space, Tag} from 'antd';
import {Input, Select, Switch, DatePicker, InputNumber, RequestSelect, Departments} from 'components/Form';
import apiClient from "utils/apiClient";
import Spinner from "components/Spinner";
import moment from 'moment';
import './styles.scss';

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale
    };
};

const formItemLayout = {
    labelCol: {span: 24},
    wrapperCol: {span: 24},
};

const GeneralMessageEdit: React.FC<any> = ({locale}) => {
    const [generalMessage, setGeneralMessage] = useState<any>();
    const [isDisplayEveryone, setIsDisplayEveryone] = useState<boolean>(false);
    const [organizations, setOrganizations] = useState<any[]>([]);
    const [allOrganizationUnits, setAllOrganizationUnits] = useState<any[]>([]);
    const [allDepartments, setAllDepartments] = useState<any[]>([]);
    const [selectedOrganizationUnits, setSelectedOrganizationUnits] = useState<any[]>([]);
    const [selectedDepartments, setSelectedDepartments] = useState<any[]>([]);
    const [selectedOrganizationIndex, setSelectedOrganizationIndex] = useState<any>(null);
    const [displayType, setDisplayType] = useState<string>('EVERYONE');
    
    const intl = useIntl();
    const params = useParams();
    const [handleError] = useHandleError();
    const navigate = useNavigate();
    const generalMessageId = params.id;
    const [form] = Form.useForm();
    const {TextArea} = AntDInput;
    const {Search} = AntDInput;
    const typeOptions = {
        INFO: intl.formatMessage({id: 'general_messaging.type.info'}), 
        ALERT: intl.formatMessage({id: 'general_messaging.type.alert'})
    };
    const displayTypeOptions: any = {
        EVERYONE: intl.formatMessage({id: 'general_messaging.everyone'}), 
        ORGANIZATION_UNIT: intl.formatMessage({id: 'general_messaging.selected_units'})
    };
    const [isLoading, setIsLoading] = useState(!!generalMessageId);
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);

    useEffect(() => {
        if (generalMessageId) {
            loadGeneralMessage()
            getOrganizations(generalMessageId)
        } else {
            getOrganizations(null)
        }
    }, [generalMessageId])

    useEffect(() => {
        if (generalMessage) {
            form.setFieldsValue({
                ...generalMessage,
                startDate: generalMessage.startDate ? moment(generalMessage.startDate) : null,
                endDate: generalMessage.endDate ? moment(generalMessage.endDate) : null,
            })
            setIsDisplayEveryone(generalMessage.isDisplayEveryone)
        } else {
            form.setFieldsValue({
                startDate: null,
                endDate: null,
                type: 'INFO'
            })
        }
    }, [generalMessage])

    const loadGeneralMessage = async () => {

        setIsLoading(true);
        try {
            const response = await apiClient.request(`/api/v1/general-messaging/${generalMessageId}`, [], 'GET');
            setGeneralMessage(response.generalMessage)

        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false);
        }
    }

    const getOrganizations = async (generalMessageId: any) => {

        setIsLoading(true);
        try {
            const response = await apiClient.request(`/api/v1/general-messaging/get-organizations`, {generalMessageId: generalMessageId}, 'GET');
            setOrganizations(response.organizations)

        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false);
        }
    }

    const submitForm = async (values: any) => {
        setIsSubmitLoading(true);

        const parsedOrganizations = organizations.map((item: any) => {
            return {
                departments: item.departments,
                displayType: item.displayType,
                isEnabled: item.isEnabled,
                organizationUnits: item.organizationUnits,
                type: item.type,
                uuid: item.uuid
            }
        })

        const parsedValues = {
            ...values,
            organizations: parsedOrganizations
        };

        if (parsedValues.startDate) {
            parsedValues.startDate = values.startDate.format(config.DBDateFormat);
        }

        if (parsedValues.endDate) {
            parsedValues.endDate = values.endDate.format(config.DBDateFormat);
        }

        let response;

        try {
            if (generalMessageId) {
                response = await apiClient.request(`/api/v1/general-messaging/${generalMessageId}/update`, parsedValues, 'PUT');
                setGeneralMessage(response.generalMessage)
            } else {
                response = await apiClient.request('/api/v1/general-messaging/store', parsedValues, 'POST');
                navigate(`/general-messaging/${response.generalMessage.id}`)
            }
        } catch (error) {
            handleError(error)
        } finally {
            setIsSubmitLoading(false);
        }
    };

    const updateOrganizationData = (data: any, key: number) => {
        let newOrganizations = [...organizations];
        newOrganizations[key] = {...newOrganizations[key], ...data};
        setOrganizations(newOrganizations)
    }

    const onSearchUnits = async (value: string) => {
        const allUnits = organizations[selectedOrganizationIndex].allOrganizationUnits;
        const allDepartments = organizations[selectedOrganizationIndex].allDepartments;
        const searchStr = value.toLocaleLowerCase()
        const filteredUnits = allUnits.filter((item:any) => {
            return (item.title.toLowerCase()).indexOf(searchStr) !== -1;
        });
        const filteredDepartments = allDepartments.filter((item:any) => {
            return (item.title.toLowerCase()).indexOf(searchStr) !== -1;
        });
        setAllOrganizationUnits(filteredUnits)
        setAllDepartments(filteredDepartments)
    };

    const openPopover = async (item: any, key: number) => {
        setDisplayType(item.displayType)
        setSelectedOrganizationUnits(item.organizationUnits?item.organizationUnits:[])
        setAllOrganizationUnits(item.allOrganizationUnits)
        setSelectedDepartments(item.departments?item.departments:[])
        setAllDepartments(item.allDepartments)
        setSelectedOrganizationIndex(key)
    };

    const closePopover = async () => {
        setDisplayType('')
        setSelectedOrganizationUnits([])
        setAllOrganizationUnits([])
        setSelectedDepartments([])
        setAllDepartments([])
        setSelectedOrganizationIndex(null)
    };

    const organizationUnitContent = (allOrganizationUnits: any, departments: any, index: any) => {
        return (<>
            <div className={'flex flex-column p-1 checkbox-wrapper'}>
                <Radio.Group 
                    value={displayType} 
                    onChange={(e: any) => {
                        setDisplayType(e.target.value)
                        updateOrganizationData({
                            displayType: e.target.value
                        }, index)
                    }}
                    className='mb-5'
                >
                    <Space direction="vertical">
                        {Object.keys(displayTypeOptions).map((key: any) => {
                            return <Radio value={key} key={key}>{displayTypeOptions[key]}</Radio>;
                        })}
                    </Space>
                </Radio.Group>
                {displayType === 'ORGANIZATION_UNIT' && <>
                    <Search className={'w-100 h-34 mb-3'} placeholder={intl.formatMessage({id: 'general_messaging.search_here'})} onChange={(e: any) => onSearchUnits(e.target.value)} />
                    <div className={'scrollable-div'}>
                        <h3>{intl.formatMessage({id: 'general_messaging.organization_units'})}</h3>
                        <Checkbox.Group
                            onChange={(values: any) => {
                                setSelectedOrganizationUnits(values)
                                updateOrganizationData({
                                    organizationUnits: values
                                }, index)
                            }}
                            defaultValue={selectedOrganizationUnits}
                        >
                            {allOrganizationUnits.map((el: any, key: number) => {
                                return (
                                    <div className={'flex p-1 gap-5'} key={key}>
                                        <Checkbox value={el.id} /> {el.title}
                                    </div>
                                )
                            })}
                        </Checkbox.Group>
                        <hr />
                        <h3>{intl.formatMessage({id: 'general_messaging.departments'})}</h3>
                        <Checkbox.Group
                            onChange={(values: any) => {
                                setSelectedDepartments(values)
                                updateOrganizationData({
                                    departments: values
                                }, index)
                            }}
                            defaultValue={selectedDepartments}
                        >
                            {allDepartments.map((el: any, key: number) => {
                                return (
                                    <div className={'flex p-1 gap-5'} key={key}>
                                        <Checkbox value={el.id} /> {el.title}
                                    </div>
                                )
                            })}
                        </Checkbox.Group>
                    </div>
                </>}
            </div>
            <Divider plain className={'m-2'}/>
            <div className={'flex justify-between p-1'}>
                <Button type={'link'} onClick={() => closePopover()}>
                    <FormattedMessage id={'general.cancel'}/>
                </Button>
                <Button type={'primary'} onClick={() => closePopover()}>
                    <FormattedMessage id={'general.select'}/>
                </Button>
            </div>
        </>)
    }

    const renderOrganizationUnits = (
        allOrganizationUnits: any, 
        selectedOrganizationUnitIds: any, 
        index: number
    ) => {
        const filteredUnits = allOrganizationUnits.filter((item: any) => {return selectedOrganizationUnitIds.includes(item.id)})
        const content : any = (
            filteredUnits.map((item: any) => {
                return <Tag
                  key={item.uuid}
                  closable
                  style={{ userSelect: 'none', marginBottom: '5px' }}
                  onClose={() => {
                    closePopover()
                    const selectedIdsNew = filteredUnits.filter((id: any) => {
                        return item.id !== id;
                    })
                    updateOrganizationData({
                        organizationUnits: selectedIdsNew
                    }, index)
                  }}
                ><span>{item.title}</span></Tag>
            })
        )

        const content2 : any = (
            filteredUnits.map((item: any) => {
                return <Tag
                  key={item.uuid}
                  closable
                  style={{ userSelect: 'none', marginBottom: '5px' }}
                  onClose={() => {
                    closePopover()
                    const selectedIdsNew = filteredUnits.filter((id: any) => {
                        return item.id !== id;
                    })
                    updateOrganizationData({
                        organizationUnits: selectedIdsNew
                    }, index)
                  }}
                ><span>{item.title}</span></Tag>
            })
        )

        return content
    }

    const renderDepartments = (
        allDepartments: any, 
        selectedDepartmentIds: any, 
        index: number
    ) => {
        const filteredDepartments = allDepartments.filter((item: any) => {return selectedDepartmentIds.includes(item.id)})
        return (
            filteredDepartments.length > 0 ? (filteredDepartments.map((item: any) => {
                return <Tag
                  key={item.uuid}
                  closable
                  style={{ userSelect: 'none', marginBottom: '5px' }}
                  onClose={() => {
                    closePopover()
                    const selectedIdsNew = filteredDepartments.filter((id: any) => {
                        return item.id !== id;
                    })
                    updateOrganizationData({
                        departments: selectedIdsNew
                    }, index)
                  }}
                ><span>{item.title}</span></Tag>
            })) : <></>
        )
    }
    
    return (
        <Spinner spinning={isLoading}>
            <DefaultLayout.PageLayout>
                <DefaultLayout.PageHeader
                    title={generalMessageId && generalMessage ? generalMessage.title : intl.formatMessage({id: 'general.new_message'})}
                    breadcrumb={[{
                        name: intl.formatMessage({id: 'general_messaging.general_messaging'}),
                        path: '/general-messaging'
                    }]}
                />
                <DefaultLayout.PageContent withBottomMargin>
                    <Form form={form} onFinish={submitForm} {...formItemLayout} className="general-messaging-form">
                        <div className='form-section'>
                            <h2 className='text-18'>{intl.formatMessage({id: 'general_messaging.message_settings'})}</h2>
                            <hr/>
                            <div className='form-section-content'>
                                <Input
                                    name='title'
                                    customLayout={formItemLayout}
                                    label={intl.formatMessage({id: 'general_messaging.message_name'})}
                                    validation={{required: true}}
                                />
                                <hr/>
                                <Select
                                    name='type'
                                    label={intl.formatMessage({id: 'general_messaging.message_type'})}
                                    allowClear
                                    showSearch
                                    customLayout={formItemLayout}
                                    dropdownStyle={{ width: "100%" }}
                                    manualOptions={typeOptions}
                                />
                                <hr/>
                                <Form.Item name='message' label={intl.formatMessage({id: 'general_messaging.message_text'})}>
                                    <TextArea maxLength={440}/>
                                </Form.Item>
                            </div>
                        </div>
                        <div className='form-section'>
                            <h2 className='text-18'>{intl.formatMessage({id: 'general_messaging.visibilty_setting'})}</h2>
                            <hr/>
                            <div className='form-section-content'>
                                <Row gutter={[20, 0]}>
                                    <Col span={12}>
                                        <DatePicker 
                                            format={config.defaultDateFormat} 
                                            label={intl.formatMessage({id: 'general_messaging.begin_date'})}
                                            name="startDate"
                                            rules={[{required: true, message: intl.formatMessage({id: 'validation.field_required'})}]}
                                            customLayout={formItemLayout}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <DatePicker 
                                            format={config.defaultDateFormat} 
                                            label={intl.formatMessage({id: 'general_messaging.end_date'})}
                                            name="endDate"
                                            rules={[{required: true, message: intl.formatMessage({id: 'validation.field_required'})}]}
                                            customLayout={formItemLayout}
                                        />
                                    </Col>
                                </Row>
                                <hr />
                                <div className='flex gap-10 custom-switch'>
                                    <Switch checked={!!isDisplayEveryone} isFormItem name={'isDisplayEveryone'}
                                        onChange={(bool: boolean) => setIsDisplayEveryone(bool)}/>
                                    <div className='switch-label'>
                                        <label>{intl.formatMessage({id: 'general_messaging.display_everyone'})}</label>
                                        <div>{intl.formatMessage({id: 'general_messaging.display_everyone_note'})}</div>
                                    </div>
                                </div>
                                
                                {!isDisplayEveryone && <div className='org-box'>
                                    <>
                                        <Row className='label-row'>
                                            <Col span={16}>
                                                {intl.formatMessage({id: 'general.organization'})}
                                            </Col>
                                            <Col span={8}>
                                                {intl.formatMessage({id: 'general.on_off'})}
                                            </Col>
                                        </Row>
                                        {organizations.map((item: any, key: number) => {
                                            return (<Row key={key}>
                                                <Col span={16}>
                                                    <h3>{item.name}</h3>
                                                </Col>
                                                <Col span={8}>
                                                    <div className='flex gap-10 flex-wrap w-100 align-center'>
                                                        <Switch 
                                                            defaultChecked={!!item.isEnabled} 
                                                            onChange={(bool: boolean) => updateOrganizationData({
                                                                isEnabled: bool
                                                            }, key)}
                                                            label={intl.formatMessage({id: 'general_messaging.display_everyone'})}/>
                                                            <Popover placement="top" title={intl.formatMessage({id: 'general_messaging.visibility'})} content={organizationUnitContent(allOrganizationUnits, allDepartments, key)} open={selectedOrganizationIndex === key}>
                                                                <Select
                                                                    customLayout={formItemLayout}
                                                                    style={{ width: "150px", marginBottom: '0px' }}
                                                                    dropdownStyle={{ width: "100%" }}
                                                                    manualOptions={displayTypeOptions}
                                                                    defaultValue={item.displayType}
                                                                    onClick={() => {
                                                                        if(item.isEnabled) {
                                                                            openPopover(item, key)
                                                                        }
                                                                    }}
                                                                    open={false}
                                                                    disabled={!item.isEnabled}
                                                                />
                                                            </Popover>
                                                    </div>
                                                </Col>
                                                {item.isEnabled ? item.displayType === 'ORGANIZATION_UNIT' && <Col span={24}>
                                                    <div className='flex gap-2 flex-wrap w-100'>
                                                        {item.organizationUnits.length > 0 ? renderOrganizationUnits(item.allOrganizationUnits, item.organizationUnits, key) : null}
                                                        {item.departments.length > 0 ? renderDepartments(item.allDepartments, item.departments, key) : null}
                                                    </div>
                                                </Col> : null}
                                            </Row>)
                                        })}
                                    </>
                                </div>}
                            </div>
                        </div>
                        <DefaultLayout.PageFooter
                            right={
                                <>
                                    <Button loading={isLoading || isSubmitLoading} type='primary' onClick={form.submit}>
                                        <FormattedMessage id='general.save'/>
                                    </Button>
                                </>
                            }
                        />
                    </Form>
                </DefaultLayout.PageContent>
            </DefaultLayout.PageLayout>
        </Spinner>
    );
};

export default connect(mapStateToProps)(GeneralMessageEdit);