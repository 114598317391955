import React, {useState, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {connect} from 'react-redux';
import useHandleError from "../../../utils/useHandleError";
import coursesApiClient from '../../../utils/coursesApiClient';
import Spinner from "../../../components/Spinner";
import debounce from "lodash/debounce";
import {message, Button, Checkbox, Radio, Form, Row, Col, Modal, Popover, Space, Table} from "antd";
import { PlusOutlined, ExclamationCircleOutlined, UploadOutlined, ExpandOutlined } from '@ant-design/icons';
import {Input, TextArea} from 'components/Form';
import ThumbUploader from "./ThumbUploader";
import ThumbUploaderHotspot from "./Hotspot/ThumbUploaderHotspot";
import HotspotImageEdit from './Hotspot/HotspotImageEdit';
import HotspotImagePreview from './Hotspot/HotspotImagePreview';

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale,
        session: state.session
    };
};

interface QuestionAnswersListInterface {
    question: any;
    answers: any;
    form: any;
    submitForm: Function;
    loadQuestion: Function;
    setAnswers: Function;
    onChangeQuestionField: Function;
    isQuestionFieldUpdated: boolean;
    isSaveQuestionLoading: boolean;
    questionTypes: any;
    isVersionView: boolean;
    locale?: any;
    session?: any;
}

interface AnswerInterface {
    id: number,
    title: string;
    description?: string|null;
    thumbnail: string|null;
    thumbnail2?: string|null
    isCorrect: number;
    questionId: number;
    data?: any;
}

const QuestionAnswersList: React.FC<QuestionAnswersListInterface> = ({ 
    locale, 
    session, 
    form, 
    answers,
    question, 
    submitForm, 
    loadQuestion,
    setAnswers,
    onChangeQuestionField,
    isQuestionFieldUpdated,
    isSaveQuestionLoading,
    questionTypes,
    isVersionView
 }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isSavingAnswer, setIsSavingAnswer] = useState(false);
    const [isLoadingFile, setIsLoadingFile] = useState(false);
    const [showHotSpotEditModel, setShowHotSpotEditModel] = useState(false);
    const [reload, setReload] = useState(false);
    const [reloadOnSave, setReloadOnSave] = useState(false);
    const [imageUrl, setImageUrl] = useState<string|null>();
    const [hotSpotShapeType, setHotSpotShapeType] = useState<string>('LINE');
    const [hotSpotPreviewHoverElement, setHotSpotPreviewHoverElement] = useState<number|null>(null);

    const intl = useIntl();
    const [handleError] = useHandleError();
    const { confirm } = Modal;

    const questionId = question?.id;
    const questionType = questionTypes[question.type];

    useEffect(() => {
      if(question?.type == 'HS') {
        if(question?.storageFiles?.thumbnail) {
            setImageUrl(question?.storageFiles?.thumbnail.fileData)
        } else {
            setImageUrl(null)
        }
      }
    }, [question, question?.updatedAt, question?.storageFiles?.thumbnail?.fileId])

    useEffect(() => {
        if(answers) {
          form.setFieldsValue({
            answers
          })
        }
    }, [answers])

    const warnDeletion = async (id: number) => {
        confirm({
            title: intl.formatMessage({ id: 'general.attention' }),
            content: intl.formatMessage({ id: 'question_pools.deletion_answer' }),
            icon: <ExclamationCircleOutlined />,
            okText: intl.formatMessage({ id: 'general.yes' }),
            cancelText: intl.formatMessage({ id: 'general.no' }),

            onOk() {
                deleteAnswer(id);
            }
        });
    }

    const deleteAnswer = async (id: number, showAlerts: boolean = true) => {
        
        onChangeQuestionField()

        const deletedKey = answers.findIndex((answer: any) => answer.id === id);
        deleteAnswerByKey(deletedKey)
    }

    const deleteAnswerByKey = (key: number) => {
        var updatedList = [...answers];
        updatedList.splice(key, 1);
        setAnswers(updatedList);
        onChangeQuestionField(true, updatedList)
    }

    const onDeleteAnswer = (id: number, key: number) => {
        if(id) {
            warnDeletion(id)
        } else {
            deleteAnswerByKey(key)
        }
    }

    const getAnswerObject = () => {
        const newAnswer: AnswerInterface = {
          id: 0,
          title: '',
          description: null,
          thumbnail: null,
          thumbnail2: null,
          isCorrect: 0,
          data: null,
          questionId: questionId,
        }
  
        return newAnswer;
    }

    const addNewAnswer = (answerData: any) => {
        let answer = {...getAnswerObject(), ...answerData};
        let answersArray = [...answers, answer];
        setAnswers(answersArray);
    }
    

    const answerFieldOnChange = (index: number, data: any) => {
        let answersList = [...answers];

        // prevent radio button multiple selection
        if(data?.isCorrect && question.type === 'OC') {
            answersList = answersList.map((item: any) => {
                item.isCorrect = false;
                return item;
            })
        }

        answersList[index] = {...answersList[index], ...data};
        setAnswers(answersList)
        onChangeQuestionField()
    };

    const answerFieldOnChangeDebounce = debounce((index: number, data: any) => {
        answerFieldOnChange(index, data);
    }, 800);

    const answerFieldOnChangeNoDebounce = debounce((index: number, data: any) => {
        answerFieldOnChange(index, data);
    }, 0);

    const getCorrectAnswerFeild = (key: number) => {
        if( question.type == "OC" ) {
            return (<Radio 
                onChange={(e: any) => answerFieldOnChangeNoDebounce(key, {isCorrect: e.target.checked})}
            />)
        } else {
            return (<Checkbox 
                onChange={(e: any) => answerFieldOnChangeNoDebounce(key, {isCorrect: e.target.checked})}
            />)
        }
    }

    const onChangeQuestionThumbnail = () => {
        setImageUrl(null)
        
        form.setFieldsValue({
            thumbnail: 'removed'
        })

        onChangeQuestionField()
    }

    const getAnswerFieldColSize = (questionType: any) => {
        if(!questionType.hasAnswerCorrectField && !questionType.hasAnswerScoreField) {
            return 24;
        }
        else if(questionType.hasAnswerCorrectField && questionType.hasAnswerScoreField) {
            return 18;
        }
        else {
            return 21;
        }
    }

    return (
        <>
            {/* Hotspot answers */}
            {question.type == 'HS' ? 
                <Spinner spinning={isLoading || isLoadingFile}>
                    {imageUrl ? <>
                        <Modal
                            width={780}
                            className="edit-hotspot-modal"
                            title={intl.formatMessage({ id: 'question_pools.edit_hotspot' })}
                            visible={showHotSpotEditModel}
                            onOk={() => {}}
                            onCancel={() => setShowHotSpotEditModel(false)}
                            okText=''
                            cancelText=''
                            okButtonProps={{
                                style: {
                                    display: 'none'
                                }
                            }}
                            cancelButtonProps={{
                                style: {
                                    display: 'none'
                                }
                            }}
                        >
                            <Spinner spinning={isLoading}>
                                <div className='edit-hotspot-box'>
                                    <HotspotImageEdit 
                                        visible={showHotSpotEditModel}
                                        imageUrl={imageUrl}
                                        isEdit={true}
                                        hotSpotShapeType={hotSpotShapeType}
                                        onShapeAdded={(data: any) => addNewAnswer(data)}
                                        answers={answers}
                                        answerFieldOnChangeDebounce={answerFieldOnChangeDebounce}
                                        answerFieldOnChangeNoDebounce={answerFieldOnChangeNoDebounce}
                                        warnDeletion={warnDeletion}
                                        deleteAnswerByKey={deleteAnswerByKey}
                                        deleteAnswer={deleteAnswer}
                                        isSavingAnswer={isSavingAnswer || isSaveQuestionLoading}
                                    />
                                </div>
                                <Row gutter={[10, 0]} style={{marginTop: 20}} justify='space-between' align='middle' wrap>
                                    <Col span={6}>
                                        <Space>
                                            <div><FormattedMessage id='question_pools.add_hotspot' /></div>
                                            <div><Button type={hotSpotShapeType == 'RECT' ? 'primary' : 'ghost'} ghost={hotSpotShapeType == 'RECT'} onClick={() => setHotSpotShapeType('RECT')} style={{padding: '0px 15px'}}><i className="fa fa-square"></i></Button></div>
                                            <div><Button type={hotSpotShapeType == 'CIRCLE' ? 'primary' : 'ghost'} ghost={hotSpotShapeType == 'CIRCLE'} onClick={() => setHotSpotShapeType('CIRCLE')} style={{padding: '0px 15px'}}><i className="fa fa-circle-dot"></i></Button></div>
                                            <div><Button type={hotSpotShapeType == 'LINE' ? 'primary' : 'ghost'} ghost={hotSpotShapeType == 'LINE'} onClick={() => setHotSpotShapeType('LINE')} style={{padding: '0px 15px'}}><i className="fa fa-pencil"></i></Button></div>
                                        </Space>
                                    </Col>
                                    <Col span={6} className='flex justify-between'>
                                        <Space style={{marginLeft: 'auto'}}>
                                            <Button type='ghost' onClick={() => loadQuestion()} disabled={!isQuestionFieldUpdated} >
                                                <FormattedMessage id='general.cancel' />
                                            </Button>
                                            <Button type='primary' onClick={form.submit} disabled={!isQuestionFieldUpdated}>
                                                <FormattedMessage id='general.submit' />
                                            </Button>
                                        </Space>
                                    </Col>
                                </Row>
                            </Spinner>
                        </Modal>
                        <Row gutter={[20, 0]}>
                            <Col xl={12} md={12} sm={24}>
                                <div className='hotspot-answers-box'>
                                    <div className='hotspot-box-header'>
                                        <h3 style={{marginBottom: '0px'}}><FormattedMessage id='question_pools.hotspot' /></h3>
                                        <div style={{display: 'flex', alignItems: 'center', gridGap: '5px'}}>
                                            <label htmlFor='hotspotClickLimit'>
                                                <FormattedMessage id="question_pools.hs_click_limit" /> 
                                                <Popover content={intl.formatMessage({id: 'question_pools.hs_click_limit_explanation'})}>
                                                    <i style={{marginLeft: '3px'}} className="fal fa-question-circle"></i>
                                                </Popover>
                                                <span>:</span>
                                            </label>
                                            <Input
                                                name='hsClickLimit'
                                                type="number"
                                                min="0"
                                                label={false}
                                                validation={{required: false}}
                                                customLayout={{labelCol: {span: 0}, wrapperCol: {span: 24}}}
                                                style={{marginBottom: '0px', width: '70px'}}
                                                onChange={() => onChangeQuestionField()}
                                            />
                                        </div>
                                    </div>
                                    <div className='hotspot-box-body'>
                                        <ul className='hostspot-answer-list'>
                                            {answers.map((answer: any, index: number) => {
                                                return (
                                                    <li 
                                                        key={index}
                                                        onMouseOver={() => {
                                                            setHotSpotPreviewHoverElement(index)
                                                        }}
                                                        onMouseOut={() => {
                                                            setHotSpotPreviewHoverElement(null)
                                                        }}
                                                    >
                                                        <h4>{answer.title}</h4>
                                                        {!isVersionView && <div><span className='remove-answer' onClick={() => onDeleteAnswer(answer.id, index)}><i className='fa fa-times' /></span></div>}
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                            <Col xl={12} md={12} sm={24}>
                                <div className='hotspot-image-box'>
                                    <div className='hotspot-box-header'>
                                        <h3 style={{marginBottom: '0px'}}><FormattedMessage id='question_pools.photo' /></h3>
                                        <div style={{display: 'flex', alignItems: 'center', gridGap: '5px'}}>
                                            <Button type='ghost' onClick={e => onChangeQuestionThumbnail()}><UploadOutlined /> <FormattedMessage id='question_pools.change_photo' /></Button>
                                            <Button type='ghost' onClick={e => setShowHotSpotEditModel(true)}><ExpandOutlined /></Button>
                                        </div>
                                    </div>
                                    <div className='hotspot-box-body'>
                                        <HotspotImagePreview
                                            imageUrl={imageUrl}
                                            answers={answers}
                                            selectedShapeId={hotSpotPreviewHoverElement}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </> : <ThumbUploaderHotspot
                        fieldName="thumbnail"
                        fileInfo={question?.storageFiles?.thumbnail}
                        onChangeCallback={(values: any) => {
                            form.setFieldsValue({
                                ...values
                            })
                            onChangeQuestionField()
                        }}
                    />}
                </Spinner>
                // General question answers
                : <Spinner spinning={isLoading}>
                    <Row align="middle" className='answers-header'>
                        {questionType.hasAnswerCorrectField && <Col span={3}>
                            <FormattedMessage id="question_pools.correct_answer" />
                        </Col>}
                        {questionType.hasAnswerScoreField && <Col span={3}>
                            <FormattedMessage id="question_pools.answer_score" />
                        </Col>}
                        <Col span={getAnswerFieldColSize(questionType)}><FormattedMessage id="question_pools.answers" /></Col>
                    </Row>

                    <div className='answers-body'>
                        <Form.List 
                            name="answers"
                            initialValue={answers}
                            >
                            {(fields, { add, remove }) => {
                            return (
                                <div>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Row align="middle" justify='center' key={key}>
                                        {questionType.hasAnswerCorrectField && <Col span={3} style={{textAlign: 'center'}}>
                                            <Form.Item
                                                name={[name, "isCorrect"]}
                                                style={{marginBottom: '0px', justifyContent: 'center'}}
                                                valuePropName="checked"
                                                >
                                                {getCorrectAnswerFeild(key)}
                                            </Form.Item>
                                        </Col>}
                                        {questionType.hasAnswerScoreField && <Col span={3}>
                                            <div className='answer-score text-center'>
                                                <Input
                                                    name={[name, "score"]}
                                                    type="number"
                                                    label={false}
                                                    validation={{required: false}}
                                                    customLayout={{labelCol: {span: 0}, wrapperCol: {span: 24}}}
                                                    style={{marginBottom: '0px'}}
                                                    onChange={(e: any) => answerFieldOnChangeNoDebounce(key, {score: e.target.value})}
                                                />
                                            </div>
                                        </Col>}
                                        <Col span={getAnswerFieldColSize(questionType)}>
                                            <Row gutter={[10, 0]}>
                                                <Col span={questionType.hasAnswerThumbField ? 20 : (question.type == 'MA' ? 24 : 22)}>
                                                    {question.type == 'MA' ? 
                                                        <Row gutter={[20, 0]} align="middle" justify='center'>
                                                            <Col span={11} style={{display: 'flex', alignItems: 'center', gridGap: '10px'}}>
                                                                <TextArea
                                                                    name={[name, "title"]}
                                                                    validation={{required: !answers[key]?.thumbnail}}
                                                                    customLayout={{labelCol: {span: 0}, wrapperCol: {span: 24}}}
                                                                    style={{marginBottom: '0px', width: '100%'}}
                                                                    rows={4}
                                                                    onChange={(e: any) => answerFieldOnChangeNoDebounce(key, {title: e.target.value})}
                                                                />
                                                                <ThumbUploader
                                                                    baseFieldName={name}
                                                                    fieldName="thumbnail"
                                                                    fileInfo={answers[key]?.storageFiles?.thumbnail}
                                                                    onChangeCallback={(values: any) => answerFieldOnChangeNoDebounce(key, values)}
                                                                />
                                                            </Col>
                                                            <Col span={11} style={{gridGap: '10px'}} className='flex align-center'>
                                                                <TextArea
                                                                    name={[name, "description"]}
                                                                    validation={{required: !answers[key]?.thumbnail2}}
                                                                    customLayout={{labelCol: {span: 0}, wrapperCol: {span: 24}}}
                                                                    style={{marginBottom: '0px', width: '100%'}}
                                                                    rows={4}
                                                                    onChange={(e: any) => answerFieldOnChangeNoDebounce(key, {description: e.target.value})}
                                                                />
                                                                <ThumbUploader
                                                                    baseFieldName={name}
                                                                    fieldName="thumbnail2"
                                                                    fileInfo={answers[key]?.storageFiles?.thumbnail2}
                                                                    onChangeCallback={(values: any) => answerFieldOnChangeNoDebounce(key, values)}
                                                                />
                                                            </Col>
                                                            {!isVersionView && <Col span={2} >
                                                                <Button type='link' icon={<i className="fa-solid fa-xmark"></i>} style={{color: '#00000073'}} onClick={(e: any) => {
                                                                    answers[key]?.id ? warnDeletion(answers[key].id) :  deleteAnswerByKey(key)
                                                                }} />
                                                            </Col>}
                                                        </Row>
                                                    : <Input
                                                            name={[name, "title"]}
                                                            type="text"
                                                            validation={{required: !answers[key]?.thumbnail}}
                                                            customLayout={{labelCol: {span: 0}, wrapperCol: {span: 24}}}
                                                            style={{marginBottom: '0px'}}
                                                            onChange={(e: any) => answerFieldOnChangeNoDebounce(key, {title: e.target.value})}
                                                    />}
                                                </Col>
                                                {question.type != 'MA' ? 
                                                    <>
                                                        {questionType.hasAnswerThumbField ? <Col span={4} style={{display: 'inline-flex'}}>
                                                            <ThumbUploader
                                                                baseFieldName={name}
                                                                fieldName="thumbnail"
                                                                fileInfo={answers[key]?.storageFiles?.thumbnail}
                                                                onChangeCallback={(values: any) => answerFieldOnChangeNoDebounce(key, values)}
                                                            />
                                                            {!isVersionView && <Button type='link' icon={<i className="fa-solid fa-xmark"></i>} style={{color: '#00000073'}} onClick={(e: any) => {
                                                                answers[key]?.id ? warnDeletion(answers[key].id) :  deleteAnswerByKey(key)
                                                            }} />}
                                                        </Col> : <Col span={2} style={{display: 'inline-flex'}}>
                                                            {!isVersionView && <Button type='link' icon={<i className="fa-solid fa-xmark"></i>} style={{color: '#00000073'}} onClick={(e: any) => {
                                                                answers[key]?.id ? warnDeletion(answers[key].id) :  deleteAnswerByKey(key)
                                                            }} />}
                                                        </Col>}
                                                    </>
                                                : null}
                                            </Row>
                                        </Col>
                                    </Row>
                                ))}

                                {!isVersionView && <Row>
                                    <Col span={24} style={{textAlign: 'center'}}>
                                        <Button type="dashed" icon={<PlusOutlined/>} onClick={() => addNewAnswer({})} style={{maxWidth: '574px', width: '100%'}}>
                                            <FormattedMessage id="question_pools.add_new_choice" />
                                        </Button>
                                    </Col>
                                </Row>}
                                </div>
                            );
                            }}
                        </Form.List>
                    </div>
                </Spinner>
            }
        </>
    );
};

export default connect(mapStateToProps)(QuestionAnswersList);
