import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { message, Modal, Transfer } from 'antd';
import coursesApiClient from 'utils/coursesApiClient';
import {config} from 'config/config';
import apiClient from 'utils/apiClient';
import { connect } from 'react-redux';
import './styles.scss';

interface CourseAccessModalInterface {
    visible: boolean;
    onCancel: any;
    session?: any;
    course: any;
}

const mapStateToProps = (state: any) => {
    return {
        session: state.session
    };
}

const CourseAccessModal: React.FC<CourseAccessModalInterface> = ({ visible, onCancel, session, course }) => {
    const [transferData, setTransferData] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [targetKeys, setTargetKeys] = useState([]);

    const intl = useIntl();

    useEffect(() => {
        if (course?.id && session.active_user_type !== 'CUSTOMER_ADMIN') {
            loadOrganizations();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [course]);

    const loadOrganizations = async () => {
        const data: any = [];
        const target: any = [];

        try {
            let response = await apiClient.request(config.api.routes.backend.organizationsOptions, {})

            Object.values(response.organizations).map((el: any) => {
                if (course.access && (course.access.split(',')).includes(el.uuid)) {
                    target.push(el.uuid)
                }

                if (session?.organization?.organization_id !== el.id.toString()) {
                    data.push({
                        key: el.uuid,
                        name: el.name
                    });
                }
                return data;
            });

            setTargetKeys(target);
            setTransferData(data);
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        } finally {

        }

    }

    const submitForm = async () => {
        onCancel();
        try {
            const parsedValues = {
                ...course,
                access: targetKeys.join(',')
            };

            await coursesApiClient.request(`/api/v1/courses/courses/${course.id}`, parsedValues, 'PUT');

        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        } finally {

        }
    }

    return (
        <Modal
            className='add-new-course'
            open={visible}
            onCancel={onCancel}
            title={intl.formatMessage({ id: 'courses.reseller_access' })}
            okText={intl.formatMessage({ id: 'general.submit' })}
            cancelText={intl.formatMessage({ id: 'general.back' })}
            onOk={submitForm}
        >
            <Transfer
                locale={{ notFoundContent: intl.formatMessage({ id: "general.found_no_data" }) }}
                dataSource={transferData}
                targetKeys={targetKeys}
                selectedKeys={selectedKeys}
                onSelectChange={(value: any) => {
                    setSelectedKeys(value)
                }}
                onChange={(value: any) => {
                    setTargetKeys(value)
                }}
                render={(item: any) => item.name}
                selectAllLabels={[intl.formatMessage({ id: 'campaign.available' }, { amount: transferData.length - targetKeys.length }), intl.formatMessage({ id: 'campaign.added' }, { amount: targetKeys.length })]}
                oneWay
                showSelectAll={false}
            />

        </Modal>
    );
}

export default connect(mapStateToProps)(CourseAccessModal);
