import React, {useEffect, useState} from 'react'
import DefaultLayout from 'components/DefaultLayout';
import SessionReport from "../SessionReport";
import {FormattedMessage} from "react-intl";
import {Filter} from "ui";
import './styles.scss'
import LearningProgramReport from "../LearningProgramReport";
import CustomerSessionReport from "../CustomerSessionReport";
import AudienceReport from "../AudienceReport";
import {connect} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {Form} from "antd";

const mapStateToProps = (state: any) => {
    return {
        session: state.session
    }
};

const ReportsDashboard: React.FC<any> = ({session}) => {
    const [activeReport, setActiveReport] = useState('')
    const [filter, setFilter] = useState(null);
    const [load, setLoad] = useState(false);
    const [clearFilters, setClearFilters] = useState(false);
    const params = useParams();
    const navigate = useNavigate();
    const urlPath = params.report;
    const [form] = Form.useForm();

    useEffect(() => {
        if (urlPath) {
            setActiveReport(urlPath)
        }
    },[])


    const reports = {
        LEARNING_PROGRAM: {
            title: 'general.learning_programs',
            icon: 'fa-solid fa-book',
            access: ['SUPER_ADMIN', 'DISTRIBUTOR_ADMIN', 'RESELLER_ADMIN', 'CUSTOMER_ADMIN']
        },
        LECTURE: {
            title: 'general.lectures',
            icon: 'fa-solid fa-calendar-check',
            access: ['SUPER_ADMIN', 'DISTRIBUTOR_ADMIN', 'RESELLER_ADMIN', 'CUSTOMER_ADMIN']
        },
        SESSIONS: {
            title: 'general.sessions',
            icon: 'fa-solid fa-users',
            access: ['SUPER_ADMIN', 'DISTRIBUTOR_ADMIN', 'RESELLER_ADMIN']
        },
        AUDIENCE: {
            title: 'general.audience',
            icon: 'fa-solid fa-users',
            access: ['SUPER_ADMIN', 'DISTRIBUTOR_ADMIN', 'RESELLER_ADMIN']
        }
    };

    const handleClick = (report: 'LEARNING_PROGRAM' | 'LECTURE' | '') => {
        setClearFilters(true)
        navigate(`/reports/${report}`)
        setActiveReport(report)
    }

    const renderReport = () => {

        switch (activeReport) {
            case 'LECTURE':
                return <SessionReport form={form} filter={filter} load={load} setLoad={setLoad}/>
            case 'LEARNING_PROGRAM' :
                return <LearningProgramReport form={form} filter={filter} load={load} setLoad={setLoad}/>
            case 'SESSIONS' :
                return <CustomerSessionReport form={form} filter={filter} load={load} setLoad={setLoad}/>
            case 'AUDIENCE' :
                return <AudienceReport form={form} filter={filter} load={load} setLoad={setLoad}/>
            default:
                return <></>;
        }
    }

    return <>
        <DefaultLayout.PageLayout>
            <div className={'reports-element mt-20'}>
                <div className={'flex align-center'}>
                    <div className={'bold f-20'}>
                        <FormattedMessage id={'analytics.reports'}/>
                    </div>
                    <div className={'report-separator'}>
                        |
                    </div>
                    {Object.entries(reports).map((report: any) => {
                        if (!report[1].access.includes(session.active_user_type)) {
                            return ;
                        }

                        return (
                            <div key={report[0]} className={'report-tab' + (activeReport === report[0] ? ' active-report' : '')}
                                 onClick={() => handleClick(report[0])}>
                                <i className={report[1].icon}></i> <FormattedMessage id={report[1].title}/>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className={'reports-element'}>
                <Filter form={form} setClearFilters={setClearFilters} clearFilters={clearFilters} filter={setFilter} filterType={activeReport} setLoad={setLoad} />
            </div>
            {renderReport()}
        </DefaultLayout.PageLayout>
    </>
};

export default connect(mapStateToProps)(ReportsDashboard);
