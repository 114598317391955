import React, {useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Button, Form, Radio} from 'antd';
import DefaultLayout from 'components/DefaultLayout';
import {Input, Select, Switch} from 'components/Form';
import {useParams, useNavigate} from 'react-router-dom';
import {usePhishing} from "hooks/Phishing/usePhishing";

const formItemLayout = {
    labelCol: {span: 8},
    wrapperCol: {span: 6},
};

const TemplateForm: React.FC<any> = ({}) => {
    const {getTemplate, saveTemplate} = usePhishing();

    const navigate = useNavigate();
    const params = useParams();
    const [form] = Form.useForm();
    const templateId = params.id;
    const intl = useIntl();

    useEffect(() => {
        if (templateId) {
            loadLectures(templateId);
        }
    }, [])

    const loadLectures = async (id: string) => {
        const response = await getTemplate(id);
        form.setFieldsValue(response);
    }

    const submitForm = async (values: any) => {
        const response = await saveTemplate(values, templateId ? templateId : null);
        navigate(`/phishing/templates/${response.id}`)
    };

    return (
        <>
            <Form form={form} onFinish={submitForm} {...formItemLayout}>
                <Input name="title"
                       label={intl.formatMessage({id: 'phishing.simulation_template_universal_name'})}
                       validation={{required: true}}/>
                <Switch isFormItem hasDefaultLayout name='allowSmartDephish'
                        label={intl.formatMessage({id: 'phishing.allow_smart_dephish'})}/>
                <Switch isFormItem hasDefaultLayout name='customPhishingCampaigns'
                        label={intl.formatMessage({id: 'phishing.custom_phishing_campaigns'})}/>
                <Form.Item
                    name="difficulty"
                    label={intl.formatMessage({id: "phishing.difficulty"})}
                >
                    <Radio.Group>
                        <Radio value={'LOW'}><FormattedMessage id='phishing.low'/></Radio>
                        <Radio value={'MEDIUM'}><FormattedMessage id='phishing.medium'/></Radio>
                        <Radio value={'HIGH'}><FormattedMessage id='phishing.high'/></Radio>
                    </Radio.Group>
                </Form.Item>
                <Select
                    allowSearch
                    mode="multiple"
                    showArrow
                    showSearch
                    name='industry'
                    label={intl.formatMessage({id: "phishing.industry_types"})}
                    filterOption={(input: string, option: any) => {
                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                    url={'/api/v1/enum/industry-types'}
                />
            </Form>
            <DefaultLayout.PageFooter
                right={
                    <Button onClick={form.submit} className='save-button'>
                        <FormattedMessage id="general.submit"/>
                    </Button>
                }
            />
        </>
    )
}

export default TemplateForm;
