import React, { useState } from 'react';
import DefaultLayout from "components/DefaultLayout";
import Spinner from 'components/Spinner';
import ResourcesCalendar from 'components/Resources/ResourcesCalendar';
import { useIntl } from 'react-intl';

const Resource: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const intl = useIntl();
    
    return (
        <>
            <DefaultLayout.PageLayout withStickyFooter>
                <DefaultLayout.PageHeader
                    breadcrumb={[{ name: intl.formatMessage({ id: 'system.system' }) }]}
                    title={intl.formatMessage({ id: 'system.resource.calendar' })}
                />
                <DefaultLayout.PageContent >
                    <Spinner spinning={isLoading} >
                        <ResourcesCalendar />
                    </Spinner>
                </DefaultLayout.PageContent>
            </DefaultLayout.PageLayout>
        </>
    )
}

export default Resource;