import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import useHandleError from "utils/useHandleError";
import {AccessForm} from "components/AcessForm";
import generalHelpers from "utils/generalHelpers";
import DefaultLayout from "../../DefaultLayout";
import {Button} from "../../../ui";

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale,
        session: state.session
    }
};

interface RecordType {
    key: string;
    name: string;
}

interface AccessInterface {
    program: any;
    locale?: string;
    launchButton: HTMLElement;
}

const Access: React.FC<AccessInterface> = ({program, launchButton}) => {
    const [targetKeys, setTargetKeys] = useState<string[]>([]);

    const params = useParams();
    const intl = useIntl();
    const programId = params.id;
    const [handleError] = useHandleError();

    useEffect(() => {

        if (program) {
            const customers = program.organizationAccess.filter((el: any) => el.customer_uuid);

            const target = generalHelpers.stringifyArray(generalHelpers.pluck(customers, 'customer_uuid'));

            setTargetKeys(target);
        }

    }, [program]);

    return (
        <>
            <AccessForm
                transferTitle={'program.organization_access'}
                tableTitle={'program.user_access'}
                initialTargetKeys={targetKeys}
                customerUuid={true}
                hasAdminPermission={true}
                disabled={!(program?.visualPermissions?.admin)}
                url={
                    {
                        organizationUpdate: `/api/v1/courses/learning-program/${programId}/organizations`,
                        C: `/api/v1/courses/learning-program/${programId}/access/admins`,
                        R: `/api/v1/learning-program/${programId}/access/admins`,
                        U: `/api/v1/courses/learning-program/{id}/access/admins`,
                        D: `/api/v1/courses/learning-program/{id}/access/admins`
                    }
                }
            />
            <DefaultLayout.PageFooter
                right={
                    <>
                        {launchButton}
                    </>
                }
            />
        </>
    )
};

export default connect(mapStateToProps)(Access);
