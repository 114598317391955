import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';

import useHandleError from "utils/useHandleError";
import {AccessForm} from "components/AcessForm";

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale,
        session: state.session
    }
};

interface RecordType {
    key: string;
    name: string;
}

interface AccessInterface {
    locale?: string;
}

const Access: React.FC<AccessInterface> = ({}) => {

    const params = useParams();
    const intl = useIntl();
    const questionPoolId = params.id;
    const [handleError] = useHandleError();

    return (
        <AccessForm
            transferTitle='question_pools.organization_permissions'
            tableTitle='question_pools.users_management_access'
            url={
                {
                    C:`/api/v1/courses/question-pools/${questionPoolId}/access/admins`,
                    R:`/api/v1/question-pools/${questionPoolId}/access/admins`,
                    U:`/api/v1/courses/question-pools/{id}/access/admins`,
                    D:`/api/v1/courses/question-pools/{id}/access/admins`
                }
            }
            hasOrgAccess={false}
        />
    )
};

export default connect(mapStateToProps)(Access);
