import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import DefaultLayout from 'components/DefaultLayout';
import {useParams, useNavigate} from 'react-router-dom';
import {Button, Tabs} from "antd";
import PhishingCampaignSettingsForm from "components/Phishing/Campaign/Settings";
import PhishingCampaignStudents from "components/Phishing/Campaign/Students";
import {usePhishing} from "hooks/Phishing/usePhishing";
import PhishingCampaignResults from "components/Phishing/Campaign/Results";
import PhishingCampaignSummary from "components/Phishing/Campaign/Summary";
import Spinner from "../../../../components/Spinner";

const PhishingCampaignForm: React.FC = ({}) => {
    const {loading, getCampaign, launchPhishing} = usePhishing();

    const [activeTabKey, setActiveTabKey] = useState('settings');
    const [campaign, setCampaign] = useState<any>({});

    const intl = useIntl();
    const params = useParams();
    const tab = params.tab;
    const navigate = useNavigate();
    const campaignId = params.id;

    useEffect(() => {
        if (tab) {
            setActiveTabKey(tab)
        }
        loadCampaign(campaignId);

    }, [])

    const loadCampaign = async (id?: string) => {
        if (id) {
            const response = await getCampaign(id);
            setCampaign(response);
        }
    }

    const tabChangeHandle = (tab: string) => {
        setActiveTabKey(tab)
        navigate(`/phishing/campaign/${campaignId}/${tab}`)
    }

    const launchProgram = async () => {
        if (campaignId) {
            await launchPhishing(campaignId);
        }
    }

    const launchButton: any = (
        <div className={(campaignId && campaign.status !== 'DRAFT' ? 'hidden ' : '') + 'form-buttons'}>
            <Button disabled={!campaignId || campaign?.status !== 'DRAFT'} type="primary"
                    onClick={() => launchProgram()}>
                <FormattedMessage id="program.launch"/>
            </Button>
        </div>
    )

    return (
        <DefaultLayout.PageLayout>
            <DefaultLayout.PageHeader
                breadcrumb={
                    [{name: intl.formatMessage({id: 'phishing.phishing'}), path: '/phishing'}]
                }
                title={intl.formatMessage({id: 'phishing.phishing_campaign'})}
            />
            <DefaultLayout.PageContent withBottomMargin>
                <Tabs onChange={tabChangeHandle} activeKey={activeTabKey}>
                    <Tabs.TabPane disabled={!campaignId}
                                  tab={intl.formatMessage({id: 'general.summary'})} key="summary">
                        <Spinner spinning={loading}>
                            <PhishingCampaignSummary campaign={campaign}/>
                        </Spinner>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={intl.formatMessage({id: 'general.settings'})} key="settings">
                        <Spinner spinning={loading}>
                            <PhishingCampaignSettingsForm launchButton={launchButton} campaign={campaign} setCampaign={setCampaign}/>
                        </Spinner>
                    </Tabs.TabPane>
                    <Tabs.TabPane disabled={!campaignId} tab={intl.formatMessage({id: 'phishing.users'})}
                                  key="simulations">
                        <Spinner spinning={loading}>
                            <PhishingCampaignStudents launchButton={launchButton} campaign={campaign}/>
                        </Spinner>
                    </Tabs.TabPane>
                    <Tabs.TabPane disabled={!campaignId} tab={intl.formatMessage({id: 'phishing.results'})}
                                  key="results">
                        <Spinner spinning={loading}>
                            <PhishingCampaignResults campaign={campaign}/>
                        </Spinner>
                    </Tabs.TabPane>
                </Tabs>
            </DefaultLayout.PageContent>
        </DefaultLayout.PageLayout>
    )
};

export default PhishingCampaignForm;
