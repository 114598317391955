import React, { useEffect, useState } from 'react'
import DefaultLayout from 'components/DefaultLayout';
import { useIntl, FormattedMessage } from 'react-intl';
import apiClient from 'utils/apiClient';
import Spinner from "../../components/Spinner";

const TermsAndConditions: React.FC = () => {
    const [terms, setTerms] = useState<any>();
    const [isTermsLoading, setIsTermsLoading] = useState(false);
    const intl = useIntl();

    useEffect(() => {
        const loadTerms = async () => {
            setIsTermsLoading(true);
            let response = await apiClient.request('/api/v1/terms-and-conditions/' + intl.locale, [], 'GET');
            setTerms(response.terms.text);
            setIsTermsLoading(false);
        };
        loadTerms();
    }, [intl]);

    return <>
        <DefaultLayout.PageLayout>
            <h1>
                <FormattedMessage id='system.terms_and_conditions' />
            </h1>
            <DefaultLayout.PageContent>
                <Spinner spinning={isTermsLoading}>
                    <div style={{ fontSize: 16 }} dangerouslySetInnerHTML={{ __html: terms }}></div>
                </Spinner>
            </DefaultLayout.PageContent>
        </DefaultLayout.PageLayout>
    </>
};

export default TermsAndConditions;
