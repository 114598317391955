import {useState} from 'react'
import useHandleError from 'utils/useHandleError';
import apiClient from 'utils/apiClient';
import {config} from "config/config";
import {LanguageInterface} from "./types";

export const useLanguage = () => {
    const [handleError] = useHandleError();
    const [loading, setLoading] = useState<boolean>(false)

    const getLanguages = async (): Promise<LanguageInterface[] | []> => {

        setLoading(true);
        try {
            let response = await apiClient.request(config.api.routes.backend.languages, {}, 'GET');

            if ("error" in response) {
                throw response;
            }

            if ("languages" in response) {
                return response.languages;
            }

            return [];
        } catch (error) {
            handleError(error);
            return [];
        } finally {
            setLoading(false);
        }
    }

    return {
        getLanguages,
        loading
    }
}
