import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {message, Modal} from 'antd';
import coursesApiClient from "utils/coursesApiClient";
import {RequestSelect} from "components/Form";
import {useParams} from "react-router-dom";

const PhishingTestMailModal = ({visible, onCancel, simulation}: any) => {
    const [isLoading, setIsLoading] = useState(false)
    const intl = useIntl();
    const params = useParams();
    const campaignId = params.id;
    const [selectedUser, setSelectedUser] = useState(0)

    const sendTestPhishingEmail = async () => {
        setIsLoading(true)
        try {
            if (!selectedUser) {
                message.warning(intl.formatMessage({id: "error.select_user"}));
                return;
            }
            await coursesApiClient.request(`/api/v1/courses/phishing/campaign/${campaignId}/simulations/test/${simulation.id}/${selectedUser}`, [], 'POST');
            message.success(intl.formatMessage({id: 'users.email_sent'}));
            onCancel()
        } catch (error) {
            message.error(intl.formatMessage({id: "error.server_error"}));
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Modal
            className="results-modal"
            title={simulation.title}
            open={visible}
            onCancel={() => onCancel(false)}
            destroyOnClose
            confirmLoading={isLoading}
            onOk={sendTestPhishingEmail}
            cancelText={intl.formatMessage({id: 'general.cancel'})}
            okText={intl.formatMessage({id: 'general.send'})}
        >
            <RequestSelect
                customLayout={true}
                label={intl.formatMessage({id: "general.select_user"})}
                selectClassName='request-select'
                url={`api/v1/edu/users/search-student`}
                param={[`key=id&accountStatus=ACTIVE`]}
                onChange={(userTypeId: number) => setSelectedUser(userTypeId)}
                column
                showSearch
            />
        </Modal>
    )
}
export default PhishingTestMailModal;