import React, {useState, useEffect, useRef} from 'react';
import useHandleError from "utils/useHandleError";
import coursesApiClient from 'utils/coursesApiClient';
import generalHelpers from "utils/generalHelpers";

interface TimerProps {
    initialTime: number;
    testAttempt: any;
    resetTimer: boolean;
    timeUpCallback: Function;
    updateAttemptStoreData: Function;
}

const Timer: React.FC<TimerProps> = ({ initialTime, testAttempt, resetTimer, timeUpCallback, updateAttemptStoreData }) => {
    const [initialTimeValue, setInitialTimeValue] = useState<string>('');
    const [timerTime, setTimerTime] = useState<string>('00:00');
    const [timeSpentValue, setTimeSpentValue] = useState<number>(0);
    const [isTimerLoaded, setIsTimerLoaded] = useState<boolean>(false);

    const [handleError] = useHandleError();
    let totalTimeSpent = 0;
    let secondsLeft = initialTime * 60;

    // We need ref in this, because we are dealing
    // with JS setInterval to keep track of it and
    // stop it when needed
    const Ref = useRef<any>(null)
  
    useEffect(() => {
        if( resetTimer ) {
            stopTimer()
            updateTimeInDb(0, true);
            totalTimeSpent = 0;
            setInitialTimeStr()
        }
    }, [resetTimer])

    useEffect(() => {
        return () => {
            stopTimer()
        }
    }, [])

    useEffect(() => {
        setInitialTimeStr()
    }, [initialTime])

    useEffect(() => {
        if(!isTimerLoaded) {
            setTimeSpentValue(testAttempt.attemptTime)
            setIsTimerLoaded(true)
        }
    }, [testAttempt.attemptTime])
  
    // We can use useEffect so that when the component
    // mount the timer will start as soon as possible
  
    // We put empty array to act as componentDid
    // mount only
    useEffect(() => {
        if(initialTimeValue) {
            startTimer();
        }
    }, [initialTimeValue, timeSpentValue]);

    const setInitialTimeStr = () => {
        setInitialTimeValue(generalHelpers.convertSecondsToHHMMSS(secondsLeft))
    }
  
    const updateTimer = () => {
        totalTimeSpent++;
        secondsLeft--;

        if( secondsLeft % 10 == 0 || secondsLeft === 0) {
            let totalTimeSpentDb = totalTimeSpent;
            totalTimeSpent = 0;
            updateTimeInDb(totalTimeSpentDb)
        }

        if( testAttempt.timeLimitStatus && secondsLeft <= 0 ) {
            stopTimer()
            timeUpCallback()
        }
        
        if (secondsLeft >= 0) {
            // update the timer
            // check if less than 10 then we need to 
            // add '0' at the beginning of the variable
            setTimerTime(generalHelpers.convertSecondsToHHMMSS(secondsLeft))
        }
    }
  
    const startTimer = () => {
  
        // If you adjust it you should also need to
        // adjust the Endtime formula we are about
        // to code next    
        setTimerTime(initialTimeValue);
  
        // If you try to remove this line the 
        // updating of timer Variable will be
        // after 1000ms or 1sec
        stopTimer()

        const id = setInterval(() => {
            updateTimer();
        }, 1000)
        
        Ref.current = id;
    }

    const stopTimer = () => {
        if (Ref.current) clearInterval(Ref.current);
    }
  
    const updateTimeInDb = async (timeSpent: number, reset: boolean = false) => {
        if( testAttempt.attemptStatus != 'IN_PROGRESS' ) {
            return;
        }
        
        testAttempt.attemptTime = reset ? 0 : parseInt(testAttempt.attemptTime) + timeSpent;
        updateAttemptStoreData(testAttempt, null)

        if( ! testAttempt.id ) {
            return;
        }
        const parsedValues = {
            timeSpent: timeSpent,
            reset: reset
        };

        let response;

        try {
            response = await coursesApiClient.request(`/api/v1/courses/learning-tests/test-attempts/${testAttempt.id}/update-time`, parsedValues, 'POST');
        } catch (error) {
            handleError(error)
        }
    };

    return (
        <>
            {testAttempt.timeLimitStatus ? <div className='timer'>
                <i className='fa fa-clock'></i> {timerTime}
            </div>: null}
        </>
    );
};

export default Timer;
