import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {message} from 'antd';
import {changeLocale} from 'store/actions/locale';
import globalStyles from "utils/globalStyle";
import {changePublicAppearance} from "store/actions/publicAppearanceActions";
import {ThemeSwitch} from 'ui';
import Helmet from 'react-helmet';
import {ThemeType} from 'pages/Customers/NoAuthCustomerForm/types';

const mapStateToProps = (state: any) => {
    return {
        globalTheme: state.publicAppearanceReducer
    }
};

const mapDispatchToProps = (dispatch: any) => ({
    changeLocale: (locale: string) => dispatch(changeLocale(locale)),
    changePublicAppearance: (publicAppearanceReducer: any) => dispatch(changePublicAppearance(publicAppearanceReducer)),
});

const AppearanceStyler: React.FC<any> = ({setAppearanceIsLoading, globalTheme, changePublicAppearance}) => {
    const [favicon, setFavicon] = useState();
    const [faviconPath, setFaviconPath] = useState<any>();
    const [title, setTitle] = useState('CloudStudy');
    const [theme, setTheme] = useState<ThemeType>();

    const intl = useIntl();

    useEffect(() => {
        loadStyles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadStyles = async () => {
        let options;
        try {

            if (globalTheme.title) {
                options = globalTheme;
            } else {
                options = await globalStyles.getGlobalStyles();
            }

            switch (window.location.hostname) {
                case 'rixtc.cloudstudy.lv':
                    options.local = true
                    options.logo ="/RIX_logo.png"
                    setFaviconPath("/RIX_logo.svg")
                    break;
                default:
                    break;
            }

            if (!options.logo && options.logoPath) {
                let logo = await globalStyles.loadLogo(options.logoPath);
                options.logo = logo;
            }

            if (options.favicon) {
                setFavicon(options.favicon);
                setFaviconPath(options.faviconPath);
            } else if (options.faviconPath) {
                let favicon = await globalStyles.loadFavicon(options.faviconPath);
                setFavicon(favicon);
                options.favicon = favicon;
                setFaviconPath(options.faviconPath);
            }
            if (options) {
                setTitle(options.title);
                setTheme(options.theme);
                changePublicAppearance(options);
            }

        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({id: 'error.data_load'}));
        } finally {
            setAppearanceIsLoading(false);
        }
    };

    return (
        <>
            <ThemeSwitch theme={theme ? {themeColorGlobal: theme.themeColor} : {}}/>
            <Helmet>
                {faviconPath === null
                    ? <link rel="icon" type="image/png" href="/favicon.ico" sizes="16x16"/>
                    : <link rel="icon" type="image/png" href={favicon ? URL.createObjectURL(favicon) : faviconPath}
                            sizes="16x16"/>
                }
                <title>{title}</title>
            </Helmet>
        </>
    )
};


export default connect(mapStateToProps, mapDispatchToProps)(AppearanceStyler);

