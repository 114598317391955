import { Table, Button, Search } from "ui";
import { AudienceTabProps, AudienceUserMembership } from 'components/Audiences/types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import { Modal, Popover, Space } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import apiClient from "utils/apiClient";
import useHandleError from "utils/useHandleError";
import { RequestSelect } from 'components/Form';
import FlexRow from "components/FlexRow";


export enum DataType {
    All = "ALL",
    Excluded = "EXCLUDED",
}

const UsersTab: React.FC<AudienceTabProps> = ({ audience }) => {
    const [reload, setReload] = useState<boolean>(false);
    const [visibleTooltip, setVisibleTooltip] = useState<number | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [selectedUser, setSelectedUser] = useState<string>();
    const [searchString, setSearchString] = useState<string>('');
    const [dataType, setDataType] = useState<DataType>(DataType.All);
    const intl = useIntl();
    const { confirm } = Modal;
    const [handleError] = useHandleError();

    const handleRowActionVisibleChange = (visible: boolean, recordId: number) => {
        if (visible) {
            setVisibleTooltip(recordId);
        } else {
            setVisibleTooltip(null);
        }
    };

    useEffect(() => {
       setSelectedUser('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataType])

    const columns = [
        {
            key: 'name',
            title: intl.formatMessage({ id: 'audience.user' }),
            render: (record: any) => {
                return (
                    <NavLink to={`/customer/${record.customerId}/user/${record.typeUuid}/view/aud_${audience.id}`}>
                        {`${record.name || ''} ${record.surname || ''}`}
                    </NavLink>
                )
            }
        },
        {
            key: 'email',
            title: intl.formatMessage({ id: 'general.email' }),
            render: (record: any) => record.email
        },
        {
            key: 'position',
            title: intl.formatMessage({ id: 'general.position' }),
            render: (record: any) => record.position
        },
        {
            key: 'organizationUnit',
            title: intl.formatMessage({ id: 'general.unit' }),
            render: (record: any) => record.organizationUnit
        },
        {
            key: 'organization',
            title: intl.formatMessage({ id: 'general.organization' }),
            render: (record: any) => record.organization
        },
        {
            key: 'membership',
            title: intl.formatMessage({ id: 'audience.membership' }),
            render: (record: any) => {
                switch (record.membership) {
                    case (AudienceUserMembership.Automatic):
                        return intl.formatMessage({ id: 'audience.membership_automatic' });
                    case (AudienceUserMembership.Manual):
                        return intl.formatMessage({ id: 'audience.membership_manual' });
                }
            }
        },
        {
            key: 'actions',
            render: (_text: string, record: any) => {
                const content = (
                    <div className="popover-item" onClick={() => {
                        warnDeletion(record.userTypeId);
                        setVisibleTooltip(null);
                    }}>
                        <FormattedMessage id="general.delete" />
                    </div>
                );

                return (
                    <Popover
                        open={record.userTypeId === visibleTooltip}
                        content={content}
                        trigger="click"
                        placement="bottomRight"
                        arrowPointAtCenter
                        onOpenChange={(visible) => handleRowActionVisibleChange(visible, record.userTypeId)}
                    >
                        <div style={{ width: '100%', cursor: 'pointer', textAlign: 'center' }}>
                            <i className="fal fa-ellipsis-v" style={{ fontSize: '16px' }} />
                        </div>
                    </Popover>
                )
            },
        }
    ];

    const getTableColumns = () => {
        let tableColumns = columns;

        if (dataType === DataType.Excluded) {
            tableColumns = tableColumns.filter((column: any) => column.key !== 'membership')
        }

        if (!audience.isEditable()) {
            tableColumns = tableColumns.filter((column: any) => column.key !== 'actions')
        }

        return tableColumns;
    }

    const warnDeletion = async (userTypeId: number) => {
        confirm({
            title: intl.formatMessage({ id: 'general.attention' }),
            content: intl.formatMessage({ id: 'audience.delete_user_confirm' }),
            icon: <ExclamationCircleOutlined />,
            okText: intl.formatMessage({ id: 'general.yes' }),
            cancelText: intl.formatMessage({ id: 'general.no' }),

            onOk() {
                if (dataType === DataType.Excluded) {
                    removeExcluded(userTypeId)
                } else {
                    deleteUser(userTypeId);
                }

            }
        });
    }

    const deleteUser = async (id: number) => {
        try {
            const response = await apiClient.request(`/api/v1/audiences/${audience.id}/users/delete`, {
                userTypeIds: [id]
            }, 'POST');
            if ("error" in response) {
                throw response
            };
            setReload(!reload)
        } catch (error) {
            handleError(error);
        }
    }

    const removeExcluded = async (id: number) => {
        try {
            const response = await apiClient.request(`/api/v1/audiences/${audience.id}/users/excluded/${id}`, [], 'DELETE');
            if ("error" in response) {
                throw response
            };
            setReload(!reload)
        } catch (error) {
            handleError(error);
        }
    }

    const addAudienceUser = async () => {
        setIsLoading(true)
        try {
            const response = await apiClient.request(`api/v1/audiences/${audience.id}/users`, {
                "userTypeIds": [
                    selectedUser
                ],
            }, 'POST');
            if ("error" in response) {
                throw response
            };
            setReload(true);
            setSelectedUser(undefined);
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false);

        }
    }

    return (
        <>
            <FlexRow
                left={<>
                    <Space.Compact block>
                        <Button type={dataType === DataType.All ? 'primary' : undefined}
                            onClick={() => setDataType(DataType.All)}>
                            <FormattedMessage id='audience.users' />
                        </Button>
                        <Button type={dataType === DataType.Excluded ? 'primary' : undefined}
                            onClick={() => setDataType(DataType.Excluded)}>
                            <FormattedMessage id='audience.excluded_users' />
                        </Button>
                    </Space.Compact>
                    {audience.isEditable() && dataType === DataType.All && <>
                        <FormattedMessage id='general.add_user' />
                        <RequestSelect
                            isForm={false}
                            customLayout={true}
                            style={{ width: 200 }}
                            url={`api/v1/edu/users/search-student`}
                            column
                            showSearch
                            onChange={(typeUuid: string) => setSelectedUser(typeUuid)}
                            value={selectedUser}
                        />
                        <Button type='primary' disabled={!selectedUser}
                            onClick={() => addAudienceUser()}
                        >
                            <FormattedMessage id='general.add' />
                        </Button>
                    </>}
                </>}
                right={<Search setSearchString={setSearchString} setReload={setReload} />}
            />
            {dataType === DataType.All && <Table
                columns={getTableColumns()}
                url={`/api/v1/audiences/${audience.id}/users`}
                setReload={setReload}
                reload={reload}
                rowSelection={false}
                rowKey={'userTypeId'}
                search={searchString}
                isLoading={isLoading}
            />}
            {dataType === DataType.Excluded && <Table
                columns={getTableColumns()}
                url={`/api/v1/audiences/${audience.id}/users/excluded`}
                setReload={setReload}
                reload={reload}
                rowSelection={false}
                rowKey={'userTypeId'}
                search={searchString}
                isLoading={isLoading}
            />}
        </>

    );
};

export default UsersTab;
