import React, { useEffect, useState } from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { message, Form, Tag, Breadcrumb, Card } from 'antd';
import DefaultLayout from 'components/DefaultLayout';
import { ArrowLeftOutlined } from '@ant-design/icons';
import apiClient from 'utils/apiClient';
import coursesApiClient from 'utils/coursesApiClient';
import { connect } from 'react-redux';
import './styles.scss';
import Spinner from 'components/Spinner';
import useHandleError from "utils/useHandleError";

interface AssessmentActivityInterface {
    locale?: any;
    session?: any;
    learningActivityId: number;
    programActivityAttemptId: number;
    type: 'learn' | 'test';
    isFirstAttempt?: boolean;
    parentActivityId?: number;
    campaignId?: string;
    language?: string;
    version?: string;
}

const mapStateToProps = (state: any) => ({
    locale: state.locale.locale,
    session: state.session
});

const AssessmentActivity: React.FC<AssessmentActivityInterface> = ({ 
    locale, 
    session,
    learningActivityId, 
    programActivityAttemptId,
    type,
    isFirstAttempt, 
    parentActivityId, 
    campaignId, 
    language,
    version
 }) => {
    const [isAssessmentLoading, setIsAssessmentLoading] = useState(false);
    const [assessmentSettings, setAssessmentSettings] = useState<any>();
    const [submission, setSubmission] = useState<any>();
    const [activityFormData, setActivityFormData] = useState<any>();

    const intl = useIntl();
    const [form] = Form.useForm();

    const [handleError] = useHandleError();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getAssessment();
          // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

      const consumeLicenceAccess = async () => {
        try {
          const values = {
            activityId: learningActivityId,
            userTypeId: session.active_user_type_id,
            customerId: session.organization.organization_id,
            parentActivityId: parentActivityId,
            campaignId: campaignId
          }
    
          return await apiClient.request('/api/v1/licences/consume', values, 'POST');
        } catch(error) {
          message.error(intl.formatMessage({ id: 'error.data_load' }));
          console.error(error);
        }
    }

    const getAssessment = async () => {
        setIsLoading(false);
        try {
            // if (isFirstAttempt) {
            //     await consumeLicenceAccess();
            // }

            let response = await coursesApiClient.request(`api/v1/courses/assessment-results/get-assessment/${programActivityAttemptId}`, {}, 'GET');
            let settings = response.material.settings ? JSON.parse(response.material.settings) : [];
            let submission = response.submission;

            setAssessmentSettings(settings)
            setSubmission(submission)
            setActivityFormData(response)
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        } finally {
            setIsLoading(false);
        }
    }

    /**
     * 
     * @param statusType 
     * @returns 
     */
    const getStatusTag = (statusType: 'VALUE' | 'TAG' = 'TAG') => {
        let status: string = submission?.status
        let color = ''
        let messageId = ''

        switch (status) {
            case 'NOT_STARTED':
            case 'IN_PROGRESS':
                color = 'blue'
                messageId = 'courses.topic_status.pending_grading'
                break;
            case 'PASSED':
                color = 'green'
                messageId = 'courses.topic_status.passed'
                break;
            case 'FAILED':
                color = 'red'
                messageId = 'courses.topic_status.failed'
                break;
            default:
                color = 'gold'
                messageId = 'courses.topic_status.not_started'
                break;
        }

        if (statusType === 'TAG') {
            return (
                <Tag color={color}>
                    <FormattedMessage id={messageId} />
                </Tag>
            )
        } else if (statusType === 'VALUE') {
            return status;
        }
    }

    const getProgramTitle = (program: any) => {
        return program.title[locale] ?? program.title[Object.keys(program.title)[0]];
    }

    return (
        <Spinner spinning={isLoading}>
            {activityFormData && 
            <DefaultLayout.PageLayout withoutPageHeader>
                <div className='assessment-form-wrapper'>
                    <Breadcrumb className="breadcrumb">
                        <Breadcrumb.Item>
                            <NavLink to='../'>
                                <FormattedMessage id={'student.my_courses'} />
                            </NavLink>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <NavLink to={`/materials/${activityFormData.program.id}`}>
                                {getProgramTitle(activityFormData.program)}
                            </NavLink>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{assessmentSettings.title ? <>{assessmentSettings.title}</> : <FormattedMessage id='assessment.practical_assessment' />}</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="flex align-center title">
                        <NavLink to='../'>
                            <ArrowLeftOutlined className="arrow"/>
                        </NavLink>
                        <h1>{assessmentSettings.title ? <>{assessmentSettings.title}</> : <FormattedMessage id='assessment.practical_assessment' />}</h1>
                    </div>
                    <DefaultLayout.PageContent style={{background: 'none', padding: '0px'}}>
                        <Card className='assessment-card'>
                            <div className='assessment-detail'>
                                <div className='assessment-section'>
                                    <div className='assessment-title-bar'>
                                        <div className='flex align-center flex-wrap'>
                                            <div>
                                                <div className='assessment-title flex flex-wrap space-between'>
                                                    <h3>{assessmentSettings.title ? <>{assessmentSettings.title}</> : <FormattedMessage id='assessment.practical_assessment' />}</h3>
                                                    <span className='status-bar-value'>
                                                        {getStatusTag()}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='assessment-description' dangerouslySetInnerHTML={{ __html: assessmentSettings.description }}></div>
                                </div>
                            </div>
                        </Card>
                    </DefaultLayout.PageContent>
                </div>
            </DefaultLayout.PageLayout>
        }
        </Spinner>
    )
}
export default connect(mapStateToProps)(AssessmentActivity);