import React, {useEffect, useState} from 'react';
import { useIntl } from 'react-intl';
import { Tabs } from 'antd';
import DefaultLayout from 'components/DefaultLayout';
import CustomizationForm from './CustomizationForm';
import {connect} from "react-redux";
import { useNavigate } from 'react-router-dom';

const { TabPane } = Tabs;

const mapStateToProps = (state: any) => {
    return {
        session: state.session
    };
};

const CustomizeEnvironment: React.FC = ({ session }: any) => {
  const [globalTabKey, setGlobalTabKey] = useState(0);
  const [resellerTabKey, setResellerTabKey] = useState(0);
  const [customerTabKey, setCustomerTabKey] = useState(0);
  const [defaultTabKey, setDefaultTabKey] = useState('');
  const [displayGlobalTab, setDisplayGlobalTab] = useState(false);
  const [displayOrganizationTabs, setDisplayOrganizationTabs] = useState(false);
  const navigate = useNavigate();

  const intl = useIntl();

useEffect(() => {
    if (session.active_user_type === 'CUSTOMER_ADMIN') {
      if (!session.access?.agreement?.canEditEnvironment) {
          navigate('/403')
      }
      setCustomerTabKey(customerTabKey + 1);
      setDefaultTabKey('customer');
      setDisplayGlobalTab(false);
      setDisplayOrganizationTabs(false);
    } else if (session.active_user_type === 'RESELLER_ADMIN') {
      setDisplayGlobalTab(false);
      setDisplayOrganizationTabs(true);
      setResellerTabKey(resellerTabKey + 1);
      setDefaultTabKey('reseller');
    } else  {
      setGlobalTabKey(globalTabKey + 1);
      setDefaultTabKey('global');
      setDisplayGlobalTab(true);
      setDisplayOrganizationTabs(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
},[]);

  const handleTabClick = (key: string) => {
    switch (key) {
      case 'global':
        setGlobalTabKey(globalTabKey + 1);
        setDefaultTabKey('global');
        break;
      case 'reseller':
        setResellerTabKey(resellerTabKey + 1);
        setDefaultTabKey('reseller');
        break;
      case 'customer':
        setCustomerTabKey(customerTabKey + 1);
        setDefaultTabKey('customer');
        break;
    }
  };

  return (
    <DefaultLayout.PageLayout withStickyFooter>
      <DefaultLayout.PageHeader
        breadcrumb={[{ name: intl.formatMessage({id: 'system.system'}) }]}
        title={intl.formatMessage({id: 'system.customize_environment'})}
      />
      <DefaultLayout.PageContent>

        <Tabs activeKey={defaultTabKey} type="card" onTabClick={handleTabClick}>
          {displayGlobalTab &&
            <TabPane tab={intl.formatMessage({id: 'system.environment.global'})} key="global">
              <CustomizationForm key={globalTabKey} type="GLOBAL"/>
            </TabPane>
          }
          {displayOrganizationTabs &&
            <TabPane tab={intl.formatMessage({id: 'system.environment.reseller'})} key="reseller">
              <CustomizationForm key={resellerTabKey} type="RESELLER"/>
            </TabPane>
          }
          <TabPane tab={intl.formatMessage({ id: 'system.environment.customer' })} key="customer">
            <CustomizationForm key={customerTabKey} type="CUSTOMER" />
          </TabPane>
        </Tabs>
      </DefaultLayout.PageContent>
    </DefaultLayout.PageLayout>
  );
}

export default connect(mapStateToProps)(CustomizeEnvironment);
