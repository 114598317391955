import ActivityResources from 'learningPages/Campaign/ActivityResources';
import React, {useEffect, useState} from 'react';
import ActivityList from './ActivityList';
import CampaignList from './CampaignList';
import { ActivityData, ResourceData } from './types';
import StudentDashboard from "../../../../learningPages/StudentDashboard";
import Materials from "../../../../learningPages/StudentDashboard/Materials";

interface StudiesTabProps {
  userUuid: string;
  userTypeId: number;
  activeUserIsMe?: boolean;
  campaignId?: number;
  enrollmentId?: number;
  back?: boolean;
  setBack: React.Dispatch<React.SetStateAction<boolean>>;
}

const StudiesTab: React.FC<StudiesTabProps> = ({ userUuid, userTypeId, activeUserIsMe, campaignId, enrollmentId, back, setBack }) => {
  const [programId, setProgramId] = useState(null);

  const [view, setView] = useState('PROGRAM');

    const [activityData, setActivityData] = useState<ActivityData>({
    dataIsSet: false,
    campaignId: undefined,
    enrollmentId: undefined,
  });
  const [resourceData, setResourceData] = useState<ResourceData>({
    activityId: undefined,
    campaignId: undefined,
    enrollmentId: undefined
  });

  useEffect(() => {
    if (back) {
        setActivityData({
            dataIsSet: false,
            campaignId: undefined,
            enrollmentId: undefined
        });
        setBack(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[back]);

  useEffect(() => {
      if (campaignId && enrollmentId) {
          setActivityData({
              dataIsSet: true,
              campaignId: campaignId,
              enrollmentId: enrollmentId
          });

          setResourceData({
            activityId: undefined,
            campaignId: campaignId,
            enrollmentId: enrollmentId
          });
      }
  },[campaignId, enrollmentId]);

  useEffect(() => {
    if (activityData?.campaignId && activityData?.enrollmentId) {
      setResourceData({
        activityId: undefined,
        campaignId: activityData.campaignId,
        enrollmentId: activityData.enrollmentId
      });
    }
  },[activityData]);

  const clearResourceData = () => {
    setResourceData({
      activityId: undefined,
      campaignId: campaignId,
      enrollmentId: enrollmentId
    });
  }

    const getView = (view: string) => {
        switch (view) {
            case 'PROGRAM':
                return <StudentDashboard
                    userTypeId={userTypeId}
                    adminView={true}
                    setView={setView}
                    setAdminProgramId={setProgramId}
                />
            case 'MATERIAL':
                return <Materials
                    userTypeId={userTypeId}
                    programId={programId}
                    adminView={true}
                    setView={setView}
                />
        }
  }

    // campaign view
    // if (resourceData.activityId) {
    //   return <ActivityResources resourceData={resourceData} clear={clearResourceData} />
    // } else if (activityData.dataIsSet) {
    //   return <ActivityList userUuid={userUuid} activityData={activityData} setResourceData={setResourceData} />
    // } else {
    //   return <CampaignList userUuid={userUuid} setActivityData={setActivityData} activeUserIsMe={activeUserIsMe} />
    // }

  return (
    <>
      {getView(view)}
    </>
  );
};

export default StudiesTab;
