import * as ActionTypes from 'store/actionTypes';

export const changeScormData = ({
  learningActivityId, type, activityAttemptId, programActivityAttemptId,
  enrollmentId, campaignId, isFirstAttempt, parentActivityId, materialType, learningType
}: any) => ({
  type: ActionTypes.SCORM_DATA,
  payload: {
    learningActivityId: learningActivityId,
    type: type,
    activityAttemptId: activityAttemptId,
    programActivityAttemptId: programActivityAttemptId,
    enrollmentId: enrollmentId,
    campaignId: campaignId,
    isFirstAttempt: isFirstAttempt ,
    parentActivityId: parentActivityId,
    materialType: materialType,
    learningType: learningType
  }
});
