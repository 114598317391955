import { AudienceTabProps } from 'components/Audiences/types';
import React, { useEffect, useState } from 'react';
import { Form, List, Transfer } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { Switch } from 'components/Form';
import useHandleError from 'utils/useHandleError';
import apiClient from 'utils/apiClient';
import Spinner from 'components/Spinner';

interface TransferRecordType {
    key: string;
    title: string;
}

const CompetencesTab: React.FC<AudienceTabProps> = ({ audience, reloadAudience }) => {
    const intl = useIntl();
    const [competences, setCompetences] = useState<Array<TransferRecordType>>();
    const [audienceCompetences, setAudienceCompetences] = useState<Array<TransferRecordType>>();
    const [targetKeys, setTargetKeys] = useState<Array<any>>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [settingAssign, setSettingAssign] = useState(false);
    const [form] = Form.useForm();
    const [handleError] = useHandleError();

    useEffect(() => {
        const filterCompetences = (competences: any[]): Array<TransferRecordType> => {
            const result: Array<TransferRecordType> = [];

            competences.forEach((block: any) => {
                block.children.forEach((item: any) => {
                    result.push({
                        key: item.id,
                        title: item.title,
                    })
                });
            });

            return result;
        }

        const loadCopetences = async () => {
            setIsLoading(true);
            try {
                const response = await apiClient.request(`/api/v1/competences?pagination[current]=1&pagination[pageSize]=999`, [], 'GET');
                if ("error" in response) {
                    throw response
                };

                if (response.data) {
                    setCompetences(filterCompetences(response.data))
                }
            } catch (error) {
                handleError(error);
            } finally {
                setIsLoading(false);
            }
        }

        const loadAudienceCopetences = async () => {
            setIsLoading(true);
            try {
                const response = await apiClient.request(`/api/v1/audiences/${audience.id}/competences?pagination[current]=1&pagination[pageSize]=999`, [], 'GET');
                if ("error" in response) {
                    throw response
                };
                if (response.data) {
                    const audienceCompetences = response.data.map((item: any) => item.id);
                    setAudienceCompetences(audienceCompetences)
                }
            } catch (error) {
                handleError(error);
            } finally {
                setIsLoading(false);
            }
        }

        form.resetFields()
        form.setFieldsValue({
            competencesAssign: audience.competencesAssign,
            competencesRevoke: audience.competencesRevoke,
        });

        setSettingAssign(audience.competencesAssign)

        loadCopetences();
        loadAudienceCopetences();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audience])

    useEffect(() => {
        if (audienceCompetences?.length) {
            setTargetKeys(audienceCompetences);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audienceCompetences])

    const handleAssignChange = (value: boolean) => {
        updateAudience({
            competencesAssign: value
        });
        setSettingAssign(value)
    }

    const handleRevokeChange = (value: boolean) => {
        updateAudience({
            competencesRevoke: value
        });
    }

    const updateAudience = async (audienceData: any) => {
        try {
            const response = await apiClient.request(`/api/v1/audiences/${audience.id}`, audienceData, 'PUT');
            if ("error" in response) {
                throw response
            };
        } catch (error) {
            handleError(error);
        }
        reloadAudience();
    }

    const saveCopetences = async (value: Array<any>) => {
        setIsLoading(true);
        try {
            const removed = competences?.filter(item => !value.includes(item.key))
                .map(k => k.key);

            await Promise.all([
                removed?.length && await apiClient.request(`/api/v1/audiences/${audience.id}/competences/delete`, {
                    competenceIds: removed
                }, 'POST'),
                value.length && await apiClient.request(`/api/v1/audiences/${audience.id}/competences`, {
                    competenceIds: value
                }, 'POST'),
            ]);

            setTargetKeys(value);
        } catch (error) {
            handleError(error);
        } finally {
            setIsLoading(false);
        }
    }

    return <Form form={form}>
        <List
            className="audience-form-items-list"
            header={<FormattedMessage id='audience.competencies' />}
            bordered
            dataSource={[
                <>
                    <Switch name="competencesAssign" isFormItem
                        label={intl.formatMessage({ id: 'audience.competences_assign' })}
                        valuePropName="checked"
                        onChange={handleAssignChange}
                        disabled={!audience.isEditable()}
                    />
                    <Switch name="competencesRevoke" isFormItem
                        label={intl.formatMessage({ id: 'audience.competences_revoke' })}
                        valuePropName="checked"
                        onChange={handleRevokeChange}
                        disabled={!audience.isEditable()}
                    />
                </>,
            ]}
            renderItem={(item) => <>{item}</>}
        />
        {!!settingAssign && <Spinner spinning={isLoading} >
            <Transfer className='audience-competeces-transfer'
                locale={{ notFoundContent: intl.formatMessage({ id: "general.found_no_data" }) }}
                dataSource={competences}
                showSearch
                showSelectAll={false}
                targetKeys={targetKeys}
                onChange={(value: any) => {
                    saveCopetences(value)
                }}
                render={(item: TransferRecordType) => item.title}
                disabled={!audience.isEditable()}
                titles={[intl.formatMessage({ id: 'general.available' }), intl.formatMessage({ id: 'general_added' })]}
            />
        </Spinner>}

    </Form>;

};

export default CompetencesTab;