import * as ActionTypes from 'store/actionTypes';

export const changeActivityCreationSettings = ({type, path, title}:any) => ({
  type: ActionTypes.SAVE_ACTIVITY_CREATION_SETTINGS,

  payload: {
    type: type,
    path: path,
    title: title

  }
});

export const clearActivityCreationSettings = () => ({
  type: ActionTypes.CLEAR_ACTIVITY_CREATION_SETTINGS,
});
