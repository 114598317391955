import React from 'react';
import MySessionsList from 'components/Lectures/MySessionsList'

import { LectureProps, LectureSessionFilterType } from '../../types';


export interface PastSessionsProps {
    lecture?: LectureProps,
    adminView?: boolean;
    userTypeId?: number;
    setAdminSession?: React.Dispatch<React.SetStateAction<number>>;
    setAdminLecture?: React.Dispatch<React.SetStateAction<number>>;
    setAdminView?: React.Dispatch<React.SetStateAction<string>>;
}

const PastSessions: React.FC<PastSessionsProps> = ({
                                                       lecture,
                                                       adminView,
                                                       userTypeId,
                                                       setAdminSession,
                                                       setAdminLecture,
                                                       setAdminView
                                                    }) => {
    return <MySessionsList
        type={LectureSessionFilterType.Past}
        lecture={lecture}
        adminView={adminView}
        userTypeId={userTypeId}
        setAdminSession={setAdminSession}
        setAdminLecture={setAdminLecture}
        setAdminView={setAdminView}
    />;
};

export default PastSessions;
