import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { message } from 'antd';
import moment from 'moment';
import BarLineChart from 'components/Charts/BarLineChart';
import coursesApiClient from 'utils/coursesApiClient';
import {useParams} from "react-router-dom";

interface CompletionOverTimeChartProps {
}

const CompletionOverTimeChart: React.FC<CompletionOverTimeChartProps> = ({  }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState({});
    const params = useParams();

    const learningTestId = params?.id ? parseInt(params?.id) : 0;

    const intl = useIntl();

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        try {
            setIsLoading(true);

            const response = await coursesApiClient.request(`/api/v1/courses/learning-tests/${learningTestId}/review/completion-over-time`);

            const data = response.data;

            // EVERYTHING FROM HERE TO parsedData HAS POTENTIAL FOR REFACTOR
            const rotateArrayToEndAtCurrentMonth = (array: any[]) => {
                const newArray = [...array];
                const currentMonth = moment().month();

                for (let i = 0; i < 11 - currentMonth; i++) {
                    newArray.unshift(newArray.pop());
                }

                return newArray;
            };

            const monthsLabelsEndingWithCurrent = () => {
                const monthLabels = [
                    intl.formatMessage({ id: 'general.january' }),
                    intl.formatMessage({ id: 'general.february' }),
                    intl.formatMessage({ id: 'general.march' }),
                    intl.formatMessage({ id: 'general.april' }),
                    intl.formatMessage({ id: 'general.may' }),
                    intl.formatMessage({ id: 'general.june' }),
                    intl.formatMessage({ id: 'general.july' }),
                    intl.formatMessage({ id: 'general.august' }),
                    intl.formatMessage({ id: 'general.september' }),
                    intl.formatMessage({ id: 'general.october' }),
                    intl.formatMessage({ id: 'general.november' }),
                    intl.formatMessage({ id: 'general.december' }),
                ];

                return rotateArrayToEndAtCurrentMonth(monthLabels);
            };

            const orderedData: any = [];

            Object.keys(data)
                .sort((a, b) => parseInt(a, 10) - parseInt(b, 10))
                .forEach((key, index) => {
                    orderedData[index] = data[key];
                });

            const rotatedData = rotateArrayToEndAtCurrentMonth(orderedData);

            const parsedData = {
                labels: monthsLabelsEndingWithCurrent(),
                datasets: [
                    {
                        type: 'bar',
                        label: intl.formatMessage({ id: 'learning_tests.attempted_activities' }),
                        data: Object.values(rotatedData).map((month: any) => month.count),
                        backgroundColor: 'rgb(102, 148, 224)',
                        hoverBackgroundColor: 'rgb(82, 128, 204)',
                        yAxisID: 'y-axis-absolute',
                        order: 1,
                    },
                ],
            };

            setData(parsedData);
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <BarLineChart
            data={data}
            title={intl.formatMessage({ id: 'learning_tests.completion_over_time' })}
            loading={isLoading}
        />
    );
};

export default CompletionOverTimeChart;