import React, {useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom';
import {CheckOutlined, CloseOutlined, ExclamationCircleOutlined, MenuOutlined} from '@ant-design/icons';
import {FormattedMessage, useIntl} from 'react-intl';
import {Button, InputNumber, message, Modal, Popover, Table, Tag} from 'antd';
import {RequestSelect, Switch} from 'components/Form';
import {connect} from 'react-redux';
// import {Table} from "ui";
import { TreeSelect } from 'antd';
import debounce from "lodash/debounce";
import type { SortableContainerProps, SortEnd } from 'react-sortable-hoc';
import { SortableContainer, SortableElement, SortableHandle, arrayMove } from 'react-sortable-hoc';

import useHandleError from "utils/useHandleError";
import apiClient from "utils/apiClient";
import generalHelpers from "utils/generalHelpers";
import coursesApiClient from "utils/coursesApiClient";
import Spinner from 'components/Spinner';

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale,
        session: state.session
    }
};

interface TestQuestionBanksInterface {
    locale?: string;
    session?: any;
    learningTest: any;
    loadTotalTestScores: Function
}

const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

const SortableItem = SortableElement((props: React.HTMLAttributes<HTMLTableRowElement>) => (
    <tr {...props} />
));
const SortableBody = SortableContainer((props: React.HTMLAttributes<HTMLTableSectionElement>) => (
    <tbody {...props} />
));

const TestQuestionBanks: React.FC<TestQuestionBanksInterface> = ({learningTest, loadTotalTestScores, session}) => {
    const [dataSource, setDataSource] = useState<any>([]);
    const [selectedBank, setSelectedBank] = useState<number>(0)
    const [selectedBankValue, setSelectedBankValue] = useState<string>('')
    const [isLoading, setIsLoading] = useState(false)
    const [treeData, setTreeData] = useState<any>([]);
    const [visibleTooltip, setVisibleTooltip] = useState<number | null>(null);

    const intl = useIntl();
    const [handleError] = useHandleError();
    const {confirm} = Modal;

    const handleVisibleChange = (visible: boolean, recordId: number) => {
        if (visible) {
            setVisibleTooltip(recordId);
        } else {
            setVisibleTooltip(null);
        }
    };

    useEffect(() => {
        loadQuestionBanks()
    }, [learningTest])

    useEffect(() => {
        loadTreeData();
    }, [dataSource])

    useEffect(() => {
        if(selectedBankValue) {
            const value = (selectedBankValue.split('-'))[1];
            setSelectedBank(parseInt(value))
        }
    }, [selectedBankValue])

    const handleUpdate = debounce((record: any, value:any) => {
        record.includedQuestions = value;
        updateQuestionBank(record.id, record)
        loadTotalTestScores()
    }, 800)

    const columns = [
        {
            title: intl.formatMessage({id: 'question_pools.question_bank'}),
            dataIndex: 'title',
            key: 'title',
            render: (text: string, record: any) => {
              return (
                  <NavLink
                    to={
                        `/question-pools/question-banks/${record.questionBankId}/edit`
                    }
                    target="_blank"
                  >
                    <span>{record.title}</span>

                    {!record.isOnline ? <Tag color={record.isOnline ? 'green' : 'red'} style={{marginLeft: '5px'}}>
                        {record.isOnline ? 'ONLINE' : 'OFFLINE'}
                    </Tag> : null}
                  </NavLink>
                )
            }
        },
        {
            title: intl.formatMessage({id: 'general.id'}),
            dataIndex: 'id',
        },
        {
            title: intl.formatMessage({id: 'question_pools.questions'}),
            dataIndex: 'questionsCount',
        },
        {
            title: intl.formatMessage({id: 'question_pools.critical'}),
            dataIndex: 'criticalQuestionsCount',
        },
        {
            title: intl.formatMessage({id: 'learning_tests.included_questions'}),
            render: (_text: string, record: any) => {

                return (
                    <InputNumber
                        defaultValue={record.includedQuestions}
                        disabled={isLoading || !record.isOnline}
                        onChange={(value: any) => handleUpdate(record, value)}
                        max={record.questionsCount}
                    />
                )
            }
        },
        {
            title: intl.formatMessage({id: 'learning_tests.shuffle'}),
            render: (_text: string, record: any) => {

                return (
                    <Switch
                        checkedChildren={<CheckOutlined/>}
                        unCheckedChildren={<CloseOutlined/>}
                        checked={!!record.shuffleQuestions}
                        loading={isLoading}
                        disabled={!record.isOnline}
                        onChange={() => {
                            record.shuffleQuestions = !record.shuffleQuestions;
                            updateQuestionBank(record.id, record)
                            loadTotalTestScores()
                        }}
                    />
                )
            }
        },
        {
            title: intl.formatMessage({id: 'general.sort'}),
            width: 30,
            className: 'drag-visible',
            render: () => <DragHandle />,
        },
        {
            title: intl.formatMessage({id: 'general.actions'}),
            render: (text: string, record: any, index: number) => {
                const content = <>
                  <div onClick={() => warnDeletion(record)}>
                    <div className="popover-item">
                      <FormattedMessage id="general.delete"/>
                    </div>
                  </div>
                </>;
      
                return (
                  <Popover
                    open={index === visibleTooltip}
                    content={content}
                    trigger="click"
                    placement="bottomRight"
                    arrowPointAtCenter
                    onOpenChange={(visible) => handleVisibleChange(visible, index)}
                  >
                    <div style={{width: '100%', cursor: 'pointer', textAlign: 'center'}}>
                      <i className="fal fa-ellipsis-v" style={{fontSize: '16px'}} />
                    </div>
                  </Popover>
                )
            },
        },
    ];

    const warnDeletion = async (record: any) => {
        if (record.typeUuid === session.active_user_type_uuid) {
            message.warning(intl.formatMessage({id: 'laerning_tests.test_is_online'}));
            return;
        }

        confirm({
            title: intl.formatMessage({id: 'general.attention'}),
            content: intl.formatMessage({id: 'learning_tests.delete_question_bank'}),
            icon: <ExclamationCircleOutlined/>,
            okText: intl.formatMessage({id: 'general.yes'}),
            cancelText: intl.formatMessage({id: 'general.no'}),

            onOk() {
                deleteResource(record.id);
            }
        });
    }

    const deleteResource = async (id: number) => {
        setIsLoading(true)
        try {
            const response = await coursesApiClient.request(`/api/v1/courses/learning-tests/question-banks/${id}/delete`, {}, 'DELETE');
            if(response.deleted) {
                loadQuestionBanks()
            } else {
                message.error(intl.formatMessage({id: 'learning_tests.test_is_online'}))
            }
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    const addQuestionBank = async () => {
        if (selectedBank) {
            setIsLoading(true)
            const parsedValues = {
                shuffleQuestions: true,
                sortingOrder: 0,
                includedQuestions: 0,
                questionBankId: selectedBank,
                learningTestId: learningTest.id
            }
            try {
                await coursesApiClient.request(`/api/v1/courses/learning-tests/question-banks/store`, parsedValues, 'POST');
                setSelectedBank(0)
                setSelectedBankValue('')
                loadQuestionBanks()
            } catch (error) {
                handleError(error)
            } finally {
                setIsLoading(false)
            }
        }
    }

    const updateQuestionBank = async (id: number, questionBank: any) => {
        setIsLoading(true)
        try {
            await coursesApiClient.request(`/api/v1/courses/learning-tests/question-banks/${id}/update`, questionBank, 'PUT');
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    const updateSortingOtder = async (sortIds: any) => {
        setIsLoading(true)
        try {
            await coursesApiClient.request(`/api/v1/courses/learning-tests/question-banks/sort`, {sortIds}, 'POST');
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    const loadTreeData = async () => {
        setIsLoading(true)
        try {
            const selectedBanks = dataSource.map((item: any) => {
                return item.questionBankId;
            })
            const response = await coursesApiClient.request(`/api/v1/courses/question-pools/tree-options`, {selectedBanks}, 'GET');
            setTreeData(response.tree)
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    const loadQuestionBanks = async () => {
        setIsLoading(true)
        try {
            const response = await coursesApiClient.request(`/api/v1/courses/learning-tests/${learningTest.id}/question-banks`, {}, 'GET');
            setDataSource(response.testBanks)
            loadTotalTestScores()
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
        if (oldIndex !== newIndex) {
          const newData = arrayMove(dataSource, oldIndex, newIndex)
          const sortIds = newData.map((item: any, key: number) => {
            return item.id
          })
          updateSortingOtder(sortIds)
          setDataSource(newData);
        }
    };

    const DraggableContainer = (props: SortableContainerProps) => (
        <SortableBody
          useDragHandle
          disableAutoscroll
          helperClass="row-dragging"
          onSortEnd={onSortEnd}
          {...props}
        />
    );

    const DraggableBodyRow: React.FC<any> = ({ className, style, ...restProps }) => {
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = dataSource.findIndex((x: any) => x.id === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };
    
    return (
        <>
            <div className='flex-left gap-8'>
                <FormattedMessage id='learning_tests.add_question_bank_to_draw_questions'/>
                <TreeSelect
                    showSearch
                    showArrow
                    value={selectedBankValue}
                    style={{ width: '300px' }}
                    placeholder="Please select"
                    allowClear
                    treeDefaultExpandAll
                    onChange={(value: string) => setSelectedBankValue(value)}
                    treeData={treeData}
                    treeNodeFilterProp='title'
                />
                <Button type='default' onClick={() => addQuestionBank()} loading={isLoading}>
                    <FormattedMessage id='general.add'/>
                </Button>
            </div>
            <Table
                columns={columns}
                pagination={false}
                // url={`/api/v1/courses/learning-tests/${learningTest.id}/question-banks`}
                // setReload={setReload}
                // reload={reload}
                dataSource={dataSource}
                rowKey={'id'}
                components={{
                    body: {
                        wrapper: DraggableContainer,
                        row: DraggableBodyRow,
                    },
                }}
            />
        </>
    )
};

export default connect(mapStateToProps)(TestQuestionBanks);
