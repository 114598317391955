import React, { useEffect, useRef, useState } from 'react';
import { useResource } from "hooks/Resources/useResource";
import { Button, Select, Space } from 'antd';
import { NavLink } from 'react-router-dom';
import './styles.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import { DefaultOptionType } from 'antd/lib/select';
import { ResourceProps, ResourceType } from '../types';


interface AddResourcesInterface {
    value?: number[],
    onChange?(values: number[]): void,
    types?: ResourceType[],
    limit?: number,
}

const AddResources: React.FC<AddResourcesInterface> = ({ value, onChange, types, limit }) => {
    const { getResources, resourceLoading } = useResource();
    const [resources, setResources] = useState<number[]>(value || []);
    const [options, setOptions] = useState<DefaultOptionType[]>([]);
    const [selected, setSelected] = useState<number>();


    const didMountRef = useRef(false);
    const intl = useIntl();

    useEffect(() => {
        if (!didMountRef.current) {
            didMountRef.current = true;
        } else {
            onChange && onChange(resources);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resources]);

    useEffect(() => {
        if (!!value) {
            setResources(value);
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    useEffect(() => {
        const formatResourceOptions = (resources: ResourceProps[]): DefaultOptionType[] => {
            const result: DefaultOptionType[] = [];

            resources.forEach((resource: ResourceProps) => {
                const label = getFormattedTitle(resource.resourceType, resource.title);

                const options = resource.children?.map((option: ResourceProps) => {
                    return {
                        label: option.title,
                        value: option.id
                    }
                });

                result.push({
                    label, options
                })
            });

            return result;
        }

        const loadResources = async () => {
            setOptions(formatResourceOptions(await getResources(types)))
        };

        // AddResource

        loadResources();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleAdd = () => {
        if (selected) {
            const resource = findOptionByValue(+selected);

            if (resource && resource.value) {
                setResources(Array.from(new Set([...resources, +selected])));
                setSelected(undefined);
            }
        }
    }

    const findOptionByValue = (id: number) => {
        const result = options.find((option: any) => option.value === id);

        if (result) {
            return result;
        }

        for (const childrens of options) {
            const result = childrens.options.find((option: any) => option.value === id)
            if (result) {
                return result;
            }
        }
    }

    const getFormattedTitle = (resourceType: string | undefined, title: string | undefined): string => {
        const typeTranslation = intl.formatMessage({ id: `system.resource.type.${resourceType}`});
        
        return !!resourceType && resourceType !== ResourceType.Other ? `${typeTranslation}${!!title ? ' - ' + title : ''}` : title ?? ''
    }

    const removeResource = (value: number) => {
        setResources(resources.filter((resource) => resource !== value));
    }

    return (<>
        <Space className="resources-select-space-container">
            <Select className='default-select'
                value={selected}
                showSearch
                optionFilterProp="children"
                onChange={setSelected}
                filterOption={(input, option:any) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={options ?? []}
                loading={resourceLoading}
            />
            <Button type="primary" onClick={handleAdd}
                disabled={!!limit && resources.length >= limit}
            >
                <FormattedMessage id='general.add' />
            </Button>
        </Space>
        <Space size={[8, 8]} wrap>
            {resources.map((resource, index) => {
                const current = findOptionByValue(resource);
                if (current) {
                    return (
                        <div>
                            <NavLink to={`#`}>{current.label}</NavLink>
                            <Button style={{
                                color: "#8C8C8C",
                                verticalAlign: 0
                            }}
                                onClick={() => removeResource(resource)}
                                size="small" type="link" key={index} icon={<i className="fa-solid fa-xmark" />} />
                        </div>

                    )
                }
            })}
        </Space>
    </>
    )
};

export default AddResources;
