import React, {useState} from 'react';
import {Checkbox, DatePicker, Divider, Form} from 'antd';
import {FormattedMessage} from "react-intl";
import {useUsers} from "hooks/Users/useUsers";
import FilterCheckBox from "../FilterCheckBox";
import FilterPopover from "../FilterPopover";
import {Button} from "ui";

interface UsersFilterInterface {
    tagBuilder: Function;
    orgUnits: string[];
    departments: string[];
    customers: string[];
    position: string[];
    setFilterValues: any;
    filterValues: any;
    form: any;
    filterKey: string;
    setFilterKey: React.Dispatch<React.SetStateAction<string>>;
}

const UsersFilter: React.FC<UsersFilterInterface> = ({
                                                         filterKey,
                                                         setFilterKey,
                                                         form,
                                                         tagBuilder,
                                                         orgUnits,
                                                         departments,
                                                         customers,
                                                         position,
                                                         setFilterValues,
                                                         filterValues
                                                     }) => {
    const {searchUsers, loading} = useUsers()
    const [data, setData] = useState([]);
    const [initialValues, setInitialValues] = useState(undefined)

    const key = 'users'

    const onSearch = async (value: string) => {

        const response = await searchUsers(value, orgUnits, departments, customers, position)
        if (response) {
            setData(response);
        } else {
            setData([]);
        }
        setFilterValues(
            {
                ...filterValues,
                userTypeId: {
                    ...filterValues.userTypeId,
                    ...response
                }
            }
        )
    };

    const content = (
        <>
            <FilterCheckBox data={data} name={'userTypeId'} onSearch={onSearch}/>
            <div className={'p-1 checkbox-wrapper'}>
                <Form.Item className={'mb-0'} name='userStatus'>
                    <Checkbox.Group>
                        <Checkbox value="ACTIVE">
                            <FormattedMessage id='user.status.ACTIVE'/>
                        </Checkbox>
                        <Checkbox value="BLOCKED">
                            <FormattedMessage id='user.status.BLOCKED'/>
                        </Checkbox>
                    </Checkbox.Group>
                </Form.Item>
                <Divider plain className={'m-2'}/>
            </div>
            <div className={'p-1 checkbox-wrapper'}>
                <Form.Item className={'mb-0'} name='userAbsence'>
                    <Checkbox.Group>
                        <Checkbox value={1}>
                            <FormattedMessage id='general.yes'/>
                        </Checkbox>
                        <Checkbox value={0}>
                            <FormattedMessage id='general.no'/>
                        </Checkbox>
                    </Checkbox.Group>
                </Form.Item>
                <Divider plain className={'m-2'}/>
                <div className={'bold'}>
                    <FormattedMessage id={'users.form.commencement_date'}/>
                </div>
                <div className={'flex flex-column checkbox-wrapper'}>
                    <Form.Item name={'commencementDate'}>
                        <DatePicker.RangePicker/>
                    </Form.Item>
                </div>
                <Divider plain className={'m-2'}/>
            </div>
            <div className={'flex justify-between p-1'}>
                <Button type={'link'} onClick={() => handleClose()}>
                    <FormattedMessage id={'general.cancel'}/>
                </Button>
                <Button type={'primary'} onClick={() => {
                    setFilterKey('')
                    handleOpen(false)
                }}>
                    <FormattedMessage id={'general.select'}/>
                </Button>
            </div>
        </>
    );

    const handleClose = () => {
        form.setFieldsValue(initialValues);
        setFilterKey('');
    }

    const handleOpen = (open: boolean) => {
        if (open) {
            setFilterKey(key)
            setInitialValues(form.getFieldsValue())
            setFilterValues(
                {
                    ...filterValues,
                    userTypeId: {
                        ...filterValues.userTypeId,
                        ...data
                    }
                }
            )
        } else {
            tagBuilder()
        }
    }

    return (
        <FilterPopover
            popoverKey={key}
            filterKey={filterKey}
            setOpen={setFilterKey}
            placement={'bottomRight'}
            handleOpen={handleOpen}
            content={content}
            loading={loading}
            title={'general.search_by_user'}
            buttonTitle={'general.users'}
        />
    );
};

export default UsersFilter;