import React, {useState, useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {connect} from 'react-redux';
import {useIntl} from 'react-intl';
import {Form, message, Button} from 'antd';
import apiClient from 'utils/apiClient';
import TemplateForm from "../TemplateForm";
import coursesApiClient from "utils/coursesApiClient";
import useHandleError from "utils/useHandleError";
import htmlTemplateParser from "../../../utils/htmlTemplateParser";
import htmlTemplateOrganizationParser from "../../../utils/htmlTemplateOrganizationParser";

const EmailTemplateFormCustomer: React.FC<any> = ({}) => {
    const [template, setTemplate] = useState();
    const [mailableArray, setMailableArray] = useState<any>([]);
    const [supportedLanguages, setSupportedLanguages] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [htmlTemplate, setHtmlTemplate] = useState<any>('');

    const params = useParams();
    const [form] = Form.useForm();
    const intl = useIntl();
    const navigate = useNavigate();

    const templateId: any = params.id;
    const [handleError] = useHandleError();

    useEffect(() => {
        if (templateId) {
            loadTemplate();
        }
        loadFrom()
    }, [])


    const loadTemplate = async (language: any = '') => {
        if (!templateId) {
            return;
        } else {
            setIsLoading(true);
        }

        try {
            let url = `/api/v1/mail-templates/customer/${templateId}&`;

            if (language) {
                url += language
            }

            const response = await coursesApiClient.request(url, [], 'GET');

            if (!response.emailTemplate.language) {
                form.setFieldsValue({
                    title: '',
                    subject: '',
                    smsTemplate: '',
                })
                setHtmlTemplate('');
                return;
            }

            const templateData = response.emailTemplate;
            const html = htmlTemplateOrganizationParser.htmlTemplateOrganizationParser(templateData.htmlTemplate ? templateData.htmlTemplate : templateData.organizationHtml ? templateData.organizationHtml : templateData.defaultHtml , intl);

            setTemplate(response.emailTemplate);
            setHtmlTemplate(html);
            setSupportedLanguages(templateData.availableLanguages)

            form.setFieldsValue({
                title: templateData.title,
                categoryId: templateData.categoryId,
                learningCampaignNotificationType: templateData.learningCampaignNotificationType,
                daysBefore: templateData.daysBefore,
                every: templateData.every,
                from: templateData.from,
                subject: templateData.subject,
                smsTemplate: templateData.smsTemplate,
            })

        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false);
        }
    }

    const loadFrom = async () => {
        const response = await apiClient.request('/api/v1/customize-environment/template-email-settings', [], 'GET');
        form.setFieldsValue({
            from: response.from
        });
    };

    const deleteLangugae = async (language: string) => {
        const newSupportedLanguages = supportedLanguages.filter((element: string) => {
            return element !== language
        });
        setSupportedLanguages(newSupportedLanguages);
    };

    const submitForm = async (values: any, language: any = '') => {
        setIsLoading(true);

        const parsedValues = {
            ...values,
            mailable: mailableArray[values.mailable],
            htmlTemplate: htmlTemplateParser.htmlTemplateParse(htmlTemplate),
            language: values.language
        }

        try {
            if (templateId) {
                await apiClient.request(`/api/v1/mail-templates/customer/${templateId}`, parsedValues, 'PUT');
            } else {
                await apiClient.request(`/api/v1/mail-templates/customer`, parsedValues, 'POST');
            }

            if (language) {
                await loadTemplate(language)
            } else {
                message.success(templateId ? intl.formatMessage({id: "emailing.templates.updated_successfully"}) : intl.formatMessage({id: "emailing.template_created"}));
                navigate('/e-mailing/manage-templates');
            }

        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <TemplateForm
            form={form}
            setMailableArray={setMailableArray}
            mailableArray={mailableArray}
            submitForm={submitForm}
            template={template}
            isLoading={isLoading}
            supportedLanguages={supportedLanguages}
            setSupportedLanguages={setSupportedLanguages}
            htmlTemplate={htmlTemplate}
            setHtmlTemplate={setHtmlTemplate}
        />
    );
};

export default EmailTemplateFormCustomer;
