import React from 'react';
import {useIntl} from 'react-intl';
import {Select} from 'components/Form';

interface LearningStatusSelectInterface {
    name?: string | string[];
    label?: string;
    layout?: {
        labelCol: {
            span: number
        }
        wrapperCol: {
            span: number
        }
    }
    disabled?: boolean;
    customRules?: any;
    type: string;
    hasDefaultLayout?: boolean;
    isFormItem?: boolean;
    onChange: any;
}

const LearningStatusSelect: React.FC<LearningStatusSelectInterface> = ({
                                                                           name,
                                                                           label,
                                                                           type,
                                                                           layout,
                                                                           disabled = false,
                                                                           customRules = [],
                                                                           hasDefaultLayout,
                                                                           isFormItem = true,
                                                                           onChange
                                                                       }) => {
    const intl = useIntl();

    const expectedResult = {
        'NOT_STARTED': intl.formatMessage({id: 'general.not_started'}),
        'IN_PROGRESS': intl.formatMessage({id: 'general.in_progress'}),
        'COMPLETED': intl.formatMessage({id: 'general.completed'})
    };

    const assessmentResult = {
        'IN_PROGRESS': intl.formatMessage({id: 'general.in_progress'}),
        'PASSED': intl.formatMessage({id: 'general.passed'}),
        'FAILED': intl.formatMessage({id: 'general.failed'}),
        'FINAL_ASSESSMENT': intl.formatMessage({id: 'assessment.final_assessment'}),
    };

    let formItemLayout = layout || {}

    if (hasDefaultLayout) {
        formItemLayout = {
            labelCol: {span: 6},
            wrapperCol: {span: 18}
        }
    }

    return (
        <Select
            customLayout={formItemLayout}
            isFormItem={isFormItem}
            name={name}
            label={label ? intl.formatMessage({id: label}) : null}
            manualOptions={type === 'ASSESSMENT' ? assessmentResult : expectedResult}
            onChange={() => onChange ? onChange(name): null}
            disabled={disabled}
            customRules={customRules}
        />
    )
}
export default LearningStatusSelect;
