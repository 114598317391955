import React, {useState, useEffect, useRef} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useNavigate, useParams} from 'react-router-dom';
import {Select, Divider, Input as AntdInput, FormInstance} from 'antd';
import DefaultLayout from "components/DefaultLayout";
import {Space, Form} from "antd";
import {Button} from 'ui';
import apiClient from "utils/apiClient";
import useHandleError from "utils/useHandleError";
import Input from 'components/Form/Input';
import {Title, Switch, InputNumber} from "components/Form";
import {config} from "config/config";
import type {InputRef} from 'antd';

interface RecordType {
    key: string;
    name: string;
}

interface CompetencesSettingsInterface {
    form: FormInstance
    competence: any;
}

const CompetencesSettings: React.FC<CompetencesSettingsInterface> = ({form, competence}) => {
    const [customers, setCustomer] = useState<RecordType[]>([]);
    const [blocks, setBlocks] = useState({});
    const [isBlockLoading, setIsBlockLoading] = useState(false);
    const [noBlock, setNoBlock] = useState(false);
    const [name, setName] = useState('');

    const intl = useIntl();
    const [handleError] = useHandleError();
    const navigate = useNavigate();
    const params = useParams();
    const competenceId = params.id;
    const inputRef = useRef<InputRef>(null);

    const {Option} = Select;

    useEffect(() => {
        if (competence?.id) {
            loadBlocks();
        }
    }, [competence]);

    useEffect(() => {
        loadCustomers();
        if (!competenceId) {
            loadBlocks();
        }
    }, []);

    const setFormItems = (blockList: Record<number, string>) => {

        const id: number = competence.parentId;

        if (blockList[id] === null) {
            setNoBlock(true);
            form.setFieldsValue({
                title: competence.title,
                noBlock: true,
                sequence: competence.sequence
            })
        } else {
            form.setFieldsValue({
                title: competence.title,
                parentId: competence.parentId.toString(),
                sequence: competence.sequence
            })
        }
    }

    const checkFieldValues = function () {
        form.validateFields(['title', 'parentId', 'sequence']);

        const values = form.getFieldsValue(['title', 'parentId', 'sequence'])

        if (values.parentId === undefined && !noBlock) {
            return;
        }
        if (values.parentId && noBlock) {
            return;
        }

        values.noBlock = noBlock;
        return values;
    }

    const submitForm = async () => {
        const parsedValues = checkFieldValues();

        if (parsedValues) {
            try {
                await apiClient.request('/api/v1/competences/' + competence.id, parsedValues, 'PUT');
            } catch (error) {
                handleError(error)
            }
        }
    }

    const loadBlocks = async () => {

        try {
            const response = await apiClient.request('/api/v1/competences/options/blocks', [], 'GET');

            if (response) {
                setBlocks(response)
            }

            if (competence) {
                setFormItems(response)
            }

        } catch (error) {
            handleError(error)
        }
    };

    const loadCustomers = async () => {
        const tempData: any = [];

        const response = await apiClient.request(config.api.routes.backend.selectCustomers, [], 'GET');

        const customers = response.customers

        Object.entries(customers).map((customer: any) => {
            const data = {
                key: customer[0].toString(),
                name: customer[1]
            };

            tempData.push(data);
        })
        setCustomer(tempData);
    };

    const addItem = async () => {
        setIsBlockLoading(true);
        try {
            await apiClient.request('/api/v1/competences', {title: name}, 'POST');
            loadBlocks()
        } catch (error) {
            handleError(error)
        } finally {
            setIsBlockLoading(false);
        }
    };

    const saveCompetence = async () => {
        const parsedValues = checkFieldValues();

        if (parsedValues) {
            try {
                const response = await apiClient.request('/api/v1/competences', parsedValues, 'POST');
                navigate('/competences/' + response.competences.id)
            } catch (error) {
                handleError(error)
            } finally {
            }
        }
    }

    return (
        <div className='competences-settings-wrapper'>
            <Title font='14px'>
                <FormattedMessage id='competences.settings'/>
            </Title>
            <Input
                name="title"
                customLayout
                label={intl.formatMessage({id: 'general.title'})}
                customRules={
                    [{
                        required: true,
                        message: intl.formatMessage({id: 'validation.field_required'}),
                    },
                    ]}
            />
            <Form.Item name='parentId' label={intl.formatMessage({id: 'competence.block'})}
                       rules={[{
                           required: !noBlock,
                           message: intl.formatMessage({id: 'validation.field_required'}),
                       },
                       ]}
            >
                <Select
                    allowClear
                    disabled={noBlock}
                    dropdownRender={menu => (
                        <>
                            {menu}
                            <Divider style={{margin: '8px 0'}}/>
                            <Space style={{padding: '0 8px 4px'}}>
                                <AntdInput
                                    ref={inputRef}
                                    value={name}
                                    onChange={(event: any) => setName(event.target.value)}
                                />
                                <Button type="outline" onClick={addItem} loading={isBlockLoading}>
                                    <FormattedMessage id='general.add'/>
                                </Button>
                            </Space>
                        </>
                    )}
                >
                    {Object.entries(blocks).map((item: any) => {
                        if (!item) {
                            return;
                        }
                        if (item[1] === null) {
                            return;
                        }
                        return <Option key={item[0]}>{item[1]}</Option>
                    })}
                </Select>
            </Form.Item>
            <Switch
                checked={noBlock}
                isFormItem name={'noBlock'}
                onChange={(bool: boolean) => {
                    setNoBlock(bool)
                    form.setFieldValue('parentId', null,)
                }}
                label={intl.formatMessage({id: 'general.competence_without_block'})}
            />
            <InputNumber
                name='sequence'
                label={intl.formatMessage({id: 'courses.sequence_priority'})}
                customLayout
                min={1}
                initialValue={null}
            />
            {!competenceId &&
                <div className='flex-end'>
                    <Button type="primary" onClick={() => saveCompetence()} loading={isBlockLoading}>
                        <FormattedMessage id='general.submit'/>
                    </Button>
                </div>
            }
            <DefaultLayout.PageFooter
                right={
                    <>
                        <Button type="default" onClick={() => navigate('/competences')}>
                            <FormattedMessage id='general.cancel'/>
                        </Button>
                        {competenceId &&
                            <Button type="primary" onClick={() => submitForm()} loading={isBlockLoading}>
                                <FormattedMessage id='general.submit'/>
                            </Button>
                        }
                    </>
                }
            />
        </div>
    )
};

export default CompetencesSettings;
