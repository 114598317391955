import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { DatePicker, Form, TreeSelect } from 'antd';
import { Modal } from 'ui';
import Spinner from 'components/Spinner';
import moment from 'moment';
import { useResource } from 'hooks/Resources/useResource';
import { ResourceType } from '../types';

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

interface ResourceReservationCreationModaltInterface {
    visible?: boolean | undefined;
    onCancel(cancel: boolean): any;
    onSubmit(): any;
    reservationData: {
        id?: Number,
        resourceId?: Number,
        start?: Date,
        end?: Date,
        day: any,
    };
}

interface ResourceTree {
    value: number,
    title: string,
    selectable?: boolean,
    disabled?: boolean,
    children?: ResourceTree[],
}

const ResourceReservationCreationModal: React.FC<ResourceReservationCreationModaltInterface> = ({ reservationData, visible = false, onCancel, onSubmit }) => {
    const [resourcesTreeData, setResourcesTreeData] = useState<ResourceTree[]>([]);
    const { getResources, saveResourceReservation, resourceLoading } = useResource();
    const intl = useIntl();
    const [form] = Form.useForm();
    const { RangePicker } = DatePicker;

    useEffect(() => {

        const fetchResources = async () => {
            const resources = await getResources();

            if (!!resources.length) {
                const formattedData: ResourceTree[] = resources.map((row: any) => {
                    const typeTranslation = intl.formatMessage({ id: `system.resource.type.${row.resourceType}`});

                    return {
                        value: row.id,
                        title: !!row.resourceType && row.resourceType !== ResourceType.Other ? `${typeTranslation}${!!row.title ? ' - ' + row.title : ''}` : row.title ?? '',
                        selectable: false,
                        children: row.children.map((children: any) => {
                            return {
                                value: children.id,
                                title: children.title,
                            }
                        }),
                    }
                });

                setResourcesTreeData(formattedData);
            }
        }

        fetchResources();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (reservationData) {
            form.setFieldsValue({
                resourceId: reservationData.resourceId?.toString(),
                dates: [
                    !!reservationData.start ? moment(reservationData.start) : moment(reservationData.day?.dateStr, "YYYY-MM-DD").hours(moment().hours()).startOf('hour'),
                    !!reservationData.start ? moment(reservationData.end ?? reservationData.start) : moment(reservationData.day?.dateStr, "YYYY-MM-DD").hours(moment().hours()).add(1, 'hours').startOf('hour')
                ]
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reservationData])

    const handleOnCancel = () => {
        form.resetFields();
        onCancel(false)
    }

    const handleFormSubmit = async (values: any) => {
        const postData = {
            id: reservationData.id,
            start: values.dates[0].format(),
            end: values.dates[1].format(),
            resourceId: values.resourceId,
        }

        if (await saveResourceReservation(postData)) {
            onSubmit();
        }
    };

    return (<Modal destroyOnClose
        open={visible}
        confirmLoading={resourceLoading}
        onCancel={() => handleOnCancel()}
        title={!!reservationData?.id ? intl.formatMessage({ id: 'system.resource.reservations.edit' }) : intl.formatMessage({ id: 'system.resource.reservations.create_new' })}
        okText={!!reservationData?.id ? intl.formatMessage({ id: 'general.save' }) : intl.formatMessage({ id: 'general.create' })}
        cancelText={intl.formatMessage({ id: 'general.cancel' })}
        onOk={form.submit}
    >
        <Spinner spinning={resourceLoading}>
            <Form form={form} onFinish={handleFormSubmit} {...formItemLayout} preserve={false}>
                <Form.Item
                    name="resourceId"
                    label={intl.formatMessage({ id: 'system.resource' })}
                    rules={[{ required: true, message: intl.formatMessage({ id: 'validation.field_required' }) }]}
                    {...formItemLayout}
                >
                    <TreeSelect className="default-select"
                        showSearch
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        allowClear
                        treeDefaultExpandAll
                        treeData={resourcesTreeData}
                    />
                </Form.Item>

                <Form.Item
                    name="dates"
                    label={intl.formatMessage({ id: 'general.date' })}
                    rules={[{ required: true, message: intl.formatMessage({ id: 'validation.field_required' }) }]}
                    {...formItemLayout}
                >
                    <RangePicker showTime={{
                        format: "HH:mm",
                        showSecond: false,
                        minuteStep: 15
                    }}
                        format="YYYY-MM-DD HH:mm"
                    />
                </Form.Item>
            </Form>
        </Spinner>
    </Modal>
    )
};

export default ResourceReservationCreationModal;
