import React, {useState} from 'react';
import {Popover} from 'antd';
import {useNavigate} from "react-router-dom";
import {FormattedMessage} from 'react-intl';
import '../styles.scss';

const RoleSwitch: React.FC<any> = ({roles, onClick, selectedUserTypeId, usersName, isMobile}) => {
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  let selectedRole = roles && selectedUserTypeId
    ? (roles.find((el: any) => parseInt(el.userTypeId) === parseInt(selectedUserTypeId)) || {})
    : {};

  const PopoverContent = () => {
    return (
      <>
        {roles.map((el: any, index: number) =>
          <div key={index} className='select-item' onClick={() => {onClick(el.userTypeId); setIsOpen(false); navigate('/')}}>
            <span>{usersName} - <FormattedMessage id={`user_type.roles.${el.role}`}/></span>
          </div>)}
      </>
    )
  };

  return <div className={isMobile ? 'session-switch-item-mobile' : 'session-switch-item'}>
    <Popover
      placement='bottomLeft'
      content={<PopoverContent />}
      overlayClassName='session-switch-popover'
      arrowContent={false}
      open={isOpen}
      trigger='click'
      onOpenChange={() => setIsOpen(!isOpen)}
    >
      <div className='role-switch'>
        <i className='fal fa-user-circle with-accent' />
        {
          <span className='display-role-name'>
            {usersName} - {selectedRole.role
              ? <FormattedMessage id={`user_type.roles.${selectedRole.role}`}/>
              : '-'
            }
          </span>
        }
        <i className='fal fa-chevron-down' style={{fontSize: 10}} />
      </div>
    </Popover>
  </div>
}

export default RoleSwitch;
