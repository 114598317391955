import React, {useState, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import { Form, Upload, Button, Image } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import coursesApiClient from 'utils/coursesApiClient';
import useHandleError from "utils/useHandleError";

interface ThumbUploaderInterface {
    baseFieldName?: string|number;
    fieldName: string;
    fileInfo: any;
    onChangeCallback: Function;
}

const ThumbUploader: React.FC<ThumbUploaderInterface> = ({ 
    baseFieldName,
    fieldName,
    fileInfo,
    onChangeCallback
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [fileList, setFileList] = useState<any>([]);
    const [fileUrl, setFileUrl] = useState<any>();

    const intl = useIntl();
    const [handleError] = useHandleError();

    useEffect(() => {
        if(fileInfo?.fileData) {
            setFileList([
                {
                    uid: fileInfo?.fileId,
                    status: 'done',
                    url: fileInfo?.fileData,
                },
            ])
        } else {
            setFileList([])
        }
    }, [fileInfo])

    useEffect(() => {
        if(fileList.length) {
            loadFileUrl(fileList[0])
        } else {
            setFileUrl(null)
        }
    }, [fileList])

    const handleOnChange = async (e: any) => {
        if( e.fileList && e.fileList.length > 0 ) {
            setFileList([...e.fileList]);
            onChangeCallback({[fieldName]: e})
        } else if(e?.file?.status == 'removed') {
            setFileList([]);
            onChangeCallback({[fieldName]: 'removed'})
        }
    }

    const handleDelete = async (e: any) => {
        setFileList([]);
        onChangeCallback({[fieldName]: 'removed'})
    }

    const loadFileUrl = async (file: any) => {
        let src: string = file?.url as string;
        if (!src) {
            src = await new Promise((resolve: any) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result as string);
            });
        }

        setFileUrl(src)
    }

    return (
        <>
            <Form.Item
                name={baseFieldName ? [baseFieldName, fieldName] : fieldName}
                {...{labelCol: {span: 0}, wrapperCol: {span: 24}}}
                className="question-pool-uploader"
                style={{marginBottom: 0}}
                >
                {fileUrl ? (
                    <div className='flex gap-5 align-center image-upload-prreview'>
                        <Image
                            className='thumbnail-preview'
                            width={20}
                            height={20}
                            src={fileUrl}
                            preview={{
                                mask: <i className="fa-regular fa-eye"></i>
                            }}
                        />
                        <Button type="link" size='small' danger icon={<i className="fa-regular fa-trash"></i>} onClick={handleDelete} />
                    </div>
                ) : <Upload
                    beforeUpload={() => false}
                    accept="image/*"
                    maxCount={1}
                    fileList={fileList}
                    listType="picture"
                    onChange={handleOnChange}
                    //showUploadList={{showPreviewIcon: true}}
                    multiple={false}
                >
                    <Button type="link" icon={<i className="fa-regular fa-image"></i>} />
                </Upload>}
            </Form.Item>
        </>
    );
};

export default ThumbUploader;
    