import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Progress, Spin, Space } from 'antd';
import moment from 'moment';
import ActivityCard from 'components/ActivityCard';
import DefaultLayout from 'components/DefaultLayout';
import Spinner from 'components/Spinner';
import coursesApiClient from 'utils/coursesApiClient';
import SignatureModal from './SignatureModal';
import FlexRow from 'components/FlexRow';
import './styles.scss';
import { FileTable } from 'ui';

const mapStateToProps = (state: any) => ({ session: state.session, locale: state.locale, languageDictionary: state.languageDictionary, activityList: state.campaignActivityList });

const StudentCampaignActivityList: React.FC<any> = ({ session, locale, languageDictionary, activityList }) => {
  const [campaignActivityList, setCampaignActivityList] = useState<any>([]);
  const [campaignData, setCampaignData] = useState<any>([]);
  const [activityListLoading, setActivityListLoading] = useState(false);
  const [activitiesCompleted, setActivitiesCompleted] = useState<any>();
  const [totalMinutes, setTotalMinutes] = useState<any>();
  const [refreshKey, setRefreshKey] = useState(0);
  const [reload, setReload] = useState(false);
  const [showSignatureModal, setShowSignatureModal] = useState(false);
  const [signature, setSignature] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [signatureDate, setSignatureDate] = useState('');
  const [files, setFiles] = useState([]);
  const [attempt, setAttempt] = useState();

  const intl = useIntl();
  const minutesPlaceholder = intl.formatMessage({ id: 'student.activity_minutes' }, { amount: totalMinutes });
  const activitesPlaceholder = intl.formatMessage({ id: 'student.total_activites' }, { amount: campaignActivityList.length });
  const progressPlaceholder = intl.formatMessage({ id: 'student.progress_activites' }, { amount: activitiesCompleted });
  const campaignAttemptId = activityList.campaignAttemptId
  const campaignId = activityList.campaignId;
  const enrollmentId = activityList.enrollmentId;

  function onFocus() {
    document.body.className = 'focused';
    setRefreshKey(oldKey => oldKey + 1)
  };

  const reloadActivities = useCallback(() => {
    if (document.visibilityState === 'visible') {
      if (reload) {
        onFocus();
        setReload(false);
        document.removeEventListener("visibilitychange", reloadActivities);
      }
    } else {
      setReload(true);
    }
  }, [reload])

  useEffect(() => {
    document.addEventListener("visibilitychange", reloadActivities);
  })

  useEffect(() => {
    loadCampaignActivityList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshKey, locale]);


  const loadCampaignActivityList = async () => {
    setActivityListLoading(true);

    let logView = (reload === false) ? 1 : 0

    let parsedData = {
      activeUserTypeUuid: session.active_user_type_uuid,
      activeLanguage: locale.locale,
      campaignAttemptId: campaignAttemptId
    }
    try {
      let campaignActivityList = await coursesApiClient.request(`/api/v1/courses/student/${enrollmentId}/activity-list/${campaignId}/${logView}`, parsedData, 'POST');

      const signSettings = campaignActivityList.settings;

      setAttempt(campaignActivityList.attempt);
      setSignature(signSettings.signed);
      setCompleted(signSettings.completed);
      setSignatureDate(signSettings.date);
      setFiles(campaignActivityList.files);
      if (signSettings.completed && !signSettings.signed && !!campaignActivityList.campaign.signatureJournal) {
        setShowSignatureModal(true);
      }
      setCampaignData(campaignActivityList.campaign)
      setCampaignActivityList(campaignActivityList.activities.sort((a: any, b: any) => (a.customSequencePriority > b.customSequencePriority ? 1 : -1)))
      let total: any = 0;
      let finished: any = 0;
      let totalMinutes: any = 0;
      campaignActivityList.activities.forEach((element: any) => {
        if (element.mandatory) {
          if (element.status === 'COMPLETED') {
            finished = finished + 1;
          }
          total = total + 1;
        }
        totalMinutes = totalMinutes + element.minutes;

      });
      setTotalMinutes(totalMinutes);
      setActivitiesCompleted(((finished / total) * 100).toFixed(0));

    } catch (err) {
      console.error(err);
    } finally {
      setActivityListLoading(false);
    }
  }

  const renderActivities = () => (
    campaignActivityList.map((activity: any) => <ActivityCard
      key={activity.id}
      activity={activity}
      preview={false}
      bordered={false}
      campaignData={campaignData}
      resource={{ campaignId: campaignId, enrollmentId: enrollmentId }}
      attempt={attempt}
    />
    )
  );
  return (
    <Spinner spinning={activityListLoading}>
      <DefaultLayout.PageLayout withoutPageHeader className='activity-list-wrapper'>
        <DefaultLayout.PageHeader title={`${campaignData.name}`} breadcrumb={[{ name: intl.formatMessage({ id: 'student.learning.list' }), path: '/' }]} />

        <FlexRow
          left={
            <div style={{ marginBottom: 10 }} className='campaign-header-row-of-data'>
              <span style={{ marginRight: 4 }}>
                <i className="fal fa-check-square" style={{ paddingRight: 4 }} />
                {activitesPlaceholder}
              </span>
              <span style={{ marginRight: 4 }}>
                <i className="fal fa-clock" style={{ paddingRight: 4 }} />
                {minutesPlaceholder}
              </span>
            </div>
          }

          right={
            <div className='campaign-header-row-of-data'>
              {completed && !!campaignData.signatureJournal ?
                <span style={{ marginRight: 8 }}>
                  <span className={(signature ? 'signature-style sign-green' : 'signature-style sign-red') + ' sign-button'} onClick={() => setShowSignatureModal(true)}>
                    <i className='fal fa-user-edit'></i>
                    <FormattedMessage id='student.signature' />
                  </span>
                </span>
                :
                null
              }

              <span style={{ marginRight: 8 }}>
                <Progress
                  showInfo={false}
                  percent={activitiesCompleted}
                  type="circle"
                  width={26}
                  strokeColor="#7633B9"
                />
              </span>
              <span style={{ marginRight: 8 }}>
                {progressPlaceholder}
              </span>
              <span style={{ marginRight: 4 }}>
                <FormattedMessage id="student.deadline_in" />
                {moment(activityList.campaignDeadline).format('DD.MM.YYYY')}
              </span>
            </div>
          }
        />

        <Spin spinning={activityListLoading}>
          <div style={{ minHeight: 50 }}>
            <Space direction="vertical" style={{ width: '100%' }}>
              {renderActivities()}
            </Space>
          </div>
          <div className='files-wrapper'>
            {files && files.length ?
              <>
                <div style={{ marginBottom: 10 }} className='campaign-header-row-of-data'>
                  <span style={{ marginRight: 4 }}>
                    <i className="fal fa-check-square" style={{ paddingRight: 4 }} />
                    <FormattedMessage id='courses.file_amount' values={{ amount: files.length }} />
                  </span>
                </div>
                <FileTable fileArray={files} description={true} />
              </>
              : null
            }
          </div>
        </Spin>
        <NavLink to=''>
          <span className='activity-list-back'>
            <i className="fal fa-arrow-left" style={{ paddingRight: '4px' }} />
            <FormattedMessage id="general.back" />
          </span>
        </NavLink>
      </DefaultLayout.PageLayout>
      {/*<SignatureModal*/}
      {/*  visible={showSignatureModal}*/}
      {/*  onCancel={() => setShowSignatureModal(false)}*/}
      {/*  campaignId={campaignId}*/}
      {/*  enrollmentId={enrollmentId}*/}
      {/*  completed={completed}*/}
      {/*  reload={loadCampaignActivityList}*/}
      {/*  signature={signature}*/}
      {/*  date={signatureDate}*/}
      {/*/>*/}
    </Spinner>
  );
}

export default connect(mapStateToProps)(StudentCampaignActivityList);
