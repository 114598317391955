import React, {useState, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {connect} from 'react-redux';
import debounce from "lodash/debounce";
import Spinner from "../../../components/Spinner";

import coursesApiClient from "utils/coursesApiClient";
import useHandleError from "utils/useHandleError";

import './styles.scss';

import { Space } from 'antd';
import {Checkbox} from 'components/Form';

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale,
        session: state.session
    };
};

interface QuestionItemListInterface {
    question: any;
    questionIndex: number;
    handlePanel: Function;
    openQuestionId: number | null;
    onSelectQuestion: Function;
    selectedQuestions: any;
    questionTypes: any;
    locale?: any;
    session?: any;
}

const QuestionItemList: React.FC<QuestionItemListInterface> = ({ 
    locale, 
    session, 
    question, 
    questionIndex, 
    openQuestionId,
    handlePanel,
    onSelectQuestion,
    selectedQuestions,
    questionTypes,
}) => {
    const [isLoading, setIsLoading] = useState(false);

    const intl = useIntl();
    const [handleError] = useHandleError();

    const questionType = questionTypes[question.type];

    return (
        <div className={`question-item ${question.id === openQuestionId ? 'active' : ''}`}>
            <Checkbox 
                onChange={(e: any) => onSelectQuestion(e)}
                value={question.id}
                checked={selectedQuestions.includes(question.id)}
            />
            <div className='question-item-info'  onClick={() => handlePanel(question.id)}>
                <h4>{question.questionNo}. {question.title}</h4>
                <p><FormattedMessage id={questionType.title} /></p>
            </div>
        </div>
    );
};

export default connect(mapStateToProps)(QuestionItemList);
    