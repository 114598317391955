import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, message, Modal, Radio } from 'antd';

import { CustomerSelect } from 'components/Form';
import coursesApiClient from 'utils/coursesApiClient';
import {config} from 'config/config';
import './styles.scss';

interface CoursesFilterModalInterface {
    visible: boolean;
    onCancel: any;
    setCoursesList: any;
    setCoursesListBeforeSearch: any;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
    setCustomerUrl: React.Dispatch<React.SetStateAction<string>>
}

const CoursesFilterModal: React.FC<CoursesFilterModalInterface> = ({ visible, onCancel, setCoursesList, setCoursesListBeforeSearch, setIsLoading, setCustomerUrl }) => {
    const [filterBy, setFilterBy] = useState('CUSTOMER');
    const [organizationType, setOrganizationType] = useState('');

    const [form] = Form.useForm();
    const intl = useIntl();

    useEffect(() => {
        form.setFieldsValue({
            filterBy: 'CUSTOMER'
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl]);

    const submitForm = async (values: any) => {
        setIsLoading(true);
        onCancel();
        try {
            const url = `/api/v1/courses/filter/${values?.customer ? values.customer : values.organization}/${organizationType}`

            setCustomerUrl(url);
            let response = await coursesApiClient.request(url + '/default', {}, 'GET');
            setCoursesList(response.courses);
            setCoursesListBeforeSearch(response.courses);
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Modal
            className='add-new-course'
            open={visible}
            onCancel={onCancel}
            title={intl.formatMessage({ id: 'courses.add_new_course' })}
            okText={intl.formatMessage({ id: 'general.filter' })}
            cancelText={intl.formatMessage({ id: 'general.back' })}
            onOk={form.submit}
        >
            <Form form={form} onFinish={submitForm}>
                <Form.Item
                    name='filterBy'
                    label={intl.formatMessage({ id: 'general.filter_by' })}
                >
                    <Radio.Group
                        onChange={(el: any) => {
                            setFilterBy(el.target.value);
                            if (el.target.value === 'CUSTOMER') {
                                form.setFieldsValue({
                                    userTypes: ['CUSTOMER_ADMIN', 'STUDENT']
                                });

                            } else {
                                form.setFieldsValue({
                                    userTypes: ['ADMIN']
                                });
                            }
                            form.setFieldsValue({
                                customer: [],
                                organization: [],
                                organizationalUnitId: [],
                            });

                        }}
                    >
                        <Radio value='CUSTOMER'><FormattedMessage id='general.customer' /></Radio>
                        <Radio value='ORGANIZATION'><FormattedMessage id='general.reseller' /></Radio>
                    </Radio.Group>
                </Form.Item>
                {
                    filterBy === 'CUSTOMER'
                        ? <CustomerSelect
                            saveLoadedData={false}
                            showSearch
                            name='customer'
                            label={intl.formatMessage({ id: 'general.customer' })}
                            url={config.api.routes.backend.organizationsCustomers}
                            customRules={[{
                                required: true,
                                message: intl.formatMessage({ id: 'validation.field_required' })
                            }]}
                            integerKey={true}
                            onChange={() => {
                                setOrganizationType('CUSTOMER');
                                form.setFieldsValue({
                                    organizationalUnitId: [],
                                    reseller: [],
                                });
                            }}
                            isResponseArray={true}
                            dataKey='customers'
                            mapDataEntries={(el: any) => ({
                                value: el.uuid,
                                label: el.name
                            })}
                            valueKey='value'
                            labelKey='label'
                            customLayout={true}
                            filterOption={(input: string, option: any) => {
                                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                            }}
                        />
                        : null
                }
                {
                    filterBy === 'ORGANIZATION'
                        ? <CustomerSelect
                            saveLoadedData={false}
                            showSearch
                            name='organization'
                            label={intl.formatMessage({ id: 'general.reseller' })}
                            customRules={[{
                                required: true,
                                message: intl.formatMessage({ id: 'validation.field_required' })
                            }]}
                            url={config.api.routes.backend.organizationsOptions}
                            integerKey={true}
                            onChange={(_value: string, options: any) => {
                                setOrganizationType(options.customerType);
                                form.setFieldsValue({
                                    organizationalUnitId: [],
                                    customer: []
                                });
                            }}
                            isResponseArray={true}
                            dataKey='organizations'
                            mapDataEntries={(el: any) => ({
                                value: el.uuid,
                                label: el.name,
                                customerType: el.type,

                            })}
                            valueKey='value'
                            labelKey='label'
                            customLayout={true}
                            filterOption={(input: string, option: any) => {
                                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                            }}
                        />
                        : null
                }
            </Form>
        </Modal>
    );
}

export default CoursesFilterModal;
