import React, {useEffect, useState} from 'react';
import {FormattedMessage} from "react-intl";
import {Popover} from "antd";

interface StatusSwitchProps {
    filtersSelected: any,
    setFiltersSelected: React.Dispatch<React.SetStateAction<any>>,
}

const options = {
    all: 'student.all_statuses',
    valid: 'general.valid_status',
    expired: 'general.expired_status',
};

const ValiditySwitch: React.FC<StatusSwitchProps> = ({ filtersSelected, setFiltersSelected }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(options.all);

    useEffect(()=> {
        if (selectedStatus === options.all) {
            setFiltersSelected((filtersSelected:any) => ({ ...filtersSelected, validity: undefined}));
        }
    },[selectedStatus])

    useEffect(()=> {
        if (Object.keys(filtersSelected).length === 0) {
            setSelectedStatus(options.all);
        }
    },[filtersSelected])

    const PopoverContent = () => {

        return (
            <>
                {
                    Object.entries(options).map((values:any, key) => {
                        if (values[1] != selectedStatus) {
                            return (
                                <div key={values[1]} className='select-item' onClick={() => {setSelectedStatus(values[1]); setFiltersSelected((filtersSelected:any) => ({ ...filtersSelected, validity: values[0]})); setIsOpen(false)}}><FormattedMessage id={values[1]} /></div>
                            )
                        }
                    })
                }
            </>
        )
    };

    return <div>
        <Popover
            placement='bottom'
            content={<PopoverContent />}
            overlayClassName='session-switch-popover'
            arrowContent={false}
            open={isOpen}
            trigger='click'
            onOpenChange={() => setIsOpen(!isOpen)}
        >
            <div className='filter-switch'>
                {
                    <span>
                        <FormattedMessage id={selectedStatus} />
                    </span>
                }
                <i className='fal fa-chevron-down' style={{fontSize: 10}} />
            </div>
        </Popover>
    </div>
}

export default ValiditySwitch;