import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { message, Modal} from 'antd';

import Spinner from 'components/Spinner';

const QuestionVideoModal: React.FC<any> = ({ open, video, onCancel, title }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [videoEmbedId, setVideoEmbedId] = useState<string|null>();

    const intl = useIntl();

    useEffect(() => {
        if(open) {
            setIsLoading(false)
        }
    }, [open])

    useEffect(() => {
        if(video) {
            setVideoEmbedId(extractVideoId(video))
        }
    }, [video])

    const extractVideoId = (url: string) => {
        const match = url.match(/^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/);
        return (match&&match[7].length==11) ? match[7] : null;
    }
    
    return (
        <Modal
            width={780}
            className="copy-question-modal"
            title={intl.formatMessage({ id: 'learning_tests.watch_video' })}
            open={open}
            onCancel={onCancel}
            cancelText={intl.formatMessage({ id: 'general.cancel' })}
            okButtonProps={{
                style: {
                    display: 'none'
                }
            }}
            destroyOnClose
        >
            <Spinner spinning={isLoading}>
                <h3 style={{margin: '0px 0px 20px'}}>{title}</h3>
                {videoEmbedId && <div className="video-responsive">
                    <iframe
                        width="853"
                        height="480"
                        src={`https://www.youtube.com/embed/${videoEmbedId}`}
                        frameBorder="0"
                        allow='accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                        allowFullScreen
                        title="Embedded youtube"
                    />
                </div>}
            </Spinner>
        </Modal>
    )
}

export default QuestionVideoModal;
