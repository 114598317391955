import React, {useEffect, useState} from 'react';
import {useIntl, FormattedMessage} from 'react-intl';
import {message, Button, Form} from 'antd';
import {Input, Select, InputNumber, TextArea, DatePicker} from 'components/Form';
import {connect} from 'react-redux';
import useHandleError from "utils/useHandleError";
import apiClient from 'utils/apiClient';
import '../styles.scss';
import moment from 'moment';
import {config} from "../../../config/config";


interface AssessmentFinalResultInterface {
    activityFormData: any;
    assessmentSettings: any;
    submission: any;
    loadAssignment: Function;
    setIsSubmitLoading: React.Dispatch<React.SetStateAction<boolean>>;
    isSubmitLoading: boolean;
    onGraded: Function;
    session?: any;
}

const mapStateToProps = (state: any) => ({
    session: state.session,
});

const AssessmentFinalResult: React.FC<AssessmentFinalResultInterface> = ({
                                                                             activityFormData,
                                                                             assessmentSettings,
                                                                             submission,
                                                                             loadAssignment,
                                                                             setIsSubmitLoading,
                                                                             isSubmitLoading,
                                                                             onGraded,
                                                                             session
                                                                         }) => {
    const [overviewRows, setOverviewRows] = useState<any[]>([]);
    const [selectedInstructor, setSelectedInstructor] = useState<any>(null);

    const intl = useIntl();
    const [handleError] = useHandleError();
    const [form] = Form.useForm();

    useEffect(() => {

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activityFormData])

    const submitAssessmentForm = async (values: any) => {
        const formValues = form.getFieldsValue();
        const parsedValues = {
            grade: formValues?.grade,
            grade_comments: formValues?.grade_comments,
            grade_date: formValues?.grade_date,
        }

        try {
            setIsSubmitLoading(true);
            let response = await apiClient.request(`/api/v1/courses/assessment-results/submit-final-assessment/${submission.id}`, parsedValues, 'POST');
            message.success(intl.formatMessage({id: "courses.evaluation_submitted_success"}));
            setIsSubmitLoading(false);
            onGraded()
        } catch (error) {
            handleError(error)
        } finally {
            setIsSubmitLoading(false);
        }
    };

    const renderGradeValue = (evaluationType: string | undefined) => {
        switch (evaluationType) {
            case 'EXAMINATION':
                return (
                    <Select
                        name="grade"
                        validation={{required: false}}
                        customLayout={{labelCol: 0, wrapperCol: 24}}
                        defaultValue={submission.grade ?? 0}
                        manualOptions={{
                            PASSED: intl.formatMessage({id: 'general.passed'}),
                            FAILED: intl.formatMessage({id: 'general.failed'})
                        }}
                        style={{width: '300px', marginBottom: '20px'}}
                        disabled={!activityFormData.attemptData.finalAssessment.canSubmit}
                    />
                )

            case 'RANGE':
                return (
                    <InputNumber
                        name="grade"
                        validation={{required: false}}
                        customLayout={{labelCol: 0, wrapperCol: 24}}
                        style={{width: '300px', marginBottom: '20px'}}
                        min={0}
                        max={100}
                        formatter={(value: number) => `${value}%`}
                        parser={(value: string) => value!.replace('%', '')}
                        disabled={!activityFormData.attemptData.finalAssessment.canSubmit}
                    />
                )
        }
    }

    return (
        <div className='assessment-overview'>
            <Form form={form} onFinish={submitAssessmentForm} initialValues={{
                grade: submission.grade,
                grade_comments: activityFormData.attemptData.finalAssessment.comments,
            }}>
                <div>
                    <label htmlFor='grade' className='mb-1'><FormattedMessage id='grading.grade'/></label>
                    {renderGradeValue(assessmentSettings.evaluation_type)}
                </div>
                <div>
                    <label htmlFor='gradeDate' className='mb-1'><FormattedMessage id='grading.grade_date'/></label>
                    <DatePicker
                        name={'grade_date'}
                        customLayout={{labelCol: 0, wrapperCol: 24}}
                        style={{width: '300px', marginBottom: '20px'}}
                        defaultValue={moment()}
                        format={config.defaultDateFormat}
                    />
                </div>
                <div>
                    <label htmlFor='grade_comments' className='mb-1'><FormattedMessage id='general.comments'/></label>
                    <TextArea
                        name="grade_comments"
                        validation={{required: false}}
                        customLayout={{labelCol: 0, wrapperCol: 24}}
                        style={{width: '100%', marginBottom: '0px'}}
                        rows={2}
                        disabled={!activityFormData.attemptData.finalAssessment.canSubmit}
                    />
                </div>
                {activityFormData.attemptData.finalAssessment.canSubmit &&
                    <div className='flex gap-10 mt-3'><Button type='primary' loading={isSubmitLoading}
                                                              onClick={() => form.submit()}><FormattedMessage
                        id='assessment.submit_assessment'/></Button></div>}
            </Form>
        </div>
    )
}
export default connect(mapStateToProps)(AssessmentFinalResult);