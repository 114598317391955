import React, {useEffect, useState} from 'react';
import FilterCheckBox from '../FilterCheckBox';
import FilterPopover from "../FilterPopover";
import {Button} from "ui";
import {FormattedMessage} from "react-intl";
import {useSession} from "hooks/Lectures/useSession";

interface SessionFilterInterface {
    tagBuilder: Function;
    session?: any;
    setFilterValues: any;
    filterValues: any
    form: any;
    filterKey: string;
    setFilterKey: React.Dispatch<React.SetStateAction<string>>;
}

const SessionFilter: React.FC<SessionFilterInterface> = ({
                                                             filterKey,
                                                             setFilterKey,
                                                             form,
                                                             tagBuilder,
                                                             setFilterValues,
                                                             filterValues
                                                         }) => {
    const {searchSession, sessionLoading: isLoading} = useSession()
    const [data, setData] = useState([]);
    const [initialValues, setInitialValues] = useState(undefined)
    const key = 'sessions'

    useEffect(() => {
        onSearch('')
    },[])

    const onSearch = async (value: string) => {
        const response = await searchSession(value)
        setData(response);
        setFilterValues(
            {
                ...filterValues,
                session: {
                    ...filterValues.session,
                    ...response
                }
            }
        )
    };

    const content = (
        <>
            <FilterCheckBox data={data} name={'session'} onSearch={onSearch}/>
            <div className={'flex justify-between p-1'}>
                <Button type={'link'} onClick={() => handleClose()}>
                    <FormattedMessage id={'general.cancel'}/>
                </Button>
                <Button type={'primary'} onClick={() => {
                                        setFilterKey('')

                    handleOpen(false)
                }}>
                    <FormattedMessage id={'general.select'}/>
                </Button>
            </div>
        </>
    );

    const handleClose = () => {
        form.setFieldsValue(initialValues);
                            setFilterKey('')
;
    }

    const handleOpen = (open: boolean) => {
        if (open) {
            setInitialValues(form.getFieldsValue())
            setFilterValues(
                {
                    ...filterValues,
                    session: {
                        ...filterValues.session,
                        ...data
                    }
                }
            )
        } else {
            tagBuilder()
        }
    }

    return (
        <FilterPopover
            popoverKey={key}
            filterKey={filterKey}
            setOpen={setFilterKey}
            handleOpen={handleOpen}
            content={content}
            loading={isLoading}
            title={'general.search_by_session'}
            buttonTitle={'general.session'}
        />
    );
};

export default SessionFilter;