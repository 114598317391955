import React from 'react';
import {Card, Col, Row} from 'antd';
import EnrolledStatisticsChart from '../Charts/EnrolledStatisticsChart';
import TotalTimeSpentChart from '../Charts/TotalTimeSpentChart';
import OrganizationalUnitChart from '../Charts/OrganizationalUnitChart';
import CompletionOverTimeChart from '../Charts/CompletionOverTimeChart';
import TopStudentTable from '../Charts/TopStudentTable';
import StatisticsDownload from "../../FormCampaign/CampaignOverview/StatisticsDownload";

interface OverviewProps {
    programId: number;
}

const Overview: React.FC<OverviewProps> = ({programId}) => (
    <div>
        <StatisticsDownload campaignId={programId} />
        <Row gutter={[16, 16]}>
            <Col span={12}>
                <Card size="small" className="chart-card">
                    <EnrolledStatisticsChart programId={programId}/>
                </Card>
                <Card size="small" className="chart-card adaptive">
                    <OrganizationalUnitChart programId={programId}/>
                </Card>
            </Col>
            <Col span={12}>
                <Card size="small" className="chart-card">
                    <TotalTimeSpentChart programId={programId}/>
                </Card>
                <Card size="small" className="chart-card">
                    <CompletionOverTimeChart programId={programId}/>
                </Card>
                <Card size="small" className="chart-card">
                    <TopStudentTable programId={programId}/>
                </Card>
            </Col>
        </Row>
    </div>
);

export default Overview;
