import React, {useState, useEffect} from 'react';
import DefaultLayout from 'components/DefaultLayout';
import {useIntl, FormattedMessage} from 'react-intl';
import {NavLink, useNavigate, useParams} from 'react-router-dom';
import {Form, Select as AntDSelect, Radio, DatePicker, Checkbox, Modal, message, Button} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import {Select, Input, RequestSelect} from 'components/Form';
import CRONBuilder from 'components/CRONBuilder';
import {config} from 'config/config';
import apiClient from 'utils/apiClient';
import moment from 'moment';
import Spinner from 'components/Spinner';
import {connect} from 'react-redux';
import datepickerTranslations from 'shared/datepickerTranslations';
import useHandleError from "utils/useHandleError";
import {FilterModal} from "../Modals";
import generalHelpers from "utils/generalHelpers";
import {parse} from "qs";
import ScenarioUsersTable from "./ScenarioUsersTable";
import './styles.scss';

const formItemPeriodLayout = {
    labelCol: {span: 10},
    wrapperCol: {span: 4},
};


const mapStateToProps = (state: any) => {
    return {
        session: state.session,
        filterdOrganizationOptions: state.filterdOrganizationOptions,
        locale: state.locale,
        userRole: state.session.active_user_type
    }
}

const ScenariosForm: React.FC<any> = ({session, locale}) => {
    const [isTemplateLoading, setIsTemplateLoading] = useState(false);
    const [templates, setTemplates] = useState([]);

    const [organizationId, setOrganizationId] = useState();
    const [organizationType, setOrganizationType] = useState('');

    const [showFilterModal, setShowFilterModal] = useState(false);
    const [filter, setFilter] = useState('');
    const [reload, setReload] = useState(false);

    const [CRONExpression, setCRONExpression] = useState<string | null>(null);
    const [initialCRONExpression, setInitialCRONExpression] = useState<string | null>(null);
    const [selectedUser, setSelectedUser] = useState('');
    const [usersList, setUsersList] = useState<any>({});
    const [saveData, setSaveData] = useState<any>({});
    const [submitInProgress, setSubmitInProgress] = useState(false);
    const [whenToSend, setWhenToSend] = useState(0);
    const [showModalSecond, setShowModalSecond] = useState(false);
    const [invalidFields, setInvalidFields] = useState<any>([]);
    const [isScenarioLoading, setIsScenarioLoading] = useState(false);
    const [CCValue, setCCValue] = useState(false);
    const [iniPasswordReset, setIniPasswordReset] = useState(false);

    const [selectedCampaign, setSelectedCampaign] = useState();

    const [totalRecords, setTotalRecords] = useState<number>(0);

    const [showUsersTable, setShowUsersTable] = useState(false);
    const [showUsersFilter, setShowUsersFilter] = useState(false);

    const intl = useIntl();
    const [form] = Form.useForm();
    const params = useParams();
    const scenarioId = params.id;
    const [handleError] = useHandleError();
    const navigate = useNavigate();

    useEffect(() => {
        if (scenarioId) {
            loadEmailScenario()
        } else {
            if (generalHelpers.isAdmin(session.active_user_type)) {
                setShowFilterModal(true)
            } else {
                setOrganizationId(session.organization.organization_id);
                setOrganizationType(session.organization.organization_type);
            }
        }
    }, [])

    useEffect(() => {
        if (reload) {
            const parsedFilter: any = parse(filter);

            if (parsedFilter?.filter?.filterBy === 'CUSTOMER') {
                setOrganizationId(parsedFilter?.filter?.customerId);
                setOrganizationType(parsedFilter?.filter?.filterBy);
            }

            if (parsedFilter?.filter?.filterBy === 'ORGANIZATION') {
                setOrganizationId(parsedFilter?.filter?.organizationId);
                setOrganizationType(parsedFilter?.filter?.filterBy);
            }
        }
    }, [reload])

    const loadEmailScenario = async () => {
        setIsScenarioLoading(true);
        let categoriesResponse = await apiClient.request(config.api.routes.backend.mailCategories);
        let mailScenarioResponse = await apiClient.request(config.api.routes.backend.mailScenarios + `/${scenarioId}`);
        let mailScenario = mailScenarioResponse ? mailScenarioResponse['mailScenario'] : {};
        let categoryName = mailScenario.description.split(':')[0];
        let mailCategory = Object.entries(categoriesResponse).find((el: any) => el[1] === categoryName);  // TODO: Category should be in database
        let categoryId = Array.isArray(mailCategory) && mailCategory.length > 0 ? parseInt(mailCategory[0]) : null;
        let activeRadio = 0;

        setOrganizationType(mailScenarioResponse.mailScenario.ownerType);

        if (mailScenario.cronExpression) {
            activeRadio = 2;
            setInitialCRONExpression(mailScenario.cronExpression);
            setCRONExpression(mailScenario.cronExpression);
        } else if (mailScenario.scheduleDate) {
            activeRadio = 1;
        }

        setWhenToSend(activeRadio);
        setCCValue(!!mailScenario.cc);
        setIniPasswordReset(!!mailScenario.iniPasswordReset)
        setSelectedCampaign(mailScenario.campaignId);
        form.setFieldsValue({
            name: mailScenario.name,
            category: categoryId,
            templateId: mailScenario.templateId,
            scheduleDate: moment(mailScenario.scheduleDate),
            period: [moment(mailScenario.startDate), moment(mailScenario.endDate)],
            emailLimit: mailScenario.emailLimit,
            campaignList: mailScenario.campaignId,
        });

        setIsScenarioLoading(false);
    };

    useEffect(() => {
        setInvalidFields([]);
    }, [CRONExpression]);

    const submitForm = async (values: any) => {
        const formValid = validateForm();

        if (formValid) {
            try {
                setSubmitInProgress(true);
                let parsedValues: any

                parsedValues = {
                    category: values.category,
                    templateId: values.templateId,
                    to: filter,
                    cc: CCValue ? 1 : 0,
                    iniPasswordReset: iniPasswordReset ? 1 : 0,
                    ownerType: organizationType,
                    ownerReferenceId: organizationId,
                    status: Object.keys(usersList).length > 0 ? 'INACTIVE' : 'ACTIVE',
                    scenarioType: 'CUSTOM',
                    toRecipients: Object.keys(usersList),
                    emailLimit: values.emailLimit
                }

                switch (whenToSend) {
                    case 0:
                        parsedValues.sendEmailNow = true;
                        parsedValues.startDate = moment().format('YYYY-MM-DD');
                        parsedValues.endDate = moment().format('YYYY-MM-DD');

                        break;
                    case 1:
                        parsedValues.sendEmailNow = false;
                        parsedValues.startDate = moment().format('YYYY-MM-DD');
                        parsedValues.endDate = moment().format('YYYY-MM-DD');
                        parsedValues.scheduleDate = values.scheduleDate.format('YYYY-MM-DD HH:mm');
                        break;
                    case 2:
                        parsedValues.sendEmailNow = false;
                        parsedValues.startDate = values.period[0].format('YYYY-MM-DD');
                        parsedValues.endDate = values.period[1].format('YYYY-MM-DD');
                        parsedValues.cronExpression = CRONExpression;
                        break;
                }

                let submitResponse

                if (scenarioId) {
                    submitResponse = await apiClient.request(`/api/v1/mail-scenarios/${scenarioId}`, parsedValues, 'PUT');

                } else {
                    submitResponse = await apiClient.request('/api/v1/mail-scenarios', parsedValues, 'POST');
                }

                if (submitResponse.status_code > 299) throw submitResponse.message;
                setSubmitInProgress(false);
                message.success(scenarioId ? 'Email scenario updated successfully' : 'Email scenario successfully created');
                navigate('/e-mailing/email-scenarios');
            } catch (error) {
                message.error('Email scenario failed');
                navigate('/e-mailing/email-scenarios');
                setSubmitInProgress(false);
            }
        }
    }

    const validateForm = () => {
        let formValid = true;

        if (whenToSend === 2 && !CRONExpression) {
            setInvalidFields((invalidFields: any) => [...invalidFields, 'CRONExpression'])
            formValid = false;
        }
        if (totalRecords === 0 && Object.keys(usersList).length === 0 && !selectedCampaign) {
            setInvalidFields((invalidFields: any) => [...invalidFields, 'whomToSend'])
            setInvalidFields((invalidFields: any) => [...invalidFields, 'createAsInactive'])
            formValid = false;
        }

        return formValid;
    }

    const loadEmailTemplates = async (categoryId: number) => {
        setIsTemplateLoading(true)
        form.setFieldsValue({templateId: null})
        try {
            let url

            if (!organizationId) {
                return setShowFilterModal(true)
            }

            switch (organizationType) {
                case 'ORGANIZATION':
                case 'RESELLER':
                case 'DISTRIBUTOR':
                    url = `/api/v1/mail-templates/organization/${organizationId}/select/${categoryId}`
                    break;
                case 'CUSTOMER':
                    url = `/api/v1/mail-templates/customer/${organizationId}/select/${categoryId}`
                    break;
            }

            const response = await apiClient.request(url, 'GET');

            setTemplates(response.mailTemplates)
        } catch (error) {
            handleError(error);
        } finally {
            setIsTemplateLoading(false);
        }
    }

    const addToUserList = () => {
        if (!!selectedUser && Object.keys(usersList).length <= 4) {
            setUsersList((usersList: any) => ({...usersList, [selectedUser]: saveData[selectedUser]}));
        } else {
            message.error('validation.too_many_users')
        }
    }

    const removeUserFromList = (uuid: string) => {
        const newItems = {...usersList};
        delete newItems[uuid];
        setUsersList(newItems);
    }

    return (
        <>
            <Spinner spinning={submitInProgress || isScenarioLoading} opaque={isScenarioLoading}>
                <DefaultLayout.PageContent>
                    <Form form={form} onFinish={submitForm} labelCol={{span: 8}} wrapperCol={{span: 6}}>
                        <Select
                            className='send-scenario-modal-select'
                            name='category'
                            showArrow
                            label={intl.formatMessage({id: "emailing.scenarios.e_mail_category"})}
                            url='/api/v1/mail-templates/options/categories'
                            integerKey={true}
                            customObjLabel={(el: string) => intl.formatMessage({id: `email_templates.categories.${el}`})}
                            onChange={(value: number) => loadEmailTemplates(value)}
                            withSystemName={true}
                            rules={[{
                                required: true,
                                message: intl.formatMessage({id: "validation.field_required"})
                            }]}
                            customLayout
                        />
                        <Form.Item
                            className='scenario-email-required'
                            name='templateId'
                            label={intl.formatMessage({id: 'emailing.scenarios.email_template'})}
                            rules={[{required: true, message: intl.formatMessage({id: "validation.field_required"})}]}
                        >
                            <AntDSelect showArrow loading={isTemplateLoading} className='default-select'>
                                {templates.map((template: any) => {
                                        const translations = template.translations;
                                        let titleObject = translations.filter((el: any) => el.language === locale)[0];

                                        if (!titleObject) {
                                            titleObject = translations[Object.keys(translations)[0]]
                                        }
                                        return <AntDSelect.Option
                                            value={parseInt(template.id)}>{titleObject.title}</AntDSelect.Option>
                                    }
                                )}
                            </AntDSelect>
                        </Form.Item>
                        <Form.Item label={intl.formatMessage({id: 'emailing.scenarios.when_to_send'})}>
                            <Radio.Group onChange={(el) => setWhenToSend(el.target.value)} value={whenToSend}>
                                <Radio value={0} className='when-to-send'><FormattedMessage
                                    id='emailing.scenarios.now'/></Radio>
                                <Radio value={1} className='when-to-send'><FormattedMessage
                                    id='emailing.scenarios.on_a_specific_time'/></Radio>
                                <Radio value={2} className='when-to-send'><FormattedMessage
                                    id='emailing.scenarios.recurring'/></Radio>
                            </Radio.Group>
                        </Form.Item>
                        {
                            whenToSend === 1
                                ?
                                <Form.Item label={intl.formatMessage({id: 'emailing.scenarios.on_a_specific_time'})}
                                           name='scheduleDate' rules={[{
                                    required: true,
                                    message: intl.formatMessage({id: "validation.field_required"})
                                }]}>
                                    <DatePicker
                                        format="YYYY-MM-DD HH:mm"
                                        disabledDate={(current: any) => current && current < moment().subtract(1, 'day').endOf('day')}
                                        showTime={{defaultValue: moment('00:00:00', 'HH:mm')}}
                                        minuteStep={60}
                                    />
                                </Form.Item>
                                : null
                        }
                        {
                            whenToSend === 2
                                ?
                                <>
                                    <Form.Item
                                        label={intl.formatMessage({id: 'emailing.scenarios.recurring'})}
                                        validateStatus={invalidFields.includes('CRONExpression') ? 'error' : undefined}
                                        help={invalidFields.includes('CRONExpression') ? intl.formatMessage({id: 'please_fill_all_reccurrance_fields'}) : undefined}
                                    >
                                        <CRONBuilder
                                            onChange={(value: string | null) => setCRONExpression(value)}
                                            initialValue={initialCRONExpression}
                                        />
                                    </Form.Item>
                                    <Form.Item {...formItemPeriodLayout} name='period'
                                               label={intl.formatMessage({id: 'emailing.scenarios.period'})}
                                               rules={[{
                                                   required: true,
                                                   message: intl.formatMessage({id: "validation.field_required"})
                                               }]}>
                                        <DatePicker.RangePicker
                                            format="YYYY-MM-DD"
                                            disabledDate={(current: any) => current && current < moment().subtract(1, 'day').endOf('day')}
                                            locale={datepickerTranslations(intl)}
                                        />
                                    </Form.Item>
                                </>
                                : null
                        }
                        <Form.Item
                            name='whomToSend'
                            label={intl.formatMessage({id: 'emailing.scenarios.whom_to_send'})}
                            validateStatus={invalidFields.includes('whomToSend') ? 'error' : undefined}
                            help={invalidFields.includes('whomToSend') ? intl.formatMessage({id: 'select_at_least_1_user'}) : undefined}
                            style={{marginBottom: 3}}
                        >
                            <Button type="primary" onClick={() => setShowUsersFilter(true)}>
                                <FormattedMessage id={'emailing.scenarios.select_users'}/> </Button>
                        </Form.Item>

                        <div style={{
                            marginLeft: '33%',
                            maxWidth: '25%',
                            marginBottom: 10,
                            textDecoration: 'underline'
                        }} onClick={() => setShowUsersTable(true)}>
                            {totalRecords} <FormattedMessage id='emailing.scenarios.users_selected'/>
                        </div>
                        <Form.Item
                            name='createAsInactive'
                            label={intl.formatMessage({id: 'emailing.scenarios.create_as_inactive_scenario_and_send_test_email_to'})}
                            validateStatus={invalidFields.includes('createAsInactive') ? 'error' : undefined}
                        >
                            <Button type="primary" onClick={() => setShowModalSecond(true)}>
                                <FormattedMessage id='emailing.scenarios.select_users'/>
                            </Button>
                        </Form.Item>
                        <Form.Item
                            name='cc'
                            label={intl.formatMessage({id: 'emailing.scenarios.copy_cc_direct_manager'})}
                        >
                            <Checkbox checked={CCValue} onChange={() => setCCValue(!CCValue)}/>
                        </Form.Item>
                        <Form.Item
                            name='iniPasswordReset'
                            label={intl.formatMessage({id: 'emailing.scenarios.initialize_password_reset'})}
                        >
                            <Checkbox
                                checked={iniPasswordReset}
                                onChange={() => setIniPasswordReset(!iniPasswordReset)}
                            />
                        </Form.Item>
                        <Input
                            type='text'
                            name='emailLimit'
                            label={intl.formatMessage({id: 'emailing.scenarios.maximum_number_of_emails_to_be_sent_in_one_hour'})}
                        />
                        <div className="form-buttons" style={{textAlign: "right"}}>
                            <NavLink to={'/e-mailing/email-scenarios'}>
                                <Button className='outlined-button' style={{margin: 8}}>
                                    <FormattedMessage id="general.back"/>
                                </Button>
                            </NavLink>
                            <Button type="primary" loading={submitInProgress} onClick={() => form.submit()}>
                                <FormattedMessage id="general.submit"/>
                            </Button>
                        </div>
                    </Form>
                </DefaultLayout.PageContent>
            </Spinner>
            <Modal
                title={intl.formatMessage({id: 'emailing.scenarios.select_users'})}
                open={showModalSecond}
                onCancel={() => setShowModalSecond(false)}
                onOk={() => setShowModalSecond(false)}
                cancelButtonProps={{style: {display: 'none'}}}
                okButtonProps={{style: {backgroundColor: '#19A9CE', border: 0, borderRadius: 8}}}
            >
                <div className='search-user-wrapper'>
                    <RequestSelect
                        customLayout={true}
                        url={`/api/v1/users/${organizationId}/${organizationType}/search`}
                        column
                        showSearch
                        saveData={setSaveData}
                        onChange={(typeUuid: string) => setSelectedUser(typeUuid)}
                    />
                    <Button type="primary" onClick={() => addToUserList()}>
                        <PlusOutlined/> <FormattedMessage id="general.add"/>
                    </Button>
                </div>

                <FormattedMessage id={'emailing.scenarios.selected_users'}/>:
                <div className='selected-users-box'>
                    {
                        Object.entries(usersList).map((user: any) => {
                            return (
                                <div className='selected-users-wrapper'>
                                    {user[1]}
                                    <i onClick={() => removeUserFromList(user[0])}
                                       className="fa-regular fa-trash"></i>
                                </div>
                            )
                        })
                    }
                </div>
            </Modal>
            <FilterModal // users filter (mass sending)
                visible={showUsersFilter}
                onCancel={setShowUsersFilter}
                modules={{
                    filterBy: {default: 'CUSTOMER', visible: generalHelpers.isAdmin(session.active_user_type)},
                    customerSelect: {visible: generalHelpers.isAdmin(session.active_user_type)},
                    organizationSelect: {visible: generalHelpers.isAdmin(session.active_user_type)},
                    organizationalUnitId: {visible: true, type: organizationType, id: organizationId},
                    userTypes: {default: ['STUDENT'], visible: true},
                    newUsers: {default: false, visible: true},
                    customerFilter: {mode: 'multiple', visible: true}
                }}
                setFilter={setFilter}
                load={setReload}
            />

            <FilterModal
                visible={showFilterModal}
                onCancel={setShowFilterModal}
                okProp='general.select'
                modules={{
                    filterBy: {default: 'CUSTOMER', visible: generalHelpers.isAdmin(session.active_user_type)},
                    customerSelect: {visible: generalHelpers.isAdmin(session.active_user_type)},
                    organizationSelect: {visible: generalHelpers.isAdmin(session.active_user_type)},
                }}
                setFilter={setFilter}
                load={setReload}
            />
            <ScenarioUsersTable
                setTotalRecords={setTotalRecords}
                visible={showUsersTable}
                onCancel={setShowUsersTable}
                filter={filter}
            />
        </>
    )
}

export default connect(mapStateToProps)(ScenariosForm);
