import { Navigate } from 'react-router-dom';
import jwt from 'utils/jwt';

const LoginRoute = (props: any) => {
  return (
    !!jwt.get() ? <Navigate to="/" replace /> : props.children
  )
}

export default LoginRoute;
