import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {FormattedMessage, useIntl} from 'react-intl';
import {
    Form,
    Input as AntDInput,
    Button,
    Transfer,
    Tabs,
    Row,
    Col,
    Space,
    Popover
} from 'antd';
import {Input, Select, InputNumber} from 'components/Form';
import moment from 'moment';
import DefaultLayout from 'components/DefaultLayout';
import coursesApiClient from 'utils/coursesApiClient';
import apiClient from 'utils/apiClient';
import Spinner from 'components/Spinner';
import Switch from 'components/Form/Switch';
import debounce from "lodash/debounce";

import './styles.scss';

import useHandleError from "../../../utils/useHandleError";
import {config} from "../../../config/config";
import {RangePickerProps} from "antd/lib/date-picker";
import generalHelpers from "../../../utils/generalHelpers";
import TestQuestionBanks from "../TestQuestionBanks";
import TestPreview from "../TestPreview";

const formItemLayout = {
    labelCol: {span: 5},
    wrapperCol: {span: 6},
};

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale,
        session: state.session
    }
};

interface SettingsInterface {
    learningTest: any
    loadLearningTest: Function;
    locale?: any;
    session?: any;
}

const Settings: React.FC<SettingsInterface> = ({locale = config.defaultEnvironmentLocale, session, learningTest, loadLearningTest}) => {
    const [loading, setIsLoading] = useState(false);
    const [loadingScore, setLoadingScore] = useState(false);
    const [ownerType, setOwnerType] = useState('CUSTOMER')
    const [learningTestData, setLearningTestData] = useState<any>(null)
    const [totalTestScores, setTotalTestScores] = useState<number>(0)
    const [requiredTestScores, setRequiredTestScores] = useState<number>(0)
    const [openPopover, setOpenPopover] = useState<boolean>(false)
    const [openPreviewModal, setOpenPreviewModal] = useState<boolean>(false)
    
    useEffect(() => {
        if (learningTest) {
            setLearningTestData(learningTest)
        }
    }, [learningTest])

    useEffect(() => {
        if (learningTestData) {
            form.setFieldsValue({
                ...learningTestData,
            });

            if (generalHelpers.isPlatformAdmin(session.active_user_type)) {
                form.setFieldsValue({
                    ownerType: learningTestData.ownerType,
                    ownerUuid: learningTestData.ownerUuid,
                });
            }
        }
    }, [learningTestData])

    const {TextArea} = AntDInput;
    const [handleError] = useHandleError();
    const navigate = useNavigate();
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    let courseId = searchParams.get("course");
    let materialId = searchParams.get("material")

    const learningTestId = params?.id ? parseInt(params?.id) : 0;

    const [form] = Form.useForm();
    const intl = useIntl();

    const submitForm = async (values: any) => {
        setIsLoading(true);

        const parsedValues = {
            ...learningTestData,
            ...values,
        };

        if (!!courseId && !!materialId) {
            parsedValues.append = {courseId, materialId, locale};
        }

        let response;

        try {
            if (learningTestId) {
                response = await coursesApiClient.request(`/api/v1/courses/learning-tests/${learningTestId}/update`, parsedValues, 'PUT');
                setLearningTestData(response.test)
                // loadLearningTest()
                loadTotalTestScores()
                if (courseId) {
                    navigate(`../courses/${courseId}/material/${materialId}`);
                }
            } else {
                response = await coursesApiClient.request('/api/v1/courses/learning-tests/store', parsedValues, 'POST');
                navigate(`/learning-tests/${response.test.id}`)
            }
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false);
        }
    };

    const loadTotalTestScores = debounce(async () => {
        setLoadingScore(true)
        try {
            const response = await coursesApiClient.request(`/api/v1/courses/learning-tests/${learningTestId}/get-required-scores`, {}, 'GET');
            setTotalTestScores(response.totalTestScores)
            setRequiredTestScores(response.totalScoreRequired)
        } catch (error) {
            handleError(error)
        } finally {
            setLoadingScore(false);
        }
    }, 800)

    return (
        <>
            <TestPreview 
                open={openPreviewModal}
                onCancel={() => setOpenPreviewModal(false)}
                learningTestId={learningTestId}
            />
            {learningTestData && <Form form={form} onFinish={submitForm} {...formItemLayout} onValuesChange={(values: any) => {}}>
                <div className='settings-panel'>
                    <Row justify='space-between' align='middle'>
                        <Col><h4><FormattedMessage id='learning_tests.settings' /></h4></Col>
                        <Col>
                            <Popover
                                open={openPopover}
                                content={
                                    <div className="popover-item" onClick={() => setOpenPreviewModal(true)}>
                                        <FormattedMessage id="general.preview"/>
                                    </div>
                                }
                                trigger="click"
                                placement="bottomRight"
                                arrowPointAtCenter
                                onOpenChange={(open) => setOpenPopover(open)}
                                >
                                <div className='w-100 pointer f-center'>
                                    <i className="fal fa-ellipsis-v f-16" />
                                </div>
                            </Popover>
                        </Col>
                    </Row>
                    <Row justify='space-between' align='middle'>
                        <Col><label><span style={{color: 'red'}}>*</span> <FormattedMessage id='learning_tests.test_name' /></label></Col>
                        <Col>
                            <Input
                                name="title"
                                validation={{required: true}}
                                customLayout={{labelCol: 0, wrapperCol: 24}}
                                style={{width: '400px'}}
                            />
                        </Col>
                    </Row>
                    <Row justify='space-between' align='middle'>
                        <Col><label><FormattedMessage id='learning_tests.passing_score' /></label></Col>
                        <Col>
                            <Space>
                                <Select
                                    name="passingScoreType"
                                    validation={{required: false}}
                                    customLayout={{labelCol: 0, wrapperCol: 24}}
                                    manualOptions={{
                                        PERCENTAGE: 'Percentage',
                                        POINTS: 'Points'
                                    }}
                                    style={{width: '118px'}}
                                    disabled={loadingScore}
                                />
                                <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, currentValues) => prevValues.passingScoreType !== currentValues.passingScoreType}
                                >
                                    {({ getFieldValue }) =>
                                        <InputNumber
                                            name="passingScore"
                                            validation={{required: false}}
                                            customLayout={{labelCol: 0, wrapperCol: 24}}
                                            style={{width: '100px'}}
                                            min={0}
                                            max={getFieldValue('passingScoreType') === 'PERCENTAGE' ? 100 : 100000000}
                                            formatter={(value: number) => getFieldValue('passingScoreType') === 'PERCENTAGE' ? `${value}%` : value}
                                            parser={(value: string) => value!.replace('%', '')}
                                            disabled={loadingScore}
                                        />
                                    }
                                </Form.Item>
                                {totalTestScores > 0 && requiredTestScores > 0 && <span style={{color: "#8C8C8C"}}>{requiredTestScores}/{totalTestScores}</span>}
                            </Space>
                        </Col>
                    </Row>
                    <Row justify='space-between' align='middle'>
                        <Col><label><FormattedMessage id='learning_tests.shuffle_question_bank_order' /></label></Col>
                        <Col>
                            <Switch 
                                key={`learning-test-shuffle-question-bank`}  
                                name='shuffleQuestionBankOrder'
                                isFormItem={true}
                            />
                        </Col>
                    </Row>
                    <Row justify='space-between' align='middle'>
                        <Col><label><FormattedMessage id='learning_tests.time_limit' /></label></Col>
                        <Col>
                            <Space>
                                <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, currentValues) => prevValues.timeLimitStatus !== currentValues.timeLimitStatus}
                                >
                                    {({ getFieldValue }) =>
                                       <InputNumber
                                            name="timeLimit"
                                            validation={{required: false}}
                                            customLayout={{labelCol: 0, wrapperCol: 24}}
                                            style={{width: '100px'}}
                                            min={0}
                                            formatter={(value: number) => `${value} min`}
                                            parser={(value: string) => value!.replace(' min', '')}
                                            disabled={!getFieldValue('timeLimitStatus')}
                                        />
                                    }
                                </Form.Item>
                                <Switch 
                                    key={`learning-test-time-limit-status`}  
                                    name='timeLimitStatus'
                                    isFormItem={true}
                                />
                            </Space>
                        </Col>
                    </Row>
                    <Row justify='space-between' align='middle'>
                        <Col>
                            <label><FormattedMessage id='learning_tests.allow_skipping' /></label>
                            <Popover content={intl.formatMessage({id: 'learning_tests.allow_skipping_explanation'})}>
                                <i style={{marginLeft: '3px'}} className="fal fa-question-circle"></i>
                            </Popover>
                        </Col>
                        <Col>
                            <Switch 
                                key={`learning-test-allow-skipping`}  
                                name='allowSkipping'
                                isFormItem={true}
                            />
                        </Col>
                    </Row>
                    <Row justify='space-between' align='middle'>
                        <Col>
                            <label><FormattedMessage id='learning_tests.allow_test_review' /></label>
                            <Popover content={intl.formatMessage({id: 'learning_tests.allow_test_review_explanation'})}>
                                <i style={{marginLeft: '3px'}} className="fal fa-question-circle"></i>
                            </Popover>
                        </Col>
                        <Col>
                            <Switch 
                                key={`learning-test-allow-test-review`} 
                                name='allowTestReview'
                                isFormItem={true}
                            />
                        </Col>
                    </Row>
                    <Row justify='space-between' align='middle'>
                        <Col>
                            <label><FormattedMessage id='learning_tests.hide_correct_answers' /></label>
                        </Col>
                        <Col>
                            <Switch 
                                key={`learning-test-hide-correct-answers`}
                                name='hideCorrectAnswers'
                                isFormItem={true}
                                
                            />
                        </Col>
                    </Row>
                </div>

                <TestQuestionBanks 
                    learningTest={learningTest}
                    loadTotalTestScores={loadTotalTestScores}
                />
            </Form>}
            <DefaultLayout.PageFooter
                right={
                    <>
                        <Button loading={loading || loadingScore} type='primary' onClick={form.submit}>
                            <FormattedMessage id='general.save'/>
                        </Button>
                    </>
                }
            />
        </>
    )
};

export default connect(mapStateToProps)(Settings);
