import {useState} from 'react'
import useHandleError from 'utils/useHandleError';
import apiClient from 'utils/apiClient';
import coursesApiClient from "utils/coursesApiClient";
import {useIntl} from "react-intl";


export const useCertificate = () => {
    const [handleError] = useHandleError();
    const [loading, setLoading] = useState<boolean>(false)

    const getCertificate = async (id: number) => {
        setLoading(true)
        try {
            let response = await coursesApiClient.request(`api/v1/courses/student/learning-program/${id}/certificate`, {}, 'GET');

            if ("error" in response) {
                throw response;
            }

            if ("data" in response) {
                return response;
            }

            return null;
        } catch (error) {
            handleError(error);
            return null;
        } finally {
            setLoading(false)
        }
    }

    const saveSubmission = async (submissionData: any, id: number): Promise<any | null> => {
        setLoading(true);
        try {
            const response = await apiClient.request(`/api/v1/courses/student/learning-program/${id}/certificate`, submissionData, 'POST');

            if ("error" in response) {
                throw response;
            }

            if ("data" in response) {
                return response;
            }

            return null;
        } catch (error) {
            handleError(error);
            return null;
        } finally {
            setLoading(false);
        }
    }


    return {
        loading,
        getCertificate,
        saveSubmission,
    }
}