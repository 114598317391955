import React, {useState, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Form, Radio, InputNumber, Upload, message, Table, Modal, Space} from 'antd';
import {Input, Switch} from 'components/Form';
import DefaultLayout from 'components/DefaultLayout';
import coursesApiClient from 'utils/coursesApiClient';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Title, Button} from 'ui';
import useHandleError from 'utils/useHandleError';
import {config} from 'config/config';
import CertificateBackground from 'components/Certificate/CertificateBackground';
import PreviewCertificateModal from 'components/CampaignForm/CampaignCertificates/PreviewCertificateModal';
import Spinner from "components/Spinner";
import {ExclamationCircleOutlined, MenuOutlined} from "@ant-design/icons";
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import type {SortableContainerProps, SortEnd} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import './styles.scss'

const formItemLayout = {
    labelCol: {span: 8},
    wrapperCol: {span: 6},
};

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale
    }
};

interface CertificatesInterface {
    program: any;
    launchButton: HTMLElement;
}

const MAX_CUSTOM_ROWS = 6

const Certificates: React.FC<CertificatesInterface> = ({program, launchButton}) => {
    const [backgrounds, setBackgrounds] = useState([])
    const [showCertificatePreviewModal, setShowCertificatePreviewModal] = useState(false);
    const [certificatePreviewData, setCertificatePreviewData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [dataSource, setDataSource] = useState<any>([]);
    const [selectedBackgroundBlob, setSelectedBackgroundBlob] = useState('');
    const [previewPath, setPreviewPath] = useState('');
    const [certificateId, setCertificateId] = useState(0);
    const [cert, setCert] = useState();
    const params = useParams();
    const intl = useIntl();
    const programId = params.id;
    const [handleError] = useHandleError();
    const [form] = Form.useForm();
    const {confirm} = Modal;

    useEffect(() => {
        loadCertificateBackgrounds();
    }, [])


    useEffect(() => {
        if (program) {
            fillForm();
            getProgramCertificate()
        }
    }, [program]);

    const getProgramCertificate = async () => {
        try {
            const response = await coursesApiClient.request(`/api/v1/courses/learning-program/certificates/${programId}/show`, {}, 'GET');
            setCert(response)

            if (response) {
                fillForm(response)
            } else {
                return
            }
            setCertificateId(response.id)
            setDataSource(response.params)
            let formData: any = {};

            Object.values(response.params).map((el: any) => {
                formData[`title_first_${el.key}`] = el.title_first
                formData[`title_second_${el.key}`] = el.title_second
                formData[`font_${el.key}`] = el.fontSize
                formData[`bold_${el.key}`] = el.bold
            })

            form.setFieldsValue({
                ...formData,
                hasCertificate: program.hasCertificate,
                canAddOuterCert: program.canAddOuterCert,
            });

        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    const fillForm = (response: any = null) => {

        if (response && program) {
            form.setFieldsValue({
                canAddOuterCert: response.canAddOuterCert,
                backgroundId: response.backgroundId,
                title: response.title,
                textBefore: response.textBefore,
                textAfter: response.textAfter,
                validDays: response.validDays,
                hasCertificate: program.hasCertificate
            });
        } else {
            form.setFieldsValue({
                canAddOuterCert: false
            });
        }

        form.setFieldsValue({
            programLanguage: config.defaultEnvironmentLocale
        });
    }

    const loadCertificateBackgrounds = async (resetBackground: boolean = false) => {
        try {
            setBackgrounds([])
            if (resetBackground) {
                form.setFieldsValue({
                    backgroundId: null
                });
            }
            const response = await coursesApiClient.request('/api/v1/courses/learning-program/certificates/background-list', {}, 'GET');

            if (response) {
                setBackgrounds(response)
            }

        } catch (error) {
            handleError(error)
        }
    }

    const submitForm = async (values: any) => {
        setIsLoading(true)

        if (!values.backgroundId) {
            setIsLoading(false)
            message.error(intl.formatMessage({id: "error.select_background"}));
            return;
        }
        const parsedValues = {
            backgroundId: values.backgroundId,
            params: dataSource,
            programId: programId
        }

        try {
            if (certificateId !== 0) {
                await coursesApiClient.request(`/api/v1/courses/learning-program/certificates/${certificateId}/update`, parsedValues, 'PUT');
            } else {
                await coursesApiClient.request('/api/v1/courses/learning-program/certificates/store', parsedValues, 'POST');
            }

            message.success(intl.formatMessage({id: "general.submit_success"}));

        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    const uploadBackgroundFile = async (file: any) => {
        try {
            let bodyFormData = new FormData();
            bodyFormData.append('file', file.file);
            await coursesApiClient.request('/api/v1/courses/learning-program/certificates/upload', bodyFormData, 'POST');

            loadCertificateBackgrounds()
        } catch (error) {
            handleError(error)
        }
    }

    const previewCertificate = async () => {
        const values = form.getFieldsValue();

        const parsedValues: any = {
            backgroundId: values.backgroundId,
            params: dataSource,
            programId: programId,
            user: {
                firstName: 'John',
                lastName: 'Doe',
            }
        };
        
        const response = await coursesApiClient.request('/api/v1/courses/learning-program/certificates/preview', parsedValues, 'POST');
        setPreviewPath(response.data)

        setShowCertificatePreviewModal(true);
    };

    const updateCertificateStatus = async (status: boolean) => {
        try {
            await coursesApiClient.request(`/api/v1/courses/learning-program/${programId}/update`, {hasCertificate: status}, 'PUT');
        } catch (error) {
            handleError(error)
        }
    }
    const updateOuterCertificateStatus = async (status: boolean) => {
        try {
            await coursesApiClient.request(`/api/v1/courses/learning-program/${programId}/update`, {canAddOuterCert: status}, 'PUT');
        } catch (error) {
            handleError(error)
        }
    }
    const DragHandle = SortableHandle(() => <MenuOutlined style={{cursor: 'grab', color: '#999'}}/>);

    const SortableItem = SortableElement((props: React.HTMLAttributes<HTMLTableRowElement>) => (
        <tr {...props} />
    ));
    const SortableBody = SortableContainer((props: React.HTMLAttributes<HTMLTableSectionElement>) => (
        <tbody {...props} />
    ));

    const DraggableContainer = (props: SortableContainerProps) => (
        <SortableBody
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    );

    const onSortEnd = ({oldIndex, newIndex}: SortEnd) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMove(dataSource, oldIndex, newIndex)
            setDataSource(newData);
        }
    };

    const DraggableBodyRow: React.FC<any> = ({className, style, ...restProps}) => {
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = dataSource.findIndex((x: any) => x.index === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };


    const updateCertificateLayout = (value: any, record: any, key: any) => {
        let objectKey: any = Object.entries(dataSource).filter((el: any) => el[1].key === record.key);
        dataSource[objectKey[0][0]][key] = value
        setDataSource(dataSource);
    }

    const columns = [
        {
            title: intl.formatMessage({id: 'program.first_column'}),
            render: (_text: string, record: any) => {
                return <Input
                    onChange={(value: any) => updateCertificateLayout(value.target.value, record, 'title_first')}
                    noMargin
                    customLayout={{labelCol: {span: 0}, wrapperCol: {span: 24}}}
                    name={`title_first_${record.key}`}/>
            }
        },
        {
            title: intl.formatMessage({id: 'program.second_column'}),
            render: (_text: string, record: any) => {
                return <Input
                    onChange={(value: any) => updateCertificateLayout(value.target.value, record, 'title_second')}
                    noMargin customLayout={{labelCol: {span: 0}, wrapperCol: {span: 24}}}
                    name={`title_second_${record.key}`}/>
            }
        },
        {
            key: 'font',
            title: <span><FormattedMessage id={'general.font'}/> / <FormattedMessage id={'general.bold'}/> </span>,
            render: (_text: string, record: any) => {
                return (
                    <div className={'flex gap-5'}>
                        <Form.Item className={'mb-0'} name={`font_${record.key}`}>
                            <InputNumber defaultValue={12} max={48}
                                         onChange={(value: any) => updateCertificateLayout(value, record, 'fontSize')}
                                         min={1}/>
                        </Form.Item>
                        <Switch onChange={(value: any) => updateCertificateLayout(value, record, 'bold')} noMargin
                                defaultChecked={false} isFormItem hasDefaultLayout
                                name={`bold_${record.key}`}/>
                    </div>
                )
            }
        },
        {
            title: intl.formatMessage({id: 'general.sort'}),
            width: 30,
            className: 'drag-visible',
            render: () => <DragHandle/>,
        },
        {
            title: intl.formatMessage({id: 'general.action'}),
            width: 30,
            render: (_text: string, record: any) => (
                <div className={'hyperlink-tag pointer'} onClick={() => warnDeletion(record)}>
                    <FormattedMessage id='general.delete'/>
                </div>)
        },
    ];

    const addRow = () => {
        if (dataSource.length >= MAX_CUSTOM_ROWS) {
            message.warn(intl.formatMessage({id: "program.certificate_max_fields"}));
            return;
        }

        if (dataSource.length != 0) {
            const lastElement = dataSource.reduce(function (prev: any, current: any) {
                return (parseInt(prev.index) > parseInt(current.index)) ? prev : current
            })

            setDataSource((dataSource: any) => [...dataSource, {
                key: (parseInt(lastElement.index) + 1).toString(),
                index: (parseInt(lastElement.index) + 1).toString(),
                title_first: '',
                title_second: '',
                fontSize: 12,
                bold: false
            }]);
        } else {
            setDataSource((dataSource: any) => [...dataSource, {
                key: '0',
                index: '0',
                title_first: '',
                title_second: '',
                fontSize: 12,
                bold: false
            }]);
        }
    }

    const warnDeletion = (record: any) => {
        confirm({
            title: intl.formatMessage({id: 'general.attention'}),
            content: intl.formatMessage({id: 'program.delete_certificate_row'}),
            icon: <ExclamationCircleOutlined/>,
            okText: intl.formatMessage({id: 'general.yes'}),
            cancelText: intl.formatMessage({id: 'general.no'}),

            onOk() {
                deleteRow(record);
            }
        });
    }

    const deleteRow = (record: any) => {
        const deletedDataSource = dataSource.filter((el: any) => el.index !== record.index);

        setDataSource(deletedDataSource);
    }

    return (
        <Spinner spinning={isLoading}>
            <Form form={form} onFinish={submitForm} {...formItemLayout}>
                <Title title='program.certificate_settings' className='f-14 bold f-left'/>
                <Switch onChange={(status: boolean) => updateCertificateStatus(status)} defaultChecked={false}
                        isFormItem hasDefaultLayout name="hasCertificate"
                        label={intl.formatMessage({id: "program.enable_certificates"})}/>
                <Switch onChange={(status: boolean) => updateOuterCertificateStatus(status)} defaultChecked={false}
                        isFormItem hasDefaultLayout name="canAddOuterCert"
                        label={intl.formatMessage({id: "program.can_add_outer_certificate"})}/>
                <Form.Item
                    labelCol={formItemLayout.labelCol}
                    wrapperCol={{span: 14}}
                    name={'backgroundId'} label={intl.formatMessage({id: 'campaign.background_and_style'})}>
                    <Radio.Group className='certificate-wrapper flex flex-wrap'>
                        <CertificateBackground cert={cert} setBackground={setSelectedBackgroundBlob} id={8}/>
                        <CertificateBackground cert={cert} setBackground={setSelectedBackgroundBlob} id={9}/>
                        {backgrounds && backgrounds.map((certificate: any) => {
                            return <CertificateBackground cert={cert} canDelete={true}
                                                          setBackground={setSelectedBackgroundBlob}
                                                          id={certificate}
                                                          reload={loadCertificateBackgrounds}/>
                        })}
                        <Upload
                            customRequest={(file: any) => uploadBackgroundFile(file)}
                            multiple
                            accept='.jpg, .jpeg, .png'
                            fileList={[]}>
                            <div className='certificate-background-upload flex align-center justify-center pointer'>
                                <span className={'word-break'}><FormattedMessage id='general.upload'/></span>
                            </div>
                        </Upload>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label={' '} className='noColon'>
                    <Button
                        onClick={() => previewCertificate()}
                        className='preview-button'>
                        <i className='fal fa-save header-item'/>
                        <FormattedMessage id='campaign.preview'/>
                    </Button>
                </Form.Item>

                {/* title: intl.formatMessage({id: 'program.first_column'}), title: intl.formatMessage({id: 'program.second_column'}), */}

                <Table
                    columns={columns}
                    rowKey={record => record['key']}
                    dataSource={dataSource}
                    pagination={false}
                    components={{
                        body: {
                            wrapper: DraggableContainer,
                            row: DraggableBodyRow,
                        },
                    }}
                    footer={() => (
                        <Button type="dashed" className={'flex justify-center w-100'} onClick={() => addRow()}>
                            <Space>
                                <i className="fa-sharp fa-solid fa-plus"></i>
                                <FormattedMessage id='general.add'/>
                            </Space>
                        </Button>
                    )}
                />

            </Form>
            <PreviewCertificateModal
                visible={showCertificatePreviewModal}
                onCancel={() => setShowCertificatePreviewModal(false)}
                previewPath={previewPath}
            />
            <DefaultLayout.PageFooter
                right={
                    <>
                        <Button
                            disabled={!(!!(program?.visualPermissions?.admin) || !!(program?.visualPermissions?.edit))}
                            loading={isLoading} type='primary' onClick={form.submit}>
                            <FormattedMessage id='general.save'/>
                        </Button>
                        {launchButton}
                    </>
                }
            />
        </Spinner>
    )
};

export default connect(mapStateToProps)(Certificates);
