import React, {useState, useEffect} from 'react';
import { useIntl } from 'react-intl';
import { Form, Select as AntDSelect } from 'antd';
import apiClient from 'utils/apiClient';
import { constructValidationRules } from './validations';
import './styles.scss';

const OrganizationSelect: React.FC<any> = ({
  url,
  integerKey,
  dataKey,
  isResponseArray,
  valueKey,
  labelKey,
  mapDataEntries,
  validation = {},
  name,
  label,
  customLayout,
  customRules,
  visible,
  className,
  ...props



}) => {
  const [options, setOptions] = useState<any>(isResponseArray ? [] : {});
  const [isOptionsLoading, setIsOptionsLoading] = useState(false);

  const intl = useIntl();

  // Fix for google autofill poping up in select input
  // https://github.com/ant-design/ant-design/issues/7659
  useEffect(() => {
    const fixAutocomplete = () => {
      document.querySelectorAll(".ant-select-selector input").forEach((e) => {
         e.setAttribute("autoComplete", "noAutocomplete");
        //you can put any value but NOT "off" or "false" because they DO NOT work
      })
    }

    fixAutocomplete();
  }, [])

  useEffect(() => {
    loadOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  const loadOptions = async () => {
    try {
      setIsOptionsLoading(true);
      let optionsResponse = await apiClient.request(url, {}, 'GET');
      let parsedOptions = parseOptions(dataKey ? optionsResponse[dataKey] : optionsResponse);
      setOptions(parsedOptions);
    } catch(error) {
      console.error(error);
    } finally {
      setIsOptionsLoading(false);
    }
  }

  const parseOptions = (options: any) => {
    let parsedOptions = options;

    if (!options && isResponseArray) {
      parsedOptions = [];
    } else if (!options) {
      parsedOptions = {};
    }

    if (mapDataEntries) {
      parsedOptions = parsedOptions.map(mapDataEntries);
    }

    if (isResponseArray) {
      let distinctOptions = [];
      const map = new Map();
      for (const item of parsedOptions) {
          if(!map.has(item.value)){
              map.set(item.value, true);
              distinctOptions.push({
                  value: item.value,
                  label: item.label,
                  uuid: item.uuid,
                  customerType: item.customerType
              });
          }
      }
      parsedOptions = distinctOptions;
    }

    return parsedOptions;
  }

  let labelCol: any = { span: 8 };
  let wrapperCol: any = { span: 6 };

  if (customLayout) {
    if (typeof customLayout === 'object') {
      labelCol = customLayout.labelCol || undefined;
      wrapperCol = customLayout.wrapperCol || undefined;
    } else {
      labelCol = undefined;
      wrapperCol = undefined;
    }
  }

  return (
    <Form.Item
      className={className}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      name={name}
      rules={customRules ? customRules : constructValidationRules(validation, intl)}
      label={label}
      style= {visible ? { display: 'none'} : {display: ''}}
    >
      <AntDSelect loading={isOptionsLoading} className={'default-select ' + (props.className || '')} {...props}>
        {
            options.map((option: any) => {
              return (
                <AntDSelect.Option key={option[valueKey]} uuid={option['uuid']} customerType={option['customerType']} value={option[valueKey]} >
                  {option[labelKey]}
                </AntDSelect.Option>
              )
            })
        }
      </AntDSelect>
    </Form.Item>
  )
}

export default OrganizationSelect;
