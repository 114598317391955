import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {NavLink, useParams} from 'react-router-dom';
import useHandleError from "utils/useHandleError";
import DefaultLayout from "../../DefaultLayout";
import {Table} from "../../../ui";
import {Popover, Progress} from "antd";
import moment from "moment/moment";
import {config} from "../../../config/config";

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale,
        session: state.session
    }
};

interface RecordType {
    key: string;
    name: string;
}

interface HistoryInterface {
    program: any;
    locale?: string;
    launchButton: HTMLElement;
}

const History: React.FC<HistoryInterface> = ({program, launchButton}) => {
    const [reload, setReload] = useState(false);
    const [filter, setFilter] = useState('');

    const params = useParams();
    const intl = useIntl();
    const programId = params.id;
    const [handleError] = useHandleError();







    const columns = [
        {
            title: intl.formatMessage({id: 'general.event_type'}),
            dataIndex: 'event_type'
        },
        {
            title: intl.formatMessage({id: 'general.event_subject_type'}),
            dataIndex: 'event_subject_type'
        },
        {
            title: intl.formatMessage({id: 'general.event_object_type'}),
            dataIndex: 'event_object_type'
        },
        {
            title: intl.formatMessage({id: 'general.event_initiator_type'}),
            dataIndex: 'event_initiator_type'
        },
        {
            title: intl.formatMessage({id: 'general.event_initiator'}),
            dataIndex: 'fullName'
        },
        {
            title: intl.formatMessage({id: 'general.created_at'}),
            render: (value: string, record: any) => moment(record.created_at).format('DD.MM.YYYY HH:mm:ss')
        },
    ];
    return (
        <>
            <Table
                columns={columns}
                url={`/api/v1/courses/learning-program/${programId}/history`}
                setReload={setReload}
                rowSelection={false}
                reload={reload}
                rowKey={'id'}
                scroll={{ x: 800 }}
                filter={filter}
            />

            <DefaultLayout.PageFooter
                right={
                    <>
                        {launchButton}
                    </>
                }
            />
        </>
    )
};

export default connect(mapStateToProps)(History);
