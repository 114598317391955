import React from 'react';
import {defaults, HorizontalBar} from 'react-chartjs-2'
import {Spin} from 'antd'
import {useIntl} from "react-intl";

interface IHorizontalStackedBarChart{
    data: any
    title?: string,
    loading?: boolean,
}

export const HorizontalStackedBarChart:React.FC<IHorizontalStackedBarChart> = ({data, title, loading})=>{
    defaults.global.defaultFontFamily = 'Gilroy';

    const intl = useIntl();

    
    const getMaxValueOfColumn = (): number => {
        const sumArray: any = [];
    
        data.datasets?.forEach((dataset: any) => {
          dataset.data.forEach((value: number, index: number) => {
            sumArray[index] = sumArray[index] ? sumArray[index] + value : value;
          });
        });
    
        return Math.max(...sumArray);
    }

    const options = {
        maintainAspectRatio: false,
        responsive: true,
        legend: {
            display: false,
        },
        tooltips: {
            mode: 'index',
            intersect: false,
        },
        scales:{ 
            yAxes: [{
            stacked: true,
            ticks: {
              fontColor: 'black',
              autoSkip: false,
              maxRotation: 60,
              minRotation: 0,
              callback: (label: string, labelIndex:number, labels: string[]) => {  
                let maxLength = 30;

                if (!label) {
                    return intl.formatMessage({id: 'general.without_org_unit'}) + (intl.formatMessage({id: 'general.without_org_unit'}).length > maxLength ? '...': '');
                } else {
                    return label.substring(0,maxLength) + (label.length > maxLength ? '...': '');
                }
              },
            },
          }],
          xAxes: [{
            stacked: true,
            ticks: {
              suggestedMax: (getMaxValueOfColumn() || 1) * 1.25,
              beginAtZero: true,
              precision: 0,
            },
          }],},
        plugins: {
            datalabels: {
              display: false,
            },
        }
    }

    const renderLegend = (data: any) => {
        return (
          <div className="chart-legend">
            {data.datasets?.map((dataset: any) => {
              return (
                <div className="chart-legend-item">
                  <div
                    className="chart-legend-badge"
                    style={{ backgroundColor: dataset.backgroundColor }}
                  />
                  <div className="chart-legend-text">
                    <p className="chart-legend-label">{dataset.label}</p>
                  </div>
                </div>
              );
            })}
          </div>
        );
      }

      const height = data.labels ? Math.max(data.labels.length - 10, 0) * 30 + 290 : 0

    return (
        <>
      {title
        ? <p className="chart-title">{title}</p>
        : null}
      <Spin spinning={loading}>
          {height &&
              <div style={{height: `${height}px`}}>
                  <HorizontalBar
                      data={data}
                      options={options}
                  />
              </div>
          }
        {renderLegend(data)}
      </Spin>
    </>
    )
}