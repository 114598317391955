import React, {useEffect, useState} from 'react';
import {useRules} from "hooks/Rules/useRules";
import RulesModal from "./RulesModal";

interface ConfirmationInterface {
}

const Confirmation: React.FC<ConfirmationInterface> = ({}) => {
    const {getStudentRule} = useRules();
    const [rules, setRules] = useState<any>([]);

    useEffect(() => {
        loadRules()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadRules = async () => {
        setRules(await getStudentRule());
    }

    return (
        <>
            {rules.map((rule: any) => <RulesModal rule={rule}/>)}
        </>
    )
};

export default Confirmation;



