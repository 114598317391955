import React, {useState, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {NavLink, useNavigate, useParams} from 'react-router-dom';
import {message, Drawer, Button} from "antd";
import './styles.scss';

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale,
        session: state.session
    };
};

interface QuestionsSidebarInterface {
    openSidebar: boolean;
    setOpenSidebar: React.Dispatch<React.SetStateAction<boolean>>;
    addNewQuestion: Function;
    questionTypes: any;
    locale?: any;
    session?: any;
}

const QuestionsSidebar: React.FC<QuestionsSidebarInterface> = ({ locale, session, openSidebar, setOpenSidebar, addNewQuestion, questionTypes }) => {
    const [isLoading, setIsLoading] = useState(false);

    const intl = useIntl();

    const addNewQuestionByType = (questionType: string) => {
        addNewQuestion(questionType)
    }

    return (
        <Drawer title={intl.formatMessage({id: 'question_pools.choose_question_type'})} placement="right" onClose={() => setOpenSidebar(false)} visible={openSidebar}>
            <div className='sidebar-questions'>
                {Object.keys(questionTypes).map((key: string) => {
                    return <div key={key} className="question-type" onClick={() => addNewQuestionByType(key)}>
                        <span className="anticon">
                            <i className={questionTypes[key].icon} />
                        </span>
                        <span className="title"><FormattedMessage id={questionTypes[key].title} /></span>
                    </div>
                }) }
            </div>
        </Drawer>
    );
};

export default connect(mapStateToProps)(QuestionsSidebar);
