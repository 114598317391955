import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Table, Search } from "ui";
import FlexRow from "components/FlexRow";
import { Button, Tag } from 'antd';
import { LectureCompletedType, LectureSessionStatus, SessionTabProps } from 'components/Lectures/types';
import DefaultLayout from "components/DefaultLayout";
import { useSession } from "hooks/Lectures/useSession";
import AttendanceStatusChangeModal from '../../Modals/AttendanceStatusChangeModal';
import SessionStatusBadge from '../../SessionStatusBadge';

const SessionAttendees: React.FC<SessionTabProps> = ({ lecture, session, reloadSession }) => {
    const [reload, setReload] = useState(false);
    const [searchString, setSearchString] = useState<string>('');
    const [selectedRowKeys, setSelectedRowKeys] = useState<[]>([]);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [showCreateModal, setCreateModal] = useState<boolean>(false)

    const { saveSession, sessionLoading } = useSession();

    const intl = useIntl();

    const columns = [
        {
            key: 'user',
            title: intl.formatMessage({ id: 'audience.user' }),
            render: (_text: string, record: any) => {
                return `${record.name} ${record.surname}`;
            }
        },
        {
            key: 'email',
            title: intl.formatMessage({ id: 'general.email' }),
            dataIndex: 'email',
        },
        {
            title: intl.formatMessage({ id: 'general.position' }),
            dataIndex: 'position'
        },
        {
            title: intl.formatMessage({ id: 'general.organizational_unit' }),
            dataIndex: 'organizationalUnit'
        },
        {
            title: intl.formatMessage({ id: 'general.organization' }),
            dataIndex: 'organization'
        },
        {
            title: intl.formatMessage({ id: 'general.lectures.attendance_status' }),
            render: (_text: string, record: any, tag: any) => <SessionStatusBadge status={record.status} attendee/>
        },
    ];

    const handleCompleteLecture = async () => {
        if (lecture && lecture.id && lecture.completedType === LectureCompletedType.Manual) {
            const response = await saveSession(lecture.id, session.id, {
                status: LectureSessionStatus.Completed
            });
            
            if (!!response) {
                reloadSession();
            }
        }
    }

    const handleAfterStatusChange = () => {
        setCreateModal(false);
        reloadSession();
        setReload(!reload);
    }

    return (
        <>
            {<FlexRow
                left={
                    <Button disabled
                        icon={<i className="fal fa-file-import" />}>
                        <span><FormattedMessage id="system.language.export" /></span>
                    </Button>
                }
                right={
                    <Search setSearchString={setSearchString} setReload={setReload} />
                }
            />}
            <Table
                columns={columns}
                url={`/api/v1/courses/lectures/${lecture.id}/sessions/${session.id}/attendee`}
                reload={reload}
                setReload={setReload}
                search={searchString}
                rowKey={'id'}
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
                setTotalRecords={setTotalRecords}
                isLoading={sessionLoading}
            />
            <DefaultLayout.PageFooter
                left={
                    <>
                        {selectedRowKeys.length}<FormattedMessage id='users.rows_selected' />
                        <Button
                            disabled={!selectedRowKeys.length}
                            onClick={() => setCreateModal(true)}>
                            <i className='fa-regular fa-ellipsis header-item' style={{ marginRight: 4, fontSize: 16, float: 'left' }} />
                            <FormattedMessage id='general.lectures.change_attendance_status' />
                        </Button>
                        {lecture && <Button
                            disabled={(session && session.status && session.status === LectureSessionStatus.Completed) || (lecture.completedType && lecture.completedType === LectureCompletedType.Automatic)}
                            onClick={() => handleCompleteLecture()}
                            loading={sessionLoading}
                        >
                            <i className='fa-solid fa-check header-item' style={{ marginRight: 4, fontSize: 16, float: 'left' }} />
                            <FormattedMessage id='general.lectures.mark_lecture_activity_completed' />
                        </Button>}
                    </>
                }
            />
            {lecture.id && session.id && <AttendanceStatusChangeModal
                lecturesId={lecture.id}
                sessionId={session.id}
                attendeeIds={selectedRowKeys}
                visible={showCreateModal}
                onCancel={() => setCreateModal(false)}
                onSubmit={() => handleAfterStatusChange()}
            />}
        </>
    );
};

export default SessionAttendees;
