import {useState} from 'react'
import useHandleError from 'utils/useHandleError';
import coursesApiClient from "utils/coursesApiClient";
import {useIntl} from "react-intl";
import generalHelpers from "utils/generalHelpers";
import moment from "moment/moment";
import {
    TotalTimeSpentChartProps,
    CompletionOverTimeChartProps,
    EnrolledStatisticsProps,
    EnrolledStatisticsDefaultProps, OrganizationalUnitChartProps
} from "./types";


export const useProgramOverview = () => {
    const [handleError] = useHandleError();
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const intl = useIntl();

    const enrolledStatistics = async (programId: number): Promise<EnrolledStatisticsProps | EnrolledStatisticsDefaultProps> => {
        try {
            setIsLoading(true);

            const response = await coursesApiClient.request(`/api/v1/courses/learning-program/${programId}/overview/enrolled-students`);

            if (!response?.data) {
                return {
                    data: {},
                    inner: {},
                }
            }

            return {
                data: {
                    labels: [
                        intl.formatMessage({id: 'general.not_started'}),
                        intl.formatMessage({id: 'general.in_progress'}),
                        intl.formatMessage({id: 'general.completed'}),
                        intl.formatMessage({id: 'general.missed_deadline'}),
                        intl.formatMessage({id: 'general.failed'}),
                    ],
                    datasets: [{
                        data: [
                            response.data.notStarted,
                            response.data.inProgress,
                            response.data.completed,
                            response.data.missedDeadline,
                            response.data.failed,
                        ],
                        backgroundColor: [
                            'rgb(247, 216, 0)',
                            'rgb(126, 162, 223)',
                            'rgb(33, 208, 160)',
                            'rgb(244, 99, 101)',
                            'rgb(252, 109, 38)',
                        ],
                        hoverBackgroundColor: [
                            'rgb(227, 196, 0)',
                            'rgb(106, 142, 203)',
                            'rgb(13, 188, 140)',
                            'rgb(224, 79, 81)',
                            'rgb(255, 124, 38)',
                        ],
                    }],
                },
                inner: {
                    text: intl.formatMessage({id: 'campaign.enrolled_students'}),
                    number: response.data.total,
                },
            };
        } catch (error) {
            handleError(error);
            return {
                data: {},
                inner: {},
            }
        } finally {
            setIsLoading(false);
        }
    }

    const organizationalUnitChart = async (programId: number): Promise<OrganizationalUnitChartProps | {}> => {
        try {
            setIsLoading(true);

            const response = await coursesApiClient.request(`/api/v1/courses/learning-program/${programId}/overview/progress-by-org-unit`);

            if (!response?.data) {
                return {}
            }

            return {
                labels: generalHelpers.pluck(response.data, 'name'),
                datasets: [
                    {
                        label: intl.formatMessage({id: 'general.not_started'}),
                        backgroundColor: 'rgb(247, 216, 0)',
                        hoverBackgroundColor: 'rgb(227, 196, 0)',
                        data: generalHelpers.pluck(response.data, 'notStarted'),
                    },
                    {
                        label: intl.formatMessage({id: 'general.in_progress'}),
                        backgroundColor: 'rgb(126, 162, 223)',
                        hoverBackgroundColor: 'rgb(106, 142, 203)',
                        data: generalHelpers.pluck(response.data, 'inProgress'),
                    },
                    {
                        label: intl.formatMessage({id: 'general.completed'}),
                        backgroundColor: 'rgb(33, 208, 160)',
                        hoverBackgroundColor: 'rgb(13, 188, 140)',
                        data: generalHelpers.pluck(response.data, 'completed'),
                    },
                    {
                        label: intl.formatMessage({id: 'general.missed_deadline'}),
                        backgroundColor: 'rgb(244, 99, 101)',
                        hoverBackgroundColor: 'rgb(224, 79, 81)',
                        data: generalHelpers.pluck(response.data, 'missedDeadline'),
                    },
                    {
                        label: intl.formatMessage({id: 'general.failed'}),
                        backgroundColor: 'rgb(252, 109, 38)',
                        hoverBackgroundColor: 'rgb(255, 124, 38)',
                        data: generalHelpers.pluck(response.data, 'failed'),
                    },
                ],
            };
        } catch (error) {
            handleError(error)
            return {}
        } finally {
            setIsLoading(false);
        }
    }

    const totalTimeSpentChart = async (programId: number): Promise<TotalTimeSpentChartProps | {}> => {
        try {
            setIsLoading(true);
            const response = await coursesApiClient.request(`/api/v1/courses/learning-program/${programId}/overview/time-spent`);

            if (!response?.data) {
                return {}
            }

            return {
                labels: [
                    intl.formatMessage({id: 'campaign.estimated_time_to_complete'}),
                    intl.formatMessage({id: 'campaign.actual_logged_time'}),
                ],
                datasets: [
                    {
                        data: [
                            // Convert seconds to hours
                            response.data.estimated / 3600,
                            response.data.time_spent / 3600,
                        ],
                        backgroundColor: [
                            'rgb(102, 148, 224)',
                            'rgb(33, 208, 160)',
                        ],
                        hoverBackgroundColor: [
                            'rgb(82, 128, 204)',
                            'rgb(13, 188, 140)',
                        ],
                    },
                ],
            };
        } catch (error) {
            handleError(error)
            return {}
        } finally {
            setIsLoading(false);
        }
    }

    const completionOverTimeChart = async (programId: number): Promise<CompletionOverTimeChartProps | {}> => {
        try {
            setIsLoading(true);

            const response = await coursesApiClient.request(`/api/v1/courses/learning-program/${programId}/overview/completion-over-time`);

            if (!response?.data) {
                return {}
            }

            const data = response.data;

            // EVERYTHING FROM HERE TO parsedData HAS POTENTIAL FOR REFACTOR
            const rotateArrayToEndAtCurrentMonth = (array: any[]) => {
                const newArray = [...array];
                const currentMonth = moment().month();

                for (let i = 0; i < 11 - currentMonth; i++) {
                    newArray.unshift(newArray.pop());
                }

                return newArray;
            };

            const monthsLabelsEndingWithCurrent = () => {
                const monthLabels = [
                    intl.formatMessage({id: 'general.january'}),
                    intl.formatMessage({id: 'general.february'}),
                    intl.formatMessage({id: 'general.march'}),
                    intl.formatMessage({id: 'general.april'}),
                    intl.formatMessage({id: 'general.may'}),
                    intl.formatMessage({id: 'general.june'}),
                    intl.formatMessage({id: 'general.july'}),
                    intl.formatMessage({id: 'general.august'}),
                    intl.formatMessage({id: 'general.september'}),
                    intl.formatMessage({id: 'general.october'}),
                    intl.formatMessage({id: 'general.november'}),
                    intl.formatMessage({id: 'general.december'}),
                ];

                return rotateArrayToEndAtCurrentMonth(monthLabels);
            };

            const orderedData: any = [];

            Object.keys(data)
                .sort((a, b) => parseInt(a, 10) - parseInt(b, 10))
                .forEach((key, index) => {
                    orderedData[index] = data[key];
                });

            const rotatedData = rotateArrayToEndAtCurrentMonth(orderedData);

            return {
                labels: monthsLabelsEndingWithCurrent(),
                datasets: [
                    {
                        type: 'bar',
                        label: intl.formatMessage({id: 'program.completion_over_time'}),
                        data: Object.values(rotatedData).map((month: any) => month.count),
                        backgroundColor: 'rgb(102, 148, 224)',
                        hoverBackgroundColor: 'rgb(82, 128, 204)',
                        yAxisID: 'y-axis-absolute',
                        order: 1,
                    },
                ],
            };
        } catch (error) {
            handleError(error)
            return {}
        } finally {
            setIsLoading(false);
        }
    }

    const topStudentTable = async (programId: number) => {
        try {
            setIsLoading(true);

            const response = await coursesApiClient.request(`/api/v1/courses/learning-program/${programId}/overview/top-students`);

            return response.data;
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false);
        }
    }

    return {
        isLoading,
        enrolledStatistics,
        organizationalUnitChart,
        totalTimeSpentChart,
        completionOverTimeChart,
        topStudentTable
    }
}