import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Radio, Space } from 'antd';
import { Modal } from 'ui';
import { TextArea, Select } from 'components/Form';
import Spinner from 'components/Spinner';
import apiClient from 'utils/apiClient';
import useHandleError from 'utils/useHandleError';
import { AudienceNotificationScheduleCron, AudienceNotificationProps } from 'components/Audiences/types';
import { MailScenarioStatusType } from 'pages/Emailing/types';

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

interface AudienceCreationModaltInterface {
    visible?: boolean | undefined;
    onCancel(cancel: boolean): any;
    onSubmit(): any;
    scenarioData: AudienceNotificationProps | null;
    audienceId: number;
    templateOptions: any;
}

const AudienceNotificationCreationModal: React.FC<AudienceCreationModaltInterface> = ({ audienceId, scenarioData, templateOptions, visible = false, onCancel, onSubmit }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [handleError] = useHandleError();
    const intl = useIntl();
    const [form] = Form.useForm();

    useEffect(() => {
        if (scenarioData) {
            form.setFieldsValue({
                id: scenarioData.id,
                template_id: scenarioData.template_id.toString(),
                cron_expression: scenarioData.cron_expression,
                status: scenarioData.status,
                description: scenarioData.description,
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleOnCancel = () => {
        form.resetFields();
        onCancel(false)
    }

    const handleFormSubmit = async (values: any) => {
        saveNotification(values);
    };

    const saveNotification = async (values: any) => {
        setIsLoading(true)
        try {
            const response = scenarioData
                ? await apiClient.request(`api/v1/audiences/${audienceId}/scenarios/${scenarioData.id}`, values, 'PUT')
                : await apiClient.request(`api/v1/audiences/${audienceId}/scenarios/`, values, 'POST')
            if ("error" in response) {
                throw response
            };

            onSubmit();
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    return (<Modal destroyOnClose
        open={visible}
        confirmLoading={isLoading}
        onCancel={() => handleOnCancel()}
        title={intl.formatMessage({ id: 'emailing.notifications.add_new_notification' })}
        okText={!!scenarioData ? intl.formatMessage({ id: 'general.edit' }) : intl.formatMessage({ id: 'general.create' })}
        cancelText={intl.formatMessage({ id: 'general.cancel' })}
        onOk={form.submit}
    >
        <Spinner spinning={isLoading}>
            <Form form={form} onFinish={handleFormSubmit} {...formItemLayout} preserve={false}>
                <Select
                    showArrow
                    name='template_id'
                    label={intl.formatMessage({ id: 'emailing.templates.template_name' })}
                    manualOptions={templateOptions}
                    customRules={[{ required: true, message: intl.formatMessage({ id: "validation.field_required" }) }]}
                    customLayout={true}
                />
                <Select
                    showArrow
                    name='cron_expression'
                    label={intl.formatMessage({ id: 'general.schedule' })}
                    manualOptions={{
                        [AudienceNotificationScheduleCron.Daily]: intl.formatMessage({ id: 'emailing.scenarios.daily' }),
                        [AudienceNotificationScheduleCron.Weekly]: intl.formatMessage({ id: 'emailing.scenarios.weekly' }),
                        [AudienceNotificationScheduleCron.Monthly]: intl.formatMessage({ id: 'emailing.scenarios.monthly' }),
                    }}
                    customRules={[{ required: true, message: intl.formatMessage({ id: "validation.field_required" }) }]}
                    customLayout={true}
                />

                <Form.Item name='status'
                    label={intl.formatMessage({ id: 'general.status' })}
                    rules={[{ required: true, message: intl.formatMessage({ id: 'validation.field_required' }) }]}
                >
                    <Radio.Group>
                        <Space direction="vertical">
                            <Radio value={MailScenarioStatusType.Active} defaultChecked>
                                <FormattedMessage id='general.active' />
                            </Radio>
                            <Radio value={MailScenarioStatusType.Inactive}>
                                <FormattedMessage id='general.inactive' />
                            </Radio>
                        </Space>
                    </Radio.Group>
                </Form.Item>

                <TextArea
                    name="description"
                    rows={3}
                    label={intl.formatMessage({ id: 'general.description' })}
                    customLayout={true}
                />
            </Form>
        </Spinner>
    </Modal>
    )
};
export default AudienceNotificationCreationModal;
