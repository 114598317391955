import React, {useEffect, useState} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { NavLink, useNavigate } from 'react-router-dom';
import { Modal, Popover } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import useHandleError from "utils/useHandleError";
import apiClient from "utils/apiClient";

import { Search, Table, Button } from "ui";
import DefaultLayout from "components/DefaultLayout";
import FlexRow from "components/FlexRow";
import { Switch } from 'components/Form';
import AudienceCreationModal from 'components/Audiences/Modals/AudienceCreationModal';

import { AudienceStatusTypes, AudienceTypes } from 'components/Audiences/types';
import coursesApiClient from "../../../utils/coursesApiClient";

const AudiencesList: React.FC = () => {
    const [reload, setReload] = useState<boolean>(false);
    const [searchString, setSearchString] = useState<string>('');
    const [visibleTooltip, setVisibleTooltip] = useState<number | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [showCreateModal, setCreateModal] = useState<boolean>(false)
    const [customFields, setCustomFields] = useState<any>([]);

    const intl = useIntl();
    const navigate = useNavigate();
    const [handleError] = useHandleError();
    const { confirm } = Modal;

    useEffect(() => {
        loadCustomFields();
    }, []);

    const handleVisibleChange = (visible: boolean, recordId: number) => {
        if (visible) {
            setVisibleTooltip(recordId);
        } else {
            setVisibleTooltip(null);
        }
    };

    const columns = [
        {
            key: 'name',
            title: intl.formatMessage({ id: 'audience.audience' }),
            sorter: true,
            render: (record: any) => {
                return (
                    <NavLink to={`/audiences/${record.id}`}>
                        {record.name}
                    </NavLink>
                )
            }
        },
        {
            key: 'id',
            title: intl.formatMessage({ id: 'audience.id' }),
            sorter: true,
            render: (record: any) => `AD${record.id.toString().padStart(3, "0")}`
        },
        {
            key: 'users',
            sorter: true,
            title: intl.formatMessage({ id: 'general.users' }),
            render: (record: any) => record.users
        },
        {
            key: 'type',
            title: intl.formatMessage({ id: 'general.type' }),
            render: (record: any) => {
                switch (record.type) {
                    case AudienceTypes.Dynamic:
                        return intl.formatMessage({ id: 'audience.dynamic' });
                    case AudienceTypes.Strict:
                        return intl.formatMessage({ id: 'audience.strict' });
                }
            }
        },
        {
            title: intl.formatMessage({ id: 'general.status' }),
            render: (_text: string, record: any) => {
                return (
                    <Switch
                        checked={record.status === AudienceStatusTypes.Online}
                        loading={isLoading}
                        onClick={() => updateAudienceStatus(record.id, record.status)}
                    />
                )
            }
        },
        {
            key: 'actions',
            render: (_text: string, record: any) => {
                const content = (
                    <div className="popover-item" onClick={() => {
                        warnDeletion(record.id);
                        setVisibleTooltip(null);
                    }}>
                        <FormattedMessage id="general.delete" />
                    </div>
                );

                return (
                    <Popover
                        open={record.id === visibleTooltip}
                        content={content}
                        trigger="click"
                        placement="bottomRight"
                        arrowPointAtCenter
                        onOpenChange={(visible) => handleVisibleChange(visible, record.id)}
                    >
                        <div style={{ width: '100%', cursor: 'pointer', textAlign: 'center' }}>
                            <i className="fal fa-ellipsis-v" style={{ fontSize: '16px' }} />
                        </div>
                    </Popover>
                )
            },
        }
    ];

    const handleAddAudience = (id: number|undefined) => {
        setCreateModal(false);

        if (!!id) {
            navigate(`/audiences/${id}`);
        }

        setReload(!reload);
    }

    const updateAudienceStatus = async (id: number, currentValue: string) => {
        setIsLoading(true)
        try {
            await apiClient.request(`/api/v1/audiences/${id}`, {
                status: currentValue === AudienceStatusTypes.Online ? AudienceStatusTypes.Offline : AudienceStatusTypes.Online,
            }, 'PUT');
            setReload(true);
        } catch (error) {
            handleError(error);
        } finally {
            setIsLoading(false);
        }
    }

    const warnDeletion = async (id: number) => {
        confirm({
            title: intl.formatMessage({ id: 'general.attention' }),
            content: intl.formatMessage({ id: 'audience.delete_audience' }),
            icon: <ExclamationCircleOutlined />,
            okText: intl.formatMessage({ id: 'general.yes' }),
            cancelText: intl.formatMessage({ id: 'general.no' }),

            onOk() {
                deleteAudience(id);
            }
        });
    }

    const deleteAudience = async (id: number) => {
        try {
            await apiClient.request(`/api/v1/audiences/${id}`, [], 'DELETE');
            setReload(true)
        } catch (error) {
            handleError(error);
        }
    }

    const loadCustomFields = async () => {
        try {
            const customFields = await coursesApiClient.request('/api/v1/audiences/custom-fields/list', [], 'GET');
            setCustomFields(customFields.fields);
        } catch (error) {
            handleError(error)
        } finally {
        }
    }

    return (
        <DefaultLayout.PageLayout>
            <DefaultLayout.PageHeader title={intl.formatMessage({ id: 'audience.audiences' })} />
            <DefaultLayout.PageContent>
                <FlexRow
                    left={
                        <Button type='primary' onClick={() => setCreateModal(true)}>
                            <FormattedMessage id='audience.new_audience' />
                        </Button>
                    }
                    right={
                        <Search setSearchString={setSearchString} setReload={setReload} />
                    }
                />
                <Table
                    columns={columns}
                    url={'/api/v1/audiences'}
                    setReload={setReload}
                    reload={reload}
                    rowSelection={false}
                    rowKey={'id'}
                    search={searchString}
                    isLoading={isLoading}
                    customFields={customFields}
                    learningProgramCustomColumns={true}
                    scroll={{ x: 800 }}
                />
            </DefaultLayout.PageContent>

            <AudienceCreationModal
                visible={showCreateModal}
                onCancel={() => setCreateModal(false)}
                onSubmit={(id: number|undefined) => handleAddAudience(id)}
            />


        </DefaultLayout.PageLayout>
    );
};

export default AudiencesList;