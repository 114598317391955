import React, { useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Select } from 'components/Form';
import { FileProperties, ScormResultSelect, ScormVersionSelect } from 'ui';
import coursesApiClient from 'utils/coursesApiClient';
import { Upload, Row, Col, Button, message, FormInstance } from 'antd';
import Spinner from 'components/Spinner';

const formItemLayout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 },
};
interface ScormFormInterface {
    language: string;
    handleVersionSelect: Function;
    versionList: any[];
    activityId: number;
    setMaterial: React.Dispatch<React.SetStateAction<any>>;
    reloadVersions: Function;
    form: FormInstance;
    versionsLoading: boolean;
    setFileTitle: React.Dispatch<React.SetStateAction<string>>;
    fileTitle: string;
}

const ScormForm: React.FC<ScormFormInterface> = ({ language, handleVersionSelect, versionList, activityId, setMaterial, reloadVersions, form, versionsLoading, setFileTitle, fileTitle }) => {
    const [fileUpload, setFileUpload] = useState(false);
    const intl = useIntl();

    const uploadMaterial = async (file: any) => {
        setFileUpload(true);
        try {
            let bodyFormData = new FormData();

            if (file.file) {
                bodyFormData.append('file', file.file);
            } else {
                return;
            }

            bodyFormData.append('language', language);
            bodyFormData.append('type', 'SCORM_LEARNING');

            let response = await coursesApiClient.request(`/api/v1/courses/material/${activityId}/add-material`, bodyFormData, 'POST');
            setFileTitle(response.material.settings.learningFileTitle);

            setMaterial(response.material.settings);

            const versions = await reloadVersions();

            const lastVersion: any = Object.values(versions).pop();

            form.setFieldsValue({
                version: lastVersion + ''
            });

        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        } finally {
            setFileUpload(false);
        }
    }

    return (
        <Spinner spinning={fileUpload} opaqueH>
            <Row>
                <Col className='file-settings-title' span={formItemLayout.labelCol.span} >
                    <FormattedMessage id='courses.learning_file' />
                </Col>
                <Col span={formItemLayout.wrapperCol.span} className='file-settings-style'>
                    <Upload
                        name='file'
                        multiple={false}
                        customRequest={(file: any) => uploadMaterial(file)}
                        fileList={[]}
                    >
                        <Button>
                            <i className="fa-solid fa-arrow-up-from-bracket"></i> <FormattedMessage id='general.upload_files' />
                        </Button>
                    </Upload>
                </Col>
            </Row>

            <FileProperties layout={formItemLayout} showSize={false} fileProperties={{
                title: fileTitle,
                titleLabel: 'courses.learning_file_title',
            }} />

            <ScormResultSelect layout={formItemLayout} name='expectedLearningResult' label='courses.expected_learning_result' customRules={[{ required: true, message: intl.formatMessage({id: 'validation.field_required'}) }]} />
            <ScormVersionSelect layout={formItemLayout} name='learningFileScormVersion' label='courses.scorm_version' customRules={[{ required: true, message: intl.formatMessage({id: 'validation.field_required'}) }]} />

            <Select
                loading={versionsLoading}
                customLayout={formItemLayout}
                label={intl.formatMessage({ id: "general.version" })}
                name='version'
                manualOptions={versionList}
                onChange={(_value: any, options: any) => handleVersionSelect(options.children, setFileTitle)}
            />

        </Spinner>
    )
}
export default ScormForm;
