import React, {useState, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {Form, message, Radio, Checkbox, Input as AntdInput} from 'antd';
import LanguageSelect from 'components/LanguageSelect';
import {
    Input,
    Switch,
    TimeZoneInput,
    Select,
    RequestSelect,
    Departments,
    DatePicker,
    InputNumber
} from 'components/Form';
import EnableDisableButton from './EnableDisableButton';
import {PhoneNumberSelect, ComponentAccessCheck} from 'ui';
import apiClient from 'utils/apiClient';
import {UserFormDisplayInterface} from '../types';
import Spinner from 'components/Spinner';
import {config} from "config/config";
import moment from "moment";
import './styles.scss';
import {CustomValuesSelect} from "../../Form";
import coursesApiClient from "utils/coursesApiClient";
import useHandleError from "utils/useHandleError";

const formItemLayout = {
    labelCol: {span: 8},
    wrapperCol: {span: 6},
};

const mapStateToProps = (state: any) => {
    return {
        session: state.session,
    };
};

const UserFormDisplay: React.FC<UserFormDisplayInterface> = ({
                                                                 customerUser,
                                                                 disabledFields,
                                                                 customerParams,
                                                                 session,
                                                                 form,
                                                                 submitForm,
                                                                 setAddOrganizationalUnit,
                                                                 addOrganizationalUnit,
                                                                 userUuid,
                                                                 role,
                                                                 customerId,
                                                                 organizationalUnits,
                                                                 setOrganizationalUnits
                                                             }) => {

    const [accountStatuses, setAccountStatuses] = useState<any>({});
    const [isUserLoading, setIsUserLoading] = useState(true);
    const [isActiveLearningManagerRole, setIsActiveLearningManagerRole] = useState(false);
    const [isActiveElearningAccount, setIsActiveElearningAccount] = useState(false);
    const [sendEmail, setSendEmail] = useState(true);
    const [enableSmsSending, setEnableSmsSending] = useState(false);
    const [registerUserWithoutEmil, setRegisterUserWithoutEmil] = useState(false);
    const [customFields, setCustomFields] = useState<any>([]);
    const [handleError] = useHandleError();

    const loadCustomFields = async () => {
        try {
            const customFields = await coursesApiClient.request('/api/v1/users/custom-fields/list', [], 'GET');
            setCustomFields(customFields.fields);
        } catch (error) {
            handleError(error)
        } finally {
        }
    }

    const intl = useIntl();

    useEffect(() => {
        const loadOrganizationalUnits = async () => {
            let url = '/api/v1/customers/options/organizational-unit-types';

            if (session.active_user_type === 'SUPER_ADMIN' || session.active_user_type === 'RESELLER_ADMIN' || session.active_user_type === 'DISTRIBUTOR_ADMIN') {
                url = url + '?customerId=' + customerId;
            }

            if (session.special_rights && session.special_rights.includes('can_manage_customers')) {
                url = url + '?customerId=' + customerId;
            }

            try {
                let organizationalUnits = await apiClient.request(url, [], 'GET');

                let sortedOptions: any = Object.entries(organizationalUnits.organizationalUnits).sort(function (a: any, b: any) {
                    let textA = a[1].toUpperCase();
                    let textB = b[1].toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });
                setOrganizationalUnits(sortedOptions)
            } catch (err) {
                console.error(err);
                message.error(intl.formatMessage({id: 'error.data_load'}));
            }
        };

        loadOrganizationalUnits();
        loadCustomFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const loadClassifiers = async () => {
            let url = '';

            if (role === 'students') {
                url = '/api/v1/enum/student-account-status-types';
            } else if (role === 'learning-managers') {
                url = '/api/v1/enum/customer-admin-account-status-types';
            } else {
                url = '/api/v1/enum/customer-admin-account-status-types';
            }

            try {
                let accountStatusesResponse = await apiClient.request(url, {}, 'GET');
                setAccountStatuses(accountStatusesResponse || {});
            } catch (err) {
                console.error(err);
            }
        };

        const customerDefaultSettings = async () => {
            try {
                let response = await apiClient.request(`/api/v1/customers/${customerId}/info`, {}, 'GET');
                form.setFieldsValue({
                    primaryLanguage: response.customer.defaultPrimaryLanguage,
                    timeZone: response.customer.defaultTimeZone,
                });
            } catch (err) {
                console.error(err);
            }
        };

        customerDefaultSettings();

        form.setFieldsValue({
            accountStatus: 'ACTIVE',
        });

        loadClassifiers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (customerUser.name) {
            setIsActiveLearningManagerRole(customerUser.isActiveLearningManagerRole === 1);
            setIsActiveElearningAccount(customerUser.isActiveElearningAccount === 1);

            if (customerUser.customFields && customerUser.customFields.length > 0) {
                Object.values(customerUser.customFields).map((field: any) => {
                    switch (field.field_type) {
                        case 'DATE':
                            form.setFieldsValue({
                                customFields: {
                                    [field.uuid]: moment(field.values)
                                }
                            })
                            break
                        default:
                            form.setFieldsValue({
                                customFields: {
                                    [field.uuid]: field.values
                                }
                            })
                            break
                    }
                });
            }

            let phoneNumber

            if (customerUser.phoneNumber) {
                phoneNumber = customerUser.phoneNumber.split(' ');
            }

            form.setFieldsValue({
                name: customerUser.name,
                surname: customerUser.surname,
                personalCode: customerUser.personalCode,
                email: customerUser.email,
                position: customerUser.position,
                organizationalUnitId: customerUser?.organizationalUnitId ? customerUser.organizationalUnitId.toString() : null,
                departmentId: customerUser?.departmentId ? customerUser.departmentId.toString() : null,
                managerReferenceId: customerUser.directManagerEmail,
                resourcePlanner: customerUser.resourcePlannerEmail,
                primaryLanguage: customerUser.primaryLanguage,
                commencementDate: customerUser.commencementDate ? moment(customerUser.commencementDate) : null,
                location: customerUser.location,
                timeZone: customerUser.timeZone,
                phishingAllowed: customerUser.phishingAllowed === 1,
                accountStatus: customerUser.accountStatus,
                isActiveElearningAccount: customerUser.isActiveElearningAccount === 1,
                isActiveSuperVision: customerUser.isActiveSuperVision === 1,
                absence: !!customerUser.absence,
                canManageLearningManagers: !!customerUser.canManageLearningManagers,
                canManageCustomers: !!customerUser.canManageCustomers,
                ignoreAccessPermissions: !!customerUser.ignoreAccessPermissions,
                canAddOwnContent: !!customerUser.canAddOwnContent,
                enableLector: !!customerUser.enableLector,
                phoneNumber: phoneNumber ? phoneNumber[1] : '',
                code: phoneNumber ? phoneNumber[0] : config.api.defaultCode,
                sendEmail: false,
                smsSending: !!customerUser.smsSending,
                canManageGeneralMessaging: customerUser.canManageGeneralMessaging,
            });

            setEnableSmsSending(!!customerUser.smsSending);

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerUser]);

    useEffect(() => {
        if (role === 'learning-managers') {
            form.setFieldsValue({
                isActiveElearningAccount: true,
                canManageLearningManagers: false,
                isActiveSuperVision: false,
                canManageCustomers: false,
                ignoreAccessPermissions: false,
                phishingAllowed: false,
                absence: false,

                sendEmail: true
            });
        } else if (role === 'students') {
            form.setFieldsValue({
                isActiveElearningAccount: false,
                isActiveSuperVision: false,
                canManageLearningManagers: false,
                canManageCustomers: false,
                ignoreAccessPermissions: false,
                phishingAllowed: true,
                absence: false,
                sendEmail: true
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [role]);

    return (
        <>
            <Form form={form} onFinish={submitForm} {...formItemLayout}>
                <Input disabled={disabledFields} name='name' label={intl.formatMessage({id: 'general.name'})}
                       validation={{required: true}}/>
                <Input disabled={disabledFields} name='surname'
                       label={intl.formatMessage({id: 'general.last_name'})} validation={{required: true}}/>
                <Input disabled={disabledFields} name='personalCode'
                       label={intl.formatMessage({id: 'general.personal_code'})} maxLength={20}/>
                {!registerUserWithoutEmil &&
                    <Input disabled={disabledFields} name='email' label={intl.formatMessage({id: 'general.email'})}
                           validation={{required: !registerUserWithoutEmil, type: 'email'}}/>
                }
                <Input disabled={disabledFields} name='position'
                       label={intl.formatMessage({id: 'general.position'})}/>
                <PhoneNumberSelect
                    defaultCode={config.api.defaultCode}
                    wrapperClassName='phone-number-wrapper'
                    form={form} layout={formItemLayout}
                    name='phoneNumber'
                    label={intl.formatMessage({id: 'users.form.phone_number'})}
                />
                <Select
                    allowClear
                    showSearch
                    showArrow
                    name='organizationalUnitId'
                    label={intl.formatMessage({id: 'general.unit'})}
                    disabled={session.active_user_type === 'SUPERVISION' && disabledFields}
                    onSearch={(element: any) => {
                        setAddOrganizationalUnit(() => [...addOrganizationalUnit, element]);
                        form.setFieldsValue({organizationalUnitId: addOrganizationalUnit[addOrganizationalUnit.length - 1]});
                    }}
                    dataKey='organizationalUnits'
                    manualOptions={organizationalUnits}
                    isResponseArray={true}
                    valueKey={'0'}
                    labelKey={'1'}
                    integerKey={true}
                    onChange={(value: any, label: any) => {
                        setAddOrganizationalUnit([]);
                        form.setFieldsValue({organizationalUnitId: value});
                    }}
                    filterOption={(input: string, option: any) => {
                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                    dropdownStyle={{minWidth: '400px'}}
                />
                <Departments customerId={customerId}/>
                <RequestSelect
                    isForm={true}
                    label={intl.formatMessage({id: 'users.form.users_direct_manager'})}
                    selectClassName='request-select'
                    url={`/api/v1/edu/users/mass-search`}
                    param={[`customerId=${customerId}&key=reference_id&bothRoles=true`]}
                    column
                    name={'managerReferenceId'}
                    showSearch
                    allowClear
                />
                <RequestSelect
                    isForm={true}
                    label={intl.formatMessage({id: 'users.form.resource_planner'})}
                    selectClassName='request-select'
                    url={`/api/v1/edu/users/mass-search`}
                    param={[`customerId=${customerId}&key=reference_id&bothRoles=true`]}
                    column
                    name={'resourcePlanner'}
                    showSearch
                    allowClear
                />
                <LanguageSelect
                    name='primaryLanguage'
                    label={intl.formatMessage({id: 'general.language'})}
                    disabled={disabledFields}
                />
                <Form.Item
                    {...formItemLayout}
                    name='timeZone'
                    label={intl.formatMessage({id: 'users.form.time_zone'})}
                    rules={[{required: true, message: intl.formatMessage({id: 'validation.field_required'})}]}
                >
                    <TimeZoneInput disabled={disabledFields}/>
                </Form.Item>
                <DatePicker
                    label={intl.formatMessage({id: 'users.form.commencement_date'})}
                    name={'commencementDate'}
                    defaultValue={null}
                    format={config.defaultDateFormat}
                />
                <Switch onChange={() => setEnableSmsSending(!enableSmsSending)} isFormItem hasDefaultLayout
                        name='smsSending' label={intl.formatMessage({id: 'users.form.enable_sms_sending'})}/>

                {!userUuid &&
                    <Switch onChange={() => setRegisterUserWithoutEmil(!registerUserWithoutEmil)} isFormItem
                            hasDefaultLayout label={intl.formatMessage({id: 'users.form.register_user_no_email'})}/>
                }
                {session.active_user_type !== 'SUPERVISION' &&
                    <>
                        {isActiveLearningManagerRole || role === 'learning-managers' ?
                            <Switch isFormItem hasDefaultLayout name='isActiveElearningAccount'
                                    label={intl.formatMessage({id: 'users.form.active_e_learning_account'})}/>
                            :
                            null
                        }
                        <Switch isFormItem hasDefaultLayout
                                name='isActiveSuperVision'
                                label={intl.formatMessage({id: 'users.form.is_active_super_vision'})}/>
                        {userUuid &&
                            <Switch isFormItem hasDefaultLayout name='absence'
                                    label={intl.formatMessage({id: 'users.form.long_absence'})}/>
                        }
                        <Form.Item {...formItemLayout} name='accountStatus'
                                   label={intl.formatMessage({id: 'users.form.account_status'})}>
                            <Radio.Group>
                                {Object.keys(accountStatuses).map(key =>
                                    <Radio disabled={disabledFields}
                                           className='radio-button'
                                           key={key}
                                           value={key}
                                    >
                                        {intl.formatMessage({id: `users.form.${key}`})}
                                    </Radio>
                                )}
                            </Radio.Group>
                        </Form.Item>
                        <ComponentAccessCheck permission={'view_phishing'}>
                            <Switch isFormItem hasDefaultLayout name='phishingAllowed'
                                    label={intl.formatMessage({id: 'users.form.phishing_allowed'})}
                                    disabled={disabledFields}/>
                        </ComponentAccessCheck>
                        {!userUuid &&
                            <Form.Item {...formItemLayout} name='sendEmail'
                                       label={intl.formatMessage({id: 'general.send_email'})}>
                                <Checkbox checked={sendEmail} onChange={() => {
                                    setSendEmail(!sendEmail);
                                    form.setFieldsValue({sendEmail: !sendEmail});
                                }}/>
                            </Form.Item>
                        }
                        {userUuid &&
                            <>
                                <EnableDisableButton
                                    userUuid={userUuid}
                                    from={form}
                                    isActiveLearningManagerRole={isActiveLearningManagerRole}
                                    setIsActiveLearningManagerRole={setIsActiveLearningManagerRole}
                                    isActiveElearningAccount={isActiveElearningAccount}
                                    setIsActiveElearningAccount={setIsActiveElearningAccount}
                                    customerId={customerId}
                                />
                                <Switch isFormItem hasDefaultLayout name="enableLector"
                                        label={intl.formatMessage({id: "organization.enable_lector"})}/>
                            </>
                        }
                        {
                            customFields.length > 0 ?
                                <>
                                    <div className='campaign-header-text'>
                                        <h1 className='text-18'>{intl.formatMessage({id: 'general.custom_fields'})}</h1>
                                    </div>
                                    {
                                        customFields.map((field: {
                                            uuid: string,
                                            field_type: 'TEXT' | 'INTEGER' | 'DATE' | 'SWITCH' | 'MULTIPLE_CHOICE' | 'HYPERLINK',
                                            title: string,
                                            multiple_choice_options: string[]
                                        }) => {

                                            switch (field.field_type) {
                                                case 'TEXT':
                                                    return (
                                                        <Input name={['customFields', field.uuid]}
                                                               label={field.title}></Input>
                                                    )
                                                case 'INTEGER':
                                                    return (
                                                        <InputNumber name={['customFields', field.uuid]}
                                                                     label={field.title}></InputNumber>
                                                    )
                                                case 'DATE':
                                                    return (
                                                        <DatePicker name={['customFields', field.uuid]}
                                                                    label={field.title}
                                                                    format={config.defaultDateFormat}></DatePicker>
                                                    )
                                                case 'SWITCH':
                                                    return (
                                                        <Switch name={['customFields', field.uuid]} isFormItem
                                                                label={field.title}></Switch>
                                                    )
                                                case 'MULTIPLE_CHOICE':
                                                    return (
                                                        <div className={'multiple-choice-fields'}>
                                                            <Form.Item label={field.title}
                                                                       name={['customFields', field.uuid]}>
                                                                <Checkbox.Group>
                                                                    {
                                                                        field.multiple_choice_options.map((el: string) => {
                                                                            return (
                                                                                <Checkbox value={el}>
                                                                                    {el}
                                                                                </Checkbox>
                                                                            )
                                                                        })
                                                                    }
                                                                </Checkbox.Group>
                                                            </Form.Item>
                                                        </div>
                                                    )
                                                case 'HYPERLINK':
                                                    return (
                                                        <Form.Item label={field.title}
                                                                   name={['customFields', field.uuid]}>
                                                            <AntdInput.Group compact>
                                                                <Form.Item
                                                                    className={'vertical-form-item'}
                                                                    label={intl.formatMessage({id: 'users.custom_filter_display_name'})}
                                                                    name={['customFields', field.uuid, 'displayName']}
                                                                >
                                                                    <AntdInput/>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    className={'vertical-form-item'}
                                                                    label={intl.formatMessage({id: 'users.custom_filter_hyperlink'})}
                                                                    name={['customFields', field.uuid, 'hyperlink']}
                                                                >
                                                                    <AntdInput/>
                                                                </Form.Item>
                                                            </AntdInput.Group>
                                                        </Form.Item>
                                                    )
                                                default:
                                                    return;
                                            }
                                        })
                                    }
                                </>
                                :
                                null
                        }
                    </>
                }
                <Spinner spinning={isUserLoading}>
                    <CustomValuesSelect
                        customerId={customerId}
                        allowClear
                        showArrow
                        setLoading={setIsUserLoading}
                        customLayout={formItemLayout}
                        setCustomFilterValues
                        role={session.active_user_type}
                        filterOption={(input: string, option: any) => {
                            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                        }}
                    />
                </Spinner>
                {session.active_user_type !== 'SUPERVISION' &&
                    <>
                        {isActiveLearningManagerRole || role === 'learning-managers' ?
                            <>
                                <hr className='form-group-separator'/>
                                <h1 className='form-group-header'>{intl.formatMessage({id: 'organization.special_rights'})}</h1>
                                <Switch isFormItem hasDefaultLayout name='ignoreAccessPermissions'
                                        label={intl.formatMessage({id: 'organization.ignore_access_permissions'})}/>
                                <Switch isFormItem hasDefaultLayout name='canManageLearningManagers'
                                        label={intl.formatMessage({id: 'users.form.add_remove_learning_manager'})}/>
                                <Switch isFormItem hasDefaultLayout name='canManageCustomers'
                                        label={intl.formatMessage({id: 'organizations.can_manage_customers'})}/>
                                {customerParams && customerParams.canAddOwnContent &&
                                    <Switch isFormItem hasDefaultLayout name='canAddOwnContent'
                                            label={intl.formatMessage({id: 'users.form.can_add_own_content'})}/>
                                }
                                <Switch isFormItem hasDefaultLayout name='canManageGeneralMessaging'
                                        label={intl.formatMessage({id: 'organizations.can_manage_general_messaging'})}/>
                            </>
                            :
                            null
                        }
                    </>
                }
            </Form>
        </>
    );
};

export default connect(mapStateToProps)(UserFormDisplay);
