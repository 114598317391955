import { useIntl } from 'react-intl';
export const useDate = () => {
    const intl = useIntl();

    /**
    * 
    * @param dateString 
    * @returns 
    */
    const formatDate = (dateString: string, dateFormat: any = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        weekday: 'long',
        hour: 'numeric',
        minute: 'numeric',
        // second: 'numeric',
        hour12: false,
    }) => {
        let date = intl.formatDate(new Date(dateString), dateFormat);
        return date;
    }

    /**
     * 
     * @param monthNumber 
     * @returns 
     */
    const getMonth = (monthNumber: number) => {
        const month = [
            intl.formatMessage({id: 'general.january'}),
            intl.formatMessage({id: 'general.february'}),
            intl.formatMessage({id: 'general.march'}),
            intl.formatMessage({id: 'general.april'}),
            intl.formatMessage({id: 'general.may'}),
            intl.formatMessage({id: 'general.june'}),
            intl.formatMessage({id: 'general.july'}),
            intl.formatMessage({id: 'general.august'}),
            intl.formatMessage({id: 'general.september'}),
            intl.formatMessage({id: 'general.october'}),
            intl.formatMessage({id: 'general.november'}),
            intl.formatMessage({id: 'general.december'})
        ];

        return month[monthNumber];
    }

    return {
        formatDate,
        getMonth,
    }
}