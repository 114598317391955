import React, {useEffect, useState} from 'react';
import {CheckOutlined, CloseOutlined, MenuOutlined} from '@ant-design/icons';
import {FormattedMessage, useIntl} from 'react-intl';
import {Button, Form, Space, Tabs, Transfer} from 'antd';
import {RequestSelect, Select, Switch} from 'components/Form';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Table, Title} from "ui";

import useHandleError from "utils/useHandleError";
import apiClient from "utils/apiClient";
import {config} from "config/config";

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale,
        session: state.session
    }
};

interface RecordType {
    key: string;
    name: string;
}

interface GroupsInterface {
    locale?: string;
}

const Groups: React.FC<GroupsInterface> = ({}) => {
    const [selectedUser, setSelectedUser] = useState('')
    const [admins, setAdmins] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [customers, setCustomer] = useState<RecordType[]>([]);
    const [targetKeys, setTargetKeys] = useState<string[]>([]);

    const params = useParams();
    const intl = useIntl();
    const programId = params.id;
    const [handleError] = useHandleError();



    return (
        <>
Groups
        </>
    )
};

export default connect(mapStateToProps)(Groups);
