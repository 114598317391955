import {useState} from 'react'
import useHandleError from 'utils/useHandleError';
import apiClient from 'utils/apiClient';
import {UploadFile} from 'antd';
import {RcFile} from 'antd/lib/upload';

export const useCertificate = () => {
    const [handleError] = useHandleError();
    const [programLoading, setProgramLoading] = useState<boolean>(false)

    const getProgramFiles = async (certificateId: number): Promise<UploadFile[]> => {
        setProgramLoading(true);

        try {
            const response = await apiClient.request(`/api/v1/courses/certificates/files/${certificateId}`, [], 'GET');

            if (response && response.data && Array.isArray(response.data)) {
                return response.data as UploadFile[];
            }

            throw response;
        } catch (error) {
            handleError(error);
            setProgramLoading(false);
            return [];
        } finally {
            setProgramLoading(false);
        }
    }

    const storeProgramFile = async (file: RcFile): Promise<number | undefined> => {
        setProgramLoading(true);

        try {
            const bodyFormData = new FormData();
            bodyFormData.append('file', file);
            const response = await apiClient.request(`/api/v1/courses/certificates/files`, bodyFormData, 'POST');

            if (!!response && typeof response === 'object' && !!response.id) {
                return response.id;
            }

            throw response;

        } catch (error) {
            handleError(error);
        } finally {
            setProgramLoading(false);
        }
    }

    const deleteProgramFile = async (id: number): Promise<boolean> => {
        setProgramLoading(true);

        try {
            const response = await apiClient.request(`/api/v1/courses/certificates/files/${id}`, [], 'DELETE');

            if (!!response && !!response.deleted) {
                return true;
            }

            throw response;

        } catch (error) {
            handleError(error);
            return false;
        } finally {
            setProgramLoading(false);
        }
    }

    return {
        programLoading,
        getProgramFiles,
        storeProgramFile,
        deleteProgramFile,
    }
}