import React, {useState, useEffect} from 'react';
import {MenuOutlined} from '@ant-design/icons';
import {FormattedMessage, useIntl} from 'react-intl';
import {Popover, Space, Dropdown} from 'antd';
import {DownOutlined} from '@ant-design/icons';
import {Switch} from 'components/Form';
import coursesApiClient from 'utils/coursesApiClient';
import {connect} from 'react-redux';
import {NavLink, useNavigate, useParams} from 'react-router-dom';
import {Table} from "ui";
import useHandleError from "utils/useHandleError";
import {config} from "config/config";
import ActivityModal from "../Modals/ActivityModal";
import helper from "utils/generalHelpers";
import './styles.scss';
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import type {SortableContainerProps, SortEnd} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import DefaultLayout from "../../DefaultLayout";
import LectureTransferModal from 'components/Lectures/Modals/LectureTransferModal';
import {useLecture} from "hooks/Lectures/useLecture";
import ActivityCreationModal from "./ActivityCreationModal";

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale,
        session: state.session
    }
};

interface MaterialsInterface {
    locale?: string;
    launchButton: HTMLElement;
    program: any;
}

const SortableItem = SortableElement((props: React.HTMLAttributes<HTMLTableRowElement>) => (
    <tr {...props} />
));
const SortableBody = SortableContainer((props: React.HTMLAttributes<HTMLTableSectionElement>) => (
    <tbody {...props} />
));

const Materials: React.FC<MaterialsInterface> = ({locale = config.defaultEnvironmentLocale, launchButton, program}) => {
    const [loading, setIsLoading] = useState(false);
    const [minutes, setMinutes] = useState(0)
    const [activitiesCount, setActivitiesCount] = useState(0)
    const [showActivityModal, setShowActivityModal] = useState(false)
    const [showLectureModal, setShowLectureModal] = useState(false)
    const [reload, setReload] = useState(false);
    const [dataSource, setDataSource] = useState<any>([]);
    const [lectures, setLectures] = useState([]);
    const [orderMaterials, setOrderMaterials] = useState(false);
    const [showTopicCreationModal, setShowTopicCreationModal] = useState(false);
    const [visibleTooltip, setVisibleTooltip] = useState<number | null>(null);
    const [sequenceSwitch, setSequenceSwitch] = useState(false)

    const params = useParams();
    const intl = useIntl();
    const navigate = useNavigate();
    const programId = params.id;
    const [handleError] = useHandleError();
    const {addLectureToLearningProgram, lectureLoading} = useLecture();

    useEffect(() => {
        if (program) {
            setSequenceSwitch(program.forceSequence)
        }
    }, [program])

    useEffect(() => {
        if (dataSource.length) {
            let minutesCount = 0;
            Object.values(dataSource).map((el: any) => {
                if (el.minutes) {
                    minutesCount += el.minutes
                }
            })

            setActivitiesCount(dataSource.length)
            setMinutes(minutesCount)
        }
    }, [dataSource])

    const DragHandle = SortableHandle(() => <MenuOutlined style={{cursor: 'grab', color: '#999'}}/>);

    const handleDelete = async (id: any) => {
        if (!(program?.visualPermissions?.edit || program?.visualPermissions?.admin)) {
            return;
        }
        try {
            await coursesApiClient.request(`/api/v1/courses/learning-program/materials/${id}/delete`, {}, 'DELETE');
            setReload(true);
        } catch (error) {
            handleError(error)
        }
    }

    const handleRowActionVisibleChange = (visible: boolean, recordId: number) => {
        if (visible) {
            setVisibleTooltip(recordId);
        } else {
            setVisibleTooltip(null);
        }
    };

    const columns = [
        {
            title: intl.formatMessage({id: 'general.sort'}),
            width: 30,
            className: 'drag-visible',
            render: () => !(program?.visualPermissions?.edit || program?.visualPermissions?.admin) ? <MenuOutlined style={{cursor: 'not-allowed', color: '#e1e1e1'}}/> :  <DragHandle/>,
        },
        {
            title: intl.formatMessage({id: 'general.activity'}),
            render: (_text: string, record: any) => {
                if (record.lectureTitle) {
                    return <div className={'activity-name' + (!record.isOnline ? ' disabled-cell' : '')}>
                        {record.lectureTitle}
                        <div><FormattedMessage id='general.lecture'/></div>
                    </div>
                } else if (record.activityTitle) {
                    return <div className={'activity-name' + (!record.isOnline ? ' disabled-cell' : '')}>
                        {record.activityTitle[locale] ? record.activityTitle[locale] : record.activityTitle[Object.keys(record.activityTitle)[0]]}
                        <div><FormattedMessage id='program.e_learning_activity'/></div>
                    </div>
                }
            }
        },
        {
            title: intl.formatMessage({id: 'courses.course_title'}),
            render: (_text: string, record: any) => {
                if (record.nameTranslations) {
                    return (
                        <div className={!record.isOnline ? ' disabled-cell' : ''}>
                            <NavLink to={`/courses/${record.courseId}/edit`}>
                                {record.nameTranslations[locale] ? record.nameTranslations[locale] : record.nameTranslations[Object.keys(record.nameTranslations)[0]]}
                            </NavLink>
                        </div>
                    );
                } else return '-';
            }
        },
        {
            title: intl.formatMessage({id: 'campaign.available_languages'}),
            render: (_text: string, record: any) => {
                if (record.availableLanguages) {
                    const languageArray = record.availableLanguages.split(',')

                    return <div className={!record.isOnline ? ' disabled-cell' : ''}>
                        {languageArray.filter(helper.onlyUnique).join(',')}
                    </div>

                } else return '-';
            }
        },
        {
            title: intl.formatMessage({id: 'general.pass_rate'}),
            render: (_text: string, record: any) => {
                if (record.passRate) {
                    return <div className={!record.isOnline ? ' disabled-cell' : ''}> {record.passRate}% </div>
                } else return '-';
            }
        },
        {
            title: intl.formatMessage({id: 'general.minutes'}),
            render: (_text: string, record: any) => {
                if (record.minutes) {
                    return <div className={!record.isOnline ? ' disabled-cell' : ''}><FormattedMessage
                        id="student.activity_minutes" values={{amount: record.minutes}}/></div>
                } else return '-';
            }
        },
        {
            title: intl.formatMessage({id: 'program.mandatory_first'}),
            render: (_text: string, record: any) => {
                if (record.type === 'FILE') {
                    return '-';
                }

                return <Switch loading={record?.loadingMandatory} disabled={!record.isOnline || !(program?.visualPermissions?.edit || program?.visualPermissions?.admin)}
                               onChange={() => handleSwitch(record, 'mandatory')}
                               defaultChecked={!!record.mandatory}/>
            }
        },
        {
            title: intl.formatMessage({id: 'program.mandatory_second'}),
            render: (_text: string, record: any) => {
                if (record.type === 'FILE') {
                    return '-';
                }

                return <Switch loading={record?.mandatoryRepeatLoading} disabled={!record.isOnline || !(program?.visualPermissions?.edit || program?.visualPermissions?.admin)}
                               onChange={() => handleSwitch(record, 'mandatory-repeat')}
                               defaultChecked={!!record.mandatoryRepeat}/>
            }
        },
        {
            title: intl.formatMessage({id: 'program.access_after_pass'}),
            render: (_text: string, record: any) => {
                return <Switch loading={record?.accessAfterPassLoading} disabled={!record.isOnline || !(program?.visualPermissions?.edit || program?.visualPermissions?.admin)}
                               onChange={() => handleSwitch(record, 'access')}
                               defaultChecked={!!record.accessAfterPass}/>
            }
        },
        {
            title: intl.formatMessage({id: 'program.is_online'}),
            render: (_text: string, record: any) => {
                return <Switch loading={record?.isOnlineLoading} onChange={() => handleSwitch(record, 'is-online')}
                               disabled={!(program?.visualPermissions?.edit || program?.visualPermissions?.admin)}
                               defaultChecked={!!record.isOnline}/>
            }
        },
        {
            title: intl.formatMessage({id: 'general.actions'}),
            key: 'actions',
            render: (_text: string, record: any) => {
                const content = (
                    <>
                        <div className={"popover-item " + (!(program?.visualPermissions?.edit || program?.visualPermissions?.admin) ? 'important-x-cursor' : '')}
                             onClick={() => handleDelete(record.id)}>
                            <FormattedMessage id="general.delete"/>
                        </div>
                        {!!record.activityId &&
                            <div className="popover-item"
                                 onClick={() => handleEdit(record)}>
                                <FormattedMessage id="general.edit"/>
                            </div>
                        }
                    </>
                )

                return (
                    <Popover
                        open={record.id === visibleTooltip}
                        content={content}
                        trigger="click"
                        placement="bottomRight"
                        arrowPointAtCenter
                        onOpenChange={(visible) => handleRowActionVisibleChange(visible, record.id)}
                    >
                        <div style={{width: '100%', cursor: 'pointer', textAlign: 'center'}}>
                            <i className="fal fa-ellipsis-v" style={{fontSize: '16px'}}/>
                        </div>
                    </Popover>
                )
            },
        },
    ];

    const handleEdit = (record: any) => {
        setTimeout(function () {
            window.open(`/courses/${record.courseId}/material/${record.activityId}`)
        }, 100);
    }

    const onSortEnd = ({oldIndex, newIndex}: SortEnd) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMove(dataSource, oldIndex, newIndex)
            const sortIds = newData.map((item: any, key: number) => {
                return item.id
            })
            setDataSource(newData);
            updateOrder(sortIds)
            setOrderMaterials(true)
        }
    };

    const updateOrder = async (orderIds: any) => {
        setIsLoading(true)
        try {
            await coursesApiClient.request(`/api/v1/courses/learning-program/materials/${programId}/order`, {orderIds}, 'PUT');
            setOrderMaterials(false)
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    const DraggableContainer = (props: SortableContainerProps) => (
        <SortableBody
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    );

    const DraggableBodyRow: React.FC<any> = ({className, style, ...restProps}) => {
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = dataSource.findIndex((x: any) => x.id === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };

    const handleAddLectureToProgram = async (lecturesIds: []) => {
        if (!program) {
            return;
        }

        if (await addLectureToLearningProgram(program.id, lecturesIds)) {
            setShowLectureModal(false);
            setReload(!reload);
        }
    }

    const handleSequenceSwitch = async (sequence: boolean) => {
        setIsLoading(true)
        setSequenceSwitch(sequence);
        try {
            await coursesApiClient.request(`/api/v1/courses/learning-program/${programId}/update`, {forceSequence: sequence}, 'PUT');
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    const handleSwitch = async (record: any, param: any) => {
        let key: any = Object.entries(dataSource).filter((el: any) => el[1].id === record.id);
        setOrderMaterials(true)
        try {
            let data;
            let change = false;

            switch (param) {
                case "mandatory":
                    data = !record.mandatory
                    dataSource[key[0][0]].loadingMandatory = true
                    change = await updateSettings(record, param)
                    if (change) {
                        dataSource[key[0][0]].mandatory = data
                    }
                    break;
                case "mandatory-repeat":
                    data = !record.mandatoryRepeat
                    dataSource[key[0][0]].mandatoryRepeatLoading = true
                    change = await updateSettings(record, param)
                    if (change) {
                        dataSource[key[0][0]].mandatoryRepeat = data
                    }
                    break;
                case "access":
                    data = !record.accessAfterPass
                    dataSource[key[0][0]].accessAfterPassLoading = true
                    change = await updateSettings(record, param)
                    if (change) {
                        dataSource[key[0][0]].accessAfterPass = data
                    }
                    break;
                case "is-online":
                    data = !record.isOnline
                    dataSource[key[0][0]].isOnlineLoading = true
                    change = await updateSettings(record, param)
                    if (change) {
                        dataSource[key[0][0]].isOnline = data
                    }
                    break;
                default:
                    return;
            }

            setReload(true);
            setDataSource(dataSource);
        } catch (error) {
            handleError(error)
        } finally {
            setOrderMaterials(false)
        }
    }

    const updateSettings = async (record: any, param: any) => {
        try {
            await coursesApiClient.request(`/api/v1/courses/learning-program/materials/${record.id}/update/${param}`, {}, 'PUT');

            return true;
        } catch (error) {
            handleError(error)
            return false;
        }
    }

    return (
        <div className={'program-activities'}>
            <h1 className='text-16'>{intl.formatMessage({id: 'program.add_learning_activities'})}</h1>
            <Space size={16} className='activity-sum'>
                {activitiesCount > 0 ?
                    <span>
                        <FormattedMessage id={intl.formatMessage({id: 'general.activities'})}/>:
                        <div>{activitiesCount}</div>
                    </span>
                    : null}
                {minutes > 0 ?
                    <span>
                        <FormattedMessage id={intl.formatMessage({id: 'general.minutes'})}/>
                        <div><FormattedMessage id="student.activity_minutes" values={{amount: minutes}}/></div>
                    </span>
                    : null}
            </Space>

            <div className='flex-between flex-wrap'>
                <Space size={16} className='flex-wrap'>
                    <Dropdown.Button trigger={['click']}
                                     placement="bottomRight"
                                     type="primary"
                                     disabled={!(program?.visualPermissions?.edit || program?.visualPermissions?.admin)}
                                     menu={{
                                         items: [{
                                             label: intl.formatMessage({id: 'program.add_learning_activity'}),
                                             key: '1',
                                             onClick: () => setShowActivityModal(true),
                                         }, {
                                             label: intl.formatMessage({id: 'program.new_program_activity'}),
                                             key: '2',
                                             onClick: () => setShowTopicCreationModal(true),
                                         }],
                                     }}
                                     onClick={() => setShowActivityModal(true)}
                                     icon={<DownOutlined/>}>
                        <Space size={8}><i className="fa-solid fa-book"></i><FormattedMessage
                            id='program.add_learning_activity'/></Space>
                    </Dropdown.Button>

                    <Dropdown.Button trigger={['click']}
                                     placement="bottomRight"
                                     disabled={!(program?.visualPermissions?.edit || program?.visualPermissions?.admin)}
                                     type="primary"
                                     menu={{
                                         items: [{
                                             label: intl.formatMessage({id: 'program.add_lecture'}),
                                             key: '1',
                                             onClick: () => setShowLectureModal(true),
                                         }, {
                                             label: intl.formatMessage({id: 'general.lectures.create_new_lecture'}),
                                             key: '2',
                                             onClick: () => {
                                                 navigate(`/lectures?program=${programId}`)
                                             },
                                         }],
                                     }}
                                     onClick={() => setShowLectureModal(true)}
                                     icon={<DownOutlined/>}>
                        <Space size={8}><i className="fa-solid fa-calendar-check"></i><FormattedMessage
                            id='program.add_lecture'/></Space>
                    </Dropdown.Button>
                </Space>
                <div className={'flex gap-10 strict-order'}>
                    <FormattedMessage id="program.sequence_parameter"/>
                    <Switch
                        loading={loading}
                        disabled={!(program?.visualPermissions?.edit || program?.visualPermissions?.admin)}
                        checked={sequenceSwitch}
                        onClick={(value: boolean) => handleSequenceSwitch(value)}
                    />
                </div>
            </div>
            <Table
                columns={columns}
                url={`/api/v1/courses/learning-program/materials/${programId}`}
                setReload={setReload}
                reload={reload}
                rowSelection={false}
                rowKey={'id'}
                manualDataUpdate={orderMaterials}
                silentReload={reload}
                updateData={dataSource}
                saveData={setDataSource}
                components={{
                    body: {
                        wrapper: DraggableContainer,
                        row: DraggableBodyRow,
                    },
                }}
                scroll={{x: 800}}
            />
            <DefaultLayout.PageFooter
                right={
                    <>
                        {launchButton}
                    </>
                }
            />
            {program &&
                <ActivityModal
                    visible={showActivityModal}
                    onCancel={setShowActivityModal}
                    setReload={setReload}
                    program={program}
                />
            }

            {program && <LectureTransferModal
                oneWay
                visible={showLectureModal}
                onCancel={setShowLectureModal}
                onOk={handleAddLectureToProgram}
                selectedLectures={[]}
                excludeKeys={lectures.map((lecture: any) => lecture.lecturesId)}
            />}

            <ActivityCreationModal
                visible={showTopicCreationModal}
                onCancel={setShowTopicCreationModal}
                program={program}
            />
        </div>
    )
};

export default connect(mapStateToProps)(Materials);
