import React, {useState, useEffect} from 'react';
import {Button, Collapse} from "antd";
import {Modal} from 'ui'
import {FormattedMessage, useIntl} from "react-intl";
import coursesApiClient from "utils/coursesApiClient";
import useHandleError from "utils/useHandleError";
import ResultTable from "./ResultTable";
import Spinner from "../../Spinner";
import SubmissionDetail from "../../AssignmentActivity/SubmissionDetail";


interface CertModalInterface {
    open: boolean;
    onCancel: React.Dispatch<React.SetStateAction<boolean>>;
    submission: any;
}

const CertModal: React.FC<CertModalInterface> = ({open, onCancel, submission}) => {

    return (
        <Modal
            open={open}
            width={'600px'}
            onCancel={() => onCancel(false)}
            className='add_remove_activity'
            title={submission.title}
            destroyOnClose={true}
            footer={
                <Button onClick={() => onCancel(false)}>
                    <FormattedMessage id='general.close'/>
                </Button>
            }
        >
            <SubmissionDetail
                submission={submission}
            />
        </Modal>
    )
};

export default CertModal;
