import React, {useState, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {message, Button, Layout, Card, Spin, Tabs, Space} from 'antd';
import {connect} from 'react-redux';
import apiClient from 'utils/apiClient';
import {Select} from 'components/Form';
import {config} from "config/config";
import VideoInstructionsCreateModal from "components/VideoInstructions/VideoInstructionsCreateModal";
import VideoInstruction from "components/VideoInstructions/VideoInstruction";
import {VideoInstructionInterface} from 'components/VideoInstructions/types';
import DefaultLayout from "components/DefaultLayout";
import {NavLink} from "react-router-dom";
import './styles.scss';
import StudentManual from "../StudentManual";

const mapStateToProps = (state: any) => {
    return {
        session: state.session,
        locale: state.locale.locale
    }
};

interface VideoInstructionsInterface {
    session: any;
    locale: string;
}

const VideoInstructions: React.FC<VideoInstructionsInterface> = ({ session, locale}) => {
    const [showCreateInstructionModal, setShowCreateInstructionModal] = useState(false);
    const [showVideoInstructionModal, setShowVideoInstructionModal] = useState(false);
    const [instructionsLoading, setInstructionsLoading] = useState(false);
    const [instructions, setInstructions] = useState([]);
    const [videoInstructionCode, setVideoInstructionCode] = useState<string>();
    const [videoInstructionId, setVideoInstructionId] = useState<number>();
    const [positions, setPositions] = useState();
    const [positionsLoading, setPositionsLoading] = useState(false);
    const [activeTabKey, setActiveTabKey] = useState(session.active_user_type !== 'SUPERVISION' ? '1' : '2');
    const [terms, setTerms] = useState<any>();

    const intl = useIntl();
    const {Header, Content} = Layout;
    const {TabPane} = Tabs;

    const userRole = session.active_user_type;

    useEffect(() => {
        const loadPositions = async () => {
            setPositionsLoading(true);
            try {
                const response = await apiClient.request(config.api.routes.backend.instructions.positions, {}, 'GET');
                let positions: any = [];
                let positionsKeys: any = [];

                Object.entries(response.instructionPositions).map((el: any) => {
                    positions[el[0]] = <FormattedMessage id={'system.positions_' + el[1]}/>;
                    positionsKeys[el[0]] = 'system.positions_' + el[1];

                    return positionsKeys;
                });

                setPositions(positions);
                setPositionsLoading(false);
            } catch (error) {
                console.error(error);
                message.error(intl.formatMessage({id: 'error.data_load'}));
                setPositionsLoading(false);
            }
        };

        const loadTerms = async () => {
            let response = await apiClient.request('/api/v1/terms-and-conditions/' + intl.locale, [], 'GET');
            setTerms(response.terms.text);
        };

        if (userRole !== 'SUPERVISION') {
            loadTerms();
            loadPositions();
            loadInstructions();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl]);

    const loadInstructions = async (position: number = 0) => {
        setInstructionsLoading(true);

        let parsedValues: { language: string, position?: number } = {
            language: locale
        };

        if (position !== 0) {
            parsedValues.position = position;
        }

        let response;
        try {
            response = await apiClient.request(`/api/v1/instruction/list`, parsedValues, 'POST');
            setInstructions(response.instructions);
            setInstructionsLoading(false);
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({id: 'error.data_load'}));
            setInstructionsLoading(false);
        }
    };

    return (
        <Layout className='instructions-layout'>
            <Header className='instructions-header'>
                <div>
                    {intl.formatMessage({id: 'system.help'})}
                </div>
            </Header>
            <span>
                <div className='video-instructions-content'>
                    <Tabs onChange={(activeKey: string) => setActiveTabKey(activeKey)} defaultActiveKey={activeTabKey}
                        tabBarExtraContent={
                            <Space>
                                {activeTabKey === '1' &&
                                    <>
                                        <Select
                                            className='instruction-filter-select'
                                            allowClear
                                            customLayout={{ labelCol: {span: 0}, wrapperCol: {span: 24} }}
                                            name="position"
                                            integerKey={true}
                                            placeholder={intl.formatMessage({id: 'system.video.all_instructions'})}
                                            validation={{required: true}}
                                            manualOptions={positions}
                                            loading={positionsLoading}
                                            onChange={(el: number) => { loadInstructions(el); }}
                                        />
                                        <span style={{width: 10}}/>
                                        {userRole !== 'CUSTOMER_ADMIN' && userRole !== 'STUDENT' &&
                                        <Button style={{marginRight: 18}} type="primary" onClick={() => setShowCreateInstructionModal(true)}>
                                            <i className="far fa-plus"></i> <FormattedMessage id="system.add_video"/>
                                        </Button>
                                        }
                                    </>
                                }
                                {activeTabKey === '3' &&
                                    <div className='terms-conditions-button'>
                                        <NavLink to='/terms-and-conditions/edit'>
                                            <Button type="primary" className='terms-edit-button'>
                                                Edit
                                            </Button>
                                        </NavLink>
                                    </div>
                                }
                            </Space>
                        }>
                        {userRole !== 'SUPERVISION' &&
                            <TabPane tab={<FormattedMessage id="system.video.instructions"/>} key="1">
                                <hr className="form-group-separator"/>
                                <Spin style={{marginTop: 20}} spinning={instructionsLoading}>
                                    <Content className='instructions-content'>
                                        {instructions &&
                                        instructions.map((element: VideoInstructionInterface) => {
                                            return (
                                                <Card
                                                    hoverable
                                                    className='instructions-card'
                                                    onClick={() => {
                                                        setVideoInstructionCode(element.videoCode);
                                                        setShowVideoInstructionModal(true);
                                                        setVideoInstructionId(element.id);
                                                    }}
                                                >
                                                    <img className='instructions-card-image'
                                                         src={`https://img.youtube.com/vi/${element.videoCode}/maxresdefault.jpg`}
                                                         alt=''/>
                                                    <div className='instruction-settings'>
                                                        <span className='instruction-title'>
                                                            {element.title.substring(0, 65)}
                                                            <span>
                                                                {element.title.length > 65 ? '...' : null}
                                                            </span>
                                                        </span>
                                                        <hr className="form-group-separator"/>
                                                        {element.videoLength &&
                                                            <span className='instruction-info'>
                                                                <i className="fal fa-clock"></i>
                                                                <span style={{marginRight: 5}}>
                                                                    {element.videoLength}
                                                                </span>
                                                                <FormattedMessage id="general.minutes"/>
                                                            </span>
                                                        }

                                                    </div>
                                                </Card>
                                                )
                                            })
                                        }
                                    </Content>
                                </Spin>
                            </TabPane>
                        }

                        <TabPane tab={<FormattedMessage id="system.support"/>} key="2">
                            <hr className="form-group-separator"/>
                            <Content className='support-content'>
                                <div>
                                    <div className='support-title'>
                                        <FormattedMessage id="system.support_info"/>:
                                    </div>
                                    <div>
                                         <span className='support-info-title'><FormattedMessage id="system.support_email"/>:</span> <span className='support-info'>{config.api.supportInfoEmail}</span>
                                        <br/>
                                        <span className='support-info-title'><FormattedMessage id="system.support_phone"/>:</span> <span className='support-info'>{config.api.supportInfoPhone}</span>
                                    </div>
                                </div>
                            </Content>
                        </TabPane>
                        {userRole !== 'SUPERVISION' &&
                            <TabPane tab={<FormattedMessage id="system.terms_and_conditions"/>} key="3">
                                <hr className="form-group-separator"/>
                                <Content className='terms-conditions-content'>
                                    <DefaultLayout.PageContent>
                                        <div style={{fontSize: 16}} dangerouslySetInnerHTML={{__html: terms}}></div>
                                    </DefaultLayout.PageContent>
                                </Content>
                            </TabPane>
                        }
                        <TabPane tab={<FormattedMessage id="system.student_manual"/>} key="student_manual">
                            <StudentManual />
                        </TabPane>
                              </Tabs>
                          </div>
                        </span>
            <VideoInstructionsCreateModal
                visible={showCreateInstructionModal}
                onCancel={() => setShowCreateInstructionModal(false)}
                afterSubmit={() => {
                    setShowCreateInstructionModal(false);
                }}
                positions={positions}
                reloadInstructions={loadInstructions}
            />

            <VideoInstruction
                visible={showVideoInstructionModal}
                onCancel={() => setShowVideoInstructionModal(false)}
                videoCode={videoInstructionCode}
                id={videoInstructionId}
                reset={false}
                positions={positions}
                reloadInstructions={loadInstructions}
            />

        </Layout>
    )
};

export default connect(mapStateToProps)(VideoInstructions);
