import Cookies from 'js-cookie';

function useJwt() {
    const setCookieWithMaxAge = (name: string, value: string, maxAgeSeconds: number) => {
        Cookies.set(name, value, { expires: maxAgeSeconds / (24 * 60 * 60) });
    };

    const setInitialCookies = (token: string, expires: string, refreshToken: string, refreshExpires: string) => {
        Cookies.set('jwt', token, { expires: new Date(expires) });
        Cookies.set('jwt_refresh', refreshToken, { expires: new Date(refreshExpires) });
    };

    const setRefreshCookie = (refreshToken: string, refreshExpires: string) => {
        Cookies.set('jwt_refresh', refreshToken, { expires: new Date(refreshExpires) });
    };

    const setInactivityCookie = (inactivityToken: string, inactivityExpires: string) => {
        Cookies.set('jwt_inactivity', inactivityToken, { expires: new Date(inactivityExpires) });
    };

    const setToken = (token: string, expires: number) => {
        setCookieWithMaxAge('jwt', token, expires);
    };

    const get = () => {
        return Cookies.get('jwt');
    };

    const getInactivity = () => {
        return Cookies.get('jwt_inactivity');
    };

    const isTokenActive = () => {
        return !!get() || !!getRefresh();
    };

    const getRefresh = () => {
        return Cookies.get('jwt_refresh');
    };

    const getHeader = () => {
        return 'Bearer ' + get();
    };

    const remove = () => {
        Cookies.remove('jwt');
        Cookies.remove('jwt_refresh');
        Cookies.remove('jwt_inactivity');
        localStorage.removeItem('INACTIVITY_TOKEN_LIFE');
        localStorage.removeItem('REFRESH_TOKEN_LIFE');
    };

    // const sessionTime = () => {
    //     const decodedToken = getDecoded();
    //     if (decodedToken && decodedToken?.iat > 0) {
    //         return decodedToken?.exp - decodedToken?.iat;
    //     }
    //     return decodedToken.exp;
    // };

    return {
        setInitialCookies,
        setToken,
        get,
        getRefresh,
        getHeader,
        remove,
        isTokenActive,
        setRefreshCookie,
        setInactivityCookie,
        getInactivity,
    };
}

export default useJwt;
