import React, { useState } from "react";
import { FormattedMessage, useIntl } from 'react-intl';
import { NavLink, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Spinner from 'components/Spinner';
import { Form, Button, Layout, Row, Card, Spin, Checkbox } from 'antd';
import { changeLocale } from 'store/actions/locale';
import { changeIsEmailSent } from 'store/actions/resetPassword';
import Input from 'components/Form/Input';
import AppearanceStyler from "components/NoAuthHelper/AppearanceStyler";
import apiClient from 'utils/apiClient';
import { PhoneNumberSelect } from 'ui';
import '../styles.scss';

const { Header, Footer, Content } = Layout;

const mapStateToProps = (state: any) => {
  return {
    locale: state.locale.locale,
    resetPasword: state.isEmailSent,
    languageDictionary: state.languageDictionary,
    title: state.appearanceSettings.title,
    logoPath: state.appearanceSettings.logoPath,
    appearanceSettingsLoading: state.appearanceSettings.loading,
    globalTheme: state.publicAppearanceReducer
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  changeLocale: (locale: string) => dispatch(changeLocale(locale)),
  changeIsEmailSent: (isEmailSent: boolean) => dispatch(changeIsEmailSent(isEmailSent))
})

const ResetPasswordForm: React.FC = ({ locale, changeLocale, changeIsEmailSent, languageDictionary, globalTheme }: any) => {
  const [inProgress, setInProgress] = useState(false);
  const [appearanceIsLoading, setAppearanceIsLoading] = useState(!!!globalTheme);
  const [phoneLogin, setPhoneLogin] = useState(false);
  const intl = useIntl();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const submitForm = async (values: any) => {
    setInProgress(true);
    try {
      let parsedValues = {
        isEmail: !phoneLogin,
        userContact: phoneLogin ? `${values.code} ${values.phoneNumber}` : values.email,
        language: locale
      }

      await apiClient.request('/api/v1/password-reset', parsedValues, 'POST');

      setInProgress(false);
      navigate('/login');
    } catch (error) {

      console.error(error);
      setInProgress(false);
      navigate('/login');
    }
  }

  const getLogo = () => {
    if (globalTheme && globalTheme.logo && globalTheme.logoPath) {
      return <img className="login-card-logo" src={globalTheme.logo ? URL.createObjectURL(globalTheme.logo) : undefined} alt={globalTheme.title}/>
    } else {
      return <img className="login-card-logo" src={globalTheme.logo ? globalTheme.logo : "/cs-logo.svg"} alt={'CloudStudy'}/>
    }
  }

  return (
    <Spinner spinning={appearanceIsLoading} opaque>
      <Layout style={{ minHeight: '100vh' }}>
        <AppearanceStyler setAppearanceIsLoading={setAppearanceIsLoading} />
        <Header style={{ backgroundColor: globalTheme.theme?.themeBackground ? globalTheme.theme.themeBackground : '#463368' }} />
        <Content style={{ backgroundColor: globalTheme.theme?.themeBackground ? globalTheme.theme.themeBackground : '#463368', display: 'flex', flexDirection: 'column' }}>
          <div className="password-reset-wrapper">
          <Card className="login-card">
                <Spin spinning={appearanceIsLoading} size="small">
                  {getLogo()}
                </Spin>
                <p className="please-login-text">
                  <FormattedMessage id="login.forgot_your_password" />
                </p>
                <p className="reset-password-text">
                  {phoneLogin ?
                    <FormattedMessage id="login.enter_phone_number" />
                    :
                    <FormattedMessage id="login.enter_email" />
                  }
                </p>
                <Form className="login-form" onFinish={submitForm}>
                  {phoneLogin ?
                    <PhoneNumberSelect defaultCode={'371'} form={form} wrapperClassName='login-form-phone-input' name='phoneNumber' placeholder={intl.formatMessage({ id: "users.form.phone_number" })} prefix={<i className="fa-solid fa-mobile-screen" style={{ color: 'rgba(0,0,0,.25)' }} />} />
                    :
                    <Input
                      name="email"
                      prefix={<i className=" fal fa-envelope" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder={intl.formatMessage({ id: "login.email_address" })}
                      customRules={[
                        { required: true, message: intl.formatMessage({ id: "validation.field_required" }) },
                        { pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, message: intl.formatMessage({ id: "validation.email_pattern" }) }
                      ]}
                      customLayout={true}
                    />
                  }
                  <Checkbox onChange={(el: any) => setPhoneLogin(el.target.checked)} >
                    <FormattedMessage id='login.use_phone_to_login' />
                  </Checkbox>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button" loading={inProgress} style={{marginTop: '5px', marginBottom: '10px'}} onClick={() => changeIsEmailSent(true)} >
                      <FormattedMessage id="login.reset_password" /> 
                    </Button>
                  </Form.Item>
                  <NavLink to="/login">
                    <p className="forgot-password-text" style={{ textAlign: 'center' }}>
                      <FormattedMessage id="general.back" />
                    </p>
                  </NavLink>
                </Form>
              </Card>
          </div>
          <Row justify="center" align="top" style={{ flex: 1 }}>
            {
              Object.entries(languageDictionary).map((entries: any) => {
                if (entries[0] !== 'loading' && entries[0] !== 'failed') {
                  return (
                    <Button className={"locale-switch" + (locale === entries[0] ? " active" : "")} onClick={() => changeLocale(entries[0])}>{entries[0]}</Button>
                  )
                }
                return null;
              })
            }
          </Row>
        </Content>
        <Footer style={{  backgroundColor: globalTheme.theme?.themeBackground ? globalTheme.theme.themeBackground : '#463368', textAlign: 'center' }}>
          <ul className="footer-list">
            <li>© CloudStudy Ltd</li>
            <li><FormattedMessage id="login.terms" /></li>
            <li><FormattedMessage id="login.terms_and_conditions" /></li>
            <li><FormattedMessage id="login.cookies" /></li>
          </ul>
        </Footer>
      </Layout>
    </Spinner>
  )

}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm);
