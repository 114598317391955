import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { message, Input, Button } from 'antd';
import Spinner from 'components/Spinner';
import { Stage, Layer, Image  } from 'react-konva';
import {debounce} from 'lodash';
import ShapeEdit from './ShapeEdit';

import './styles.scss';

const HotspotImageEdit: React.FC<any> = ({ 
    visible,
    imageUrl, 
    isEdit = false, 
    hotSpotShapeType,
    onShapeAdded,
    answers,
    answerFieldOnChangeDebounce,
    answerFieldOnChangeNoDebounce,
    warnDeletion,
    deleteAnswerByKey,
    deleteAnswer,
    isSavingAnswer
}) => {
    const [isLoading, setIsLoading] = useState(false);

    // Shape data
    const [mainImage, setMainImage] = useState<HTMLImageElement>();
    const [canvasDimentions, setCanvasDimentions] = useState<any>({
        width: 500,
        height: 500
    });
    const [shapesData, setShapesData] = useState<any>([]);
    const [showHtmlPopover, setShowHtmlPopover] = useState<boolean>(false);
    
    const intl = useIntl();
    const imageRef = React.useRef<any>();
    const canvasImageRef = React.useRef<any>();
    const canvasRef = React.useRef<any>();
    const isMouseDown = React.useRef<boolean>(false);
    const selectedShapeId = React.useRef<null|number>(null);

    useEffect(() => {
        // Delete shape with keyboard Delete key
        window.addEventListener('keydown', ({key}) => {
            if (key === "Delete") {
                onDeleteSpot()
            }
        })
    }, [])

    useEffect(() => {
        if(imageUrl && visible) {
            const baseImage = imageRef.current;
            baseImage.onload = function () {
                const image = new window.Image();
                image.src = imageUrl;
                image.onload = () => {
                    setMainImage(image)
                }
                resizeCanvas()
            }
        }
    }, [imageUrl, visible])

    useEffect(() => {
        if( visible ) {
            // Check and delete invalid shapes
            const shapes = answers.filter((item: any) => {
                if(item?.data && item?.data !== null) {
                    return true
                } else {
                    if( item.id !== 0 ) {
                        deleteAnswer(item.id, false)
                    }
                    return false;
                }
            })
            
            // selectedShapeId.current = null;
            setShapesData(shapes)
        }
    }, [answers, visible])

    const resizeCanvas = () => {
        const baseImage = imageRef.current;
        setCanvasDimentions({
            width: baseImage.width,
            height: baseImage.height,
        })
    }

    const drawNewCircle = (points: any, lastShape: any) => {
        const r = Math.abs((lastShape.data.x - points.x) / 2);
        lastShape.data.radius = r;
        lastShape.data.y = points.y;

        // replace last
        shapesData.splice(shapesData.length - 1, 1, lastShape);
        setShapesData(shapesData.concat());
    }

    const drawNewRect = (points: any, lastShape: any) => {
        const width = Math.abs(lastShape.data.x - points.x);
        const height = Math.abs(lastShape.data.y - points.y);
        lastShape.data.width = width;
        lastShape.data.height = height;

        // replace last
        shapesData.splice(shapesData.length - 1, 1, lastShape);
        setShapesData(shapesData.concat());
    }

    const drawNewLine = (points: any, lastShape: any) => {
        lastShape.data.points = lastShape.data.points.concat([points.x, points.y]);

        // replace last
        shapesData.splice(shapesData.length - 1, 1, lastShape);
        setShapesData(shapesData.concat());
    }

    const drawNewShape = (event: any) => {
        const points = event.target.getStage().getPointerPosition();
        let lastShape = shapesData[shapesData.length - 1];
        switch (hotSpotShapeType) {
            case 'CIRCLE':
                lastShape = drawNewCircle(points, lastShape)
                break;
            case 'RECT':
                lastShape = drawNewRect(points, lastShape)
                break;
            case 'LINE':
                lastShape = drawNewLine(points, lastShape)
                break;
            default:
                break;
        }
    }

    const validateNewElement = (event: any) => {
        const points = event.target.getStage().getPointerPosition();
        let lastShape = shapesData[shapesData.length - 1];
        if( lastShape.data.type == 'LINE' ) {
            const xAxis = lastShape.data.points.filter((poin: number, index: number) => {
                return index === 0 || index % 2 === 0;
            })
            const yAxis = lastShape.data.points.filter((poin: number, index: number) => {
                return index !== 0 && index % 2 !== 0;
            })

            if( Math.abs(xAxis[0] - Math.max(...xAxis)) < 5 || Math.abs(yAxis[0] - Math.max(...yAxis)) < 5) {
                onDeleteSpot(shapesData.length - 1)
            } else {
                setSelectedShapeId(shapesData.length - 1);
                setShowHtmlPopover(true)
            }
        } else {
            if( Math.abs(points.x - lastShape.data.x) < 5 && Math.abs(points.y - lastShape.data.y) < 5 ) {
                onDeleteSpot(shapesData.length - 1)
            } else {
                setSelectedShapeId(shapesData.length - 1);
                setShowHtmlPopover(true)
            }
        }
    }
    
    const handleMouseDown = (event: any) => {
        isMouseDown.current = true;
        checkDeselect(event);

        if( selectedShapeId.current === null && isEdit ) {
            const points = event.target.getStage().getPointerPosition();
            let newShape : any;
            switch (hotSpotShapeType) {
                case 'CIRCLE':
                    newShape = {
                        id: 0,
                        title: '',
                        isCorrect: 1,
                        data: {
                            x: points.x,
                            y: points.y,
                            radius: 1,
                            type: hotSpotShapeType
                        }
                    }
                    break;
                case 'RECT':
                    newShape = {
                        id: 0,
                        title: '',
                        isCorrect: 1,
                        data: {
                            x: points.x,
                            y: points.y,
                            width: 1,
                            height: 1,
                            type: hotSpotShapeType
                        }
                    };
                    break;

                case 'LINE':
                    newShape = {
                        id: 0,
                        title: '',
                        isCorrect: 1,
                        data: {
                            points: [points.x, points.y],
                            type: hotSpotShapeType
                        }
                    };
                    break;
            
                default:
                    break;
            }

            if( newShape ) {
                onShapeAdded(newShape)
            }
        }
    }

    const handleMouseMove = (event: any) => {
        if (isMouseDown.current) {
            if( selectedShapeId.current === null && isEdit ) {
                drawNewShape(event)
            }
        }
    }

    const handleMouseUp = (event: any) => {
        if( isMouseDown.current && selectedShapeId.current === null && isEdit ) {
            validateNewElement(event)
        }
        isMouseDown.current = false;
    }

    const onClickShape = (index: number) => {
        setSelectedShapeId(index)
        setShowHtmlPopover(true)
    }

    const onDragStartShape = (shape: any, index: number, e: any) => {
        setSelectedShapeId(index)
        setShowHtmlPopover(false)
    }

    const onDragEndShape = (shape: any, index: number, e: any) => {
        onShapeChange(shape, index, e.target);
        setShowHtmlPopover(true)
    }

    const onShapeChange = (shape: any, index: number, node: any, isTransformed: boolean = false) => {
        const shapes = [...shapesData];

        // transformer is changing scale of the node
        // and NOT its width or height
        // but in the store we have only width and height
        // to match the data better we will reset scale on transform end
        const scaleX = node?.scaleX();
        const scaleY = node?.scaleY();
     
        switch (shape.data.type) {
            case 'CIRCLE':
                shape.data = {
                    ...shape.data,
                    x: node.x(),
                    y: node.y(),
                    radius: Math.floor(node.width() * scaleX / 2),
                };
                break;

            case 'RECT':
                shape.data = {
                    ...shape.data,
                    x: node.x(),
                    y: node.y(),
                    width: Math.max(10, node.width() * scaleX),
                    height: Math.max(10, node.height() * scaleY),
                };
                break;

            case 'LINE':
                const transformer = node.getAbsoluteTransform();
                const points = shape.data.points;
                const newPoints : any[] = [];
                for (var n = 0; n < points.length; n += 2) {
                    const { x, y } = transformer.point({
                        x: points[n],
                        y: points[n + 1],
                    });

                    newPoints.push(x, y);
                }

                node.points(newPoints);

                node.x(0);
                node.y(0);
                
                shape.data = {
                    ...shape.data,
                    points: newPoints
                };
                break;
        }

        // we will reset it back
        node.scaleX(1);
        node.scaleY(1);
        
        setSelectedShapeId(index)
        
        shapes[index] = shape;
        setShapesData(shapes);

        answerFieldOnChangeNoDebounce(index, shape)
    }
    
    const setSelectedShapeId = (index: number|null) => {
        selectedShapeId.current = index;

        if( index !== null ) {
            setShapesData([...shapesData]);
        } else {
            setShowHtmlPopover(false)
        }
    }

    const checkDeselect = (e: any) => {
        // deselect when clicked on empty area
        const clickedOnEmpty = e.target === canvasImageRef.current;
        if (clickedOnEmpty) {
            setSelectedShapeId(null);
        }
    };

    const onChangeSpotTitle = debounce((value: string) => {
        if( selectedShapeId.current !== null ) {
            const shapes = shapesData.slice();
            shapes[selectedShapeId.current].title = value;
            setShapesData(shapes);

            // Update answer
            answerFieldOnChangeNoDebounce(selectedShapeId.current, shapes[selectedShapeId.current])
        } 
    }, 0);

    const onDeleteSpot = (index: number|null = selectedShapeId.current) => {
        if( index !== null ) {
            const shapes = [...shapesData];
            if(shapes[index].id !== 0) {
                warnDeletion(shapes[index].id)
            } else {
                deleteAnswerByKey(index)
            }

            setSelectedShapeId(null);
        } 
    }

    return (
        <Spinner spinning={isLoading}>
            <div className='hotspot-image'>
                <Stage
                    ref={canvasRef}
                    width={canvasDimentions.width}
                    height={canvasDimentions.height}
                    onMouseDown={handleMouseDown}
                    onMousemove={handleMouseMove}
                    onMouseup={handleMouseUp}
                >
                    <Layer>
                        <Image
                            x={0}
                            y={0}
                            image={mainImage}
                            ref={canvasImageRef}
                            width={canvasDimentions.width}
                            height={canvasDimentions.height}
                        />
                        {
                            shapesData.map((shape: any, index: number) => {
                                return (<ShapeEdit
                                    key={index}
                                    index={index}
                                    shape={shape}
                                    shapeProps={{
                                        id: `shape-${index}`,
                                        strokeWidth: 2, 
                                        stroke: `${selectedShapeId.current == index ? '#ffba32' : '#000000'}`,
                                        fill: "rgba(225,225,225,0.5)",
                                        draggable: (isEdit ? true : false),
                                        onDragStart: (e: any) => {
                                            onDragStartShape(shape, index, e)
                                        },
                                        onClick: () => onClickShape(index),
                                        onTap: () => onClickShape(index),
                                        onMouseDown: () => setSelectedShapeId(index),
                                        onDragEnd: (e: any) => {
                                            onDragEndShape(shape, index, e);
                                        },
                                    }} 
                                    onTransformEnd={(e: any, node: any) => {
                                        onShapeChange(shape, index, node, true);
                                    }}
                                    isSelected={selectedShapeId.current !== null && selectedShapeId.current === index}
                                    isEditingLast={(isMouseDown.current && selectedShapeId.current === null && isEdit && index == (shapesData.length - 1))}
                                />)
                            })
                        }
                    </Layer>
                </Stage>
                {selectedShapeId.current !== null && shapesData[selectedShapeId.current] && showHtmlPopover && isEdit ? <div
                    className='edit-hotspot-shape'
                >
                    <span className='hide-hotspot-box' onClick={() => {
                        setSelectedShapeId(null)
                    }}>
                        <i className='fa fa-times' />
                    </span>
                    <h3><FormattedMessage id='question_pools.edit_hotspot' /></h3>
                    <Input 
                        placeholder={intl.formatMessage({id: 'question_pools.edit_hotspot_placeholder'})} 
                        defaultValue={shapesData[selectedShapeId.current].title}
                        value={shapesData[selectedShapeId.current].title}
                        onChange={(e: any) => {
                            onChangeSpotTitle(e.target.value)
                        }}
                        disabled={isSavingAnswer}
                    />
                    <div style={{marginTop: '10px', textAlign: 'right'}}><Button type='primary' danger style={{padding: '0px 15px', background: '#ff4d4f', borderColor: '#ff4d4f', color: '#fff'}} onClick={() => onDeleteSpot()} disabled={isSavingAnswer}><FormattedMessage id='question_pools.delete_hotspot' /><i className="fa fa-trash"></i></Button></div>
                </div> : null}
                <img src={imageUrl} ref={imageRef} style={{
                    position: 'absolute', 
                    top: '0px', 
                    left: '0px',
                    opacity: 0,
                    visibility: 'hidden',
                    zIndex: '-1',
                    width: 710,
                    maxWidth: 'initial'
                }} />
            </div>
        </Spinner>
    )
}

export default HotspotImageEdit;
