import React, {useEffect, useState} from 'react';
import DefaultLayout from "components/DefaultLayout";
import Spinner from 'components/Spinner';
import {connect} from 'react-redux';
import {Button} from "ui";
import {FormattedMessage, useIntl} from "react-intl";
import moment from "moment/moment";
import Badge from "components/CampaignCard/components/Badge";
import {Card, Empty, message, Tag} from "antd";
import StudentProgramFilter from "components/StudentProgramFilter";
import useHandleError from "utils/useHandleError";
import coursesApiClient from "utils/coursesApiClient";
import FileDownload from "js-file-download";
import './styles.scss';
import {config} from "config/config";

const mapStateToProps = (state: any) => {
    return {
        session: state.session,
        locale: state.locale.locale,
    }
}

const MyCertificates: React.FC<any> = ({session, locale, userTypeId = null}) => {
    const [isLoading, setIsLoading] = useState(false);
    const intl = useIntl();
    const [filtersSelected, setFiltersSelected] = useState<any>({});
    const [searchString, setSearchString] = useState<string>('');
    const [reload, setReload] = useState<boolean>(false);
    const [programsList, setProgramsList] = useState<any>([]);
    const [programsListShow, setProgramsListShow] = useState<any>([]);
    const [handleError] = useHandleError();

    useEffect(() => {
        if ((session.active_user_type_id && session.active_user_type === 'STUDENT') || userTypeId) {
            loadPrograms();
        }
    }, []);

    useEffect(() => {
        loadPrograms();
    }, [searchString]);

    const loadPrograms = async () => {
        setIsLoading(true);

        let url = `/api/v1/courses/certificates/student?search=${searchString}`

        if (userTypeId) {
            url += `&userTypeId=${userTypeId}`;
        }

        try {
            const response = await coursesApiClient.request(url, [], 'GET');
            if (!response.programs) {
                return;
            }
            setProgramsList(response.programs);
            setProgramsListShow(response.programs);
        } catch (error) {
            handleError(error);
        } finally {
            setIsLoading(false);
        }
    }

    const downloadFile = async (name: string, id: number) => {
        try {
            let response = await coursesApiClient.request(`api/v1/courses/storage-file/${id}`, {}, 'GET', true, true);
            FileDownload(response, name);
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({id: 'error.data_load'}));
        }
    }

    useEffect(() => {
        showPrograms();
    }, [filtersSelected])

    const showPrograms = () => {
        let showCertificates: any = programsList.map((c: any) => {
            return {...c}
        }); // clone, not attach
        let show: any = [];

        if (filtersSelected.validity !== undefined) {
            if (filtersSelected.validity === 'valid') {
                Object.values(showCertificates).map((program: any) => {
                    let today = moment();
                    let expDate = moment(program.expirationDate);
                    if (program.validIndefinite === 1 || expDate.diff(today, 'seconds') > 0) {
                        show.push(program);
                    }
                })
            } else if (filtersSelected.validity === 'expired') {
                Object.values(showCertificates).map((program: any) => {
                    let today = moment();
                    let expDate = moment(program.expirationDate);
                    if (program.validIndefinite === 0 && expDate.diff(today, 'seconds') < 0) {
                        show.push(program);
                    }
                })
            }
            setProgramsListShow(show);
        } else {
            setProgramsListShow(showCertificates);
        }
    }

    const tags = function (validIndefinite: any, issueDate: any, expirationDate: any, termLimitValue: any) {
        if (validIndefinite === 1) {
            return (
                <Tag color={'green'}>
                    <FormattedMessage id={'general.valid'}/>
                </Tag>
            )
        } else {
            let today = moment();
            let expDate = moment(expirationDate);

            if (expDate.diff(today, 'seconds') > 0) {
                if (expDate.diff(today, 'days') > termLimitValue) {
                    return (
                        <Tag color={'green'}>
                            <FormattedMessage id={'general.valid'}/>
                        </Tag>
                    )
                } else {
                    return (
                        <Tag color={'orange'}>
                            <FormattedMessage id={'general.expires_soon'}/>
                        </Tag>
                    )
                }
            } else if (expDate.diff(today, 'seconds') < 0) {
                return (
                    <Tag color={'red'}>
                        <FormattedMessage id={'general.expired'}/>
                    </Tag>
                )
            }
        }
    }

    const renderPrograms = (list: any) => {
        if (!Object.keys(list).length) {
            return (
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={intl.formatMessage({id: 'general.found_no_data'})}
                />
            );
        }
        return (
            <div className={'my-certificates'}>
                {
                    Object.values(list).map((program: any) => {
                        return (
                            <Card>
                                <div className="container">
                                    <div className="details">
                                        <div className="badge">
                                            {!!program.title &&
                                                <Badge thumbnail={program.thumbnail} results={'100'}/>
                                            }
                                        </div>
                                        <div className="heading">
                                            {program.title ?
                                                program.title[locale] ? program.title[locale] : program.title[Object.keys(program.title)[0]]
                                                : program.certificateId
                                            }
                                        </div>
                                        <div className="date">
                                            <span><FormattedMessage
                                                id={"general.issue_date"}/>: {moment(program.issueDate).format(config.defaultDateFormat)}</span>
                                            <span><FormattedMessage id={"general.expiring_date"}/>:
                                                {!program.validIndefinite ?
                                                    moment(program.expirationDate).format(config.defaultDateFormat)
                                                    :
                                                    ' -'
                                                }
                                            </span>
                                        </div>
                                    </div>
                                    <div className="tags">
                                        {tags(program.validIndefinite, program.issueDate, program.expirationDate, program.termLimitValue)}
                                    </div>
                                    <div className="download">
                                        <Button type={'default'}
                                                onClick={() => downloadFile(program.name, program.storageId)}>
                                            <FormattedMessage id={"general.download"}/>
                                        </Button>
                                    </div>
                                </div>
                            </Card>
                        )
                    })
                }
            </div>
        )
    }

    return (
        <Spinner spinning={isLoading}>
            <DefaultLayout.PageLayout withoutPageLayout>
                <StudentProgramFilter
                    setSearchString={setSearchString}
                    setReload={setReload}
                    filtersSelected={filtersSelected}
                    setFiltersSelected={setFiltersSelected}
                    programsList={programsList}
                    status={false}
                    competences={false}
                    validity={true}
                />
                <div className="program-list-wrapper program-list ">
                    {renderPrograms(programsListShow)}
                </div>
            </DefaultLayout.PageLayout>
        </Spinner>
    )
};

export default connect(mapStateToProps)(MyCertificates);
