import React from 'react';
import { useIntl } from 'react-intl';
import { Form } from 'antd';
import { Modal } from 'ui';
import { TextArea } from 'components/Form';
import { SessionProps } from '../../types';
import { useSession } from 'hooks/Lectures/useSession';

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

interface IncreasedStudentInterestModalModaltInterface {
    session: SessionProps,
    visible?: boolean | undefined,
    onCancel(): any,
    onSubmit(): any,
}

const IncreasedStudentInterestModal: React.FC<IncreasedStudentInterestModalModaltInterface> = ({ session, visible = false, onCancel, onSubmit }) => {
    const { sendSessionStudentInterestMessage, sessionLoading } = useSession();
    const intl = useIntl();
    const [form] = Form.useForm();

    const handleOnCancel = () => {
        form.resetFields();
        onCancel();
    }

    const handleFormSubmit = async (values: any) => {
        if (session && session.id && session.lecturesId) {
            const request = await sendSessionStudentInterestMessage(session.lecturesId, session.id, values.message);

            if (request) {
                form.resetFields();
                onSubmit();
            }
        }
    };

    return (
        <Modal
            open={visible}
            isLoading={sessionLoading}
            onCancel={() => handleOnCancel()}
            title={intl.formatMessage({ id: 'general.lectures.notify_session_owner_increase_capacity' })}
            okText={intl.formatMessage({ id: 'general.send' })}
            cancelText={intl.formatMessage({ id: 'general.cancel' })}
            onOk={form.submit}
        >
            <Form form={form} onFinish={handleFormSubmit} {...formItemLayout}>
                <TextArea
                    name="message"
                    rows={3}
                    label={intl.formatMessage({ id: 'general.message' })}
                    customLayout={true}
                />
            </Form>
        </Modal>
    )
};
export default IncreasedStudentInterestModal;
