import React from 'react';
import { Modal } from 'antd';
import { PreviewCertificateModalInterface } from '../types';
import { config } from 'config/config';

const PreviewCertificateModal: React.FC<PreviewCertificateModalInterface> = ({
    visible,
    onCancel,
    previewPath,
}) => {
    return (
        <Modal
            className="campaign-certificate-modal"
            open={visible}
            onCancel={onCancel}
            footer={null}
            width={920}
        >
            <iframe key={'pdf-cert-preview'} className="manual-player" title="PDF_PREVIEW"
                src={`${config.api.previewUrl}/${previewPath}`} />
        </Modal>
    )
}
export default PreviewCertificateModal;
