import React from 'react';
import {Popover, Space} from 'antd';
import {Button} from "ui";
import {FormattedMessage, useIntl} from "react-intl";
import {TooltipPlacement} from "antd/es/tooltip";

interface FilterPopoverInterface {
    handleOpen: Function;
    content: any;
    loading?: boolean;
    title: string;
    buttonTitle: string;
    className?: string;
    placement?: TooltipPlacement;
    setOpen: React.Dispatch<React.SetStateAction<string>>;
    popoverKey: string;
    filterKey: string;
}

const FilterPopover: React.FC<FilterPopoverInterface> = ({
                                                             popoverKey,
                                                             filterKey,
                                                             setOpen,
                                                             handleOpen,
                                                             content,
                                                             className,
                                                             loading = false,
                                                             title,
                                                             buttonTitle,
                                                             placement = "bottom"
                                                         }) => {
    const intl = useIntl();

    return (
        <Popover open={popoverKey === filterKey} onOpenChange={(open: boolean) => handleOpen(open)}
                 placement={placement}
                 overlayClassName={className}
                 title={intl.formatMessage({id: title})}
                 content={content} trigger="click">
            <Button onClick={() => setOpen(filterKey === popoverKey ? '' : popoverKey)} loading={loading}
                    className={'mx-1 mb-1'} type={'outline'}>
                <Space size={5}>
                    <i className="fa-sharp fa-regular fa-plus"></i>
                    <FormattedMessage id={buttonTitle}/>
                </Space>
            </Button>
        </Popover>
    );
};

export default FilterPopover;