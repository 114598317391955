import React from 'react';
import './styles.scss';

const TagBuilder: React.FC<any> = ({
                                       setTags,
                                       tags,
                                       form,
                                       tempFilter,
                                       setTempFilter
                                   }) => {
    const renderTags = () => {
        return Object.entries(tags).map((element: any) => {

            const key = element[0];
            const value = element[1]

            if (value.label) {
                return (
                    <div key={key} className={'report-filter-tag flex gap-5 align-center'}>
                        {value.label}
                        <i onClick={() => removeFilter(value.id)} className="fa-duotone fa-xmark"></i>
                    </div>
                )
            } else {
                return;
            }
        })
    }

    const removeFilter = (id: any) => {
        const temp = Object.values(tags).filter((el: any) => el.id !== id)

        const deleteValue: any = (Object.values(tags).filter((el: any) => el.id === id))[0]

        if ((deleteValue.key).toLowerCase().includes('date') || (deleteValue.key).toLowerCase().includes('bool') || (deleteValue.key).toLowerCase().includes('count')) {
            form.setFieldsValue({
                [deleteValue.key]: null
            })
        } else if (deleteValue.key === 'enrollmentTypeStatus') {
            form.setFieldsValue({
                [deleteValue.key]: null
            })
        } else {
            let array = [];

            if (deleteValue.key.toLowerCase().includes('status')) {
                array = tempFilter[deleteValue.key].split(',').filter((el: any) => el !== deleteValue.id).join(',')
            } else {
                array = tempFilter[deleteValue.key].filter((el: any) => el !== deleteValue.id)
            }

            setTempFilter({...tempFilter, [deleteValue.key]: array})
        }

        setTags(temp)
    }

    return (
        <div className={'flex gap-5 flex-wrap'}>
            {renderTags()}
        </div>
    );
};

export default TagBuilder;