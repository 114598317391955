import {Transfer} from 'antd';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Modal} from 'ui';
import {useLecture} from "hooks/Lectures/useLecture";
import {LectureProps} from 'components/Lectures/types';
import './styles.scss'

interface LectureTransferModalInterface {
    selectedLectures: number[];
    excludeKeys?: number[];
    visible?: boolean | undefined;
    oneWay?: boolean | undefined;

    onCancel(cancel: boolean): any;

    onOk(data: number[]): any;
}

interface TransferRecordType {
    key: string;
    title: string;
    disabled?: boolean;
}

const LectureTransferModal: React.FC<LectureTransferModalInterface> = ({
                                                                           selectedLectures,
                                                                           excludeKeys,
                                                                           visible = false,
                                                                           oneWay = false,
                                                                           onCancel,
                                                                           onOk
                                                                       }) => {
    const [targetKeys, setTargetKeys] = useState<Array<any>>([]);
    const [lectures, setLectures] = useState<Array<TransferRecordType>>();

    const {getLectures, lectureLoading} = useLecture();
    const intl = useIntl();

    const filterLectures = (lectures: LectureProps[]): Array<TransferRecordType> => {
        const result: Array<TransferRecordType> = [];

        Object.entries(lectures).map((lecture: any): void => {
            const lectureId = lecture[0];
            if (!excludeKeys || !excludeKeys.includes(parseInt(lectureId))) {
                result.push({
                    key: lectureId,
                    title: lecture[1] ?? '',
                })
            }
        });

        return result;
    }

    useEffect(() => {
        const loadLectures = async () => {
            const response = await getLectures();

            if (response && Object.keys(response).length) {
                setLectures(filterLectures(response));
            }
        }

        if (selectedLectures.length) {
            setTargetKeys(selectedLectures);
        }

        loadLectures();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible])

    const handleOnConfirm = () => {
        onOk(targetKeys)
    }

    return (
        <Modal
            width={'80%'}
            open={visible}
            isLoading={lectureLoading}
            onCancel={() => onCancel(false)}
            title={intl.formatMessage({id: 'general.lectures.add_lecture'})}
            okText={intl.formatMessage({id: 'general.submit'})}
            cancelText={intl.formatMessage({id: 'general.cancel'})}
            onOk={handleOnConfirm}
            destroyOnClose
        >
            <Transfer className='lectures-modal-transfer'
                      locale={{notFoundContent: intl.formatMessage({id: "general.found_no_data"})}}
                      dataSource={lectures}
                      showSearch
                      showSelectAll={true}
                      targetKeys={targetKeys}
                      onChange={(keys: any) => setTargetKeys(keys)}
                      render={(item: TransferRecordType) => item.title}
                      oneWay={oneWay}
            />
        </Modal>
    )
};
export default LectureTransferModal;
