import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Form } from 'antd';
import { Modal } from 'ui';
import { Select, Input } from 'components/Form';
import Spinner from 'components/Spinner';
import ColorPicker from 'components/ColorPicker';
import { useResource } from 'hooks/Resources/useResource';
import { ResourceType } from '../types';

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

interface ResourceCreationModaltInterface {
    visible?: boolean | undefined;
    onCancel(cancel: boolean): any;
    onSubmit(): any;
    resourceData?: any;
    parentOptions?: any;
}

const ResourceCreationModal: React.FC<ResourceCreationModaltInterface> = ({ resourceData, parentOptions, visible = false, onCancel, onSubmit }) => {
    const [selectedGroup, setSelectedGroup] = useState<number>();
    const intl = useIntl();
    const [form] = Form.useForm();
    const { saveResource, resourceLoading } = useResource();

    useEffect(() => {
        if (resourceData && resourceData.classifier) {
            form.setFieldsValue({
                parentId: resourceData.classifier.parentId ? resourceData.classifier.parentId.toString() : undefined,
                title: resourceData.classifier.title,
                resourceType: resourceData.classifier ? resourceData.classifier.resourceType ?? "null" : undefined,
                data: resourceData.classifier ? resourceData.classifier.data ?? undefined : undefined
            });

            if (resourceData.classifier.parentId) {
                setSelectedGroup(+resourceData.classifier.parentId);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resourceData])

    const handleOnCancel = () => {
        form.resetFields();
        setSelectedGroup(undefined);
        onCancel(false)
    }

    const handleFormSubmit = async (values: any) => {
        const response = await saveResource(resourceData?.classifier?.id ? {
            id: resourceData.classifier.id,
            ...values
        } : values);

        if (!!response) {
            setSelectedGroup(undefined);
            onSubmit();
        }
    };

    const resourceTypeOptions = {
        [ResourceType.Device]: intl.formatMessage({ id: 'system.resource.type.DEVICE' }),
        [ResourceType.Location]: intl.formatMessage({ id: 'system.resource.type.LOCATION' }),
        [ResourceType.License]: intl.formatMessage({ id: 'system.resource.type.LICENSE' }),
        [ResourceType.Other]: intl.formatMessage({ id: 'system.resource.type.other' }),
    };

    const groupOptions = () => {
        const result: any = {};

        parentOptions.forEach((option: { id: number, title: string }) => {
            result[option.id] = option.title
        });

        return result;
    }

    const isLicense = (): boolean => {
        if (selectedGroup && !!parentOptions.length) {
            const group = parentOptions.find((option: any) => option.id === selectedGroup);
            if (group && group.resourceType && group.resourceType === ResourceType.License) {
                return true
            }
        }

        return false;
    }

    return (<Modal destroyOnClose
        open={visible}
        confirmLoading={resourceLoading}
        onCancel={() => handleOnCancel()}
        title={resourceData && !!resourceData.classifier ? intl.formatMessage({ id: 'system.resource.edit' }) : intl.formatMessage({ id: 'system.resource.create_new' })}
        okText={resourceData && !!resourceData.classifier ? intl.formatMessage({ id: 'general.save' }) : intl.formatMessage({ id: 'general.create' })}
        cancelText={intl.formatMessage({ id: 'general.cancel' })}
        onOk={form.submit}
    >
        <Spinner spinning={resourceLoading}>
            <Form form={form} onFinish={handleFormSubmit} {...formItemLayout} preserve={false}>
                {resourceData && resourceData.isGroup && <Select
                    showArrow
                    name='resourceType'
                    label={intl.formatMessage({ id: 'system.resource.type' })}
                    manualOptions={resourceTypeOptions}
                    customRules={[{ required: true, message: intl.formatMessage({ id: "validation.field_required" }) }]}
                    customLayout={true}
                />}
                <Input
                    name='title'
                    label={intl.formatMessage({ id: 'general.title' })}
                    customRules={[{ required: !resourceData?.isGroup, message: intl.formatMessage({ id: 'validation.field_required' }) }]}
                    customLayout={true} {...formItemLayout}
                />
                {resourceData && !resourceData.isGroup && <Select
                    showArrow
                    name='parentId'
                    label={intl.formatMessage({ id: 'system.group' })}
                    manualOptions={groupOptions()}
                    customRules={[{ required: true, message: intl.formatMessage({ id: "validation.field_required" }) }]}
                    customLayout={true}
                    onChange={(value: string) => setSelectedGroup(+value)}
                />}
                {resourceData && !resourceData.isGroup && <ColorPicker
                    initialColor={resourceData && resourceData.classifier && resourceData.classifier.color ? resourceData.classifier.color : '#19A9CE'}
                    form={form}
                    name="color"
                    label={intl.formatMessage({ id: "system.resource.color" })}
                />}
                {isLicense() && <>
                    <Input
                        name={['data', 'id']}
                        label={intl.formatMessage({ id: 'general.lectures.client_id' })}
                        customLayout={true} {...formItemLayout}
                    />
                    <Input
                        name={['data', 'secret']}
                        label={intl.formatMessage({ id: 'general.lectures.client_secret' })}
                        customLayout={true} {...formItemLayout}
                    />
                  <Input
                    name={['data', 'account']}
                    label={intl.formatMessage({ id: 'general.lectures.account_id' })}
                    customLayout={true} {...formItemLayout}
                  />
                </>}
            </Form>
        </Spinner>
    </Modal>
    )
};
export default ResourceCreationModal;
