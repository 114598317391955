import React, {useState, useEffect} from 'react';
import DefaultLayout from 'components/DefaultLayout';
import Spinner from "components/Spinner";
import {Tabs} from "antd";
import {useParams} from "react-router-dom";
import {useIntl} from "react-intl";
import coursesApiClient from "utils/coursesApiClient";
import useHandleError from "utils/useHandleError";
import UpcomingLectureSessions from "./UpcomingLectureSessions";
import {LectureProps} from "components/Lectures/types";
import {connect} from "react-redux";
import generalHelpers from "../../../utils/generalHelpers";

const mapStateToProps = (state: any) => {
    return {
        session: state.session
    }
};

const StudentLecture: React.FC = ({session}: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const [programTitle, setProgramTitle] = useState<any>();
    const [lecture, setLecture] = useState<LectureProps>({});

    const params = useParams();
    const [handleError] = useHandleError();
    const intl = useIntl();

    useEffect(() => {
        loadSessions()
    }, [])
    const loadSessions = async () => {
        setIsLoading(true);
        try {
            const response = await coursesApiClient.request(`/api/v1/courses/student/learning-program/${params.lectureId}/sessions`, [], 'GET');

            if (response?.sessions[0]) {
                setProgramTitle(response.sessions[0].sessions[0].title)
            }

            setLecture(response.sessions)
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false);
        }
    }

    const renderContent = () => {
        return <Tabs destroyInactiveTabPane
                     items={[
                         {
                             label: intl.formatMessage({id: 'general.lectures.upcoming_sessions'}),
                             key: 'upcoming-sessions',
                             children: <UpcomingLectureSessions reload={loadSessions} lecture={lecture}/>
                         },
                         // { TODO past lecture sessions
                         //     label: intl.formatMessage({id: 'general.lectures.past_sessions'}),
                         //     key: 'past-sessions',
                         //     children: <PastLectureSessions lecture={lecture} />,
                         // },
                     ]}
        />
    }

    return (
        <Spinner spinning={isLoading}>
            <DefaultLayout.PageLayout withoutPageLayout>
                <div className="program-list-wrapper program-list">
                    <DefaultLayout.PageHeader
                        noBottomMargin
                        title={programTitle}
                        breadcrumb={[{
                            name: intl.formatMessage({id: 'student.my_learning'}),
                            path: generalHelpers.isStudent(session.active_user_type) ? '/my-lectures' : `/materials/${params.id}`
                        }]}
                    />
                    {renderContent()}
                </div>
            </DefaultLayout.PageLayout>
        </Spinner>
    )
}

export default connect(mapStateToProps)(StudentLecture);