import React, {useState, useEffect} from 'react';
import {NavLink, useParams} from 'react-router-dom';
import {connect} from 'react-redux';
import {FormattedMessage, useIntl} from 'react-intl';
import {
    Form,
    Button,
    InputNumber,
    Tabs,
    Input as AntDInput,
    Alert,
    Space, message
} from 'antd';
import DefaultLayout from 'components/DefaultLayout';
import Spinner from 'components/Spinner';
import apiClient from 'utils/apiClient';
import {config} from 'config/config';
import {Input, RemoveConfirmSelect, Select} from "components/Form";
import Variables from "../Variables";
import LanguageRadioButtons from "components/LanguageRadioButtons";
import TemplateEditor from "components/TemplateEditor";
import './styles.scss';
import {useMail} from "../../../hooks/Mailing/useMail";

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale,
        campaignData: state.campaignData,
        session: state.session
    };
}

const formItemLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 12},
};

const TemplateForm: React.FC<any> = ({
                                         session,
                                         submitForm,
                                         form,
                                         isLoading,
                                         setMailableArray,
                                         mailableArray,
                                         supportedLanguages,
                                         setSupportedLanguages,
                                         template,
                                         htmlTemplate,
                                         setHtmlTemplate
                                     }) => {
    const {getCategoryMailable} = useMail();
    const [categories, setCategories] = useState([])
    const [mailable, setMailable] = useState<any>();
    const [selectLanguage, setSelectLanguage] = useState<string>('');
    const [languageList, setLanguageList] = useState({});
    const [editor, setEditor] = useState<any>();
    const [inputType, setInputType] = useState<'htmlTemplate' | 'smsTemplate'>('htmlTemplate')
    const [mailableOptions, setMailableOptions] = useState<any>([])

    const params = useParams();
    const templateId = params.id;
    const {TabPane} = Tabs;
    const {TextArea} = AntDInput;

    const intl = useIntl();

    useEffect(() => {
        loadLanguages()
        loadMailable()
    }, [])

    useEffect(() => {
        if (template) {
            const splitMailable: any = template.mailable.split('\\');
            setMailable(getMailableId(splitMailable[splitMailable.length - 1]));
            setSelectLanguage(template.language);

            form.setFieldsValue({
                mailable: getMailableId(splitMailable[splitMailable.length - 1]),
                language: template.language,
            })
        }
    }, [template])

    const getMailableId = (mailableString: any) => {
        if (mailableString) {
            return mailableOptions.indexOf(mailableString);
        }

        return -1;
    }

    const loadMailable = async () => {
        const response = await apiClient.request('/api/v1/mail-templates/options/standard-mailable', [], 'GET');
        setMailableOptions(response);
        setMailableArray(response)
    };

    const loadLanguages = async () => {
        let languages = await apiClient.request(config.api.routes.backend.languages, {}, 'GET');
        let languageListForSelect: any = {};
        Object.values(languages.languages).map((value: any) => {
            return languageListForSelect[value['code']] = value['name'];
        });
        setLanguageList(languageListForSelect);
    };

    const deleteLangugae = async (language: string) => {
        const newSupportedLanguages = supportedLanguages.filter((element: string) => {
            return element !== language
        });
        setSupportedLanguages(newSupportedLanguages);
    }

    const languageChange = (languageCode: string) => {
        if (languageCode === selectLanguage) {
            return;
        }

        if (!templateId) {
            message.info(intl.formatMessage({id: "emailing.save_template_info"}));
            return;
        }

        const values = form.getFieldsValue();
        submitForm(values, languageCode)

        setSelectLanguage(languageCode);
    }

    const getCategoryKey = () => {
        const categoryId = form.getFieldValue('categoryId')
        if (categoryId) {
            return categories[categoryId];
        }
        // category !== 'course_status' && mailable !== 'UserDefinedTemplateMail'
        return '';
    }

    const getMailable = () => {
        if (mailable && getCategoryKey() !== 'course_status') {
            return mailableOptions[mailable];
        }

        return '';
    }

    const handleChange = (languageArray: any) => {
        if (!supportedLanguages.length) {
            form.setFieldsValue({
                language: languageArray[languageArray.length - 1]
            })
        }
    }
    const handleCategoryChange = (categoryId: number) => {
        setMailable(undefined)
        form.setFieldsValue({mailable: null})
        setMailableOptions(getCategoryMailable(categories, categoryId, mailableArray))
    }

    return (
        <DefaultLayout.PageLayout withStickyFooter>
            <DefaultLayout.PageHeader
                breadcrumb={[{
                    name: intl.formatMessage({id: 'emailing.templates.manage_templates'}),
                    path: '/e-mailing/manage-templates'
                }]}
                title={intl.formatMessage({id: templateId ? 'emailing.templates.edit_template' : 'emailing.templates.add_template'})}
            />
            <Spinner spinning={isLoading} opaque={isLoading}>
                <DefaultLayout.PageContent withTopPadding>
                    <Form form={form} onFinish={submitForm} {...formItemLayout}>
                        <Input customLayout={true} name="title"
                               label={intl.formatMessage({id: "emailing.templates.template_name"})}
                               validation={{required: true}}/>
                        <Select
                            name='categoryId'
                            url='/api/v1/mail-templates/options/categories'
                            customLayout={formItemLayout}
                            label={intl.formatMessage({id: "general.category"})}
                            integerKey={true}
                            customRules={[{
                                required: true,
                                message: intl.formatMessage({id: "validation.field_required"})
                            }]}
                            customObjLabel={(label: string) => intl.formatMessage({id: `email_templates.categories.${label}`})}
                            saveLoadedData={true}
                            onChange={(el: number) => handleCategoryChange(el)}
                            setLoadedData={setCategories}
                        />
                          <Select
                            name='mailable'
                            customLayout={formItemLayout}
                            manualOptions={mailableOptions}
                            label={intl.formatMessage({id: "emailing.templates.mailable"})}
                            labelKey={true}
                            customRules={[{
                                required: true,
                                message: intl.formatMessage({id: "validation.field_required"})
                            }]}
                            customObjLabel={(label: string) => intl.formatMessage({id: `email_templates.mailables.${label}`})}
                            onChange={(id: any) => setMailable(id)}
                          />
                        <Input customLayout={true} name="from" label={intl.formatMessage({id: "general.from"})}
                               disabled={!session.access.appearance.allow}/>
                        <Input customLayout={true} name="subject"
                               label={intl.formatMessage({id: "emailing.templates.subject"})}
                               validation={{required: true}}/>
                        <RemoveConfirmSelect
                            name='supportedLanguages'
                            label={intl.formatMessage({id: "emailing.supported_languages"})}
                            valueKey='value'
                            labelKey='label'
                            customLayout={true}
                            manualOptions={languageList}
                            customRules={[{
                                required: true,
                                message: intl.formatMessage({id: "validation.field_required_for_language"})
                            }]}
                            selectedOptions={supportedLanguages}
                            setSelectedOptions={setSupportedLanguages}
                            onChange={(el: any) => handleChange(el)}
                            removeFunction={deleteLangugae}
                            confirmModalOK={intl.formatMessage({id: "general.delete"})}
                            confirmModalTitle={intl.formatMessage({id: "emailing.delete_this_template_language"})}
                            confirmModalCancel={intl.formatMessage({id: "general.cancel"})}
                        />
                        <LanguageRadioButtons
                            languages={supportedLanguages}
                            onClickFunction={languageChange}
                        />

                        <Variables editor={editor} mailable={getMailable()} form={form} inputType={inputType}/>
                        <Form.Item
                            label='‎'
                            className='form-alert noColon'

                        >
                            <Alert message={intl.formatMessage({id: 'emailing.variable_info_message'})} type="info"/>
                        </Form.Item>
                        <Tabs className='tab-row' onChange={(tabKey: any) => setInputType(tabKey)}>
                            <TabPane tab={intl.formatMessage({id: "emailing.templates.html_template"})}
                                     key="htmlTemplate">
                                <TemplateEditor
                                    html={htmlTemplate}
                                    setHtml={setHtmlTemplate}
                                    setEditor={setEditor}
                                />
                            </TabPane>
                            <TabPane tab={intl.formatMessage({id: "emailing.templates.sms_template"})}
                                     key="smsTemplate">
                                <Form.Item className='sms-text-area' name='smsTemplate'
                                           label={intl.formatMessage({id: "emailing.templates.html_template"})}>
                                    <TextArea showCount maxLength={160} style={{height: 160}}/>
                                </Form.Item>
                            </TabPane>
                        </Tabs>
                    </Form>
                </DefaultLayout.PageContent>
            </Spinner>
            <DefaultLayout.PageFooter
                right={
                    <Space size={10}>
                        <NavLink to="/e-mailing/manage-templates">
                            <Button>
                                <FormattedMessage id="general.back"/>
                            </Button>
                        </NavLink>
                        <Button type="primary" htmlType="submit" loading={isLoading}
                                onClick={() => form.submit()}>
                            <FormattedMessage id="general.submit"/>
                        </Button>
                    </Space>
                }
            />
        </DefaultLayout.PageLayout>
    )
}

export default connect(mapStateToProps)(TemplateForm);
