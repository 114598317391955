import React from 'react';
import {connect} from 'react-redux';
import {useIntl} from 'react-intl';
import {Form} from 'antd';
import {config} from 'config/config';
import {Editor} from '@tinymce/tinymce-react';

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale,
        campaignData: state.campaignData,
        session: state.session
    };
}

const VARIABLE_NODE_CLASS_NAME = 'email_template.variable';
interface TemplateEditorInterface {
    layout?: any;
    label?: string;
    height?: number;
    name?: string;
    html: string;
    setHtml: React.Dispatch<React.SetStateAction<string>>;
    setEditor: React.Dispatch<React.SetStateAction<any>>;
}

const TemplateEditor: React.FC<TemplateEditorInterface> = ({layout, label, name, height, html, setHtml, setEditor}) => {
    const intl = useIntl();

    let formItemLayout = layout || {}

    return (
        <Form.Item {...formItemLayout} name={name ? name: null} label={label ?? intl.formatMessage({id: "emailing.templates.html_template"})}>
            <Editor
                apiKey={config.api.tinymceApiKey}
                value={html}
                onEditorChange={(el: any) => setHtml(el)}
                init={{
                    mode: 'exact',
                    setup: function (editor: any) {
                        editor.on('init', function () {
                            if (html) {
                                editor.setContent(html);
                            }
                            if (setEditor) {
                                setEditor(editor);
                            }
                        });
                    },

                    height: height ?? 500,
                    relative_urls: false,
                    remove_script_host: false,
                    menubar: false,
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar: 'undo redo | formatselect | ' +
                        'bold italic backcolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',

                }}
                onKeyDown={(event, editor) => {
                    if (event.code === 'Backspace') {
                        let currentNode: any = editor.selection.getNode();
                        const text = currentNode.innerText.replaceAll(/\s/g,'')

                        if (currentNode.className === VARIABLE_NODE_CLASS_NAME && text[text.length - 1] === '}' && text[text.length - 2] === '}') {
                            currentNode.remove();
                        }
                    }
                    if (event.code === 'Delete') {
                        let currentNode = editor.selection.getNode();

                        if (currentNode.className === VARIABLE_NODE_CLASS_NAME) {
                            currentNode.remove();
                        }
                    }
                }}
            />
        </Form.Item>
    )
}

export default connect(mapStateToProps)(TemplateEditor);
