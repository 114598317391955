import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Form } from 'antd';
import { Modal } from 'ui';
import { Input } from 'components/Form';
import { SessionProps } from '../../types';
import { useSession } from 'hooks/Lectures/useSession';
import TemplateEditor from 'components/TemplateEditor';


const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

interface SessionEditModalInterface {
    editDetails: string[],
    session: SessionProps,
    visible?: boolean | undefined,
    onCancel(): any,
    onSubmit(): any,
}

const SessionEditModal: React.FC<SessionEditModalInterface> = ({ editDetails, session, visible = false, onCancel, onSubmit }) => {
    const [description, setDescription] = useState<string>();
    const [editor, setEditor] = useState<any>();
    const { saveSession, sessionLoading } = useSession();
    const intl = useIntl();
    const [form] = Form.useForm();

    const editField = editDetails[0] === 'link' ? 'link' : 'description'

    useEffect(() => {
        if (editField === 'link') {
            form.setFieldValue('link', session.link);
        }

        if (editField === 'description') {
            setDescription(session.description);
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleOnCancel = () => {
        form.resetFields();
        onCancel()
    }

    const handleFormSubmit = async (values: any) => {
        const data = editField === 'link' ? {
            link: values.link
        } : {
            description: description
        }

        if (await saveSession(session.lecturesId, session.id, data)) {
            form.resetFields();
            onSubmit();
        }
    };

    return (
        <Modal destroyOnClose
            open={visible}
            isLoading={sessionLoading}
            onCancel={() => handleOnCancel()}
            title={editField === 'link' ? intl.formatMessage({ id: 'general.lectures.edit_session_details' }) : intl.formatMessage({ id: 'general.lectures.edit_session_description' })}
            okText={intl.formatMessage({ id: 'general.submit' })}
            cancelText={intl.formatMessage({ id: 'general.cancel' })}
            onOk={form.submit}
        >
            <Form form={form} onFinish={handleFormSubmit} {...formItemLayout}>
                {editField === 'link' && <Input
                    name='link'
                    label={intl.formatMessage({ id: 'general.lectures.meeting_link' })}
                    customLayout={true} {...formItemLayout}
                />}
                {editField === 'description' && typeof description !== 'undefined' && <TemplateEditor height={200}
                    label={intl.formatMessage({ id: 'general.description' })}
                    html={description}
                    setHtml={(value: any) => setDescription(value ?? '')}
                    setEditor={setEditor}
                />}
            </Form>
        </Modal>
    )
};
export default SessionEditModal;
