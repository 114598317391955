import React, {useState} from 'react';
import {Modal} from 'antd';
import {FormattedMessage} from 'react-intl';
import coursesApiClient from 'utils/coursesApiClient';
import Button from 'ui/Button';
import moment from 'moment';
import {ProgramDataInterface} from "types/Student";
import useHandleError from "utils/useHandleError";
import './styles.scss';

interface SignatureModalInterface {
    visible: boolean;
    onCancel: any;
    program: ProgramDataInterface;
    reload: Function;
}

const SignatureModal: React.FC<SignatureModalInterface> = ({visible, onCancel, program, reload}) => {
    const [signatureIsLoading, setSignatureIsLoading] = useState(false);

    const [handleError] = useHandleError();

    const submit = async () => {
        setSignatureIsLoading(true)

        try {
            await coursesApiClient.request(`/api/v1/courses/student/${program.attemptId}/sign`, [], 'POST');
            reload();
        } catch (error) {
            handleError(error)
        } finally {
            onCancel();
            setSignatureIsLoading(false)
        }
    }

    const getVisible = () => {
        if (!program.readyToSign && !program.signed) {
            return false
        }

        return visible
    }

    return (
        <Modal
            className='signature-modal'
            open={getVisible()}
            maskClosable={!!program.signed}
            onCancel={() => onCancel(false)}
            title={<span>
                <span style={{fontSize: 25}}>
                    <i style={{fontWeight: 600, color: '#C1E6FF'}} className='fal fa-user-edit'></i>
                </span>
                <FormattedMessage id='student.signature'/>
            </span>}
            footer={
                !program.signed ?
                    <span>
                        {!program.hasJournal.managerSign ?
                            < Button type='primary' loading={signatureIsLoading} onClick={() => submit()}>
                                <FormattedMessage id='general.sign'/>
                            </Button>
                            :
                            null
                        }
                    </span>
                    : <span>
                        <i style={{color: '#D9D9D9'}} className="fal fa-user-edit"></i>
                        {moment(program.signed).format('HH:mm DD.MM.YYYY')}
                    </span>
            }
        >
            <div>
                {program.hasJournal.description}
            </div>
        </Modal>
    );
}

export default SignatureModal;
