import React from 'react';
import {FormattedMessage} from "react-intl";
import './styles.scss'

interface TitleInterface {
    title: string;
    className?: string;
    withLine?: boolean;
}


const Title: React.FC<TitleInterface> = ({withLine, title, className = ''}) => {

    if (withLine) {
        return (
            <div className={`${className} title-with-line`}>
                <span><FormattedMessage id={title}/></span>
                {withLine && <div />}
            </div>
        );
    }

    return (
        <div className={className}>
            <FormattedMessage id={title}/>
        </div>
    );
};

export default Title;