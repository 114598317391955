import React, {useState, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {connect} from 'react-redux';
import debounce from "lodash/debounce";
import Switch from 'components/Form/Switch';
import Spinner from "components/Spinner";
import coursesApiClient from "utils/coursesApiClient";
import useHandleError from "utils/useHandleError";
import QuestionAnswersList from "./QuestionAnswersList";
import ThumbUploader from "./ThumbUploader";
import './styles.scss';

import { message, Button, Row, Col, Form, Tooltip } from 'antd';
import {TextArea, Input, Checkbox, FileInput} from 'components/Form';

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale,
        session: state.session
    };
};

interface QuestionEditViewInterface {
    locale?: any;
    session?: any;
    questionId: number;
    saveQuestion: Function;
    isParentLoading: boolean;
    isSaveQuestionLoading: boolean;
    questionTypes: any;
    showVersionListPopup: Function;
    setHasFormChanged: React.Dispatch<React.SetStateAction<boolean>>;
    saveEvent: boolean;
    setSaveEvent: React.Dispatch<React.SetStateAction<boolean>>;
    isVersionView: boolean;
    setCurrentQuestionAnswers?: any;
    questionIdPrefix: string|null
}

const QuestionEditView: React.FC<QuestionEditViewInterface> = ({
    locale,
    session,
    questionId,
    saveQuestion,
    isParentLoading,
    isSaveQuestionLoading,
    setHasFormChanged,
    setCurrentQuestionAnswers,
    saveEvent,
    setSaveEvent,
    questionTypes,
    showVersionListPopup,
    isVersionView,
    questionIdPrefix
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [question, setQuestion] = useState<any>(null);
    const [answers, setAnswers] = useState<any>(null);
    const [questionType, setQuestionType] = useState<any>(null);
    const [thumbnailId, setThumbnailId] = useState(0);
    const [isQuestionFieldUpdated, setIsQuestionFieldUpdated] = useState<boolean>(false);
    const [showVideoField, setShowVideoField] = useState<boolean>(false);
    const [questionPointsDisabled, setQuestionPointsDisabled] = useState<boolean>(false);

    const intl = useIntl();
    const [handleError] = useHandleError();
    const [form] = Form.useForm();

    useEffect(() => {
        if( questionId ) {
            loadQuestion()
        }
    }, [questionId])

    useEffect(() => {
        if (setCurrentQuestionAnswers) {
            setCurrentQuestionAnswers(answers)
        }
    }, [answers])


    useEffect(() => {
        if(question) {
            form.setFieldsValue(question)
            setShowVideoField(question.video || false)
            if(question?.type == 'RMC') {
                setQuestionPointsDisabled(true)
            } else {
                setQuestionPointsDisabled(false)
            }
        }
    }, [question])

    useEffect(() => {
        form.setFieldsValue({
            answers: answers
        })
        if(question?.type === 'RMC') {
            let questionPoints: number = 0;
            answers.map((answer: any) => {
                if(parseFloat(answer.score) > 0) {
                    questionPoints += parseFloat(answer.score);
                }
            })
            form.setFieldsValue({
                score: questionPoints
            })
        }
    }, [answers])

    useEffect(() => {
        setIsLoading(isParentLoading)
    }, [isParentLoading])

    useEffect(() => {
        if (saveEvent) {
            form.submit();
            setSaveEvent(false)
        }
    }, [saveEvent])

    const loadQuestion = async () => {
        setIsLoading(true);
        setIsQuestionFieldUpdated(false)
        setQuestion(null)

        try {
            const response = await coursesApiClient.request(`/api/v1/courses/question-pools/questions/${questionId}`, [], 'GET');
            await loadAnswers()

            setQuestionType(questionTypes[response.question.type]);
            setThumbnailId(response.question.thumbnail ? parseInt(response.question.thumbnail) : 0)
            setQuestion(response.question)
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false);
        }
    }

    const loadAnswers = async () => {
        setIsLoading(true);
        try {
            const response = await coursesApiClient.request(`/api/v1/courses/question-pools/questions/${questionId}/answers`, [], 'GET');
            setAnswers(response.answers)
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false);
        }
    }

    const updateQuestion = async (values: any, callback: Function = () => {
        loadQuestion()
    }) => {
        const parsedValues = {...question, ...values};

        // Validate required answer
        if(questionType.isAnswerRequired) {
            const addedAnswers = answers.length > 0 ? answers.filter((item: any) => {return item.title || item.thumbnail}) : [];
            if(!addedAnswers.length) {
                message.error(intl.formatMessage({id: 'question_pools.add_answer_error'}))
                return;
            }
        }

        // Validate correct answer selected
        if(questionType.isCorrectAnswerRequired && answers.length > 0) {
            const correctAnswers = answers.filter((item: any) => {return item.isCorrect})
            if(!correctAnswers.length) {
                message.error(intl.formatMessage({id: 'question_pools.choose_correct_answer_error'}))
                return;
            }
        }

        const updatedKeys = Object.keys(question).filter( key => question[key] !== parsedValues[key] )
        if( updatedKeys && updatedKeys.length > 0 ) {
            parsedValues.versionNote = updatedKeys.join();
        }

        setIsQuestionFieldUpdated(false)
        setQuestion(parsedValues)
        saveQuestion(parsedValues, answers, callback);
    };

    const submitForm = (values: any, callback: Function = () => {
        loadQuestion()
    }) => {
        setIsQuestionFieldUpdated(false)
        updateQuestion(values, callback)
    };

    const onChangeQuestionField = (deleteAnswer: boolean = false, list: any = []) => {

        if (question.type === 'MA' && deleteAnswer && list.length > 0) {
            setIsQuestionFieldUpdated(true)
        } else if (question.type === 'MA' && !deleteAnswer) {
            if (answers[0].title && answers[0].description) {
                setIsQuestionFieldUpdated(true)
            }
        } else if (question.type !== 'MA') {
            setIsQuestionFieldUpdated(true)
        }

        if (question.type === 'MA' && deleteAnswer && list.length == 0) {
            setIsQuestionFieldUpdated(false)
        }
    };

    const getQuestionIdKey = (id: number) => {
        if(questionIdPrefix) {
            return `${intl.formatMessage({id: 'general.id'})}-${questionIdPrefix}-${id}`;
        } else {
            return `${intl.formatMessage({id: 'general.id'})}${id}`
        }
    };

    return (
        <Spinner spinning={isLoading || isParentLoading || isSaveQuestionLoading}>
            {question && <Form form={form} onFinish={submitForm} disabled={isVersionView} onValuesChange={() => setHasFormChanged(true)}>
                <div className={`panel question-panel question-${question.type}`}>
                    {question.thumbInfo && <img src={question.thumbInfo.fileData} />}
                    <div className="panel-header">
                        <div className='panel-header-left'>
                            <div className='panel-header-item'>
                                <label htmlFor="score"><FormattedMessage id='question_pools.score' /></label>
                                <Input
                                    name="score"
                                    type="number"
                                    min="0"
                                    validation={{required: false}}
                                    customLayout={{labelCol: {span: 0}, wrapperCol: {span: 24}}}
                                    style={{marginBottom: '0px', width: '70px'}}
                                    onChange={() => onChangeQuestionField()}
                                    disabled={questionPointsDisabled}
                                />
                            </div>
                            {questionType.hasToPassScoreField && <div className='panel-header-item'>
                                <label htmlFor="toPassScore"><FormattedMessage id='question_pools.to_pass_score' /></label>
                                <Input
                                    name="toPassScore"
                                    type="number"
                                    min="0"
                                    validation={{required: false}}
                                    customLayout={{labelCol: {span: 0}, wrapperCol: {span: 24}}}
                                    style={{marginBottom: '0px', width: '70px'}}
                                    onChange={() => onChangeQuestionField()}
                                />
                            </div>}
                            {questionType.hasShuffleAnswersField && <div className='panel-header-item'>
                                <label><FormattedMessage id='question_pools.shuffle_answers' /></label>
                                <Switch
                                    key={`question-shuffle-switch-${question.id}`}
                                    onChange={() => {
                                        question.shuffleAnswers = !question.shuffleAnswers;
                                        form.setFieldsValue({
                                            shuffleAnswers: question.shuffleAnswers
                                        })
                                        onChangeQuestionField()
                                    }}
                                    defaultChecked={question.shuffleAnswers}
                                />
                            </div>}
                            {questionType.hasCriticalField && <div className='panel-header-item'>
                                <label htmlFor="isCritical"><FormattedMessage id='question_pools.is_critical' /></label>
                                <Form.Item
                                    name="isCritical"
                                    style={{marginBottom: '0px', justifyContent: 'center'}}
                                    valuePropName="checked"
                                    >
                                    <Checkbox onChange={() => onChangeQuestionField()} />
                                </Form.Item>
                            </div>}
                        </div>
                        <div className='panel-header-right'>
                            <div className='panel-header-item separator' style={{marginLeft: 'auto'}}>&nbsp;</div>
                            <div className='panel-header-item'>
                                <span style={{textTransform: 'uppercase'}}>{getQuestionIdKey(question.id)}</span>
                            </div>
                            <div className='panel-header-item'>
                                <a onClick={() => showVersionListPopup(true)}><FormattedMessage id='question_pools.version_history' /></a>
                            </div>
                        </div>
                    </div>
                    <div className="panel-body">
                        <div className='question-title'>
                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'start'}}>
                                <h3>{intl.formatMessage({id: questionType.title})}</h3>
                                {questionType.hasQuestionVideoField && <div>
                                    <Button type='link' onClick={() => setShowVideoField(true)}><FormattedMessage id='question_pools.add_video' /></Button>
                                </div>}
                            </div>
                            <Row gutter={[10, 0]} align='middle'>
                                <Col span={24} style={{display: 'flex', alignItems: 'center', gridGap: '10px'}}>
                                    <TextArea
                                        name="title"
                                        rows={2}
                                        label=''
                                        validation={{required: true}}
                                        customLayout={{labelCol: {span: 0}, wrapperCol: {span: 24}}}
                                        style={{marginBottom: '0px', width: '100%'}}
                                        onChange={() => onChangeQuestionField()}
                                    />
                                    {questionType.hasQuestionThumbField &&
                                        <ThumbUploader
                                            fieldName="thumbnail"
                                            fileInfo={question?.storageFiles?.thumbnail}
                                            onChangeCallback={(values: any) => {
                                                form.setFieldsValue({
                                                    ...values
                                                })
                                                onChangeQuestionField()
                                            }}
                                        />
                                    }
                                </Col>
                            </Row>
                            {questionType.hasQuestionVideoField && showVideoField && <Row gutter={[10, 0]} align='middle' style={{marginTop: '5px'}}>
                                <Col span={24}>
                                    <Input
                                        name="video"
                                        type="text"
                                        label={
                                            <span>
                                                {intl.formatMessage({id: 'question_pools.video_url'})}
                                                <Tooltip overlayClassName='instructions-tooltip' title={
                                                    <span>
                                                        <FormattedMessage id='system.video_instruction_url_hint'/>
                                                        <br/>
                                                        1) https://youtu.be/...
                                                        <br/>
                                                        2) https://www.youtube.com/watch?v=...
                                                        <br/>
                                                        3) https://www.youtube.com/embed/...
                                                    </span>
                                                }>
                                                    <i className='fal fa-question-circle header-item'/>
                                                </Tooltip>
                                            </span>
                                        }
                                        validation={{required: false}}
                                        customLayout={{labelCol: {span: 24}, wrapperCol: {span: 24}}}
                                        onChange={() => onChangeQuestionField()}
                                        style={{marginBottom: '0px'}}

                                    />
                                </Col>
                            </Row>}
                        </div>
                        <div className='question-answers'>
                            <QuestionAnswersList
                                question={question}
                                form={form}
                                submitForm={submitForm}
                                loadQuestion={loadQuestion}
                                answers={answers}
                                setAnswers={setAnswers}
                                onChangeQuestionField={onChangeQuestionField}
                                isQuestionFieldUpdated={isQuestionFieldUpdated}
                                isSaveQuestionLoading={isSaveQuestionLoading || isLoading}
                                questionTypes={questionTypes}
                                isVersionView={isVersionView}
                            />
                        </div>
                        {!isVersionView && <div className='question-save-buttons'>
                            <Button type='ghost' onClick={() => loadQuestion()} disabled={!isQuestionFieldUpdated} >
                                <FormattedMessage id='general.cancel' />
                            </Button>
                            <Button type='primary' onClick={form.submit} disabled={!isQuestionFieldUpdated}>
                                <FormattedMessage id='general.submit' />
                            </Button>
                        </div>}
                    </div>
                </div>
            </Form>}
        </Spinner>
    );
};

export default connect(mapStateToProps)(QuestionEditView);
    