import React, { useState, useEffect } from 'react';
import { Button, Collapse, Modal as AntdModal, Table } from 'antd';
import { Modal } from 'ui';
import { FormattedMessage, useIntl } from 'react-intl';
import coursesApiClient from 'utils/coursesApiClient';
import useHandleError from 'utils/useHandleError';
import ResultTable from './ResultTable';
import Spinner from '../../Spinner';
import useQueryApiClient from 'utils/useApiClient';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import generalHelpers from 'utils/generalHelpers';
import { connect } from 'react-redux';

interface ResultsModalInterface {
    open: boolean;
    onCancel: React.Dispatch<React.SetStateAction<boolean>>;
    programId: number;
    activity: {
        enrollmentId: number;
        activityId: number;
        fullName: string;
        title: string;
        type: string;
    };
    session?: any;
}

const mapStateToProps = (state: any) => {
    return {
        session: state.session,
    };
};

const ResultModal: React.FC<ResultsModalInterface> = ({
    open,
    onCancel,
    activity,
    programId,
    session,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [activityData, setActivityData] = useState();
    const { Panel } = Collapse;
    const [handleError] = useHandleError();
    const intl = useIntl();
    const { confirm } = AntdModal;

    useEffect(() => {
        if (activity.activityId !== 0) {
            loadActivity();
        }
    }, [activity]);

    const loadActivity = async () => {
        setIsLoading(true);
        try {
            const response = await coursesApiClient.request(
                `/api/v1/courses/learning-program/options/enrollment/${activity.enrollmentId}/results/${activity.activityId}`,
                [],
                'GET'
            );

            setActivityData(response.attempt);
        } catch (error) {
            handleError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const { appendData: cancelDeletion, isLoading: isCancelLoading } = useQueryApiClient({
        request: {
            disableOnMount: true,
            url: `/api/v1/courses/learning-program/${programId}/attempt/:id/cancel`,
            method: 'POST',
        },
        onSuccess: () => {
            loadActivity();
        },
    });

    const { appendData: initDeletion, isLoading: isInitLoading } = useQueryApiClient({
        request: {
            disableOnMount: true,
            url: `/api/v1/courses/learning-program/${programId}/attempt/:id/init`,
            method: 'POST',
        },
        onSuccess: () => {
            loadActivity();
        },
    });

    const genExtra = (attempt: any) => {
        if (!generalHelpers.hasSpecialPermissions(session, 'remove_attempt')) {
            return <div></div>;
        }

        if (!!attempt[1].deletionInitiated) {
            return (
                <Button
                    loading={isCancelLoading}
                    onClick={(event) => {
                        event.stopPropagation();
                        cancelDeletion({}, { id: attempt[0] });
                    }}
                >
                    <FormattedMessage id="program.cancel_deletion" />
                </Button>
            );
        } else {
            return (
                <Button
                    loading={isInitLoading}
                    onClick={(event) => {
                        event.stopPropagation();
                        warnDeletion({}, { id: attempt[0] }, { id: attempt[0] });
                    }}
                >
                    <FormattedMessage id="program.init_deletion" />
                </Button>
            );
        }
    };

    const warnModalData = (data: any) => {
        console.log(data);

        return (
            <div>
                {intl.formatMessage({ id: 'program.delete_attempt_warning' })}
                <table>
                    <tbody>
                        <tr className='p-2'>
                            <td>{intl.formatMessage({ id: 'program.activity_data' })}: </td>
                            <td>
                                <strong>{data.activity}</strong>
                            </td>
                        </tr>
                        <tr className='p-2'>
                            <td>{intl.formatMessage({ id: 'program.attempt_data' })}: </td>
                            <td>
                                <strong>{data.attempt}</strong>
                            </td>
                        </tr>
                        <tr className='p-2'>
                            <td>{intl.formatMessage({ id: 'program.certificate_data' })}: </td>
                            <td>
                                <strong>{data.certificate}</strong>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    const { appendData: warnDeletion } = useQueryApiClient({
        request: {
            disableOnMount: true,
            url: `/api/v1/courses/learning-program/${programId}/attempt/:id/data`,
            method: 'GET',
        },
        onSuccess: (response, passOnSuccess) => {
            confirm({
                title: intl.formatMessage({ id: 'general.attention' }),
                content: warnModalData(response.data),
                icon: <ExclamationCircleOutlined />,
                okText: intl.formatMessage({ id: 'general.yes' }),
                cancelText: intl.formatMessage({ id: 'general.no' }),

                onOk() {
                    initDeletion({}, { id: passOnSuccess.id });
                },
            });

            loadActivity();
        },
    });

    return (
        <Modal
            open={open}
            width={'80%'}
            onCancel={() => onCancel(false)}
            className="add_remove_activity"
            title={activity.title}
            destroyOnClose={true}
            footer={
                <Button onClick={() => onCancel(false)}>
                    <FormattedMessage id="general.close" />
                </Button>
            }
        >
            <Spinner spinning={isLoading}>
                {activityData ? (
                    <Collapse>
                        {Object.entries(activityData).map((el: any) => {
                            return (
                                <Panel
                                    header={intl.formatMessage(
                                        { id: 'program.enrollment_date' },
                                        { date: el[1].date }
                                    )}
                                    extra={genExtra(el)}
                                    key={el[1].attempt}
                                >
                                    <div className={'program-student-table'}>
                                        <ResultTable
                                            data={el[1]}
                                            fullName={activity.fullName}
                                            isLecture={activity.type === 'LECTURE'}
                                        />
                                    </div>
                                </Panel>
                            );
                        })}
                    </Collapse>
                ) : null}
            </Spinner>
        </Modal>
    );
};

export default connect(mapStateToProps)(ResultModal);
