import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { message, Input, Button } from 'antd';
import Spinner from 'components/Spinner';
import { Stage, Layer, Image, Circle, Rect, Line } from 'react-konva';
import {debounce} from 'lodash';
import './styles.scss';

const HotspotImagePreview: React.FC<any> = ({ 
    imageUrl, 
    answers,
    selectedShapeId
}) => {
    const [isLoading, setIsLoading] = useState(false);

    // Shape data
    const [mainImage, setMainImage] = useState<HTMLImageElement>();
    const [canvasDimentions, setCanvasDimentions] = useState<any>({
        width: 500,
        height: 500
    });
    const [shapesData, setShapesData] = useState<any>([]);
    
    const intl = useIntl();
    const fullImageRef = React.useRef<any>();
    const resizedImageRef = React.useRef<any>();
    const canvasImageRef = React.useRef<any>();
    const canvasRef = React.useRef<any>();

    useEffect(() => {
        window.addEventListener('resize', () => {
            if(resizedImageRef.current) {
                resizeCanvas()
            }
        })
    }, [])

    useEffect(() => {
        if(imageUrl) {
            const baseImage = fullImageRef.current;
            baseImage.onload = function () {
                const image = new window.Image();
                image.src = imageUrl;
                image.onload = () => {
                    setMainImage(image)
                    setCanvasDimentions({
                        width: baseImage.width,
                        height: baseImage.height,
                    })
                }
            }
        }
    }, [imageUrl])

    useEffect(() => {
        // Check and delete invalid shapes
        const shapes = answers.filter((item: any) => {
            return item?.data && item?.data !== null;
        })
        
        setShapesData(shapes)
    }, [answers])

    const resizeCanvas = () => {
        const fullImage = fullImageRef.current;
        const resizedImage = resizedImageRef.current;
        const scale = resizedImage.width / fullImage.width;
        const width = fullImage.width * scale;
        const height = fullImage.height * scale;
        
        canvasRef.current.width(width);
        canvasRef.current.height(height);
        canvasRef.current.scale({ x: scale, y: scale });
    }

    const renderShape = (shape: any, index: number) => {
        switch (shape.data?.type) {
            case 'CIRCLE':
                return <Circle 
                    key={index}
                    x={shape.data.x} 
                    y={shape.data.y} 
                    radius={shape.data.radius} 
                    strokeWidth={2} 
                    stroke={`${selectedShapeId == index ? '#ffba32' : '#000000'}`}
                    fill="rgba(225,225,225,0.5)" 
                />
                break;
            case 'RECT':
                return <Rect 
                    key={index}
                    x={shape.data.x} 
                    y={shape.data.y} 
                    width={shape.data.width} 
                    height={shape.data.height} 
                    strokeWidth={2} 
                    stroke={`${selectedShapeId == index ? '#ffba32' : '#000000'}`}
                    fill="rgba(225,225,225,0.5)" 
                />
                break;

            case 'LINE': 
                return <Line 
                    key={index}
                    points={shape.data.points}
                    tension={0.5}
                    lineCap="round"
                    lineJoin="round"
                    globalCompositeOperation='source-over'
                    strokeWidth={2} 
                    stroke={`${selectedShapeId == index ? '#ffba32' : '#000000'}`}
                    fill="rgba(225,225,225,0.5)"
                    closed 
                />
                break;
        
            default:
                break;
        }
    }

    return (
        <Spinner spinning={isLoading}>
            <div className='hotspot-image'>
                <Stage
                    width={canvasDimentions.width}
                    height={canvasDimentions.height}
                    ref={canvasRef}
                >
                    <Layer>
                        <Image
                            x={0}
                            y={0}
                            image={mainImage}
                            ref={canvasImageRef}
                            width={canvasDimentions.width}
                            height={canvasDimentions.height}
                        />
                        {
                            shapesData.map((shape: any, i: number) => {
                                return renderShape(shape, i)
                            })
                        }
                    </Layer>
                </Stage>
                <img src={imageUrl} ref={fullImageRef} style={{
                    position: 'absolute', 
                    top: '0px', 
                    left: '0px',
                    opacity: 0,
                    visibility: 'hidden',
                    zIndex: '-1',
                    width: 710,
                    maxWidth: 'initial'
                }} />
                {mainImage && <img src={imageUrl} ref={resizedImageRef} onLoad={resizeCanvas} style={{
                    position: 'absolute', 
                    top: '0px', 
                    left: '0px',
                    opacity: 0,
                    visibility: 'hidden',
                    zIndex: '-1',
                    width: '100%',
                }} />}
            </div>
        </Spinner>
    )
}

export default HotspotImagePreview;
