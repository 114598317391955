import React, {useEffect} from "react";
import {useParams } from 'react-router-dom';
import Cookies from "js-cookie";

const SamlTokenRedirect: React.FC = ({}: any) => {
    const params = useParams();
    const token:any = params.token;

    useEffect(() => {
        if (token) {
            handleToken()
        }
    },[])

    const handleToken = () => {
        const expirationDate = new Date(new Date().getTime() + 86400 * 1000);
        Cookies.set('jwt', token, {expires: expirationDate});
        window.location.assign('/');
    }

    return (
        <>
        </>
    )
}

export default SamlTokenRedirect;
