import {useState} from 'react'
import useHandleError from 'utils/useHandleError';
import apiClient from 'utils/apiClient';
import {CustomerProps} from "pages/Customer/types";
import {config} from "config/config";

export const useCustomer = () => {
    const [handleError] = useHandleError();
    const [loading, setLoading] = useState<boolean>(false)

    const getCustomer = async (id: string): Promise<CustomerProps | undefined> => {
        setLoading(true);
        try {
            const response = await apiClient.request(`${config.api.routes.backend.customers}/${id}`, {}, 'GET');

            if ("error" in response) {
                throw response;
            }

            if ("data" in response) {
                return response.data;
            }

            return undefined;
        } catch (error) {
            handleError(error);
            return undefined;
        } finally {
            setLoading(false);
        }
    }

    const getCustomers = async ()/** : Promise<CustomerProps | undefined>*/ => {
        setLoading(true);
        try {
            const response = await apiClient.request(`${config.api.routes.backend.customers}`, {}, 'GET');

            if ("error" in response) {
                throw response;
            }

            if ("data" in response) {
                return response.data;
            }

            return undefined;
        } catch (error) {
            handleError(error);
            return undefined;
        } finally {
            setLoading(false);
        }
    }

    const getDepartments = async (id: string): Promise<any> => {
        setLoading(true);
        try {
            const response = await apiClient.request(`/api/v1/customers/${id}/structure`, {}, 'GET');

            if ("error" in response) {
                throw response;
            }

            if ("data" in response) {
                return response.data;
            }

            return [];
        } catch (error) {
            handleError(error);
            return [];
        } finally {
            setLoading(false);
        }
    }

    const saveResourcePlanner = async (customerId: string, resourceData: any): Promise<any | null> => {

        setLoading(true);
        try {
            const response = await apiClient.request(`/api/v1/customers/${customerId}/structure`, resourceData, 'PUT')

            if ("error" in response) {
                throw response;
            };

            if ("data" in response) {
                return response.data;
            };

            return [];
        } catch (error) {
            handleError(error);
            return [];
        } finally {
            setLoading(false);
        }
    }

    return {
        getCustomer,
        getDepartments,
        saveResourcePlanner,
        getCustomers,
        loading
    }
}
