import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { Alert, message } from 'antd';
import Spinner from '../Spinner';
import {config} from 'config/config';
import apiClient from '../../utils/apiClient';
import coursesApiClient from '../../utils/coursesApiClient';
import './styles.scss';
import { useNavigate } from 'react-router-dom';

const mapStateToProps = (state: any) => ({ session: state.session });

interface LearningActivityProps {
  learningActivityId: number;
  activityAttemptId?: number;
  type: 'learn' | 'test';
  isFirstAttempt?: boolean;
  session?: any;
  parentActivityId?: number;
  campaignId?: string;
  language?: string;
  version?: string;
}

type ServerRequestType = {
    learningActivityId: number;
    activityAttemptId?: number;
    type: 'learn' | 'test';
    language?: string;
    version?: string;
}

const LearningActivity: React.FC<LearningActivityProps> = ({
  learningActivityId, activityAttemptId, type,
  isFirstAttempt, session, parentActivityId, campaignId, language, version
}) => {
  const [isLearningActivityLoading, setIsLearningActivityLoading] = useState(false);
  const [source, setSource] = useState<string|null>(null);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const intl = useIntl();
  const navigate = useNavigate();

  useEffect(() => {
  }, [intl, learningActivityId, activityAttemptId, type, isFirstAttempt, session]);

  useEffect(() => {
    if (session?.name) {
      loadLearningActivity();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[session])

  const loadLearningActivity = async () => {
    try {
      setIsLearningActivityLoading(true);
      setHasError(false);

      if (!learningActivityId || !type) {
        navigate(-1);
      }

      const parsedValues: ServerRequestType = {
        learningActivityId,
        activityAttemptId,
        type
      };

      if (language) {
        parsedValues.language = language
      }

      if (version) {
        parsedValues.version = version
      }

      const serveResponse = await coursesApiClient.request('/api/v1/courses/serve-request', parsedValues, 'POST');


      if (serveResponse.learningActivityServeRequest?.message) {
        setHasError(true);
        setErrorMessage(serveResponse.learningActivityServeRequest.message)
        return
      }

      const serveRequest = serveResponse.learningActivityServeRequest;

      setSource(`${config.api.serveUrl}/${serveRequest.uuid}?fullName=${session.name} ${session.surname}&email=${session.email}`);

      if (isFirstAttempt) {
        consumeLicenceAccess();
      }
    } catch (err: any) {
      if (err.message === 'already_active_scorm') {
        message.error(intl.formatMessage({id: 'error.already_active_activity'}));
      } else {
        setHasError(true);
        setErrorMessage('campaign.learning_activity_not_found')
        console.error(err);
        message.error(intl.formatMessage({id: 'error.data_load'}));
      }
    } finally {
      setIsLearningActivityLoading(false);
    }
  }

  const consumeLicenceAccess = async () => {
    try {
      const values = {
        activityId: learningActivityId,
        userTypeId: session.active_user_type_id,
        customerId: session.organization.organization_id,
        parentActivityId: parentActivityId,
        campaignId: campaignId
      }

      return await apiClient.request('/api/v1/licences/consume', values, 'POST');
    } catch(error) {
      message.error(intl.formatMessage({ id: 'error.data_load' }));
      console.error(error);
    }
  }

  return (
    <Spinner spinning={isLearningActivityLoading}>
      {hasError
        ? <Alert
            message={intl.formatMessage({id: errorMessage})}
            type="error"
            showIcon
          />
        : null
      }
      {source ? <iframe className="scorm-player" title="SCORM_PLAYER" src={source}/> : null}
    </Spinner>
  );
}

export default connect(mapStateToProps)(LearningActivity);
