import React from 'react';
import { Progress } from 'antd';
import {connect} from "react-redux";
import styles from '../styles.module.scss';

interface BadgeProps {
    thumbnail: string;
    results: string;
    appearance: any;
}

const mapStateToProps = (state: any) => {
    return {
        appearance: state.appearanceSettings.theme,
    };
};

const Badge: React.FC<BadgeProps> = ({ thumbnail, results, appearance }) => (
  <div className={styles.badgeContainer}>
    <div className={styles.badge}>
      <div className={styles.badgeInner}>
        <i className={`${styles.badgeIcon} fal ${thumbnail}`} />
      </div>
        {/*{appearance &&*/}
            <Progress
                showInfo={false}
                percent={parseInt(results)}
                type="circle"
                width={60}
                strokeColor={"#2f224b"} //TODO use redux
            />
        {/*}*/}
    </div>
  </div>
);

export default connect(mapStateToProps)(Badge);
