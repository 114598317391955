import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import DefaultLayout from "components/DefaultLayout";
import Spinner from 'components/Spinner';
import { Tabs } from 'antd';
import UpcomingSessions from 'components/Lectures/MySessionsTabs/UpcomingSessions/UpcomingSessions';
import PastSessions from 'components/Lectures/MySessionsTabs/PastSessions';
import { Search, Table } from "ui";
import FlexRow from "components/FlexRow";
import { connect } from 'react-redux';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useLecture } from 'hooks/Lectures/useLecture';
import { LectureProps } from 'components/Lectures/types';
import coursesApiClient from 'utils/coursesApiClient';
import '../styles.scss';

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale,
        session: state.session
    }
};

export interface MyLecturesProps {
    session: any;
    adminView?: boolean;
    userTypeId?: number;
    setAdminSession?: React.Dispatch<React.SetStateAction<number>>;
    setAdminLecture?: React.Dispatch<React.SetStateAction<number>>;
    setAdminView?: React.Dispatch<React.SetStateAction<string>>;
}


const MyLectures: React.FC<MyLecturesProps> = ({
                                                   session,
                                                   adminView = false,
                                                   userTypeId,
                                                   setAdminSession,
                                                   setAdminLecture,
                                                   setAdminView
                                               }) => {
    const [reload, setReload] = useState<boolean>(false);
    const [searchString, setSearchString] = useState<string>('');
    const [lecture, setLecture] = useState<LectureProps>({});
    const [dataSource, setDataSource] = useState<any>([]);
    const [scenarioUpdate, setScenarioUpdate] = useState(false);

    const intl = useIntl();
    const params = useParams();
    const navigate = useNavigate();
    const { getLectureById } = useLecture();

    const lecturesId = params.lecturesId ? +params.lecturesId : undefined;

    useEffect(() => {
        const loadlLecture = async () => {
            if (!!lecturesId) {
                const lecture = await getLectureById(lecturesId);
                if (lecture && lecture.id) {
                    setLecture(lecture);
                } else {
                    navigate('/my-lectures');
                }
            } else {
                navigate('/my-lectures');
            }
        }

        if (lecturesId) {
            loadlLecture()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lecturesId])

    const loadData = async (expanded: boolean, record: any) => {
        if (expanded) {
            setScenarioUpdate(true);
            try {
                const response = await coursesApiClient.request(
                    `/api/v1/courses/lectures/${record.id}/sessions/supervision`,
                    [],
                    'GET'
                );
                // record
                let key: any = Object.entries(dataSource).filter(
                    (el: any) => el[1].id === record.id
                );

                dataSource[key[0][0]].data = response.data;

                setDataSource(dataSource);

                setScenarioUpdate(false);
            } catch (e) {}
        }
    };

    const renderContent = () => {
        if (session.active_user_type === 'SUPERVISION' && !lecturesId) {
            return <DefaultLayout.PageContent>
                <FlexRow
                    left={<FormattedMessage id={'student.my_lectures'} />}
                    right={
                        <Search setSearchString={setSearchString} setReload={setReload} />
                    }
                />
                <Table
                    columns={[
                        {
                            key: 'title',
                            title: intl.formatMessage({ id: 'general.lectures.title' }),
                            render: (record: any) => {
                                return (
                                    <NavLink to={`/my-lectures/${record.id}`}>
                                        {record.title}
                                    </NavLink>
                                )
                            }
                        },
                    ]}
                    url={'/api/v1/courses/lectures'}
                    setReload={setReload}
                    reload={reload}
                    rowSelection={false}
                    rowKey={'id'}
                    search={searchString}
                />
            </DefaultLayout.PageContent>
        }

        return <Tabs destroyInactiveTabPane
            items={[
                {
                    label: intl.formatMessage({ id: 'general.lectures.upcoming_sessions' }),
                    key: 'upcoming-sessions',
                    children: <UpcomingSessions
                        lecture={lecture}
                        adminView={adminView}
                        userTypeId={userTypeId}
                        setAdminSession={setAdminSession}
                        setAdminLecture={setAdminLecture}
                        setAdminView={setAdminView}
                    />,
                },
                {
                    label: intl.formatMessage({ id: 'general.lectures.past_sessions' }),
                    key: 'past-sessions',
                    children: <PastSessions
                        lecture={lecture}
                        adminView={adminView}
                        userTypeId={userTypeId}
                        setAdminSession={setAdminSession}
                        setAdminLecture={setAdminLecture}
                        setAdminView={setAdminView}
                    />,
                },
            ]}
        />
    }
    return (
        <Spinner spinning={false}>
            <DefaultLayout.PageLayout withoutPageLayout>
                {session.active_user_type && <div className='content-wrapper'>
                    {lecturesId && lecture && <DefaultLayout.PageHeader
                        title={lecture.title ?? ''}
                        breadcrumb={[{
                            name: intl.formatMessage({ id: 'general.lectures.my_lectures' }),
                            path: '/my-lectures'
                        }]}
                    />}
                    {renderContent()}
                </div>}
            </DefaultLayout.PageLayout>
        </Spinner>
    )
};

export default connect(mapStateToProps)(MyLectures);
