import React, {useEffect,useState,useRef} from 'react';
import {Popover,Alert, Empty,message} from 'antd';
import { connect } from 'react-redux';
import useHandleError from "utils/useHandleError";
import apiClient from "utils/apiClient";
import {FormattedMessage, useIntl} from 'react-intl';
import Spinner from "components/Spinner";
import './styles.scss';

const mapStateToProps = (state: any) => {
    return {
      locale: state.locale.locale,
    }
}

const NotificationDropdown: React.FC<any> = ({locale, isMobile}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [notifications, setNotifications] = useState<any[]>([]);

  const [handleError] = useHandleError();
  const intl = useIntl();
  const Ref = useRef<any>(null)
  const intervalTime = 300000;

  useEffect(() => {
      startTimer()

      return () => {
        if(Ref.current) {
          clearInterval(Ref.current);
        }
      }
  }, [])

  useEffect(() => {
      if(isOpen) {
        startTimer()
      }
  }, [isOpen])

  const startTimer = () => {
    if(Ref.current) {
      clearInterval(Ref.current);
    }
    
    loadNotifications()
    const id = setInterval(() => {
      if(!isLoading) {
        loadNotifications()
      }
    }, intervalTime)
    
    Ref.current = id;
  }

  const loadNotifications = async () => {
    if(isLoading) {
      return;
    }
    
    setIsLoading(true);
    try {
        const response = await apiClient.request(`/api/v1/general-messaging/get-user-messages`, [], 'GET');
        setNotifications(response.generalMessages)

    } catch (error) {
        handleError(error)
    } finally {
        setIsLoading(false);
    }
  }
  
  const getAlertType = (type: string) => {
    switch (type) {
      case 'ALERT':
        return 'warning';
    
      default:
        return 'info';
    }
  };
  
  const PopoverContent = () => {
    return (
      <div className='notification-content' style={{width: '300px'}}>
        <Spinner spinning={isLoading}>
        {
          notifications.length > 0 ? notifications.map((item:any, key:number) => {
            return (
              <>
                <Alert key={key} type={getAlertType(item.type)} message={item.message} showIcon />
              </>
            )
          }) : <Empty description={intl.formatMessage({id: 'general_messaging.no_messages'})} />
        }
        </Spinner>
      </div>
    )
  };

  return <div className={isMobile ? 'session-switch-item-mobile' : 'session-switch-item'}>
    <Popover
      placement='bottomRight'
      content={<PopoverContent />}
      overlayClassName='session-switch-popover notifications-popover'
      //arrowContent={false}
      open={isOpen}
      trigger='click'
      onOpenChange={() => setIsOpen(!isOpen)}
      title={intl.formatMessage({id: 'general_messaging.notifications'})}
    >
      <div className='role-switch notifications-dropdown'>
        <i className='fal fa-bell with-accent' />
        {notifications.length > 0 && <div className='notifications-dot'></div>}
      </div>
    </Popover>
  </div>
}

export default connect(mapStateToProps)(NotificationDropdown);
