import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Tag, Tooltip } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import ListItem from './ListItem';
import styles from '../../styles.module.scss';
import { connect } from 'react-redux';

interface ListProps {
  activity: any;
  locale?: string;
  attempt?: any;
}
const mapStateToProps = (state: any) => {
  return {
    locale: state.locale.locale
  }
}

const List: React.FC<ListProps> = ({ activity, locale, attempt }) => {
  const intl = useIntl();

  const getTimeSpentContent = () => {
    if (!activity.timeSpent) {
      return null;
    }

    const timeDate = new Date(activity.timeSpent * 1000);

    const timeSpent = {
      hours: timeDate.getUTCHours(),
      minutes: timeDate.getUTCMinutes(),
      seconds: timeDate.getUTCSeconds(),
    };

    if (timeSpent.hours > 0 || timeSpent.minutes > 0) {
      if (timeSpent.hours > 0) {
        return intl.formatMessage({ id: 'student.time_spent_with_hours' }, { hours: timeSpent.hours, minutes: timeSpent.minutes });
      } else {
        return intl.formatMessage({ id: 'student.time_spent_without_hours' }, { minutes: timeSpent.minutes });
      }
    } else {
      return `${timeSpent.seconds}s`;
    }
  }

  const getResultContent = () => {
    if ((activity.testStatus === 'PASSED' || activity.testStatus === 'FAILED') && activity.result) {
      return `${activity.result}%`;
    }

    return null;
  }

  const getMandatoryContent = () => {
    return activity.mandatory
      ? <CheckOutlined style={{ marginLeft: '15%', color: 'green' }} />
      : <CloseOutlined style={{ marginLeft: '15%', color: 'red' }} />
  };

  const getStatusContent = (status: 'NOT_STARTED' | 'IN_PROGRESS' | 'COMPLETED' | null) => {
    status = status === null ? 'NOT_STARTED' : status;

    const parameters = {
      'NOT_STARTED': { color: 'gold', message: 'student.not_started' },
      'IN_PROGRESS': { color: 'blue', message: 'student.in_progress' },
      'COMPLETED': { color: 'green', message: 'student.completed' },
      'EVALUATION': { color: 'purple', message: 'courses.topic_status.evaluation' }
    };

    let assignmentCompletionColor

    switch (activity.assignmentStatus) {
      case 'COMPLETED':
      case 'FAILED':
        assignmentCompletionColor = '#379C37'
        break;
      case 'SUBMITTED':
        assignmentCompletionColor = '#428bdf'
        break;
      case 'NOT_SUBMITTED':
        assignmentCompletionColor = ''
        break;
    }

    return (
      <div className={styles.material}>
        <Tag color={(activity.hasAssignment && activity.status === 'COMPLETED') ? parameters['EVALUATION'].color : parameters[status].color}>
          <FormattedMessage id={(activity.hasAssignment && activity.status === 'COMPLETED') ? parameters['EVALUATION'].message : parameters[status].message} />
        </Tag>

        {activity.hasMaterials && (activity.hasLearningFile || activity.hasTest) &&
          <Tag className={styles.materialDisplay} style={{ display: activity.hasMaterials ? '' : 'none' }} icon={<i style={{ color: activity.learningActivity ? '#379C37' : '' }} className="fa-solid fa-check"></i>} >
            <FormattedMessage id='courses.learning_activity' />
          </Tag>
        }
        {activity.hasMaterials && (activity.hasLearningFile || activity.hasTest) &&
          <Tag className={styles.materialDisplay} style={{ display: activity.hasMaterials ? '' : 'none' }} icon={<i style={{ color: assignmentCompletionColor }} className="fa-solid fa-check"></i>} >
            <FormattedMessage id='courses.individual_work' />
          </Tag>
        }
      </div>
    );
  };

  const getTile = () => {
    const title = JSON.parse(activity.title);

    if (locale) {
      return title[locale] ? title[locale] : title[Object.keys(title)[0]]
    } else {
      return title[Object.keys(title)[0]];
    }
  }

  const getTileIcon = (output: 'tooltip' | 'icon' = 'tooltip') => {

    let title: any = {}

    switch (activity.lastLearningActivity) {
      case 'SCORM_LEARNING':
      case 'SCORM_TEST':
        title = { icon: <i className="fa-solid fa-book" />, tooltip: 'courses.interactive_study' };
        break;
      case 'VIDEO_MATERIAL':
        title = { icon: <i className="fa-solid fa-book" />, tooltip: 'courses.material_type_video_material' };
        break;
      case 'PDF_MATERIAL':
        title = { icon: <i className="fa-solid fa-book" />, tooltip: 'courses.material_type_pdf_material' };
        break;
      default:
        title = { icon: <i className="fa-solid fa-square-exclamation" />, tooltip: 'general.error' }
        break;
    }

    if (activity.hasAssignment) {
      title = { icon: <i className="fa-solid fa-file-signature" />, tooltip: 'courses.individual_work' };
    }

    return title[output]
  }

  const listItems = [
    {
      titleId: 'general.activity',
      content: <span className={styles.listItemContentTitle} >
        <Tooltip title={intl.formatMessage({ id: `${getTileIcon('tooltip')}` })}>
          {getTileIcon('icon')}{getTile()}
        </Tooltip>
      </span>,
    },
    {
      titleId: 'student.time_spent',
      content: getTimeSpentContent(),
    },
    {
      titleId: 'general.pass_rate',
      content: activity.hasTest ? `${activity.passRate}%` : null,
    },
    {
      titleId: 'student.result',
      content: getResultContent(),
    },
    {
      titleId: 'general.mandatory',
      content: getMandatoryContent(),
    },
    {
      titleId: 'general.status',
      content: getStatusContent(activity.status),
    },
  ];

  return (
    <div className={styles.list}>
      {listItems.map((listItem) => (
        <ListItem
          key={listItem.titleId}
          title={intl.formatMessage({ id: listItem.titleId })}
          content={listItem.content}
        />
      ))}
    </div>
  );
};

export default connect(mapStateToProps)(List);
