import React from 'react';
import { connect } from 'react-redux';
import DefaultLayout from 'components/DefaultLayout';
import LearningActivity from 'components/LearningActivity';
import PdfMaterialPlayer from 'components/StudentComponents/PdfMaterialPlayer';
import VideoMaterialPlayer from 'components/StudentComponents/VideoMaterialPlayer';
import EmbeddedDocMaterialPlayer from "components/StudentComponents/EmbeddedDocMaterialPlayer";
import LearningTestAttempt from 'components/LearningTest/TestAttempt';
import AssignmentForm from "components/AssignmentActivity";
import AssessmentActivity from "components/AssessmentActivity";
import CertificateActivity from "../../../components/CertificateActivity";

const mapStateToProps = (state: any) => ({ scormData: state.scormData, language: state.locale.locale });

const ShowScorm: React.FC<any> = ({ scormData, language }) => {

  const getLearningView = () => {

    switch (scormData.materialType) {
      case 'SCORM_LEARNING':
      case 'SCORM_TEST':
        return (
          <DefaultLayout.PageLayout withoutPageHeader>
            <DefaultLayout.PageContent>
              <LearningActivity
                learningActivityId={scormData.learningActivityId}
                activityAttemptId={scormData.activityAttemptId}
                type={scormData.type}
                isFirstAttempt={scormData.isFirstAttempt}
                parentActivityId={scormData.parentActivityId}
                campaignId={scormData.campaignId}
                language={language}
              />
            </DefaultLayout.PageContent>
          </DefaultLayout.PageLayout>
        )
      case 'LEARNING_TEST':
        return (
          <DefaultLayout.PageLayout withoutPageHeader>
            <DefaultLayout.PageContent style={{background: 'none', padding: '0px'}}>
              <LearningTestAttempt
                learningActivityId={scormData.learningActivityId}
                programActivityAttemptId={scormData.programActivityAttemptId}
                isFirstAttempt={scormData.isFirstAttempt}
                parentActivityId={scormData.parentActivityId}
                campaignId={scormData.campaignId}
                language={language}
                isStudentAttempt={true}
              />
            </DefaultLayout.PageContent>
          </DefaultLayout.PageLayout>
        )
      case 'PDF_MATERIAL':
        return (
          <div className='activity-wrapper'>
            <div className='activity-content'>
              <PdfMaterialPlayer
                learningActivityId={scormData.learningActivityId}
                activityAttemptId={scormData.activityAttemptId}
                type={scormData.type}
                isFirstAttempt={scormData.isFirstAttempt}
                parentActivityId={scormData.parentActivityId}
                campaignId={scormData.campaignId}
                language={language}
              />
            </div>
          </div>
        )
      case 'EMBEDDED_DOC':
        return (
            <div className='activity-wrapper'>
              <div className='activity-content'>
                <EmbeddedDocMaterialPlayer activityAttemptId={scormData.activityAttemptId} />
              </div>
            </div>
        )
      case 'VIDEO_MATERIAL':
        return (
          <div className='activity-wrapper'>
            <div className='activity-content'>
              <VideoMaterialPlayer
                scormData={scormData}
                language={language}

              />
            </div>
          </div>
        )
      case 'ASSIGNMENT':
        return (
          <AssignmentForm
            learningActivityId={scormData.learningActivityId}
            programActivityAttemptId={scormData.programActivityAttemptId}
            type={scormData.type}
            isFirstAttempt={scormData.isFirstAttempt}
            parentActivityId={scormData.parentActivityId}
            campaignId={scormData.campaignId}
            language={language}
          />
        )
      case 'ASSESSMENT':
        return (
          <AssessmentActivity
            learningActivityId={scormData.learningActivityId}
            programActivityAttemptId={scormData.programActivityAttemptId}
            type={scormData.type}
            isFirstAttempt={scormData.isFirstAttempt}
            parentActivityId={scormData.parentActivityId}
            campaignId={scormData.campaignId}
            language={language}
          />
        )
      case 'CERTIFICATE':
        return (
            <CertificateActivity programActivityAttemptId={scormData.programActivityAttemptId} />
        )
      default:
        break;
    }
  }
  return (
    <>
      {getLearningView()}
    </>
  )
}

export default connect(mapStateToProps)(ShowScorm);
