import {
    AudienceTabProps,
    AudienceRuleSetModel,
    AudienceTypes,
    AudienceStatusTypes,
    AudienceRuleModel,
    AudienceRuleOperator
} from 'components/Audiences/types';
import React, {useEffect, useState} from 'react';
import {Button} from "ui";
import {FormattedMessage, useIntl} from 'react-intl';
import RuleSetCreationModal from 'components/Audiences/Modals/RuleSetCreationModal';
import AudienceRuleSet from 'components/Audiences/AudienceRuleSet';
import useHandleError from 'utils/useHandleError';
import apiClient from 'utils/apiClient';
import {Checkbox, Form, Input as AntdInput, List} from 'antd';
import {DatePicker, Input, InputNumber, Switch} from 'components/Form';
import DefaultLayout from "components/DefaultLayout";
import Spinner from 'components/Spinner';
import '../../styles.scss';
import {config} from "config/config";
import coursesApiClient from "utils/coursesApiClient";
import moment from "moment/moment";

export enum FormChanges {
    Audience = 'AUDIENCE',
    Rulset = 'RULESET',
    Rule = 'RULE',
}

const SettingsTab: React.FC<AudienceTabProps> = ({audience, reloadAudience}) => {
    const [showCreateModal, setCreateModal] = useState<boolean>(false)
    const [reload, setReload] = useState<boolean>(false);
    const [ruleSets, setRuleSets] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [formChanges, setFormChanges] = useState<FormChanges[]>([]);
    const [dynamicForm, setDynamicForm] = useState<boolean>(false);
    const [customFields, setCustomFields] = useState<any>([]);

    const intl = useIntl();
    const [form] = Form.useForm();
    const [handleError] = useHandleError();

    const formItemLayout = {
        labelCol: {span: 8},
        wrapperCol: {span: 16},
    };

    const handleAddRuleSet = () => {
        setCreateModal(false);
        setReload(!reload);
    }
    const loadCustomFields = async () => {
        try {
            const customFields = await coursesApiClient.request('/api/v1/audiences/custom-fields/list', [], 'GET');
            setCustomFields(customFields.fields);
        } catch (error) {
            handleError(error)
        } finally {
        }
    }

    useEffect(() => {
        loadCustomFields();
    }, []);


    useEffect(() => {
        const loadRules = async (ruleSetId: number): Promise<Array<AudienceRuleModel>> => {
            const response = await apiClient.request(`/api/v1/audiences/rules?audienceRuleSetId=${ruleSetId}`, [], 'GET');
            return response.data ?? [];
        }

        const loadRuleSets = async () => {
            setIsLoading(true);
            try {
                const response = await apiClient.request(`/api/v1/audiences/rule-sets/?audienceId=${audience.id}`, [], 'GET');
                if ("error" in response) {
                    throw response
                };
                const ruleSets = response.data;

                for await (const ruleSet of ruleSets) {
                    ruleSet.rules = await loadRules(ruleSet.id);

                    form.setFieldsValue({
                        rulesGroup: {
                            [ruleSet.id]: {
                                rules: ruleSet.rules
                            }
                        },
                        operatorGroup: {
                            [ruleSet.id]: {
                                operator: ruleSet.operator === AudienceRuleOperator.And,
                                operator_tmp: ruleSet.operator !== AudienceRuleOperator.And,
                            }
                        }
                    });
                }

                setRuleSets(ruleSets);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                handleError(error)
            } finally {
                setIsLoading(false);
            }
        }

        loadRuleSets();
        setFormChanges([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload])

    useEffect(() => {
        form.resetFields()

        if (Object.keys(audience.customFields).length > 0 && audience.customFields) {
            Object.values(audience.customFields).map((field: any) => {
                switch (field.field_type) {
                    case 'DATE':
                        form.setFieldsValue({
                            customFields: {
                                [field.uuid]: moment(field.values)
                            }
                        })
                        break
                    default:
                        form.setFieldsValue({
                            customFields: {
                                [field.uuid]: field.values
                            }
                        })
                        break
                }
            });
        }

        form.setFieldsValue({
            type: audience.type === AudienceTypes.Dynamic,
            name: audience.name,
            status: audience.status === AudienceStatusTypes.Online,
            rule_add_members: !!audience.ruleAddMembers,
            rule_exclude_members: !!audience.ruleExcludeMembers,
        });

        setDynamicForm(audience.type === AudienceTypes.Dynamic)
        setReload(!reload)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audience])

    const handleFormSubmit = async (values: any) => {
        if (!formChanges.length) {
            return;
        }

        setIsLoading(true);
        try {
            await Promise.all([
                formChanges.includes(FormChanges.Audience) && saveAudienceData(values),
                formChanges.includes(FormChanges.Rulset) && saveRuleSetSettings(values.operatorGroup),
                formChanges.includes(FormChanges.Rule) && saveRules(values.rulesGroup),
            ]);
            reloadAudience();
        } catch (error) {
            setIsLoading(false);
            handleError(error)
        } finally {
            executeRules()
            setIsLoading(false);
        }
    };

    const saveAudienceData = async (values: any) => {
        const audienceData: any = {
            name: values.name,
            type: values.type ? AudienceTypes.Dynamic : AudienceTypes.Strict,
            status: values.status ? AudienceStatusTypes.Online : AudienceStatusTypes.Offline,
            ruleAddMembers: values.rule_add_members,
            ruleExcludeMembers: values.rule_exclude_members,
            customFields: values.customFields
        }

        setIsLoading(true);
        try {
            const response = await apiClient.request(`/api/v1/audiences/${audience.id}`, audienceData, 'PUT');
            if ("error" in response) {
                throw response
            };
        } catch (error) {
            setIsLoading(false);
            handleError(error)
        } finally {
            setIsLoading(false);
        }
    };

    const saveRuleSetSettings = async (operatorGroups: any) => {
        for await (const ruleSetId of Object.keys(operatorGroups)) {
            const operator = operatorGroups[ruleSetId].operator;

            if (operatorGroups[ruleSetId]) {
                setIsLoading(true);
                try {
                    const response = await apiClient.request(`/api/v1/audiences/rule-sets/${ruleSetId}`, {
                        operator: operator ? AudienceRuleOperator.And : AudienceRuleOperator.Or,
                    }, 'PUT');
                    if ("error" in response) {
                        throw response
                    };
                } catch (error) {
                    setIsLoading(false);
                    handleError(error)
                } finally {
                    setIsLoading(false);
                }
            }
        }
    };

    const saveRules = async (rulesGroup: any) => {
        const rules = getRulesFromFormValues(rulesGroup);
        const updated: number[] = [];

        for await (const rule of rules) {
            if (!rule.id) {
                setIsLoading(true);
                try {
                    const response = await apiClient.request(`/api/v1/audiences/rules`, rule, 'POST');
                    if ("error" in response) {
                        throw response
                    };
                } catch (error) {
                    setIsLoading(false);
                    handleError(error)
                } finally {
                    setIsLoading(false);
                }
            } else {
                const ruleId = rule.id;
                updated.push(ruleId);
                delete rule.id;
                setIsLoading(true);
                try {
                    const response = await apiClient.request(`/api/v1/audiences/rules/${ruleId}`, rule, 'PUT');
                    if ("error" in response) {
                        throw response
                    };
                } catch (error) {
                    setIsLoading(false);
                    handleError(error)
                } finally {
                    setIsLoading(false);
                }
            }
        }

        const deleted = ruleSets.map((ruleSet: AudienceRuleSetModel) => {
            if (ruleSet.rules) {
                return ruleSet.rules.map((rule: AudienceRuleModel) => {
                    if (rule.id && !updated.includes(rule.id)) {
                        return rule.id;
                    }

                    return null;
                });
            }
            return null;
        }).flat().filter(Number);

        if (!!deleted.length) {
            for await (const ruleId of deleted) {
                await apiClient.request(`/api/v1/audiences/rules/${ruleId}`, [], 'DELETE');
            }
        }

    };

    const executeRules = async () => {
        await apiClient.request(`/api/v1/audiences/rules/${audience.id}/execute-rules`, [], 'POST');
    };

    const getRulesFromFormValues = (values: any): Array<AudienceRuleModel> => {
        let rules: any = [];

        Object.entries(values).forEach(([ruleSetId, rulesGroup]: Array<any>) => {
            if (rulesGroup.rules) {
                rules.push(rulesGroup.rules.map((rule: any) => {
                    return {
                        audienceRuleSetId: ruleSetId,
                        ...rule,
                    }
                }));
            };
        })

        return rules.flat();
    }

    const onValuesChange = (changedValues: any) => {
        if (changedValues.rulesGroup) {
            setFormChanges(current => Array.from(new Set([...current, FormChanges.Rule])));
        } else if (changedValues.operatorGroup) {
            setFormChanges(current => Array.from(new Set([...current, FormChanges.Rulset])));
        } else {
            setFormChanges(current => Array.from(new Set([...current, FormChanges.Audience])));
        }
    }

    const audienceFormItemsdata = [
        <Input
            name='name'
            label={intl.formatMessage({id: 'audience.audience_name'})}
            customLayout={{labelCol: {span: 18}, wrapperCol: {span: 6}}}
            customRules={[{required: true, message: intl.formatMessage({id: 'validation.field_required'})}]}
            disabled={!audience.isEditable()}
        />,

        <Switch name='status' isFormItem
                customLayout={{labelCol: {span: 20}, wrapperCol: {span: 4}}}
                label={intl.formatMessage({id: 'general.online'})}
                valuePropName="checked"
                disabled={!audience.isEditable()}
        />,

        <Switch name='type' isFormItem
                customLayout={{labelCol: {span: 20}, wrapperCol: {span: 4}}}
                label={intl.formatMessage({id: 'audience.dynamic_audience'})}
                valuePropName="checked" onChange={(value: any) => {
            setDynamicForm(value);
        }}
                disabled={!audience.isEditable()}
        />
    ];

    const audiencemembershipFormItemsdata = [
        <Switch name='rule_add_members' isFormItem
                customLayout={{labelCol: {span: 20}, wrapperCol: {span: 4}}}
                label={intl.formatMessage({id: 'audience.add_user_if_matches_criteria'})}
                valuePropName="checked"
                disabled={!audience.isEditable()}
        />,

        <Switch name='rule_exclude_members' isFormItem
                customLayout={{labelCol: {span: 20}, wrapperCol: {span: 4}}}
                label={intl.formatMessage({id: 'audience.exclude_user_if_no_matches_criteria'})}
                valuePropName="checked"
                disabled={!audience.isEditable()}
        />
    ];

    const audienceFilters = () => {
        let filters: any = [];

        if (customFields.length > 0) {
            customFields.map((field: {
                uuid: string,
                field_type: 'TEXT' | 'INTEGER' | 'DATE' | 'SWITCH' | 'MULTIPLE_CHOICE' | 'HYPERLINK',
                title: string,
                multiple_choice_options: string[]
            }) => {


                switch (field.field_type) {
                    case 'TEXT':
                        filters.push(<Input name={['customFields', field.uuid]}
                                            customLayout={{labelCol: {span: 20}, wrapperCol: {span: 4}}}

                                            label={field.title}></Input>)
                        break
                    case 'INTEGER':
                        filters.push(<InputNumber className={'my-1'} name={['customFields', field.uuid]}
                                                  customLayout={{labelCol: {span: 20}, wrapperCol: {span: 4}}}

                                                  label={field.title}></InputNumber>)
                        break

                    case 'DATE':
                        filters.push(<DatePicker className={'my-1'} name={['customFields', field.uuid]}
                                                 customLayout={{labelCol: {span: 20}, wrapperCol: {span: 4}}}
                                                 label={field.title}
                                                 format={config.defaultDateFormat}></DatePicker>)
                        break

                    case 'SWITCH':
                        filters.push(<Switch name={['customFields', field.uuid]} isFormItem
                                             customLayout={{labelCol: {span: 20}, wrapperCol: {span: 4}}}
                                             label={field.title}></Switch>)
                        break

                    case 'MULTIPLE_CHOICE':
                        filters.push(<div className={'multiple-choice-fields'}>
                            <Form.Item label={field.title}
                                       name={['customFields', field.uuid]}>
                                <Checkbox.Group>
                                    {
                                        field.multiple_choice_options.map((el: string) => {
                                            return (
                                                <Checkbox value={el}>
                                                    {el}
                                                </Checkbox>
                                            )
                                        })
                                    }
                                </Checkbox.Group>
                            </Form.Item>
                        </div>)
                        break
                    case 'HYPERLINK':
                        filters.push(
                            <Form.Item className={'audience-hyperlink'} label={field.title}
                                       name={['customFields', field.uuid]}>
                                <AntdInput.Group compact>
                                    <Form.Item
                                        className={'vertical-form-item'}
                                        label={intl.formatMessage({id: 'users.custom_filter_display_name'})}
                                        name={['customFields', field.uuid, 'displayName']}
                                    >
                                        <AntdInput/>
                                    </Form.Item>
                                    <Form.Item
                                        className={'vertical-form-item'}
                                        label={intl.formatMessage({id: 'users.custom_filter_hyperlink'})}
                                        name={['customFields', field.uuid, 'hyperlink']}
                                    >
                                        <AntdInput/>
                                    </Form.Item>
                                </AntdInput.Group>
                            </Form.Item>
                        )
                        break
                }
            })
        }
        return filters
    };


    return (
        <>
            <Form form={form} onFinish={handleFormSubmit} {...formItemLayout} onValuesChange={onValuesChange}>
                <List
                    className="audience-form-items-list"
                    header={<FormattedMessage id='audience.settings'/>}
                    bordered
                    dataSource={audienceFormItemsdata}
                    renderItem={(item) => <>{item}</>}
                />
                {audienceFilters().length ?
                    <List
                        className="audience-form-items-list"
                        header={<FormattedMessage id='general.custom_fields'/>}
                        bordered
                        dataSource={audienceFilters()}
                        renderItem={(item) => <>{item}</>}
                    />
                    : null
                }

                {dynamicForm && <Spinner spinning={isLoading}>
                    <List
                        className="audience-form-items-list"
                        header={<FormattedMessage id='audience.automatic_membership_updating'/>}
                        bordered
                        dataSource={audiencemembershipFormItemsdata}
                        renderItem={(item) => <>{item}</>}
                    />

                    {ruleSets.map((ruleSet: AudienceRuleSetModel, index: number) => {
                        return <AudienceRuleSet index={index}
                                                audience={audience}
                                                ruleSet={ruleSet}
                                                form={form} reloadAudience={reloadAudience}/>
                    })}

                    {audience.isEditable() && <Button type='primary' onClick={() => {
                        setCreateModal(true)
                    }}
                                                      icon={<i className="fal fa-plus"/>}
                                                      style={{
                                                          marginLeft: 'auto',
                                                          marginRight: 'auto',
                                                          display: 'block'
                                                      }}
                    >
                        <FormattedMessage id='audience.new_rule_set'/>
                    </Button>}
                </Spinner>}
            </Form>

            {audience.isEditable() && <DefaultLayout.PageFooter right={
                <>
                    <Button type='secondary' disabled={!formChanges.length} isLoading={isLoading}
                            onClick={reloadAudience}>
                        <FormattedMessage id='general.cancel'/>
                    </Button>
                    <Button type='primary' disabled={!formChanges.length} isLoading={isLoading}
                            onClick={() => {
                                form.submit()
                            }}>
                        <FormattedMessage id='general.submit'/>
                    </Button>
                </>
            }/>}

            {audience.isEditable() && audience && audience.id && <RuleSetCreationModal audienceId={audience.id}
                                                                                       visible={showCreateModal}
                                                                                       onCancel={() => setCreateModal(false)}
                                                                                       onSubmit={() => handleAddRuleSet()}
            />}
        </>
    );
};

export default SettingsTab;
