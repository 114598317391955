import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {Tooltip} from 'antd';
import {useParams} from 'react-router-dom';
import {Table} from 'ui';
import {usePhishing} from "hooks/Phishing/usePhishing";
import EnrollmentResultsModal from "../../Modals/EnrollmentResults";

const PhishingCampaignResults: React.FC<any> = ({}) => {
    const {} = usePhishing();
    const [reload, setReload] = useState(false);
    const [showResultModal, setShowResultModal] = useState(false);
    const [enrollment, setEnrollment] = useState(null);

    const params = useParams();
    const campaignId = params.id;
    const intl = useIntl();

    const columns = [
        {
            title: intl.formatMessage({id: 'general.name'}),
            dataIndex: 'name',
        },
        {
            title: intl.formatMessage({id: 'general.surname'}),
            dataIndex: 'surname',
        },
        {
            title: intl.formatMessage({id: 'general.email'}),
            dataIndex: 'email',
        },
        {
            title: intl.formatMessage({id: 'general.organizational_unit'}),
            dataIndex: 'organizationalUnit',
        },
        {
            title: intl.formatMessage({id: 'phishing.phishes_received'}),
            dataIndex: 'totalEvents',
            render: (events: string, record: any) => {
                return (
                    <div className='phishes-recieved' onClick={() => {
                        setShowResultModal(true)
                        setEnrollment(record)
                    }}>
                        <span>{events}</span>
                    </div>
                )
            }
        },
        {
            title: (
                <span>
                    {intl.formatMessage({id: 'phishing.result'})}
                    <Tooltip title={intl.formatMessage({id: 'phishing.results_hint'})}>
                          <i className='fal fa-question-circle header-item'/>
                    </Tooltip>
                </span>
            ),
            dataIndex: 'phishingResult',
        },
    ];

    return (
        <>
            <Table
                columns={columns}
                url={`/api/v1/courses/phishing/campaign/${campaignId}/results`}
                setReload={setReload}
                size={'small'}
                rowSelection={false}
                showTotalResults={true}
                reload={reload}
                rowKey={'id'}
            />
            <EnrollmentResultsModal
                visible={showResultModal}
                onCancel={setShowResultModal}
                enrollment={enrollment}
            />
        </>
    )
}

export default PhishingCampaignResults;
