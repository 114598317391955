
import { Tag } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { LectureSessionAttendeeStatus, LectureSessionStatus } from '../types';

const mapStateToProps = (state: any) => {
    return {
        session: state.session
    }
};

export interface SessionStatusBadgeProps {
    attendee? : boolean,
    status?: LectureSessionAttendeeStatus | LectureSessionStatus,
    session?: any;
}

const SessionStatusBadge: React.FC<SessionStatusBadgeProps> = ({ attendee, status, session }) => {
    const intl = useIntl();
    let badge;

    if (attendee || (!!session && !!session.active_user_type && session.active_user_type === 'STUDENT')) {
        switch (status) {
            case LectureSessionStatus.NotStarted:
                badge = {
                    color: 'yellow',
                    text: intl.formatMessage({ id: 'lectures.status_NOT_STARTED' }).toLowerCase(),
                }
                break;
            case LectureSessionStatus.NotApplicable:
                badge = {
                    color: 'green',
                    text: intl.formatMessage({ id: 'lectures.status_NOT_APPLICABLE' }).toLowerCase(),
                }
                break;
            case LectureSessionAttendeeStatus.Planned:
                badge = {
                    color: 'geekblue',
                    text: intl.formatMessage({ id: `general.lectures.session_attendee_status_${status}` }),
                }
                break;
            case LectureSessionAttendeeStatus.Attended:
                badge = {
                    color: 'green',
                    text: intl.formatMessage({ id: `general.lectures.session_attendee_status_${status}` }),
                }
                break;
            case LectureSessionAttendeeStatus.Missed:
                badge = {
                    color: 'red',
                    text: intl.formatMessage({ id: `general.lectures.session_attendee_status_${status}` }),
                }
                break;
        }
    } else {
        switch (status) {
            case LectureSessionStatus.NotStarted:
                badge = {
                    color: 'yellow',
                    text: intl.formatMessage({ id: 'lectures.status_NOT_STARTED' }).toLowerCase(),
                }
                break;
            case LectureSessionStatus.NotApplicable:
                badge = {
                    color: 'green',
                    text: intl.formatMessage({ id: 'lectures.status_NOT_APPLICABLE' }).toLowerCase(),
                }
                break;
            case LectureSessionStatus.Planned:
                badge = {
                    color: '#d3d3d3',
                    text: intl.formatMessage({ id: `general.lectures.session_attendee_status_PLANNED` }).toLowerCase(),
                }
                break;
            case LectureSessionStatus.Completed:
                badge = {
                    color: 'green',
                    text: intl.formatMessage({ id: `general.completed` }).toLowerCase(),
                }
                break;
            case LectureSessionStatus.InProgress:
                badge = {
                    color: 'cyan',
                    text: intl.formatMessage({ id: `general.in_progress` }).toLowerCase(),
                }
                break;
        }
    }

    if (!badge) {
        return <div className={'child'}></div>;
    }

    return <Tag color={badge.color} key={status} className={status}>
        {badge.text}
    </Tag>;
};

export default connect(mapStateToProps)(SessionStatusBadge);
