import React, {useState} from 'react';
import {useIntl, FormattedMessage} from 'react-intl';
import {Button, message, Modal} from 'antd';
import coursesApiClient from 'utils/coursesApiClient';
import LearningActivity from 'components/LearningActivity';
import {Select} from 'components/Form';
import LanguageSelect from 'components/LanguageSelect';
import PdfMaterialPlayer from 'components/StudentComponents/PdfMaterialPlayer';
import VideoMaterialPlayer from 'components/StudentComponents/VideoMaterialPlayer';
import EmbeddedDocMaterialPlayer from "components/StudentComponents/EmbeddedDocMaterialPlayer";
import LearningTestAttempt from "components/LearningTest/TestAttempt";

interface ScormPreviewModalInterface {
    visible: boolean;
    onCancel: any;
    title: any;
    activityId: number;
    previewType: 'learn' | 'test',
    type: string;
}

const ScormPreviewModal: React.FC<ScormPreviewModalInterface> = ({
                                                                     visible,
                                                                     onCancel,
                                                                     title,
                                                                     activityId = 0,
                                                                     previewType,
                                                                     type
                                                                 }) => {
    const [showPreviewFile, setShowPreviewFile] = useState(false);
    const [versionList, setVersionList] = useState<any>([]);
    const [selectedVersion, setSelectedVersion] = useState<string | undefined>();
    const [isVersionsLoading, setIsVersionsLoading] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState<string>('');

    const intl = useIntl();

    const getVersionList = async (id: number, language: string) => {
        try {
            setIsVersionsLoading(true);
            let versionList = await coursesApiClient.request(`/api/v1/courses/activities/${id}/version-list/${language}`, {}, 'GET');
            setVersionList(versionList);
            setIsVersionsLoading(false);
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({id: "error.data_load"}));
        }
    };

    const getLearningView = () => {
        switch (type) {
            case 'LEARNING_TEST':
                return (
                    <LearningTestAttempt
                        defaultLearningTestId={activityId}
                        isPreview={true}
                    />
                )
            case 'SCORM_LEARNING':
            case 'SCORM_TEST':
                return (
                    <LearningActivity learningActivityId={activityId} type={previewType} language={selectedLanguage}
                                      version={selectedVersion}/>
                )
            case 'PDF_MATERIAL':
                return (
                    <PdfMaterialPlayer learningActivityId={activityId} type={'learn'} language={selectedLanguage}
                                       version={selectedVersion}/>
                )
            case 'VIDEO_MATERIAL':
                return (
                    <div className='activity-wrapper'>
                        <div className='activity-content'>
                            <VideoMaterialPlayer
                                scormData={{
                                    learningActivityId: activityId,
                                    type: 'learn'
                                }}
                                language={selectedLanguage}
                            />
                        </div>
                    </div>
                )
            case 'EMBEDDED_DOC':
                return (
                    <EmbeddedDocMaterialPlayer activityAttemptId={activityId} isPreview={true}
                                               language={selectedLanguage} version={selectedVersion}/>
                )
            default:
                break;
        }
    }

    return (
        <>
            {type !== 'LEARNING_TEST' ?
                <Modal
                    open={visible}
                    title={title}
                    onCancel={() => onCancel(false)}
                    cancelText={intl.formatMessage({id: 'general.close'})}
                    onOk={() => {
                        setShowPreviewFile(true);
                        onCancel(false)
                    }}
                    destroyOnClose
                >
                    <LanguageSelect
                        name='previewLanguage'
                        label={intl.formatMessage({id: 'general.language'})}
                        onChange={(language: string) => {
                            getVersionList(activityId, language);
                            setSelectedLanguage(language);
                        }}
                    />
                    <Select
                        loading={isVersionsLoading}
                        label={intl.formatMessage({id: "general.version"})}
                        name='version'
                        onChange={(version: string) => setSelectedVersion(version)}
                        manualOptions={{...versionList.versionList}}
                    />
                </Modal>
                : null
            }
            <Modal
                open={type !== 'LEARNING_TEST' ? showPreviewFile : visible}
                onCancel={() => type !== 'LEARNING_TEST' ? setShowPreviewFile(false) : onCancel(false)}
                title={title}
                cancelText={intl.formatMessage({id: 'general.back'})}
                width="84vw"
                destroyOnClose
                footer={[
                    <Button onClick={() => type !== 'LEARNING_TEST' ? setShowPreviewFile(false) : onCancel(false)}>
                        <FormattedMessage id='general.cancel'/>
                    </Button>
                ]}
            >
                {getLearningView()}
            </Modal>
        </>
    );
}

export default ScormPreviewModal;
