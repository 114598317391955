import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Form, Select as AntDSelect, Tooltip, Modal } from 'antd';
import { constructValidationRules } from './validations';
import './styles.scss';

const RemoveConfirmSelect: React.FC<any> = ({
  form,
  name,
  label,
  validation = {},
  customRules,
  customLayout,
  infoText,
  help,
  distinct,
  visible = true,
  manualOptions,
  className,
  hint,
  translationModule,
  setSelectedOptions,
  selectedOptions,
  setValueAfterDelete,
  selectedElements,
  removeFunction,
  confirmModalOK,
  confirmModalTitle,
  confirmModalCancel,
  confirmModalContent,
  ...props
}) => {
  const [selectselectedElements, setSelectSelectedElements] = useState<any>([]);

  const intl = useIntl();
  const confirm = Modal.confirm;

  // Fix for google autofill poping up in select input
  // https://github.com/ant-design/ant-design/issues/7659
  useEffect(() => {
    const fixAutocomplete = () => {
      document.querySelectorAll(".ant-select-selector input").forEach((e) => {
        e.setAttribute("autoComplete", "noAutocomplete");
        //you can put any value but NOT "off" or "false" because they DO NOT work
      })
    }
    fixAutocomplete();
  }, [])

  useEffect(()=> {
    if (selectedOptions) {
      let arr:any = [];
      selectedOptions.map((element:any)=> {
        Object.entries(manualOptions).map((el:any)=>{
          if (el[0] === element) {
            arr.push(el[0])
          }
          return arr;
        })
        return arr;
      })
      setSelectSelectedElements(arr)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedOptions])

  let labelCol: any = { span: 8 };
  let wrapperCol: any = { span: 6 };

  if (customLayout) {
    if (typeof customLayout === 'object') {
      labelCol = customLayout.labelCol || undefined;
      wrapperCol = customLayout.wrapperCol || undefined;
    } else {
      labelCol = undefined;
      wrapperCol = undefined;
    }
  }

  const onSelect = (value: any) => {
    setSelectedOptions([...selectselectedElements, value])
    setSelectSelectedElements([...selectselectedElements, value]);

  }

  const removeElement = (element:any) => {
    removeFunction(element.value);
  }

  function showConfirm(element:any) {
    confirm({
      title: confirmModalTitle,
      content: confirmModalContent,
      okText: confirmModalOK,
      okType: 'danger',
      cancelText: confirmModalCancel,
      onOk() {
        removeElement(element);
      },
      onCancel() {
        return null;
      },
    });
  }

  function tagRender (element:any) {
    return (
      <div className='custom-language-tags'> 
        {intl.formatMessage({ id: element.label })}
        <i onClick={()=>{showConfirm(element)}} style={{paddingLeft:'5px', paddingRight:'3px', cursor:'pointer'}} className="fal fa-times"></i>
      </div>
    )
  }

  return (
    <Form.Item
      className={className}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      label={
        hint
          ? <span>
            {intl.formatMessage({ id: label })}
            <Tooltip title={intl.formatMessage({ id: hint })}>
              <i className='fal fa-question-circle header-item' />
            </Tooltip>
          </span>
          : label
      }
      rules={customRules || constructValidationRules(validation, intl)}
      help={help}
      style={{ display: visible ? '' : 'none', width:'100%' }}
    >
      <AntDSelect
        mode="tags"
        tagRender={(el:any) => tagRender(el)}
        value={selectselectedElements}
        onSelect={(el: any) => onSelect(el)}
        {...props}
        showArrow
      >
        {Object.entries(manualOptions).map((options:any) => { 
          return (
            <AntDSelect.Option className='selected-languages' disabled={selectselectedElements.includes(options[0])} key={options[0]} value={options[0]} >
              {options[1]}
            </AntDSelect.Option>
          );
        }) 
        }
      </ AntDSelect>
    </Form.Item>
  )
}

export default RemoveConfirmSelect;
