import {useState} from 'react'
import useHandleError from 'utils/useHandleError';
import apiClient from 'utils/apiClient';
import generalHelpers from "utils/generalHelpers";

export const useProgram = () => {
    const [handleError] = useHandleError();
    const [loading, setLoading] = useState<boolean>(false)

    const searchProgram = async (q: string) => {
        setLoading(true)
        try {
            let requestUrl = '/api/v1/courses/learning-program/options/list';

            requestUrl = generalHelpers.urlBuilder(requestUrl, q, 'q')

            const response = await apiClient.request(requestUrl, [], 'GET');

            if (response) {
                if (response?.programs) {
                    return response.programs;
                } else {
                    return response;
                }
            }
        } catch (error) {
            handleError(error);
        } finally {
            setLoading(false)
        }
    }

    return {
        loading,
        searchProgram,
    }
}