import React, { useState, useEffect } from 'react';
import { Circle, Transformer, Line, Rect } from 'react-konva';
import Konva from 'konva';

const ShapeEdit: React.FC<any> = ({ 
    shape,
    index,
    shapeProps, 
    isSelected,
    isEditingLast,
    onTransformEnd
}) => {
    const shapeRef = React.useRef<Konva.Node>(null);
    const transformer = React.useRef<Konva.Transformer>(null);

    React.useEffect(() => {
        if (isSelected) {
            // we need to attach transformer manually
            transformer.current?.nodes([shapeRef.current!]);
            transformer.current?.getLayer()?.batchDraw();
        }
    }, [isSelected]);

    const renderShape = (shape: any) => {
        switch (shape.data.type) {
            case 'CIRCLE':
                return <Circle 
                    {...shapeProps}
                    ref={shapeRef}
                    x={shape.data.x} 
                    y={shape.data.y} 
                    radius={shape.data.radius} 
                    onTransformEnd={(e: any) => {
                        onTransformEnd(e, shapeRef.current)
                    }}
                />
                
            case 'RECT':
                return <Rect 
                    {...shapeProps}
                    ref={shapeRef}
                    x={shape.data.x} 
                    y={shape.data.y} 
                    width={shape.data.width} 
                    height={shape.data.height} 
                    onTransformEnd={(e: any) => {
                        onTransformEnd(e, shapeRef.current)
                    }}
                />

            case 'LINE': 
                return <Line
                    {...shapeProps}
                    ref={shapeRef}
                    points={shape.data.points}
                    tension={0.5}
                    lineCap="round"
                    lineJoin="round"
                    closed={!isEditingLast}
                    onTransformEnd={(e: any) => {
                        onTransformEnd(e, shapeRef.current)
                    }}
                />
        }
    }

    return (
        <React.Fragment>
            {renderShape(shape)}
            {isSelected ? (
                <Transformer
                    ref={transformer}
                    rotateEnabled={false}
                    ignoreStroke={true}
                    keepRatio={shape.data.type === 'CIRCLE'}
                    // centeredScaling={true}
                    enabledAnchors={shape.data.type == 'CIRCLE' ? [
                        'top-left',
                        'top-right',
                        'bottom-left',
                        'bottom-right',
                    ]: undefined}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            ): null}
        </React.Fragment>
  );
}

export default ShapeEdit;
