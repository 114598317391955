import React, {useState} from 'react';
import RoleSwitch from 'components/SessionSwitches/RoleSwitch';
import OrganizationSwitch from 'components/SessionSwitches/OrganizationSwitch';
import LanguageSwitch from 'components/LanguageSwitch';
import NotificationDropdown from 'components/GeneralMessaging/NotificationDropdown';
import jwt from 'utils/jwt';
import {NavLink} from "react-router-dom";
import CertModal from "../LearningProgram/Students/CertModal";
import StudentManualModal from "../StudentManualModal/StudentManualModal";

const HeaderMenu: React.FC<any> = ({ selectedOrganization, customerOptions, organizationOptions,
    setSelectedOrganizationType, setSelectedOrganization, roles, setSelectedUserTypeId, session, environment, switchToStudentRole,
    switchToAdministrationEnvironment, clearSession, isMobile}) => {
    const [showManualModal, setShowManualModal] = useState(false)

    return (
        <>
            <OrganizationSwitch
                selectedOrganization={selectedOrganization}
                customers={customerOptions}
                organizations={organizationOptions}
                isMobile={isMobile}
                onClick={(value: number, type: any) => {
                    setSelectedOrganizationType(type);
                    setSelectedOrganization(value)
                }}
            />

            <RoleSwitch
                roles={roles}
                isMobile={isMobile}
                onClick={(value: number) => setSelectedUserTypeId(value)}
                selectedUserTypeId={session.active_user_type_id}
                usersName={`${session.name} ${session.surname}`}
            />

            <LanguageSwitch
                isMobile={isMobile}
            />
            <NotificationDropdown
                isMobile={isMobile}
            />
            {/*{environment === 'ADMINISTRATION'*/}
            {/*    ? <NavLink to='/'><i className='fal fa-book header-item' onClick={() => switchToStudentRole()}/></NavLink>*/}
            {/*    : (!(['STUDENT', 'LECTOR'].includes(session.active_user_type)) ?*/}
            {/*        <NavLink to='/'><i className='fal fa-user-cog header-item' onClick={() => switchToAdministrationEnvironment()}/></NavLink> : null)*/}
            {/*}*/}
            {/* TODO */}
            {/* Kamēr nav funkcionalitāte tikmēr nerādīt */}
            {/*{<i className='fal fa-bell header-item' /><i className='fal fa-cog header-item' />}*/}

            {
                session.active_user_type !== 'STUDENT'
                    ?
                    <NavLink to='/support'>
                        <i className='fal fa-question-circle header-item'/>
                    </NavLink>
                    :
                    <div onClick={() => setShowManualModal(true)}
                         className={isMobile ? 'session-switch-item-mobile' : 'session-switch-item p-0'}>
                        <div className='role-switch notifications-dropdown'>
                            <i onClick={() => setShowManualModal(true)} className='fal fa-question-circle'/>
                        </div>
                    </div>
            }
            {
                isMobile ?
                    <div className="session-switch-item-mobile">
                        <div className="role-switch" onClick={() => {
                            jwt.logout(session.logout);
                            clearSession()
                        }}>
                            <span>Logout<i className='fal fa-sign-out header-item'/></span>
                        </div>
                    </div>
                :
                    <i className='fal fa-sign-out header-item' onClick={() => {
                        jwt.logout(session.logout);
                        clearSession()
                    }}/>
            }
            <StudentManualModal open={showManualModal} onCancel={setShowManualModal} />
        </>
    )
};
export default HeaderMenu;
