import React from 'react';
import {Modal as AntdModal, ModalProps} from 'antd';

interface ModalInterface extends ModalProps {
    isLoading?: boolean;
    children?: React.ReactNode;
}

const Modal: React.FC<ModalInterface> = ({
                                             isLoading = false,
                                             children,
                                             ...props
                                         }) => {
    return (
        <AntdModal
            okButtonProps={{ loading: isLoading }}
            {...props}>
            {children}
        </AntdModal>
    )
};

export default Modal;
