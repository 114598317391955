import React, {useRef, useEffect} from "react";
import {Button, Modal} from 'antd';
import {FormattedMessage, useIntl} from 'react-intl';
import "plyr-react/plyr.css";
import {ExclamationCircleOutlined} from "@ant-design/icons";

const VdocipherComponent = ({video, endVideoSession, endLoading, finish}: any) => {
    const iframeRef = useRef<HTMLIFrameElement>(null)
    const intl = useIntl();
    const {confirm} = Modal;

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://player.vdocipher.com/v2/api.js";
        document.body.appendChild(script);
    }, [])

    useEffect(() => {
        // @ts-ignore
        if (window?.VdoPlayer) {
            // @ts-ignore
            const player = window.VdoPlayer.getInstance(iframeRef.current);

            if (player?.video) {
                if (video.settings.view_all_to_complete) {
                    player?.video?.addEventListener("ended", function () {
                        endVideoSession(true)
                    });
                }
            }
        }

    }, [iframeRef.current]);

    const handleFinish = () => {
        if (video.settings.view_all_to_complete) {
            confirm({
                title: intl.formatMessage({id: 'general.attention'}),
                content: intl.formatMessage({id: 'student.video_warning'}),
                icon: <ExclamationCircleOutlined/>,
                okText: intl.formatMessage({id: 'general.yes'}),
                cancelText: intl.formatMessage({id: 'general.cancel'}),
                onOk() {
                    endVideoSession(false)
                }
            });
        } else {
            endVideoSession()
        }
    }

    return (
        <>
            <div className={'video-player-container'}>
                <iframe
                    style={{
                        height: "100%",
                        width: "100%"
                    }}
                    ref={iframeRef}
                    src={video.videoId}
                    allowFullScreen allow="encrypted-media"></iframe>
            </div>
            <div className='buttons-container'>
                <div></div>
                <Button loading={endLoading} onClick={() => handleFinish()}>
                    <FormattedMessage id='courses.finish'/>
                </Button>
            </div>
        </>
    );
};

export default VdocipherComponent;
