import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import PermissionRoute from 'components/PermissionRoute';

/** ADMINISTRATION ROUTES */
import Dashboard from 'pages/Dashboard';
import OrganizationList from 'pages/Organizations/OrganizationList';
import OrganizationForm from 'pages/Organizations/OrganizationForm';
import OrganizationAgreementForm from 'pages/Organizations/AgreementForm';
import OrganizationAdminList from 'pages/Organizations/AdminList';
import OrganizationAdminForm from 'pages/Organizations/AdminForm';
import CustomerList from 'pages/Customers/CustomerList';
import CustomerForm from 'pages/Customers/CustomerForm';
import CustomerAgreementForm from 'pages/Customers/AgreementForm';
import UserListTest from 'pages/Users/UsersList_test';
import UserForm from 'pages/Users/UserForm';
import EmailScenarioForm from 'pages/Emailing/EmailScenarioForm';
import EmailTemplateFormStandard from 'pages/Emailing/EmailTemplateFormStandard';
import EmailTemplateFormCustomer from 'pages/Emailing/EmailTemplateFormCustomer';
import EmailTemplateFormOrganization from 'pages/Emailing/EmailTemplateFormOrganization';
import EmailTemplateList from 'pages/Emailing/EmailTemplateList';
import EmailHistoryList from 'pages/Emailing/EmailHistoryList';
import SendEmailScenarios from 'pages/Emailing/SendEmailScenarios';
import PageUnderConstruction from 'pages/PageUnderConstruction';
import ScenaroHistoryList from 'pages/Emailing/ScenaroHistoryList';
import PermissionsList from 'pages/System/PermissionsList';
import ManageContent from 'pages/Courses/ManageContent';
import CourseEdit from 'pages/Courses/CourseEdit';
import ManageELearning from 'pages/Courses/ManageELearning';
import NotAllowed from 'pages/ErrorPages/NotAllowed';
import ServiceList from 'pages/Licences/ServiceList';
import ServiceForm from 'pages/Licences/ServiceForm';
import LicenceList from 'pages/Licences/LicenceList';
import LicenceEdit from 'pages/Licences/LicenceEdit';



// import PhishingTemplateForm from 'pages/Phishing/PhishingTemplateForm';
import ManageLanguages from 'pages/System/ManageLanguages';
import CustomizeEnvironment from '../../pages/System/CustomizeEnvironment';
import ResourcesList from 'pages/System/Resources/List';
import ResourcesBusyness from 'pages/System/Resources/Busyness';
import ResourcesCalendar from 'pages/System/Resources/Resource';
import Audit from '../../pages/System/Audit';
import ContentForm from 'pages/Content/ContentForm';
import ContentList from 'pages/Content/ContentList';
import VideoInstructions from "pages/System/VideoInstructions";
import TermsAndConditions from 'pages/TermsAndConditions/TermsAndConditions';
import PrivacyPolicy from 'pages/PrivacyPolicy/PrivacyPolicy';
import TermsAndConditionsEdit from 'pages/TermsAndConditions/TermsAndConditionsEdit';
import UserOrganizations from 'pages/Customers/UserOrganizations';
import LearningMaterial from 'pages/LearningMaterial';
import CampaignForm from 'pages/Campaign';
import CompetencesList from "pages/Competences/CompetencesList";
import CertificatesList from "pages/Certificates/CertificatesList";
import CompetencesForm from "pages/Competences/CompetencesForm";

/** QUESTION POOL ROUTES */
import QuestionPoolList from 'pages/QuestionPools/QuestionPoolList';
import QuestionPoolEdit from 'pages/QuestionPools/QuestionPoolEdit';
import QuestionPoolBankEdit from 'pages/QuestionPools/QuestionPoolBankEdit';

/** LEARNING ROUTES */
import ManageFilters from 'pages/System/ManageFilters';
import StudentCampaignActivityList from 'learningPages/Campaign/StudentCampaignActivityList';
import ShowScorm from 'learningPages/Campaign/ShowScorm';
import ContentView from 'pages/Content/ContentView';
import ActivityResources from 'learningPages/Campaign/ActivityResources';
import StudentDashboard from "learningPages/StudentDashboard";
import Materials from "learningPages/StudentDashboard/Materials";

import ConnectionSettings from 'pages/System/ConnectionSettings';
import LearningProgramList from "pages/LearningProgram/List";
import LearningProgramForm from "pages/LearningProgram/Form";
import LearningTestList from "pages/LearningTest/List";
import LearningTestEdit from "pages/LearningTest/Edit";
import LearningTestOverview from "pages/LearningTest/Overview";

import Audience from "pages/Audiences/Audience";
import AudiencesList from "pages/Audiences/List";

import Lecture from "pages/Lectures/Lecture";
import LecturesList from "pages/Lectures/LecturesList";
import LectureSession from "pages/Lectures/LectureSession";
import MyLectures from "pages/Lectures/MyLectures";
import MySession from "pages/Lectures/MySession";
import MyCertificates from "pages/Certificates/MyCertificates";
import StudentLecture from "../../learningPages/StudentDashboard/StudentLecture";
import GradingList from "pages/Grading/List";
import ReportsDashboard from "../../pages/Reports/Dashboard";
import MyDepartment from "pages/Department";

import GeneralMessagingList from "pages/GeneralMessaging/List";
import GeneralMessagingEdit from "pages/GeneralMessaging/Edit";
import Customer from "../../pages/Customer";

/** PHISHING ROUTES */
import PhishingTemplatesList from 'pages/Phishing/Templates/List';
import PhishingTemplatesForm from "pages/Phishing/Templates/Form";
import PhishingCampaignList from "pages/Phishing/Campaign/List";
import PhishingCampaignForm from "pages/Phishing/Campaign/Form";
import PhishingAnalytics from "pages/Phishing/Analytics";

/** RULES ROUTES */
import RulesList from "pages/Rules/List";
import Rule from "pages/Rules/Rule";
import PhishingTab from "../../pages/Customers/CustomerUsersEdit/PhishingTab";
import {config} from "../../config/config";
import AcademiesList from "../../pages/Academies/List";
import Academy from "../../pages/Academies/Academy";

// STUDENT ROUTES
import MyAcademy from "pages/Student/MyAcademy";

const mapStateToProps = (state: any) => {
  return {
    environment: state.environment.environment
  }
};

const Router: React.FC<any> = ({ environment }) => {

  if (environment === 'ADMINISTRATION') {
    return (
      <Routes>
        <Route path="/" element={<PermissionRoute><Dashboard /> </PermissionRoute>} />

        {/*  users */}
        <Route path="/users" element={<PermissionRoute permissionSlug='view_users'><UserListTest /> </PermissionRoute>} />

        {/*  organizations */}
        <Route path="/organizations" element={<PermissionRoute permissionSlug='view_organizations,view_customers'><OrganizationList /></PermissionRoute>} />
        <Route path="/organizations/add" element={<PermissionRoute permissionSlug='create_organizations' specialRight='can_add_new_resellers'><OrganizationForm /></PermissionRoute>} />
        <Route path="/organizations/:id/edit" element={<PermissionRoute permissionSlug='update_organizations'><OrganizationForm /></PermissionRoute>} />
        <Route path="/organizations/:organizationId/agreement" element={<PermissionRoute permissionSlug='view_agreements' specialRight='can_manage_agreements'><OrganizationAgreementForm /></PermissionRoute>} />
        <Route path="/organizations/:organizationId/admins/add" element={<PermissionRoute permissionSlug='create_admins' specialRight='can_manage_admins' ><OrganizationAdminForm /></PermissionRoute>} />
        <Route path="/organizations/:organizationId/admins/:uuid/edit" element={<PermissionRoute permissionSlug='update_admins' specialRight='can_manage_admins'><OrganizationAdminForm /></PermissionRoute>} />
        <Route path="/organizations/:organizationId/admins" element={<PermissionRoute permissionSlug='view_admins' specialRight='can_manage_admins'><OrganizationAdminList /></PermissionRoute>} />


        <Route path="/customers/:id/:tab?" element={<PermissionRoute permissionSlug='view_customers' specialRight='can_manage_admins'><Customer /></PermissionRoute>} />


        {/* customer paskatīties tur haoss ienest arī agreement ?*/}
        <Route path="/customers" element={<PermissionRoute permissionSlug='view_customers' specialRight='can_manage_admins'><CustomerList /></PermissionRoute>} />
        <Route path="/customers/add" element={<PermissionRoute permissionSlug='create_customers' specialRight='can_add_new_customers'><CustomerForm /> </PermissionRoute>} />
        <Route path="/customers/:id/edit" element={<PermissionRoute permissionSlug='update_admins' specialRight='can_manage_customers'><CustomerForm /></PermissionRoute>} />
        <Route path="/customers/:customerId/agreement" element={<PermissionRoute permissionSlug='view_customer_agreements' specialRight='can_manage_agreements'><CustomerAgreementForm /></PermissionRoute>} />
        <Route path="/customers/:id/users/:backPath" element={<PermissionRoute permissionSlug='view_customers_users'><UserListTest /></PermissionRoute>} />
        <Route path="/customer/:customerId/user/:uuid/view/:backPath" element={<PermissionRoute permissionSlug='view_users'><UserForm /> </PermissionRoute>} />
        <Route path="/customer/:customerId/user/add/:role" element={<PermissionRoute permissionSlug='create_users'><UserForm /></PermissionRoute>} />


          {/* campaign routes */}
        <Route path="/courses/manage-campaigns" element={<PermissionRoute permissionSlug='view_campaigns'><ManageELearning /></PermissionRoute>} />
        {/* <Route path="/campaign/create/:step" element={<PermissionRoute permissionSlug='create_campaigns'><CampaignForm /></PermissionRoute>} />
        <Route path="/campaign/create/:id/:step" element={<PermissionRoute permissionSlug='create_campaigns'><CampaignForm /></PermissionRoute>} />
        <Route path="/campaign/:id/:step" element={<PermissionRoute permissionSlug='view_campaigns'><CampaignForm /></PermissionRoute>} /> */}


        {/*  program routes TODO add permissions */}
          <Route path="/learning/programs" element={<PermissionRoute permissionSlug=''><LearningProgramList /></PermissionRoute>} />
          <Route path="/learning/programs/create" element={<PermissionRoute permissionSlug=''><LearningProgramForm /></PermissionRoute>} />
          <Route path="/learning/programs/:id/:tab?" element={<PermissionRoute permissionSlug=''><LearningProgramForm /></PermissionRoute>} />

          {/*  program routes TODO add permissions */}
          <Route path="/reports/:report?" element={<PermissionRoute permissionSlug=''><ReportsDashboard /></PermissionRoute>} />

          <Route path="/rules" element={<PermissionRoute permissionSlug='view_rules'><RulesList /></PermissionRoute>} />
          <Route path="/rules/create" element={<PermissionRoute permissionSlug='view_rules'><Rule /></PermissionRoute>} />
          <Route path="/rules/:id" element={<PermissionRoute permissionSlug='view_rules'><Rule /></PermissionRoute>} />

          <Route path="/competences" element={<PermissionRoute permissionSlug=''/*view_competences*/ ><CompetencesList /></PermissionRoute>} />
          <Route path="/competences/create" element={<PermissionRoute permissionSlug=''/*create_competences*/ ><CompetencesForm /></PermissionRoute>} />
          <Route path="/competences/:id" element={<PermissionRoute permissionSlug=''/*update_competences*/ ><CompetencesForm /></PermissionRoute>} />

          <Route path="/courses/manage-content" element={<PermissionRoute permissionSlug='view_courses' specialRight='can_manage_courses,can_add_own_content'><ManageContent /></PermissionRoute>} />
        <Route path="/courses/:courseId/edit" element={<PermissionRoute permissionSlug='edit_courses' specialRight='can_manage_courses,can_add_own_content'><CourseEdit /></PermissionRoute>} />
        <Route path="/courses/:courseId/material/:activityId" element={<PermissionRoute permissionSlug='edit_courses' specialRight='can_manage_courses,can_add_own_content'><LearningMaterial /></PermissionRoute>} />

        {/* Question Pool Routes */}
        <Route path="/question-pools/manage-content" element={<PermissionRoute permissionSlug='' /*view_question_pools*/ specialRight='' /*can_manage_question_pools,can_add_own_content*/><QuestionPoolList/></PermissionRoute>} />
        <Route path="/question-pools/:id/edit" element={<PermissionRoute permissionSlug=''/*edit_question_pools*/ specialRight=''/*can_manage_question_pools,can_add_own_content*/><QuestionPoolEdit /></PermissionRoute>} />
        <Route path="/question-pools/question-banks/:id/edit/:question?" element={<PermissionRoute permissionSlug=''/*edit_question_pools*/ specialRight='' /*can_manage_question_pools,can_add_own_content*/><QuestionPoolBankEdit /></PermissionRoute>} />

        {/* Learning Test Routes : TODO add permissions */}
        <Route path="/learning/tests" element={<PermissionRoute permissionSlug=''><LearningTestList/></PermissionRoute>} />
        <Route path="/learning/tests/:id/edit" element={<PermissionRoute permissionSlug=''><LearningTestEdit /></PermissionRoute>} />
        <Route path="/learning/tests/:id/overview" element={<PermissionRoute permissionSlug=''><LearningTestOverview /></PermissionRoute>} />

        {/* Grading Routes : TODO add permissions */}
        <Route path="/learning/grading" element={<PermissionRoute permissionSlug=''><GradingList/></PermissionRoute>} />

        {/* General Messaging Routes : TODO add permissions */}
        <Route path="/general-messaging" element={<PermissionRoute permissionSlug=''><GeneralMessagingList specialRight='can_manage_general_messaging'/></PermissionRoute>} />
        <Route path="/general-messaging/create" element={<PermissionRoute permissionSlug=''><GeneralMessagingEdit specialRight='can_manage_general_messaging'/></PermissionRoute>} />
        <Route path="/general-messaging/:id" element={<PermissionRoute permissionSlug=''><GeneralMessagingEdit specialRight='can_manage_general_messaging'/></PermissionRoute>} />

        <Route path="/competences" element={<PermissionRoute permissionSlug=''/*view_competences*/ ><CompetencesList /></PermissionRoute>} />
        <Route path="/competences/create" element={<PermissionRoute permissionSlug=''/*create_competences*/ ><CompetencesForm /></PermissionRoute>} />
        <Route path="/competences/:id" element={<PermissionRoute permissionSlug=''/*update_competences*/ ><CompetencesForm /></PermissionRoute>} />

        {/*Certificates TODO add permissions*/}
        <Route path="/certificates" element={<PermissionRoute permissionSlug=''/*view_certificates*/ ><CertificatesList /></PermissionRoute>} />

        <Route path="/admins/add" element={<PermissionRoute permissionSlug='create_admins' specialRight='can_manage_admins'> <OrganizationAdminForm /></PermissionRoute>} />
        <Route path="/e-learning/add-content" element={<PermissionRoute permissionSlug='create_courses' specialRight='can_manage_courses'> <PageUnderConstruction /></PermissionRoute>} />
        <Route path="/e-learning/apply-for-new-content" element={<PermissionRoute> <PageUnderConstruction /></PermissionRoute>} />


        {/*  PHISHING ROUTES*/}
        {/*  SIMULATIONS*/}
        <Route path="/phishing/templates" element={<PermissionRoute permissionSlug='create_phishing_templates'> <PhishingTemplatesList /></PermissionRoute>} />
        <Route path="/phishing/templates/add" element={<PermissionRoute permissionSlug='create_phishing_templates'> <PhishingTemplatesForm /></PermissionRoute>} />
        <Route path="/phishing/templates/:id/:tab?" element={<PermissionRoute permissionSlug='create_phishing_templates'> <PhishingTemplatesForm /></PermissionRoute>} />
        {/*SIMULATIONS END */}

        <Route path="/phishing" element={<PermissionRoute permissionSlug='view_phishing'> <PhishingCampaignList /></PermissionRoute>} />
        <Route path="/phishing/campaign/add" element={<PermissionRoute permissionSlug='create_phishing'> <PhishingCampaignForm /></PermissionRoute>} />
        <Route path="/phishing/campaign/:id/:tab?" element={<PermissionRoute permissionSlug='edit_phishing'><PhishingCampaignForm /></PermissionRoute>} />
        <Route path="/phishing/analytics" element={<PermissionRoute permissionSlug='view_phishing'><PhishingAnalytics /></PermissionRoute>} />


        {/*<Route path="/phishing/campaign/dep" element={<PermissionRoute permissionSlug='create_phishing'> <CustomPhishingCampaignForm /></PermissionRoute>} />*/}
        {/*PHISHING END */}


        <Route path="/e-mailing/email-scenarios/add" element={<PermissionRoute permissionSlug='create_mail_scenarios'><EmailScenarioForm /></PermissionRoute>} />
        <Route path="/e-mailing/email-scenarios" element={<PermissionRoute permissionSlug='view_mail_scenarios'><SendEmailScenarios /></PermissionRoute>} />

          {/*vai mail scenāriju edits ir ?*/}
          {/*<Route path="/e-mailing/email-scenarios/:id/edit" element={<PermissionRoute permissionSlug='update_mail_scenarios'><EmailScenarioForm /></PermissionRoute>} />*/}

        <Route path="/e-mailing/manage-templates" element={<PermissionRoute permissionSlug='view_mail_templates'><EmailTemplateList /></PermissionRoute>} />
        <Route path="/e-mailing/manage-templates/add" element={<PermissionRoute permissionSlug='create_mail_templates'><EmailTemplateFormStandard /></PermissionRoute>} />
        <Route path="/e-mailing/manage-templates/:id/edit" element={<PermissionRoute permissionSlug='update_mail_templates'><EmailTemplateFormStandard /></PermissionRoute>} />
        <Route path="/e-mailing/manage-templates/organization/add" element={<PermissionRoute permissionSlug='create_mail_templates' specialRight='can_edit_email_templates'><EmailTemplateFormOrganization /></PermissionRoute>} />
        <Route path="/e-mailing/manage-templates/organization/:id/edit" element={<PermissionRoute permissionSlug='update_mail_templates' specialRight='can_edit_email_templates'><EmailTemplateFormOrganization /></PermissionRoute>} />
        <Route path="/e-mailing/manage-templates/customer/add" element={<PermissionRoute permissionSlug='create_mail_templates' specialRight='can_edit_email_templates'><EmailTemplateFormCustomer /></PermissionRoute>} />
        <Route path="/e-mailing/manage-templates/customer/:id/edit" element={<PermissionRoute permissionSlug='update_mail_templates' specialRight='can_edit_email_templates'><EmailTemplateFormCustomer /></PermissionRoute>} />

        <Route path="/e-mailing/history" element={<PermissionRoute permissionSlug='view_mail_scenarios'><EmailHistoryList /></PermissionRoute>} />
        <Route path="/e-mailing/:id/history" element={<PermissionRoute permissionSlug='view_mail_scenarios'><ScenaroHistoryList /></PermissionRoute>} />
        <Route path="/analytics/e-learning" element={<PageUnderConstruction />} />
        <Route path="/analytics/gamification" element={<PageUnderConstruction />} />
        <Route path="/analytics/reports" element={<PageUnderConstruction />} />
        <Route path="/licensing/manage-services" element={<PermissionRoute permissionSlug='view_services' specialRight='can_manage_services'><ServiceList /></PermissionRoute>} />
        <Route path="/licensing/manage-services/add" element={<PermissionRoute permissionSlug='create_services' specialRight='can_manage_services'><ServiceForm /></PermissionRoute>} />
        <Route path="/licensing/manage-services/:id/edit" element={<PermissionRoute permissionSlug='edit_services' specialRight='can_manage_services'><ServiceForm /></PermissionRoute>} />
        <Route path="/licensing/manage-licences" element={<PermissionRoute permissionSlug='view_licences'><LicenceList /></PermissionRoute>} />
        <Route path="/licensing/manage-licences/:id/edit" element={<PermissionRoute permissionSlug='view_licences'><LicenceEdit /></PermissionRoute>} />
        <Route path="/subscriptions/assign-licences" element={<PageUnderConstruction />} />
        <Route path="/subscriptions/my-subscription-licences" element={<PageUnderConstruction />} />
        <Route path="/system/manage-languages" element={<ManageLanguages />} />
        <Route path="/system/auditing" element={<PermissionRoute permissionSlug='view_global_logs,view_organization_logs,view_customer_logs'><Audit /></PermissionRoute>} />
        <Route path="/system/permissions" element={<PermissionRoute permissionSlug='view_permissions'><PermissionsList /></PermissionRoute>} />

        <Route path="/system/manage-filters" element={<PermissionRoute permissionSlug='create_users_filters'><ManageFilters /></PermissionRoute>} />
        <Route path="/system/connection" element={<PermissionRoute permissionSlug='edit_connection' specialRight='can_manage_customer_connection'><ConnectionSettings /></PermissionRoute>} />

        <Route path="/system/customize-environment" element={<PermissionRoute permissionSlug='can_edit_environment'><CustomizeEnvironment /></PermissionRoute>} />
        <Route path="/system/resource-classifiers" element={<PermissionRoute permissionSlug=''><ResourcesList /></PermissionRoute>} />
        <Route path="/system/resource-busyness" element={<PermissionRoute permissionSlug=''><ResourcesBusyness /></PermissionRoute>} />
        <Route path="/system/resource-calendar" element={<PermissionRoute permissionSlug=''><ResourcesCalendar /></PermissionRoute>} />
        <Route path="/content" element={<PermissionRoute permissionSlug='view_content'><ContentList /></PermissionRoute>} />
        <Route path="/content/add/:type" element={<PermissionRoute permissionSlug='create_content'><ContentForm /></PermissionRoute>} />
        <Route path="/content/:id/edit" element={<PermissionRoute permissionSlug='create_content'><ContentForm /></PermissionRoute>} />
        <Route path="/content/:id/view" element={<PermissionRoute permissionSlug='view_content'><ContentView /></PermissionRoute>} />
        <Route path="/support" element={<VideoInstructions />} />
        <Route path="/user/organizations" element={<PermissionRoute specialRight='can_manage_customers'> <UserOrganizations /></PermissionRoute>} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions/edit" element={<TermsAndConditionsEdit />} />
        <Route path="/privacy-policy/edit" element={<TermsAndConditionsEdit />} />
        <Route path="/403" element={<NotAllowed />} />
        {/* <Route path="*" element={<Dashboard />} /> */}

        <Route path="/audiences" element={<PermissionRoute permissionSlug=''><AudiencesList /></PermissionRoute>} />
        <Route path="/audiences/:id" element={<PermissionRoute permissionSlug=''><Audience /></PermissionRoute>} />

        <Route path="/lectures" element={<PermissionRoute permissionSlug=''><LecturesList /></PermissionRoute>} />
        <Route path="/lectures/:id" element={<PermissionRoute permissionSlug=''><Lecture /></PermissionRoute>} />
        <Route path="/lectures/:lecturesId/sessions/:id" element={<PermissionRoute permissionSlug=''><LectureSession /></PermissionRoute>} />

        {/*<Route path="/academy" element={<PermissionRoute permissionSlug=''><AcademiesList /></PermissionRoute>} />*/}
        {/*<Route path="/academy/:id/:tab?" element={<PermissionRoute permissionSlug=''><Academy /></PermissionRoute>} />*/}
      </Routes>
    )
  }

  return (
    <Routes>
      <Route path="/student/:enrollmentId/activity-list/:id" element={<StudentCampaignActivityList />} />
      {/*<Route path="/student/:enrollmentId/activity-list/:id/resources/:activityId" element={<ActivityResources />} />*/}
      <Route path="/student/show-activity" element={<ShowScorm />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/my-lectures" element={<MyLectures />} />
      {/*<Route path="/academy" element={<MyAcademy />} />*/}
      <Route path="/my-lectures/:lecturesId" element={<MyLectures />} />
      <Route path="/my-lectures/:lecturesId/session/:id" element={<MySession />} />
      <Route path="/my-certificates" element={<MyCertificates />} />
      {config.api.environment === 'development' &&
          <Route path="/my-phishing" element={<PhishingTab/>} />
      }
      <Route path="/" element={<StudentDashboard />} />
      <Route path="/materials/:id" element={<Materials />} />
      <Route path="/materials/:id/lecture/:lectureId" element={<StudentLecture />} />
      <Route path="/my-department" element={<MyDepartment />} />
      {/* <Navigate to="/" /> */}
    </Routes>
  )
};

export default connect(mapStateToProps)(Router);
