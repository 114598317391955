import React, {useEffect} from 'react';
import {Form, Input, Select} from 'antd';
import Flag from '../CountryFlags';
import './styles.scss';

const PhoneNumberSelect: React.FC<any> = ({
                                              layout,
                                              label,
                                              name,
                                              hasDefaultLayout,
                                              defaultCode,
                                              wrapperClassName,
                                              form,
                                              ...props
                                          }) => {

    const countryCodes = {
        "1": "us",
        "7": "ru",
        "48": "pl",
        "49": "de",
        "370": "lt",
        "371": "lv",
        "372": "ee",
        "381": "rs",
        "385": "hr",
        "387": "ba",
        "998": "uz"
    }
    let formItemLayout = layout || {}
    const {Option} = Select;

    if (hasDefaultLayout) {
        formItemLayout = {
            labelCol: {span: 6},
            wrapperCol: {span: 18}
        }
    }

    useEffect(() => {
        if (defaultCode) {
            form.setFieldsValue({
                code: defaultCode
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultCode])

    const selectBefore = (
        <Form.Item name='code' initialValue={defaultCode}>
            <Select defaultValue={defaultCode} className="select-before">
                {Object.entries(countryCodes).map((code: any) =>
                    <Option key={code[0]} value={code[0]}><Flag languageCode={code[1].toUpperCase()}/> +{code[0]}
                    </Option>
                )}
            </Select>
        </Form.Item>
    );

    return (
        <Form.Item name={name} {...formItemLayout} className={wrapperClassName + ' phone-select-wrapper'}
                   label={label && label}>
            <Input addonBefore={selectBefore} {...props} />
        </Form.Item>
    )
};

export default PhoneNumberSelect;
