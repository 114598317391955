import React, {useState, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {NavLink, useNavigate, useParams} from 'react-router-dom';
import useHandleError from "utils/useHandleError";
import {Table} from "ui";
import {message, Modal, Popover, Form, Button, Tag, Space} from "antd";
import {ExclamationCircleOutlined, PlusOutlined} from '@ant-design/icons';
import coursesApiClient from 'utils/coursesApiClient';
import FlexRow from "../../../components/FlexRow";
import {Input as CustomInput} from 'components/Form';
import Switch from 'components/Form/Switch';

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale,
        session: state.session
    };
};
const formItemLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
};

interface QuestionBanksInterface {
    questionPool: any,
    locale?: any;
    session?: any;
}

const QuestionBanks: React.FC<QuestionBanksInterface> = ({ locale, session, questionPool }) => {
    const [reload, setReload] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [visibleTooltip, setVisibleTooltip] = useState<number | null>(null);
    const [showAddNewQuestionBank, setShowAddNewQuestionBank] = useState(false);
    const [isSwitchLoading, setIsSwitchLoading] = useState(false);
    const [isSubmitProgress, setIsSubmitProgress] = useState(false);

    const intl = useIntl();
    const params = useParams();
    const [handleError] = useHandleError();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const questionPoolId = params.id;
    const { confirm } = Modal;

    const handleVisibleChange = (visible: boolean, recordId: number) => {
        if (visible) {
            setVisibleTooltip(recordId);
        } else {
            setVisibleTooltip(null);
        }
    };

    const onSwitchChange = async (record: any) => {
      try {
        setIsSwitchLoading(true);
  
        record.isOnline = !record.isOnline;
  
        await coursesApiClient.request(`/api/v1/courses/question-pools/question-banks/${record.id}/update`, record, 'PUT');
  
        const messageId = 'question_pools.question_bank_type_updated';
  
        message.success(intl.formatMessage({id: messageId}), 7);
      } catch (err) {
        console.error(err);
        message.error(intl.formatMessage({id: 'error.action_failed'}));
      } finally {
        setIsSwitchLoading(false);
      }
    }

    const submitForm = async (values: any) => {
      setIsSubmitProgress(true);

      let parsedValues = {
        ...values,
        isOnline: 0,
        questionPoolId: questionPoolId,
      }

      try {
        let responese = await coursesApiClient.request(`/api/v1/courses/question-pools/question-banks/store`, parsedValues, 'POST');
        navigate(`/question-pools/question-banks/${responese.question_bank.id}/edit`);
      } catch (error) {
          handleError(error)
      } finally {
        setIsSubmitProgress(false);
      }
    }

    const handleSwitch = async (record: any) => {
        try {
            setIsSwitchLoading(true);
            if( record.isOnline ) {
                const response = await coursesApiClient.request(`/api/v1/courses/question-pools/question-banks/${record.id}/active-learning-tests`, {}, 'GET');
                if( response.titles.length > 0 ) {
                    confirm({
                        title: intl.formatMessage({ id: 'general.attention' }),
                        content: intl.formatMessage({
                            id: 'question_pools.warning_bank_offline',
                            defaultMessage: 'Question bank is used in following tests: {learning_tests}. When status is set to offline, questions from the question bank will not be available for these tests. Do you want to proceed?'
                        }, {
                            learning_tests: response.titles.join(', ')
                        }),
                        icon: <ExclamationCircleOutlined />,
                        okText: intl.formatMessage({ id: 'general.yes' }),
                        cancelText: intl.formatMessage({ id: 'general.no' }),

                        onOk() {
                            updateQuestionBankStatus(record);
                        },
                        onCancel() {
                            setIsSwitchLoading(false);
                        }
                    });
                } else {
                    updateQuestionBankStatus(record);
                }
            } else {
                updateQuestionBankStatus(record);
            }

        } catch (err) {
            console.error(err);
            message.error(intl.formatMessage({id: 'error.action_failed'}));
        } finally {
            setIsSwitchLoading(false);
        }
    }

    const updateQuestionBankStatus = async (record:any) => {
        try {
            setIsSwitchLoading(true);

            const status = !record.isOnline;

            await coursesApiClient.request(`/api/v1/courses/question-pools/question-banks/${record.id}/update-status`, {status: status}, 'POST');
            setReload(true)
            message.success(intl.formatMessage({id: 'question_pools.question_bank_status_updated'}), 7);
        } catch (err) {
            console.error(err);
            message.error(intl.formatMessage({id: 'error.action_failed'}));
        } finally {
            setIsSwitchLoading(false);
        }
    }
  
    const columns = [
      {
        title: intl.formatMessage({id: 'question_pools.question_bank'}),
        dataIndex: 'name',
        key: 'name',
        render: (text: string, record: any) => {
          return (
              <NavLink
                to={
                    `/question-pools/question-banks/${record.id}/edit`
                }
              >
                {record.title}
              </NavLink>
            )
        }
      },
      {
        title: intl.formatMessage({id: 'general.id'}),
        dataIndex: 'id ',
        key: 'id ',
        render: (text: string, record: any) => record.id
      },
      {
        title: intl.formatMessage({id: 'question_pools.questions'}),
        dataIndex: 'questionsCount ',
        key: 'questionsCount ',
        render: (text: string, record: any) => record.questionsCount
      },
      {
        title:
          <div>
            {intl.formatMessage({id: 'general.online'})}
            <Popover content={intl.formatMessage({id: 'question_pools.question_bank_status_explanation'})}>
                <i style={{marginLeft: '3px'}} className="fal fa-question-circle"></i>
            </Popover>
          </div>,
        dataIndex: 'active',
        key: 'active',
        render: (_text: string, record: any) => {
          return (
              <Space size={5}>
                  <Switch
                      key={`question-bank-switch-${record.id}`}
                      onClick={() => handleSwitch(record)}
                      checked={record.isOnline}
                      disabled={isSwitchLoading || !record.editAccess}
                      loading={isSwitchLoading}
                  />

                  <Tag color={record.isOnline ? 'green' : 'red'}>
                      {record.isOnline ? 'ONGOING' : 'DRAFT'}
                  </Tag>
              </Space>
          )
        }
      },
      {
        key: 'actions',
        render: (text: string, record: any, index: number) => {
          const content = <>
            <NavLink
              to={
                `/question-pools/question-banks/${record.id}/edit`
              }
            >
              <div className="popover-item">
                <FormattedMessage id="general.edit"/>
              </div>
            </NavLink>
              <div className="popover-item" onClick={() => handleDelete(record)}>
                  <FormattedMessage id="general.delete"/>
              </div>
          </>;

          return (
            <Popover
              open={index === visibleTooltip}
              content={content}
              trigger="click"
              placement="bottomRight"
              arrowPointAtCenter
              onOpenChange={(visible) => handleVisibleChange(visible, index)}
            >
              <div style={{width: '100%', cursor: 'pointer', textAlign: 'center'}}>
                <i className="fal fa-ellipsis-v" style={{fontSize: '16px'}} />
              </div>
            </Popover>
          )
        },
      }
    ];

    const handleDelete = async (record: any) => {
        setVisibleTooltip(null)

        try {
            const response = await coursesApiClient.request(`/api/v1/courses/question-pools/question-banks/${record.id}/delete`, [], 'DELETE');

            if (!response.deleted) {
                message.error(intl.formatMessage({id: 'error.question_bank_in_use'}));
            } else {
                setReload(true)
            }
        } catch (error) {
            handleError(error)
        } finally {
            setIsSubmitProgress(false);
        }
    }

    return (
        <>
            <FlexRow
                left={
                    <Button type="primary" icon={<PlusOutlined/>} onClick={() => setShowAddNewQuestionBank(true)}>
                        <FormattedMessage id="question_pools.add_new_question_bank" />
                    </Button>
                }
            />
            <Modal
                width={530}
                className="add-new-question-bank"
                visible={showAddNewQuestionBank}
                onCancel={() => setShowAddNewQuestionBank(false)}
                title={intl.formatMessage({ id: 'question_pools.add_new_question_bank' })}
                okText={intl.formatMessage({ id: 'general.submit' })}
                cancelText={intl.formatMessage({ id: 'general.back' })}
                onOk={form.submit}
                okButtonProps={{disabled: isSubmitProgress}}
            >
                <Form form={form} onFinish={submitForm}>
                  <CustomInput 
                      customLayout={formItemLayout} 
                      name="title" 
                      label={intl.formatMessage({ id: 'question_pools.question_bank_title' })} 
                      validation={{ required: true }}
                  />
                  <CustomInput 
                      customLayout={formItemLayout} 
                      name="idKey" 
                      label={<>
                          {intl.formatMessage({ id: 'question_pools.id_key' })}
                          <Popover content={intl.formatMessage({id: 'question_pools.id_key_explanation'})}>
                              <i style={{marginLeft: '3px'}} className="fal fa-question-circle"></i>
                          </Popover>
                          {intl.formatMessage({ id: 'general.optional' })}
                      </>} 
                  />
                </Form>
            </Modal>

            <Table
                columns={columns}
                url={`/api/v1/courses/question-pools/${questionPoolId}/question-banks`}
                setReload={setReload}
                reload={reload}
                rowSelection={false}
                rowKey={'id'}
                setTotalRecords={setTotalRecords}
                search={searchString}
            />
        </>
    );
};

export default connect(mapStateToProps)(QuestionBanks);
