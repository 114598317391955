import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {Checkbox, Form, DatePicker, Radio, Row, Space, TimePicker, message} from 'antd';
import { Title, Button } from 'ui';
import { LectureSessionEventType, LectureSessionRegisteredByType, LectureSessionType, SessionProps, SessionTabProps, LectureSessionIntervalType, SessionLectorProps, SessionResourceProps } from '../../types';
import { Input, Select, Switch, InputNumber, DatePicker as CustomDatePicker } from 'components/Form';
import AddLectors from 'components/Lectures/AddLectors';
import AddResource from 'components/Resources/AddResources';
import DefaultLayout from "components/DefaultLayout";
import { useSession } from "hooks/Lectures/useSession";
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import TemplateEditor from 'components/TemplateEditor';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { ResourceType } from 'components/Resources/types';
import Spinner from 'components/Spinner';
import { useLocation } from 'react-router-dom';
import {config} from "../../../../config/config";
import coursesApiClient from "../../../../utils/coursesApiClient";
import useHandleError from "../../../../utils/useHandleError";
const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 10 },
};

export enum FormChanges {
    Session = 'SESSION',
}

const SessionDetails: React.FC<SessionTabProps> = ({ lecture, session, reloadSession }) => {
    const [formChanges, setFormChanges] = useState<FormChanges[]>([]);
    const [eventType, setEventType] = useState<LectureSessionEventType>(LectureSessionEventType.OneTime);
    const [interval, setInterval] = useState<LectureSessionIntervalType>(LectureSessionIntervalType.Daily);
    const [dayOfTheWeek, setDayOfTheWeek] = useState<CheckboxValueType[]>([]);
    const [dayOfTheMonth, setDayOfTheMonth] = useState<number | null>(null);
    const [limitedPeriod, setLimitedPeriod] = useState<boolean>(false);
    const [capacityLimit, setCapacityLimit] = useState<boolean>(false);
    const intl = useIntl();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { saveSession, sessionLoading, buttonLoading, validateAvailability, getSessionyById } = useSession();
    const [editor, setEditor] = useState<any>();
    const [description, setDescription] = useState<string>('');
    const [customFields, setCustomFields] = useState<any>([]);
    const [handleError] = useHandleError();

    const { RangePicker } = DatePicker;
    const query = new URLSearchParams(useLocation().search);

    useEffect(() => {
        form.resetFields();
        form.resetFields();
        if (!!query.get('copy')) {
            copySession(query.get('copy'));
        } else {
            form.setFieldsValue({
                title: isNew() ? lecture.title : session.title,
                type: session.type,
                date: session.date ? moment(session.date, "YYYY-MM-DD") : moment(),
                time: [
                    session.start ? moment(session.start, "HH:mm") : moment(),
                    session.end ? moment(session.end, "HH:mm") : moment().add(1, 'hours')
                ],
                link: session.link,
                lectors: session.lectors?.map((lector: SessionLectorProps) => lector.id),
                locations: session.locations?.map((resource: SessionResourceProps) => resource.id),
                resources: session.resources?.map((resource: SessionResourceProps) => resource.id),
                licenses: session.licenses?.map((resource: SessionResourceProps) => resource.id),
                registeredBy: session.registeredBy,
                limitedPeriod: !!session.opensBefore || !!session.closesBefore,
                opensBefore: session.opensBefore,
                closesBefore: session.closesBefore,
                remindBefore: session.remindBefore,
                capacityLimit: !!session.capacity,
                capacity: session.capacity,
            });

            if (session.customFields && Object.keys(session.customFields).length > 0) {
                if (session.customFieldValues) {
                    Object.entries(session.customFieldValues).map((field: any) => {
                        const fieldOptions = session.customFields.filter((el: any) => el.uuid === field[0])
                        if (fieldOptions[0].field_type === 'MULTIPLE_CHOICE') {
                            form.setFieldsValue({
                                customFields: {
                                    [field[0]]: field[1]
                                }
                            })
                        } else if (fieldOptions[0].field_type === 'DATE') {
                            form.setFieldsValue({
                                customFields: {
                                    [field[0]]: moment(field[1])
                                }
                            })
                        } else {
                            form.setFieldsValue({
                                customFields: {
                                    [field[0]]: field[1]
                                }
                            })
                        }
                    });
                }
            }

            setLimitedPeriod(!!session.opensBefore || !!session.closesBefore);
            setCapacityLimit(!!session.capacity);

            let defaultDescription = isNew() ? lecture.description : session.description

            setDescription(defaultDescription ? defaultDescription : '');
        }

        setFormChanges([]);


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [session, lecture])

    const loadCustomFields = async () => {
        try {
            const customFields = await coursesApiClient.request('/api/v1/courses/lectures/custom-fields/lecture-custom-fields', [], 'GET');
            setCustomFields(customFields.fields);
        } catch (error) {
            handleError(error)
        } finally {
        }
    }

    useEffect(() => {
        loadCustomFields();
    }, []);

    useEffect(() => {
        onValuesChange({ eventType: eventType });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventType])

    useEffect(() => {
        onValuesChange({ limitedPeriod: limitedPeriod });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limitedPeriod])

    useEffect(() => {
        onValuesChange({ capacityLimit: capacityLimit });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [capacityLimit])

    useEffect(() => {
        onValuesChange({ description: description });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [description])

    useEffect(() => {
        onValuesChange({ interval: interval });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [interval])


    const isNew = (): boolean => !!session && !session.id;

    const copySession = async (sessionId: string | null) => {
        if (!!sessionId && lecture && lecture.id) {
            const session = await getSessionyById(lecture.id, +sessionId);

            if (!!session) {
                form.setFieldsValue({
                    title: session.title,
                    type: session.type,
                    date: session.date ? moment(session.date, "YYYY-MM-DD") : moment(),
                    time: [
                        session.start ? moment(session.start, "HH:mm") : moment(),
                        session.end ? moment(session.end, "HH:mm") : moment().add(1, 'hours')
                    ],
                    link: session.link,
                    lectors: session.lectors?.map((lector: SessionLectorProps) => lector.id),
                    locations: session.locations?.map((resource: SessionResourceProps) => resource.id),
                    resources: session.resources?.map((resource: SessionResourceProps) => resource.id),
                    licenses: session.licenses?.map((resource: SessionResourceProps) => resource.id),
                    registeredBy: session.registeredBy,
                    limitedPeriod: !!session.opensBefore || !!session.closesBefore,
                    opensBefore: session.opensBefore,
                    closesBefore: session.closesBefore,
                    remindBefore: session.remindBefore,
                    capacityLimit: !!session.capacity,
                    capacity: session.capacity,
                });

                setLimitedPeriod(!!session.opensBefore || !!session.closesBefore);
                setCapacityLimit(!!session.capacity);
                setDescription(session.description ? session.description : '' );
            }
        }
    }

    const handleFormSubmit = async (values: any) => {

        interface Fields {
            customFields: {
                [key: string]: string;
            };
        }

        const parsedValues: Fields = {
            customFields: {},
        };

        if (values.customFields !== undefined) {
            parsedValues['customFields'] = values.customFields;
            Object.entries(parsedValues.customFields).map((field: any) => {
                if ((field[1] === null) || (field[1] == undefined) || (field[1] == '')) {
                    delete parsedValues.customFields[field[0]];
                } else {
                    const fieldOptions = customFields.filter((el: any) => el.uuid === field[0])
                    if (fieldOptions[0].field_type === 'DATE') {
                        parsedValues.customFields[field[0]] = field[1].format(config.DBDateFormat);
                    }
                }
            });
        }

        const sessionData: SessionProps | undefined = formChanges.includes(FormChanges.Session) ? {
            title: values.title,
            description: description ?? undefined,
            type: values.type,
            eventType: !session.id ? eventType : undefined,
            interval: !session.id && eventType === LectureSessionEventType.Reccuring ? interval : undefined,
            dayOfTheWeek: !session.id && eventType === LectureSessionEventType.Reccuring && interval === LectureSessionIntervalType.Weekly ? dayOfTheWeek : undefined,
            dayOfTheMonth: !session.id && eventType === LectureSessionEventType.Reccuring && interval === LectureSessionIntervalType.Monthly && dayOfTheMonth ? +dayOfTheMonth : undefined,
            date: !!values.date ? values.date.format('YYYY-MM-DD') : undefined,
            start: !!values.time && values.time.length ? values.time[0].format('HH:mm') : undefined,
            end: !!values.time && values.time.length ? values.time[1].format('HH:mm') : undefined,
            period: !!values.period && values.period.length ? [values.period[0].format('YYYY-MM-DD'), values.period[1].format('YYYY-MM-DD')] : undefined,
            link: values.link,
            lectors: values.lectors,
            locations: values.locations,
            resources: values.resources,
            licenses: values.licenses,
            registeredBy: values.registeredBy,
            limitedPeriod: values.limitedPeriod,
            opensBefore: !!values.limitedPeriod ? values.opensBefore : null,
            closesBefore: !!values.limitedPeriod ? values.closesBefore : null,
            remindBefore: values.remindBefore,
            capacity: !!values.capacityLimit ? values.capacity : null,
            customFields: values.customFields,
        } : undefined

        const response = await saveSession(lecture.id, session.id, sessionData);

        setTimeout(() => {
            message.success(intl.formatMessage({id: 'general.submit_success'}));
        }, 2000);

        if (isNew()) {
            if (!response) {
                return;
            // else if (response.length === 1) {
            //     navigate(`/lectures/${lecture.id}/sessions/${response[0].id}`);
            } else {
                navigate(`/lectures/${lecture.id}`);
            }

            reloadSession();
        } else {
            reloadSession();
        }
    };

    const onValuesChange = (changedValues: any) => {
        setFormChanges(current => Array.from(new Set([...current, FormChanges.Session])));

        if ([
            'date',
            'time',
            'eventType',
            'dayOfTheWeek',
            'dayOfTheMonth',
            'period',
            'interval',
        ].some(r => Object.keys(changedValues).includes(r))) {
            triggerAvailabilityValidation();
        }
    }

    const registeredByOptions = {
        [LectureSessionRegisteredByType.SelfRegistration]: intl.formatMessage({id: 'general.learning_manager_supervisor_student_self'}),
        [LectureSessionRegisteredByType.TrainingManager]: intl.formatMessage({id: 'general.learning_manager'}),
        [LectureSessionRegisteredByType.TrainingAndDirectManager]: intl.formatMessage({id: 'general.learning_manager_and_supervisor'}),
    }

    const remindBeforeOptions = () => {
        const options: { [index: number]: number; } = {};

        for (var x = 1; x < 25; x++) {
            options[x] = x;
        }

        return options;
    }

    const triggerAvailabilityValidation = () => {
        const validateFields: string[] = [];

        if ((form.getFieldValue('lectors') ?? []).length) {
            validateFields.push('lectors');
        }

        if ((form.getFieldValue('locations') ?? []).length) {
            validateFields.push('locations');
        }

        if ((form.getFieldValue('resources') ?? []).length) {
            validateFields.push('resources');
        }

        if ((form.getFieldValue('licenses') ?? []).length) {
            validateFields.push('licenses');
        }

        form.validateFields(validateFields);
    }

    const validateFieldAvailability = async (field: any, value: number[], callback: any) => {
        if (!(!!value && !!value.length)) {
            return Promise.resolve();
        }

        if (lecture && lecture.id) {
            const period = form.getFieldValue('period');
            const time = form.getFieldValue('time');

            if (await validateAvailability(lecture.id, {
                sessionId: !isNew() ? session.id : undefined,
                eventType: !session.id ? eventType : undefined,
                date: form.getFieldValue('date').format('YYYY-MM-DD'),
                start: !!time && time.length ? time[0].format('HH:mm') : undefined,
                end: !!time && time.length ? time[1].format('HH:mm') : undefined,
                period: eventType === LectureSessionEventType.Reccuring && !!period && period.length ? [period[0].format('YYYY-MM-DD'), period[1].format('YYYY-MM-DD')] : undefined,
                interval: !session.id && eventType === LectureSessionEventType.Reccuring ? interval : undefined,
                dayOfTheWeek: !session.id && eventType === LectureSessionEventType.Reccuring && interval === LectureSessionIntervalType.Weekly ? dayOfTheWeek : undefined,
                dayOfTheMonth: !session.id && eventType === LectureSessionEventType.Reccuring && interval === LectureSessionIntervalType.Monthly && dayOfTheMonth ? +dayOfTheMonth : undefined,
                lectors: field.fullField === 'lectors' ? value : undefined,
                locations: field.fullField === 'locations' ? value : undefined,
                resources: field.fullField === 'resources' ? value : undefined,
                licenses: field.fullField === 'licenses' ? value : undefined,
            })) {
                return Promise.resolve();
            };
        }

        return Promise.reject();
    }

    return (<Spinner spinning={sessionLoading}>
        <Form form={form} onFinish={handleFormSubmit} {...formItemLayout} onValuesChange={onValuesChange}>
            <Title withLine title="general.lectures.title_description" />
            <Input
                name='title'
                label={intl.formatMessage({ id: 'general.lectures.session_title' })}
                customRules={[{ required: true, message: intl.formatMessage({ id: 'validation.field_required' }) }]}
                customLayout={true} {...formItemLayout}
            />

            {typeof description !== 'undefined' && <TemplateEditor height={200}
                label={intl.formatMessage({ id: 'general.description' })}
                html={description}
                setHtml={setDescription}
                setEditor={setEditor}
            />}

            <Title withLine title="general.lectures.event_type_date_time" />

            <Form.Item name='type'
                label={intl.formatMessage({ id: 'general.lectures.session_type' })}
                rules={[{ required: true, message: intl.formatMessage({ id: 'validation.field_required' }) }]}
            >
                <Radio.Group>
                    <Space>
                        <Radio value={LectureSessionType.Online}>
                            <FormattedMessage id='general.lectures.online' />
                        </Radio>
                        <Radio value={LectureSessionType.ClassRoom}>
                            <FormattedMessage id='general.lectures.classroom' />
                        </Radio>
                    </Space>
                </Radio.Group>
            </Form.Item>

            {!session.id && <Form.Item wrapperCol={{ span: 10, offset: 8 }}>
                <Space.Compact block>
                    <Button type={eventType === LectureSessionEventType.OneTime ? 'primary' : undefined}
                        onClick={() => setEventType(LectureSessionEventType.OneTime)}>
                        <FormattedMessage id='general.lectures.one_time_event' />
                    </Button>
                    <Button type={eventType === LectureSessionEventType.Reccuring ? 'primary' : undefined}
                        onClick={() => setEventType(LectureSessionEventType.Reccuring)}>
                        <FormattedMessage id='general.lectures.recurring_event' />
                    </Button>
                </Space.Compact>
            </Form.Item>}

            {!session.id && eventType === LectureSessionEventType.Reccuring && <Form.Item wrapperCol={{ span: 10, offset: 8 }}>
                <Space.Compact block>
                    <Button type={interval === LectureSessionIntervalType.Daily ? 'primary' : undefined}
                        onClick={() => setInterval(LectureSessionIntervalType.Daily)}>
                        <FormattedMessage id='general.lectures.daily' />
                    </Button>
                    <Button type={interval === LectureSessionIntervalType.Weekly ? 'primary' : undefined}
                        onClick={() => setInterval(LectureSessionIntervalType.Weekly)}>
                        <FormattedMessage id='general.lectures.weekly' />
                    </Button>
                    <Button type={interval === LectureSessionIntervalType.Monthly ? 'primary' : undefined}
                        onClick={() => setInterval(LectureSessionIntervalType.Monthly)}>
                        <FormattedMessage id='general.lectures.monthly' />
                    </Button>
                </Space.Compact>
            </Form.Item>}

            {eventType === LectureSessionEventType.OneTime && <Form.Item name="date"
                label={intl.formatMessage({ id: 'general.date' })}
                rules={[{ required: true, message: intl.formatMessage({ id: 'validation.field_required' }) }]}
            >
                <DatePicker format="DD-MM-YYYY" />
            </Form.Item>}

            {!session.id && eventType === LectureSessionEventType.Reccuring && interval === LectureSessionIntervalType.Weekly && <Form.Item name="dayOfTheWeek"
                label={intl.formatMessage({ id: 'general.lectures.every_week_on' })}
                rules={[{ required: true, message: intl.formatMessage({ id: 'validation.field_required' }) }]}
            >
                <Checkbox.Group
                    onChange={(values: CheckboxValueType[]) => setDayOfTheWeek(values)}
                >
                    <Row><Checkbox value='1'>{intl.formatMessage({ id: 'general.lectures.monday' })}</Checkbox></Row>
                    <Row><Checkbox value='2'>{intl.formatMessage({ id: 'general.lectures.tuesday' })}</Checkbox></Row>
                    <Row><Checkbox value='3'>{intl.formatMessage({ id: 'general.lectures.wednesday' })}</Checkbox></Row>
                    <Row><Checkbox value='4'>{intl.formatMessage({ id: 'general.lectures.thursday' })}</Checkbox></Row>
                    <Row><Checkbox value='5'>{intl.formatMessage({ id: 'general.lectures.friday' })}</Checkbox></Row>
                    <Row><Checkbox value='6'>{intl.formatMessage({ id: 'general.lectures.saturday' })}</Checkbox></Row>
                    <Row><Checkbox value='0'>{intl.formatMessage({ id: 'general.lectures.sunday' })}</Checkbox></Row>
                </Checkbox.Group>
            </Form.Item>}

            {!session.id && eventType === LectureSessionEventType.Reccuring && interval === LectureSessionIntervalType.Monthly && <Form.Item name="dayOfTheMonth"
                label={intl.formatMessage({ id: 'general.lectures.day_of_the_month' })}
                rules={[{ required: true, message: intl.formatMessage({ id: 'validation.field_required' }) }]}
            >
                <InputNumber min={1} max={31} onChange={(value: number | null) => setDayOfTheMonth(value)} />
            </Form.Item>}

            <Form.Item name="time"
                label={intl.formatMessage({ id: 'general.lectures.time' })}
                rules={[{ required: true, message: intl.formatMessage({ id: 'validation.field_required' }) }]}
            >
                <TimePicker.RangePicker
                    showMinute={true}
                    showSecond={false}
                    minuteStep={5}
                    format="HH:mm"
                />
            </Form.Item>

            {eventType !== LectureSessionEventType.OneTime && <Form.Item name="period"
                label={intl.formatMessage({ id: 'general.lectures.period_of_operation' })}
                rules={[{ required: true, message: intl.formatMessage({ id: 'validation.field_required' }) }]}
                initialValue={[
                    moment(),
                    moment().add(1, 'days')
                ]}
            >
                <RangePicker format="YYYY-MM-DD" />
            </Form.Item>}

            <Select
                hint={"general.lectures.reminder_notification_hint"}
                showArrow
                name='remindBefore'
                label={intl.formatMessage({ id: 'general.lectures.reminder_notification' })}
                manualOptions={remindBeforeOptions()}
                customLayout={true} {...formItemLayout}
            />

            <Input
                name='link'
                label={intl.formatMessage({ id: 'general.lectures.meeting_link' })}
                customLayout={true} {...formItemLayout}
                disabled={!!session?.zoom?.id}
            />

            <Title withLine title="general.lectures.instructor_location_resources" />

            {!!session && <Form.Item name="lectors"
                label={intl.formatMessage({ id: 'general.lectures.instructor' })}
                rules={[{
                    validator: validateFieldAvailability,
                    message: intl.formatMessage({ id: 'general.lectures.instructor_unavailable' }),
                    warningOnly: true
                }]}
            >
                <AddLectors />
            </Form.Item>}

            {!!session && <Form.Item name="locations"
                label={intl.formatMessage({ id: 'system.resource.type.LOCATION' })}
                rules={[{ 
                    validator: validateFieldAvailability, 
                    message: intl.formatMessage({ id: 'general.lectures.location_unavailable' }),
                    warningOnly: true
                }]}
            >
                <AddResource types={[ResourceType.Location]} />
            </Form.Item>}

            {!!session && <Form.Item name="resources"
                label={intl.formatMessage({ id: 'general.lectures.resources' })}
                rules={[{ 
                    validator: validateFieldAvailability, 
                    message: intl.formatMessage({ id: 'general.lectures.resource_unavailable' }),
                    warningOnly: true 
                }]}
            >
                <AddResource types={[ResourceType.Device, ResourceType.Other]} />
            </Form.Item>}

            {!!session && <Form.Item name="licenses"
                label={intl.formatMessage({ id: 'general.lectures.licenses' })}
                rules={[{ 
                    validator: validateFieldAvailability, 
                    message: intl.formatMessage({ id: 'general.lectures.license_unavailable' }),
                    warningOnly: true 
                }]}
            >
                <AddResource types={[ResourceType.License]} limit={1}/>
            </Form.Item>}


            <Title withLine title="general.lectures.registration_settings" />

            <Select
                showArrow
                name='registeredBy'
                label={intl.formatMessage({ id: 'general.lectures.registration_allowed_by' })}
                manualOptions={registeredByOptions}
                customRules={[{ required: true, message: intl.formatMessage({ id: "validation.field_required" }) }]}
                customLayout={true} {...formItemLayout}
            />

            <Switch isFormItem
                name="limitedPeriod"
                label={intl.formatMessage({ id: 'general.lectures.limited_registration_period' })}
                valuePropName="checked"
                onChange={setLimitedPeriod}
            />

            {!!limitedPeriod && <Input
                name='opensBefore'
                label={`${intl.formatMessage({ id: 'general.lectures.registration_opens' })} (${intl.formatMessage({ id: 'general.lectures.hours_before_seesion' })})`}
            />}

            {!!limitedPeriod && <Input
                name='closesBefore'
                label={`${intl.formatMessage({ id: 'general.lectures.registration_closes' })} (${intl.formatMessage({ id: 'general.lectures.hours_before_seesion' })})`}
            />}

            <Switch isFormItem
                name="capacityLimit"
                label={intl.formatMessage({ id: 'general.lectures.meeting_capacity_limit' })}
                valuePropName="checked"
                onChange={setCapacityLimit}
            />

            {!!capacityLimit && <Input
                name='capacity'
                label={intl.formatMessage({ id: 'general.lectures.meeting_capacity' })}
            />}

            {customFields.length > 0 ?
                <>
                    <Title withLine title="general.custom_fields" />
                    {
                        customFields.map((field: { uuid: string, field_type: 'TEXT' | 'INTEGER' | 'DATE' | 'SWITCH' | 'MULTIPLE_CHOICE', title: string, multiple_choice_options: string[] }) => {

                            switch (field.field_type) {
                                case 'TEXT':
                                    return (
                                        <Input name={['customFields', field.uuid]} label={field.title}></Input>
                                    )
                                case 'INTEGER':
                                    return (
                                        <InputNumber name={['customFields', field.uuid]}
                                             label={field.title}></InputNumber>
                                    )
                                case 'DATE':
                                    return (
                                        <CustomDatePicker name={['customFields', field.uuid]} label={field.title}
                                            format={config.defaultDateFormat}></CustomDatePicker>
                                    )
                                case 'SWITCH':
                                    return (
                                        <Switch name={['customFields', field.uuid]} isFormItem
                                            label={field.title}></Switch>
                                    )
                                case 'MULTIPLE_CHOICE':
                                    return (
                                        <div className={'multiple-choice-fields'}>
                                            <Form.Item label={field.title} name={['customFields', field.uuid]}>
                                                <Checkbox.Group>
                                                    {
                                                        field.multiple_choice_options.map((el:string)=>{
                                                            return (
                                                                <Checkbox value={el}>
                                                                    {el}
                                                                </Checkbox>
                                                            )
                                                        })
                                                    }
                                                </Checkbox.Group>
                                            </Form.Item>
                                        </div>
                                    )
                                default:
                                    return;
                            }
                        })
                    }
                </>
                :
                null
            }

        </Form>
        {(isNew() || (lecture.isEditable && !isNew())) && <DefaultLayout.PageFooter right={
            <>
                <Button type='secondary' disabled={!formChanges.length} loading={buttonLoading}
                    onClick={reloadSession}>
                    <FormattedMessage id='general.cancel' />
                </Button>
                <Button type='primary' disabled={!formChanges.length} loading={buttonLoading}
                    onClick={() => { form.submit() }}>
                    <FormattedMessage id='general.submit' />
                </Button>
            </>
        } />}
    </Spinner>

    )
};

export default SessionDetails;
