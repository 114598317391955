import React, { useEffect, useState } from 'react';
import { ChromePicker, ColorResult } from 'react-color';
import { Form, Input, Popover } from 'antd';
import { FormInstance } from 'antd/es/form';
import { DownOutlined } from '@ant-design/icons';
import './styles.scss';

interface ColorPickerProps {
  initialColor?: string;
  form: FormInstance;
  name: string;
  label?: string;
}

const ColorPicker: React.FC<ColorPickerProps> = ({ initialColor = '#ffffff', form, name, label }) => {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [color, setColor] = useState(initialColor);

  useEffect(() => {
    setColor(initialColor);
    form.setFieldsValue({ [name]: initialColor });
  }, [form, name, initialColor]);

  const handleVisibleChange = () => setShowColorPicker(!showColorPicker);

  const handleColorChange = ({ hex }: ColorResult) => setColor(hex);

  const handleColorChangeComplete = ({ hex }: ColorResult) => form.setFieldsValue({ [name]: hex });

  const popoverContent = (
    <ChromePicker
      color={color}
      onChange={handleColorChange}
      onChangeComplete={handleColorChangeComplete}
      disableAlpha
    />
  );

  return (
    <Form.Item label={label}>
      <div className="color-picker">
        <Form.Item name={name} style={{ width: '100%' }}>
          <Input tabIndex={-1} readOnly />
        </Form.Item>
        <Popover
          content={popoverContent}
          placement="bottom"
          trigger="click"
          open={showColorPicker}
          onOpenChange={handleVisibleChange}
        >
          <button className="color-picker-trigger">
            <div className="color-picker-preview" style={{ backgroundColor: color }} />
            <DownOutlined className="color-picker-icon" />
          </button>
        </Popover>
      </div>
    </Form.Item>
  );
}

export default ColorPicker;