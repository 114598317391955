import React from 'react';
import './styles.scss';

const PageLayout: React.FC<any> = ({withStickyFooter, withoutPageHeader, withoutLeftMargin, withSidebar, withoutPageLayout = false, ...props}) => {
  let className = 'page-layout';

  if (withoutPageLayout) {
    className = '';
  }

  if (withStickyFooter) {
    className += ' with-sticky-footer';
  }

  if (withoutPageHeader) {
    className += ' without-page-header';
  }

  if (withoutLeftMargin) {
    className += ' without-left-margin';
  }

  if (withSidebar) {
    className += ' with-sidebar';
  }

  return (
    <div className={className} {...props} />
  )
}

export default PageLayout;
