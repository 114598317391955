import type { Identifier, XYCoord } from 'dnd-core'
import React, {useEffect} from 'react'
import { DragLayerMonitor, useDragLayer } from 'react-dnd'
import {DragOutlined} from '@ant-design/icons';
import ThumbPreview from "../ThumbPreview";
import { getEmptyImage } from 'react-dnd-html5-backend';

export const DragPreview: React.FC<any> = () => {
  const {isDragging, currentOffset, item} = useDragLayer(
    (monitor: DragLayerMonitor) => {
        return {
            isDragging: monitor.isDragging(),
            currentOffset: monitor.getSourceClientOffset(),
            item: monitor.getItem()
        };
    }
);

return (
  <>
  {isDragging && currentOffset  ?
    <div className='ma-answer-row drag-preview-row' style={{ 
      transform: `translate(${currentOffset.x}px, ${currentOffset.y}px)`,
      position: 'fixed',
      top: 0,
      left: 0,
      pointerEvents: 'none',
      width: item.width,
      height: item.height,
      border: 'none'
  }}>
      <div className='ma-static-item-wrapper'>
      
      </div>
      <div  className='ma-answer-item ma-sortable-item'>
        <span className='drag-handle'><DragOutlined style={{ cursor: 'grab', color: '#19A9CE' }} /></span>
        {item.sortableElement.description && <span className='ma-answer-text'><span>{item.sortableElement.description}</span></span>}
        {item.sortableElement?.storageFiles?.thumbnail2?.fileId && <ThumbPreview
            fileInfo={item.sortableElement?.storageFiles?.thumbnail2}
        />}
      </div>
    </div> : null}
  </>
)
}
