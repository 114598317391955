import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {Form} from 'antd';
import {Modal} from 'ui';
import {Input} from 'components/Form';
import apiClient from 'utils/apiClient';
import useHandleError from 'utils/useHandleError';


const formItemLayout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};

interface RuleSetCreationModaltInterface {
    audienceId: number|string;
    visible?: boolean|undefined;
    onCancel(cancel: boolean): any;
    onSubmit(): any;
}


const RuleSetCreationModal: React.FC<RuleSetCreationModaltInterface> = ({ audienceId, visible = false, onCancel, onSubmit }) => {
    const [loading, setLoading] = useState(false);
    const [handleError] = useHandleError();
    const intl = useIntl();
    const [form] = Form.useForm();

    const handleOnCancel = () => {
        form.resetFields();
        onCancel(false)
    }

    const handleFormSubmit = async (values: any) => {
        setLoading(true)

        const audienceData: any = {
            audienceId: audienceId,
            name: values.name,
        }

        try {
            const response = await apiClient.request('/api/v1/audiences/rule-sets', audienceData, 'POST');
            if ("error" in response) {
                throw response
            };
            setLoading(false);
            form.resetFields();
            onSubmit();
        } catch (error) {
            setLoading(false);
            handleError(error);
        }
    };

    return (
        <Modal
            open={visible}
            isLoading={loading}
            onCancel={() => handleOnCancel()}
            title={intl.formatMessage({id: 'audience.new_rule_set'})}
            okText={intl.formatMessage({id: 'general.create'})}
            cancelText={intl.formatMessage({id: 'general.cancel'})}
            onOk={form.submit}
        >
            <Form form={form} onFinish={handleFormSubmit} >
                <Input
                    name='name' 
                    label={intl.formatMessage({ id: 'audience.rule_set_name' })} 
                    rules={[{ required: true, message: intl.formatMessage({ id: 'validation.field_required' }) }]}
                    customLayout={true} {...formItemLayout}
                />
            </Form>
        </Modal>
    )
};
export default RuleSetCreationModal;
