import React, {useEffect, useState} from "react"
import DefaultLayout from 'components/DefaultLayout';
import { useIntl, FormattedMessage } from 'react-intl';
import apiClient from "../../utils/apiClient";
import Spinner from "../../components/Spinner";

const PrivacyPolicy: React.FC = ({})=>{
    const [policy, setPolicy] = useState<any>();
    const [isTermsLoading, setIsTermsLoading] = useState(false);
    const intl = useIntl();

    useEffect(() => {
        const loadTerms = async () => {
            setIsTermsLoading(true);
            let response = await apiClient.request('/api/v1/privacy-policy/' + intl.locale, [], 'GET');
            setPolicy(response.policy.text);
            setIsTermsLoading(false);
        };
        loadTerms();
    }, [intl]);

    return <>
        <DefaultLayout.PageLayout>
            <h1>
                <FormattedMessage id='system.privacy_policy'/>
            </h1>
            <DefaultLayout.PageContent>
                <Spinner spinning={isTermsLoading}>
                    <div style={{fontSize: 16}} dangerouslySetInnerHTML={{__html: policy}}></div>
                </Spinner>
            </DefaultLayout.PageContent>
        </DefaultLayout.PageLayout>
    </>
};

export default PrivacyPolicy;
