import React, {useState} from 'react';
import {useParams} from "react-router-dom";
import Spinner from "components/Spinner";
import {message, Modal, Tabs} from "antd";
import {Table} from 'ui'
import {FormattedMessage, useIntl} from "react-intl";
import Jwt from "../../../utils/jwt";
import apiClient from "../../../utils/apiClient";
import useHandleError from "../../../utils/useHandleError";
import {ExclamationCircleOutlined} from "@ant-design/icons";

interface StructureUsageInterface {
}

const StructureUsage: React.FC<StructureUsageInterface> = ({}) => {
    const [activeTabKey, setActiveTabKey] = useState('organizational-units')
    const [loading, setLoading] = useState(false)
    const [reload, setReload] = useState(false);

    const params = useParams();
    const customerId = params.id;
    const intl = useIntl();
    const [handleError] = useHandleError();
    const {confirm} = Modal;

    const columns = [
        {
            title: intl.formatMessage({id: activeTabKey === 'organizational-units' ? 'users.organizational_unit' : 'general.departments'}),
            dataIndex: 'title',
        },
        {
            title: intl.formatMessage({id: 'general.usage'}),
            dataIndex: 'count',
        },
        {
            key: 'actions',
            render: (_text: string, record: any) => {
                if (record.count === 0) {
                    return (
                        <div className={'pointer'} onClick={() => warnDeletion(record.id)}>
                            <FormattedMessage id='general.delete'/>
                        </div>
                    )
                }
            },
        }
    ];

    const warnDeletion = async (id: number) => {
        confirm({
            title: intl.formatMessage({id: 'general.attention'}),
            content: intl.formatMessage({id: 'confirmation.are_you_sure'}),
            icon: <ExclamationCircleOutlined/>,
            okText: intl.formatMessage({id: 'general.yes'}),
            cancelText: intl.formatMessage({id: 'general.no'}),

            onOk() {
                deleteStructure(id);
            }
        });
    }

    const deleteStructure = async (id: number) => {
        try {
            await apiClient.request(`/api/v1/customers/${customerId}/structure/${activeTabKey}/${id}/delete`, [], 'DELETE');
        } catch (error) {
            handleError(error);
        } finally {
            setReload(true)
        }
    };


    return (
        <Spinner spinning={loading}>
            <Tabs destroyInactiveTabPane onChange={setActiveTabKey} activeKey={activeTabKey}
                  items={[
                      {
                          label: intl.formatMessage({id: 'general.organizational_unit'}),
                          key: 'organizational-units',
                          children: (
                              <Table
                                  url={`/api/v1/customers/${customerId}/structure/organizational-units`}
                                  setReload={setReload}
                                  reload={reload}
                                  columns={columns}
                                  rowSelection={false}
                                  rowKey={'id'}
                              />
                          )
                      },
                      {
                          label: intl.formatMessage({id: 'general.departments'}),
                          key: 'departments',
                          children: (
                              <Table
                                  url={`/api/v1/customers/${customerId}/structure/departments`}
                                  setReload={setReload}
                                  reload={reload}
                                  columns={columns}
                                  rowSelection={false}
                                  rowKey={'id'}
                              />
                          )
                      }
                  ]}
            />
        </Spinner>
    )
};

export default StructureUsage;
