import React from 'react';
import { Typography } from 'antd';

const { Title: AntDTitle } = Typography;

interface FormTitleProps {
  children: any,
  align?: 'left'|'center'|'right',
  font?: string
}

const Title: React.FC<FormTitleProps> = ({ children, align,font = '20px' }) => (
  <AntDTitle level={4} style={{ marginBottom: 20, fontSize: font, fontWeight: 'bold', textAlign: align || 'left' }}>
    {children}
  </AntDTitle>
);

export default Title;