import React, { useEffect, useState} from 'react';
import { FullCalendar} from 'ui'
import {EventClickArg} from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from "@fullcalendar/interaction"
import ResourceReservationCreationModal from 'components/Resources/ResourceReservationCreationModal'
import {useResource} from "../../../hooks/Resources/useResource";
import ResourceReservationFilterModal from "../ResourceResevationFilterModal";
import { useIntl} from "react-intl";
import {Tooltip} from "antd";

const ResourcesCalendar: React.FC = () => {
    const [showCreateModal, setCreateModal] = useState<boolean>(false);
    const [showFilterModal, setShowFilterModal] = useState<boolean>(false);
    const [refetchEvents, setRefetchEvents] = useState<boolean>(false);
    const [reservationData, setReservationData] = useState<any | null>(null);
    const [events, setEvents] = useState<any[]>([]);
    const { getCalendarResourceReservations } = useResource();
    const intl = useIntl();

    useEffect(() => {
        const fetchResources = async () => {
            const response = await getCalendarResourceReservations();
            setEvents(response);
        }

        fetchResources();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!showCreateModal) {
            setReservationData(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showCreateModal])

    useEffect(() => {
        if (!!reservationData) {
            setCreateModal(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reservationData])


    const handleDateClick = (day: any) => {
        setReservationData({
            day
        });
    }

    const handleEventClick = (clickInfo: EventClickArg) => {
        if (clickInfo.event.extendedProps.hasSession) {
            return;
        }

        setReservationData({
            id: clickInfo.event.id,
            resourceId: clickInfo.event.extendedProps.resourceId,
            start: clickInfo.event.start,
            end: clickInfo.event.end,
        });
    }

    const handleAddReservation = () => {
        setCreateModal(false);
        setRefetchEvents(!refetchEvents);
    }

    const renderEventContent = (eventInfo: any) => {
        return (
            <>
                <Tooltip className={'flex align-center w-100'}  placement="top" title={eventInfo.event.extendedProps.sessionTitle}>
                    <div className='fc-daygrid-event-dot' style={{borderColor: eventInfo.backgroundColor}}></div>
                    <div className={'fc-event-time'}>{eventInfo.timeText} </div>
                    <div className={'fc-event-title'}>{eventInfo.event.title}</div>
                </Tooltip>
            </>
        )
    }

    return <>
        <FullCalendar
            refetchEvents={refetchEvents}
            nowIndicator={true}
            allDaySlot={false}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            customButtons={{
                myCustomButton: {
                    text: intl.formatMessage({id:'general.filter'}),
                    click: function() { setShowFilterModal(true) }
                }
            }}
            headerToolbar={{
                left: 'prev,next today myCustomButton',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay'
            }}
            initialView="dayGridMonth"
            dateClick={handleDateClick}
            eventClick={handleEventClick}
            eventContent={renderEventContent}
            events={events}
        />
        {showCreateModal && <ResourceReservationCreationModal
            visible={showCreateModal}
            onCancel={() => setCreateModal(false)}
            onSubmit={() => handleAddReservation()}
            reservationData={reservationData}
        />}
          <ResourceReservationFilterModal visible={showFilterModal} onCancel={setShowFilterModal} setEvents={setEvents}/>
    </>;
};

export default ResourcesCalendar;