import React, { useEffect, useState } from 'react';
import { Button, Table } from 'ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { config } from 'config/config';
import { Checkbox, Divider, message, Popover } from 'antd';
import generalHelpers from 'utils/generalHelpers';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { connect } from 'react-redux';
import coursesApiClient from 'utils/coursesApiClient';
import FileDownload from 'js-file-download';

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale,
    };
};

const defaultFields: string[] = [
    'audienceTitle',
    'audienceUsers',
    'competenceBlockTitle',
    'competenceTitle',
    'programTitle',
];

const AudienceReport: React.FC<any> = ({
    filter,
    locale = config.defaultEnvironmentLocale,
    load,
    setLoad,
    form,
}) => {
    const [reload, setReload] = useState(false);
    const [exportLoading, setExportLoading] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState<CheckboxValueType[]>(defaultFields);
    const [openPopover, setOpenPopover] = useState(false);
    const [displayColumns, setDisplayColumns] = useState<any>([]);

    const cacheKey = 'sessionColumns_AUDIENCE';

    const intl = useIntl();

    useEffect(() => {
        if (filter !== null && load) {
            filterSession();
            setLoad(false);
        }

        const hasColumns = localStorage.getItem(cacheKey);

        if (hasColumns) {
            setDisplayColumns(hasColumns.split(','));
            setSelectedColumns(hasColumns.split(','));
        }
    }, [load]);

    const filterSession = () => {
        setReload(true);
    };

    const columns = [
        {
            title: intl.formatMessage({ id: 'general.audience_title' }),
            dataIndex: 'audienceTitle',
            key: 'audienceTitle',
            sorter: true,
        },
        {
            title: intl.formatMessage({ id: 'general.audience_users' }),
            dataIndex: 'audienceUsers',
            key: 'audienceUsers',
            sorter: true,
        },
        {
            title: intl.formatMessage({ id: 'general.competence_block_title' }),
            dataIndex: 'competenceBlockTitle',
            key: 'competenceBlockTitle',
            sorter: true,
        },
        {
            title: intl.formatMessage({ id: 'general.competence_title' }),
            dataIndex: 'competenceTitle',
            key: 'competenceTitle',
            sorter: true,
        },
        {
            title: intl.formatMessage({ id: 'general.program_title' }),
            dataIndex: 'programTitle',
            key: 'programTitle',
            sorter: true,
            render: (_text: string, record: any) => {
                if (record?.programTitle) {
                    const title = JSON.parse(record.programTitle);

                    return title[locale] ? title[locale] : title[Object.keys(title)[0]];
                } else {
                    return '-';
                }
            },
        },
    ];
    const content = (
        <div>
            <Checkbox.Group
                value={selectedColumns}
                onChange={(checkboxGroupColumns: CheckboxValueType[]) =>
                    setSelectedColumns(checkboxGroupColumns)
                }
            >
                {Object.values(columns).map((el: any) => {
                    return (
                        <div key={el.dataIndex} className={'flex p-1 gap-5'}>
                            <Checkbox key={el.dataIndex} value={el.dataIndex} />
                            {el.title}
                        </div>
                    );
                })}
            </Checkbox.Group>
            <Divider plain className={'m-2'} />
            <div className={'flex justify-between p-1'}>
                <Button type={'link'} onClick={() => handleCancel()}>
                    <FormattedMessage id={'general.cancel'} />
                </Button>
                <Button type={'primary'} onClick={() => handleColumnSubmit()}>
                    <FormattedMessage id={'general.select'} />
                </Button>
            </div>
        </div>
    );

    const handleColumnSubmit = () => {
        setDisplayColumns(selectedColumns);
        localStorage.setItem(cacheKey, selectedColumns.toString());
        setOpenPopover(false);
    };

    const handleCancel = () => {
        const hasColumns = localStorage.getItem(cacheKey);

        if (hasColumns) {
            setSelectedColumns(hasColumns.split(','));
        } else {
            setSelectedColumns(defaultFields);
        }
        setOpenPopover(false);
    };

    const exportData = async () => {
        const values = form.getFieldsValue();
        const query = generalHelpers.filterParser(values).replace('&', '?');

        setExportLoading(true);
        try {
            const url = '/api/v1/courses/reports/export/AUDIENCE' + query;
            const response = await coursesApiClient.request(url, [], 'GET', true, true);

            if (response.fileName) {
                FileDownload(response, response.fileName);
            } else {
                message.error(intl.formatMessage({ id: 'error.data_load' }));
            }
        } catch (error) {
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        } finally {
            setExportLoading(false);
        }
    };

    return (
        <div className={'reports-element'}>
            <div className={'flex justify-between'}>
                <Button loading={exportLoading} type={'outline'} onClick={() => exportData()}>
                    <FormattedMessage id={'general.export'} />
                </Button>
                <Popover
                    open={openPopover}
                    placement="bottomRight"
                    title={intl.formatMessage({ id: 'general.columns' })}
                    content={content}
                    trigger="click"
                >
                    <Button type={'outline'} onClick={() => setOpenPopover(!openPopover)}>
                        <i className="fa-solid fa-table-columns"></i>
                    </Button>
                </Popover>
            </div>
            <Table
                columns={columns.filter((el: any) => displayColumns.includes(el.dataIndex))}
                url={'/api/v1/courses/reports/audiences'}
                reload={reload}
                setReload={setReload}
                rowKey={'id'}
                filter={filter}
                scroll={{ x: true }}
                rowSelection={false}
                hasCustomFields={false}
                showTotalResults={true}
            />
        </div>
    );
};

export default connect(mapStateToProps)(AudienceReport);
