import {useState} from 'react'
import useHandleError from 'utils/useHandleError';
import apiClient from 'utils/apiClient';
import generalHelpers from "utils/generalHelpers";

export const useUsers = () => {
    const [handleError] = useHandleError();
    const [loading, setLoading] = useState<boolean>(false)

    const searchUsers = async (
        q: string,
        orgUnits: string[],
        departments: string[],
        customers: string[],
        position: string[],
    ) => {
        setLoading(true)
        try {
            let requestUrl: string = 'api/v1/edu/users/search-student?key=id'

            requestUrl = generalHelpers.urlBuilder(requestUrl, q, 'q')
            requestUrl = generalHelpers.urlBuilder(requestUrl, customers, 'customersUuid')
            requestUrl = generalHelpers.urlBuilder(requestUrl, departments, 'depId')
            requestUrl = generalHelpers.urlBuilder(requestUrl, orgUnits, 'orgUnitId')
            requestUrl = generalHelpers.urlBuilder(requestUrl, position, 'position')

            const response = await apiClient.request(requestUrl, [], 'GET');

            if (response) {
                if (response?.students) {
                    return response.students;
                } else {
                    return response;
                }
            }
        } catch (error) {
            handleError(error);
        } finally {
            setLoading(false)
        }
    }

    const getPositions = async (
        q: string,
        customers: string[] = [],
        departments: string[] = [],
        orgUnits: string[] = []
    ) => {
        setLoading(true)
        try {
            let requestUrl = 'api/v1/customer-users/positions/list'

            requestUrl = generalHelpers.urlBuilder(requestUrl, q, 'q')
            requestUrl = generalHelpers.urlBuilder(requestUrl, customers, 'customersUuid')
            requestUrl = generalHelpers.urlBuilder(requestUrl, departments, 'depId')
            requestUrl = generalHelpers.urlBuilder(requestUrl, orgUnits, 'orgUnitId')

            const response = await apiClient.request(requestUrl, [], 'GET');

            if (response) {
                if (response?.positions) {
                    return response.positions;
                } else {
                    return response;
                }
            }
        } catch (error) {
            handleError(error);
        } finally {
            setLoading(false)
        }
    }

    const getOrganizationalUnits = async (
        q: string,
        customers: string[] = [],
        departments: string[] = []
    ) => {
        setLoading(true)
        try {
            let requestUrl = 'api/v1/customers/options/organizational-unit-types?organizationCustomerIds'

            requestUrl = generalHelpers.urlBuilder(requestUrl, q, 'q')
            requestUrl = generalHelpers.urlBuilder(requestUrl, customers, 'customersUuid')
            requestUrl = generalHelpers.urlBuilder(requestUrl, departments, 'depId')

            const response = await apiClient.request(requestUrl, [], 'GET');

            if (response) {
                if (response?.organizationalUnits) {
                    return response.organizationalUnits;
                } else {
                    return response;
                }
            }
        } catch (error) {
            handleError(error);
        } finally {
            setLoading(false)
        }
    }

    const getDepartments = async (
        q: string, session: any,
        customers: string[] = []
    ) => {
        setLoading(true)
        try {
            let requestUrl: string;

            if (session.organization.organization_type === 'CUSTOMER') {
                requestUrl = `/api/v1/departments/list?customerId=${session.organization.organization_id}`;
            } else {
                requestUrl = `/api/v1/departments/list?organizationId=${session.organization.organization_id}`;
            }

            requestUrl = generalHelpers.urlBuilder(requestUrl, q, 'q')
            requestUrl = generalHelpers.urlBuilder(requestUrl, customers, 'customersUuid')
            requestUrl = generalHelpers.urlBuilder(requestUrl, 1, 'onlyCustomers')

            const response = await apiClient.request(requestUrl, [], 'GET');

            if (response) {
                if (response?.departments) {
                    return response.departments;
                } else {
                    return response;
                }
            }
        } catch (error) {
            handleError(error);
        } finally {
            setLoading(false)
        }
    }

    return {
        loading,
        searchUsers,
        getPositions,
        getOrganizationalUnits,
        getDepartments
    }
}