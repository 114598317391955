// THIS HAS TO BE ON TOP BECAUSE REASONS, just don't touch it, okay
import 'assets/scss/styles.scss';

import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { loadLanguageDictionary } from 'store/actions/languageDictionary';
import Login from 'pages/Login/Login';
import ResetConfirmPassword from 'pages/Login/ResetConfirmPassword';
import ResetPassword from 'pages/Login/ResetPassword';
import DefaultLayout from 'components/DefaultLayout';
import LoginRoute from 'components/LoginRoute';
import ShortRoute from 'components/ShortRoute';


import ProtectedRoute from 'components/ProtectedRoute';
import NoAuthCustomerForm from 'pages/Customers/NoAuthCustomerForm';
import RegistrationCompletion from 'pages/HelperPages/RegistrationCompletion';
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import Spinner from 'components/Spinner';
import SamlTokenRedirect from "./pages/SamlTokenRedirect";

const mapStateToProps = (state: any) => ({
  locale: state.locale.locale,
  languageDictionary: state.languageDictionary,
  languageDictionaryLoading: state.languageDictionary.loading,
});

const mapDispatchToProps = (dispatch: any) => ({
  loadLanguageDictionary: () => dispatch(loadLanguageDictionary()),
});

interface AppProps {
  locale: string;
  languageDictionary: any;
  languageDictionaryLoading: boolean;
  loadLanguageDictionary: () => void;
}

const App: React.FC<AppProps> = ({
  locale,
  languageDictionary,
  loadLanguageDictionary,
}) => {
  useEffect(() => {
    loadLanguageDictionary();
  }, [loadLanguageDictionary]);

  useEffect(() => {
    loginRedirect(window.location.hostname)
  },[])
  const loginRedirect = (hostname: string) => {
    switch (hostname) {
      case 'rix.cloudstudy.lv' :
        window.location.replace("https://saml2.cloudstudy.lv/saml2/1a7f1839-9398-4358-aefc-53db85d99681/login");
        break;
    }
  }

  return (
    <Spinner spinning={languageDictionary.loading} opaque>
      <IntlProvider locale={locale} messages={languageDictionary[locale]}>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<LoginRoute><Login /></LoginRoute>} />
            <Route path="/short/:code" element={<ShortRoute><ResetConfirmPassword /></ShortRoute>} />
            <Route path="/forgot-password" element={<LoginRoute><ResetPassword /></LoginRoute>} />
            <Route path="/reset-confirm-password/:token" element={<LoginRoute><ResetConfirmPassword /></LoginRoute>} />
            <Route path="/register-customer" element={<NoAuthCustomerForm />} />
            <Route path="/registration-complete" element={<RegistrationCompletion />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/token/:token" element={<SamlTokenRedirect />} />
            <Route path="*" element={<ProtectedRoute><DefaultLayout /></ProtectedRoute>} />
          </Routes>
        </BrowserRouter>
      </IntlProvider>
    </Spinner>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
