import React, { useState, useEffect, version } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { message, Modal, Form, Button } from 'antd';
import coursesApiClient from 'utils/coursesApiClient';
import useHandleError from "utils/useHandleError";
import QuestionEditView from "../QuestionEditView";

import Spinner from 'components/Spinner';
import {Table} from "ui";
import moment from "moment";
import "./styles.scss";

const formItemLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 18},
};

const QuestionVersionDetailModal: React.FC<any> = ({ 
    open, 
    onCancel, 
    questionId,
    saveQuestion,
    isParentLoading,
    setHasFormChanged,
    saveEvent,
    setSaveEvent,
    isSaveQuestionLoading,
    questionTypes,
    showVersionListPopup,
    setOpenQuestionId,
    loadQuestions,
    questionIdPrefix
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [question, setQuestion] = useState<any>(null);

    const intl = useIntl();
    const [copyForm] = Form.useForm();
    const [handleError] = useHandleError();

    useEffect(() => {
        if(open && questionId) {
            loadQuestion()
        }
    }, [open, questionId])

    const loadQuestion = async () => {
        setIsLoading(true);

        try {
            const response = await coursesApiClient.request(`/api/v1/courses/question-pools/questions/${questionId}`, [], 'GET');
            setQuestion(response.question)
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false);
        }
    }

    const restoreRevision = async () => {
        setIsLoading(true);

        try {
            const response = await coursesApiClient.request(`/api/v1/courses/question-pools/questions/${questionId}/restore-version`, [], 'POST');
            setOpenQuestionId(response.question.id)
            loadQuestions()
            onCancel()
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false);
        }
    }
    
    return (
        <Modal
            className="question-version-detail-modal"
            width={900}
            title={intl.formatMessage({ 
                id: 'question_pools.version_detail_modal_title' 
            }, {
                id: questionId,
                version_no: question?.versionNo
            })}
            open={open}
            onCancel={onCancel}
            onOk={restoreRevision}
            okText={intl.formatMessage({id: 'question_pools.restore'})}
            okButtonProps={{
                style: {

                }
            }}
            cancelButtonProps={{
                style: {
                    display: 'none',
                }
            }}
        >
            <Spinner spinning={isLoading}>
                {question && <QuestionEditView
                    questionId={questionId}
                    saveQuestion={saveQuestion}
                    isParentLoading={isParentLoading}
                    setHasFormChanged={setHasFormChanged}
                    saveEvent={saveEvent}
                    setSaveEvent={setSaveEvent}
                    isSaveQuestionLoading={isSaveQuestionLoading}
                    questionTypes={questionTypes}
                    showVersionListPopup={showVersionListPopup}
                    isVersionView={true}
                    questionIdPrefix={questionIdPrefix}
                />}
            </Spinner>
        </Modal>
    )
}

export default QuestionVersionDetailModal;
