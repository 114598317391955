import { Alert, Button, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import MySessionsList from 'components/Lectures/MySessionsList'
import { useLecture } from "hooks/Lectures/useLecture";
import { LectureNotificationsProps, LectureProps, LectureSessionFilterType } from '../../types';

import './styles.scss';
import { useNavigate } from 'react-router-dom';

export interface UpcomingSessionsProps {
    lecture?: LectureProps,
    adminView?: boolean;
    userTypeId?: number;
    setAdminSession?: React.Dispatch<React.SetStateAction<number>>;
    setAdminLecture?: React.Dispatch<React.SetStateAction<number>>;
    setAdminView?: React.Dispatch<React.SetStateAction<string>>;
}


const UpcomingSessions: React.FC<UpcomingSessionsProps> = ({
                                                               lecture,
                                                               adminView,
                                                               userTypeId,
                                                               setAdminSession,
                                                               setAdminLecture,
                                                               setAdminView
}) => {
    const [notifications, setNotifications] = useState<LectureNotificationsProps>()
    const { getLectureNotifications } = useLecture();

    const intl = useIntl();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchNotifications = async () => {
            setNotifications(await getLectureNotifications())
        }

        fetchNotifications()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderNotifications = (
        title: string,
        data: { id: number, title: string }[],
        type: "pastAttendanceSessions" | "pastSessions" | "signUpLectures") => {
        return <Alert className='lecture-notification-alert'
            message={<div>
                <div>{title}</div>
                {data.map((item: { id: number, title: string, lecturesId?: number, programId?: number }, index: number) => {
                    return <div>
                        <Button type='link' key={index} onClick={() => {
                            if (!!item.lecturesId && ['pastAttendanceSessions', 'pastSessions'].includes(type)) {
                                navigate(`/my-lectures/${item.lecturesId}/session/${item.id}`);
                            }
                            if (!!item.id && ['signUpLectures'].includes(type)) {
                                navigate(`/materials/${item.programId}/lecture/${item.id}`);
                            }
                        }}>
                            {item.title}
                        </Button>
                    </div>
                })}
            </div>}
            type="warning"
            closable
        />
    }

    return (
        <>
            <Space direction="vertical" style={{ width: '100%' }}>
                {notifications?.pastAttendanceSessions && !!notifications?.pastAttendanceSessions.length && renderNotifications(
                    intl.formatMessage({ id: 'general.lectures.notification_past_attendance_sessions' }),
                    notifications?.pastAttendanceSessions,
                    "pastAttendanceSessions"
                )}
                {notifications?.pastSessions && !!notifications?.pastSessions.length && renderNotifications(
                    intl.formatMessage({ id: 'general.lectures.notification_past_sessions' }),
                    notifications?.pastSessions,
                    "pastSessions"
                )}
                {notifications?.signUpLectures && !!notifications?.signUpLectures.length && renderNotifications(
                    intl.formatMessage({ id: 'general.lectures.notification_sign_up_lectures' }, { count: notifications?.signUpLectures.length }),
                    notifications?.signUpLectures,
                    "signUpLectures"
                )}
            </Space>
            <MySessionsList
                type={LectureSessionFilterType.Upcoming}
                lecture={lecture}
                adminView={adminView}
                userTypeId={userTypeId}
                setAdminSession={setAdminSession}
                setAdminLecture={setAdminLecture}
                setAdminView={setAdminView}
            />
        </>
    );
};

export default UpcomingSessions;
