import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { message, Modal, Input, Form } from 'antd';
import coursesApiClient from 'utils/coursesApiClient';
import { Select } from 'components/Form';
import { changeCampaignActivityAction } from 'store/actions/customCampaignActivityAction';
import { connect } from 'react-redux';
import './styles.scss';
import { useNavigate } from 'react-router-dom';

interface ActivityCreationModalInterface {
    visible: boolean;
    onCancel: any;
    courseId: number;
    languageList: any;
    customActivityCampaign: { campaignId: number | null, type: string | null };
    changeCampaignActivityAction: any
}

const formItemLayout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 },
};

const mapDispatchToProps = (dispatch: any) => ({
    changeCampaignActivityAction: (data: any) => dispatch(changeCampaignActivityAction(data)),
});

const ActivityCreationModal: React.FC<ActivityCreationModalInterface> = ({ visible, onCancel, courseId, languageList, changeCampaignActivityAction, customActivityCampaign }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const intl = useIntl();

    const navigate = useNavigate();

    /**
     * 
     * @param values 
     */
    const submitForm = async (values: any) => {
        setLoading(true);
        try {
            const parsedValues = {
                name: values.name,
                language: values.language,
                status: 'ONLINE',
                courseId: courseId
            }

            const response = await coursesApiClient.request(`/api/v1/courses/material/store`, parsedValues, 'POST');

            if (customActivityCampaign.campaignId) {
                changeCampaignActivityAction(customActivityCampaign)
            }

            navigate(`/courses/${courseId}/material/${response.activity.id}`);
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: "error.server_error" }));
        } finally {
            setLoading(false);
        }
    }

    return (
        <Modal
            width={530}
            open={visible}
            onCancel={onCancel}
            title={intl.formatMessage({ id: 'courses.add_new_topic' })}
            okText={intl.formatMessage({ id: 'general.submit' })}
            cancelText={intl.formatMessage({ id: 'general.back' })}
            okButtonProps={{ loading: loading }}
            onOk={form.submit}
        >
            <Form form={form} onFinish={submitForm}>
                <Form.Item labelCol={formItemLayout.labelCol} wrapperCol={formItemLayout.wrapperCol} name='name' label={intl.formatMessage({ id: 'courses.activity_name' })} rules={[{ required: true, message: intl.formatMessage({ id: 'validation.field_required' }) }]}>
                    <Input />
                </Form.Item>
                <Select
                    customLayout={formItemLayout}
                    name='language'
                    label={intl.formatMessage({ id: "general.language" })}
                    manualOptions={languageList}
                    customRules={[{ required: true, message: intl.formatMessage({ id: "validation.field_required" }) }]}
                />
            </Form>
        </Modal>
    );
}

export default connect(mapDispatchToProps)(ActivityCreationModal);
