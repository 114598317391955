import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Image} from 'antd';

const ThumbPreview: React.FC<any> = ({ fileInfo }) => {

    return (
        <Image
            className='thumbnail-preview'
            width={'auto'}
            src={fileInfo?.fileData}
        />
    )
}

export default ThumbPreview;
