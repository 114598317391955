import React, {useEffect, useState} from 'react';
import {useUsers} from "hooks/Users/useUsers";
import FilterCheckBox from "../FilterCheckBox";
import FilterPopover from "../FilterPopover";
import {Button} from "ui";
import {FormattedMessage} from "react-intl";

interface PositionsFilterInterface {
    tagBuilder: Function;
    customers: string[];
    departments: string[];
    orgUnits: string[];
    setPosition: React.Dispatch<React.SetStateAction<string[]>>;
    setFilterValues: any;
    filterValues: any
    form: any;
    filterKey: string;
    setFilterKey: React.Dispatch<React.SetStateAction<string>>;
}

const PositionsFilter: React.FC<PositionsFilterInterface> = ({
                                                                 filterKey,
                                                                 setFilterKey,
                                                                 form,
                                                                 setFilterValues,
                                                                 filterValues,
                                                                 setPosition,
                                                                 tagBuilder,
                                                                 customers,
                                                                 departments,
                                                                 orgUnits
                                                             }) => {
    const {getPositions, loading} = useUsers()
    const [data, setData] = useState([]);
    const [initialValues, setInitialValues] = useState(undefined)

    const key = 'positions'

    const onSearch = async (value: string = '') => {
        const response = await getPositions(value, customers, departments, orgUnits)
        setData(response);

        setFilterValues(
            {
                ...filterValues,
                position: {
                    ...filterValues.position,
                    ...response
                }
            }
        )
    };

    useEffect(() => {
        onSearch()
    }, [orgUnits, customers, departments])


    const content = (
        <>
            <FilterCheckBox setData={setPosition} data={data} name={'position'} onSearch={onSearch}/>
            <div className={'flex justify-between p-1'}>
                <Button type={'link'} onClick={() => handleClose()}>
                    <FormattedMessage id={'general.cancel'}/>
                </Button>
                <Button type={'primary'} onClick={() => {
                    setFilterKey('')

                    handleOpen(false)
                }}>
                    <FormattedMessage id={'general.select'}/>
                </Button>
            </div>
        </>
    );

    const handleClose = () => {
        form.setFieldsValue(initialValues);
        setFilterKey('')
        ;
    }

    const handleOpen = (open: boolean) => {
        if (open) {
            setInitialValues(form.getFieldsValue())
            setFilterValues(
                {
                    ...filterValues,
                    position: {
                        ...filterValues.position,
                        ...data
                    }
                }
            )
        } else {
            tagBuilder()
        }
    }

    return (
        <FilterPopover
            popoverKey={key}
            filterKey={filterKey}
            setOpen={setFilterKey}
            handleOpen={handleOpen}
            content={content}
            loading={loading}
            title={'general.search_by_position'}
            buttonTitle={'general.position'}
        />
    );
};

export default PositionsFilter;