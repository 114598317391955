import React from 'react';
import './styles.scss';

interface InfoTableInterface {
    rows: any[];
}

const InfoTable: React.FC<InfoTableInterface> = ({
    rows,
}) => {
    return (
        <div className='info-table'>
            {rows.map((item: any) => {
                return (
                    <div className='info-table-row'>
                        <div className='info-table-col label-col'>{item.label}</div>
                        <div className='info-table-col data-col'>{item.value}</div>
                    </div>
                )
            })}
        </div >
    )
}
export default InfoTable;