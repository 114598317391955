import React, { useState, useEffect, useCallback } from 'react';
import update from 'immutability-helper'
import { FormattedMessage, useIntl } from 'react-intl';
import { HTML5Backend } from 'react-dnd-html5-backend'
import {SortableElement} from "./SortableElement";
import {DragPreview} from "./DragPreview";
import './styles.scss';
import { TouchBackend } from "react-dnd-touch-backend";
import { DndProvider,
    TouchTransition,
    MouseTransition
} from 'react-dnd-multi-backend'


const MixMatch: React.FC<any> = ({ 
    activeQuestion,
    updateActiveQuestionData,
    onChangeAnswers,
    form,
    onFormChange,
    answers,
    isTestOverview,
    hideCorrectAnswers,
}) => {
    const [sortableData, setSortableData] = useState<any>([]);
    
    const intl = useIntl();

    useEffect(() => {
        if( activeQuestion && answers.length > 0 ) {
            let itemsArray = [...answers];
            if( activeQuestion.savedMixAndMatchOrder && activeQuestion.savedMixAndMatchOrder.length > 0 ) {
                itemsArray.sort((a, b) => {
                    return activeQuestion.savedMixAndMatchOrder.indexOf(a.id) - activeQuestion.savedMixAndMatchOrder.indexOf(b.id);
                });
                if (!activeQuestion.attemptQuestion.isAttempted) {
                    itemsArray = shuffleArray(itemsArray);
                }
            } else {
                itemsArray.sort( () => Math.random() - 0.5 );
                if (!activeQuestion.attemptQuestion.isAttempted && itemsArray.length > 1) {
                    itemsArray = shuffleArray(itemsArray);
                }
            }
            setSortableData(itemsArray)
        }
    }, [activeQuestion.poolQuestionId, answers])

    const shuffleArray = (array: any) => {
        let reshuffle = false;
        for (let i = 0; i < array.length; i++) {
            if (array[i].id === answers[i].id) {
                reshuffle = true;
            }
        }

        if (!reshuffle) {
            return array;
        }

        let currentIndex = array.length,  randomIndex;

        // While there remain elements to shuffle.
        while (currentIndex > 0) {

            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }

        if (reshuffle) {
            shuffleArray(array);
        }

        return array;
    }


    useEffect(() => {
        if( sortableData.length > 0 ) {
            const mixAndMatchOrder = sortableData.map((item: any, index: number) => {
                return item.id
            })

            updateActiveQuestionData(activeQuestion, {
                mixAndMatchOrder: mixAndMatchOrder
            })
        }
    }, [sortableData])

    const moveSortableElement = useCallback((dragIndex: number, hoverIndex: number) => {
        setSortableData((prevData: any[]) =>
            update(prevData, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevData[dragIndex]],
                ],
            }),
        )
    }, [])

    const HTML5toTouch = {
        backends: [
            {
                id: "html5",
                backend: HTML5Backend,
                transition: MouseTransition
            },
            {
                id: "touch",
                backend: TouchBackend,
                options: { enableMouseEvents: true },
                preview: true,
                transition: TouchTransition
            }
        ]
    };

    const renderSortableElement = (sortableElement: any, index: number, sortableData: any) => {
        const answer = answers[index];
        const answerIndex = answers.findIndex((item: any) => item.id == sortableElement.id)

        const attemptAnswer = activeQuestion.attemptQuestion.attemptAnswers 
            ? activeQuestion.attemptQuestion.attemptAnswers.find((item: any) => item.answer_id == sortableElement.id) 
            : null;
        
        const sortableLetter = String.fromCharCode((index + 1) + 64);
        const answerLetter = String.fromCharCode((answerIndex + 1) + 64);

        // Naming is GOD tier
        // answer = question
        // sortableElement = answer

        return (
            <SortableElement
                answer={answer}
                sortableElement={sortableElement}
                attemptAnswer={attemptAnswer}
                sortableLetter={sortableLetter}
                answerLetter={answerLetter}
                key={sortableElement.id}
                index={index}
                id={sortableElement.id}
                moveSortableElement={moveSortableElement}
                isTestOverview={isTestOverview}
                hideCorrectAnswers={hideCorrectAnswers}
            />
        )
    }

    return (
        <DndProvider options={HTML5toTouch}>
            <div className='ma-sortable'>{sortableData && sortableData.length > 0 && sortableData.map((item: any, index: number) => renderSortableElement(item, index, sortableData))}</div>
            <DragPreview />
        </DndProvider>
    )
}

export default MixMatch;
