import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Table} from 'antd';
import {useProgramOverview} from "hooks/Program/useProgramOverview";

interface TopStudentTableProps {
    programId: number,
}

const TopStudentTable: React.FC<TopStudentTableProps> = ({programId}) => {
    const {topStudentTable, isLoading} = useProgramOverview();
    const [data, setData] = useState([]);

    const intl = useIntl();

    const columns = [
        {
            title: '#',
            width: '15%',
            key: 'nr',
            render: (_text: any, _row: any, index: number) => String(index + 1).padStart(2, '0'),
        },
        {
            title: intl.formatMessage({id: 'general.name'}),
            dataIndex: 'fullName',
        },
        {
            title: intl.formatMessage({id: 'campaign.score'}),
            dataIndex: 'result',
        },
    ];

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        setData(await topStudentTable(programId));
    }

    return (
        <>
            <p className="chart-title">{intl.formatMessage({id: 'campaign.top_students_by_score'})}</p>
            <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                scroll={{y: '20vw'}}
                size="small"
                loading={isLoading}
            />
        </>
    );
}

export default TopStudentTable;