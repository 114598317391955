import React, {useState, useEffect} from 'react';
import {useIntl} from 'react-intl';
import {Form, message, Select as AntDSelect} from 'antd';
import apiClient from 'utils/apiClient';
import {connect} from "react-redux";

const mapStateToProps = (state: any) => {
    return {
        session: state.session
    }
};

const CustomValuesSelect: React.FC<any> = ({
                                               customerId,
                                               customLayout,
                                               className,
                                               setLoading,
                                               saveLoadedData,
                                               setLoadedData,
                                               role,
                                               session,
                                               ...props
                                           }) => {
    const [options, setOptions] = useState<any>([]);
    const [isOptionsLoading, setIsOptionsLoading] = useState(false);

    const intl = useIntl();

    useEffect(() => {
        const loadCustomFields = async () => {
            try {
                setIsOptionsLoading(true);
                setLoading(true);
                let url = '/api/v1/customers/options/filters';

                if (
                    session.active_user_type === 'SUPER_ADMIN' ||
                    session.active_user_type === 'RESELLER_ADMIN' ||
                    session.active_user_type === 'DISTRIBUTOR_ADMIN'
                ) {
                    url = url + '?customerId=' + customerId;
                }
                let response = await apiClient.request(url, [], 'GET');
                setLoading(false);
                setOptions(response.filters);
                if (saveLoadedData) {
                    setLoadedData(response.filters);
                }
                setIsOptionsLoading(false);
            } catch (e) {
                console.error(e);
                message.error(intl.formatMessage({id: "error.data_load"}));
                setIsOptionsLoading(false);
            }
        };

        if (customerId) {
            loadCustomFields();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerId]);

    let labelCol: any = {span: 8};
    let wrapperCol: any = {span: 6};

    if (customLayout) {
        if (typeof customLayout === 'object') {
            labelCol = customLayout.labelCol || undefined;
            wrapperCol = customLayout.wrapperCol || undefined;
        } else {
            labelCol = undefined;
            wrapperCol = undefined;
        }
    }

    return (
        <>
            {options ?
                options.map((value: any) => {
                    return (
                        <Form.Item
                            className={className}
                            labelCol={labelCol}
                            wrapperCol={wrapperCol}
                            name={`${value['slug']}`}
                            label={value['name']}
                        >
                            <AntDSelect
                                loading={isOptionsLoading}
                                className={`default-select ${props.className || ''}`}
                                maxTagCount={2}
                                {...props}
                            >
                                {
                                    Object.values(value.fieldValues).map((filter: any) => {
                                        return (
                                            <AntDSelect.Option value={filter.id}>
                                                {filter.value}
                                            </AntDSelect.Option>
                                        );
                                    })
                                }
                            </AntDSelect>
                        </Form.Item>
                    );
                })
                : null
            }
        </>
    )
};

export default connect(mapStateToProps)(CustomValuesSelect);
