/* eslint-disable jsx-a11y/anchor-is-valid */

import { Button, List, Typography } from 'antd';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { UploadFile } from "antd/lib/upload/interface";
import SessionStatusBadge from '../SessionStatusBadge';
import SessionEditModal from '../Modals/SessionEditModal';
import EditSessionFilesModal from '../Modals/EditSessionFilesModal';
import FileDownload from "js-file-download";
import moment from 'utils/momentWithLocales';
import { LectureSessionType, SessionLectorProps, SessionProps, SessionResourceProps } from '../types';
import { useSession } from 'hooks/Lectures/useSession';
import { useNavigate } from 'react-router-dom';
import Spinner from 'components/Spinner';
import './styles.scss'

export interface MySessionDetailsProps {
    session: SessionProps,
    reloadSession?: () => void,
}

const MySessionDetails: React.FC<MySessionDetailsProps> = ({ session, reloadSession }) => {
    const [editDetails, setEditDetails] = useState<string[]>([]);
    const [editFiles, setEditFiles] = useState<boolean>(false);
    const { downloadSessionFile, cancelMySession, sessionLoading } = useSession();
    const navigate = useNavigate();

    const canCancel = !!session.actions && !!session.actions.cancel;
    const canEdit = !!session.actions && !!session.actions.edit;

    const downloadFile = async (file: UploadFile) => {
        if (session && session.lecturesId && session.id) {
            const download = await downloadSessionFile(session.lecturesId, session.id, +file.uid);
            if (!!download) {
                FileDownload(download, file.name);
            }
        }
    }

    const handleChangeDetails = () => {
        setEditDetails([]);
        if (reloadSession) {
            reloadSession();
        }
    }

    const cancelSession = async () => {
        if (canCancel && session && session.lecturesId && session.id) {

            const cancel = await cancelMySession(session.lecturesId, session.id);

            if (cancel && reloadSession) {
                navigate('/my-lectures');
            }
        }
    }

    const handleAddFiles = (changes: boolean) => {
        setEditFiles(false);
        if (!!changes && reloadSession) {
            reloadSession();
        }
    }

    const renderType = (type?: LectureSessionType) => {
        if (!type) {
            return '';
        }

        switch (type) {
            case LectureSessionType.Online:
                return <FormattedMessage id='general.lectures.online' />;
            case LectureSessionType.ClassRoom:
                return <FormattedMessage id='general.lectures.classroom' />
        }
    }

    return (
        <Spinner spinning={sessionLoading}>
            <div className="my-session-details">
                <Typography.Title level={4}>{session.title}</Typography.Title>
                <SessionStatusBadge status={session.status} />

                <div className='my-session-details--info'>
                    <div>
                        <div><i className="fa-solid fa-clock with-accent"></i></div>
                        <div>
                            <span>{moment(session.date).format("DD MMMM YYYY")}</span>
                            <span>{session.start?.slice(0, -3)}</span>
                            <span>{session.end?.slice(0, -3)}</span>
                            <span>{renderType(session.type)}</span>
                        </div>
                        <div></div>
                    </div>
                    <div>
                        <div><i className="fa-sharp fa-solid fa-building"></i></div>
                        <div>{session.locations?.map((location: SessionResourceProps) => !!location.name ? location.name : '---').join(', ')}</div>
                        <div></div>
                    </div>
                    <div>
                        <div><i className="fa-solid fa-user"></i></div>
                        <div>{session.lectors?.map((lector: SessionLectorProps) => !!lector.name ? lector.name : '---').join(', ')}</div>
                        <div></div>
                    </div>
                    <div>
                        <div><i className="fa-solid fa-link"></i></div>
                        <div><a href={session.link} target="_blank" rel="noreferrer">{session.link}</a></div>
                        <div>
                            <a onClick={() => {
                                if (session.link) {
                                    navigator.clipboard.writeText(session.link)
                                }
                            }}>
                            <i className="fa-sharp fa-solid fa-files"></i></a>
                        </div>
                    </div>
                </div>

                {canEdit && <Button onClick={() => setEditDetails(['link'])}>
                    <FormattedMessage id='general.edit' />
                </Button>}
                {canCancel && <div><Button danger onClick={cancelSession}>
                    <FormattedMessage id='general.lectures.cancel_registration' />
                </Button></div>}

                <div className='my-session-details--description'>
                    <div dangerouslySetInnerHTML={{ __html: session.description ?? '' }}></div>
                    {canEdit && <Button onClick={() => setEditDetails(['description'])}>
                        <FormattedMessage id='general.edit' />
                    </Button>}
                </div>

                {!!session.files && !!session.files.length && <>
                    <List bordered className='my-session-details--file'
                        dataSource={session.files}
                        renderItem={(file: UploadFile) => <div className='my-session-details--file-item'>
                            <i className="fa-sharp fa-solid fa-paperclip"></i>
                            <Typography.Text>{file.name}</Typography.Text>
                            <Button onClick={() => downloadFile(file)} type='link'>
                                <FormattedMessage id='general.download' />
                            </Button>
                        </div>}
                    />
                </>}
                {canEdit && <div><Button onClick={() => setEditFiles(true)}>
                    <FormattedMessage id='general.add_files' />
                </Button></div>}

                {canEdit && session && !!editDetails.length && <SessionEditModal
                    editDetails={editDetails}
                    visible={!!editDetails.length}
                    session={session}
                    onCancel={() => setEditDetails([])}
                    onSubmit={handleChangeDetails}
                />}

                {canEdit && session && editFiles && <EditSessionFilesModal
                    visible={editFiles}
                    session={session}
                    onCancel={handleAddFiles}
                />}
            </div>
        </Spinner>
    );
};

export default MySessionDetails;
