import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Form, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

interface FileInputProps {
  name: any;
  label?: string;
  accept?: string;
  fileSizeLimit?: number;
}

const FileInput: React.FC<FileInputProps> = ({ name, label, accept = '*', fileSizeLimit }) => {
  const [error, setError] = useState<string|false>(false);

  const intl = useIntl();

  const handleFileChange = (e: { file: File, fileList: File[] }) => {
    setError(false);

    if (fileSizeLimit) {
      const fileSizeInMb = e.file.size / 1024 / 1024;

      if (fileSizeInMb < 2) {
        e.fileList.pop();
        setError(intl.formatMessage({ id: 'system.image_upload_limit_2mb' }));

        return e && e.fileList;
      }
    }

    if (e.fileList.length > 1) {
      e.fileList.shift();

      return e && e.fileList;
    }

    return e && e.fileList;
  };

  return (
    <Form.Item
      name={name}
      label={label}
      valuePropName="fileList"
      getValueFromEvent={handleFileChange}
      extra={error && (
        <div className="ant-form-item-has-error">
          <div className="ant-form-item-explain">
            <div role="alert">{error}</div>
          </div>
        </div>
      )}
    >
      <Upload
        beforeUpload={() => false}
        accept={accept}
      >
        <Button icon={<UploadOutlined />}>
          <span><FormattedMessage id="general.upload_no_colon" /></span>
        </Button>
      </Upload>
    </Form.Item>
  );
}

export default FileInput;
