import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Modal, Form, Input, Badge } from 'antd';
import apiClient from 'utils/apiClient';

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

interface ConnectionTestModalInterface {
    visible: boolean;
    onCancel: any;
    form: any;
}

const ConnectionTestModal = ({
    visible,
    onCancel,
    form
}: ConnectionTestModalInterface) => {
    const [connection, setConnection] = useState<'error' | 'processing' | 'success'>('error');

    const [modalForm] = Form.useForm();
    const intl = useIntl();

    const submitForm = async (values: any) => {
        setConnection('processing');
        const formValues = form.getFieldsValue();

        try {
            const parsedValues = {
                username: values.username,
                password: values.password,
                settings: {
                    servers: formValues.connection.servers,
                    base_dn: formValues.connection.base_dn,
                    port: formValues.connection.port
                }
            }

            let response = await apiClient.request('/api/v1/auth/connection/test', parsedValues, 'POST');
            if (response.connection) {
                setConnection('success');
            } else {
                setConnection('error');
            }

        } catch (error) {
            setConnection('error');
        }
    }

    return (
        <Modal
            open={visible}
            onCancel={onCancel}
            title={intl.formatMessage({ id: 'system.connection_test' })}
            okText={intl.formatMessage({ id: 'system.test' })}
            cancelText={intl.formatMessage({ id: 'general.close' })}
            onOk={modalForm.submit}
        >
            <Form form={modalForm} onFinish={submitForm}>
                {connection !== 'success' &&
                    <>
                        <Form.Item
                            {...formItemLayout}
                            name='username'
                            label={intl.formatMessage({ id: 'system.username' })}
                            rules={[{ required: true, message: intl.formatMessage({ id: 'validation.field_required' }) }]}
                        >
                            <Input autoComplete="off" />
                        </Form.Item>
                        <Form.Item
                            {...formItemLayout}
                            name='password'
                            label={intl.formatMessage({ id: 'system.password' })}
                            rules={[{ required: true, message: intl.formatMessage({ id: 'validation.field_required' }) }]}
                        >
                            <Input autoComplete="off" type='password' />
                        </Form.Item>
                    </>
                }
                <Form.Item
                    {...formItemLayout}
                    name='password'
                    label={intl.formatMessage({ id: 'system.connection' })}
                >
                    <Badge status={connection} />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default ConnectionTestModal;
