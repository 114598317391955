import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Spinner from 'components/Spinner';
import { Stage, Layer, Image, Circle, Rect, Line } from 'react-konva';
import './styles.scss';

const HotspotImage: React.FC<any> = ({ 
    activeQuestion,
    updateActiveQuestionData,
    onChangeAnswers,
    form,
    onFormChange,
    imageUrl, 
    answers,
    isTestOverview,
    hideCorrectAnswers
}) => {
    const [isLoading, setIsLoading] = useState(false);

    // Shape data
    const [mainImage, setMainImage] = useState<HTMLImageElement>();
    const [canvasDimentions, setCanvasDimentions] = useState<any>({
        width: 500,
        height: 500
    });
    const [shapesData, setShapesData] = useState<any[]>([]);
    const [clickDots, setClickDots] = useState<any[]>([]);
    const [renderableShapes, setRenderableShapes] = useState<any[]>([]);
    
    const intl = useIntl();
    const fullImageRef = React.useRef<any>();
    const resizedImageRef = React.useRef<any>();
    const canvasImageRef = React.useRef<any>();
    const canvasRef = React.useRef<any>();
    const isProcessingClick = React.useRef<boolean>(false);
    const clickedShapeId = React.useRef<number|null|-1>(null);

    useEffect(() => {
        window.addEventListener('resize', () => {
            if(resizedImageRef.current) {
                resizeCanvas()
            }
        })
    }, [])

    useEffect(() => {
        if(imageUrl) {
            const baseImage = fullImageRef.current;
            baseImage.onload = function () {
                const image = new window.Image();
                image.src = imageUrl;
                image.onload = () => {
                    setMainImage(image)
                    setCanvasDimentions({
                        width: baseImage.width,
                        height: baseImage.height,
                    })
                }
            }
        }
    }, [imageUrl])

    useEffect(() => {
        if(activeQuestion.poolQuestionId || activeQuestion?.questionData?.clearHsSelection === 1) {
            let shapes: any[] = [];
            let answers: any[] = [...activeQuestion.question.answers];

            answers.map((item: any, key: number) => {
                if( item?.data && item?.data !== null ) {
                    shapes.push(item);
                }
            })

            updateActiveQuestionData(activeQuestion, {
                clearHsSelection: 0
            })

            setRenderableShapes([])
            setShapesData(shapes);
            setClickDots(activeQuestion?.questionData?.clickDots && activeQuestion?.questionData?.clickDots.length > 0 ? activeQuestion?.questionData?.clickDots : []);
        }
    }, [activeQuestion.poolQuestionId, activeQuestion?.questionData?.clearHsSelection])

    useEffect(() => {
        let renderedCorrectShapes: any[] = [];
        let allShapes = clickDots.map((dot: any) => {
            const dotShape = shapesData.find((shape: any) => {
                return dot.id !== null && dot.id && dot.id === shape.id;
            })

            let finalShape = {
                id: dot.id,
                isDot: 1,
                isCorrect: 0,
                shapeProps: {
                    strokeWidth: 2,
                    fill: "rgba(225,225,225,0.5)",
                    stroke: '#999999',
                },
                data: {
                    x: dot.x,
                    y: dot.y,
                    radius: 20,
                    type: 'CIRCLE'
                }
            }
            
            if(isTestOverview && !hideCorrectAnswers) {
                if(dot.id !== null && dot.id) {
                    if(!renderedCorrectShapes.includes(dot.id)) {
                        finalShape.isCorrect = 1;
                        finalShape.shapeProps.stroke = '#73d13d';
                        renderedCorrectShapes.push(dot.id)
                    }
                } else {
                    finalShape.isCorrect = 0;
                    finalShape.shapeProps.stroke = '#ff0003';
                }
            }

            return finalShape;
        });

        allShapes = [...allShapes, ...shapesData];

        setRenderableShapes(allShapes)
        updateActiveQuestionData(activeQuestion, {
            clickDots: clickDots
        })

        let selectedAnswers = clickDots.filter((item: any) => {
            return item.id !== null && item.id;
        }).map((item: any) => {
            return item.id;
        })
        selectedAnswers = selectedAnswers.filter((value, index, array) => array.indexOf(value) === index);
        onChangeAnswers(selectedAnswers)
    }, [clickDots])

    const resizeCanvas = () => {
        const fullImage = fullImageRef.current;
        const resizedImage = resizedImageRef.current;
        const scale = resizedImage.width / fullImage.width;
        const width = fullImage.width * scale;
        const height = fullImage.height * scale;
        
        canvasRef.current.width(width);
        canvasRef.current.height(height);
        canvasRef.current.scale({ x: scale, y: scale });
    }

    const onClickShape = (shape: any) => {
        if( isProcessingClick.current || activeQuestion.disableEditing ) {
            return;
        }

        isProcessingClick.current = true;

        // Check if clicked shape
        // is correct or incorrect
        // No need to do anything 
        // In case of incorrect shape
        // is clicked as we already
        // added incorrect shape
        if( ! shape?.isDot ) {
            clickedShapeId.current = shape.id;
        } else {
            clickedShapeId.current = -1;
        }

        isProcessingClick.current = false;
    }

    const onClickCanvas = (event: any) => {
        if( isProcessingClick.current || activeQuestion.disableEditing ) {
            return;
        }

        if(clickedShapeId.current === -1) {
            isProcessingClick.current = false;
            clickedShapeId.current = null;
            return;
        }
        
        isProcessingClick.current = true;

        // Create new dot
        const points = event.target.getStage().getPointerPosition();
        const newClickDots = [...clickDots, {
            x: points.x,
            y: points.y,
            id: clickedShapeId.current
        }]
        setClickDots(newClickDots)

        updateActiveQuestionData(activeQuestion, {
            hsClickCount: activeQuestion.hsClickedCount + 1
        })

        isProcessingClick.current = false;
        clickedShapeId.current = null;
    };

    const renderShape = (shape: any, index: number) => {
        const shapeInfo = shape.data;
        switch (shapeInfo?.type) {
            case 'CIRCLE':
                return <Circle 
                    key={index}
                    x={shapeInfo.x} 
                    y={shapeInfo.y} 
                    radius={shapeInfo.radius} 
                    onClick={() => onClickShape(shape)}
                    onTap={() => onClickShape(shape)}
                    onTouchEnd={() => onClickShape(shape)}
                    onTouchStart={() => onClickShape(shape)}
                    {...shape?.shapeProps}
                />
                break;
            case 'RECT':
                return <Rect 
                    key={index}
                    x={shapeInfo.x} 
                    y={shapeInfo.y} 
                    width={shapeInfo.width} 
                    height={shapeInfo.height} 
                    onClick={() => onClickShape(shape)}
                    onTap={() => onClickShape(shape)}
                    onTouchEnd={() => onClickShape(shape)}
                    onTouchStart={() => onClickShape(shape)}
                    {...shape?.shapeProps}
                />
                break;
            
            case 'LINE': 
                return <Line 
                    key={index}
                    points={shapeInfo.points}
                    tension={0.5}
                    lineCap="round"
                    lineJoin="round"
                    globalCompositeOperation='source-over'
                    onClick={() => onClickShape(shape)}
                    onTap={() => onClickShape(shape)}
                    {...shape?.shapeProps}
                    closed 
                />
                break;
        
            default:
                break;
        }
    }

    return (
        <Spinner spinning={isLoading}>
            <div className='hotspot-image'>
                <div style={{position: 'relative'}}>
                    <Stage
                        width={canvasDimentions.width}
                        height={canvasDimentions.height}
                        ref={canvasRef}
                        onClick={onClickCanvas}
                        onTouchStart={onClickCanvas}
                    >
                        <Layer>
                            <Image
                                x={0}
                                y={0}
                                image={mainImage}
                                ref={canvasImageRef}
                                width={canvasDimentions.width}
                                height={canvasDimentions.height}
                            />
                            {renderableShapes && renderableShapes.length > 0 ? renderableShapes.map((shape: any, i: number) => {
                                return renderShape(shape, i)
                            }) : null}
                        </Layer>
                    </Stage>
                    <img src={imageUrl} ref={fullImageRef} style={{
                        position: 'absolute', 
                        top: '0px', 
                        left: '0px',
                        opacity: 0,
                        visibility: 'hidden',
                        zIndex: '-1',
                        width: 710,
                        maxWidth: 'initial'
                    }} />
                    {mainImage && <img src={imageUrl} ref={resizedImageRef} onLoad={resizeCanvas} style={{
                        position: 'absolute', 
                        top: '0px', 
                        left: '0px',
                        opacity: 0,
                        visibility: 'hidden',
                        zIndex: '-1',
                        width: canvasDimentions.width,
                        height: canvasDimentions.height
                    }} />}
                </div>
            </div>
        </Spinner>
    )
}

export default HotspotImage;
