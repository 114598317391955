import React, {useEffect, useState} from 'react';
import FilterCheckBox from '../FilterCheckBox';
import FilterPopover from "../FilterPopover";
import {Button} from "ui";
import {FormattedMessage} from "react-intl";
import {useLecture} from "hooks/Lectures/useLecture";

interface AudienceFilterInterface {
    tagBuilder: Function;
    session?: any;
    setFilterValues: any;
    filterValues: any
    form: any;
    filterKey: string;
    setFilterKey: React.Dispatch<React.SetStateAction<string>>;
}

const AudienceFilter: React.FC<AudienceFilterInterface> = ({
                                                               filterKey,
                                                               setFilterKey,
                                                             form,
                                                             tagBuilder,
                                                             setFilterValues,
                                                             filterValues
                                                         }) => {
    const {searchLectors, lectureLoading: isLoading} = useLecture()
    const [data, setData] = useState([]);
    const [initialValues, setInitialValues] = useState(undefined)

    const key = 'lectors'

    useEffect(() => {
        onSearch()
    },[])

    const onSearch = async (value: string = '') => {
        const response = await searchLectors(value)
        setData(response);
        setFilterValues(
            {
                ...filterValues,
                lectors: {
                    ...filterValues.lectors,
                    ...response
                }
            }
        )
    };

    const content = (
        <>
            <FilterCheckBox data={data} name={'lectors'} onSearch={onSearch}/>
            <div className={'flex justify-between p-1'}>
                <Button type={'link'} onClick={() => handleClose()}>
                    <FormattedMessage id={'general.cancel'}/>
                </Button>
                <Button type={'primary'} onClick={() => {
                    setFilterKey('')

                    handleOpen(false)
                }}>
                    <FormattedMessage id={'general.select'}/>
                </Button>
            </div>
        </>
    );

    const handleClose = () => {
        form.setFieldsValue(initialValues);
        setFilterKey('')

    }

    const handleOpen = (open: boolean) => {
        if (open) {
            setInitialValues(form.getFieldsValue())
            setFilterValues(
                {
                    ...filterValues,
                    lectors: {
                        ...filterValues.lectors,
                        ...data
                    }
                }
            )
        } else {
            tagBuilder()
        }
    }

    return (
        <FilterPopover
            popoverKey={key}
            filterKey={filterKey}
            setOpen={setFilterKey}
            handleOpen={handleOpen}
            content={content}
            loading={isLoading}
            title={'general.search_by_lectors'}
            buttonTitle={'general.lectors'}
        />
    );
};

export default AudienceFilter;