import React from 'react';
import MyCertificates from "../../../Certificates/MyCertificates";

interface LectureTabProps {
    userTypeId: number;
}

const CertificatesTab: React.FC<LectureTabProps> = ({userTypeId}) => {

    return (
        <MyCertificates userTypeId={userTypeId}/>
    );
};

export default CertificatesTab;
