import React, {useState, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {message, Button, Form, Row, Col, Modal} from "antd";
import generalHelpers from "utils/generalHelpers";
import {changeLearningTestAttemptData} from 'store/actions/learningTestAttemptData';
import DefaultLayout from "components/DefaultLayout";
import useHandleError from "utils/useHandleError";
import ActiveQuestion from "components/LearningTest/TestAttempt/Questions/ActiveQuestion";
import Timer from "components/LearningTest/TestAttempt/Timer";
import coursesApiClient from "utils/coursesApiClient";
import Spinner from "components/Spinner";
import CompletedPage from "../Completed";

import './styles.scss';
import {ExclamationCircleOutlined} from "@ant-design/icons";

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale,
        attemptStoreData: state.learningTestAttemptData
    };
};
const mapDispatchToProps = (dispatch: any) => ({
    changeLearningTestAttemptData: (data: any) => dispatch(changeLearningTestAttemptData(data)),
});

const formItemLayout = {
    labelCol: {span: 24},
    wrapperCol: {span: 24},
};

interface CurrentQuestionType {
    poolQuestionId: number;
    questionIndex: number;
    question: any,
    attemptQuestion: any;
    questionData: any;
    selectedAnswers: any,
    isAnswerChanged: boolean;
    isAnswerSelected: boolean;
    disableEditing: boolean;
    hsTotalClicks: number;
    hsClickedCount: number;
    savedMixAndMatchOrder: any;
    questionImageUrl: any;
}

const currentQuestionDefault: CurrentQuestionType = {
    poolQuestionId: 0,
    questionIndex: 0,
    question: null,
    attemptQuestion: null,
    questionData: null,
    selectedAnswers: null,
    isAnswerChanged: false,
    isAnswerSelected: false,
    disableEditing: false,
    hsTotalClicks: 0,
    hsClickedCount: 0,
    savedMixAndMatchOrder: null,
    questionImageUrl: null,
};

const TestAttempt: React.FC<any> = ({ 
    locale, 
    changeLearningTestAttemptData, 
    learningTest, 
    programActivityAttemptId, 
    isPreview,
    isOverview,
    isStudentAttempt,
    attemptId,
    setAttemptId,
    attemptStoreData,
}) => {
    const [testAttempt, setTestAttempt] = useState<any>();
    const [isTestOverview, setIsTestOverview] = useState<boolean>(!!isOverview);
    const [isAttemptCompleted, setIsAttemptCompleted] = useState<boolean>(false);
    const [activeQuestion, setActiveQuestion] = useState<CurrentQuestionType>(currentQuestionDefault);
    const [questions, setQuestions] = useState<any[]>([]);

    const intl = useIntl();
    const { confirm } = Modal;

    const params = useParams();
    const [handleError] = useHandleError();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(!!learningTest.id);
    const [isLoadingQuestion, setIsLoadingQuestion] = useState(false);
    const questionIndex = React.useRef<number|null>(null);

    useEffect(() => {
        if (learningTest) {
            loadAttempt()
        }
    }, [learningTest])

    useEffect(() => {
        if (testAttempt && questionIndex.current === null && !isLoading) {
            startTestAttempt()
        }
    }, [testAttempt, isLoading])

    useEffect(() => {
        if (attemptStoreData?.attempt) {
            setTestAttempt(attemptStoreData?.attempt)
        }
    }, [attemptStoreData.attempt])

    useEffect(() => {
        if (attemptStoreData?.questions) {
            let newQuestions: any = [];
            if(isTestOverview) {
                newQuestions = attemptStoreData?.questions.filter((item: any) => {
                    return item?.isAttempted;
                })
            } else {
                newQuestions = attemptStoreData?.questions;
            }
            setQuestions(newQuestions)
        }
    }, [attemptStoreData.questions, isTestOverview])

    useEffect(() => {
        if (activeQuestion.poolQuestionId) {
            form.setFieldsValue({
                [`selectedAnswers${activeQuestion.poolQuestionId}`]: activeQuestion.selectedAnswers
            })
        }
    }, [activeQuestion.poolQuestionId])

    const getValidatedAnswers = (activeQuestion: any, updatedAnswers: any = undefined) => {
        let selectedAnswers: any = null;
        if( ['MC', 'RMC', 'HS'].includes(activeQuestion.question.type) ) {
            selectedAnswers = [];
            if(updatedAnswers !== undefined && Array.isArray(updatedAnswers) && updatedAnswers.length > 0) {
                selectedAnswers = updatedAnswers;
            } 
            else if( activeQuestion.attemptAnswers && activeQuestion.attemptAnswers.length > 0 ) {
                selectedAnswers = generalHelpers.pluck(activeQuestion.attemptAnswers, 'answer_id');
            }
        }
        else if( ['OC'].includes(activeQuestion.question.type) ) {
            selectedAnswers = '';
            if(updatedAnswers !== undefined && !Array.isArray(updatedAnswers)) {
                selectedAnswers = updatedAnswers;
            } 
            else if( activeQuestion.attemptAnswers && activeQuestion.attemptAnswers.length > 0 ) {
                selectedAnswers = activeQuestion.attemptAnswers[0].answer_id;
            }
        }
        else if( ['WA'].includes(activeQuestion.question.type) ) {
            selectedAnswers = '';
            if(updatedAnswers !== undefined && !Array.isArray(updatedAnswers)) {
                selectedAnswers = updatedAnswers;
            } 
            else if( activeQuestion.attemptAnswers && activeQuestion.attemptAnswers.length > 0 ) {
                selectedAnswers = activeQuestion.attemptAnswers[0].answer_text;
            }
        }
        return selectedAnswers;
    }

    const setNewCurrentQuestion = (newQuestion: any, index: any) => {
        // Set active questions data
        let questionData = newQuestion.data ? newQuestion.data : {};
        if( questionData && ! questionData?.answersOrder ) {
            const answersOrder = newQuestion.question.answers.map((answer: any, index: number) => {
                return answer.id
            })

            questionData = {...questionData, answersOrder: answersOrder}
        }

        const activeQuestionData = {
            ...currentQuestionDefault,
            poolQuestionId: newQuestion.questionId,
            questionIndex: index,
            question: newQuestion.question,
            attemptQuestion: newQuestion,
            questionData: questionData,
            selectedAnswers: getValidatedAnswers(newQuestion),
        };

        updateActiveQuestionData(activeQuestionData)
    }

    const updateActiveQuestionData = (newData: any, questionData: any = null) => {
        let data: any = newData ? newData : {};
        const question = data?.question ? data?.question : activeQuestion.question; 
        let selectedAnswers = data?.selectedAnswers ? data?.selectedAnswers : activeQuestion.selectedAnswers;
        let newQuestionData = data?.questionData ? data?.questionData : activeQuestion.questionData;

        // Update question data
        if(questionData) {
            newQuestionData = {...newQuestionData, ...questionData};
        }

        let answers = [];

        if(selectedAnswers && Array.isArray(selectedAnswers) && selectedAnswers.length  ) {
            const result = selectedAnswers.every((value: any) => question.answers.some((obj: any) => obj.id === value));
            if (!result) {
                selectedAnswers = null;
            }
        } else if (selectedAnswers) {
            answers = question.answers.filter((el: any) => el.id === selectedAnswers )

            if (!answers.length) {
                selectedAnswers = null;
            }
        }

        if (question) {
            // If it is hotspot question
            // check already clicked counts
            // In case of overview or
            // taking partial test
            if( question.type == 'HS' ) {
                const hsTotalClicks = question?.hs_click_limit 
                    ? parseInt(question.hs_click_limit) 
                    : 0;
                const clickedCount = newQuestionData && newQuestionData?.hsClickCount ? parseInt(newQuestionData.hsClickCount) : 0;
                data.isAnswerSelected = clickedCount > 0;

                // Disable hotspot question editing
                if(hsTotalClicks > 0) {
                    data.disableEditing = isTestOverview || clickedCount >= hsTotalClicks;
                }

                data.hsTotalClicks = hsTotalClicks;
                data.hsClickedCount = clickedCount;

                // Load hotspot image
                if( question?.storageFiles?.thumbnail?.fileId ) {
                    data.questionImageUrl = question.storageFiles.thumbnail.fileData;
                }
            }

            // If MixMatch question type
            // set default sorting as per
            // user already saved answers
            else if (question.type == 'MA') {
                const savedMixAndMatchOrder = newQuestionData && newQuestionData?.mixAndMatchOrder ? newQuestionData.mixAndMatchOrder : [];
                data.savedMixAndMatchOrder = savedMixAndMatchOrder;
                data.isAnswerSelected = savedMixAndMatchOrder && savedMixAndMatchOrder.length > 0;
            }

            // Check if question answer 
            // is selected to enable next or skip
            if(!['HS', 'MA'].includes(question.type)) {
                if( (!selectedAnswers) || (selectedAnswers && Array.isArray(selectedAnswers) && selectedAnswers.length === 0) ) {
                    data.isAnswerSelected = false;
                } else {
                    data.isAnswerSelected = true;
                }
            }
            if (question.type === 'WA' && !!data.selectedAnswers) {
                data.isAnswerSelected = true;
            }

            if(isTestOverview) {
                data.disableEditing = true;
            }
        }

        data.questionData = newQuestionData;
        const currentQuestionData = {...activeQuestion, ...data};
        setActiveQuestion(currentQuestionData)
    }

    const updateAttemptStoreData = (attempt: any, question: any = null, questionIndex: any = null) => {
        let newAttempt = attemptStoreData?.attempt;
        let newQuestions = attemptStoreData?.questions;

        if( question && questionIndex !== null ) {
            newQuestions[questionIndex] = {...newQuestions[questionIndex], ...question};
            setNewCurrentQuestion(newQuestions[questionIndex], questionIndex)
        }
        
        if( attempt ) {
            newAttempt = {...newAttempt, ...attempt};
        }

        changeLearningTestAttemptData({
            attempt: newAttempt,
            questions: newQuestions,
        })

        if( attempt ) {
            setTestAttempt(newAttempt)
        }
    };

    const loadAttempt = async () => {
        setIsLoading(true);
        try {
            const response = await coursesApiClient.request(`/api/v1/courses/learning-tests/test-attempts/${learningTest.id}/get-or-create`, {attemptId : testAttempt?.id ? testAttempt?.id : attemptId, programActivityAttemptId}, 'POST');
            setIsTestOverview(response.attempt.attemptStatus != 'IN_PROGRESS' || isOverview)
            questionIndex.current = null;
            changeLearningTestAttemptData({
                attempt: response.attempt,
                questions: response.questions,
            })
        } catch (error) {
            handleError(error)
            window.location.reload()
        } finally {
            setIsLoading(false);
        }
    };

    const loadQuestionFromCache = (questionIndex: number) => {
        let question : any = null;
        if(questionIndex !== null && questions[questionIndex]?.isAttempted && questions[questionIndex]?.question) {
            question = questions[questionIndex];
        }
        return question;
    };

    const loadActiveQuestion = async (questionIndex: number) => {
        const poolQuestionId = questions[questionIndex].questionId;

        // check if question is already loaded
        if(activeQuestion && activeQuestion?.question?.id === poolQuestionId) {
            return;
        }

        setIsLoadingQuestion(true);
        try {
            let question : any = loadQuestionFromCache(questionIndex);
            if(question !== null) {
                setNewCurrentQuestion(question, questionIndex)
            } else {
                const response = await coursesApiClient.request(`/api/v1/courses/learning-tests/test-attempts/${poolQuestionId}/get-active-question`, {poolQuestionId, attemptId: testAttempt?.id ? testAttempt?.id : 0, isPreview, isTestOverview}, 'GET');
                question = response.question;
                updateAttemptStoreData(null, question, questionIndex)
            }
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoadingQuestion(false);
        }
    };

    const submitForm = async (values: any) => {
        setIsLoadingQuestion(true);
        
        const nextQuestionIndex: any = getNextQuestionIndex();
        const nextQuestionId = nextQuestionIndex ? questions[nextQuestionIndex].questionId : null;
        const parsedValues = {
            ...values,
            attemptId: testAttempt?.id ? testAttempt?.id : 0,
            selectedAnswers: activeQuestion.selectedAnswers,
            data: activeQuestion.questionData,
            isPreview,
            isTestOverview,
            nextQuestionId: nextQuestionId
        };

        let response : any;
        
        try {
            response = await coursesApiClient.request(`/api/v1/courses/learning-tests/test-attempts/${activeQuestion.poolQuestionId}/save-question-answer`, parsedValues, 'POST');
            updateAttemptStoreData(null, response.currentQuestion, questionIndex.current)

            if( response.completeAttempt ) {
               await checkCompletion(response)
            } else {
                questionIndex.current = nextQuestionIndex;
                let question : any = loadQuestionFromCache(nextQuestionIndex);
                if(question !== null) {
                    setNewCurrentQuestion(question, nextQuestionIndex)
                } else {
                    question = response.nextQuestion;
                    updateAttemptStoreData(null, question, nextQuestionIndex)
                }
                setIsLoadingQuestion(false);
            }
        } catch (error) {
            handleError(error)
            setIsLoadingQuestion(false);
        }
    };

    const checkCompletion = async (answerResponse: any):Promise<void> => {
        try {
            const response = await coursesApiClient.request(`/api/v1/courses/learning-tests/test-attempts/${testAttempt.id}/check-completion`, [], 'POST');

            if (!response.summary.completedAll) {
                confirm({
                    title: intl.formatMessage({ id: 'general.attention' }),
                    content: intl.formatMessage({ id: 'learning_tests.completion_warning' }, {total: response.summary.total, completed: response.summary.completed}),
                    icon: <ExclamationCircleOutlined />,
                    okText: intl.formatMessage({ id: 'general.submit' }),
                    cancelText: intl.formatMessage({ id: 'general.cancel' }),

                    onOk() {
                        completeAttempt(answerResponse.isFailedCriticalQuestion);
                    },

                    onCancel() {
                        setIsLoadingQuestion(false);
                    }
                });
            } else if (response.summary.completedAll) {
                completeAttempt(response.isFailedCriticalQuestion);
            }

        } catch (error) {
            handleError(error)
            setIsLoadingQuestion(false);
        }
    }

    const completeAttempt = async (isFailedCriticalQuestion: boolean = false) => {
        // Lets calculate attempt result
        if( isPreview ) {
            let attemptScores: number = 0;
            let attempt = attemptStoreData.attempt;

            if(!isFailedCriticalQuestion) {
                questions.map((question: any) => {
                    if(question.isPassed) {
                        attemptScores += parseFloat(question.pointsAwarded);
                    }
                })
    
                // Calculate passing score in percentage
                if( attempt.scoreType == 'PERCENTAGE' ) {
                    const passingScore = parseFloat(attempt.passingScore);
                    const totalScore = parseFloat(attempt.totalScore);
    
                    const userPercentage = totalScore > 0 ? (attemptScores / totalScore) * 100 : 0;
                    if( userPercentage >= passingScore ) {
                        attempt.attemptStatus = 'PASSED';
                    } else {
                        attempt.attemptStatus = 'FAILED';
                    }
                }
                // Calculate passing score in points
                else {
                    const passingScore = parseFloat(attempt.passingScore);
    
                    if( attemptScores >= passingScore ) {
                        attempt.attemptStatus = 'PASSED';
                    } else {
                        attempt.attemptStatus = 'FAILED';
                    }
                }
            } else {
                attempt.attemptStatus = 'FAILED';
            }

            attempt.attemptScore = attemptScores;
            updateAttemptStoreData(attempt, null)
            setIsAttemptCompleted(true)
            setIsTestOverview(true)
        } else if( isTestOverview ) {
            setIsAttemptCompleted(true)
        } else {
            setIsLoading(true);

            let response;

            try {
                response = await coursesApiClient.request(`/api/v1/courses/learning-tests/test-attempts/${testAttempt.id}/complete-attempt`, {programActivityAttemptId, isFailedCriticalQuestion}, 'POST');
                if( response?.testAttempt ) {
                    updateAttemptStoreData(response?.testAttempt, null)
                    setIsAttemptCompleted(true)
                    setIsTestOverview(true)
                }
            } catch (error) {
                handleError(error)
            } finally {
                setIsLoading(false);
            } 
        }
    };

    const timeUpCallback = async () => {
        completeAttempt();
    };

    const onFormChange = (values: any) => {
        const selectedAnswers = form.getFieldValue('selectedAnswers');
        updateActiveQuestionData({
            selectedAnswers: getValidatedAnswers(activeQuestion, selectedAnswers)
        })
    };   
    
    const onChangeAnswers = (selectedAnswers: any) => {
        updateActiveQuestionData({
            selectedAnswers: getValidatedAnswers(activeQuestion, selectedAnswers)
        })
    };

    const startTestAttempt = (isOverview: boolean = false) => {
        // Skip overview if student overview
        // is disabled in test settings
        if(isTestOverview) {
            if( testAttempt.allowTestReview === 0 ) {
                setIsAttemptCompleted(true)
                questionIndex.current = null;
            } else {
                setIsAttemptCompleted(false)
                questionIndex.current = 0;
                loadActiveQuestion(questionIndex.current)
            }
        } else if( questions.length > 0 ) {
            setIsAttemptCompleted(false)
            questionIndex.current = 0;
            loadActiveQuestion(questionIndex.current)
        } else {
            message.error(intl.formatMessage({id: 'learning_tests.test_have_no_question'}))
        }
    }

    const getPreviousQuestion = () => {
        if( questionIndex.current !== null && questionIndex.current >= 1 ) {
            questionIndex.current--;
            loadActiveQuestion(questionIndex.current);
        }
    }

    const getNextQuestion = () => {
        if( questionIndex.current !== null && questionIndex.current < (questions.length - 1) ) {
            questionIndex.current++;
            loadActiveQuestion(questionIndex.current);
        }
    }

    const getNextQuestionIndex = () => {
        if( questionIndex.current !== null && questionIndex.current < (questions.length - 1) ) {
            return questionIndex.current + 1;
        }
    }
    
    const showPreviousButton = () => {
        if (isTestOverview && !isFirstQuestion()) {
            return true
        }
        // return !isFirstQuestion() && !isLoadingQuestion && !isAnswerUpdated;
        return !!(testAttempt.allowSkipping && !isFirstQuestion() && !isLoadingQuestion);
    }

    const showNextButton = () => {
        return (
            !isLastQuestion() && 
            !activeQuestion.question.is_critical && 
            testAttempt.allowSkipping  && 
            !isLoadingQuestion
        ) || (
            isTestOverview && 
            !isLoadingQuestion &&
            !isLastQuestion()
        );
    }

    const isFirstQuestion = () => {
        return questionIndex.current !== null && questionIndex.current === 0;
    };

    const isLastQuestion = () => {
        return questionIndex.current !== null && questionIndex.current === (questions.length - 1);
    };

    const getQuestionIdKey = (questionIdPrefix: string|null, id: number) => {
        if(questionIdPrefix) {
            return `${intl.formatMessage({id: 'general.id'})}-${questionIdPrefix}-${id}`;
        } else {
            return `${intl.formatMessage({id: 'general.id'})}${id}`
        }
    };

    const closeLearningTest = () => {
        if (window.location.pathname === '/student/show-activity') {
            window.close();
        } else {
            navigate('/')
        }
    }


    return (
        <>
        {isAttemptCompleted ? <CompletedPage 
            isParentLoading={isLoading}
            setIsAttemptCompleted={setIsAttemptCompleted}
            testAttempt={testAttempt}
            learningTest={learningTest}
            startTestAttempt={startTestAttempt}
            isPreview={isPreview}
        /> : 
            <DefaultLayout.PageLayout style={{marginTop: '0px'}}>
                <DefaultLayout.PageContent style={{padding: 0}}>
                    <Spinner spinning={isLoading || isLoadingQuestion || !testAttempt}>
                        {activeQuestion.question ? <Form form={form} onFinish={submitForm} {...formItemLayout}>
                            <div className='test-attempt-page'>
                                <Row align='middle' justify='space-between' className='test-attempt-header'>
                                    <Col>{activeQuestion.question ? <span>{activeQuestion.attemptQuestion.questionNumber} of {testAttempt.questionsCount} <span style={{textTransform: 'uppercase', color: '#999'}}><span style={{margin: '0px 10px'}}>|</span>{getQuestionIdKey(activeQuestion.question?.question_bank.id_key, activeQuestion.poolQuestionId)}</span></span> : null}<></></Col>
                                    <Col>
                                        {isTestOverview ? null : <Timer 
                                            initialTime={testAttempt.timeLimitStatus ? testAttempt.timeLimit : 999999999} 
                                            testAttempt={testAttempt} 
                                            resetTimer={false}
                                            timeUpCallback={timeUpCallback}
                                            updateAttemptStoreData={updateAttemptStoreData}
                                        />}
                                    </Col>
                                </Row>
                                <div className={`test-attempt-card question-type-${activeQuestion.poolQuestionId ? activeQuestion?.question.type : 'default'}`}>
                                    {activeQuestion.question && <ActiveQuestion 
                                        learningTest={learningTest}
                                        attemptId={testAttempt.id} 
                                        activeQuestion={activeQuestion} 
                                        updateActiveQuestionData={updateActiveQuestionData} 
                                        onChangeAnswers={onChangeAnswers}
                                        isTestOverview={isTestOverview}
                                        form={form}
                                    />}
                                </div>
                                {activeQuestion && <Row align='middle' justify='space-between' className='test-attempt-footer'>
                                    <Col>
                                        {showPreviousButton() ? <Button type='ghost' style={{marginRight: 10}} onClick={() => getPreviousQuestion()}><i className='fa fa-arrow-left' /> <FormattedMessage id="learning_tests.previous" /></Button> : null}
                                        {showNextButton() ? <Button type='ghost' onClick={() => getNextQuestion()}><FormattedMessage id="learning_tests.next" /> <i className='fa fa-arrow-right' /></Button> : null}
                                    </Col>
                                    <Col>
                                        {isTestOverview ? (!isPreview && !isOverview && <Button type='primary' ghost onClick={() => closeLearningTest()} disabled={!activeQuestion.isAnswerSelected || isLoadingQuestion}><FormattedMessage id="general.close" /></Button>) : <Button type='primary' onClick={form.submit} disabled={!activeQuestion.isAnswerSelected || isLoadingQuestion}><FormattedMessage id="general.submit" /></Button>}
                                    </Col>
                                </Row>}
                            </div>
                        </Form> : <div style={{height: '400px', width: '100%'}}></div>}
                    </Spinner>
                </DefaultLayout.PageContent>
            </DefaultLayout.PageLayout>}
        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(TestAttempt);
