import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Radio, Space, Tooltip } from 'antd';
import { Modal } from 'ui';
import { Input } from 'components/Form';
import apiClient from 'utils/apiClient';
import useHandleError from 'utils/useHandleError';
import { AudienceTypes } from 'components/Audiences/types';


const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

interface AudienceCreationModaltInterface {
    visible?: boolean | undefined;
    onCancel(cancel: boolean): any;
    onSubmit(id: number|undefined): any;
}

const AudienceCreationModal: React.FC<AudienceCreationModaltInterface> = ({ visible = false, onCancel, onSubmit }) => {
    const [loading, setLoading] = useState(false);
    const [handleError] = useHandleError();
    const intl = useIntl();
    const [form] = Form.useForm();

    const handleOnCancel = () => {
        form.resetFields();
        onCancel(false)
    }

    const handleFormSubmit = async (values: any) => {
        setLoading(true)

        const audienceData: any = {
            name: values.name,
            type: values.type,
        }

        try {
            const response = await apiClient.request('/api/v1/audiences', audienceData, 'POST');
            if ("error" in response) {
                throw response
            };

            setLoading(false);
            form.resetFields();
            onSubmit(response.audience && response.audience.id ? response.audience.id : undefined);
        } catch (error) {
            setLoading(false);
            handleError(error);
        }
    };

    return (
        <Modal
            open={visible}
            isLoading={loading}
            onCancel={() => handleOnCancel()}
            title={intl.formatMessage({ id: 'audience.new_audience' })}
            okText={intl.formatMessage({ id: 'general.create' })}
            cancelText={intl.formatMessage({ id: 'general.cancel' })}
            onOk={form.submit}
        >
            <Form form={form} onFinish={handleFormSubmit} {...formItemLayout}>
                <Input
                    name='name'
                    label={intl.formatMessage({ id: 'audience.audience_name' })}
                    customRules={[{ required: true, message: intl.formatMessage({ id: 'validation.field_required' }) }]}
                    customLayout={true} {...formItemLayout}
                />

                <Form.Item name='type'
                    label={
                        <span>
                            {intl.formatMessage({ id: 'general.type' })}
                            <Tooltip title={intl.formatMessage({ id: 'audience.new_audience_hint' })}>
                                <i className='fal fa-info-circle' />
                            </Tooltip>
                        </span>
                    }
                    rules={[{ required: true, message: intl.formatMessage({ id: 'validation.field_required' }) }]}
                >
                    <Radio.Group>
                        <Space direction="vertical">
                            <Radio value={AudienceTypes.Dynamic} defaultChecked>
                                <FormattedMessage id='audience.dynamic' />
                            </Radio>
                            <Radio value={AudienceTypes.Strict}>
                                <FormattedMessage id='audience.strict' />
                            </Radio>
                        </Space>
                    </Radio.Group>
                </Form.Item>
            </Form>
        </Modal>
    )
};
export default AudienceCreationModal;
