import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { message, Modal, Upload, Form, Button } from 'antd';
import coursesApiClient from 'utils/coursesApiClient';
import {config} from 'config/config';

import Spinner from 'components/Spinner';
import { UploadOutlined } from '@ant-design/icons';
import { Select } from 'components/Form';

const formItemLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 18},
};

const QuestionsImportModel: React.FC<any> = ({ visible, onCancel, questionBankId }) => {
    const [submitInProgress, setSubmitInProgress] = useState(false);
    const [importFileList, setImportFileList] = useState<any>([]);

    const intl = useIntl();
    const [importForm] = Form.useForm();
    const {Dragger} = Upload;

    const normFile = (e: any) => {
        let fileList = [...e.fileList];

        fileList = fileList.slice(-1);
        setImportFileList(fileList);
        if (Array.isArray(e)) {
            return e;
        }
        return e && fileList;
    };

    const customerDraggerRequest = ({onSuccess}: any) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const submitForm = async (values: any) => {
        setSubmitInProgress(true);
        try {
            let file: any;

            if (values && values.dragger) {
                file = values.dragger[0];
            }
            
            if (!file) {
                setSubmitInProgress(false);
                return message.error(intl.formatMessage({ id: "question_pools.file_not_uploaded" }));
            }

            const formData = new FormData();
            formData.append('file', file.originFileObj);

            const response = await coursesApiClient.request(`/api/v1/courses/question-pools/question-banks/${questionBankId}/import`, formData, 'POST', false, true);

            if( response ) {
                message.success(intl.formatMessage({ id: "question_pools.import_started"}));   
                onCancel();
                //window.location.reload();
            } else {
                message.error(intl.formatMessage({id: "question_pools.import.unsuccessful_import_in_message"}));
            }

        } catch (error) {
            message.error(intl.formatMessage({ id: "question_pools.import_unsuccessful" }));
            console.error(error);
        } finally {
            setImportFileList([]);
            setSubmitInProgress(false);
            importForm.setFieldsValue({
                dragger: null
            });
        }
    }
    
    return (
        <Form form={importForm} onFinish={submitForm}>
            <Modal
                className="import-modal"
                title={intl.formatMessage({ id: 'question_pools.questions_import_modal' })}
                open={visible}
                onOk={() => { importForm.submit() }}
                onCancel={onCancel}
                okText={intl.formatMessage({ id: 'question_pools.import' })}
                cancelText={intl.formatMessage({ id: 'general.cancel' })}
            >
                <Spinner spinning={submitInProgress}>
                    <p>
                        <FormattedMessage id="question_pools.import.first_paragraph"/>
                    </p>
                    <p>
                        <FormattedMessage id="question_pools.import.second_paragraph"/>
                    </p>
                    <p>
                        <FormattedMessage id="question_pools.import.upload_file_template"/>
                        <a
                            href={config.api.frontendUrl + '/ImportQuestionsTemplate.xlsx'}
                            download
                        >
                            ImportQuestionsTemplate.xlsx
                        </a>
                    </p>

                    <Form.Item
                        name='dragger'
                        valuePropName="fileList" 
                        getValueFromEvent={normFile}
                        label={intl.formatMessage({ id: 'question_pools.import_questions' })}
                    >
                        <Dragger
                            className={importFileList.length ? 'hide-dragger' : ''}
                            customRequest={({onSuccess, file}) => customerDraggerRequest({onSuccess, file})}
                            name="file"
                            style={{
                                backgroundColor: 'white',
                                borderStyle: 'none',
                                padding: '0',
                                margin: 0,
                                cursor: 'initial'
                            }}
                            multiple={false} accept=".xlsx, .xls"
                        >
                            <div style={{textAlign: 'left'}}>
                                <FormattedMessage id="general.upload"/>
                                <div className="upload-file user-name-style" style={{border: 0, paddingLeft: '3px'}}>
                                    <FormattedMessage id="users.choose_file"/>
                                </div>
                            </div>
                            <div
                                style={{
                                    border: 1,
                                    borderStyle: 'dashed',
                                    padding: '40px',
                                    cursor: 'pointer',
                                    backgroundColor: '#fafafa',
                                    borderColor: '#DCDCDC'
                                }}
                            >
                                <i style={{fontSize: 50, color: '#D9D9D9', padding: 10}}
                                   className='fal fa-file-upload'/>
                                <p className='ant-upload-text'>
                                    <FormattedMessage id='users.import.click_or_drag_file_to_this_area_to_upload'/>
                                </p>
                            </div>
                        </Dragger>
                    </Form.Item>
                </Spinner>
            </Modal>
        </Form>
    )
}

export default QuestionsImportModel;
