import React, { useEffect, useRef, useState } from 'react';
import { useLecture } from "hooks/Lectures/useLecture";
import { Button, Select, Space } from 'antd';
import { NavLink } from 'react-router-dom';
import './styles.scss';
import { FormattedMessage } from 'react-intl';
import { DefaultOptionType } from 'antd/lib/select';
import { SessionLectorProps } from '../types';

interface AddLectorsInterface {
    value?: number[],
    onChange?(values: number[]): void,
}

const AddLectors: React.FC<AddLectorsInterface> = ({ value, onChange }) => {
    const { getLectors, lectureLoading } = useLecture();
    const [lectors, setLectors] = useState<number[]>(value || []);
    const [options, setOptions] = useState<SessionLectorProps[]>([]);
    const [selected, setSelected] = useState<number>();


    const didMountRef = useRef(false);

    useEffect(() => {
        if (!didMountRef.current) {
            didMountRef.current = true;
        } else {
            onChange && onChange(lectors);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lectors]);

    useEffect(() => {
        if (!!value) {
            setLectors(value);
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    useEffect(() => {
        const loadLectors = async () => setOptions(await getLectors());

        loadLectors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleAdd = () => {
        if (selected) {
            const lector = options.find(option => option.id === +selected);

            if (lector) {
                setLectors(Array.from(new Set([...lectors, +selected])));
                setSelected(undefined);
            }
        }
    }

    const filteredOptions = options.length ? options.map((option) => {
        if (!lectors.map(lector => lector).includes(option.id)) {
            return {
                value: option.id,
                label: option.name,
            }
        }
    }).filter(item => item) as DefaultOptionType[] : [];

    const removeLector = (value: number) => {
        setLectors(lectors.filter((lector) => lector !== value));
    }

    return (<>
        <Space className="lectors-select-space-container">
            <Select className='default-select'
                value={selected}
                showSearch
                optionFilterProp="children"
                onChange={setSelected}
                filterOption={(input, option:any) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={filteredOptions ?? []}
                loading={lectureLoading}
            />
            <Button type="primary" onClick={handleAdd}>
                <FormattedMessage id='general.add' />
            </Button>
        </Space>
        <Space size={[8, 8]} wrap>
            {lectors.map((lector, index) => {
                const current = options.find((option) => option.id === lector);
                if (current) {
                    return (
                        <div>
                            <NavLink to={`#`}>{current.name}</NavLink>
                            <Button style={{
                                color: "#8C8C8C",
                                verticalAlign: 0
                            }}
                                onClick={() => removeLector(lector)}
                                size="small" type="link" key={index} icon={<i className="fa-solid fa-xmark" />} />
                        </div>

                    )
                }
            })}
        </Space>
    </>
    )
};

export default AddLectors;
