import { AppearanceSettings } from 'interfaces/redux';

export const CHANGE_LOCALE = 'CHANGE_LOCALE';

export const CHANGE_SIDEBAR_COLLAPSED = 'CHANGE_SIDEBAR_COLLAPSED';

export const CHANGE_RESET_PASSWORD_EMAIL_IS_SENT = 'CHANGE_RESET_PASSWORD_EMAIL_IS_SENT';

export const SAVE_USER_TABLE_FILTER = 'SAVE_USER_TABLE_FILTER';
export const SAVE_CUSTOMER_USER_FILTER = 'SAVE_CUSTOMER_USER_FILTER';

export const SET_SESSION = 'SET_SESSION';
export const CLEAR_SESSION = 'CLEAR_SESSION';
export const TOGGLE_SESSION_LOADING = 'TOGGLE_SESSION_LOADING';
export const SET_SESSION_SELECTED_ORGANIZATION = 'SET_SESSION_SELECTED_ORGANIZATION';

export const SWITCH_ACTIVE_ENVIRONMENT_TO_LEARNING = 'SWITCH_ACTIVE_ENVIRONMENT_TO_LEARNING';
export const SWITCH_ACTIVE_ENVIRONMENT_TO_ADMINISTRATION = 'SWITCH_ACTIVE_ENVIRONMENT_TO_ADMINISTRATION';

export const SAVE_CAMPAIGN_FORM = 'SAVE_CAMPAIGN_FORM';
export const CLEAR_CAMPAIGN_DATA = 'CLEAR_CAMPAIGN_DATA';
export const SAVE_CAMPAIGN_CUSTOMER_FILTER = 'SAVE_CAMPAIGN_CUSTOMER_FILTER';
export const SAVE_CAMPAIGN_ACTIVITY_LIST = 'SAVE_CAMPAIGN_ACTIVITY_LIST';


export const SCORM_DATA = 'SCORM_DATA';
export const STORE_FILTERED_ORGANIZATION_OPTIONS = 'STORE_FILTERED_ORGANIZATION_OPTIONS';

export const SAVE_MAIL_TEMPLATE_ORGANIZATION_OPTIONS = 'SAVE_MAIL_TEMPLATE_ORGANIZATION_OPTIONS';
export const SAVE_MAIL_TEMPLATE_CUSTOMER_OPTIONS = 'SAVE_MAIL_TEMPLATE_CUSTOMER_OPTIONS';
export const SAVE_MAIL_TEMPLATE_FILTER_OPTIONS ='SAVE_MAIL_TEMPLATE_FILTER_OPTIONS';

export const SAVE_LANGUAGE_DICTIONARY = 'SAVE_LANGUAGE_DICTIONARY';
export const TOGGLE_LANGUAGE_DICTIONARY_LOADING = 'TOGGLE_LANGUAGE_DICTIONARY_LOADING'

export const SAVE_PHISHING_RESULTS_VIEW = 'SAVE_PHISHING_RESULTS_VIEW';
export const SAVE_PHISHING_SIMULATION_LANGUAGE = 'SAVE_PHISHING_SIMULATION_LANGUAGE';
export const SAVE_PHISHING_CAMPAIGN_TYPE = 'SAVE_PHISHING_CAMPAIGN_TYPE';

export const LANGUAGE_DICTIONARY_GET_SUCCESS = 'LANGUAGE_DICTIONARY_GET_SUCCESS';
export const LANGUAGE_DICTIONARY_GET_FAILED = 'LANGUAGE_DICTIONARY_GET_FAILED';
export const LANGUAGE_DICTIONARY_GET_IN_PROGRESS = 'LANGUAGE_DICTIONARY_GET_IN_PROGRESS';

export const SAVE_LICENCE_FILTER = 'SAVE_LICENCE_FILTER';

// Appearance
export const APPEARANCE_SETTINGS_GET_IN_PROGRESS = 'APPEARANCE_SETTINGS_GET_IN_PROGRESS';
export const APPEARANCE_SETTINGS_GET_SUCCESS = 'APPEARANCE_SETTINGS_GET_SUCCESS';
export const APPEARANCE_SETTINGS_GET_FAILED = 'APPEARANCE_SETTINGS_GET_FAILED';

export const SAVE_PUBLIC_APPEARANCE = 'SAVE_PUBLIC_APPEARANCE';

export const SAVE_STUDENT_VIEW_DATA = 'SAVE_STUDENT_VIEW_DATA';
export const CLEAR_STUDENT_VIEW_DATA = 'CLEAR_STUDENT_VIEW_DATA';


export const SAVE_CUSTOM_CAMPAIGN_ACTIVITY = 'SAVE_CUSTOM_CAMPAIGN_ACTIVITY';
export const CLEAR_CUSTOM_CAMPAIGN_ACTIVITY = 'CLEAR_CUSTOM_CAMPAIGN_ACTIVITY';

export const SAVE_ACTIVITY_CREATION_SETTINGS = 'SAVE_ACTIVITY_CREATION_SETTINGS';
export const CLEAR_ACTIVITY_CREATION_SETTINGS = 'CLEAR_ACTIVITY_CREATION_SETTINGS';

interface appearanceSettingsGetInProgressAction {
  type: typeof APPEARANCE_SETTINGS_GET_IN_PROGRESS;
}

interface appearanceSettingsGetSuccessAction {
  type: typeof APPEARANCE_SETTINGS_GET_SUCCESS;
  payload: AppearanceSettings;
}

interface appearanceSettingsGetFailedAction {
  type: typeof APPEARANCE_SETTINGS_GET_FAILED;
}

export type AppearanceSettingsActionTypes = appearanceSettingsGetInProgressAction
  | appearanceSettingsGetSuccessAction
  | appearanceSettingsGetFailedAction;

// Learning Test Attempt
export const SAVE_LEARNING_TEST_ATTEMPT_DATA = 'SAVE_LEARNING_TEST_ATTEMPT_DATA';
export const CLEAR_LEARNING_TEST_ATTEMPT_DATA = 'CLEAR_LEARNING_TEST_ATTEMPT_DATA';
