import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {useParams} from 'react-router-dom';
import {Table} from 'ui';
import moment from "moment/moment";

const RulesResults: React.FC<any> = ({}) => {
    const [reload, setReload] = useState(false);

    const params = useParams();
    const ruleId = params.id;
    const intl = useIntl();

    const columns = [
        {
            title: intl.formatMessage({id: 'general.full_name'}),
            dataIndex: 'fullName',
        },
        {
            title: intl.formatMessage({id: 'general.email'}),
            dataIndex: 'email',
        },
        {
            title: intl.formatMessage({id: 'general.date'}),
            dataIndex: 'created_at',
            key: 'created_at',
            render: (date: string) => date ? moment(date).format('YYYY-MM-DD HH:mm') : '-'
        },
    ];

    return (
        <Table
            columns={columns}
            url={`/api/v1/rules/confirmation/${ruleId}/list`}
            setReload={setReload}
            reload={reload}
            rowSelection={false}
            rowKey={'id'}
            scroll={{x: true}}
        />
    )
}

export default RulesResults;
