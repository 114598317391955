import React, {useEffect, useState} from 'react';
import VideoInstruction from "../VideoInstruction";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import apiClient from "utils/apiClient";
import Button from 'ui/Button';
import '../InstructionButton/styles.scss';

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale
    }
};

const LoginInstructionButton: React.FC<any> = ({locale}) => {
    const [showVideoInstructionModal, setShowVideoInstructionModal] = useState(false);
    const [code, setCode] = useState();

    useEffect(() => {
        const loadInstruction = async () => {
            try {
                let url = `/api/v1/login-help/${locale}`

                const response = await apiClient.request(url, {}, 'GET');

                if (response.code.length) {
                    setCode(response.code[0].code);
                }
            } catch (error) {
                console.error(error);
            }
        };

        loadInstruction();

    }, [locale]);

    return (
        <>
            {!!code ?
                <>
                    <div className='login-help' onClick={() => setShowVideoInstructionModal(true)}>
                        {/* mock button */}
                        <FormattedMessage id="general.login_problems"/>
                        <Button className='help-button-login'>
                            <div className='icon-style'>
                                <i className="fas fa-play"></i>
                            </div>
                        </Button>
                    </div>
                    <VideoInstruction
                        visible={showVideoInstructionModal}
                        onCancel={() => setShowVideoInstructionModal(false)}
                        videoCode={code}
                        reset={false}
                        setCode={setCode}
                    />
                </>
                : null
            }
        </>
    )
};

export default connect(mapStateToProps)(LoginInstructionButton);
