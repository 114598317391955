import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {message} from 'antd';
import {HorizontalStackedBarChart} from 'components/Charts/HorizontalStackedBarChart';
import coursesApiClient from 'utils/coursesApiClient';
import {useParams} from "react-router-dom";
import {config} from "config/config";
import {connect} from "react-redux";

interface TestUsageProps {
    locale: string
}

const mapStateToProps = (state: any) => ({
    locale: state.locale.locale,
});

const TestUsage: React.FC<TestUsageProps> = ({
                                                 locale = config.defaultEnvironmentLocale,
                                             }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState({});
    const params = useParams();

    const learningTestId = params?.id ? parseInt(params?.id) : 0;

    const intl = useIntl();

    useEffect(() => {
        const loadData = async () => {
            try {
                setIsLoading(true);

                const response = await coursesApiClient.request(`/api/v1/courses/learning-tests/${learningTestId}/review/usage`);

                const data = response.data;

                const parsedData = {
                    labels: Object.values(data).map((el:any) => el.title[locale] ?? el[Object.keys(el.title)[0]] ),
                    datasets: [
                        {
                            label: intl.formatMessage({id: 'learning_tests.total_usage'}),
                            backgroundColor: 'rgb(82, 128, 204)',
                            hoverBackgroundColor: 'rgb(61, 96, 153)',
                            data: Object.values(data).map((value: any) => value.total_usage),
                        }
                    ],
                };

                setData(parsedData);
            } catch (error) {
                console.error(error);
                message.error(intl.formatMessage({id: 'error.data_load'}));
            } finally {
                setIsLoading(false);
            }
        }

        loadData();
    }, []);

    return (
        <HorizontalStackedBarChart
            data={data}
            title={intl.formatMessage({id: 'learning_tests.test_attempts_month'})}
            loading={isLoading}
        />
    );
}

export default connect(mapStateToProps)(TestUsage);