import React, { useState, useEffect, useRef } from 'react';
import { Divider, Form, Input as AntdInput, InputRef, Select, Space } from "antd";
import { Button } from "ui";
import { FormattedMessage, useIntl } from "react-intl";
import apiClient from "utils/apiClient";
import useHandleError from "utils/useHandleError";
import generalHelpers from "utils/generalHelpers";
import { connect } from "react-redux";

const mapStateToProps = (state: any) => {
    return {
        session: state.session,
    }
}

const Departments: React.FC<any> = ({ session, organizationId, customerId, name, isUuid, hideLabel, canCreate = true, setLoadedData = null }) => {
    const [departments, setDepartments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [title, setTitle] = useState('');

    const inputRef = useRef<InputRef>(null);
    const [handleError] = useHandleError();
    const { Option } = Select;
    const intl = useIntl();

    useEffect(() => {
        loadDepartments();
    }, [])

    const loadDepartments = async () => {
        setIsLoading(true)
        try {
            let url = '/api/v1/departments';

            if (generalHelpers.isAdmin(session.active_user_type)) {
                if (!!organizationId) {
                    url += '?organizationId=' + organizationId
                } else {
                    url += '?customerId=' + customerId
                }
            }

            if (isUuid) {
                url += '&isUuid=true'
            }

            const response = await apiClient.request(url, [], 'GET');

            if (response) {
                setDepartments(response.departments)

                if (setLoadedData) {
                    setLoadedData(response.departments)
                }
            }
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    };

    const addDepartment = async () => {
        setIsLoading(true);
        try {
            let parsedValues: any = { title: title }

            if (generalHelpers.isAdmin(session.active_user_type)) {
                parsedValues[!!organizationId ? 'organizationId' : 'customerId'] = !!organizationId ? organizationId : customerId
            }

            await apiClient.request('/api/v1/departments/store', parsedValues, 'POST');
            loadDepartments()
            setTitle('')
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Form.Item
            name={name ? name : 'departmentId'}
            label={!hideLabel ? intl.formatMessage({ id: 'general.department' }) : undefined}
        >
            <Select className='default-select'
                dropdownRender={menu => (
                    <>
                        {menu}
                        {canCreate && <>
                            <Divider style={{ margin: '8px 0' }} />
                            <Space style={{ padding: '0 8px 4px' }}>
                                <AntdInput
                                    ref={inputRef}
                                    value={title}
                                    onChange={(event: any) => setTitle(event.target.value)}
                                />
                                <Button type="outline" onClick={addDepartment} loading={isLoading}>
                                    <FormattedMessage id='general.add' />
                                </Button>
                            </Space>
                        </>}
                    </>
                )}
            >
                {Object.entries(departments).map((item: any) => {
                    if (!item) {
                        return;
                    }

                    return <Option key={item[0]}>{item[1]}</Option>
                })}
            </Select>
        </Form.Item>
    )
}

export default connect(mapStateToProps)(Departments);
