import React, {useState} from 'react';
import {Form, Input} from "antd";
import {LearningStatusSelect, Modal, Table, TestStatusSelect} from 'ui'
import {useIntl} from "react-intl";
import coursesApiClient from "utils/coursesApiClient";
import useHandleError from "utils/useHandleError";
import Spinner from "../../Spinner";
import {useParams} from "react-router-dom";
import {config} from "config/config";
import {connect} from "react-redux";
import './styles.scss';

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale,
    }
};

interface ActivityStatusModalInterface {
    open: boolean;
    locale?: string
    onCancel: React.Dispatch<React.SetStateAction<boolean>>;
    students: number[];
    reloadStudents: React.Dispatch<React.SetStateAction<boolean>>;
}

const ActivityStatusModal: React.FC<ActivityStatusModalInterface> = ({
                                                                         reloadStudents,
                                                                         open,
                                                                         onCancel,
                                                                         locale = config.defaultEnvironmentLocale,
                                                                         students
                                                                     }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [reload, setReload] = useState(false);
    const [showComment, setShowComment] = useState<any>([])
    const intl = useIntl();
    const params = useParams();
    const programId = params.id;
    const [handleError] = useHandleError();
    const [form] = Form.useForm();
    const {TextArea} = Input;

    const submit = async (values: any) => {
        setIsLoading(true)
        const parsedValues = {
            enrollmentsId: students,
            activities: values,
            programId: programId
        }

        try {
            await coursesApiClient.request('/api/v1/courses/learning-program/materials/update-activity-status', parsedValues, 'POST');
            reloadStudents(true);
            onCancel(false);
            setShowComment([]); 
            form.resetFields();
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    const handleChange = (fieldName:any) => {
        const name = fieldName.join('_');
        setShowComment((showComment:any) => [...showComment, name])
    }

    const columns = [
        {
            title: intl.formatMessage({id: 'general.activity'}),
            render: (_text: string, record: any) => record.title[locale] ? record.title[locale] : record.title[Object.keys(record.title)[0]],
        },
        {
            title: intl.formatMessage({id: 'program.change_activity_status'}),
            render: (_text: string, record: any) => {
                const name = [record.id, 'learn'].join('_');

                return (
                    <div className={'full-width-select'}>
                        <LearningStatusSelect type={record.learning_type} onChange={handleChange} disabled={!record.has_learning} name={[record.id, 'learn']}/>
                        {showComment.includes(name) &&
                          <Form.Item name={[record.id, 'learn_comment']} label={intl.formatMessage({id: 'general.comment'})}>
                            <TextArea maxLength={255}/>
                          </Form.Item>
                        }
                    </div>
                )
            },
        },
        {
            title: intl.formatMessage({id: 'program.change_test_status'}),
            render: (_text: string, record: any) => {
                const name = [record.id, 'test'].join('_');

                return (
                    <div className={'full-width-select'}>
                        <TestStatusSelect type={record.learning_type} onChange={handleChange} disabled={!record.has_test} name={[record.id, 'test']}/>
                        {showComment.includes(name) &&
                          <Form.Item name={[record.id, 'test_comment']} label={intl.formatMessage({id: 'general.comment'})}>
                            <TextArea maxLength={255}/>
                          </Form.Item>
                        }
                    </div>
                )
            },
        },
    ];

    return (
        <Modal
            open={open}
            width={'70%'}
            isLoading={isLoading}
            onCancel={() => onCancel(false)}
            className='add_remove_activity'
            destroyOnClose={true}
            title={intl.formatMessage({id: 'program.activity_status_change'})}
            okText={intl.formatMessage({id: 'general.add'})}
            cancelText={intl.formatMessage({id: 'general.cancel'})}
            onOk={form.submit}
        >
            <Spinner spinning={isLoading}>
                <Form layout="vertical" form={form} onFinish={submit}>
                    <Table
                        columns={columns}
                        url={`/api/v1/courses/learning-program/materials/${programId}/activities`}
                        reload={reload}
                        setReload={setReload}
                        rowKey={'id'}
                        isLoading={isLoading}
                        rowSelection={false}
                        pagination={false}
                    />
                </Form>
            </Spinner>
        </Modal>
    )
};

export default connect(mapStateToProps)(ActivityStatusModal);
