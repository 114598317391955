import React from 'react';
import {useIntl} from 'react-intl';
import {Tabs} from 'antd';
import DefaultLayout from 'components/DefaultLayout';
import './styles.scss';
import LearningProgramCustomFields from "./LearningProgramCustomFields";
import LectureCustomFields from "./LectureCustomFields";
import UsersCustomFilters from "./UsersCustomFilters";
import AudienceFilters from "./AudienceFilters";

const ManageFilters: React.FC = () => {
    const intl = useIntl();

    return (
        <>
            <DefaultLayout.PageLayout>
                <DefaultLayout.PageHeader title={intl.formatMessage({id: 'system.custom_fields_management'})}/>
                <DefaultLayout.PageContent>
                    <Tabs defaultActiveKey="1">
                        <Tabs.TabPane tab={intl.formatMessage({id: 'general.users'})} key="1">
                            <UsersCustomFilters/>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={intl.formatMessage({id: 'general.learning_programs'})} key="2">
                            <LearningProgramCustomFields/>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={intl.formatMessage({id: 'general.lectures'})} key="3">
                            <LectureCustomFields/>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={intl.formatMessage({id: 'general.audiences'})} key="audience">
                            <AudienceFilters/>
                        </Tabs.TabPane>
                    </Tabs>
                </DefaultLayout.PageContent>
            </DefaultLayout.PageLayout>
        </>

    )
}

export default ManageFilters;
