import React, { useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Form, Input, Radio, InputNumber, FormInstance, message, Tooltip } from 'antd';
import { Switch } from 'components/Form';
import coursesApiClient from 'utils/coursesApiClient';
import { FileTable, FileUpload, RichTextEditor } from 'ui';
import Spinner from 'components/Spinner';
import TemplateEditor from 'components/TemplateEditor';

const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
};

interface ActivityTabInterface {
    language: string;
    form: FormInstance;
    activityId: number;
    viewMaterial?: any;
    onCancel: any;
    reload: Function;
    settings: any;
    setSettings: Function
}

const ActivityTab: React.FC<ActivityTabInterface> = ({
    language,
    form,
    activityId,
    viewMaterial,
    onCancel,
    reload,
    settings,
    setSettings
}) => {
    const [description, setDescription] = useState('');
    const [editor, setEditor] = useState<any>();

    const intl = useIntl();

    return (
        <>
            <h3><FormattedMessage id='assessment.title_and_description' /></h3>
            <Form.Item required={true} {...formItemLayout} name='title' label={intl.formatMessage({ id: 'general.title' })} >
                <Input />
            </Form.Item>
            <TemplateEditor 
                height={300}
                layout={formItemLayout}
                label={intl.formatMessage({ id: 'general.description' })}
                html={settings.description}
                setHtml={(value: any) => setSettings({
                    ...settings,
                    description: value ?? ''
                })}
                setEditor={setEditor}
            />
        </>
    )
}
export default ActivityTab;
