import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import BarLineChart from 'components/Charts/BarLineChart';
import {useProgramOverview} from "hooks/Program/useProgramOverview";

interface CompletionOverTimeChartProps {
    programId: number,
}

const CompletionOverTimeChart: React.FC<CompletionOverTimeChartProps> = ({ programId }) => {
    const {completionOverTimeChart, isLoading} = useProgramOverview();
    const [data, setData] = useState({});

    const intl = useIntl();

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        setData(await completionOverTimeChart(programId));
    }

    return (
        <BarLineChart
            data={data}
            title={intl.formatMessage({ id: 'program.completion_over_time_title' })}
            loading={isLoading}
        />
    );
};

export default CompletionOverTimeChart;