import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import DoughnutChart from 'components/Charts/DoughnutChart';
import {useProgramOverview} from "hooks/Program/useProgramOverview";

interface EnrolledStatisticsChartProps {
  programId: number,
}

const EnrolledStatisticsChart: React.FC<EnrolledStatisticsChartProps> = ({ programId }) => {
  const {enrolledStatistics, isLoading} = useProgramOverview();

  const [data, setData] = useState({
    data: {},
    inner: {},
  });

  const intl = useIntl();


  useEffect(() => {
      loadData();
  }, []);

  const loadData = async () => {
    setData(await enrolledStatistics(programId));
  }

  return (
    <DoughnutChart
      data={data.data}
      title={intl.formatMessage({ id: 'general.statistics' })}
      inner={data.inner}
      loading={isLoading}
    />
  );
}

export default EnrolledStatisticsChart;