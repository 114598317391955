import React, {useState, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import { Form, Upload, Button } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

import coursesApiClient from 'utils/coursesApiClient';
import useHandleError from "utils/useHandleError";

interface ThumbUploaderInterface {
    baseFieldName?: string|number;
    fieldName: string;
    fileInfo: any;
    onChangeCallback: Function;
}

const { Dragger } = Upload;

const ThumbUploader: React.FC<ThumbUploaderInterface> = ({ 
    baseFieldName,
    fieldName,
    fileInfo,
    onChangeCallback
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [fileList, setFileList] = useState<any>([]);

    const intl = useIntl();
    const [handleError] = useHandleError();

    useEffect(() => {
        if(fileInfo?.fileData) {
            setFileList([
                {
                    uid: fileInfo?.fileId,
                    status: 'done',
                    url: fileInfo?.fileData,
                },
            ])
        } else {
            setFileList([])
        }
    }, [fileInfo])

    const handleOnChange = async (e: any) => {
        if( e.fileList && e.fileList.length > 0 ) {
            setFileList([...e.fileList]);
            onChangeCallback({[fieldName]: e})
        } else if(e?.file?.status == 'removed') {
            setFileList([]);
            onChangeCallback({[fieldName]: 'removed'})
        }
    }

    return (
        <>
            <Form.Item
                name={baseFieldName ? [baseFieldName, fieldName] : fieldName}
                {...{labelCol: {span: 0}, wrapperCol: {span: 24}}}
                // label={intl.formatMessage({id: 'general.add_image'})}
                // valuePropName="fileList"
                className="question-pool-uploader"
                style={{marginBottom: 0}}
                >
                    <div className='hotspot-upload-area'>
                        <h3><FormattedMessage id='question_pools.add_photo' /></h3>
                        <Dragger 
                            beforeUpload={() => false}
                            accept="image/*"
                            maxCount={1}
                            fileList={fileList}
                            onChange={handleOnChange}
                            multiple={false}
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text"><FormattedMessage id='question_pools.hs_upload_title' /></p>
                            <p className="ant-upload-hint"><FormattedMessage id='question_pools.hs_upload_description' /></p>
                        </Dragger>
                    </div>
            </Form.Item>
        </>
    );
};

export default ThumbUploader;
    