import React, { useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Form, Button, message, Space, Upload, Alert, Empty, Input, Modal } from 'antd';
import { UploadOutlined, InboxOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import coursesApiClient from 'utils/coursesApiClient';
import { connect } from 'react-redux';
import { FileTableExtended } from 'ui';
import SubmissionDetail from './SubmissionDetail';
import {useDate} from 'hooks/useDate';
import Spinner from 'components/Spinner';
import useHandleError from "utils/useHandleError";
import './styles.scss';

interface AssignmentSubmissionInterface {
    activityFormData: any;
    assignmentSettings: any;
    submission: any;
    files: any;
    session?: any;
    getStatusTag: Function;
    getAssignment: Function;
    disableSubmit: boolean;
}
const mapStateToProps = (state: any) => ({
    session: state.session,
});

const { Dragger } = Upload;

const AssignmentSubmission: React.FC<AssignmentSubmissionInterface> = ({
    activityFormData,
    assignmentSettings,
    submission,
    files,
    session, 
    getStatusTag,
    getAssignment,
    disableSubmit
}) => {
    const adminRoles = ['RESELLER_ADMIN', 'SUPER_ADMIN', 'DISTRIBUTOR_ADMIN', 'CUSTOMER_ADMIN', 'SUPERVISION'];
    const [isFilesLoading, setIsFilesLoading] = useState(false);
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [submittedFiles, setSubmittedFiles] = useState<any>();

    const intl = useIntl();
    const { TextArea } = Input;
    const [form] = Form.useForm();
    const {formatDate} = useDate();
    const [handleError] = useHandleError();

    useEffect(() => {
        if (activityFormData) {
            if (submission && submission.submitted_files && submission.submitted_files.length > 0) {
                const submittedFilesArray = submission.submitted_files.map((file: any) => {
                    file.date = formatDate(file.date);
                    return file;
                });
                
                setSubmittedFiles(submittedFilesArray)
            } else {
                setSubmittedFiles([])
            }

            if (submission && submission?.comments) {
                form.setFieldsValue({
                    comments: submission?.comments
                })
            } 
        }
    }, [activityFormData, submission?.submission_date])

     /**
     * 
     * @param file 
     */
     const uploadStudentFile = async (file: any) => {
        setIsFilesLoading(true);
        try {
            if (activityFormData) {
                let bodyFormData = new FormData();
                bodyFormData.append('file', file.file);
                const response = await coursesApiClient.request(`/api/v1/courses/assignment-results/resource-upload/${submission.id}`, bodyFormData, 'POST');
                getAssignment()
            }
        } catch (error) {
            handleError(error)
        } finally {
            setIsFilesLoading(false);
        }
    }

    const submitToCheck = async () => {
         if (!disableSubmit && assignmentSettings && !!assignmentSettings.file_upload && !adminRoles.includes(session.active_user_type) && !submittedFiles.length) {
             return message.warning(intl.formatMessage({id: "courses.file_not_uploaded"}));
         }

        Modal.confirm({
            title: intl.formatMessage({ id: 'general.attention' }),
            icon: <ExclamationCircleOutlined />,
            content: intl.formatMessage({ id: 'courses.student_submit_work' }),
            okText: intl.formatMessage({ id: 'general.submit' }),
            cancelText: intl.formatMessage({ id: 'general.cancel' }),
            onOk() {
                submit();
            }
        })
    }

    const submit = async () => {
        setIsSubmitLoading(true);
        try {
            const values = form.getFieldsValue();
            const parsedValues = {
                comments: values?.comments ?? '',
            };
            
            const response = await coursesApiClient.request(`/api/v1/courses/assignment-results/submit-work/${submission.id}`, parsedValues, 'POST');
            message.success(intl.formatMessage({ id: 'courses.assignment_submitted_success' }));
            getAssignment()
        } catch (error) {
            handleError(error)
        } finally {
            setIsSubmitLoading(false);
        }
    }

    const submissionAlert = (submission: any) => {
        if( ['NOT_SUBMITTED', 'SUBMITTED'].includes(submission.status) && !disableSubmit && assignmentSettings && !!assignmentSettings.file_upload  ) {
            return <Alert type='info' showIcon message={intl.formatMessage({id: 'courses.assignment_upload_files_explaination'})}  style={{marginBottom: '20px'}} />
        }
        
        if( submission.status === 'RETURNED' && !disableSubmit ) {
            return <Alert type='warning' showIcon message={intl.formatMessage({id: 'courses.assignment_returned_explaination'})}  style={{marginBottom: '20px'}} />
        }
    }
    
    return (
        <div className='assignment-submission'>
            <Form form={form}>
                {submissionAlert(submission)}

                {!disableSubmit && assignmentSettings && !!assignmentSettings.file_upload && !adminRoles.includes(session.active_user_type) && <div className='assignment-section'>
                    <div className='assignment-title-bar'>
                        <div className='assignment-title flex'>
                            <h3><FormattedMessage id='general.file_submission' /></h3>
                        </div>
                    </div>
                    <Spinner spinning={isFilesLoading}>
                        <div className='assignment-upload-button'>
                            <Dragger 
                                accept="image/*,.xls,.xlsx,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                fileList={[]}
                                customRequest={(file: any) => uploadStudentFile(file)}
                                multiple
                            >
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-hint"><FormattedMessage id='general.drag_file_upload' /></p>
                            </Dragger>
                            <Upload
                                customRequest={(file: any) => uploadStudentFile(file)}
                                multiple
                                fileList={[]} >
                                <Button type='ghost'><Space size={8}><UploadOutlined /><FormattedMessage id='general.upload_files' /></Space></Button>
                            </Upload>
                        </div>
                    </Spinner>
                </div>}
                <div className='assignment-section'>
                    <div className='assignment-title-bar'>
                        <div className='assignment-title flex'>
                            <h3><FormattedMessage id='general.your_uploads' /></h3>
                        </div>
                    </div>
                    <div className='resource-container'>
                        {submittedFiles && !!submittedFiles.length ? <FileTableExtended 
                            key={'assignment_materials'} 
                            fileArray={submittedFiles} 
                            showMenu={false} 
                            allowDelete={!disableSubmit} 
                            showLabels={false}
                            dateBelowTitle={true}
                            cols={['title', 'date', 'type', 'size']}
                            urls={{
                                download: `api/v1/courses/material/storage/{path}`,
                                delete: `/api/v1/courses/assignment-results/delete-file/${submission.id}`
                            }}
                            onDelete={() => getAssignment()}
                            theme='with-bg'
                        /> : <Empty
                            description={intl.formatMessage({id: 'general.no_uploads'})}
                        />}
                    </div>
                </div>
                <div className='assignment-section'>
                    <div className='assignment-title-bar'>
                        <div className='assignment-title flex'>
                            <h3><FormattedMessage id='general.comments' /></h3>
                        </div>
                    </div>
                    <div className='resource-container'>
                        <Form.Item name='comments' >
                            <TextArea maxLength={1000} showCount disabled={disableSubmit} />
                        </Form.Item>
                    </div>
                </div>
                <div className='assignment-section'>
                    <div className='assignment-title-bar'>
                        <div className='flex align-center flex-wrap'>
                            <div>
                                <div className='assignment-title flex flex-wrap'>
                                    <h3><FormattedMessage id='courses.submission_status' /></h3>
                                    <span className='status-bar-value'>
                                        {getStatusTag()}
                                    </span>
                                </div>
                            </div>
                            {!disableSubmit && <Button className='toggle-submission' type='primary' ghost={submission.status === 'SUBMITTED'} onClick={() => submitToCheck()} loading={isSubmitLoading}>
                                <FormattedMessage id={submission.status === 'NOT_SUBMITTED' ? 'courses.submit_assignment' : 'courses.edit_sbmission'} />
                            </Button>}
                        </div>
                    </div>
                    <SubmissionDetail 
                        submission={submission} 
                    />
                </div>
            </Form>
        </div >
    )
}
export default connect(mapStateToProps)(AssignmentSubmission);