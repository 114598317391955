import * as ActionTypes from 'store/actionTypes';

const defaultState = {
  attempt: null,
  questions: [],
}

const learningTestAttemptData = (state = defaultState, action: any) => {
  switch(action.type) {
    case ActionTypes.SAVE_LEARNING_TEST_ATTEMPT_DATA:
      var attemptData = action.payload;
      return ({...state, ...attemptData});
    case ActionTypes.CLEAR_LEARNING_TEST_ATTEMPT_DATA:
      return (defaultState);
    default:
      return state;
  }
}

export default learningTestAttemptData;