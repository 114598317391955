import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {AccessForm} from "../../AcessForm";
import generalHelpers from "utils/generalHelpers";

interface AccessInterface {
    test: any;
}

const Access: React.FC<AccessInterface> = ({test}) => {
    const [targetKeys, setTargetKeys] = useState<string[]>([]);

    const params = useParams();

    const learningTestId = params?.id ? parseInt(params?.id) : 0;

    useEffect(() => {

        if (test) {
            const customers = test.organizationAccess.filter((el: any) => el.customer_uuid);

            const target = generalHelpers.stringifyArray(generalHelpers.pluck(customers, 'customer_uuid'));

            setTargetKeys(target);
        }

    }, [test]);

    return (
        <>
            <AccessForm
                transferTitle={'program.organization_access'}
                tableTitle={'learning_test.user_access'}
                initialTargetKeys={targetKeys}
                customerUuid={true}
                url={
                    {
                        organizationUpdate: `/api/v1/courses/learning-tests/${learningTestId}/access/organizations`,
                        C: `/api/v1/courses/learning-tests/${learningTestId}/access/admins`,
                        R: `/api/v1/learning-tests/${learningTestId}/access/admins`,
                        U: `/api/v1/courses/learning-tests/{id}/access/admins`,
                        D: `/api/v1/courses/learning-tests/{id}/access/admins`
                    }
                }
            />
        </>
    )
};

export default Access;
