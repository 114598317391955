import React, {useEffect, useState} from 'react';
import {useIntl, FormattedMessage} from 'react-intl';
import {Select, Switch} from 'components/Form';
import coursesApiClient from 'utils/coursesApiClient';
import {message, FormInstance, Form, Input, Radio, Tooltip} from 'antd';
import debounce from 'lodash/debounce';

const formItemLayout = {
    labelCol: {span: 10},
    wrapperCol: {span: 14},
};

interface VideoFormInterface {
    language: string;
    handleVersionSelect: Function;
    versionList: any[];
    activityId: number;
    setMaterial: React.Dispatch<React.SetStateAction<any>>;
    reloadVersions: Function;
    form: FormInstance;
    versionsLoading: boolean;
    material: any;
    youtubeParser: Function;
    vimeoParser: Function;
}

const VideoForm: React.FC<VideoFormInterface> = ({
                                                     form,
                                                     setMaterial,
                                                     language,
                                                     handleVersionSelect,
                                                     versionList,
                                                     activityId,
                                                     reloadVersions,
                                                     versionsLoading,
                                                     material,
                                                     youtubeParser,
                                                     vimeoParser
                                                 }) => {
    const [videoProvider, setVideoProvider] = useState(''),
        [disabledVideoId, setDisabledVideoId] = useState(false),
        intl = useIntl();

    const parseVideoId = (url: string) => {
        switch (videoProvider) {
            case 'youtube':
                return youtubeParser(url);
            case 'vimeo':
                return vimeoParser(url);
            case 'vdocipher':
                return url;
            default:
                return null;

        }
    }
    const create = async (value: string) => {
        try {
            const videoId = parseVideoId(value);

            if (!videoId) {
                return message.error(intl.formatMessage({id: 'courses.video_url_error'}));
            }
            const parsedValues = {
                type: 'VIDEO_MATERIAL',
                settings: {
                    videoProvider: videoProvider,
                    videoId: videoId
                },
                language: language
            }

            if (material) {
                let fileSettings = JSON.parse(material.fileSettings);
                if (fileSettings.video_id === videoId) {
                    return;
                }
            }

            let response = await coursesApiClient.request(`/api/v1/courses/material/${activityId}/add-material`, parsedValues, 'POST');

            setMaterial(response.material.settings);

            const versions = await reloadVersions();

            const lastVersion: any = Object.values(versions).pop();

            form.setFieldsValue({
                version: lastVersion + ''
            });

        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({id: 'error.data_load'}));
        }
    }

    const storeOptions = debounce(function (value: string) {
        if (value.length > 10) {
            create(value);
        }
    }, 800);

    const changeProvider = (event: any) => {
        setVideoProvider(event.target.value);
        setDisabledVideoId(false);
        form.setFieldsValue({videoId: ''});
    }

    useEffect(() => {
        if (material) {
            const materialFileSettings = JSON.parse(material.fileSettings);

            if (materialFileSettings?.video_provider) {
                setVideoProvider(materialFileSettings.video_provider);
                setDisabledVideoId(false);
            } else {
                setDisabledVideoId(true);
            }
        } else {
            setDisabledVideoId(true);
        }
    }, [material])


    return (
        <>
            <Form.Item
                {...formItemLayout}
                name="videoProvider"
                label={intl.formatMessage({id: 'courses.video_provider'})}
            >
                <Radio.Group onChange={(event: any) => changeProvider(event)}>
                    <Radio value="youtube" style={{lineHeight: '32px'}}><FormattedMessage id='general.youtube'/></Radio>
                    <Radio value="vimeo" style={{lineHeight: '32px'}}><FormattedMessage id='general.vimeo'/></Radio>
                    <Radio value="vdocipher" style={{lineHeight: '32px'}}><FormattedMessage
                        id='general.vdocipher'/></Radio>
                </Radio.Group>
            </Form.Item>

            <Form.Item {...formItemLayout} name='videoId' label={intl.formatMessage({id: 'courses.video_id'})}
                       rules={[{required: true, message: intl.formatMessage({id: 'validation.field_required'})}]}>
                <Input disabled={disabledVideoId} onBlur={(event: any) => storeOptions(event.target.value)}/>
            </Form.Item>
            <Switch customLayout={formItemLayout} isFormItem name="viewAllToComplete"
                    label={
                        <span>
                        {intl.formatMessage({id: 'courses.see_all_to_complete'})}
                            <Tooltip title={intl.formatMessage({id: 'courses.video_view_all_hint'})}>
                            <i className='fal fa-question-circle header-item'/>
                        </Tooltip>
                    </span>
                    }/>
            <Switch disabled={videoProvider === 'vdocipher'} customLayout={formItemLayout} isFormItem name="pauseOnBlur"
                    label={intl.formatMessage({id: "general.pause_on_blur"})}/>
            <Switch disabled={videoProvider === 'vdocipher'} customLayout={formItemLayout} isFormItem name="videoProgress"
                    label={intl.formatMessage({id: "general.disable_progress"})}/>
            <Select
                loading={versionsLoading}
                customLayout={formItemLayout}
                label={intl.formatMessage({id: "general.version"})}
                name='version'
                manualOptions={versionList}
                onChange={(_value: any, options: any) => handleVersionSelect(options.children)}
            />
        </>
    )
}
export default VideoForm;
