import React, {useState, useEffect} from 'react';
import DefaultLayout from 'components/DefaultLayout';
import {FormattedMessage, useIntl} from 'react-intl';
import {message, Table} from 'antd';
import ContentRequestModal from 'components/Content/ContentRequestModal';
import DemoContentRequestModal from 'components/Content/DemoContentRequestModal';
import Flag from "components/Flags/FlagProps";
import Spinner from 'components/Spinner';
import {connect} from 'react-redux';
import Button from 'ui/Button';
import apiClient from 'utils/apiClient';
import { useParams, useNavigate } from 'react-router-dom';
import FlexRow from 'components/FlexRow';
import './styles.scss';

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale,
        session: state.session
    }
};

const ContentView: React.FC<any> = ({ session, locale}: any) => {
    // Translations
    const [contentName, setContentName] = useState<string>();
    const [contentDescription, setContentDescription] = useState<string>();
    const [priceComment, setPriceComment] = useState<string>();
    const [productDescription, setProductDescription] = useState<string>();

    // Settings
    const [price, setPrice] = useState<string>();
    const [activities, setActivities] = useState();
    const [serviceType, setServiceType] = useState();
    const [serviceId, setServiceId] = useState();
    const [transferData, setTransferData] = useState();
    const [contentImages, setContentImages] = useState([]);

    // View
    const [showDemoRequestModal, setDemoShowRequestModal] = useState(false);
    const [showRequestModal, setShowRequestModal] = useState(false);
    const [displayDemoButton, setDisplayDemoButton] = useState(false);

    // Loaders
    const [isLoading, setIsLoading] = useState(false);
    const [requestDemoLoader, setRequestDemoLoader] = useState(false);
    const [requestLoader, setRequestLoader] = useState(false);
    const [contentType, setContentType] = useState();

    const intl = useIntl();
    const params = useParams();

    const contentUuid = params.id;
    const userRole = session.active_user_type;

    const navigate = useNavigate();

    useEffect(() => {
        const loadContent = async () => {
            setIsLoading(true);
            let response;
            try {
                response = await apiClient.request(`/api/v1/auth/content/${contentUuid}/view/${locale}`, {}, 'GET');
            } catch (error: any) {
                if (error.code === 403) {
                    message.error(intl.formatMessage({id: 'error.'  + error.error}));
                    navigate('/content');
                    return;
                } else if (error.code === 404) {
                    message.error(intl.formatMessage({id: 'error.' + error.error}));
                    navigate('/content');
                    return;
                } else {
                    console.error(error);
                    message.error(intl.formatMessage({id: 'error.data_load'}));
                    return;
                }
            }

            let usedDemoArray = [];

            if (response.content.demoUsed) {
                switch (userRole) {
                    case 'DISTRIBUTOR_ADMIN':
                        if (response.content.demoUsed.distributor) {
                            usedDemoArray = response.content.demoUsed.distributor.split(',');
                        }
                        break;
                    case 'RESELLER_ADMIN':
                        if (response.content.demoUsed.reseller) {
                            usedDemoArray = response.content.demoUsed.reseller.split(',');
                        }
                        break;
                    case 'CUSTOMER_ADMIN':
                        if (response.content.demoUsed.customer) {
                            usedDemoArray = response.content.demoUsed.customer.split(',');
                        }
                        break;
                }
            }

            setActivities(response.content.activities);
            setContentName(response.content.name);
            setContentDescription(response.content.contentDescription);
            setPriceComment(response.content.priceComment);
            setProductDescription(response.content.productDescription);
            setPrice(response.content.price);
            setServiceType(response.content.serviceType);
            setServiceId(response.content.serviceId);
            setContentType(response.content.serviceType);

            if (response.content.contentImages) {
                Object.values(response.content.contentImages).map((imgPath: any) => {
                    getImgSrc(imgPath);
                    return null;
                })
            }

            if (userRole === 'SUPER_ADMIN') {
                setDisplayDemoButton(true);
            } else {

                if (!usedDemoArray.includes(session.organization.organization_id)) {
                    setDisplayDemoButton(true);
                }
            }

            const data: any = [];

            if (response.content.activities) {
                Object.values(response.content.activities).map((el: any) => {
                    data.push({
                        key: el.id,
                        name: el.name
                    });
                    return data;
                });
                setTransferData(data);
            }
            return setIsLoading(false);
        };

        const getImgSrc = async (path: string) => {

            let image;
            // requests image from backend path='/storage/... .png' no need for additional '/'
            image = await apiClient.request(`api/v1${path}`, {}, 'GET', true, true);

            let imgArray: any = contentImages;

            if (Promise.resolve(image) !== image && image !== null) {
                let url: string = URL.createObjectURL(image);

                if (typeof url === 'string') {
                    imgArray.push(url);
                }

                if (imgArray.length) {
                    let blobs = imgArray.filter(function (el: any) {
                        return el != null;
                    });

                    setContentImages(blobs);
                }
            }
        };

        loadContent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl]);

    function stripHtml(html: string)
    {
        let tmp = document.createElement("DIV");
        tmp.innerHTML = html;

        return tmp.textContent || tmp.innerText || "";
    }

    const columns = [
        {
            width: 65,
            render: (value: string, record: any) => {
                return (
                <div className='content-column'>
                    <i className="fal fa-book"></i>
                </div>
                );
            }
        },
        {
            title: intl.formatMessage({id: 'courses.activities'}),
            dataIndex: 'name',
            width: '50%'
        },
        {
            title: intl.formatMessage({id: 'general.language'}),
            dataIndex: 'language',
            render: (value: string, record: any) => {
                return (
                    <span className='content-settings-flags'>
                        {record.language &&
                            Object.values(record.language).map((el: any) => {
                                return <Flag languageCode={el} />
                            })
                        }
                    </span>
                )
            }
        },
        {
            title: intl.formatMessage({id: 'general.minutes'}),
            dataIndex: 'minutes',
        },
        {
            title: intl.formatMessage({id: 'general.pass_rate'}),
            dataIndex: 'passRate',
            render: (value: string, record: any) => record.passRate + '%'
        }
    ];

    const renderHtmlText = (text: string) => {
        return (
            <div style={{fontSize: 16}} dangerouslySetInnerHTML={{__html: text}}></div>
        );
    };

    return (
        <>
            <DefaultLayout.PageLayout>
                <DefaultLayout.PageHeader
                    breadcrumb={[{name: intl.formatMessage({id: 'licensing.content.content'}), path: '/content'}]}
                    title={contentName ? contentName.toString() : ''}
                />
                <Spinner spinning={isLoading}>
                    <DefaultLayout.PageContent withTopPadding className='page-content'>
                        {userRole === 'DISTRIBUTOR_ADMIN' || userRole === 'RESELLER_ADMIN' || userRole === 'SUPER_ADMIN' ?
                            <Button style={{position: 'absolute', right: 10}}
                                    onClick={() => navigate(`/content/${contentUuid}/edit`)}>
                                <FormattedMessage id='licensing.content.edit'/>
                            </Button>
                            :
                            null
                        }
                        {productDescription && stripHtml(productDescription).length ?
                            <>
                                <h3 className='content-view-headers'>
                                    <FormattedMessage id='licensing.content.about_courses'/>
                                </h3>
                                    {renderHtmlText(productDescription)}
                            </>
                            :
                            null
                        }
                        <br/>
                        {contentDescription && stripHtml(contentDescription).length ?
                            <>
                                <h3 className='content-view-headers'>
                                    <FormattedMessage id='licensing.content.description'/>
                                </h3>
                                {renderHtmlText(contentDescription)}
                            </>
                            :
                            null
                        }
                        <br/>
                        {serviceType === 'E_LEARNING_CONTENT' &&
                        <Table
                            locale={{emptyText: intl.formatMessage({id: 'general.found_no_data'})}}
                            style={{margin: 0, marginTop: 15}}
                            columns={columns}
                            dataSource={activities}
                            size="middle"
                            pagination={false}
                            loading={isLoading}
                            scroll={{x: 800}}
                        />
                        }
                        <br/>
                        <FlexRow
                            left={
                                <>
                                    <h3 className='content-view-headers'>
                                        <FormattedMessage id='licensing.content.price'/>
                                    </h3>
                                </>
                            }
                            right={
                                <>
                                    <Button loading={requestLoader} className='content-button-style' type='primary' onClick={() => setShowRequestModal(true)}>
                                        <i style={{marginRight: '4px'}} className="far fa-plus"></i> <FormattedMessage
                                        id='licensing.content.request_licence'/>
                                    </Button>
                                    {displayDemoButton &&
                                        <Button loading={requestDemoLoader}  className='content-outlined-button-style' type='outlined' onClick={() => setDemoShowRequestModal(true)}>
                                            <i style={{marginRight: '4px'}} className="far fa-plus"></i> <FormattedMessage
                                            id='licensing.content.request_licence_demo'/>
                                        </Button>
                                    }
                                </>
                            }
                        />
                        <h3 className='content-view-headers'>
                            {price}
                        </h3>
                        {priceComment && stripHtml(priceComment).length ?
                            <div style={{marginRight: '50%'}}>
                                {renderHtmlText(priceComment)}
                            </div>
                            :
                            null
                        }
                        {contentImages && contentImages.length ?
                            <>
                                <h3 style={{marginTop: 32}} className='content-view-headers'>
                                    <FormattedMessage id='licensing.content.examples_content'/>
                                </h3>
                                <hr className='form-group-separator'/>
                            </>
                            : null
                        }
                        <div className='content-images-container'>
                            {contentImages && contentImages.length ?
                            contentImages.map((img: string) => {
                                return (
                                    <img width='50%' className='content-images-view' src={img} alt=''/>
                                );
                            })
                            : null
                            }
                        </div>
                    </DefaultLayout.PageContent>
                </Spinner>
            </DefaultLayout.PageLayout>
            <ContentRequestModal
                visible={showRequestModal}
                onCancel={() => setShowRequestModal(false)}
                afterSubmit={() => setShowRequestModal(false)}
                serviceType={serviceType}
                contentUuid={contentUuid}
                serviceId={serviceId}
                transferData={transferData}
                contentType={contentType}
                setLoader={setRequestLoader}
            />
            <DemoContentRequestModal
                visible={showDemoRequestModal}
                onCancel={() => setDemoShowRequestModal(false)}
                afterSubmit={() => setDemoShowRequestModal(false)}
                serviceType={serviceType}
                contentUuid={contentUuid}
                serviceId={serviceId}
                transferData={transferData}
                displayButton={setDisplayDemoButton}
                contentType={contentType}
                setLoader={setRequestDemoLoader}
            />
        </>
    );
};

export default connect(mapStateToProps)(ContentView);
