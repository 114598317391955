import React, {useState} from 'react';
import {FormattedMessage, useIntl} from "react-intl";
import {Switch} from "../../../../components/Form";
import {Radio, Table} from "antd";
import {connect} from 'react-redux';
import Spinner from 'components/Spinner';
import DefaultLayout from "components/DefaultLayout";
import './styles.scss';


const mapStateToProps = (state: any) => ({
    environment: state.environment.environment,
});


const PhishingTab: React.FC<any> = ({environment}) => {
    const intl = useIntl();
    const [size, setSize] = useState('thisMonth');

    const [data, setData] = useState(
        [
            {
                name: 'Vitālijs Ševčenko',
                points: 40,

            },
            {
                name: 'Ērika Ciguze',
                points: 20,
            },
        ]
    )

    const [data2, setData2] = useState(
        [
            {
                date: '10/08/2023',
                campaign: 'Demo 10.08.2023',
                simulation: 'Google Chrome browser security update',
                difficulty: 'Zema',
                reported: '10/08/2023',
                lastActivity: 'Nav atvērts e-pasts',
                points: 10,
            },
            {
                date: '10/08/2023',
                campaign: 'Demo 10.08.2023',
                simulation: 'URGENT: Your Office 365 account is at risk',
                difficulty: 'Vidēja',
                reported: '10/08/2023',
                lastActivity: 'Nav atvērts e-pasts',
                points: 20,
            },
            {
                date: '10/08/2023',
                campaign: 'Demo 10.08.2023',
                simulation: 'Atgādinājums par pusdienu pasūtījumu',
                difficulty: '-',
                reported: '10/08/2023',
                lastActivity: 'Nav atvērts e-pasts',
                points: 0,
            },
        ]
    )

    const columns2 = [
        {
            title: intl.formatMessage({id: 'general.date'}),
            dataIndex: 'date'
        },
        {
            title: intl.formatMessage({id: 'phishing.campaign_name'}),
            dataIndex: 'campaign'
        },
        {
            title: intl.formatMessage({id: 'phishing.simulation_template_name'}),
            dataIndex: 'simulation'
        },
        {
            title: intl.formatMessage({id: 'phishing.difficulty'}),
            dataIndex: 'difficulty',
        },
        {
            title: intl.formatMessage({id: 'phishing.reported'}),
            dataIndex: 'reported',
        },
        {
            title: intl.formatMessage({id: 'phishing.last_activity'}),
            dataIndex: 'lastActivity',
        },
        {
            title: intl.formatMessage({id: 'general.points'}),
            dataIndex: 'points',
        },
    ];


    const columns = [
        {
            title: intl.formatMessage({id: 'general.name'}),
            dataIndex: 'name'
        },
        {
            title: intl.formatMessage({id: 'general.points'}),
            dataIndex: 'points'
        },
    ];


    const tables = (
        <div className={'flex justify-between gap-10'}>
            <div>
                <div className={'flex bold h-80'}>
                    <FormattedMessage id='phishing.reported_events'/>
                </div>

                <Table columns={columns2} dataSource={data2} pagination={false}/>
            </div>
            <div>
                <div className={'flex flex-column justify-between min-w-320'}>
                    <div className={'bold h-12'}>
                        <FormattedMessage id='phishing.user_top'/>
                    </div>
                    <Radio.Group value={size} onChange={e => setSize(e.target.value)}>
                        <Radio.Button value="thisMonth">
                            <FormattedMessage id='general.this_month'/>
                        </Radio.Button>
                        <Radio.Button value="thisQuarter">
                            <FormattedMessage id='general.this_quarter'/>
                        </Radio.Button>
                        <Radio.Button value="thisYear">
                            <FormattedMessage id='general.this_year'/>
                        </Radio.Button>
                    </Radio.Group>
                </div>
                <Table
                    pagination={false}
                    columns={columns}
                    dataSource={data}
                    footer={() => <Switch isFormItem label={intl.formatMessage({id: 'phishing.hide_personal_data'})}/>}
                />
            </div>
        </div>
    )

    if (environment === 'LEARNING') {
        return (
            <Spinner spinning={false}>
                <DefaultLayout.PageLayout withoutPageLayout>
                    <div className={'student-env'}>
                        {tables}
                    </div>
                </DefaultLayout.PageLayout>
            </Spinner>
        )
    }

    return (
        <>
            {tables}
        </>
    )
};

export default connect(mapStateToProps)(PhishingTab);
