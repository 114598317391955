import React, { useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Form, FormInstance, message, Divider } from 'antd';
import { Select } from 'components/Form';
import {Button} from 'ui'
import coursesApiClient from 'utils/coursesApiClient';
import Input from 'components/Form/Input';
import Spinner from 'components/Spinner';
import useHandleError from "utils/useHandleError";
import {useNavigate, useParams} from "react-router-dom";
import FlexRow from "../../../../components/FlexRow";

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

type LearningTestMaterialFormType = {
    type: 'LEARNING_TEST',
    language: string,
    learningTestId: number,
    settings: {}
}

interface LearningTestMaterialFormInterface {
    language: string;
    form: FormInstance;
    activityId: number;
    viewMaterial?: any;
    onCancel: any;
    reload: Function;
}

const LearningTestMaterialForm: React.FC<LearningTestMaterialFormInterface> = ({
    language,
    form,
    activityId,
    viewMaterial,
    onCancel,
    reload
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [learningTestOptions, setLearningTestOptions] = useState<any>([]);
    const [isSubmitProgress, setIsSubmitProgress] = useState(false);
    const navigate = useNavigate();

    const intl = useIntl();
    const [handleError] = useHandleError();
    const [formTest] = Form.useForm();
    const params = useParams();
    let materialId = params.courseId;

    useEffect(() => {
        if (activityId) {
            loadLearningTestOptions()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activityId]);

    useEffect(() => {
        if (viewMaterial) {

            const materialSettings = JSON.parse(viewMaterial.settings);

            if (viewMaterial.settings) {
                form.setFieldsValue({
                    learningTestId: viewMaterial.learningTestId,
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewMaterial]);

    const submitForm = async (values: any) => {
        setIsLoading(true);
        try {
            let description = values.description?.level?.content ? values.description.level.content : values.description?.level?.fragments ? values.description.level.fragments : values.description;

            if (Array.isArray(description)) {
                description = values.description?.level?.fragments.join(' ');
            }

            const parsedValues: LearningTestMaterialFormType = {
                type: 'LEARNING_TEST',
                language: language,
                learningTestId: values.learningTestId,
                settings: {}
            }

            let response;

            if (viewMaterial) {
                response = await coursesApiClient.request(`/api/v1/courses/material/update/${viewMaterial.id}`, parsedValues, 'PUT');
            } else {
                response = await coursesApiClient.request(`/api/v1/courses/material/${activityId}/add-material`, parsedValues, 'POST');
            }

            onCancel();
            reload(activityId);
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        } finally {
            setIsLoading(false);
        }
    }

    const loadLearningTestOptions = async () => {

        setIsLoading(true);
        try {
            const response = await coursesApiClient.request(`/api/v1/courses/learning-tests/learning-test-options`, {}, 'GET');
            setLearningTestOptions(response.options)
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false);
        }
    };

    const submitTestForm = async (values: any) => {
        setIsSubmitProgress(true);

        let parsedValues = {
            ...values,
        }

        try {
            let response = await coursesApiClient.request(`/api/v1/courses/learning-tests/store`, parsedValues, 'POST');
            navigate(`/learning/tests/${response.test.id}/edit?course=${materialId}&material=${activityId}`);
        } catch (error) {
            handleError(error)
        } finally {
            setIsSubmitProgress(false);
        }
    }

    return (
        <>
            <Form form={form} onFinish={submitForm} >
                <Spinner spinning={isLoading}>
                    <Select
                        customLayout={formItemLayout}
                        name='learningTestId'
                        label={intl.formatMessage({ id: 'courses.learning_test' })}
                        manualOptions={learningTestOptions}
                    />
                </Spinner>
            </Form>
            <div className={'flex justify-end gap-5'}>
                <Button>
                    <FormattedMessage id={'general.cancel'}/>
                </Button>
                <Button type={'primary'} onClick={() => form.submit()}>
                    <FormattedMessage id={'general.submit'}/>
                </Button>
            </div>
            <Divider/>
            <Form form={formTest} onFinish={submitTestForm}>
                <Input customLayout={formItemLayout} name="title" label={intl.formatMessage({ id: 'learning_tests.learning_test_title' })} validation={{ required: true }}/>
            </Form>
            <FlexRow
                right={
                    <Button type={'primary'} onClick={() => formTest.submit()}>
                        <FormattedMessage id='courses.new_test_activity' />
                    </Button>
                }
            />
         </>
    )
}
export default LearningTestMaterialForm;
