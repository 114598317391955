import React, {useState, useEffect} from 'react';
import {useIntl} from 'react-intl';
import {Form, Select as AntDSelect, Tooltip} from 'antd';
import apiClient from 'utils/apiClient';
import {constructValidationRules} from './validations';
import './styles.scss';
import {ComponentAccessCheck} from "ui";

const Select: React.FC<any> = ({
                                   url,
                                   integerKey,
                                   dataKey,
                                   isResponseArray,
                                   valueKey,
                                   labelKey,
                                   mapDataEntries,
                                   name,
                                   label,
                                   validation = {},
                                   customRules,
                                   infoText,
                                   sortOptions,
                                   classifierIsObject,
                                   customLayout,
                                   disabled,
                                   customObjLabel,
                                   filter,
                                   help,
                                   withSystemName,
                                   distinct,
                                   visible = true,
                                   arrayHasKey = true,
                                   manualOptions,
                                   className,
                                   isFormItem = true,
                                   hint,
                                   translationModule,
                                   saveLoadedData = false,
                                   setLoadedData,
                                   ...props
                               }) => {
    const [options, setOptions] = useState<any>(isResponseArray ? [] : {});
    const [isOptionsLoading, setIsOptionsLoading] = useState(false);

    const intl = useIntl();

    // Fix for google autoFill popping up in select input
    // https://github.com/ant-design/ant-design/issues/7659
    useEffect(() => {
        const fixAutoComplete = () => {
            document.querySelectorAll(".ant-select-selector input").forEach((e) => {
                e.setAttribute("autoComplete", "noAutoComplete");
                //you can put any value but NOT "off" or "false" because they DO NOT work
            })
        };

        fixAutoComplete();
    }, []);

    useEffect(() => {
        const loadOptions = async () => {
            setIsOptionsLoading(true);
            if (manualOptions) {
                setOptions(manualOptions);
            } else {
                if (url) {
                    setOptions([]);
                    let optionsResponse = await apiClient.request(url, {}, 'GET');
                    if (optionsResponse) {
                        let parsedOptions = parseOptions(dataKey ? optionsResponse[dataKey] : optionsResponse);
                        setOptions(parsedOptions);
                        if (saveLoadedData) {
                            setLoadedData(parsedOptions);
                        }

                    } else {
                        setOptions(isResponseArray ? [] : {});
                    }
                }
            }

            setIsOptionsLoading(false);
        };

        const parseOptions = (options: any) => {
            let parsedOptions = options;

            if (!options && isResponseArray) {
                parsedOptions = [];
            } else if (!options) {
                parsedOptions = {};
            }

            if (filter) {
                parsedOptions = parsedOptions.filter(filter);
            }

            if (mapDataEntries) {
                parsedOptions = parsedOptions.map(mapDataEntries);
            }

            if (distinct && isResponseArray) {
                let distinctOptions = [];
                const map = new Map();
                for (const item of parsedOptions) {
                    if (!map.has(item.value)) {
                        map.set(item.value, true);
                        distinctOptions.push({
                            value: item.value,
                            label: item.label
                        });
                    }
                }
                parsedOptions = distinctOptions;
            }

            if (sortOptions) {
                if (classifierIsObject) {
                    parsedOptions = Object.entries(parsedOptions).sort(sortOptions);
                } else {
                    parsedOptions = parsedOptions.sort(sortOptions);
                }
            }

            if (translationModule) {
                Object.keys(parsedOptions).forEach((key) => {
                    parsedOptions[key] = intl.formatMessage({id: `${translationModule}.${key.toLowerCase()}`});
                });
            }

            return parsedOptions;
        };

        if (options) {
            loadOptions();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url, manualOptions]);

    const parsedKey = (key: string) => {
        if (integerKey) {
            return parseInt(key);
        }

        return key;
    };

    let labelCol: any = {span: 8};
    let wrapperCol: any = {span: 6};

    if (customLayout) {
        if (typeof customLayout === 'object') {
            labelCol = customLayout.labelCol || undefined;
            wrapperCol = customLayout.wrapperCol || undefined;
        } else {
            labelCol = undefined;
            wrapperCol = undefined;
        }
    }

    const getOption = (key: any) => {

        if (customObjLabel) {
            return customObjLabel(options[key])
        } else {
            return options[key]
        }

        return null;
    }

    const selectComponent = (
        <AntDSelect
            loading={isOptionsLoading}
            className={`default-select ${props.className || ''}`}
            disabled={disabled}
            filterOption={(input: string, option: any) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
            {...props}
        >
            {
                isResponseArray
                    ? options.map((option: any) => {
                        if (arrayHasKey) {
                            return (
                                <AntDSelect.Option key={option[valueKey]} value={option[valueKey]}>
                                    {option[labelKey]}
                                </AntDSelect.Option>
                            );
                        } else {
                            return (
                                <AntDSelect.Option key={option} value={option}>
                                    {option}
                                </AntDSelect.Option>
                            );
                        }

                    })
                    : Object.keys(options).map((key: any, index: number) => {
                        if (sortOptions && classifierIsObject) {
                            return (
                                <AntDSelect.Option
                                    key={parsedKey(key)}
                                    value={options[key][0]}
                                >
                                    {options[key][1]}
                                </AntDSelect.Option>
                            );
                        }
                        if (key === 'PHISHING_SERVICE') {
                            <ComponentAccessCheck permission={'view_phishing'}>
                                <AntDSelect.Option
                                    key={parsedKey(key)}
                                    value={parsedKey(key)}
                                >
                                    {getOption(key)}
                                </AntDSelect.Option>
                            </ComponentAccessCheck>
                        }
                        return (
                            <AntDSelect.Option
                                key={parsedKey(key)}
                                value={parsedKey(key)}
                            >
                                {getOption(key)}
                            </AntDSelect.Option>
                        );
                    })
            }
        </AntDSelect>
    )

    return (
        <>
        {isFormItem ?
            <Form.Item
                className={className}
                labelCol={labelCol}
                wrapperCol={wrapperCol}
                name={name}
                label={
                    hint
                        ? <span>
                {intl.formatMessage({id: label})}
                            <Tooltip title={intl.formatMessage({id: hint})}>
                  <i className='fal fa-question-circle header-item'/>
                </Tooltip>
              </span>
                        : label
                }
                rules={customRules || constructValidationRules(validation, intl)}
                help={help}
                style={{display: visible ? '' : 'none'}}
            >
                {selectComponent}
            </Form.Item>
            :
            selectComponent
        }

        </>

    )
};

export default Select;
