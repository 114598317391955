import React from 'react';
import './styles.scss';
import {FormattedMessage, useIntl} from "react-intl";
import {Search} from "../../ui";
import ProgramSwitch from "../FilterSwitches/ProgramSwitch";
import StatusSwitch from "../FilterSwitches/StatusSwitch";
import ValiditySwitch from "../FilterSwitches/ValiditySwitch";
import CompetenceSwitch from "../FilterSwitches/CompetenceSwitch";

interface StudentProgramFilterProps {
    setSearchString:  React.Dispatch<React.SetStateAction<string>>,
    setReload: React.Dispatch<React.SetStateAction<boolean>>,
    filtersSelected: any,
    programsList: any,
    setFiltersSelected: React.Dispatch<React.SetStateAction<any>>,
    status: any,
    competences: any,
    validity: any,
}
const StudentProgramFilter: React.FC<StudentProgramFilterProps> = ({ setSearchString, setReload, filtersSelected, setFiltersSelected, programsList, status, competences, validity }) => {
    const intl = useIntl();

    let possibleFilters:any = {
        competences: {
            all:intl.formatMessage({id: 'student.all_competencies'})
        },
    }

    Object.values(programsList).map((competence: any) => {
        if (competence.id == '0') {
            possibleFilters.competences[competence.id] = intl.formatMessage({id: 'organizations.OTHER'});
        } else {
            possibleFilters.competences[competence.id] = competence.title;
        }
    });

    return (
        <div className="activity-list-wrapper">
            <div className="filter-container">
                <div className="search">
                    <Search setSearchString={setSearchString} setReload={setReload} />
                </div>
                <div className="filters">
                    {
                        status === false
                            ? <></>
                            : <StatusSwitch
                                filtersSelected={filtersSelected}
                                setFiltersSelected={setFiltersSelected}
                            />
                    }
                    {
                        competences === false
                            ? <></>
                            : <CompetenceSwitch
                                possibleFilters={possibleFilters}
                                filtersSelected={filtersSelected}
                                setFiltersSelected={setFiltersSelected}
                            />
                    }
                    {
                        validity === false
                            ? <></>
                            : <ValiditySwitch
                                filtersSelected={filtersSelected}
                                setFiltersSelected={setFiltersSelected}
                            />
                    }
                </div>
            </div>
            <div className="filter-clear" onClick={()=>{setFiltersSelected({})}}>
                <FormattedMessage id={'student.clear_filters'} />
            </div>
        </div>
    )};

export default StudentProgramFilter;