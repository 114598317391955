import React, {useEffect, useState} from 'react';
import FilterCheckBox from '../FilterCheckBox';
import FilterPopover from "../FilterPopover";
import {Button} from "ui";
import {FormattedMessage} from "react-intl";
import {useLecture} from "hooks/Lectures/useLecture";
import {useAudience} from "../../../../hooks/Audiences/useAudience";

interface AudienceFilterInterface {
    tagBuilder: Function;
    session?: any;
    setFilterValues: any;
    filterValues: any
    form: any;
    filterKey: string;
    setFilterKey: React.Dispatch<React.SetStateAction<string>>;
}

const AudienceFilter: React.FC<AudienceFilterInterface> = ({
                                                               filterKey,
                                                               setFilterKey,
                                                               form,
                                                               tagBuilder,
                                                               setFilterValues,
                                                               filterValues
                                                           }) => {
    const {searchAudiences, audienceLoading: isLoading} = useAudience()
    const [data, setData] = useState([]);
    const [initialValues, setInitialValues] = useState(undefined)

    const key = 'audiences'

    useEffect(() => {
        onSearch()
    }, [])

    const onSearch = async (value: string = '') => {
        const response = await searchAudiences(value)
        setData(response);
        setFilterValues(
            {
                ...filterValues,
                audienceId: {
                    ...filterValues.audienceId,
                    ...response
                }
            }
        )
    };

    const content = (
        <>
            <FilterCheckBox data={data} name={'audienceId'} onSearch={onSearch}/>
            <div className={'flex justify-between p-1'}>
                <Button type={'link'} onClick={() => handleClose()}>
                    <FormattedMessage id={'general.cancel'}/>
                </Button>
                <Button type={'primary'} onClick={() => {
                    setFilterKey('')
                    handleOpen(false)
                }}>
                    <FormattedMessage id={'general.select'}/>
                </Button>
            </div>
        </>
    );

    const handleClose = () => {
        form.setFieldsValue(initialValues);
        setFilterKey('')
        ;
    }

    const handleOpen = (open: boolean) => {
        if (open) {
            setInitialValues(form.getFieldsValue())
            setFilterValues(
                {
                    ...filterValues,
                    audienceId: {
                        ...filterValues.audienceId,
                        ...data
                    }
                }
            )
        } else {
            tagBuilder()
        }
    }

    return (
        <FilterPopover
            popoverKey={key}
            filterKey={filterKey}
            setOpen={setFilterKey}
            handleOpen={handleOpen}
            content={content}
            loading={isLoading}
            title={'general.search_by_audiences'}
            buttonTitle={'general.audiences'}
        />
    );
};

export default AudienceFilter;