import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Form, Modal, message, Radio} from 'antd';
import {Select} from 'components/Form';
import {connect} from 'react-redux';
import {changeLicenceFilter} from 'store/actions/saveLicenceFilter';

import apiClient from 'utils/apiClient';

const mapStateToProps = (state: any) => {
    return {
        licenceFilter: state.licenceFilter,
        session: state.session,
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    changeLicenceFilter: (formData: any) => dispatch(changeLicenceFilter(formData))
});

const LicenceFilterModal = ({
                                showFilter, setShowFilter, customers, organizations, setLicenseActivities,
                                customersLoading, setLicences, setLicencesLoading,
                                setFilteredCustomerName, setValidOnly, setSearchString,
                                setResponsibleAdmins, setResponsibleOrganizations, setCustomer,
                                newLicenceForm, changeLicenceFilter, licenceFilter, setLicenceConsumption, session
                            }: any) => {
    const [licenseOwnerType, setLicenseOwnerType] = useState('CUSTOMER');
    const intl = useIntl();
    const [form] = Form.useForm();

    const clearForm = () => form.setFieldsValue({customer: null});

    useEffect(() => {
        if (session.active_user_type === 'CUSTOMER_ADMIN') {
            filter({customer: session.organization.organization_id});
        }

        form.setFieldsValue({filterBy: 'CUSTOMER'})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl]);

    const filter = async (values: any) => {
        setShowFilter(false);

        let response;
        try {
            setLicencesLoading(true);

            if (values?.organization) {
                response = await apiClient.request(`/api/v1/organizations/${values.organization}/licences`, {}, 'GET');
            } else {
                response = await apiClient.request(`/api/v1/customers/${values.customer}/licences`, {}, 'GET');
            }

            if (values?.customer) {
                changeLicenceFilter(values.customer);
            }

            if (session.active_user_type === 'CUSTOMER_ADMIN') {
                setFilteredCustomerName(session.user_types.customers[session.organization.organization_id].name);
            } else if (values?.customer) {
                setFilteredCustomerName(customers[values.customer]);
            } else if (values?.organization) {
                setFilteredCustomerName(organizations[values.organization]);
            }

            const licences = response.licences;
            const responsibleAdmins = response.responsibleAdmins;
            const responsibleOrganizations = response.organizations;
            const activities = response.activities;
            setLicenceConsumption(response.licenceParams);
            setLicenseActivities(activities);

            licences.forEach((licence: any) => {
                licence.isLicence = true;
                if (licence.services.length) {
                    licence.children = licence.services;
                    licence.licenseId = licence.id;
                }
            });

            setLicences(licences);

            setResponsibleAdmins(responsibleAdmins)
            setResponsibleOrganizations(responsibleOrganizations)

            if (session.active_user_type === 'CUSTOMER_ADMIN') {
                setValidOnly(true);
            } else {
                setValidOnly(false);
            }

            setSearchString('');
            setCustomer(values.customer);

            clearForm();
            newLicenceForm.setFieldsValue({customer: values.customer});
        } catch (error) {
            message.error(intl.formatMessage({id: 'error.data_load'}));
            console.error(error);
        } finally {
            setLicencesLoading(false);
        }
    }

    if (licenceFilter.customerId && form.getFieldValue('customer') === undefined && customers !== null) {
        form.setFieldsValue({customer: licenceFilter.customerId});
        filter({customer: licenceFilter.customerId});
    }

    return (
        <Modal
            open={showFilter}
            title={intl.formatMessage({id: 'licensing.filter_licences'})}
            okText={intl.formatMessage({id: 'general.filter'})}
            cancelText={intl.formatMessage({id: 'general.cancel'})}
            onOk={form.submit}
            onCancel={() => {
                setShowFilter(false);
                clearForm();
            }}
        >
            <Form form={form} onFinish={filter}>
                <Form.Item name="filterBy"
                           label={intl.formatMessage({id: 'general.filter_by'})}
                >
                    <Radio.Group onChange={(el: any) => setLicenseOwnerType(el.target.value)}>
                        <Radio value="CUSTOMER"><FormattedMessage id='general.customer'/></Radio>
                        <Radio value="ORGANIZATION"><FormattedMessage id='general.reseller'/></Radio>
                    </Radio.Group>
                </Form.Item>
                {licenseOwnerType === 'CUSTOMER' ?
                    <Select
                        showSearch
                        customLayout
                        name="customer"
                        label={intl.formatMessage({id: 'general.customer'})}
                        customRules={[{required: true, message: intl.formatMessage({id: 'validation.field_required'})}]}
                        manualOptions={customers}
                        filterOption={(input: string, option: any) => {
                            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                        }}
                        loading={customersLoading}
                    />
                    :
                    <Select
                        showSearch
                        customLayout
                        name="organization"
                        label={intl.formatMessage({id: 'general.organizations'})}
                        customRules={[{required: true, message: intl.formatMessage({id: 'validation.field_required'})}]}
                        manualOptions={organizations}
                        filterOption={(input: string, option: any) => {
                            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                        }}
                        loading={customersLoading}
                    />
                }
            </Form>
        </Modal>
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(LicenceFilterModal);
