import React, {useEffect, useState} from 'react';
import {CheckOutlined, CloseOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import {FormattedMessage, useIntl} from 'react-intl';
import {Button, message, Modal} from 'antd';
import {RequestSelect, Switch} from 'components/Form';
import {connect} from 'react-redux';
import {Table} from "ui";

import useHandleError from "utils/useHandleError";
import apiClient from "utils/apiClient";
import generalHelpers from "utils/generalHelpers";
import coursesApiClient from "utils/coursesApiClient";

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale,
        session: state.session
    }
};

interface UsersInterface {
    locale?: string;
    customers: string[];
    session?: any;
    userKey?: string;
    reloadTable?: boolean,
    url: {
        C: string;
        R: string;
        U: string;
        D: string;
    };
    setUsers: React.Dispatch<React.SetStateAction<any>>
    customerUuid?: boolean;
    hasAdminPermission?: boolean;
    disabled?: boolean;
}

const Users: React.FC<UsersInterface> = ({customers, url, userKey= 'type_uuid', setUsers, session, reloadTable = false, customerUuid= false, hasAdminPermission = false, disabled = false}) => {
    const [selectedUser, setSelectedUser] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [reload, setReload] = useState(false);

    const intl = useIntl();
    const [handleError] = useHandleError();
    const {confirm} = Modal;

    useEffect(() => {
        if (reloadTable) {
            setReload(true);
        }
    }, [reloadTable])

    const columns = [
        {
            title: intl.formatMessage({id: 'general.name'}),
            dataIndex: 'name',
        },
        {
            title: intl.formatMessage({id: 'general.surname'}),
            dataIndex: 'surname',
        },
        {
            title: intl.formatMessage({id: 'general.email'}),
            dataIndex: 'email',
        },
        {
            title: intl.formatMessage({id: 'general.position'}),
            dataIndex: 'position',
        },
        {
            title: intl.formatMessage({id: 'general.organization'}),
            dataIndex: 'organization',
        },
        {
            title: intl.formatMessage({id: 'general.organizational_unit'}),
            dataIndex: 'organizationalUnit',
        },
        {
            title: intl.formatMessage({id: 'general.role'}),
            dataIndex: 'role',
        },
        {
            title: intl.formatMessage({id: 'general.view'}),
            render: (_text: string, record: any) => {

                return (
                    <Switch
                        disabled={disabled}
                        checked={!!record.view}
                        loading={isLoading}
                        onClick={() => updatePermissions(record.id, 'view', !record.view)}
                    />
                )
            }
        },
        {
            title: intl.formatMessage({id: 'general.edit'}),
            render: (_text: string, record: any) => {
                return (
                    <Switch
                        disabled={disabled}
                        checked={!!record.edit}
                        loading={isLoading}
                        onClick={() => updatePermissions(record.id, 'edit', !record.edit)}
                    />
                )
            }
        },
        {
            title: intl.formatMessage({id: 'general.admin'}),
            index: 'admin',
            render: (_text: string, record: any) => {

                if (record?.admin !== undefined) {
                    return (
                        <Switch
                            disabled={disabled}
                            checked={!!record.admin}
                            loading={isLoading}
                            onClick={() => updatePermissions(record.id, 'admin', !record.admin)}
                        />
                    )
                }
            }
        },
        {
            render: (_text: string, record: any) => {

                return (
                    <div className={'pointer'} onClick={() => warnDeletion(record)}>
                        <i className="fa-duotone fa-x"></i>
                    </div>
                )
            }
        },
    ];

    const warnDeletion = async (record: any) => {
        if (record.typeUuid === session.active_user_type_uuid) {
            message.warning(intl.formatMessage({id: 'warning.self_remove'}));
            return;
        }

        confirm({
            title: intl.formatMessage({id: 'general.attention'}),
            content: intl.formatMessage({id: 'program.delete_admin'}),
            icon: <ExclamationCircleOutlined/>,
            okText: intl.formatMessage({id: 'general.yes'}),
            cancelText: intl.formatMessage({id: 'general.no'}),

            onOk() {
                deleteResource(record.id);
            }
        });
    }

    const deleteResource = async (id: number) => {
        setIsLoading(true)
        if (!url?.D) {
            return;
        }
        try {
            await coursesApiClient.request(generalHelpers.formUrl(url.D, {id: id}), {}, 'DELETE');
            setReload(true)
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    const addAccessUser = async () => {
        if (selectedUser) {
            setIsLoading(true)
            try {
                await apiClient.request(url.C, {users: [selectedUser]}, 'POST');
                setReload(true)
            } catch (error) {
                handleError(error)
            } finally {
                setIsLoading(false)
            }
        }
    }

    const updatePermissions = async (id: number, permission: string, status: boolean) => {
        setIsLoading(true)
        try {
            await apiClient.request(generalHelpers.formUrl(url.U, {id: id}), {[permission]: status}, 'PUT');
            setReload(true)
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <>
            <div className='flex-left gap-8'>
                <FormattedMessage id='general.add_user'/>
                <RequestSelect
                    disabled={disabled}
                    isForm={false}
                    customLayout={true}
                    selectClassName='request-select'
                    url={`/api/v1/edu/users/mass-search`}
                    param={[`${customerUuid ? 'customerUuid' : 'customerId'}=${customers.toString()}`, `key=${userKey}`]}
                    column
                    showSearch
                    onChange={(typeUuid: string) => setSelectedUser(typeUuid)}
                />
                <Button disabled={disabled} type='default' onClick={() => addAccessUser()}>
                    <FormattedMessage id='general.add'/>
                </Button>
            </div>
            <Table
                columns={!hasAdminPermission ? columns.filter((column: any) => column.index !== 'admin') : columns}
                url={url.R}
                reload={reload}
                setReload={setReload}
                rowKey={'id'}
                isLoading={isLoading}
                rowSelection={false}
                saveData={setUsers}
                scroll={{ x: 800 }}
            />
        </>
    )
};

export default connect(mapStateToProps)(Users);
