import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Form, Modal, message, Radio} from 'antd';
import {Select, CustomerSelect, Input} from 'components/Form';
import {changeCampaignCustomerFilter} from 'store/actions/campaignCustomerFilter';
import {connect} from 'react-redux';
import coursesApiClient from 'utils/coursesApiClient';
import {config} from 'config/config';
import { changeCampaignData } from 'store/actions/campaignData';
import generalHelper from "../../../utils/generalHelpers";

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const mapStateToProps = (state: any) => {
    return {
        session: state.session,
        campaignCustomerFilter: state.campaignCustomerFilter,
        locale: state.locale.locale
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    changeCampaignCustomerFilter: (formData: any) => dispatch(changeCampaignCustomerFilter(formData)),
    changeCampaignData: (formData: any) => dispatch(changeCampaignData(formData))

});

const CampaignFilterModal = ({locale, visible, onCancel, session, setCampaignList, setCampaignOriginalList, setLoading, campaignCustomerFilter, changeCampaignCustomerFilter, finished, changeCampaignData}: any) => {
    const [isCourseListLoading, setIsCourseListLoading] = useState(false);
    const [customerId, setCustomerId] = useState();
    const [customerUuid, setCustomerUuid] = useState();
    const [courseList, setCourseList] = useState([]);
    const isCustomerAdmin = session.active_user_type === 'CUSTOMER_ADMIN';
    const [filterBy, setFilterBy] = useState('CUSTOMER');
    const [courses, setCourses] = useState([]);

    const intl = useIntl();
    const [form] = Form.useForm();

    useEffect(() => {
        if (session.active_user_type === 'CUSTOMER_ADMIN') {
            loadCourses(session.organization.organization_id, 'CUSTOMER')
        }

        if (campaignCustomerFilter.customerUuid && !isCustomerAdmin) {
            form.setFieldsValue({
                customer: campaignCustomerFilter.customerId
            });
            setCustomerUuid(campaignCustomerFilter.customerUuid);
            setCustomerId(campaignCustomerFilter.customerId);
            submitForm({
                customer: campaignCustomerFilter.customerId,
                status: undefined,
                includedCourses: []
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl]);

    useEffect(() => {
        if ((session?.organization?.organization_type !== 'ORGANIZATION' && session?.organization?.organization_uuid) || customerUuid) {
            submitForm([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[finished])

    const loadCourses = async (id: string, type: 'CUSTOMER' | 'ORGANIZATION') => {
        setCourseList([]);
        form.setFieldsValue({includedCourses: []});
        if (!id) {
            return;
        }
        try {
            setIsCourseListLoading(true);

            let url = '/api/v1/courses/courses/valid';

            if (!isCustomerAdmin) {
                switch (type) {
                    case "CUSTOMER":
                        url += '?customerId=' + id
                        break;

                    case "ORGANIZATION":
                        url += '?organizationId=' + id
                        break;
                }
            }

            const response = await coursesApiClient.request(url, {}, 'POST');
            const courses = response.courses;

            setCourses(courses)
            const courseListObject: any = {};

            Object.values(courses).map((course: any) => {
                return courseListObject[course.id] = course.name_translations ? (course.name_translations[locale] ? course.name_translations[locale] : course.name) : course.name;
            });

            setCourseList(courseListObject);
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({id: 'error.data_load'}));
        } finally {
            setIsCourseListLoading(false);
        }
    };

    const submitForm = async (values: any) => {
        setLoading(true);
        try {
            const parsedValues = {
                status: values.status,
                orgType: values.organizationType,
                includedCourses: values.includedCourses
            };

            if (session.active_user_type === 'SUPERVISION') {
                if (!values.status && finished) {
                    parsedValues.status = 'in:ONGOING'
                } else if (!values.status && !finished) {
                    parsedValues.status = 'in:ONGOING,FINISHED'
                }
            } else {
                if (!values.status && finished) {
                    parsedValues.status = 'in:DRAFT,ONGOING'
                } else if (!values.status && !finished) {
                    parsedValues.status = 'in:DRAFT,ONGOING,FINISHED'
                }
            }

            let uuid;
            if (session.active_user_type === 'CUSTOMER_ADMIN' || session.active_user_type === 'SUPERVISION' ) {
                uuid = session.organization.organization_uuid
            } else {
                uuid = customerUuid ? customerUuid : values.ownerUuid ;
            }

            if (!uuid && campaignCustomerFilter?.customerUuid) {
                uuid = campaignCustomerFilter.customerUuid;
            }

            if (!uuid) {
                return message.error(intl.formatMessage({id: 'error.data_load'}));
            }
            onCancel();
            const response = await coursesApiClient.request(`/api/v1/courses/customer/${uuid}/filter-campaigns`, parsedValues, 'POST');

            changeCampaignData({customerId: customerId, customerUuid: customerUuid, cameFromCampaign: false});

            changeCampaignCustomerFilter({
                customerId: customerId,
                customerUuid: uuid
            });

            setCampaignList(response.campaigns);
            setCampaignOriginalList(response.campaigns);
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({id: 'error.data_load'}));
        } finally {
            setLoading(false);
        }
    };

    const displayOrganizationSelect = (type: 'CUSTOMER' | 'ORGANIZATION') => {
        let display = false;

        switch (type) {
            case 'CUSTOMER':
                if (filterBy === 'CUSTOMER') {
                    display = true;
                }
                break;
            case 'ORGANIZATION':
                if (filterBy === 'ORGANIZATION') {
                    display = true;
                }
                break;
        }

        return display;
    }

    return (
        <Modal
            title={intl.formatMessage({id: 'courses.campaign_filter'})}
            open={visible}
            onCancel={() => onCancel(true)}
            okText={intl.formatMessage({id: 'general.filter'})}
            cancelText={intl.formatMessage({id: 'general.cancel'})}
            onOk={form.submit}
            forceRender={true}
        >
            <Form form={form} onFinish={submitForm} {...formItemLayout}>
                {!generalHelper.isCustomerAdmin(session.active_user_type) &&
                  <Form.Item
                    name="organizationType"
                    label={intl.formatMessage({id: 'general.filter_by'})}
                  >
                    <Radio.Group onChange={(el: any) => setFilterBy(el.target.value)}>
                      <Radio value="CUSTOMER"><FormattedMessage id='general.customer'/></Radio>
                      <Radio value="ORGANIZATION"><FormattedMessage id='general.reseller'/></Radio>
                    </Radio.Group>
                  </Form.Item>
                }
                {!generalHelper.isCustomerAdmin(session.active_user_type) && displayOrganizationSelect('CUSTOMER') &&
                  <Select
                    name='ownerUuid'
                    dataKey='customers'
                    allowClear
                    customLayout={formItemLayout}
                    showSearch
                    label={intl.formatMessage({id: "general.customer"})}
                    url={config.api.routes.backend.selectCustomersUuid}
                    dropdownStyle={{minWidth: "385px"}}
                    onChange={(id: any) => loadCourses(id, 'CUSTOMER')}
                    filterOption={(input: string, option: any) => {
                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                  />
                }
                {!generalHelper.isCustomerAdmin(session.active_user_type) && displayOrganizationSelect('ORGANIZATION') &&
                  <Select
                    allowClear
                    showSearch
                    customLayout={formItemLayout}
                    name='ownerUuid'
                    dataKey='organizations'
                    onChange={(id: any) => loadCourses(id, 'ORGANIZATION')}
                    label={intl.formatMessage({id: "general.reseller"})}
                    url={config.api.routes.backend.selectOrganizationsUuid}
                    dropdownStyle={{minWidth: "385px"}}
                    filterOption={(input: string, option: any) => {
                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                  />
                }
                <Select
                    allowClear
                    name='status'
                    customLayout={formItemLayout}
                    label={intl.formatMessage({ id: 'general.status' })}
                    url={config.api.routes.enums.campaignStatusTypes}
                />
                <Select
                    showArrow
                    showSearch
                    customLayout={formItemLayout}
                    name="includedCourses"
                    label={intl.formatMessage({id: 'campaign.included_courses'})}
                    onChange={(value: any) => {
                        form.setFieldsValue({value})
                    }}
                    valueKey="value"
                    labelKey="label"
                    manualOptions={courseList}
                    loading={isCourseListLoading}
                    mode="multiple"
                    disabled={generalHelper.isEmpty(courseList)}
                />
            </Form>
        </Modal>
    )
};
export default connect(mapStateToProps, mapDispatchToProps)(CampaignFilterModal);
