import React from 'react';
import { Alert, Form, message } from 'antd';
import { Modal } from 'ui';
import { useIntl } from 'react-intl';
import Spinner from '../../Spinner';
import { config } from 'config/config';
import { DatePicker } from 'components/Form';
import useQueryApiClient from 'utils/useApiClient';

interface TermModalInterface {
    reloadStudents: React.Dispatch<React.SetStateAction<boolean>>;
    open: boolean;
    onCancel: React.Dispatch<React.SetStateAction<boolean>>;
    students: number[];
    data: any;
    program: any;
}

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};

const TermModal: React.FC<TermModalInterface> = ({
    reloadStudents,
    open,
    onCancel,
    students,
    data,
    program,
}) => {
    const selected = data.filter((el: any) => el.enrollmentId === students[0])[0];
    const intl = useIntl();
    const [form] = Form.useForm();

    const { appendData: submit, isLoading } = useQueryApiClient({
        request: {
            disableOnMount: true,
            url: `/api/v1/courses/learning-program/${program.id}/attempt/${selected?.enrollmentId}/term`,
            method: 'POST',
        },
        onSuccess(response) {
            if (response?.error) {
                message.error(intl.formatMessage({ id: response.error }));
            } else {
                onCancel(false)
                reloadStudents(true);
            }
        }
    });

    const warning = () => {
        return (
            <Alert
                type="warning"
                showIcon
                message={intl.formatMessage({ id: 'program.select_one_user' })}
            />
        );
    };

    return (
        <Modal
            open={open}
            isLoading={isLoading}
            onCancel={() => onCancel(false)}
            className="add_remove_activity"
            destroyOnClose={true}
            title={`${selected?.name} ${selected?.surname}`}
            okText={intl.formatMessage({ id: 'general.save' })}
            cancelText={intl.formatMessage({ id: 'general.cancel' })}
            onOk={students.length > 1 ? undefined : form.submit}
            okButtonProps={{
                disabled: students.length > 1,
            }}
        >
            {students.length > 1 ? warning() : null}
            <Spinner spinning={isLoading}>
                <Form form={form} onFinish={submit} {...formItemLayout}>
                    <DatePicker
                        label={intl.formatMessage({ id: 'general.deadline' })}
                        name={'deadline'}
                        defaultValue={null}
                        format={config.defaultDateFormat}
                        customLayout={true}
                    />
                    <DatePicker
                        label={intl.formatMessage({ id: 'general.is_completed' })}
                        name={'isCompleted'}
                        defaultValue={null}
                        format={config.defaultDateFormat}
                        disabled={selected?.actualStatus !== 'COMPLETED'}
                        customLayout={true}
                    />
                    {program.type === 'CERTIFICATION' ? (
                        <DatePicker
                            label={intl.formatMessage({ id: 'program.recertification' })}
                            name={'recertification'}
                            defaultValue={null}
                            format={config.defaultDateFormat}
                            customLayout={true}
                        />
                    ) : null}
                </Form>
            </Spinner>
        </Modal>
    );
};

export default TermModal;
