import React, {useState, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Modal, Input, Form, Tag, Tabs, message} from 'antd';
import apiClient from 'utils/apiClient';
import Spinner from 'components/Spinner';
import useHandleError from "utils/useHandleError";
import {useDate} from 'hooks/useDate';
import {AssessmentOverview, AssessmentDescription, AssessmentInstructors, AssessmentFinalResult, AssessmentFiles} from 'components/AssessmentActivity/Tabs';
import './styles.scss';
// import SubmissionDetail from 'components/AssessmentActivity/SubmissionDetail'

interface AssessmentSubmissionInterface {
    open: boolean;
    onGraded: Function;
    onCancel: any;
    isLoading: boolean;
    activityFormData: any;
    canGrade: boolean;
    setCanGrade: React.Dispatch<React.SetStateAction<boolean>>;
    loadAssignment: Function;
}

const AssessmentSubmission: React.FC<AssessmentSubmissionInterface> = ({
    open,
    onGraded,
    onCancel,
    isLoading,
    activityFormData,
    canGrade,
    setCanGrade,
    loadAssignment
}) => {
    const [submittedFiles, setSubmittedFiles] = useState<any>();
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [assessmentSettings, setAssessmentSettings] = useState<any>();
    const [submission, setSubmission] = useState<any>();
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);

    const intl = useIntl();
    const {formatDate} = useDate();
    const { TextArea } = Input;
    const [form] = Form.useForm();
    const [handleError] = useHandleError();

    useEffect(() => {
        if(activityFormData) {
            let settings = activityFormData.material.settings ? JSON.parse(activityFormData.material.settings) : [];
            let submission = activityFormData.submission;

            form.setFieldsValue(submission)

            setAssessmentSettings(settings)
            setSubmission(submission)
        }
        
    }, [activityFormData])

   /**
     * 
     * @param statusType 
     * @returns 
     */
    const getStatusTag = (statusType: 'VALUE' | 'TAG' = 'TAG') => {
        let status: string = submission?.status
        let color = ''
        let messageId = ''

        switch (status) {
            case 'NOT_STARTED':
            case 'IN_PROGRESS':
                color = 'blue'
                messageId = 'courses.topic_status.pending_grading'
                break;
            case 'FINAL_ASSESSMENT':
                color = 'blue'
                messageId = 'courses.topic_status.final_assessment'
                break;
            case 'PASSED':
                color = 'green'
                messageId = 'courses.topic_status.passed'
                break;
            case 'FAILED':
                color = 'red'
                messageId = 'courses.topic_status.failed'
                break;
            default:
                color = 'gold'
                messageId = 'courses.topic_status.not_started'
                break;
        }

        if (statusType === 'TAG') {
            return (
                <Tag color={color}>
                    <FormattedMessage id={messageId} />
                </Tag>
            )
        } else if (statusType === 'VALUE') {
            return status;
        }
    }

    return (

        <Modal
            destroyOnClose={true}
            width={1000}
            className="grade-modal"
            title={intl.formatMessage({id: 'assessment.practical_assessment'})}
            open={open}
            onOk={() => {

            }}
            onCancel={onCancel}
            okText={intl.formatMessage({id: 'grading.grade'})}
            okButtonProps={{style: {display: 'none'}}}
            cancelText={intl.formatMessage({id: 'general.close'})}
        >
            <Spinner spinning={isLoading || isSubmitLoading}>
                {submission && <div className='assessment-submission'>
                    <h1>{assessmentSettings.title ? <>{assessmentSettings.title}</> : <FormattedMessage id='assessment.practical_assessment' />}</h1>
                    <div className='assessment-header flex justify-between align-center'>
                        {activityFormData.studentData && <div className='assessment-student flex align-center'>
                            <div className='user-thumb'><i className="fal fa-user-circle with-accent" /></div>
                            <div className='user-info'>
                                <h4>{activityFormData.studentData?.name} {activityFormData.studentData?.surname}</h4>
                                <h5>{activityFormData.studentData?.position}, {activityFormData.studentData?.organizationName}</h5>
                            </div>
                        </div>}
                        <div>{getStatusTag()}</div>
                    </div>
                    <Tabs defaultActiveKey='overview'>
                        <Tabs.TabPane key='overview' tab={intl.formatMessage({id: 'assessment.assessment_overview'})}>
                            <AssessmentOverview 
                                activityFormData={activityFormData}
                                assessmentSettings={assessmentSettings}
                                submission={submission}
                                loadAssignment={loadAssignment}
                                setIsSubmitLoading={setIsSubmitLoading}
                                isSubmitLoading={isSubmitLoading}
                                getStatusTag={getStatusTag}
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane key='description' tab={intl.formatMessage({id: 'assessment.activity_description'})}>
                            <AssessmentDescription
                                activityFormData={activityFormData}
                                assessmentSettings={assessmentSettings}
                                submission={submission}
                                loadAssignment={loadAssignment}
                                setIsSubmitLoading={setIsSubmitLoading}
                                isSubmitLoading={isSubmitLoading}
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane key='instructors' tab={intl.formatMessage({id: 'assessment.instructors'})}>
                            <AssessmentInstructors
                                activityFormData={activityFormData}
                                assessmentSettings={assessmentSettings}
                                submission={submission}
                                loadAssignment={loadAssignment}
                                setIsSubmitLoading={setIsSubmitLoading}
                                isSubmitLoading={isSubmitLoading}
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane key='final_assessment' tab={intl.formatMessage({id: 'assessment.final_assessment'})}>
                            <AssessmentFinalResult
                                activityFormData={activityFormData}
                                assessmentSettings={assessmentSettings}
                                submission={submission}
                                loadAssignment={loadAssignment}
                                setIsSubmitLoading={setIsSubmitLoading}
                                isSubmitLoading={isSubmitLoading}
                                onGraded={onGraded}
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane key='files' tab={intl.formatMessage({id: 'assessment.assessment_files'})}>
                            <AssessmentFiles
                                activityFormData={activityFormData}
                                assessmentSettings={assessmentSettings}
                                submission={submission}
                                loadAssignment={loadAssignment}
                                setIsSubmitLoading={setIsSubmitLoading}
                                isSubmitLoading={isSubmitLoading}
                                getStatusTag={getStatusTag}
                            />
                        </Tabs.TabPane>
                    </Tabs>
                </div>}
            </Spinner>
        </Modal>
    )
};

export default AssessmentSubmission;
