import React from 'react';
import UserListTest from "pages/Users/UsersList_test";

interface CustomerUsersInterface {
}

const CustomerUsers: React.FC<CustomerUsersInterface> = ({}) => {

    return (
        <UserListTest hideHeader={true} />
    )
};

export default CustomerUsers;
