import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { Menu, Input, Button, Dropdown } from 'antd';
import { PlusOutlined, DownOutlined } from '@ant-design/icons';
import InstructionButton from "components/VideoInstructions/InstructionButton";
import FlexRow from "components/FlexRow";
import apiClient from "utils/apiClient";
import debounce from "lodash/debounce";
import ImportModal from "../../ImportModal";
import UsersExportModal from "../UsersExportModal";
import { UsersListToolbarProps } from '../types';
import { FilterModal } from "components/Modals";
import { connect } from 'react-redux';
import generalHelpers from "utils/generalHelpers";

const mapStateToProps = (state: any) => {
    return {
        session: state.session
    }
};

const UsersListToolbar: React.FC<UsersListToolbarProps> = ({
    session,
    setSearch,
    setFilter,
    filter,
    setReload,
    canAddLearningManagers,
    activeOrganizationId,
    activeOrganizationType,
    setActiveOrganizationType,
    showFilter, userRole,
    setActiveOrganizationId,
    selectedRowsLength,
    selectedRowKeys,
    selectAllUsers,
    setRowSelection,
    rowSelection,
}) => {

    // Modals
    const [showImportModal, setShowImportModal] = useState<boolean>(false);
    const [showExportModal, setShowExportModal] = useState<boolean>(false);
    const [showUsersFilterModal, setShowUsersFilterModal] = useState(false);

    // Platform Access
    const [visiblePlatformAccessInfo, setVisiblePlatformAccessInfo] = useState<boolean>(false);
    const [validPlatformAccess, setValidPlatformAccess] = useState<boolean>(false);
    const [platformAccessInfo, setPlatformAccessInfo] = useState('');

    const { Search } = Input;
    const intl = useIntl();

    useEffect(() => {
        if (userRole !== 'SUPERVISION' && activeOrganizationType === 'CUSTOMER') {
            setPlatformAccessInfoMessage(activeOrganizationId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeOrganizationId]);

    useEffect(() => {
        if (showFilter) {
            setShowUsersFilterModal(true);
        }
    }, [showFilter]);

    const setPlatformAccessInfoMessage = async (selectedCustomer: any) => {
        if (selectedCustomer) {
            let platformAccessData = await apiClient.request(`/api/v1/customers/${selectedCustomer}/platform-access-data`, {}, 'GET');

            let available = platformAccessData.platformAccess.max_students - platformAccessData.platformAccess.current_students_count;

            if (available < 0) available = 0;

            if (available === 0) {
                setValidPlatformAccess(false)
            } else {
                setValidPlatformAccess(true)
            }

            setPlatformAccessInfo(available + '/' + platformAccessData.platformAccess.max_students);
            setVisiblePlatformAccessInfo(true);
        } else {
            setVisiblePlatformAccessInfo(false);
        }
    };

    const loadOptionsTimeOut = debounce(function (text: string) {
        const searchText = text.replace(/\s+/g, '');
        if (text.length === 0 || searchText.length >= 3) {

            setRowSelection({
                filter: rowSelection.filter,
                selectedAllUsers: rowSelection.selectedAllUsers,
                selectedUsers: rowSelection.selectedUsers,
                deSelectedUsers: rowSelection.deSelectedUsers,
                searchText: searchText
            });
            setSearch(searchText);
            setReload(true);
        }
    }, 800);

    const menu = (
        <Menu>

            {activeOrganizationId && activeOrganizationType === 'ORGANIZATION' ?
                <Menu.Item>
                    <NavLink
                        to={!activeOrganizationId ? `/admins/add` : `/organizations/${activeOrganizationId}/admins/add`}>
                        <FormattedMessage id='general.admin' />
                    </NavLink>
                </Menu.Item>
                : null
            }
            {
                activeOrganizationId && activeOrganizationType === 'CUSTOMER' ?
                    <Menu.Item>
                        <NavLink
                            to={`/customer/${activeOrganizationId}/user/add/learning-managers`}>
                            <FormattedMessage id='general.learning_manager' />
                        </NavLink>
                    </Menu.Item>
                    : null
            }
            {
                activeOrganizationId && activeOrganizationType === 'CUSTOMER' ?
                    <Menu.Item>

                        <NavLink to={`/customer/${activeOrganizationId}/user/add/students`}>
                            <FormattedMessage id='general.student' />
                        </NavLink>
                    </Menu.Item>
                    : null
            }
        </Menu>
    );

    return (
        <div>
            <FlexRow
                left={
                    <>
                        {userRole !== 'SUPERVISION' &&
                            <>
                                {userRole === 'SUPER_ADMIN' || userRole === 'DISTRIBUTOR_ADMIN' || userRole === 'RESELLER_ADMIN' || canAddLearningManagers
                                    ? (
                                        <Dropdown overlay={menu} placement="bottomLeft">
                                            <Button type="primary">
                                                <PlusOutlined />
                                                <span><FormattedMessage id="users.add_user" /></span>
                                                <DownOutlined />
                                            </Button>
                                        </Dropdown>
                                    ) : (
                                        <NavLink to={`/customer/${activeOrganizationId}/user/add/students`}>
                                            <Button type="primary" icon={<PlusOutlined />}>
                                                <FormattedMessage id="users.add_student" />
                                            </Button>
                                        </NavLink>
                                    )
                                }
                            </>
                        }
                        <Button type="primary" onClick={() => setShowUsersFilterModal(true)}>
                            <FormattedMessage id="general.filter" />
                        </Button>
                        {userRole !== 'SUPERVISION' &&
                            <>
                                <Button icon={<i className="fal fa-file-import" />} onClick={() => setShowImportModal(true)}>
                                    <span><FormattedMessage id={intl.formatMessage({ id: 'users.import.import' })} /></span>
                                </Button>
                                <Button icon={<i className="fal fa-file-export" />} onClick={() => setShowExportModal(true)}>
                                    <span><FormattedMessage id={intl.formatMessage({ id: 'users.export.export' })} /></span>
                                </Button>
                                {visiblePlatformAccessInfo && activeOrganizationType === 'CUSTOMER' && (
                                    <label>
                                        <FormattedMessage
                                            id={intl.formatMessage({ id: 'users.available_student_access_licences' })} />:{' '}
                                        <span style={{ color: validPlatformAccess ? '#52c419' : 'red' }}>
                                            {platformAccessInfo}
                                        </span>
                                    </label>
                                )}
                            </>
                        }
                    </>
                }
                right={
                    <>
                        <Search
                            className="table-search-field"
                            allowClear
                            placeholder={intl.formatMessage({ id: "general.search" })}
                            onChange={value => loadOptionsTimeOut(value.target.value)}
                        />
                        <InstructionButton position='USER_MANAGEMENT' />
                    </>
                }
            />
            <div className={'table-filter-modal'}>
                <FilterModal
                    visible={showUsersFilterModal}
                    onCancel={setShowUsersFilterModal}
                    modules={{
                        filterBy: { visible: generalHelpers.isAdmin(session.active_user_type) },
                        customerSelect: { visible: generalHelpers.isAdmin(session.active_user_type), set: setActiveOrganizationId, setType: setActiveOrganizationType },
                        organizationSelect: { visible: generalHelpers.isAdmin(session.active_user_type), set: setActiveOrganizationId, setType: setActiveOrganizationType },
                        organizationalUnitId: { mode: 'multiple', visible: true },
                        accountStatus: { visible: true },
                        userTypes: { visible: true },
                        newUsers: { visible: true },
                        customerFilter: { mode: 'multiple', visible: true },
                    }}
                    setFilter={setFilter}
                    load={setReload}
                    filter={filter}
                />
            </div>
            {userRole !== 'SUPERVISION' &&
                <>
                    <ImportModal
                        visible={showImportModal}
                        onCancel={() => setShowImportModal(false)}
                        afterSubmit={() => setShowImportModal(false)}
                        customerId={activeOrganizationType === "CUSTOMER" ? activeOrganizationId : null}
                        setActiveOrganizationId={setActiveOrganizationId}
                        userRole={userRole}
                    />
                    <UsersExportModal
                        visible={showExportModal}
                        onCancel={() => setShowExportModal(false)}
                        customerId={activeOrganizationId}
                        selectAllUsers={selectAllUsers}
                        selectedRowKeys={selectedRowKeys}
                    />
                </>
            }
        </div>
    );
};

export default connect(mapStateToProps)(UsersListToolbar);
