import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import DefaultLayout from "components/DefaultLayout";
import LectureSessionsList from "components/Lectures/LectureSessionsList";
import LectureSettings from "components/Lectures/LectureSettings";
import { Tabs } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import { useLecture } from "hooks/Lectures/useLecture";
import Spinner from 'components/Spinner';
import { LectureProps } from 'components/Lectures/types';


const Lecture: React.FC = () => {
    const intl = useIntl();
    const params = useParams();
    const lectureId = params.id;
    const [lecture, setLecture] = useState<LectureProps>();
    const [reload, setReload] = useState(false);
    const navigate = useNavigate();
    const { getLectureById, lectureLoading } = useLecture()

    useEffect(() => {
        if (lectureId) {
            loadLecture()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lectureId, reload])

    const loadLecture = async () => {
        const lecture: LectureProps | null = lectureId ? await getLectureById(+lectureId) : null;

        if (!lecture) {
            navigate('/lectures');
        } else {
            setLecture(lecture);
        }
    }

    const reloadLecture = () => {
        setReload(!reload);
    }

    return (
        <>
            <DefaultLayout.PageLayout style={{ marginBottom: 64 }}>
                <DefaultLayout.PageHeader
                    title={lecture?.title ?? intl.formatMessage({ id: 'general.lectures.catalogue' })}
                    breadcrumb={[{
                        name: intl.formatMessage({ id: 'general.lectures.catalogue' }),
                        path: '/lectures'
                    }]}
                />
                <DefaultLayout.PageContent >
                    <Spinner spinning={lectureLoading} >
                        {lecture && <Tabs destroyInactiveTabPane
                            //defaultActiveKey='sessions'
                            items={[
                                {
                                    label: intl.formatMessage({ id: 'general.lectures.upcoming_sessions' }),
                                    key: 'sessions',
                                    children: <LectureSessionsList lecture={lecture} reloadLecture={reloadLecture}/>,
                                },
                                {
                                    label: intl.formatMessage({ id: 'general.lectures.default_settings' }),
                                    key: 'settings',
                                    children: <LectureSettings lecture={lecture} reloadLecture={reloadLecture} />,
                                },
                            ]}
                        />}
                    </Spinner>
                </DefaultLayout.PageContent>
            </DefaultLayout.PageLayout>
        </>
    );
};

export default Lecture;