import React, { useEffect, useRef, useState } from 'react';
import { Button, Select, Space } from 'antd';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { DefaultOptionType } from 'antd/lib/select';
import {useRules} from "hooks/Rules/useRules";
import {useCustomer} from "../../../hooks/Customers/useCustomer";

interface AddCustomersInterface {
    value?: number[],
    onChange?(values: number[]): void,
}

const AddCustomers: React.FC<AddCustomersInterface> = ({ value, onChange }) => {
    const { getCustomers, loading } = useCustomer();

    const [customers, setCustomers] = useState<number[]>(value || []);
    const [options, setOptions] = useState<any>([]);
    const [selected, setSelected] = useState<number>();


    const didMountRef = useRef(false);

    useEffect(() => {
        if (!didMountRef.current) {
            didMountRef.current = true;
        } else {
            onChange && onChange(customers);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customers]);

    useEffect(() => {
        if (!!value) {
            setCustomers(value);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    useEffect(() => {
        const loadCustomers = async () => setOptions(await getCustomers());

        loadCustomers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleAdd = () => {
        if (selected) {
            const customer = options.find((option: any) => option.id === +selected);

            if (customer) {
                setCustomers(Array.from(new Set([...customers, +selected])));
                setSelected(undefined);
            }
        }
    }

    const filteredOptions = options.length ? options.map((option: any) => {
        if (!customers.map(customer => customer).includes(option.id)) {
            return {
                value: option.id,
                label: option.name,
            }
        }
    }).filter((item: any) => item) as DefaultOptionType[] : [];

    const removeCustomer = (value: number) => {
        setCustomers(customers.filter((customer) => customer !== value));
    }

    return (<>
            <Space className="lectors-select-space-container">
                <Select className='default-select'
                        value={selected}
                        showSearch
                        optionFilterProp="children"
                        onChange={setSelected}
                        filterOption={(input, option) =>
                            (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={filteredOptions ?? []}
                        loading={loading}
                />
                <Button type="primary" onClick={handleAdd}>
                    <FormattedMessage id='general.add' />
                </Button>
            </Space>
            <Space size={[8, 8]} wrap>
                {customers.map((customer, index) => {
                    const current = options.find((option: any) => option.id === customer);
                    if (current) {
                        return (
                            <div>
                                <NavLink to={`#`}>{current.name}</NavLink>
                                <Button style={{
                                    color: "#8C8C8C",
                                    verticalAlign: 0
                                }}
                                        onClick={() => removeCustomer(customer)}
                                        size="small" type="link" key={index} icon={<i className="fa-solid fa-xmark" />} />
                            </div>

                        )
                    }
                })}
            </Space>
        </>
    )
};

export default AddCustomers;
