import React from 'react';
import './styles.scss';
import {FormattedMessage} from "react-intl";
import {NavLink, useNavigate} from 'react-router-dom';
import {useParams} from 'react-router-dom';
import {connect} from "react-redux";
import {config} from "../../config/config";
import PhishingTab from "../../pages/Customers/CustomerUsersEdit/PhishingTab";

const mapStateToProps = (state: any) => ({
    session: state.session,
});

const StudentNavBar: React.FC<any> = ({session}) => {
    const params = useParams();
    const currPage = params['*']
    const navigate = useNavigate();

    const navBarNavigation = (to: string) => {
        navigate(to)
    }

    return (
        <div className="studentDashboard">
            <div className="activity-list-wrapper">
                {
                    session.active_user_type === 'STUDENT' ?
                        <div onClick={() => navBarNavigation("/")}
                             className={'pointer ' + (!currPage ? 'active-section' : '')}>
                            <i className="fa-solid fa-book"></i>
                            <FormattedMessage id={'student.my_courses'}/>
                        </div>
                        : null
                }
                <div onClick={() => navBarNavigation("/my-lectures")}
                     className={'pointer ' + (currPage?.startsWith('my-lectures') ? 'active-section' : '')}>
                    <i className="fa-solid fa-calendar-check"></i>
                    <FormattedMessage id={'student.my_lectures'}/>
                </div>
                {
                    session.active_user_type === 'STUDENT' ?
                        <div onClick={() => navBarNavigation("/my-certificates")}
                             className={'pointer ' + (currPage?.startsWith('my-certificates') ? 'active-section' : '')}>
                            <i className="fa-solid fa-award"></i>
                            <FormattedMessage id={'student.my_certificates'}/>
                        </div>
                        : null
                }
                {
                    session.active_user_type === 'SUPERVISION' ?
                        <div onClick={() => navBarNavigation("/my-department")}
                             className={'pointer ' + (currPage?.startsWith('my-department') ? 'active-section' : '')}>
                            <i className="fa-solid fa-users"></i>
                            <FormattedMessage id={'student.my_department'}/>
                        </div>
                        : null
                }
                {/*{*/}
                {/*    session.active_user_type === 'STUDENT' ?*/}
                {/*        <div onClick={() => navBarNavigation("/academy")}*/}
                {/*             className={'pointer ' + (currPage?.startsWith('academy') ? 'active-section' : '')}>*/}
                {/*            <i className="fa-solid fa-award"></i>*/}
                {/*            <FormattedMessage id={'student.my_academy'}/>*/}
                {/*        </div>*/}
                {/*        : null*/}
                {/*}*/}
                {config.api.environment === 'development' ?
                    <div onClick={() => navBarNavigation("/my-phishing")}
                         className={'pointer ' + (currPage?.startsWith('my-phishing') ? 'active-section' : '')}>
                        <i className="fa-solid fa-users"></i>
                        <FormattedMessage id={'phishing.phishing'}/>
                    </div>
                    : null
                }
            </div>
        </div>
    )
};

export default connect(mapStateToProps)(StudentNavBar);