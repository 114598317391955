import * as ActionTypes from 'store/actionTypes';

export const changeLearningTestAttemptData = ({
  attempt, 
  questions, 
} : any) => ({
  type: ActionTypes.SAVE_LEARNING_TEST_ATTEMPT_DATA,

  payload: {
    attempt: attempt,
    questions: questions,
  }
});

export const clearLearningTestAttemptData = () => ({
  type: ActionTypes.CLEAR_LEARNING_TEST_ATTEMPT_DATA,
});
