import React, {useState} from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import {FormattedMessage, useIntl} from 'react-intl';
import {Popover, Button} from 'antd';
import DefaultLayout from 'components/DefaultLayout';
import {Table} from 'ui'

const PhishingTemplatesList: React.FC = ({}) => {
    const [reload, setReload] = useState(false);
    const [visibleTooltip, setVisibleTooltip] = useState<number | null>(null);
    const intl = useIntl();
    const navigate = useNavigate();

    const columns = [
        {
            title: intl.formatMessage({id: 'phishing.simulation_id'}),
            dataIndex: 'simulation_id',
            render: (value: string, record: any) => record.isTemplate ? record.simulationId : null
        },
        {
            title: intl.formatMessage({id: 'general.language'}),
            dataIndex: 'language',
            render: (value: string, record: any) => !record.isTemplate ? record.language : null
        },
        {
            title: intl.formatMessage({id: 'phishing.table_template_id'}),
            dataIndex: 'template_id',
            render: (value: string, record: any) => !record.isTemplate ? `${record.simulation_id}.${record.language}` : null
        },
        {
            title: intl.formatMessage({id: 'phishing.simulation_template_name'}),
            key: 'title',
            render: (value: string, record: any) => {
                return (
                    <div className='user-name-style' onClick={() => {
                        navigate(!record.isTemplate ?
                            `/phishing/templates/${record.id}/simulations` :
                            `/phishing/templates/${record.id}/settings`)
                    }}>
                        {record.title}
                    </div>
                )
            }
        },
        {
            title: intl.formatMessage({id: 'phishing.phishing_email'}),
            key: 'template_title',
            render: (value: string, record: any) => !record.isTemplate ? record.template_title : null,
        },
        {
            title: intl.formatMessage({id: 'phishing.phishing_landing_page'}),
            key: 'landing_page_title',
            render: (value: string, record: any) => !record.isTemplate ? record.landing_page_title : null,
        },
        {
            key: 'actions',
            render: (text: string, record: any, index: number) => {
                const content = (
                    <>
                        <NavLink to={`/phishing/templates/${record.id}/settings`}>
                            <div className="popover-item">
                                <FormattedMessage id="general.edit"/>
                            </div>
                        </NavLink>
                        <NavLink to={`/phishing/templates/${record.id}/simulations`}>
                            <div className="popover-item">
                                <FormattedMessage id="courses.add_new_language"/>
                            </div>
                        </NavLink>
                    </>
                );

                if (record.isTemplate) {
                    return (
                        <Popover
                            open={index === visibleTooltip}
                            content={content}
                            trigger="click"
                            placement="bottomRight"
                            arrowPointAtCenter
                            onOpenChange={(visible) => handleVisibleChange(visible, index)}
                        >
                            <div style={{width: '100%', cursor: 'pointer', textAlign: 'center'}}>
                                <i className="fal fa-ellipsis-v" style={{fontSize: '16px'}}/>
                            </div>
                        </Popover>
                    );
                }
            }
        }
    ];

    const handleVisibleChange = (visible: boolean, rowIndex: number) => {
        if (visible) {
            setVisibleTooltip(rowIndex);
        } else {
            setVisibleTooltip(null);
        }
    }

    return (
        <DefaultLayout.PageLayout>
            <DefaultLayout.PageHeader title={intl.formatMessage({id: 'phishing.manage_templates'})}/>
            <DefaultLayout.PageContent>
                <NavLink to='/phishing/templates/add'>
                    <Button type="primary">
                        <FormattedMessage id="phishing.add_new_simulation_template"/>
                    </Button>
                </NavLink>
                <Table
                    columns={columns}
                    url={'/api/v1/courses/phishing/templates'}
                    setReload={setReload}
                    reload={reload}
                    rowSelection={false}
                    rowKey={'id'}
                    scroll={{x: 800}}
                />
            </DefaultLayout.PageContent>
        </DefaultLayout.PageLayout>
    );
}

export default PhishingTemplatesList;
