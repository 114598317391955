import React from 'react';
import {connect} from 'react-redux';
import generalHelpers from "utils/generalHelpers";

interface ComponentAccessCheck {
    children?: React.ReactNode;
    permission: string;
    session: any;
}

const mapStateToProps = (state: any) => {
    return {
        session: state.session,
    };
};

const ComponentAccessCheck: React.FC<ComponentAccessCheck> = ({
                                                                  children,
                                                                  permission,
                                                                  session,
                                                              }) => {
    const renderComponent = () => {
        if (generalHelpers.hasPermissions(session, permission)) {
            return children
        }
        return;
    }

    return (
        <>
            {renderComponent()}
        </>
    )
};

export default connect(mapStateToProps)(ComponentAccessCheck);
