import React, { useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Form, Input, Radio, InputNumber, FormInstance, message, Tooltip } from 'antd';
import { Switch } from 'components/Form';
import coursesApiClient from 'utils/coursesApiClient';
import { FileTable, FileUpload, RichTextEditor } from 'ui';
import Spinner from 'components/Spinner';
import TemplateEditor from 'components/TemplateEditor';
import {Tabs} from "antd";
import ActivityTab from './ActivityTab';
import GradingTab from './GradingTab';

type AssessmentSettings = {
    title: string;
    description: string;
    evaluationType: 'EXAMINATION' | 'RANGE';
    initiationType: 'AUTOMATIC' | 'MANUAL';
    passRate: string;
    documents: any;
    manager: string;
    initiator: string;
    instructors: any;
}

type AssessmentType = {
    type: 'ASSESSMENT',
    language: string,
    settings: AssessmentSettings
}

interface AssessmentFormInterface {
    language: string;
    form: FormInstance;
    activityId: number;
    viewMaterial?: any;
    onCancel: any;
    reload: Function;
}

const AssessmentForm: React.FC<AssessmentFormInterface> = ({
    language,
    form,
    activityId,
    viewMaterial,
    onCancel,
    reload
}) => {

    const intl = useIntl();
    const [isLoading, setIsLoading] = useState(false);
    const [settings, setSettings] = useState<AssessmentSettings>({
        title: '',
        description: '',
        evaluationType: 'EXAMINATION',
        initiationType: 'MANUAL',
        passRate: '',
        documents: [],
        manager: '',
        initiator: '',
        instructors: []
    });

    useEffect(() => {
        if (viewMaterial) {

            const materialSettings = JSON.parse(viewMaterial.settings);
            
            if (viewMaterial.settings) {
                let newSettings = {
                    ...settings,
                    title: materialSettings.title,
                    description: materialSettings?.description,
                    evaluationType: materialSettings.evaluation_type,
                    initiationType: materialSettings.initiation_type,
                    passRate: materialSettings.pass_rate,
                    manager: materialSettings.manager,
                    initiator: materialSettings.initiator,
                };
                form.setFieldsValue({
                    ...newSettings
                });

                newSettings.documents = materialSettings.documents ? materialSettings.documents : settings.documents;
                newSettings.instructors = materialSettings.instructors ? materialSettings.instructors : settings.instructors;
                setSettings(newSettings)
            } else {
                form.setFieldsValue({
                    ...settings
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewMaterial]);

    const submitForm = async (values: any) => {
        setIsLoading(true);
        try {
            const documentList = values?.documents.map((item: any) => {
                return item.link
            }) 
            
            const instructorsList = values?.instructors.map((item: any) => {
                return item.id
            }) 
            const parsedValues: AssessmentType = {
                type: 'ASSESSMENT',
                language: language,
                settings: {
                    ...settings,
                    ...values,
                    documents: documentList,
                    instructors: instructorsList
                }
            }

            let response;

            if (viewMaterial) {
                response = await coursesApiClient.request(`/api/v1/courses/material/update/${viewMaterial.id}`, parsedValues, 'PUT');
            } else {
                response = await coursesApiClient.request(`/api/v1/courses/material/${activityId}/add-material`, parsedValues, 'POST');
            }

            onCancel();
            reload(activityId);
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Form form={form} onFinish={submitForm} >
            <Spinner spinning={isLoading}>
                <Tabs defaultActiveKey='activity' tabPosition='left'>
                    <Tabs.TabPane key='activity' tab={intl.formatMessage({id: 'courses.activity'})} >
                        <ActivityTab 
                            language={language} 
                            form={form} 
                            activityId={activityId} 
                            viewMaterial={viewMaterial} 
                            onCancel={onCancel} 
                            reload={reload} 
                            settings={settings}
                            setSettings={setSettings}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane forceRender={true} key='grading' tab={intl.formatMessage({id: 'gradings.grading'})}>
                        <GradingTab
                            language={language} 
                            form={form} 
                            activityId={activityId} 
                            viewMaterial={viewMaterial} 
                            onCancel={onCancel} 
                            reload={reload} 
                            settings={settings}
                            setSettings={setSettings}
                        />
                    </Tabs.TabPane>
                </Tabs>
            </Spinner>
        </Form>
    )
}
export default AssessmentForm;
