import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Button, Form, UploadFile, Modal} from 'antd';
import DefaultLayout from 'components/DefaultLayout';
import {Input} from 'components/Form';
import {useParams, useNavigate} from 'react-router-dom';
import {ExclamationCircleOutlined} from "@ant-design/icons";
import useHandleError from "utils/useHandleError";
import {useRules} from "hooks/Rules/useRules";
import TemplateEditor from "components/TemplateEditor";
import FileUploadList from "ui/FileUploadList";
import {RcFile} from "antd/lib/upload/interface";
import FileDownload from "js-file-download";
import AddCustomers from "./AddCustomers";
import {connect} from "react-redux";
import {RuleCustomerProps} from "../types";

const formItemLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 12},
};

const mapStateToProps = (state: any) => {
    return {
        session: state.session
    }
};
const RulesForm: React.FC<any> = ({rule, session}) => {
    const {saveRule, getRuleFile, storeRuleFile, deleteRuleFile, downloadRuleFile, loading} = useRules();
    const [description, setDescription] = useState<string>('');
    const [editor, setEditor] = useState<any>();
    const [fileList, setFileList] = useState<UploadFile[]>();

    const [handleError] = useHandleError();
    const navigate = useNavigate();
    const params = useParams();
    const [form] = Form.useForm();
    const ruleId = params.id;
    const intl = useIntl();

    useEffect(() => {
        if (rule) {
            form.setFieldsValue({
                title: rule.title,
                confirmationText: rule.confirmationText,
                organizations: rule.organizations?.map((customer: RuleCustomerProps) => customer.id),
            });
            setDescription(rule.description)
        }
        fetchFiles();
    }, [rule]);

    const fetchFiles = async () => {
        if (rule && rule.id) {
            setFileList(await getRuleFile(rule.id));
        }
    }

    useEffect(() => {
        form.setFieldsValue({
            files: fileList,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileList])

    const submitForm = async (values: any) => {
        values.description = description
        const response = await saveRule(values, ruleId ? ruleId : null);

        if (!ruleId) {
            navigate(`/rules/${response.id}`)
        }
    };

    const handleAddFile = async (file: RcFile, callBack: (id?: number) => void) => {
        if (rule && rule.id) {
            callBack(await storeRuleFile(rule.id, file));
        }
    }

    const handleRemoveFile = async (id: number): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            Modal.confirm({
                title: intl.formatMessage({id: 'general.attention'}),
                icon: <ExclamationCircleOutlined/>,
                content: intl.formatMessage({id: 'general.delete_file'}),
                okText: intl.formatMessage({id: 'general.delete'}),
                okType: 'danger',
                cancelText: intl.formatMessage({id: 'general.cancel'}),
                onOk: () => {
                    resolve(true)
                    if (rule && rule.id) {
                        return deleteRuleFile(rule.id, id);
                    }
                },
                onCancel: () => {
                    reject(true)
                }
            })
        })
        return false;
    }

    const handleDownload = async (file: any) => {
        if (rule && rule.id) {
            try {
                if (file?.originFileObj) {
                    return FileDownload(file.originFileObj, file.name);
                }

                const download = await downloadRuleFile(rule.id, +file.uid)

                if (download) {
                    FileDownload(download, file.name);
                }

            } catch (error) {
                handleError(error)
            }
        }
    }

    return (
        <>
            <Form form={form} onFinish={submitForm} {...formItemLayout}>
                {!!session && <Form.Item
                  name="organizations"
                  label={intl.formatMessage({id: 'general.customers'})}
                >
                  <AddCustomers/>
                </Form.Item>}
                <Input
                    customLayout={{}}
                    name="title"
                    label={intl.formatMessage({id: 'general.title'})}
                    validation={{required: true}}
                />
                <TemplateEditor
                    label={intl.formatMessage({id: 'general.description'})}
                    html={description}
                    setHtml={(value: any) => setDescription(value)}
                    setEditor={setEditor}
                />
                <Input
                    customLayout={{}}
                    name="confirmationText"
                    label={intl.formatMessage({id: 'rule.confirmation_text'})}
                    validation={{required: true}}
                />
                {ruleId &&
                  <>
                    <div className='campaign-header-text'>
                      <h1 className='text-18'>{intl.formatMessage({id: 'general.program_files'})}</h1>
                    </div>
                    <Form.Item
                      label={intl.formatMessage({id: 'general.upload_files'})}
                      name="files"
                    >
                      <FileUploadList
                        multiple={true}
                        onAdd={handleAddFile}
                        onDelete={handleRemoveFile}
                        onDownload={handleDownload}
                        showUploadList={{
                            showDownloadIcon: true,
                            downloadIcon: intl.formatMessage({id: 'general.download'}),
                            showRemoveIcon: true,
                            removeIcon: intl.formatMessage({id: 'general.delete'})
                        }}
                      />
                    </Form.Item>
                  </>
                }
            </Form>
            <DefaultLayout.PageFooter
                right={
                    <Button loading={loading} type='primary' onClick={form.submit}>
                        <FormattedMessage id='general.save'/>
                    </Button>
                }
            />
        </>
    )
}

export default connect(mapStateToProps)(RulesForm);
