import React, { useEffect, useState } from 'react';
import { Button, Table } from 'ui';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import { config } from 'config/config';
import { Checkbox, Divider, message, Popover } from 'antd';
import generalHelpers from 'utils/generalHelpers';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import coursesApiClient from 'utils/coursesApiClient';
import FileDownload from 'js-file-download';

const defaultFields: string[] = [
    'date',
    'start',
    'end',
    'sessionLength',
    'lectureTitle',
    'title',
    'lectors',
    'lectorsDepartments',
    'attendees',
];

const CustomerSessionReport: React.FC<any> = ({ filter, load, setLoad, form }) => {
    const [reload, setReload] = useState(false);
    const [exportLoading, setExportLoading] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState<CheckboxValueType[]>(defaultFields);
    const [openPopover, setOpenPopover] = useState(false);
    const [displayColumns, setDisplayColumns] = useState<any>([]);

    const cacheKey = 'sessionColumns_CUSTOMER_SESSION';

    const intl = useIntl();

    useEffect(() => {
        if (filter !== null && load) {
            filterSession();
            setLoad(false);
        }

        const hasColumns = localStorage.getItem(cacheKey);

        if (hasColumns) {
            setDisplayColumns(hasColumns.split(','));
            setSelectedColumns(hasColumns.split(','));
        }
    }, [load]);

    const filterSession = () => {
        setReload(true);
    };

    const columns = [
        {
            title: intl.formatMessage({ id: 'general.date' }),
            dataIndex: 'date',
            key: 'date',
            sorter: true,
            render: (date: string) => moment(date).format(config.defaultDateFormat),
        },
        {
            title: intl.formatMessage({ id: 'general.start' }),
            dataIndex: 'start',
            key: 'start',
            sorter: true,
            render: (date: string) => date.substring(0, date.length - 3),
        },
        {
            title: intl.formatMessage({ id: 'general.end' }),
            dataIndex: 'end',
            key: 'end',
            sorter: true,
            render: (date: string) => date.substring(0, date.length - 3),
        },
        {
            title: intl.formatMessage({ id: 'general.session_length' }),
            dataIndex: 'sessionLength',
            key: 'sessionLength',
            sorter: true,
            render: (minutes: number) => generalHelpers.toHoursAndMinutes(minutes),
        },
        {
            title: intl.formatMessage({ id: 'general.lecture_title' }),
            dataIndex: 'lectureTitle',
            key: 'lectureTitle',
            sorter: true,
        },
        {
            title: intl.formatMessage({ id: 'general.session_title' }),
            dataIndex: 'title',
            key: 'title',
            sorter: true,
        },
        {
            title: intl.formatMessage({ id: 'general.lectors' }),
            dataIndex: 'lectors',
            key: 'lectors',
        },
        {
            title: intl.formatMessage({ id: 'general.departments' }),
            dataIndex: 'lectorsDepartments',
            key: 'lectorsDepartments',
        },
        {
            title: intl.formatMessage({ id: 'general.attendees' }),
            dataIndex: 'attendees',
            key: 'attendees',
            sorter: true,
        },
    ];

    const content = (
        <div>
            <Checkbox.Group
                value={selectedColumns}
                onChange={(checkboxGroupColumns: CheckboxValueType[]) =>
                    setSelectedColumns(checkboxGroupColumns)
                }
            >
                {Object.values(columns).map((el: any) => {
                    const excludeList: any = [];

                    if (excludeList.includes(el.title)) {
                        return;
                    }

                    return (
                        <div key={el.dataIndex} className={'flex p-1 gap-5'}>
                            <Checkbox key={el.dataIndex} value={el.dataIndex} />
                            {el.title}
                        </div>
                    );
                })}
            </Checkbox.Group>
            <Divider plain className={'m-2'} />
            <div className={'flex justify-between p-1'}>
                <Button type={'link'} onClick={() => handleCancel()}>
                    <FormattedMessage id={'general.cancel'} />
                </Button>
                <Button type={'primary'} onClick={() => handleColumnSubmit()}>
                    <FormattedMessage id={'general.select'} />
                </Button>
            </div>
        </div>
    );

    const handleColumnSubmit = () => {
        setDisplayColumns(selectedColumns);
        localStorage.setItem(cacheKey, selectedColumns.toString());
        setOpenPopover(false);
    };

    const handleCancel = () => {
        const hasColumns = localStorage.getItem(cacheKey);

        if (hasColumns) {
            setSelectedColumns(hasColumns.split(','));
        } else {
            setSelectedColumns(defaultFields);
        }
        setOpenPopover(false);
    };

    const exportData = async () => {
        const values = form.getFieldsValue();
        const query = generalHelpers.filterParser(values).replace('&', '?');

        setExportLoading(true);
        try {
            const url = '/api/v1/courses/reports/export/SESSIONS' + query;
            const response = await coursesApiClient.request(url, [], 'GET', true, true);

            if (response.fileName) {
                FileDownload(response, response.fileName);
            } else {
                message.error(intl.formatMessage({ id: 'error.data_load' }));
            }
        } catch (error) {
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        } finally {
            setExportLoading(false);
        }
    };

    return (
        <div className={'reports-element'}>
            <div className={'flex justify-between'}>
                <Button loading={exportLoading} type={'outline'} onClick={() => exportData()}>
                    <FormattedMessage id={'general.export'} />
                </Button>
                <Popover
                    open={openPopover}
                    placement="bottomRight"
                    title={intl.formatMessage({ id: 'general.columns' })}
                    content={content}
                    trigger="click"
                >
                    <Button type={'outline'} onClick={() => setOpenPopover(!openPopover)}>
                        <i className="fa-solid fa-table-columns"></i>
                    </Button>
                </Popover>
            </div>
            <Table
                columns={columns.filter((el: any) => displayColumns.includes(el.dataIndex))}
                url={'/api/v1/courses/reports/customer-session'}
                reload={reload}
                setReload={setReload}
                rowKey={'id'}
                filter={filter}
                scroll={{ x: true }}
                rowSelection={false}
                hasCustomFields={false}
                showTotalResults={true}
            />
        </div>
    );
};

export default CustomerSessionReport;
