import { message } from 'antd';
import stringConverter from 'utils/stringConverter';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

interface ErrorProps {
    [key: string]: string[] | string;
}

message.config({
    maxCount: 5,
});

function useHandleError() {
    const intl = useIntl();
    const { toSnake } = stringConverter();
    const navigate = useNavigate();

    const handleError: any = (
        error: ErrorProps,
        key?: string,
        specialMessage?: string,
        specialAttribute?: string,
        dontShowMessages?: boolean
    ) => {
        if (!dontShowMessages) {
            if (specialMessage) {
                message.error(intl.formatMessage({ id: specialMessage }, { attribute: specialAttribute }), 15);
            } else if (error.hasOwnProperty('message') && typeof error.message === 'string') {
                if (error.message === 'dont_have_permission_to_access') {
                    message.error(intl.formatMessage({ id: error.message }), 15);
                    navigate('/');
                } else if (error.message?.length < 50) {
                    message.error(intl.formatMessage({ id: error.message }), 15);
                } else {
                    message.error(intl.formatMessage({ id: 'error.data_load' }), 15);
                }
            } else if (!!key) {
                Object.entries(error).forEach((entry) => {
                    const convertedString = toSnake(entry[0]);
                    const attribute = intl.formatMessage({ id: key + '.' + convertedString });

                    message.error(intl.formatMessage({ id: entry[1][0] }, { attribute: attribute }), 15);
                });
            } else {
                message.error(intl.formatMessage({ id: 'error.data_load' }), 15);
            }
        }

        document.body.scrollTop = document.documentElement.scrollTop = 0; //pure js scroll to top, supported in all browsers
        console.error(error);
    };

    const prepareFormValidation: any = (error: ErrorProps, key?: string) => {
        let errorList: any = [];

        if (typeof error?.error !== 'string') {
            Object.entries(error).forEach((entry) => {
                const convertedString = toSnake(entry[0]);
                const attribute = intl.formatMessage({ id: key + '.' + convertedString });

                const errorObject = {
                    errors: [intl.formatMessage({ id: entry[1][0] }, { attribute: attribute })],
                    name: entry[0],
                };

                errorList.push(errorObject);
            });
        }

        return errorList;
    };

    return [handleError, prepareFormValidation];
}

export default useHandleError;
