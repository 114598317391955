import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Switch} from 'components/Form';
import coursesApiClient from 'utils/coursesApiClient';
import {connect} from 'react-redux';
import {NavLink, useParams} from 'react-router-dom';
import {Title, Button, Table} from 'ui';
import useHandleError from 'utils/useHandleError';
import {config} from "config/config";
import DefaultLayout from "../../DefaultLayout";

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale
    }
};

interface NotificationsInterface {
    locale?: string;
    program: any;
    launchButton: HTMLElement;
}

const Notifications: React.FC<NotificationsInterface> = ({locale = config.defaultEnvironmentLocale, launchButton, program}) => {
    const [reload, setReload] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [scenarioUpdate, setScenarioUpdate] = useState(false);
    const [dataSource, setDataSource] = useState<any>([]);

    const params = useParams();
    const intl = useIntl();
    const programId = params.id;
    const [handleError] = useHandleError();

    const handleSwitch = async (record: any, type: 'status' | 'cc') => {
        if (!(!!(program?.visualPermissions?.admin) || !!(program?.visualPermissions?.edit))) {
            return
        }
        let key: any = Object.entries(dataSource).filter((el:any) => el[1].scenarioId === record.scenarioId);
        try {
            let data;
            let url = '';

            switch (type) {
                case "cc":
                    data = !record.cc
                    dataSource[key[0][0]].cc = data
                    setDataSource(dataSource);

                    url = '/set-cc'
                    break;
                case "status":
                    data = record.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';
                    dataSource[key[0][0]].status = data
                    setDataSource(dataSource);

                    url = '/set-status'
                    break;
                default:
                    return;
            }

            setScenarioUpdate(true)

            await coursesApiClient.request(`/api/v1/mail-scenarios/${record.scenarioId}${url}`, {[type]: data}, 'POST');

            setScenarioUpdate(false)
        } catch (error) {
            handleError(error)
        }
    }

    const columns = [
        {
            title: intl.formatMessage({id: 'emailing.templates.template_name'}),
            render: (text: string, record: any) => {
                const translations = record.translations;
                let titleObject = translations.filter((el: any) => el.language === locale)[0];

                if (!titleObject) {
                    titleObject = translations[Object.keys(translations)[0]]
                }
                return (
                    <div>
                        {titleObject.title}
                    </div>
                )
            }
        },
        {
            title: intl.formatMessage({id: 'general.status'}),
            render: (_text: string, record: any) => {

                if (!(!!(program?.visualPermissions?.admin) || !!(program?.visualPermissions?.edit))) {
                    return <Switch checked={record.status === 'ACTIVE'}/>
                }

                return <Switch onChange={() => handleSwitch(record, 'status')}
                               defaultChecked={record.status === 'ACTIVE'}/>

            }
        },
        {
            title: intl.formatMessage({id: 'general.cc'}),
            render: (_text: string, record: any) => {
                if (!(!!(program?.visualPermissions?.admin) || !!(program?.visualPermissions?.edit))) {
                    return <Switch checked={!!record.cc}/>
                }

                return <Switch onChange={() => handleSwitch(record, 'cc')} defaultChecked={!!record.cc}/>
            }
        },
    ];



    return (
        <>
            <Title title='program.notifications_settings' className='f-14 bold f-left'/>
            <Button>
                <FormattedMessage id='campaign.add_new_email'/>
            </Button>

            <Table
                columns={columns}
                url={`/api/v1/learning-program/${programId}/scenarios`}
                setReload={setReload}
                reload={reload}
                manualDataUpdate={scenarioUpdate}
                updateData={dataSource}
                saveData={setDataSource}
                rowSelection={false}
                rowKey={'id'}
                isLoading={isLoading}
            />
            <DefaultLayout.PageFooter
                right={
                    <>
                        {launchButton}
                    </>
                }
            />
        </>
    )
};

export default connect(mapStateToProps)(Notifications);
