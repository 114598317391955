import React from 'react';
import {Button as AntDButton, ButtonProps} from 'antd';
import './styles.scss';

interface ButtonInterface {
    className: string;
    type: 'outline' | 'primary';
    disabled: boolean;
}

//ButtonProps & ButtonInterface
const Button: React.FC<any> = ({className, type, disabled, ...props}) => {
    let buttonClassName = '';

    switch (type) {
        case 'primary':
            buttonClassName = 'primary-button ';
            break;
        case 'outline':
            buttonClassName = 'outlined-button ';
            break;
        case 'dashed':
            buttonClassName = 'dashed-button ';
            break;
        case 'link':
            buttonClassName = 'link-button ';
            break;
    }

    return (
        <AntDButton {...props} className={buttonClassName + (className || '')} disabled={disabled}/>
    )
}

export default Button;
