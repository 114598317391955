import React from 'react';
import { useIntl } from 'react-intl';
import { Form } from 'antd';
import { Modal } from 'ui';
import {Select, TextArea} from 'components/Form';
import { SessionProps } from '../../types';
import { useSession } from 'hooks/Lectures/useSession';

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

interface ChangeStatusModalInterface {
    session: SessionProps,
    visible: boolean,
    onCancel: React.Dispatch<React.SetStateAction<boolean>>;
    selectedRowKeys: any[];
}
const ChangeStatusModal: React.FC<ChangeStatusModalInterface> = ({ session, visible, onCancel, selectedRowKeys }) => {
    const { statusChangeSessionAttendance } = useSession();
    const intl = useIntl();
    const [form] = Form.useForm();

    const attendeeStatus = {
        'NOT_STARTED': intl.formatMessage({ id: 'lectures.status_NOT_STARTED' }),
        'NOT_APPLICABLE' : intl.formatMessage({ id: 'lectures.status_NOT_APPLICABLE' })
    };


    const handleOnCancel = () => {
        form.resetFields();
        onCancel(false );
    }

    const handleFormSubmit = async (values: any) => {
        if (session && session.id && session.lecturesId) {
            if (await statusChangeSessionAttendance(session.lecturesId, session.id, selectedRowKeys, values.status)) {
            }
        }
    }

    return (
        <Modal
            open={visible}
            onCancel={() => handleOnCancel()}
            title={intl.formatMessage({ id: 'general.lectures.status_change' })}
            okText={intl.formatMessage({ id: 'general.submit' })}
            cancelText={intl.formatMessage({ id: 'general.cancel' })}
            onOk={form.submit}
        >
            <Form form={form} onFinish={handleFormSubmit} {...formItemLayout}>
                <Select
                    customLayout={formItemLayout}
                    name={'status'}
                    label={intl.formatMessage({ id: 'general.status' })}
                    manualOptions={attendeeStatus}
                />
            </Form>

        </Modal>
    )
};
export default ChangeStatusModal;
