import { Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Modal } from 'ui';
import FileUploadList from 'ui/FileUploadList';
import { SessionProps } from '../../types';
import { useSession } from 'hooks/Lectures/useSession';
import { RcFile } from 'antd/lib/upload/interface';


export interface EditSessionFilesModalProps {
    visible: boolean,
    session: SessionProps,
    onCancel: (changes: boolean) => void,
}

const EditSessionFilesModal: React.FC<EditSessionFilesModalProps> = ({ visible, session, onCancel }) => {
    const { storeSessionFile, deleteSessionFile, sessionLoading } = useSession();
    const [changes, setChanges] = useState<boolean>(false);
    const intl = useIntl();
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldValue('files', session.files);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleAddFile = async (file: RcFile, callBack: (id?: number) => void) => {
        if (session && session.id && session.lecturesId) {
            callBack(await storeSessionFile(session.lecturesId, session.id, file));
            setChanges(true);
        }
    }

    const handleRemoveFile = async (id: number): Promise<boolean> => {
        if (session && session.id && session.lecturesId) {
            setChanges(true);
            return await deleteSessionFile(session.lecturesId, session.id, id);
        }

        return false;
    }

    return (
        <Modal destroyOnClose
            open={visible}
            isLoading={sessionLoading}
            title={intl.formatMessage({ id: 'general.add_files' })}
            footer={null}
            onCancel={() => onCancel(changes)}
            bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}
        >
            <Form form={form}>
                <Form.Item
                    label={intl.formatMessage({ id: 'general.upload_files' })}
                    name="files"
                >
                    <FileUploadList
                        multiple={true}
                        onAdd={handleAddFile}
                        onDelete={handleRemoveFile}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default EditSessionFilesModal;
