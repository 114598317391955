import React, {useEffect, useState} from "react";
import {useRules} from "hooks/Rules/useRules";
import {FormattedMessage, useIntl} from "react-intl";
import {Button, Modal} from "ui";
import {Checkbox, Form, Upload} from "antd";
import Spinner from "components/Spinner";
import jwt from "utils/jwt";
import {connect} from 'react-redux';
import FileDownload from "js-file-download";
import useHandleError from "utils/useHandleError";

interface ConfirmationInterface {
    rule: any;
    session: any
}

const mapStateToProps = (state: any) => {
    return {
        session: state.session
    }
}
const RulesModal: React.FC<ConfirmationInterface> = ({
                                                         rule,
                                                         session
                                                     }) => {
    const {acceptRule, downloadRuleFile} = useRules();
    const [acceptRules, setAcceptRules] = useState(false);

    const [showConfirmation, setShowConfirmation] = useState(false)
    const [handleError] = useHandleError();
    const [loading, setLoading] = useState(false)
    const intl = useIntl();

    useEffect(() => {
        if (rule) {
            setShowConfirmation(true)
        }
    }, [rule])

    const submitForm = async (rule: any) => {
        setLoading(true)
        try {
            if (acceptRules) {
                const response = await acceptRule(rule.id)

                jwt.refreshRole(session.active_user_type_id);
                if (response) {
                    setShowConfirmation(false)
                }
            }
        } catch (error) {

            handleError(error)

        } finally {
            setLoading(false)
        }
    }

    const handleDownload = async (file: any) => {
        if (rule && rule.id) {
            try {
                if (file?.originFileObj) {
                    return FileDownload(file.originFileObj, file.name);
                }

                const download = await downloadRuleFile(rule.id, +file.uid)

                if (download) {
                    FileDownload(download, file.name);
                }

            } catch (error) {
                handleError(error)
            }
        }
    }

    return (
        <Modal
            width={'85%'}
            open={showConfirmation}
            title={rule ? rule?.title : intl.formatMessage({id: 'genera.rules'})}
            destroyOnClose={true}
            footer={
                <>
                    <Button loading={loading} disabled={!acceptRules} type={'primary'} onClick={() => submitForm(rule)}>
                        <FormattedMessage id={'rule.start'}/>
                    </Button>
                </>
            }
        >
            {rule?.description ?
                <>
                    <h1 className={'flex justify-center'}>
                        {rule?.title}
                    </h1>
                    <div dangerouslySetInnerHTML={{__html: rule.description}}></div>
                    <div className={'flex justify-end'}>
                        <Checkbox value={acceptRules} onChange={() => setAcceptRules(!acceptRules)}>
                            {rule.confirmation_text}
                        </Checkbox>
                    </div>
                    <Upload
                        fileList={rule.files}
                        listType="picture"
                        isImageUrl={() => false}
                        onDownload={handleDownload}
                        showUploadList={{
                            showDownloadIcon: true,
                            downloadIcon: intl.formatMessage({id: 'general.download'}),
                            showRemoveIcon: false,
                        }}
                    />
                </>
                :
                <Spinner spinning={true}/>
            }
        </Modal>
    )
};

export default connect(mapStateToProps)(RulesModal);
