import React, {useState, useEffect} from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import {connect} from 'react-redux';
import {FormattedMessage, useIntl} from 'react-intl';
import moment from 'moment/moment.js';
import {message, Tag, Modal, Form, Popover, Radio, Button} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import {changeOrganizationOptions} from 'store/actions/saveOrganizationOptions';
import InstructionButton from 'components/VideoInstructions/InstructionButton';
import DefaultLayout from 'components/DefaultLayout';
import FlexRow from 'components/FlexRow';
import {Select, CustomerSelect, Switch} from 'components/Form';
import apiClient from 'utils/apiClient';
import {config} from 'config/config';
import {Table} from 'ui'

import './styles.scss';
import {OrganizationType} from "../../../components/UsersComponents/types";
import {MailScenariosFilterModal} from "../../../components/Modals";

const mapStateToProps = (state: any) => {
    return {
        session: state.session,
        filterdOrganizationOptions: state.filterdOrganizationOptions,
        locale: state.locale.locale
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    changeOrganizationOptions: (filterdOrganizationOptions: any) => dispatch(changeOrganizationOptions(filterdOrganizationOptions))
});

const SendEmailScenarios: React.FC = ({
                                          session,
                                          filterdOrganizationOptions,
                                          changeOrganizationOptions,
                                          locale
                                      }: any) => {
    const [reload, setReload] = useState(false);

    const [filter, setFilter] = useState('');


    const [selected, setSelected] = useState({});
    const [scenariosLoading, setScenariosLoading] = useState(false);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [filterBy, setFilterBy] = useState('customer');
    const [visibleTooltip, setVisibleTooltip] = useState<number | null>(null);
    const [ownerId, setOwnerId] = useState();
    const [filteredOptions, setFilteredOptions] = useState<any>([]);
    const [mailTemplateUrl, setMailTemplateUrl] = useState<any>();
    const [emailCategories, setEmailCategories] = useState();
    const [showDeactivationConfirmationModal, setShowDeactivationConfirmationModal] = useState(false);
    const [queuedEmails, setQueuedEmails] = useState<any>(null);
    const [mailDeliveryTerm, setMailDeliveryTerm] = useState<any>(null);

    const [showOnlyNotFinishedScenarios, setShowOnlyNotFinishedScenarios] = useState(true);
    const [templateOwnerType, setTemplateOwnerType] = useState('customer');
    // const [filter, setFilter] = useState();

    const [templateOwnerLabel, setTemplateOwnerLabel] = useState(null);
    const [mailTemplateLabel, setMailTemplateLabel] = useState(null);
    const [mailCategoryLabel, setMailCategoryLabel] = useState(null);

    const pageSizeOptions = [10, 20];
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [currentPageSize, setCurrentPageSize] = useState<number>(pageSizeOptions[0]);

    const [showOrganizationModal, setShowOrganizationModal] = useState(false);

    const resourceUrl = '/api/v1/get-mail-scenarios';
    const navigate = useNavigate();


    const intl = useIntl();
    const [form] = Form.useForm();
    const [modal] = Form.useForm();

    useEffect(() => {
        if (session.active_user_type === 'SUPER_ADMIN') {
            setScenariosLoading(false);
        }
        setOrganization();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setOrganization = () => {
        setTemplateOwnerType(session.organization.organization_type);
        setOwnerId(session.organization.organization_id);
    }


    useEffect(() => {
        changeOrganizationOptions({});
        const loadEmailCategories = async () => {
            let categoriesResponse = await apiClient.request(`/api/v1/mail-templates/options/categories`, {}, 'GET');
            if (session.active_user_type !== 'SUPER_ADMIN') {
                Object.entries(categoriesResponse).map((el: any) => {
                    if (el[1] === 'system_events') {
                        delete categoriesResponse[el[0]]
                    }
                    return categoriesResponse;
                })
            }
            setEmailCategories(categoriesResponse);
        };

        if (!filterdOrganizationOptions.owner) {
            form.setFieldsValue({
                filterBy: 'customer',
            });
        }
        modal.setFieldsValue({
            filterBy: 'customer',
        });

        // if (session.active_user_type === 'DISTRIBUTOR_ADMIN') {
        //     setTemplateOwnerType('organization');
        //     setOwnerId(session.organization.organization_id);
        // } else if (session.active_user_type === 'RESELLER_ADMIN') {
        //     setTemplateOwnerType('organization');
        //     setOwnerId(session.organization.organization_id);
        // } else if (session.active_user_type === 'CUSTOMER_ADMIN') {
        //     setTemplateOwnerType('customer');
        //     setOwnerId(session.organization.organization_id);
        // }

        loadEmailCategories();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [session]);

    const handleVisibleChange = (visible: boolean, recordId: number) => {
        if (visible) {
            setVisibleTooltip(recordId);
        } else {
            setVisibleTooltip(null);
        }
    }

    async function onChange(record: any) {
        let errorMessageCode = 'error.data_load';
        let force = 0;

        setSelected(record)

        try {
            if (record.status === 'ACTIVE') {
                await apiClient.request(`/api/v1/mail-scenario/${record.id}/deactivate/${force}`, record, 'POST');
                record.status = 'INACTIVE'
            } else if (record.status === 'INACTIVE') {
                await apiClient.request(`/api/v1/mail-scenario/${record.id}/activate`, record, 'POST');
                record.status = 'ACTIVE'
            }
        } catch (err: any) {
            if (err.message === 'unsend_mail_scenario_queue') {
                errorMessageCode = err.message

                let keys = Object.keys(record.queued);
                let last = keys[keys.length - 1];

                setQueuedEmails(record.queued[last])

                let hours = calculateMailDeliveryTime(record.queued[last], record.emailLimit)

                var term = moment().add(hours, 'hours').format('Y-MM-DD');

                setMailDeliveryTerm(term)
                setShowDeactivationConfirmationModal(true)
            } else {
                message.error(intl.formatMessage({id: 'error.' + errorMessageCode}));
            }
        }
    }

    const calculateMailDeliveryTime = (recipients: any, limit: any) => {
        let hours = 0;

        if (limit > 0 && recipients > 0) {
            let add = (recipients % limit === 0) ? 0 : 1;

            hours = (Math.trunc(recipients / limit) + add) - 1;
        }

        return hours
    };

    const columns = [
        {
            title: intl.formatMessage({id: 'emailing.scenarios.e_mailing_event'}),
            render: (value: string, record: any) => {

                let templateDescription = '';
                if (record.templateName && record.templateName[locale]) {
                    templateDescription = record.templateName[locale];
                } else if (record.templateName) {
                    templateDescription = record.templateName[Object.keys(record.templateName)[0]];
                }

                return <>
                    <Tag>
                        <FormattedMessage id={`email_templates.categories.${record.categoryName}`}/>
                    </Tag>
                    {templateDescription}
                </>
            }
        },
        {
            title: intl.formatMessage({id: 'emailing.campaign'}),
            dataIndex: 'campaignName',
            render: (value: string, record: any) =>
                record.campaignName ? record.campaignName : '-'
        },
        {
            title: intl.formatMessage({id: 'general.schedule'}),
            dataIndex: 'cron_expression',
            key: 'cronExpression',
            render: (value: string, record: any) => {
                if (record.cronExpression !== null) {
                    let result = {everyMinutesOn: '', everyHourOn: '', everyMonthOn: '', everyWeekOn: []};
                    let expressionElements = record.cronExpression.split(' ');
                    if (expressionElements[0] !== '*') {
                        if (expressionElements[0] < 10) {

                            result.everyMinutesOn = '0' + expressionElements[0].split(',').map((el: string) => parseInt(el));
                        } else result.everyMinutesOn = expressionElements[0].split(',').map((el: string) => parseInt(el));
                    }
                    if (expressionElements[1] !== '*') {
                        if (expressionElements[1] < 10) {

                            result.everyHourOn = '0' + expressionElements[1].split(',').map((el: string) => parseInt(el));
                        } else result.everyHourOn = expressionElements[1].split(',').map((el: string) => parseInt(el));
                    }

                    if (expressionElements[2] !== '*') {

                        result.everyMonthOn = expressionElements[2].split(',').map((el: string) => parseInt(el));
                        if (result.everyMonthOn.length === 1) {
                            return ` ${result.everyHourOn}:${result.everyMinutesOn} ${intl.formatMessage({id: 'emailing.scenarios.every_month_on'})}  ${result.everyMonthOn} ${intl.formatMessage({id: 'day'})}  `
                        } else {
                            return ` ${result.everyHourOn}:${result.everyMinutesOn} ${intl.formatMessage({id: 'emailing.scenarios.every_month_on'})} ${result.everyMonthOn} ${intl.formatMessage({id: 'day'})}`
                        }
                    } else if (expressionElements[4] !== '*') {

                        result.everyWeekOn = expressionElements[4].split(',').map((el: string) => parseInt(el));

                        if (result.everyWeekOn.length > 1) {
                            let text = ` ${result.everyHourOn}:${result.everyMinutesOn} ${intl.formatMessage({id: 'emailing.scenarios.every_week_on'})}`;
                            result.everyWeekOn.forEach((el: number) => {
                                text += ` ${intl.formatMessage({id: `emailing.scenarios.day.${el}`})},`;
                            })
                            text = text.substring(0, text.length - 1);
                            return text;
                        } else {
                            return ` ${result.everyHourOn}:${result.everyMinutesOn} ${intl.formatMessage({id: 'emailing.scenarios.every_week_on'})} ${intl.formatMessage({id: `emailing.scenarios.day.${result.everyWeekOn}`})} `
                        }


                    } else if (result.everyMinutesOn !== '' && result.everyHourOn !== '') {
                        return ` ${result.everyHourOn}:${result.everyMinutesOn} ${intl.formatMessage({id: 'emailing.scenarios.every_day'})} `
                    } else return ` ${intl.formatMessage({id: 'error.invalid_cron_expression'})} `

                } else if (record.scheduleDate) {
                    return `${record.scheduleDate}`;
                } else {
                    return '-'
                }
            }
        },
        {
            title: intl.formatMessage({id: 'emailing.scenarios.last_occurance'}),
            dataIndex: 'lastExecution',
            render: (value: string, record: any) =>
                record.lastExecution ? moment(record.lastExecution).format('DD.MM.YYYY h:mm') : '-'
        },
        {
            title: intl.formatMessage({id: 'emailing.scenarios.next_occurance'}),
            dataIndex: 'nextExecution',
            render: (value: string, record: any) =>
                record.nextExecution ? (record.isScenarioEnded ? '-' : moment(record.nextExecution).format('dd.mm.yyyy')) : '-'
        },
        {
            title:
                <div>
                    {intl.formatMessage({id: 'general.status'})}
                    <Popover
                        content={intl.formatMessage({id: 'emailing.scenarios.status.hint'})}>
                        <i style={{marginLeft: '3px'}} className='fal fa-question-circle'></i>
                    </Popover>
                </div>,
            dataIndex: 'status',
            render: (value: string, record: any) => {
                let switchStatus;
                if (record.status === 'ACTIVE') switchStatus = true;
                if (record.status === 'INACTIVE') switchStatus = false;
                return (<Switch onChange={() => onChange(record)} defaultChecked={switchStatus}
                                disabled={record.isScenarioEnded}/>)
            }
        },
        {
            key: 'actions',
            render: (text: string, record: any) => {
                const organizationId = record.id;
                const content = <>
                    {record.scenarioType === 'CUSTOM' ?
                        <NavLink to={`/e-mailing/email-scenarios/${record.id}/edit`}>
                            <div className='popover-item'>
                                <FormattedMessage id='general.edit'/>
                            </div>
                        </NavLink>
                        : null}
                    <NavLink to={`/e-mailing/${record.id}/history/`}>
                        <div className='popover-item'>
                            <FormattedMessage id='emailing.scenarios.view_sending_history'/>
                        </div>
                    </NavLink>
                </>;

                return (
                    <Popover
                        open={organizationId === visibleTooltip}
                        content={content}
                        trigger='click'
                        placement='bottomRight'
                        arrowPointAtCenter
                        onOpenChange={(visible) => handleVisibleChange(visible, organizationId)}
                    >
                        <div style={{width: '100%', cursor: 'pointer', textAlign: 'center'}}>
                            <i className='fal fa-ellipsis-v' style={{fontSize: '16px'}}/>
                        </div>
                    </Popover>
                )
            },
        }
    ];

    const clearFilter = async () => {
        setFilteredOptions([]);

        setTemplateOwnerLabel(null);
        setMailCategoryLabel(null);
        setMailTemplateLabel(null);

        form.setFieldsValue({
            templateCategory: [],
            templateName: []
        });

        setTotalRecords(0);
    };


    const tagFilter = async (el: any, index: any) => {
        let newFilter: any = filter;

        let parsedValues;

        if (el.customer) {
            form.setFieldsValue({
                customer: [],
                organization: [],
                templateCategory: [],
                templateName: []
            });

            setTemplateOwnerLabel(null);
            newFilter = [];
            setFilteredOptions([]);
        }

        if (el.category) {
            form.setFieldsValue({
                templateCategory: [],
            });
            setMailCategoryLabel(null);
            newFilter.categoryId = [];
        }

        if (el.template) {
            form.setFieldsValue({
                templateName: []
            });
            setMailTemplateLabel(null);
            newFilter.templateId = [];
        }

        if (el.category || el.template) {
            parsedValues = newFilter;

            setScenariosLoading(true);
            setShowFilterModal(false);

            setScenariosLoading(false);
        }
    };

    const forceDeactivateMailScenario = async (selected: any) => {
        await apiClient.request(`/api/v1/mail-scenario/${selected.id}/deactivate/1`, {}, 'POST');
        selected.status = 'INACTIVE'

        setShowDeactivationConfirmationModal(false)
    };

    return (
        <>
            <DefaultLayout.PageLayout>
                <DefaultLayout.PageHeader title={intl.formatMessage({id: 'emailing.scenarios.e_mailing_center'})}/>
                <DefaultLayout.PageContent>
                    <FlexRow
                        left={
                            <>
                                <NavLink to={'/e-mailing/email-scenarios/add'}>
                                    <Button type='primary'>
                                        <PlusOutlined/> <FormattedMessage id='emailing.scenarios.send_new_e_mail'/>
                                    </Button>
                                </NavLink>
                                <Button type='primary' onClick={() => setShowFilterModal(true)}>
                                    <FormattedMessage id='general.filter'/>
                                </Button>
                            </>
                        }
                        right={
                            <>
                                <InstructionButton position='MAIL_NOTIFICATIONS'/>
                            </>
                        }
                    />
                    <Table
                        columns={columns}
                        url='api/v1/mail-scenarios'
                        setReload={setReload}
                        reload={reload}
                        rowKey='id'
                        rowSelection={false}
                        filter={filter}
                    />
                </DefaultLayout.PageContent>
            </DefaultLayout.PageLayout>
            <MailScenariosFilterModal
                visible={showFilterModal}
                onCancel={setShowFilterModal}
                setFilter={setFilter}
                reload={setReload}
            />


            <Modal
                title={intl.formatMessage({id: 'emailing.scenarios.deactivate_mail_scenario'})}
                open={showDeactivationConfirmationModal}
                onOk={() => forceDeactivateMailScenario(selected)}
                onCancel={() => setShowDeactivationConfirmationModal(false)}
                okText={intl.formatMessage({id: 'general.yes'})}
                cancelText={intl.formatMessage({id: 'general.no'})}
            >
                {intl.formatMessage({id: 'general.warning'})}!
                <br></br>
                <br></br>
                {intl.formatMessage({id: 'emailing.scenarios.mail_scenario_deactivate_confirmation'}, {
                    queuedEmails: queuedEmails,
                    mailDeliveryTerm: mailDeliveryTerm
                })}
                <br></br>
                <br></br>
                {intl.formatMessage({id: 'emailing.scenarios.are_you_sure_you_want_to_disable'})}?
            </Modal>
        </>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(SendEmailScenarios);
