import React, {useState, useEffect} from 'react';
import coursesApiClient from 'utils/coursesApiClient';
import {message} from 'antd';
import {useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {PlyrComponent} from 'ui'

import './styles.scss';
import VdocipherComponent from "../../../ui/VdocipherComponent";

type ServerRequestType = {
    learningActivityId: number;
    activityAttemptId?: number;
    type: 'learn' | 'test';
    userTypeUuid: string;
    language?: string;
    version?: string;
}

/**
 *
 * @param state
 * @returns
 */
const mapStateToProps = (state: any) => ({session: state.session});

/**
 *
 * @param param0
 * @returns
 */
const VideoPlayer: React.FC<any> = ({scormData, language, session, version}) => {
    const [video, setVideo] = useState<any>({}),
        [endLoading, setEndLoading] = useState(false),
        intl = useIntl();


    useEffect(() => {
        if (scormData) {
            loadPdfMaterialPlayer(scormData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const loadPdfMaterialPlayer = async (data: any) => {
        try {
            const parsedValues: ServerRequestType = {
                learningActivityId: data.learningActivityId,
                type: data.type,
                userTypeUuid: session.active_user_type_uuid,
                activityAttemptId: data.activityAttemptId
            };

            if (language) {
                parsedValues.language = language
            }

            if (version) {
                parsedValues.version = version
            }
            const response = await coursesApiClient.request(`/api/v1/courses/serve-request/start-video`, parsedValues, 'POST');

            const materialData = response.material;
            const settings = materialData.settings;
            startActivity(materialData.uuid)
            setVideo(materialData)
            if (!settings.view_all_to_complete) {
                finish(materialData.uuid);
            }
        } catch (error: any) {
            if (error.message === 'already_active_scorm') {
                message.error(intl.formatMessage({id: 'error.already_active_activity'}));
            } else {
                console.error(error);
                message.error(intl.formatMessage({id: 'error.data_load'}));
            }
        }
    }

    const finish = async (uuid: string) => {
        try {
            await coursesApiClient.request(`/api/v1/courses/video-results/${uuid ? uuid : video.uuid}/finish`, {}, 'POST');
        } catch (error) {
            message.error(intl.formatMessage({id: 'error.data_load'}));
            console.error(error);
        }
    }

    const endVideoSession = async (completeLearning: boolean = true) => {
        setEndLoading(true)
        if (completeLearning) {
            await finish(video.uuid);
        }
        try {
            await coursesApiClient.request(`/api/v1/courses/video-results/${video.uuid}/end`, {}, 'POST');
            window.self.close();
        } catch (error) {
            message.error(intl.formatMessage({id: 'error.data_load'}));
            console.error(error);
        } finally {
            setEndLoading(false)
        }
    }

    /**
     *
     * @param uuid
     */
    const startActivity = (uuid: string) => {
        coursesApiClient.request(`/api/v1/courses/video-results/${uuid}/initialize`, {}, 'POST');
    }

    const getPlayer = () => {
        switch (video.videoProvider) {
            case 'vdocipher':
                return (
                    <VdocipherComponent
                        video={video}
                        endVideoSession={endVideoSession}
                        endLoading={endLoading}
                        finish={finish}
                    />
                )
            case 'youtube':
            case 'vimeo':
                return (
                    <PlyrComponent
                        video={video}
                        endVideoSession={endVideoSession}
                        endLoading={endLoading}
                        finish={finish}
                    />
                )
            default:
                return null;
        }
    }

    return (
        <>
            {video?.videoId &&
                <>
                    {getPlayer()}
                </>
            }
        </>
    );
};

export default connect(mapStateToProps)(VideoPlayer);
