import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {RotateLeftOutlined, RotateRightOutlined} from '@ant-design/icons';
import {useIntl, FormattedMessage} from 'react-intl';
import {Button, message, Progress, Space} from 'antd';
import apiClient from 'utils/apiClient';
import coursesApiClient from 'utils/coursesApiClient';
import Spinner from 'components/Spinner';
import {useBeforeunload} from 'react-beforeunload';
import moment from 'moment';
import './styles.scss';
import {useNavigate} from 'react-router-dom';
import {config} from "../../../config/config";
import useHandleError from "../../../utils/useHandleError";

const mapStateToProps = (state: any) => ({session: state.session});

interface PdfMaterialPlayerProps {
    learningActivityId: number;
    activityAttemptId?: number;
    type: 'learn' | 'test';
    isFirstAttempt?: boolean;
    session?: any;
    parentActivityId?: number;
    campaignId?: string;
    language?: string;
    version?: string;
}

type ServerRequestType = {
    learningActivityId: number;
    activityAttemptId?: number;
    type: 'learn' | 'test';
    userTypeUuid: string;
    language?: string;
    version?: string;
}

const PdfMaterialPlayer: React.FC<PdfMaterialPlayerProps> = ({
                                                                 learningActivityId,
                                                                 activityAttemptId,
                                                                 type,
                                                                 isFirstAttempt,
                                                                 session,
                                                                 parentActivityId,
                                                                 campaignId,
                                                                 language,
                                                                 version
                                                             }) => {
    const [pdfMaterialData, setPdfMaterialData] = useState<any>();
    const [currentPage, setCurrentPage] = useState(0);
    const [isMaterialLoading, setIsMaterialLoading] = useState(false);
    const [isNextButtonLoading, setIsNextButtonLoading] = useState(false);
    const [rotation, setRotation] = useState(0);
    const [percent, setPercent] = useState(0);
    const [timerStart, seTimerStart] = useState<any>();
    const [finishLoading, setFinishLoading] = useState(false);
    const [path, setPath] = useState('')
    const [orientation, setOrientation] = useState('P');
    const [imageStyle, setImageStyle] = useState<any>({
        transform: `rotate(0deg)`,
        width: '100%'
    });
    const [timer, setTimer] = useState<any>();
    const [timeoutId, setTimeoutId] = useState<any>(0);
    const navigate = useNavigate();
    const intl = useIntl();
    const [handleError] = useHandleError();

    useEffect(() => {
        loadPdfMaterialPlayer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl, learningActivityId, activityAttemptId, type, isFirstAttempt, session]);

    document.addEventListener("visibilitychange", (event) => {
        if (document.visibilityState === "visible") {
            if (pdfMaterialData && pdfMaterialData?.settings?.seconds_on_slide ? pdfMaterialData.settings.seconds_on_slide : 5 && timer) {
                setIsNextButtonLoading(true);
                const pause = pdfMaterialData?.settings?.seconds_on_slide ? pdfMaterialData.settings.seconds_on_slide : 5 - timer
                if (pause > 0) {
                    setTimeout(function () {
                        setIsNextButtonLoading(false)
                    }, pause * 1000);
                }
            }
        } else {
            if (timerStart) {
                clearTimeout(timeoutId);
                setTimer(~~(moment().diff(timerStart) / 1000));
            }
        }
    });

    const loadPdfMaterialPlayer = async () => {
        setIsMaterialLoading(true);

        try {

            if (!learningActivityId || !type) {
                navigate(-1);
            }

            const parsedValues: ServerRequestType = {
                learningActivityId,
                activityAttemptId,
                type,
                userTypeUuid: session.active_user_type_uuid
            };

            if (language) {
                parsedValues.language = language
            }

            if (version) {
                parsedValues.version = version
            }

            const response = await coursesApiClient.request('/api/v1/courses/serve-request/get-pdf-material', parsedValues, 'POST');

            setPdfMaterialData(response.material);
            startActivity(response.material.uuid);
            let lastPage = response.material.lastPage ? parseInt(response.material.lastPage) : 0;

            loadImage(lastPage, response.material);
            if (isFirstAttempt) {
                consumeLicenceAccess();
            }
        } catch (error: any) {
            if (error.message === 'already_active_scorm') {
                message.error(intl.formatMessage({id: 'error.already_active_activity'}));
            } else {
                console.error(error);
                message.error(intl.formatMessage({id: 'error.data_load'}));
            }
        } finally {
            setIsMaterialLoading(false);
        }
    }

    const consumeLicenceAccess = async () => {
        try {
            const values = {
                activityId: learningActivityId,
                userTypeId: session.active_user_type_id,
                customerId: session.organization.organization_id,
                parentActivityId: parentActivityId,
                campaignId: campaignId
            }

            return await apiClient.request('/api/v1/licences/consume', values, 'POST');
        } catch (error) {
            message.error(intl.formatMessage({id: 'error.data_load'}));
            console.error(error);
        }
    }

    const loadImage = async (page: number, material = null) => {
        setIsNextButtonLoading(true);

        if (page === 0) {
            page = 1
        }

        const data = material ? material : pdfMaterialData;

        try {
            setPercent((page / data.pages) * 100)
            setCurrentPage(page);

            const response = await coursesApiClient.request(`/api/v1/courses/pdf-results/${data.uuid}/get`, {page: page}, 'POST');

            setOrientation(response.orientation)
            setPath(response.path)
            buttonLoadingTimer(data);
        } catch (error) {
            handleError(error)
            setIsNextButtonLoading(false);
        }
    }

    /**
     *
     * @param uuid
     */
    const startActivity = (uuid: string) => {
        coursesApiClient.request(`/api/v1/courses/pdf-results/${uuid}/initialize`, {}, 'POST');
    }

    const endActivity = () => {
        coursesApiClient.request(`/api/v1/courses/pdf-results/${pdfMaterialData.uuid}/finish`, {currentPage}, 'POST');
    }

    useBeforeunload((event) => {
        if (pdfMaterialData) {
            if (!finishLoading) {
                endActivity();
            }

            if (currentPage !== pdfMaterialData.pages) {
                event.preventDefault();
            }
        }
    });

    const finish = async () => {
        setFinishLoading(true)
        try {
            await coursesApiClient.request(`/api/v1/courses/pdf-results/${pdfMaterialData.uuid}/finish`, {currentPage}, 'POST');
            window.close();
        } catch (error) {
            message.error(intl.formatMessage({id: 'error.data_load'}));
            console.error(error);
            setFinishLoading(false)
        }
    }

    const buttonLoadingTimer = (data: any) => {
        const timeout = setTimeout(function () {
            setIsNextButtonLoading(false)
        }, data?.settings?.seconds_on_slide ? (data.settings.seconds_on_slide  * 1000): 5 * 1000);
        setTimeoutId(timeout)
    }

    const changeWidthWithHeight = (direction: 'right' | 'left') => {
        let degree = rotation;

        if (direction === 'right') {
            degree += 90
        } else if (direction === 'left') {
            degree -= 90
        }

        let newImageStyle = {};

        if (imageStyle.marginTop) {
            newImageStyle = {
                transform: `rotate(${degree}deg)`,
                width: '100%'
            }
        } else {
            newImageStyle = {
                transform: `rotate(${degree}deg)`,
                marginTop: '35%',
                width: '100%',
                marginBottom: '35%'
            }
        }

        setRotation(degree);
        setImageStyle(newImageStyle);
    }

    const toTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'})
    }

    return (
        <>
            <Spinner spinning={isMaterialLoading}>
                <div className="image-wrapper">
                    {path &&
                        <iframe className={orientation === 'P' ? 'pdf-player' : "pdf-player-land"} height={'100%'}
                                width={'100%'} title="MANUAL_PLAYER"
                                src={`${config.api.serveUrl}-pdf/${path}`}/>
                    }
                </div>
            </Spinner>
            <div className='pdf-progress-bar'>
                {pdfMaterialData &&
                    <Progress showInfo={false} percent={percent}/>
                }
            </div>
            <div className='pdf-material-toolbar'>
                {/*<Space>*/}
                {/*    {pdfMaterialData &&*/}
                {/*        <>*/}
                {/*            <Button onClick={() => {*/}
                {/*                changeWidthWithHeight('left')*/}
                {/*            }}>*/}
                {/*                <RotateLeftOutlined/>*/}
                {/*            </Button>*/}
                {/*            <Button onClick={() => {*/}
                {/*                changeWidthWithHeight('right')*/}
                {/*            }}>*/}
                {/*                <RotateRightOutlined/>*/}
                {/*            </Button>*/}
                {/*        </>*/}
                {/*    }*/}
                {/*</Space>*/}
                <div></div>
                {pdfMaterialData &&
                    <div>
                        {currentPage} / {pdfMaterialData.pages}
                    </div>
                }
                <Space>
                    {currentPage !== 1 &&
                        <Button loading={isNextButtonLoading} type='primary' onClick={() => {
                            toTop();
                            loadImage(currentPage - 1)
                        }}>
                            <FormattedMessage id='campaign.previous'/>
                        </Button>
                    }
                    {pdfMaterialData && currentPage !== pdfMaterialData.pages &&
                        <Button loading={isNextButtonLoading} type='primary' onClick={() => {
                            toTop();
                            loadImage(currentPage + 1);
                        }}>
                            <FormattedMessage id='general.next'/>
                        </Button>
                    }
                    {pdfMaterialData && currentPage === pdfMaterialData.pages &&
                        <Button loading={finishLoading} type='primary' onClick={() => finish()}>
                            <FormattedMessage id='general.finish'/>
                        </Button>
                    }
                </Space>
            </div>
        </>

    );
}

export default connect(mapStateToProps)(PdfMaterialPlayer);
