import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { Modal, Popover } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Search, Table, Button } from "ui";
import DefaultLayout from "components/DefaultLayout";
import FlexRow from "components/FlexRow";
import LectureCreationModal from 'components/Lectures/Modals/LectureCreationModal';
import { useLecture } from "hooks/Lectures/useLecture";
import coursesApiClient from "utils/coursesApiClient";
import useHandleError from "utils/useHandleError";


const LecturesList: React.FC = () => {
    const [reload, setReload] = useState<boolean>(false);
    const [searchString, setSearchString] = useState<string>('');
    const [visibleTooltip, setVisibleTooltip] = useState<number | null>(null);
    const [showCreateModal, setCreateModal] = useState<boolean>(false)
    const [programId, setProgramId] = useState<number>()
    const { deleteLecture, lectureLoading } = useLecture();
    const [customFields, setCustomFields] = useState<any>([]);
    const [searchParams, setSearchParams] = useSearchParams();

    const intl = useIntl();
    const { confirm } = Modal;
    const navigate = useNavigate();
    const [handleError] = useHandleError();

    
    const handleVisibleChange = (visible: boolean, recordId: number) => {
        if (visible) {
            setVisibleTooltip(recordId);
        } else {
            setVisibleTooltip(null);
        }
    };

    useEffect(() => {
        const programId = searchParams.get('program');
        if (!!programId) {
            setProgramId(+programId);
            searchParams.delete('program');
            setSearchParams(searchParams);
            setCreateModal(true);
        }
        loadCustomFields();


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const loadCustomFields = async () => {
        try {
            const customFields = await coursesApiClient.request('/api/v1/courses/lectures/custom-fields/lecture-custom-fields', [], 'GET');
            setCustomFields(customFields.fields);
        } catch (error) {
            handleError(error)
        } finally {
        }
    }

    const columns = [
        {
            key: 'title',
            title: intl.formatMessage({ id: 'general.lectures.title' }),
            render: (record: any) => {
                return (
                    <NavLink to={`/lectures/${record.id}`}>
                        {record.title}
                    </NavLink>
                )
            }
        },
        {
            key: 'actions',
            render: (_text: string, record: any) => {
                const content = (
                    <div className="popover-item" onClick={() => {
                        warnDeletion(record.id);
                        setVisibleTooltip(null);
                    }}>
                        <FormattedMessage id="general.delete" />
                    </div>
                );

                return (
                    <Popover
                        open={record.id === visibleTooltip}
                        content={content}
                        trigger="click"
                        placement="bottomRight"
                        arrowPointAtCenter
                        onOpenChange={(visible) => handleVisibleChange(visible, record.id)}
                    >
                        <div style={{ width: '100%', cursor: 'pointer', textAlign: 'center' }}>
                            <i className="fal fa-ellipsis-v" style={{ fontSize: '16px' }} />
                        </div>
                    </Popover>
                )
            },
        }
    ];

    const handleAddLecture = (id: number) => {
        setProgramId(undefined);
        setCreateModal(false);
        navigate(`/lectures/${id}`);
    }

    const handleDeleteLecture = async (id: number) => {
        if (await deleteLecture(id)) {
            setReload(!reload);
        }
    }

    const warnDeletion = async (id: number) => {
        confirm({
            title: intl.formatMessage({ id: 'general.attention' }),
            content: intl.formatMessage({ id: 'general.lectures.question_delete_lecture' }),
            icon: <ExclamationCircleOutlined />,
            okText: intl.formatMessage({ id: 'general.yes' }),
            cancelText: intl.formatMessage({ id: 'general.no' }),

            onOk() {
                handleDeleteLecture(id);
            }
        });
    }

    const handleCancelCreatelecture = () => {
        setCreateModal(false);
        setProgramId(undefined);
    }

    return (
        <DefaultLayout.PageLayout>
            <DefaultLayout.PageHeader title={intl.formatMessage({ id: 'general.lectures.catalogue' })} />
            <DefaultLayout.PageContent>
                <FlexRow
                    left={
                        <Button type='primary' onClick={() => setCreateModal(true)}>
                            <FormattedMessage id='general.lectures.add_new_lecture' />
                        </Button>
                    }
                    right={
                        <Search setSearchString={setSearchString} setReload={setReload} />
                    }
                />
                <Table
                    columns={columns}
                    url={'/api/v1/courses/lectures'}
                    setReload={setReload}
                    reload={reload}
                    rowSelection={false}
                    rowKey={'id'}
                    search={searchString}
                    isLoading={lectureLoading}
                    customFields={customFields}
                    learningProgramCustomColumns={true}
                    scroll={{ x: 800 }}
                />
            </DefaultLayout.PageContent>

            <LectureCreationModal
                visible={showCreateModal}
                programId={programId}
                onCancel={() => handleCancelCreatelecture()}
                onSubmit={(id: number) => handleAddLecture(id)}
            />


        </DefaultLayout.PageLayout>
    );
};

export default LecturesList;