import {config} from 'config/config';
import apiClient from "./apiClient";

const getGlobalStyles: any = async () => {
    try {
        return config.api.appAppearance;
    } catch (error) {
      console.error(error);
    }
};

const loadLogo = async (logoPath: string) => {
    try {
      return await apiClient.request(`/api/v1/appearance-settings?path=${logoPath}`, {}, 'GET', true, true);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const loadFavicon = async (faviconPath: string) => {
    try {
      return await apiClient.request(`/api/v1/appearance-settings?path=${faviconPath}`, {}, 'GET', true, true);
    } catch (error) {
      console.error(error);
    }
  };

const exportedObject = {
  getGlobalStyles, loadLogo, loadFavicon
};

export default exportedObject;