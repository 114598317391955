import React from 'react';
import {FormattedMessage} from 'react-intl';
import {useNavigate} from "react-router-dom";
import {Card} from 'antd';
import {CheckOutlined, CloseOutlined} from '@ant-design/icons';
import {Button} from "ui";
import SessionStatusBadge from "components/Lectures/SessionStatusBadge/SessionStatusBadge";
import './styles.scss';
import generalHelpers from "../../../utils/generalHelpers";

const LectureCard: React.FC<any> = ({
                                        lecture,
                                        adminView = false,
                                        multipleAttempts
                                    }) => {
    const navigate = useNavigate();

    const hasButton =() => {
        if (!lecture.accessAfterPass && lecture.status === 'ATTENDED') {
            return false
        }

        return true
    }

    const getMandatory = () => {
        if (multipleAttempts) {
            return (
                lecture.mandatoryRepeat ? <CheckOutlined style={{color: 'green'}}/> :
                    <CloseOutlined style={{color: 'red'}}/>
            )
        } else {
            return lecture.mandatory ? <CheckOutlined style={{color: 'green'}}/> :
                <CloseOutlined style={{color: 'red'}}/>
        }
    }

    const getTimeSpent = () => {
        if (lecture.status !== 'ATTENDED') {
            return
        }

        const timeDifferenceMinutes = generalHelpers.getLectureTimeSpent(lecture)
        
        return generalHelpers.secondsToHHmm(timeDifferenceMinutes*60);
    }

    return (
        <Card className="card lecture-card">
            <div className="program-data-container">
                <div className={'student-material title'}>
                    <span className={'student-material-title'}>
                        <FormattedMessage id={'general.activity'}/>
                    </span>
                    <span>
                                        <div className={'flex gap-5 align-center'}>
                            <i className="fa-solid fa-calendar-check"></i>
                            <div className={'flex flex-column gap-5'}>
                                <div>
                                    {lecture.name}
                                </div>
                                <div className={'c-gray flex gap-5'}>
                                    <span>
                                        <FormattedMessage id={'general.lecture'}/>
                                    </span>
                                    <span>
                                        {lecture.date}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </span>
                </div>
                <div className={'student-material time-spent'}>
                    <span className={'student-material-title'}>
                        <FormattedMessage id={'student.time_spent'}/>
                    </span>
                    <div className={'child'}>
                        {getTimeSpent()}
                    </div>
                </div>
                <div className={'student-material pass-rate'}>
                   <span className={'student-material-title'}>
                        <FormattedMessage id={'general.pass_rate'}/>
                    </span>
                    <div className={'child'}>
                    </div>
                </div>
                <div className={'student-material student-result'}>
                    <span className={'student-material-title'}>
                        <FormattedMessage id={'student.result'}/>
                    </span>
                    <div className={'child'}>
                    </div>
                </div>
                <div className={'student-material mandatory'}>
                    <span className={'student-material-title'}>
                        <FormattedMessage id={'general.mandatory'}/>
                    </span>
                    <span>
                          {getMandatory()}
                    </span>
                </div>
                <div className={'student-material student-status'}>
                    <span className={'student-material-title'}>
                        <FormattedMessage id={'general.status'}/>
                    </span>
                    <SessionStatusBadge status={lecture.status} attendee/>
                </div>
                <div className={'student-material action'}>
                    <span className={'student-material-buttons'}>
                        {adminView ?
                            null
                            :
                            <>
                                {hasButton() &&
                                    <>
                                        {lecture.sessionId &&
                                            <Button
                                                disabled={lecture.button === 'disabled'}
                                                type={'primary'}
                                                onClick={() => navigate(`/my-lectures/${lecture.id}/session/${lecture.sessionId}`)}>
                                                <FormattedMessage id={'general.view'}/>
                                            </Button>
                                        }
                                        {lecture.registeredBy === 'SELF_REGISTRATION' &&
                                            <Button type={'primary'} onClick={() => navigate('lecture/' + lecture.id)}>
                                                <FormattedMessage id={'general.sing_up'}/>
                                            </Button>
                                        }
                                    </>
                                }
                            </>
                        }
                    </span>
                </div>
            </div>
        </Card>
    )
}

export default LectureCard;