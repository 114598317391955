import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { NavLink, useParams } from 'react-router-dom';
import { message, Popover, Tag, Modal, Checkbox, Input, Form, Button, Space } from 'antd';
import { PlusOutlined, ExclamationCircleOutlined, InfoCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import DefaultLayout from 'components/DefaultLayout';
import apiClient from 'utils/apiClient';
import useHandleError from 'utils/useHandleError';
import { FilterModal, SendEmailModal } from 'components/Modals';
import { Table } from 'ui'
import debounce from "lodash/debounce";
import FlexRow from "components/FlexRow";
import { OrganizationType } from "components/UsersComponents/types";
import './styles.scss';
import Jwt from "../../../utils/jwt";
import { connect } from "react-redux";

const mapStateToProps = (state: any) => {
    return {
        session: state.session
    }
};


const AdminList: React.FC<any> = ({ session }) => {
    const [reload, setReload] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<[]>([]);
    const [showUsersFilterModal, setShowUsersFilterModal] = useState(false);
    const [filter, setFilter] = useState('');
    const [organizationType, setOrganizationType] = useState<OrganizationType>('ORGANIZATION');
    const [searchString, setSearchString] = useState('');

    const [isAdminsLoading, setIsAdminsLoading] = useState(false);
    const [visibleTooltip, setVisibleTooltip] = useState<number | null>(null);
    const [organization, setOrganization] = useState<any>({});
    const [isOrganizationLoading, setIsOrganizationLoading] = useState(false);
    const [selectedRowsLength, setSelectedRowsLength] = useState<number>(0);
    const [showScenariosModal, setShowScenariosModal] = useState(false);

    const [totalRecords, setTotalRecords] = useState<number>(0);

    const { confirm, info } = Modal;
    const { Search } = Input;
    const params = useParams();
    const organizationId = params.organizationId;
    const [handleError] = useHandleError();


    const intl = useIntl();

    useEffect(() => {
        if (organizationId) {
            loadOrganization();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationId]);

    const loadOrganization = async () => {
        try {
            setIsOrganizationLoading(true);
            let organizationResponse = await apiClient.request(`/api/v1/organizations/${organizationId}`, {}, 'GET');
            setOrganizationType(organizationResponse.organization.type);
            setOrganization(organizationResponse.organization);
        } catch (err) {
            console.error(err);
            message.error(intl.formatMessage({ id: "error.data_load" }));
        } finally {
            setIsOrganizationLoading(false);
        }
    };

    const handleVisibleChange = (visible: boolean, recordId: number) => {
        if (visible) {
            setVisibleTooltip(recordId);
        } else {
            setVisibleTooltip(null);
        }
    };

    const tagColor = {
        ADMIN: 'gold',
        LECTOR: 'blue',
    }

    const columns = [
        {
            title: intl.formatMessage({ id: 'general.name' }),
            dataIndex: 'name',
            key: 'name',
            render: (text: string, record: any) => {
                if (record.organizationType === 'CUSTOMER') {
                    return (
                        <NavLink to={`/customer/${record.organizationId}/user/${record.typeUuid}/view/users`}>
                            {record.name}
                        </NavLink>
                    )
                } else {
                    return (
                        <NavLink to={`/organizations/${organizationId}/admins/${record.typeUuid}/edit`}>
                            {record.name}
                        </NavLink>
                    )

                }
            }
        },
        {
            title: intl.formatMessage({ id: 'general.surname' }),
            dataIndex: 'surname',
        },
        {
            title: intl.formatMessage({ id: 'general.email' }),
            dataIndex: 'email',
        },
        {
            title: intl.formatMessage({ id: 'general.organization' }),
            dataIndex: 'organizationName',
        },
        {
            title: intl.formatMessage({ id: 'users.organizational_unit' }),
            dataIndex: 'organizationalUnit',
        },
        {
            title: intl.formatMessage({ id: 'general.position' }),
            dataIndex: 'position',
        },
        {
            title: intl.formatMessage({ id: 'users.user_status' }),
            dataIndex: 'accountStatus',
            render: (text: string, record: any, tag: any) => {
                let color;
                if (record.accountStatus) {
                    if (record.accountStatus === 'ACTIVE') color = 'green';
                    if (record.accountStatus === 'BLOCKED') color = 'red';
                    return (
                        <>
                            <Tag color={color} key={tag}>
                                {intl.formatMessage({ id: `user.status.${record.accountStatus}` })}
                            </Tag>
                            {record.deletionInitiatedAt != null ?
                                <Tag color={'red'}>
                                    {intl.formatMessage({ id: `user.status.DELETION` })}
                                </Tag> : ''}
                        </>
                    )
                } else {
                    return '-';
                }
            }
        },
        {
            title: intl.formatMessage({ id: 'users.user_type' }),
            dataIndex: 'userType',
            render: (text: string, record: any, tag: any) => {

                const type: 'ADMIN' | 'LECTOR' | ['ADMIN', 'LECTOR'] = record.type

                if (!Array.isArray(type)) {
                    return (
                        <span className='flex flex-wrap gap-5'>
                            {(record.hasAdminRole) ?
                                <Tag color={tagColor['ADMIN']}>
                                    {intl.formatMessage({ id: `user.type.ADMIN` })}
                                </Tag>
                                : null
                            }
                            {(record.hasLectorRole) ?
                                <Tag color={tagColor['LECTOR']}>
                                    {intl.formatMessage({ id: `user.type.LECTOR` })}
                                </Tag>
                                : null
                            }
                        </span>
                    )
                } else {
                    return (
                        <div className={'user-roles'}>
                            {Array.isArray(type) &&
                                Object.values(type).map((element: 'ADMIN' | 'LECTOR') => {
                                    return (
                                        <Tag color={tagColor[element]}>
                                            {intl.formatMessage({ id: `user.type.${element}` })}
                                        </Tag>
                                    )
                                })
                            }
                        </div>
                    )
                }
            }
        },
        {
            key: 'actions',
            render: (text: string, record: any) => {
                const content = <>
                    <NavLink to={`/organizations/${organizationId}/admins/${record.typeUuid}/edit`}>
                        <div className="popover-item">
                            <FormattedMessage id="general.edit" />
                        </div>
                    </NavLink>
                    {isUserSwitchAllowed()
                        ? <div className="popover-item" onClick={() => {
                            switchToUser(record.uuid, record.typeId)
                        }}>
                            <FormattedMessage id="users.switch_user" />
                        </div>
                        : null
                    }
                </>;

                return (
                    <Popover
                        open={record.id === visibleTooltip}
                        content={content}
                        trigger="click"
                        placement="bottomRight"
                        arrowPointAtCenter
                        onOpenChange={(visible) => handleVisibleChange(visible, record.id)}
                    >
                        <div style={{ width: '100%', cursor: 'pointer', textAlign: 'center' }}>
                            <i className="fal fa-ellipsis-v" style={{ fontSize: '16px' }} />
                        </div>
                    </Popover>
                )
            },
        }
    ];

    const isUserSwitchAllowed = () => {
        return (session.active_user_type === 'SUPER_ADMIN')
    };

    const switchToUser = async (userId: number, userTypeId: number) => {
        let errorMessageCode = 'switch_to_user_fail';

        try {
            setVisibleTooltip(null);

            let response = await Jwt.switchUser(userId, userTypeId);

            if (response === true) {
                window.location.assign('/')
            } else {
                message.error(intl.formatMessage({ id: errorMessageCode }));
            }
        } catch (err: any) {
            if (err.message === 'user_switch_not_allowed') {
                errorMessageCode = err.message
            } else if (err.message === 'user_is_not_activated') {
                errorMessageCode = err.message
            }

            message.error(intl.formatMessage({ id: "error." + errorMessageCode }));
        }
    };

    const blockUsers = async () => {
        const parsedValues = {
            organizationType: 'ORGANIZATION',
            organizationId: organizationId,
            users: selectedRowKeys
        };

        try {
            await apiClient.request('/api/v1/users/mass-block', parsedValues, 'POST');

            info({
                content: intl.formatMessage({ id: 'users.block_users_info' }, { amount: selectedRowsLength }),
                icon: <InfoCircleOutlined />,
                cancelText: intl.formatMessage({ id: 'general.close' })
            });
            setReload(true);
        } catch (error) {
            handleError(error);
        }
    };

    const activateUsers = async () => {
        const parsedValues = {
            organizationType: 'ORGANIZATION',
            organizationId: organizationId,
            users: selectedRowKeys
        };

        try {
            await apiClient.request('/api/v1/users/mass-activate', parsedValues, 'POST');

            info({
                content: intl.formatMessage({ id: 'users.activate_users_info' }, { amount: selectedRowsLength }),
                icon: <InfoCircleOutlined />,
                cancelText: intl.formatMessage({ id: 'general.close' })
            });
            setReload(true);
        } catch (error) {
            handleError(error);
        }
    };

    const showConfirm = (value: boolean) => {
        if (value) {
            confirm({
                content: intl.formatMessage({ id: 'users.activate_users' }, { amount: selectedRowKeys.length }),
                icon: <ExclamationCircleOutlined />,
                okText: intl.formatMessage({ id: 'general.continue' }),
                cancelText: intl.formatMessage({ id: 'general.cancel' }),
                onOk() {
                    activateUsers();
                },
            });
        } else {
            confirm({
                content: intl.formatMessage({ id: 'users.block_users' }, { amount: selectedRowKeys.length }),
                icon: <ExclamationCircleOutlined />,
                okText: intl.formatMessage({ id: 'general.continue' }),
                cancelText: intl.formatMessage({ id: 'general.cancel' }),
                onOk() {
                    blockUsers();
                },
            });
        }
    };

    const showConfirmDelete = async () => {
        confirm({
            title: intl.formatMessage({ id: "general.attention" }),
            content: intl.formatMessage({ id: "users.confirm_delete" }),
            icon: <ExclamationCircleOutlined />,
            okText: intl.formatMessage({ id: 'general.continue' }),
            cancelText: intl.formatMessage({ id: 'general.cancel' }),

            onOk() {
                initiateUsersDelete(selectedRowKeys);
            },
        });
    };

    const initiateUsersDelete = async (parsedValues: any) => {
        setIsAdminsLoading(true);

        try {
            await apiClient.request('/api/v1/admin/mass-delete', parsedValues, 'POST');

            setReload(true);

            info({
                content: <div className="delete-success-modal-content">
                    <FormattedMessage id='users.after_initiate_delete_info_1' />
                    <FormattedMessage id='users.after_initiate_delete_info_2' />
                </div>,
                icon: <CheckCircleOutlined />
            });
        } catch (error) {
            handleError(error);
        }
    };

    // ??
    const showConfirmUndelete = async () => {
        undeleteUsers(selectedRowKeys);
    };

    const undeleteUsers = async (parsedValues: any) => {
        setIsAdminsLoading(true);

        try {
            let submitResponse = await apiClient.request('/api/v1/admin/mass-undelete', parsedValues, 'POST');

            setReload(true);

            if (submitResponse.status_code > 299) message.error(intl.formatMessage({ id: "general.submit_error" }));

            message.success(intl.formatMessage({ id: "users.undelete_success" }))
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: "general.submit_error" }));    // TODO: Izmantot notification
        }
    };

    const loadOptionsTimeOut = debounce(function (text: string) {
        const searchText = text.replace(/\s+/g, '');
        if (text.length === 0 || searchText.length >= 3) {
            setSearchString(searchText);
            setReload(true)
        }
    }, 800);

    return (
        <>
            <DefaultLayout.PageLayout>
                <DefaultLayout.PageHeader
                    loading={isOrganizationLoading}
                    breadcrumb={[{ name: intl.formatMessage({ id: 'general.organizations' }), path: '/organizations' }]}
                    title={(organization.name + ' ' + intl.formatMessage({ id: 'general.users' }))}
                />
                <DefaultLayout.PageContent>
                    <FlexRow
                        right={
                            <Search
                                allowClear
                                placeholder={intl.formatMessage({ id: "general.search" })}
                                onChange={value => loadOptionsTimeOut(value.target.value)}
                            />
                        }
                        left={
                            <>
                                <NavLink to={`/organizations/${organizationId}/admins/add`}>
                                    <Button type="primary">
                                        <PlusOutlined /> <FormattedMessage id="users.add_admin" />
                                    </Button>
                                </NavLink>
                                <Button type="primary" onClick={() => setShowUsersFilterModal(true)}>
                                    <FormattedMessage id="general.filter" />
                                </Button>
                            </>
                        }
                    />
                    {/* <FilterBar
          filter={filter}
          ownerId={organizationId}
          organizationalUnitLabels={organizationalUnitLabels}
          customerLabel={organization.name}
          organizationalUnit={organizationalUnitIds}
          setFilterBarValues={setFilterBarValues}
          clearFilter={clearFilter}
          clearAllFilter={clearAllFilter}
        /> */}
                    {organizationId &&
                        <Table
                            columns={columns}
                            url={'/api/v1/admin/list/' + organizationId}
                            setReload={setReload}
                            reload={reload}
                            rowKey={'typeUuid'}
                            selectedRowKeys={selectedRowKeys}
                            setSelectedRowKeys={setSelectedRowKeys}
                            setTotalRecords={setTotalRecords}
                            filter={filter}
                            search={searchString}
                            scroll={{ x: 800 }}
                        />
                    }

                </DefaultLayout.PageContent>
                <DefaultLayout.PageFooter
                    left={
                        <>
                            <div>
                                {selectedRowKeys.length}
                                <FormattedMessage id='users.rows_selected' />
                            </div>
                            <Button
                                onClick={() => selectedRowKeys.length !== 0 ? setShowScenariosModal(true) : message.error(intl.formatMessage({ id: "error.select_users" }))}>
                                <i className="fal fa-envelope" style={{ paddingRight: '8px' }} />
                                <FormattedMessage id='users.send_email_users' />
                            </Button>
                            <Button onClick={() => showConfirm(true)}>
                                <Space size={8}>
                                    <i style={{ color: '#52C419' }} className="fal fa-check-circle" />
                                    <FormattedMessage id='general.activate' />
                                </Space>
                            </Button>
                            <Button onClick={() => showConfirm(false)}>
                                <Space size={8}>
                                    <i style={{ color: '#F5222D' }} className="fal fa-times-circle" />
                                    <FormattedMessage id='general.block' />
                                </Space>

                            </Button>
                            <Button onClick={() => showConfirmDelete()}>
                                <Space size={8}>
                                    <i style={{ color: '#F5222D' }} className="fal fa-trash" />
                                    <FormattedMessage id='users.delete' />
                                </Space>

                            </Button>
                            <Button onClick={() => showConfirmUndelete()}>
                                <Space size={8}>
                                    <i style={{ color: '#F5222D' }}
                                        className="fal fa-trash-restore" />
                                    <FormattedMessage id='users.undelete' />
                                </Space>

                            </Button>
                        </>
                    }
                />
            </DefaultLayout.PageLayout>
            <FilterModal
                visible={showUsersFilterModal}
                onCancel={setShowUsersFilterModal}
                modules={{
                    organizationalUnitId: { visible: true, id: organizationId },
                    accountStatus: { visible: true, default: ['ACTIVE'] }
                }}
                setFilter={setFilter}
                load={setReload}
            />
            <SendEmailModal
                visible={showScenariosModal}
                onCancel={setShowScenariosModal}
                organizationId={organizationId}
                organizationType={organizationType}
                users={selectedRowKeys}
            />


            {/*<SendEmailModal*/}
            {/*    ownerId={organizationId}*/}
            {/*    ownerType={organizationType}*/}
            {/*    users={selectedUsers}*/}
            {/*    isUserModal={true}*/}
            {/*    visible={showScenariosModal}*/}
            {/*    onCancel={() => setShowScenariosModal(false)}*/}
            {/*    afterSubmit={() => setShowScenariosModal(false)}*/}
            {/*    selectAll={selectAllFiltered}*/}
            {/*    getRecords={getRecords}*/}
            {/*    resourceUrl={resourceUrl}*/}
            {/*/>*/}
        </>
    )
};

export default connect(mapStateToProps)(AdminList);
