import React, {useState, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {changeUserTableFilter} from 'store/actions/saveUserTableFilter';
import {NavLink, useNavigate, useParams} from 'react-router-dom';
import {config} from 'config/config';
import DefaultLayout from "components/DefaultLayout";
import useHandleError from "utils/useHandleError";
import {Search, Table} from "ui";
import {Modal, Popover} from "antd";
import {Button} from "ui";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {useRules} from "../../../hooks/Rules/useRules";

const RulesList: React.FC<any> = ({}) => {
    const {deleteRule} = useRules();
    const [reload, setReload] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [visibleTooltip, setVisibleTooltip] = useState<number | null>(null);

    const intl = useIntl();
    const [handleError] = useHandleError();
    const {confirm} = Modal;

    const handleVisibleChange = (visible: boolean, recordId: number) => {
        if (visible) {
            setVisibleTooltip(recordId);
        } else {
            setVisibleTooltip(null);
        }
    };

    const columns = [
        {
            title: intl.formatMessage({id: 'general.title'}),
            sorter: true,
            dataIndex: 'title',
            key: 'title',
            render: (title: string, record: any) => {
                return (
                    <NavLink to={`/rules/${record.id}`}>
                        {title}
                    </NavLink>
                )
            }
        },
        {
            title: intl.formatMessage({ id: 'general.actions' }),
            render: (_text: string, record: any) => {
                const content = (
                    <>
                        <NavLink to={`/rules/${record.id}`}>
                            <div className="popover-item">
                                <FormattedMessage id="general.edit"/>
                            </div>
                        </NavLink>
                        <div className="popover-item" onClick={() => {
                            warnDeletion(record.id);
                            setVisibleTooltip(null);
                        }}>
                            <FormattedMessage id="general.delete"/>
                        </div>
                    </>
                );

                return (
                    <Popover
                        open={record.id === visibleTooltip}
                        content={content}
                        trigger="click"
                        placement="bottomRight"
                        arrowPointAtCenter
                        onOpenChange={(visible) => handleVisibleChange(visible, record.id)}
                    >
                        <div style={{width: '100%', cursor: 'pointer', textAlign: 'center'}}>
                            <i className="fal fa-ellipsis-v" style={{fontSize: '16px'}}/>
                        </div>
                    </Popover>
                )
            },
        },
    ];

    const warnDeletion = async (id: number) => {
        confirm({
            title: intl.formatMessage({id: 'general.attention'}),
            content: intl.formatMessage({id: 'courses.delete_program'}),
            icon: <ExclamationCircleOutlined/>,
            okText: intl.formatMessage({id: 'general.yes'}),
            cancelText: intl.formatMessage({id: 'general.no'}),

            onOk() {
                deleteRuleFuncton(id);
            }
        });
    }

    const deleteRuleFuncton = async (id: number) => {
        try {
            await deleteRule(id)
            setReload(true)
        } catch (error) {
            handleError(error);
        }
    }

    return (
        <DefaultLayout.PageLayout>
            <DefaultLayout.PageHeader title={intl.formatMessage({id: 'general.rules'})}/>
            <DefaultLayout.PageContent>
                <div className="search-bar-row">
                    <NavLink to='/rules/create'>
                        <Button type='primary'>
                            <FormattedMessage id='rule.add_new_rule'/>
                        </Button>
                    </NavLink>
                    <div className="search">
                        <Search setSearchString={setSearchString} setReload={setReload}/>
                    </div>
                </div>
                <Table
                    columns={columns}
                    url={'/api/v1/rules'}
                    setReload={setReload}
                    reload={reload}
                    rowSelection={false}
                    rowKey={'id'}
                    search={searchString}
                    scroll={{x: true}}
                />
            </DefaultLayout.PageContent>
        </DefaultLayout.PageLayout>
    );
};

export default RulesList;