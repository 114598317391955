import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, message, Input, Modal, Slider, InputNumber, Tooltip, Row, Col } from 'antd';
import Spinner from 'components/Spinner';
import { Switch, Select } from 'components/Form';
import coursesApiClient from 'utils/coursesApiClient';
import LanguageSelect from 'components/LanguageSelect';
import {FileUpload, ScormResultSelect, FileProperties} from 'ui';

interface ActivityModalInterface {
    visible: boolean;
    setVisible: any;
    onCancel: any;
    courseId: any;
    reload: Function;
    activity: {
        activityId: number;
        language: string;
        selected: boolean;
    } | null;
    setSelectActivity: any
}

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
};

const ActivityModal: React.FC<ActivityModalInterface> = ({ visible, setVisible, onCancel, courseId, reload, activity, setSelectActivity }) => {
    const [isModalLoading, setIsModalLoading] = useState(false);
    const [passRate, setPassRate] = useState(50);
    const [activityData, setActivityData] = useState<any>();
    const [isVersionsLoading, setIsVersionsLoading] = useState(false);
    const [versionList, setVersionList] = useState<any>([]);
    const [fileUploadSettings, setFileUploadSettings] = useState({
        learningFileUpload: false,
        testFileUpload: false,
        showLearningFileProperties: false,
        showTestFileProperties: false
    });

    const intl = useIntl();
    const [form] = Form.useForm();

    const { TextArea } = Input;

    useEffect(() => {
        if (activity?.selected) {
            loadActivity();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activity])

    const loadActivity = async (version: string = '') => {
        setIsModalLoading(true);

        if (!visible) {
            setVisible();
        }

        let url = `/api/v1/courses/activities/${activity?.activityId}`;

        url = url + '?language=' + activity?.language

        if (version) {
            url = url + '&version=' + version
        }

        try {
            let response = await coursesApiClient.request(url, [], 'GET');
            const values = response.learningActivity;
            setActivityData(values);

            form.setFieldsValue({
                title: values.title,
                language: activity?.language,
                status: values.status === 'ONLINE' ? true : false,
                minutes: values.minutes,
                sequencePriority: values.sequencePriority,
                expectedLearningResult: values.expectedLearningResult,
                expectedTestResult: values.expectedTestResult,
                passRate: parseInt(values.passRate)
            });
            setPassRate(parseInt(values.passRate));

            setFileUploadSettings({ ...fileUploadSettings, learningFileUpload: !!values.hasLearningFile, showLearningFileProperties: !!values.hasLearningFile, showTestFileProperties: !!values.hasTest, testFileUpload: !!values.hasTest });

            if (activity) {
                getVersionList(activity?.activityId, activity?.language);
            }
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: "error.data_load" }));
        } finally {
            setIsModalLoading(false);
        }
    }

    useEffect(() => {
        if (visible) {
            form.setFieldsValue({
                status: false,
                passRate: 50
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible])

    const getVersionList = async (id: number, language: string) => {
        try {
            setIsVersionsLoading(true);
            let versionList = await coursesApiClient.request(`/api/v1/courses/activities/${id}/version-list/${language}`, {}, 'GET');
            setVersionList(versionList);
            setIsVersionsLoading(false);
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: "error.data_load" }));
        }
    };

    const submitForm = async (values: any) => {
        setIsModalLoading(true);
        let bodyFormData = new FormData();

        try {
            if (values.testFile && fileUploadSettings.testFileUpload) {
                bodyFormData.append('testFile', values.testFile[0].originFileObj);
                bodyFormData.append('expectedTestResult', values.expectedTestResult);
            }
            if (values.learningFile && fileUploadSettings.learningFileUpload) {
                bodyFormData.append('learningFile', values.learningFile[0].originFileObj);
                bodyFormData.append('expectedLearningResult', values.expectedLearningResult);
            }
            bodyFormData.append('courseId', courseId);
            bodyFormData.append('status', values.status ? 'ONLINE' : 'OFFLINE');
            bodyFormData.append('title', values.title);
            bodyFormData.append('language', values.language);
            bodyFormData.append('minutes', values.minutes);
            bodyFormData.append('passRate', passRate + '');
            bodyFormData.append('sequencePriority', values.sequencePriority);

            if (activity?.selected) {
                await coursesApiClient.request('/api/v1/courses/activities/update/' + activity.activityId, bodyFormData, 'POST', true, true);
            } else {
                await coursesApiClient.request('/api/v1/courses/activities/store', bodyFormData, 'POST', true, true);
            }

            reload();
            form.resetFields();

        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: "error.data_load" }));
        } finally {
            setIsModalLoading(false);
            onCancel();
        }
    }

    const close = () => {
        setFileUploadSettings({ ...fileUploadSettings, showLearningFileProperties: false, showTestFileProperties: false });
        setSelectActivity({ ...activity, activityId: 0, language: '', selected: false });
        onCancel();
    }

    return (
        <Modal
            className="add-new-activity"
            open={visible}
            width={610}
            onCancel={() => close()}
            title={intl.formatMessage({ id: "courses.add_new_activity" })}
            okText={intl.formatMessage({ id: 'general.submit' })}
            cancelText={intl.formatMessage({ id: 'general.back' })}
            onOk={form.submit}
            confirmLoading={isModalLoading}
            destroyOnClose
        >
            <Spinner spinning={isModalLoading}>
                <Form form={form} onFinish={submitForm} preserve={false}>
                    <Form.Item
                        name="title"
                        label={intl.formatMessage({ id: "courses.activity_name" })}
                        rules={[{ required: true }]}
                        labelCol={formItemLayout.labelCol}
                        wrapperCol={formItemLayout.wrapperCol}
                        style={{ marginBottom: 12 }}
                    >
                        <TextArea style={{ height: 32, paddingTop: 5 }} />
                    </Form.Item>

                    <LanguageSelect
                        name='language'
                        label={intl.formatMessage({ id: 'general.language' })}
                    />
                    <FileUpload onUpload={()=> setFileUploadSettings({ ...fileUploadSettings, learningFileUpload: true })} layout={formItemLayout} name="learningFile" label="courses.learning_file" buttonLabel='general.upload_files' />
                    {fileUploadSettings.learningFileUpload &&
                        <ScormResultSelect label='courses.expected_learning_result' layout={formItemLayout} name='expectedLearningResult' customRules={[{ required: fileUploadSettings.learningFileUpload, message: intl.formatMessage({ id: "validation.field_required" }) }]} />
                    }
                    {fileUploadSettings.showLearningFileProperties && activityData &&
                        <FileProperties layout={formItemLayout} fileProperties={{
                            title: activityData.learningFileTitle,
                            size: activityData.learningFileSize,
                            titleLabel: 'courses.learning_file_title',
                            sizeLabel: 'courses.learning_file_size'
                        }} />
                    }
                    <FileUpload onUpload={()=> setFileUploadSettings({ ...fileUploadSettings, testFileUpload: true })} layout={formItemLayout} name="testFile" label="courses.test_file" buttonLabel='general.upload' />
                    {fileUploadSettings.testFileUpload &&
                        <ScormResultSelect label='courses.expected_test_result' layout={formItemLayout} name='expectedTestResult' customRules={[{ required: fileUploadSettings.testFileUpload, message: intl.formatMessage({ id: "validation.field_required" }) }]} />
                    }
                    {fileUploadSettings.showTestFileProperties && activityData &&
                        <FileProperties layout={formItemLayout} fileProperties={{
                            title: activityData.testFileTitle,
                            size: activityData.testFileSize,
                            titleLabel: 'courses.test_file_title',
                            sizeLabel: 'courses.test_file_size'
                        }} />
                    }
                    <Switch
                        name="status"
                        label={
                            <>
                                {intl.formatMessage({ id: 'general.status' })}
                                <Tooltip title={intl.formatMessage({ id: 'courses.activity_status_explanation' })}>
                                    <i className='fal fa-question-circle header-item' />
                                </Tooltip>
                            </>
                        }
                        isFormItem
                        hasDefaultLayout
                    />
                    {fileUploadSettings.testFileUpload || fileUploadSettings.showTestFileProperties ?
                        <Row>
                            <Col span={formItemLayout.labelCol.span} className='pass-rate-label'>
                                <FormattedMessage id='general.pass_rate' />
                            </Col>
                            <Col span={formItemLayout.wrapperCol.span}>
                                <div className='pass-rate-input' >
                                    <Form.Item name="passRate" label=' '>
                                        <Slider
                                            min={0}
                                            max={100}
                                            value={passRate}
                                            step={1}
                                            onChange={(value: any) => { form.setFieldsValue({ passRate: value }); setPassRate(value); }}
                                            style={{ width: 100 }}
                                        />
                                    </Form.Item>
                                    <Form.Item name="passRate" label=' ' >
                                        <InputNumber
                                            min={0}
                                            max={100}
                                            step={1}
                                            value={passRate}
                                            onChange={(value: any) => setPassRate(value)}
                                        />
                                    </Form.Item>
                                </div>
                            </Col>
                        </Row>
                        : null
                    }
                    <Form.Item
                        {...formItemLayout}
                        label={intl.formatMessage({ id: "general.minutes" })}
                        name="minutes"
                        rules={[{ required: true, message: intl.formatMessage({ id: "validation.field_required" }) }]}
                    >
                        <InputNumber min={1} />
                    </Form.Item>
                    <Form.Item
                        {...formItemLayout}
                        label={
                            <span>
                                {intl.formatMessage({ id: 'courses.sequence_priority' })}
                                <Tooltip title={intl.formatMessage({ id: 'courses.sequence_priority_info' })}>
                                    <i className='fal fa-question-circle header-item' />
                                </Tooltip>
                            </span>
                        }
                        name="sequencePriority"
                        rules={[{ required: true, message: intl.formatMessage({ id: "validation.field_required" }) }]}
                    >
                        <Input style={{ borderRadius: 8 }} />
                    </Form.Item>
                    <Select
                        loading={isVersionsLoading}
                        label={intl.formatMessage({ id: "general.version" })}
                        name='version'
                        onChange={(version: string) => loadActivity(version)}
                        manualOptions={{ ...versionList.versionList }}
                    />
                </Form>
            </Spinner>
        </Modal>
    );
}

export default ActivityModal;
