import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Col, Form, Input, message, Row} from "antd";
import {Button} from "ui";
import './styles.scss';


interface TagComponentInterface {
    customItemLayout?: { labelCol?: { span: number }, wrapperCol?: { span: number } },
    hasDefaultLayout?: boolean;
    label: any;
    className?: string
    tags: string;
    setTags: React.Dispatch<React.SetStateAction<string>>
    disabled?: boolean;
}


const TagComponent: React.FC<TagComponentInterface> = ({
                                                           label,
                                                           className,
                                                           customItemLayout,
                                                           tags,
                                                           setTags,
                                                           disabled = false
                                                       }) => {

    const [newTag, setNewTag] = useState('');
    const [inputStatus, setInputStatus] = useState<any>()
    let formItemLayout = customItemLayout || {}
    const intl = useIntl();

    if (!customItemLayout) {
        formItemLayout = {
            labelCol: {span: 8},
            wrapperCol: {span: 8}
        }
    }

    const renderTags = (tag: string) => {

        if (!tag) {
            return;
        }

        return <span className='tag'>{tag} <i onClick={() => removeTag(tag)} className="fa-duotone fa-xmark"></i></span>
    }


    const removeTag = (tag: string) => {
        const tagsString = tags.replace(tag + ';', '');
        setTags(tagsString)
    }

    const addTag = () => {
        if (tags.includes(newTag)) {
            message.error(intl.formatMessage({id: 'program.tag_exists'}))
            setInputStatus("error")
            return;
        }

        if (newTag) {
            setTags(`${tags}${newTag};`)
            setNewTag('')
        }
    }

    //

    return (
        <>
            <Form.Item
                {...formItemLayout}
                label={label}
                className={className}
            >
                <div className='tags-form-wrapper'>
                    {tags && tags.split(';').map((tag: string) => renderTags(tag))}
                </div>
            </Form.Item>
            <Row>
                <Col offset={formItemLayout?.labelCol?.span} span={formItemLayout.wrapperCol?.span}>
                    <div className='tag-input-wrapper'>
                        <Input disabled={disabled} onInput={(value: any) => !value.target.value.length && setInputStatus(undefined)}
                               status={inputStatus} value={newTag}
                               onChange={(value: any) => setNewTag(value.target.value)}/>
                        <Button disabled={disabled} onClick={() => addTag()}>
                            <FormattedMessage id='general.add'/>
                        </Button>
                    </div>
                </Col>
            </Row>

        </>
    )
};

export default TagComponent;