import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Badge, Form, Popover, Progress, Tag } from 'antd';
import { RequestSelect, Switch } from 'components/Form';
import coursesApiClient from 'utils/coursesApiClient';
import apiClient from 'utils/apiClient';
import Spinner from 'components/Spinner';
import { connect } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import { Search, Table, TableSelectedRowCount, Button } from 'ui';
import useHandleError from 'utils/useHandleError';
import generalHelpers from 'utils/generalHelpers';
import { FilterModal, SendEmailModal } from 'components/Modals';
import DefaultLayout from '../../DefaultLayout';
import './styles.scss';
import moment from 'moment';
import { config } from '../../../config/config';
import ResultModal from './ResultModal';
import TermModal from './TermModal';
import ActivityStatusModal from './ActivityStatusModal';
import FlexRow from '../../FlexRow';
import useQueryApiClient from 'utils/useApiClient';
import FileDownload from 'js-file-download';

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale,
        session: state.session,
    };
};

interface StudentsInterface {
    program: any;
    locale?: any;
    session?: any;
    launchButton: HTMLElement;
}

const tagColors = {
    NOT_STARTED: 'gold',
    IN_PROGRESS: 'blue',
    RETURNED: 'blue',
    COMPLETED: 'green',
    PASSED: 'green',
    MISSED: 'red',
    CANCELLED: 'red',
    FAILED: 'red',
    PLANNED: 'geekblue',
    ATTENDED: 'green',
};

const Students: React.FC<StudentsInterface> = ({ locale, session, program, launchButton }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [reload, setReload] = useState(false);
    const [search, setSearch] = useState('');
    const [visibleTooltip, setVisibleTooltip] = useState<number | null>(null);
    const [showUsersFilterModal, setShowUsersFilterModal] = useState(false);
    const [filter, setFilter] = useState('');
    const [buttonLoad, setButtonLoad] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<[]>([]);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [organizationsLoaded, setOrganizationsLoaded] = useState('');
    const [selectedUser, setSelectedUser] = useState('');
    const [showResultModal, setShowResultModal] = useState(false);
    const [showStudentFilterModal, setShowStudentFilterModal] = useState(false);
    const [activity, setActivity] = useState<{
        enrollmentId: number;
        activityId: number;
        fullName: string;
        title: string;
        type: string;
    }>({
        enrollmentId: 0,
        activityId: 0,
        fullName: '',
        type: '',
        title: '',
    });
    const [showActivityStatusModal, setShowActivityStatusModal] = useState(false);
    const [form] = Form.useForm();
    const [selectedCustomerId, setSelectedCustomerId] = useState<string>();
    const [dataSource, setDataSource] = useState<any>([]);
    const [scenarioUpdate, setScenarioUpdate] = useState(false);
    const [showMailModal, setShowMailModal] = useState(false);
    const [showTermModal, setShowTermModal] = useState(false);
    const [userTypeIds, setUserTypeIds] = useState<any>([]);
    const [userSync, setUserSync] = useState<boolean>(false);

    useEffect(() => {
        if (selectedCustomerId === undefined) {
            form.setFieldsValue({
                organizationalUnits: undefined,
                departments: undefined,
            });
        }
    }, [selectedCustomerId]);

    const loadData = async (expanded: boolean, record: any) => {
        if (expanded) {
            setScenarioUpdate(true);
            try {
                const response = await coursesApiClient.request(
                    `/api/v1/courses/learning-program/${programId}/students/${record.enrollmentId}/activities`,
                    [],
                    'GET'
                );
                // record
                let key: any = Object.entries(dataSource).filter(
                    (el: any) => el[1].enrollmentId === record.enrollmentId
                );

                dataSource[key[0][0]].data = response.data;

                setDataSource(dataSource);

                setScenarioUpdate(false);
            } catch (e) {}
        }
    };

    const defaultExpandable = {
        onExpand: (expanded: boolean, record: any) => loadData(expanded, record),
        expandedRowRender: (record: any) => {
            if (record?.data) {
                return Object.values(record.data).map((activity: any) => {
                    let title = '';
                    if (activity.type === 'ACTIVITY') {
                        title =
                            activity.title[locale] ??
                            activity.title[Object.keys(activity.title)[0]];
                    } else {
                        title = activity.activityTitle ? activity.activityTitle : activity.title;
                    }

                    return (
                        <div
                            onClick={() =>
                                openResultsModal(
                                    record.enrollmentId,
                                    activity.id,
                                    record.name + ' ' + record.surname,
                                    title,
                                    activity.type
                                )
                            }
                            className={'flex justify-between student-result-wrapper pointer'}
                        >
                            <span>{title}</span>
                            <span>{tagBuilder(activity.status)}</span>
                        </div>
                    );
                });
            } else {
                return <Spinner className={'ml-2'} size="small" spinning={true} />;
            }
        },
    };

    const [handleError] = useHandleError();
    const params = useParams();
    const programId = params.id;
    const intl = useIntl();

    useEffect(() => {
        managingOrganizations();
    }, []);

    const handleVisibleChange = (visible: boolean, recordId: number) => {
        if (visible) {
            setVisibleTooltip(recordId);
        } else {
            setVisibleTooltip(null);
        }
    };

    const openResultsModal = (
        enrollmentId: number,
        activityId: number,
        fullName: string,
        title: string,
        type: string
    ) => {
        setShowResultModal(true);
        setActivity({
            enrollmentId: enrollmentId,
            activityId: activityId,
            fullName: fullName,
            type: type,
            title: title,
        });
    };

    const addUsers = async (usersToEnroll: string) => {
        setButtonLoad(true);
        try {
            await coursesApiClient.request(
                `/api/v1/learning-program/${programId}/enroll?${usersToEnroll}`,
                [],
                'POST'
            );
            setReload(true);
        } catch (error) {
            handleError(error);
        } finally {
            setButtonLoad(false);
        }
    };

    const getDeadline = (record: any) => {
        if (program.status === 'DRAFT') {
            return '-';
        }
        let enrollDate;

        switch (program.type) {
            case 'TIMED_EVENT':
                let deadline;

                if (moment(record.createdAt) > moment(program.beginDate)) {
                    enrollDate = moment(record.createdAt);
                } else {
                    enrollDate = moment(program.beginDate);
                }

                if (
                    moment(enrollDate).add(program.maxCompletionTerm, 'days') >
                    moment(program.endDate)
                ) {
                    deadline = moment(enrollDate).add(program.termLimitValue, 'days');
                } else {
                    deadline = moment(enrollDate).add(program.maxCompletionTerm, 'days');
                }

                return deadline.format(config.defaultDateFormat);
            case 'CERTIFICATION':
                if (moment(record.createdAt) > moment(program.beginDate)) {
                    enrollDate = moment(record.createdAt);
                } else {
                    enrollDate = moment(program.beginDate);
                }

                return moment(enrollDate)
                    .add(program.maxCompletionTerm, 'days')
                    .format(config.defaultDateFormat);
        }
    };

    const columns = [
        {
            title: intl.formatMessage({ id: 'general.name' }),
            sorter: true,
            key: 'name',
            dataIndex: 'name',
        },
        {
            title: intl.formatMessage({ id: 'general.surname' }),
            sorter: true,
            key: 'surname',
            dataIndex: 'surname',
        },
        {
            title: intl.formatMessage({ id: 'general.position' }),
            sorter: true,
            dataIndex: 'position',
            key: 'position',
        },
        {
            title: intl.formatMessage({ id: 'general.organizational_unit' }),
            sorter: true,
            dataIndex: 'organizationalUnit',
            key: 'organizationalUnit',
        },
        {
            title: intl.formatMessage({ id: 'general.organization' }),
            sorter: true,
            dataIndex: 'organization',
            key: 'organization',
        },
        // {
        //     title: intl.formatMessage({id: 'general.progress'}),
        //     render: (_text: string, record: any) => {
        //         return (
        //             <div>
        //                 <Progress showInfo={false} trailColor={'#D9D9D9'} strokeColor={'#52c41a'}
        //                           percent={((record.passed + record.passedLecture) / (record.totalLecture + record.total)) * 100}/>
        //             </div>
        //         )
        //     },
        // },
        {
            title: intl.formatMessage({ id: 'general.status' }),
            sorter: true,
            key: 'actual_status',
            render: (_text: string, record: any) =>
                tagBuilder(record.actualStatus, !!record.isCancelled),
        },
        {
            title: intl.formatMessage({ id: 'general.deadline' }),
            sorter: true,
            dataIndex: 'deadline',
            key: 'current_deadline',
            render: (deadline: string, record: any) =>
                !!deadline
                    ? moment(deadline).format(config.defaultDateFormat)
                    : getDeadline(record),
        },
        {
            title: intl.formatMessage({ id: 'general.is_completed' }),
            sorter: true,
            dataIndex: 'completedAt',
            key: 'completed_at',
            render: (isCompleted: string) =>
                !!isCompleted ? moment(isCompleted).format(config.defaultDateFormat) : null,
        },
        {
            title: intl.formatMessage({ id: 'general.valid_until' }),
            sorter: true,
            dataIndex: 'expirationDate',
            key: 'valid_to',
            render: (expirationDate: string) =>
                !!expirationDate ? moment(expirationDate).format(config.defaultDateFormat) : '-',
        },
        {
            title: intl.formatMessage({ id: 'general.last_activity' }),
            sorter: true,
            dataIndex: 'lastActivity',
            key: 'last_activity',
            render: (lastActivity: string) =>
                !!lastActivity ? moment(lastActivity).format(config.defaultDateFormat) : null,
        },
        {
            title: intl.formatMessage({ id: 'general.enrollment_type' }),
            dataIndex: 'enrollmentType',
            render: (enrollmentType: any, record: any) => {
                switch (enrollmentType) {
                    case 'SELF_ENROLLMENT':
                    case 'INVITE_ENROLLMENT':
                    case 'MANUAL_ENROLLMENT':
                        return intl.formatMessage({
                            id: 'program.enrollment.' + enrollmentType.toLowerCase(),
                        });
                    case 'AUDIENCE_ENROLLMENT':
                        if (!record.audienceEnrollment) {
                            return;
                        }
                        return generalHelpers.replaceAll(record.audienceEnrollment, ',', ';') + ';';
                    case 'ENROLLMENT_CANCELLED':
                        return '-';
                }
            },
        },
        {
            title: intl.formatMessage({ id: 'program.recertification' }),
            sorter: true,
            dataIndex: 'recertification',
            key: 'recertification',
            render: (_text: string, record: any) => {
                return (
                    <Switch
                        onChange={() => handleSwitch([record.enrollmentId])}
                        checked={!!record.recertification}
                    />
                );
            },
        },
    ];

    const handleSwitch = async (ids: number[]) => {
        try {
            setScenarioUpdate(true);

            await coursesApiClient.request(
                `/api/v1/courses/learning-program/${programId}/update-recertification`,
                { enrollmentIds: ids },
                'POST'
            );

            setScenarioUpdate(false);
            setReload(true);
        } catch (error) {
            handleError(error);
        }
    };

    const cancelEnrollment = async (ids: number[]) => {
        try {
            await coursesApiClient.request(
                `/api/v1/courses/learning-program/${programId}/un-enroll`,
                { enrollmentIds: ids },
                'POST'
            );
            setReload(true);
        } catch (error) {
            handleError(error);
        } finally {
        }
    };

    const reEnroll = async (ids: number[]) => {
        try {
            await coursesApiClient.request(
                `/api/v1/courses/learning-program/${programId}/re-enroll`,
                { enrollmentIds: ids },
                'POST'
            );
            setReload(true);
        } catch (error) {
            handleError(error);
        } finally {
        }
    };

    const managingOrganizations = async () => {
        setIsLoading(true);
        if (
            session.active_user_type === 'CUSTOMER_ADMIN' ||
            session.active_user_type === 'SUPERVISION'
        ) {
            setOrganizationsLoaded(session.organization.organization_id);
            setIsLoading(false);
            return;
        }

        try {
            const organizations = await apiClient.request(
                '/api/v1/organizations/options/managing-organizations',
                [],
                'GET'
            );
            setOrganizationsLoaded(Object.keys(organizations.customers).toString());
        } catch (error) {
            handleError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const addUSerToProgram = async () => {
        try {
            await coursesApiClient.request(
                `/api/v1/courses/learning-program/${programId}/enrollment`,
                {
                    userTypeId: selectedUser,
                },
                'POST'
            );
            setReload(true);
        } catch (error) {
            handleError(error);
        }
    };

    const tagBuilder = (
        status:
            | 'NOT_STARTED'
            | 'IN_PROGRESS'
            | 'COMPLETED'
            | 'PASSED'
            | 'MISSED'
            | 'CANCELLED'
            | 'FAILED'
            | 'RETURNED'
            | null,
        isCancelled = false
    ) => {
        let tagStatus = status ? status : 'NOT_STARTED';

        if (status !== 'COMPLETED' && isCancelled) {
            tagStatus = 'CANCELLED';
        }

        return (
            <Tag color={tagColors[tagStatus]}>
                {intl.formatMessage({
                    id:
                        status === 'RETURNED'
                            ? 'courses.topic_status.submission_returned'
                            : `general.${tagStatus.toLowerCase()}`,
                })}
            </Tag>
        );
    };

    const parseMailingData: any = () => {
        let data: any = [];
        userTypeIds.map((el: any) => {
            data.push({
                userTypeId: el.userTypeId,
                student_deadline: !!el.deadline
                    ? moment(el.deadline).format(config.defaultDateFormat)
                    : getDeadline(el),
                program_title: program.title,
            });
        });

        return data;
    };
    const generateTitle = () => {
        return `${
            programId && program
                ? program.title[locale]
                    ? program.title[locale]
                    : program.title[Object.keys(program.title)[0]]
                : 'export'
        }_${moment().format('YYYY-MM-DD')}.xlsx`;
    };

    const { fetch: handleExport } = useQueryApiClient({
        request: {
            disableOnMount: true,
            method: 'GET',
            multipart: true,
            url: `/api/v1/courses/learning-program/${programId}/students/export`,
        },
        onSuccess: (response) => {
            FileDownload(response, generateTitle());
        },
    });

    useQueryApiClient({
        request: {
            disableOnMount: config.api.environment !== 'development',
            url: `/api/v1/courses/learning-program/${programId}/students/status`,
        },
        onSuccess: (response) => {
            setUserSync(response.data);
        },
    });

    return (
        <>
            <Spinner spinning={isLoading}>
                <FlexRow
                    left={
                        <>
                            <Button
                                disabled={
                                    !(
                                        program?.visualPermissions?.edit ||
                                        program?.visualPermissions?.admin
                                    )
                                }
                                loading={buttonLoad}
                                type="primary"
                                onClick={() => setShowUsersFilterModal(true)}
                            >
                                <FormattedMessage id="campaign.enroll" />
                            </Button>
                            {organizationsLoaded && (
                                <>
                                    <RequestSelect
                                        disabled={
                                            !(
                                                program?.visualPermissions?.edit ||
                                                program?.visualPermissions?.admin
                                            )
                                        }
                                        isForm={false}
                                        customLayout={true}
                                        selectClassName="request-select"
                                        url={`api/v1/edu/users/search-student`}
                                        param={[`customerId=${organizationsLoaded}&key=id&accountStatus=ACTIVE`]}
                                        onChange={(typeUuid: string) => setSelectedUser(typeUuid)}
                                        column
                                        showSearch
                                    />
                                    <Button
                                        disabled={
                                            !(
                                                program?.visualPermissions?.edit ||
                                                program?.visualPermissions?.admin
                                            )
                                        }
                                        type="primary"
                                        onClick={() => addUSerToProgram()}
                                    >
                                        <FormattedMessage id="general.add" />
                                    </Button>
                                </>
                            )}
                            {config.api.environment === 'development' ? (
                                <Badge status={userSync ? 'processing' : 'success'} />
                            ) : null}
                        </>
                    }
                    right={
                        <>
                            <Button type="default" onClick={() => handleExport()}>
                                <FormattedMessage id="general.export" />
                            </Button>
                            <Button type="default" onClick={() => setShowStudentFilterModal(true)}>
                                <FormattedMessage id="general.filter" />
                            </Button>
                            <Search setReload={setReload} setSearchString={setSearch} />
                        </>
                    }
                />
                <div className={'table-filter-modal'}>
                    <FilterModal
                        visible={showStudentFilterModal}
                        onCancel={setShowStudentFilterModal}
                        title={'general.filter_by'}
                        okProp={'campaign.form.filter_title'}
                        modules={{
                            programStatus: { mode: 'multiple', visible: true },
                            customerSelect: {
                                mode: 'multiple',
                                visible: generalHelpers.isAdmin(session.active_user_type),
                            },
                            organizationalUnitId: { mode: 'multiple', visible: true },
                            departmentId: { mode: 'multiple', visible: true },
                            recertification: { default: [1, 0], visible: true },
                            expirationDate: { visible: true },

                            // activity: {mode: 'multiple', visible: true},
                            // lecture: {mode: 'multiple', visible: true},
                            // activityStatus: {mode: 'multiple', visible: true},
                        }}
                        setFilter={setFilter}
                        filter={filter}
                        load={setReload}
                        locale={locale}
                    />
                </div>

                <div className={'program-student-table'}>
                    {program && (
                        <Table
                            rowClassName={(record: any) =>
                                record.accountStatus === 'BLOCKED' ? 'table-row-red' : ''
                            }
                            expandable={defaultExpandable}
                            columns={
                                program.type === 'CERTIFICATION'
                                    ? columns
                                    : columns.filter(
                                          (el: any) => el.dataIndex !== 'recertification'
                                      )
                            }
                            url={`/api/v1/courses/learning-program/${programId}/students`}
                            setReload={setReload}
                            reload={reload}
                            rowSelection={
                                program?.visualPermissions?.edit ||
                                program?.visualPermissions?.admin
                            }
                            selectedRowKeys={selectedRowKeys}
                            setSelectedRowKeys={setSelectedRowKeys}
                            setTotalRecords={setTotalRecords}
                            manualDataUpdate={scenarioUpdate}
                            updateData={dataSource}
                            saveData={setDataSource}
                            additionalSelect={setUserTypeIds}
                            additionalSelectKey={'userTypeId'}
                            wholeRow={true}
                            rowKey={'enrollmentId'}
                            scroll={{ x: 800 }}
                            filter={filter}
                            search={search}
                        />
                    )}
                </div>
            </Spinner>
            <FilterModal
                okProp={'campaign.enroll'}
                visible={showUsersFilterModal}
                onCancel={setShowUsersFilterModal}
                modules={{
                    customerSelect: {
                        mode: 'default',
                        visible: generalHelpers.isAdmin(session.active_user_type),
                    },
                    organizationalUnitId: { visible: true },
                    newUsers: { default: false, visible: true },
                    customerFilter: { mode: 'multiple', visible: true },
                }}
                submit={addUsers}
            />
            <DefaultLayout.PageFooter
                left={
                    <>
                        <TableSelectedRowCount rows={selectedRowKeys} />
                        {program && program.status === 'ONGOING' && (
                            <>
                                <Button
                                    disabled={
                                        !(
                                            program?.visualPermissions?.edit ||
                                            program?.visualPermissions?.admin
                                        )
                                    }
                                    onClick={() => setShowActivityStatusModal(true)}
                                >
                                    <FormattedMessage id="program.change_activity_status" />
                                </Button>
                            </>
                        )}
                        {program && program.status !== 'FINISHED' && (
                            <>
                                <Button
                                    disabled={
                                        !(
                                            program?.visualPermissions?.edit ||
                                            program?.visualPermissions?.admin
                                        )
                                    }
                                    onClick={() => cancelEnrollment(selectedRowKeys)}
                                >
                                    <FormattedMessage id="program.cancel_enrollment" />
                                </Button>
                                <Button
                                    disabled={
                                        !(
                                            program?.visualPermissions?.edit ||
                                            program?.visualPermissions?.admin
                                        )
                                    }
                                    onClick={() => reEnroll(selectedRowKeys)}
                                >
                                    <FormattedMessage id="program.re_enroll" />
                                </Button>
                                <Button onClick={() => setShowMailModal(true)}>
                                    <i className="fal fa-envelope" />
                                    <FormattedMessage id="users.send_email_users" />
                                </Button>
                                {generalHelpers.hasSpecialPermissions(session, 'edit_terms')  ? 
                                    <Button onClick={() => setShowTermModal(true)}>
                                        <FormattedMessage id="program.edit_terms" />
                                    </Button>
                                : null
                                }
                            </>
                        )}
                    </>
                }
                right={<>{launchButton}</>}
            />
            {!!(program?.id && selectedRowKeys.length) && (
                <TermModal
                    reloadStudents={setReload}
                    students={selectedRowKeys}
                    open={showTermModal}
                    program={program}
                    data={dataSource}
                    onCancel={setShowTermModal}
                />
            )}
            {(program?.id) && (
                <ResultModal
                    programId={program.id}
                    open={showResultModal}
                    onCancel={setShowResultModal}
                    activity={activity}
                />
            )}
            <ActivityStatusModal
                reloadStudents={setReload}
                students={selectedRowKeys}
                open={showActivityStatusModal}
                onCancel={setShowActivityStatusModal}
            />
            {(program?.ownerUuid) && (
                <SendEmailModal
                    visible={showMailModal}
                    onCancel={setShowMailModal}
                    organizationId={program.ownerUuid}
                    organizationType={program.ownerType}
                    defaultCategory={'learning_program'}
                    users={[]}
                    programId={program.id}
                    programData={parseMailingData()}
                />
            )}
        </>
    );
};
export default connect(mapStateToProps)(Students);
