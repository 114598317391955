import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { message, Modal, Form, Button } from 'antd';
import coursesApiClient from 'utils/coursesApiClient';
import {config} from 'config/config';
import useHandleError from "utils/useHandleError";

import Spinner from 'components/Spinner';
import { Select } from 'components/Form';

const formItemLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 18},
};

const CopyQuestionModel: React.FC<any> = ({ visible, onCancel, selectedQuestions, copyQuestionSubmit }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [questionPoolOptions, setQuestionPoolOptions] = useState<any>([]);
    const [questionBankOptions, setQuestionBankOptions] = useState<any>([]);
    const [selectedQuestionPool, setSelectedQuestionPool] = useState<number>();
    const [selectedQuestionBank, setSelectedQuestionBank] = useState<number>();

    const intl = useIntl();
    const [copyForm] = Form.useForm();
    const [handleError] = useHandleError();

    useEffect(() => {
        if(visible) {
            setQuestionPoolOptions([]);
            setSelectedQuestionPool(0);

            setQuestionBankOptions([]);
            setSelectedQuestionBank(0);

            copyForm.setFieldsValue({
                questionPool: null,
                questionBank: null,
            });

            loadQuestionPools()
        }
    }, [visible])

    useEffect(() => {
        setQuestionBankOptions([]);
        setSelectedQuestionBank(0);

        if(selectedQuestionPool) {
            loadQuestionBanks()
        }
    }, [selectedQuestionPool])

    const loadQuestionPools = async () => {

        setIsLoading(true);
        try {
            const response = await coursesApiClient.request(`/api/v1/courses/question-pools/question-pool-options`, [], 'GET');
            setQuestionPoolOptions(response.questionPools)
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false);
        }
    }

    const loadQuestionBanks = async () => {

        setIsLoading(true);
        try {
            const response = await coursesApiClient.request(`/api/v1/courses/question-pools/${selectedQuestionPool}/question-banks/question-bank-options`, [], 'GET');
            setQuestionBankOptions(response.questionBanks)
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false);
        }
    }
    
    const submitForm = (values: any) => {
        const parsedValues = {
            ...values,
            selectedQuestions: selectedQuestions
        }

        copyQuestionSubmit(parsedValues)
    }
    
    return (
        <Form form={copyForm} onFinish={submitForm}>
            <Modal
                className="copy-question-modal"
                title={intl.formatMessage({ id: 'question_pools.questions_copy_modal' })}
                open={visible}
                onOk={() => { copyForm.submit() }}
                onCancel={onCancel}
                okText={intl.formatMessage({ id: 'question_pools.copy' })}
                cancelText={intl.formatMessage({ id: 'general.cancel' })}
                okButtonProps={{disabled: selectedQuestionBank ? false : true}}
            >
                <Spinner spinning={isLoading}>
                    <Select
                        customLayout={formItemLayout}
                        name='questionPool'
                        label={intl.formatMessage({id: 'question_pools.select_question_pool'})}
                        manualOptions={questionPoolOptions}
                        valueKey="id"
                        labelKey="title"
                        isResponseArray={true}
                        onChange={(value: any) => setSelectedQuestionPool(value)}
                    />

                    {questionBankOptions && questionBankOptions.length > 0 && <Select
                        customLayout={formItemLayout}
                        name='questionBank'
                        label={intl.formatMessage({id: 'question_pools.select_question_bank'})}
                        manualOptions={questionBankOptions}
                        valueKey="id"
                        labelKey="title"
                        isResponseArray={true}
                        onChange={(value: any) => setSelectedQuestionBank(value)}
                    />}
                </Spinner>
            </Modal>
        </Form>
    )
}

export default CopyQuestionModel;
