import React from 'react';
import {FormattedMessage} from "react-intl";

interface TableSelectedRowCountInterface {
    rows: any[];
}

const TableSelectedRowCount: React.FC<TableSelectedRowCountInterface> = ({rows}) => {
    return (
        <div>
            {rows ? rows.length : 0}
            <FormattedMessage id='users.rows_selected'/>
        </div>
    );
};

export default TableSelectedRowCount;