import React, {useState, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useNavigate, useParams} from 'react-router-dom';

import {connect} from 'react-redux';
import useHandleError from "utils/useHandleError";
import {message, Button, Form, Radio, Checkbox, Row, Col} from "antd";
import DefaultLayout from "components/DefaultLayout";

import coursesApiClient from 'utils/coursesApiClient';
import Spinner from "../../../../components/Spinner";

import {TextArea, Input} from 'components/Form';
import debounce from "lodash/debounce";
import './styles.scss';

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale,
        session: state.session
    };
};

interface TestAttemptLandingProps {
    locale?: any;
    session?: any;
    testAttempt: any;
    learningTest: any;
    isParentLoading: boolean;
    setIsAttemptCompleted: Function;
    startTestAttempt: Function;
    isPreview: boolean;
}

const AttemptCompleted: React.FC<TestAttemptLandingProps> = ({ 
    locale, 
    session, 
    testAttempt, 
    learningTest, 
    isParentLoading, 
    setIsAttemptCompleted,
    startTestAttempt,
    isPreview
}) => {
    const intl = useIntl();

    const [handleError] = useHandleError();
    const navigate = useNavigate();

    const convertSecondsToMins = (seconds: number) => {
        return Math.round(seconds / 60);
    }

    const getAttemptScores = () => {
        return testAttempt.scoreType == 'PERCENTAGE' ? ((testAttempt.attemptScore / testAttempt.totalScore) * 100).toFixed(1) + "%" + ` (${testAttempt.attemptScore} ${intl.formatMessage({id: 'learning_tests.points'})})` : testAttempt.attemptScore;
    }

    return (
        <DefaultLayout.PageLayout style={{marginTop: '80px'}}>
            <DefaultLayout.PageContent style={{padding: 0}}>
                <Spinner spinning={isParentLoading}>
                    {testAttempt && <div className='test-attempt-page'>
                        <Row align='middle' justify='space-between' className='test-attempt-header'></Row>
                        <div className={`learning-test-landing-card`}>
                            <h4 className='color-1'>{learningTest.title}</h4>
                            <h1>
                                <span style={{display: 'block', marginBottom: '20px'}}><i className={`fa ${testAttempt.attemptStatus == 'PASSED' ? 'fa-check-circle' : 'fa-times-circle'}`} style={{color: testAttempt.attemptStatus == 'PASSED' ? '#52C41A' : '#F5222D'}} /></span>
                                <span>{
                                    testAttempt.attemptStatus == 'PASSED' ? 
                                        <>
                                            <FormattedMessage id="learning_tests.passed_msg_title" />,<br />
                                            <FormattedMessage id="learning_tests.passed_msg_text" />
                                        </>
                                    : <>
                                        <FormattedMessage id="learning_tests.failed_msg_title" />,<br />
                                        <FormattedMessage id="learning_tests.failed_msg_text" />
                                    </>
                                }</span>
                            </h1>
                            <div className='flex justify-center'>
                                <Row gutter={[30, 0]} wrap className='test-stats'>
                                    <Col>
                                        <label className='color-1'><FormattedMessage id="learning_tests.your_score" /></label>
                                        <div>{getAttemptScores()}</div>
                                    </Col>
                                    <Col>
                                        <label className='color-1'><FormattedMessage id="learning_tests.to_pass_score" /></label>
                                        <div>{testAttempt.scoreType == 'PERCENTAGE' ? testAttempt.passingScore + '%' : testAttempt.passingScore}</div>
                                    </Col>
                                    <Col>
                                        <label className='color-1'><FormattedMessage id="learning_tests.time_spent" /></label>
                                        <div>{convertSecondsToMins(parseInt(testAttempt.attemptTime))} min</div>
                                    </Col>
                                </Row>
                            </div>
                            {testAttempt.allowTestReview === 1 ? <Button type='primary' size='large' onClick={() => startTestAttempt(true)}><FormattedMessage id='learning_tests.test_overview' /></Button> : null}
                        </div>
                        <Row align='middle' justify='space-between' className='test-attempt-footer'>
                            <Col><></></Col>
                            <Col>
                            {!isPreview && <Button type='ghost' size='large' onClick={() => navigate('/')}><FormattedMessage id='learning_tests.close' /></Button>}
                            </Col>
                        </Row>
                    </div>}
                    
                </Spinner>
            </DefaultLayout.PageContent>
        </DefaultLayout.PageLayout>
        
    );
};

export default connect(mapStateToProps)(AttemptCompleted);
