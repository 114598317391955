import React, { useState } from 'react';
import { Radio, Table, Tag, Modal, Tabs } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { NavLink } from 'react-router-dom';
import FlexRow from "components/FlexRow";
import moment from "moment/moment";
import { Button } from "ui";
import CertModal from "./CertModal";
import { connect } from "react-redux";
import { config } from "config/config";

interface ResultTableInterface {
    data: any;
    fullName: string;
    isLecture: boolean;
    locale?: any;
}

const tagColors = {
    NOT_STARTED: 'gold',
    IN_PROGRESS: 'blue',
    COMPLETED: 'green',
    EXEMPTED: 'lime',
    PLANNED: 'geekblue',
    NOT_PLANNED: 'gold',
    ATTENDED: 'green',
    FAILED: 'red',
    PASSED: 'green',
    NOT_SUBMITTED: 'gold',
    SUBMITTED: 'blue',
    MISSED_DEADLINE: 'red',
    RETURNED: 'lime'
}

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale,
    };
}

const ResultTable: React.FC<ResultTableInterface> = ({
    data,
    fullName,
    isLecture,
    locale = config.defaultEnvironmentLocale
}) => {
    const [showModal, setShowModal] = useState(false);
    const intl = useIntl();
    const toHoursAndMinutes = (totalSeconds: number) => {
        const totalMinutes = Math.floor(totalSeconds / 60);

        const seconds = totalSeconds % 60;
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;

        const totalTime = (!!hours ? hours + 'h ' : '') + (!!minutes ? minutes + 'min ' : '') + (!!seconds ? seconds + 's' : '')
        return totalTime ? totalTime : '-'
    }


    const defaultExpandable = {
        expandedRowRender: (record: any) => {
            return (

                Object.entries(record.responses).map((userResponse: any) => {
                    const response = userResponse[1];
                    return (
                        <div className={'flex justify-between student-response-wrapper gap-20 p-5'}>
                            <div>
                                {parseInt(userResponse[0]) + 1}
                            </div>
                            <div className={'flex flex-column gap-5 w-35'}>
                                <span>
                                </span>
                                <span>
                                    {response.question}
                                </span>
                            </div>
                            <div className={'flex flex-column gap-5 w-35'}>
                                <span>
                                    <FormattedMessage id={'courses.export.answer'} />
                                </span>
                                <span>
                                    {response.learnerResponse}
                                </span>
                            </div>
                            <div className={'flex flex-column gap-5'}>
                                <span>
                                    <FormattedMessage id={'question_pools.score'} />
                                </span>
                                <span>
                                    {response?.points}
                                </span>
                            </div>
                        </div>
                    )
                })
            )
        },
    };

    const columns = [
        {
            title: intl.formatMessage({ id: 'program.attempt' }),
            render: (_text: string, record: any) => intl.formatMessage({ id: 'program.attempt_count' }, { attempt: record.attempt })
        },
        {
            title: intl.formatMessage({ id: 'student.result' }),
            dataIndex: 'result',
            render: (result: number) => result ? result + '%' : '-'
        },

        {
            title: intl.formatMessage({ id: 'program.attempt_start' }),
            dataIndex: 'attemptStart',
            key: 'attemptStart',
            render: (date: string) => date ? moment(date).format('YYYY-MM-DD HH:mm') : '-'
        },
        {
            title: intl.formatMessage({ id: 'program.attempt_end' }),
            dataIndex: 'attemptEnd',
            key: 'attemptEnd',
            render: (date: string) => date ? moment(date).format('YYYY-MM-DD HH:mm') : '-'
        },
        {
            title: intl.formatMessage({ id: 'general.comment' }),
            render: (_text: string, record: any) => <div className={'pointer'} onClick={() =>
                handleCommentModal(record)
            }><i className="fa-sharp fa-regular fa-comment-check"></i></div>
        },
        {
            title: intl.formatMessage({ id: 'program.total_time_spent' }),
            dataIndex: 'timeSpent',
            render: (time: number) => time ? toHoursAndMinutes(time) : '-'

        }
    ];

    const tagBuilder = (status: 'NOT_STARTED' | 'IN_PROGRESS' | 'COMPLETED' | 'EXEMPTED' | 'PLANNED' | 'NOT_PLANNED' | 'ATTENDED' | 'NOT_SUBMITTED' | 'SUBMITTED' | 'FAILED' | 'PASSED' | 'MISSED_DEADLINE' | 'RETURNED') => {
        return (
            <Tag color={tagColors[status]}>
                {intl.formatMessage({ id: `general.${status.toLowerCase()}` })}
            </Tag>
        )
    }

    const learningColumns = [
        {
            title: intl.formatMessage({ id: 'general.status' }),
            dataIndex: 'status',
            render: (status: 'NOT_STARTED' | 'IN_PROGRESS' | 'COMPLETED' | 'EXEMPTED' | 'PLANNED' | 'NOT_PLANNED' | 'ATTENDED') => status ? tagBuilder(status) : null
        },
        {
            title: intl.formatMessage({ id: 'general.comment' }),
            render: (_text: string, record: any) => <div className={'pointer'} onClick={() =>
                handleCommentModal(record)
            }><i className="fa-sharp fa-regular fa-comment-check"></i></div>
        },
        {
            title: intl.formatMessage({ id: 'program.total_time_spent' }),
            dataIndex: 'timeSpent',
            render: (time: number) => toHoursAndMinutes(time)
        },
        {
            title: intl.formatMessage({ id: 'program.view_cert_details' }),
            render: (_text: string, record: any) => {
                if (record?.submission) {

                    return (
                        <>
                            <Button type={'link'} onClick={() => setShowModal(true)}>
                                <FormattedMessage id={'program.view_cert'} />
                            </Button>
                            <CertModal open={showModal} onCancel={setShowModal} submission={record.submission} />

                        </>
                    )
                }
            }
        }
    ];

    const lectureColumns = [
        {
            title: intl.formatMessage({ id: 'general.title' }),
            dataIndex: 'title'
        },
        {
            title: intl.formatMessage({ id: 'general.status' }),
            dataIndex: 'status',
            render: (status: 'NOT_STARTED' | 'IN_PROGRESS' | 'COMPLETED' | 'PLANNED' | 'NOT_PLANNED') => status ? tagBuilder(status) : null
        },
        {
            title: intl.formatMessage({ id: 'general.comment' }),
            render: (_text: string, record: any) => <div className={'pointer'} onClick={() =>
                handleCommentModal(record)
            }><i className="fa-sharp fa-regular fa-comment-check"></i></div>
        },
        {
            title: intl.formatMessage({ id: 'program.completed_on' }),
            dataIndex: 'completedOn'
        }
    ];

    const learningTestColumns = [
        {
            title: intl.formatMessage({ id: 'program.attempt' }),
            render: (_text: string, record: any) => intl.formatMessage({ id: 'program.attempt_count' }, { attempt: record.attempt })
        },
        {
            title: intl.formatMessage({ id: 'student.result' }),
            dataIndex: 'resultStr',
            render: (resultStr: string) => resultStr
        },
        {
            title: intl.formatMessage({ id: 'general.status' }),
            dataIndex: 'status',
            render: (status: 'NOT_STARTED' | 'IN_PROGRESS' | 'FAILED' | 'PASSED') => status ? tagBuilder(status) : null
        },
        {
            title: intl.formatMessage({ id: 'program.attempt_start' }),
            dataIndex: 'attemptStart'
        },
        {
            title: intl.formatMessage({ id: 'program.attempt_end' }),
            dataIndex: 'attemptEnd'
        },
        {
            title: intl.formatMessage({ id: 'program.total_time_spent' }),
            dataIndex: 'timeSpent',
            render: (time: number) => toHoursAndMinutes(time)
        },
        {
            title: intl.formatMessage({ id: 'general.comment' }),
            render: (_text: string, record: any) => <div className={'pointer'} onClick={() =>
                handleCommentModal(record)
            }><i className="fa-sharp fa-regular fa-comment-check"></i></div>
        },
        {
            title: intl.formatMessage({ id: 'general.overview' }),
            dataIndex: 'attemptId',
            key: 'overview',
            render: (attemptId: number, record: any) => {
                if (record.status === 'IN_PROGRESS') {
                    return '-';
                } else {
                    return <NavLink to={`/learning/tests/${attemptId}/overview`} target='_blank'><FormattedMessage
                        id='learning_tests.overview' /></NavLink>;
                }
            }
        },
    ];

    const assignmentColumns = [
        {
            title: intl.formatMessage({ id: 'program.attempt' }),
            render: (_text: string, record: any) => intl.formatMessage({ id: 'program.attempt_count' }, { attempt: record.attempt })
        },
        {
            title: intl.formatMessage({ id: 'student.result' }),
            dataIndex: 'resultStr',
            render: (resultStr: string) => resultStr
        },
        {
            title: intl.formatMessage({ id: 'general.status' }),
            dataIndex: 'status',
            render: (status: 'NOT_SUBMITTED' | 'SUBMITTED' | 'FAILED' | 'PASSED' | 'MISSED_DEADLINE' | 'RETURNED') => status ? tagBuilder(status) : null
        },
        {
            title: intl.formatMessage({ id: 'program.attempt_start' }),
            dataIndex: 'attemptStart'
        },
        {
            title: intl.formatMessage({ id: 'program.attempt_end' }),
            dataIndex: 'attemptEnd'
        },
        {
            title: intl.formatMessage({ id: 'general.comment' }),
            render: (_text: string, record: any) => <div className={'pointer'} onClick={() =>
                handleCommentModal(record)
            }><i className="fa-sharp fa-regular fa-comment-check"></i></div>
        },
        {
            title: intl.formatMessage({ id: 'program.total_timFe_spent' }),
            dataIndex: 'timeSpent',
            render: (time: number) => toHoursAndMinutes(time)
        },
    ];

    const assessmentColumns = [
        {
            title: intl.formatMessage({ id: 'general.title' }),
            dataIndex: 'title',
            render: (_text: string, record: any) => {
                return record.title[locale] ? record.title[locale] : record.title[Object.keys(record.title)[0]]
            }
        },
        {
            title: intl.formatMessage({ id: 'general.status' }),
            dataIndex: 'status',
            render: (status: 'NOT_SUBMITTED' | 'SUBMITTED' | 'FAILED' | 'PASSED' | 'MISSED_DEADLINE' | 'RETURNED') => status ? tagBuilder(status) : null
        },
        {
            title: intl.formatMessage({ id: 'general.comment' }),


            render: (_text: string, record: any) => <div className={record.comment ? 'pointer' : 'disabled'}
                onClick={() => record.comment ?
                    handleCommentModal(record) : null
                }><i className="fa-sharp fa-regular fa-comment-check"></i>
            </div>
        },
        {
            title: intl.formatMessage({ id: 'program.attempt_end' }),
            dataIndex: 'attemptEnd'
        },
    ];


    const handleCommentModal = (record: any) => {
        const key = Object.keys(record.comment)[Object.keys(record.comment).length - 1];
        Modal.info({
            okText: intl.formatMessage({ id: 'general.close' }),
            title: intl.formatMessage({ id: 'general.comment' }),
            content: (
                (Array.isArray(record.comment)) ?
                    record.comment.map((comment: string) => {
                        return <div>{comment}</div>
                    })
                    :
                    <div>
                        {record.comment}
                    </div>
            ),
            onOk() {
            },
        });
    }

    const renderTable = (data: any) => {
        const { test, learningTest, assignment, learn, assessment } = data;

        if (assignment) {
            return <Table
                columns={assignmentColumns}
                dataSource={assignment}
                rowKey={'attempt'}
                pagination={{ position: [] }}
            />
        }

        if (isLecture) {
            return <Table
                columns={lectureColumns}
                dataSource={[learn]}
                rowKey={'status'}
                pagination={{ position: [] }}
            />
        } else {
            if (learningTest) {
                return <Table
                    columns={learningTestColumns}
                    dataSource={learningTest}
                    rowKey={'attempt'}
                    pagination={{ position: [] }}
                />
            } else if (assessment) {
                return <Table
                    columns={assessmentColumns}
                    dataSource={assessment}
                    rowKey={'attempt'}
                    pagination={{ position: [] }}
                />
            } else {
                return <Table
                    expandable={defaultExpandable}
                    columns={columns}
                    dataSource={test}
                    rowKey={'attempt'}
                    pagination={{ position: [] }}
                />
            }
        }
    }
    const renderLearnTable = () => {
        return <Table
            columns={learningColumns}
            dataSource={data.learn}
            rowKey={'status'}
            pagination={{ position: [] }}
        />
    }


    return (
        <>
            <FlexRow
                right={
                    <div className={'bold'}>
                        {fullName}
                    </div>
                }
            />
            <Tabs defaultActiveKey="test">
                <Tabs.TabPane tab={intl.formatMessage({ id: 'courses.learning_activity' })} key="learn">
                    {renderLearnTable()}
                </Tabs.TabPane>
                <Tabs.TabPane tab={intl.formatMessage({ id: 'courses.test_material' })} key="test">
                    {renderTable(data)}
                </Tabs.TabPane>
            </Tabs>
        </>
    )
};

export default connect(mapStateToProps)(ResultTable);
