import React, { useState } from 'react';
import { Dropdown, Input, message, Menu, Modal, Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useIntl, FormattedMessage } from 'react-intl';
import coursesApiClient from 'utils/coursesApiClient';
import generalHelpers from "utils/generalHelpers";
import debounce from 'lodash/debounce';
import FileDownload from 'js-file-download';
import Spinner from 'components/Spinner';
import styles from './styles.module.scss'
interface FileTableExtendedInterface {
    fileArray: any;
    onDelete?: Function;
    showMenu?: boolean;
    allowDelete?: boolean;
    cols?: any[];
    dateBelowTitle?: boolean;
    showLabels?: boolean;
    theme?: 'bordered'|'with-bg';
    urls?: {
        delete?: string;
        download?: string;
    };
}

const FileTableExtended: React.FC<FileTableExtendedInterface> = ({ 
    fileArray, 
    onDelete, 
    showMenu = false,
    allowDelete = false,
    cols = ['type', 'size'],
    dateBelowTitle = true,
    showLabels = true,
    theme = 'bordered',
    urls,
}) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedFile, setSelectedFile] = useState<any>();

    const intl = useIntl();
    const { confirm } = Modal;

    const getFileType = (fileName: string) => {
        const fileNameArray = fileName.split('.');

        return fileNameArray[fileNameArray.length - 1].toUpperCase();
    }

    function getFileSize(size: number) {
        var units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        var i = 0;
        while (size >= 1024) {
            size /= 1024;
            ++i;
        }
        return size.toFixed(1) + ' ' + units[i];
    };

    const content = (
        <Menu>
            <Menu.Item key="download" onClick={() => downloadFile()}>
                <FormattedMessage id="general.download" />
            </Menu.Item>
            {allowDelete &&
                <>
                    <Menu.Divider />
                    <Menu.Item key="delete" onClick={() => warnDeletion()}>
                        <FormattedMessage id="general.delete" />
                    </Menu.Item>
                </>
            }
        </Menu >
    );

    const downloadFile = async (file: any = selectedFile) => {
        if (!file) {
            return;
        }

        if (!urls?.download) {
            return;
        }

        setIsLoading(true)

        try {
            const response = await coursesApiClient.request(generalHelpers.formUrl(urls.download, {path: file.file_path}), {}, 'GET', true, true);
            FileDownload(response, file.title);
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        } finally {
            setIsLoading(false);
        }
    }

    const warnDeletion = async (file: any = selectedFile) => {
        if (!file) {
            return;
        }

        confirm({
            title: intl.formatMessage({ id: 'general.attention' }),
            content: intl.formatMessage({ id: 'courses.deletion_resource' }),
            icon: <ExclamationCircleOutlined />,
            okText: intl.formatMessage({ id: 'general.yes' }),
            cancelText: intl.formatMessage({ id: 'general.no' }),

            onOk() {
                deleteResource(file);
            }
        });
    }

    const deleteResource = async (file: any) => {
        if (!urls?.delete) {
            return;
        }

        setIsLoading(true)

        try {
            const response = await coursesApiClient.request(urls.delete, {filePath: file.file_path}, 'DELETE');

            if (onDelete) {
                onDelete();
            }
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        } finally {
            setIsLoading(false);
        }
    }
    return (
        <>
        <Spinner spinning={isLoading}>
            {fileArray &&
                <div className={styles.file}>
                    {fileArray.map((file: any) => {

                        return (
                            <div className={theme == 'with-bg' ? styles.fileRowWithBg : styles.fileRow}>
                                <div className={styles.fileRowElement}>
                                    {showLabels && <div className={styles.fileRowLabel}>
                                        <FormattedMessage id='general.title' />
                                    </div>}
                                    <div className={styles.fileContentTitle}>
                                        <Tooltip placement="topLeft" title={file.title}>
                                            <div className={styles.fileContentTitleOuter}>
                                                <i className={`${styles.fileContentIcon} fa-solid fa-file-lines`} />
                                                <div className={styles.fileContentTitleInner}>
                                                    <div>{file.title.split(150)}</div>
                                                    {cols.includes('date') && file?.date && dateBelowTitle && <div className={styles.fileContentTitleDate}>
                                                        {file.date}
                                                    </div>}
                                                </div>
                                            </div>
                                        </Tooltip>
                                    </div>
                                </div>

                                {cols.includes('description') && file?.description &&
                                    <div className={styles.fileRowElement}>
                                        {showLabels && <div className={styles.fileRowLabel}>
                                            <FormattedMessage id='general.description' />
                                        </div>}
                                        <div className={styles.fileContent}>
                                            <div style={{ fontSize: 16, overflowWrap: 'break-word' }} dangerouslySetInnerHTML={{ __html: file.description ? file.description : '' }}></div>
                                        </div>
                                    </div>
                                }
                                {cols.includes('user') && file?.user_name &&
                                    <div className={styles.fileRowElement}>
                                        {showLabels && <div className={styles.fileRowLabel}>
                                            <FormattedMessage id='general.user' />
                                        </div>}
                                        <div className={styles.fileContent}>
                                            {file.user_name}
                                        </div>
                                    </div>
                                }
                                {cols.includes('date') && file?.date && !dateBelowTitle &&
                                    <div className={styles.fileRowElement}>
                                        {showLabels && <div className={styles.fileRowLabel}>
                                            <FormattedMessage id='general.upload_date' />
                                        </div>}
                                        <div className={styles.fileContent}>
                                            {file.date}
                                        </div>
                                    </div>
                                }

                                {cols.includes('type') &&
                                <div className={styles.fileRowDetailsElement}>
                                    {showLabels && <div className={styles.fileRowLabel}>
                                        <FormattedMessage id='general.type' />
                                    </div>}
                                    <div className={styles.fileDetails}>
                                        {getFileType(file.title)}
                                    </div>
                                </div>}

                                {cols.includes('size') && file?.file_size && <div className={styles.fileRowDetailsElement}>
                                    {showLabels && <div className={styles.fileRowLabel}>
                                        <FormattedMessage id='general.file_size' />
                                    </div>}
                                    <div className={styles.fileDetails}>
                                        {getFileSize(file.file_size)}
                                    </div>
                                </div>}

                                <div className={styles.fileRowElement}>
                                    {showLabels && <div className={styles.fileRowLabel}>
                                    ‎
                                    </div>}
                                    <div className={styles.fileAction} onClick={() => setSelectedFile(file)}>
                                        {showMenu ?
                                            <Dropdown overlay={content} trigger={['click']}>
                                                <i className={`${styles.fileActionIcon} fa-solid fa-ellipsis-vertical`} />
                                            </Dropdown>
                                            :
                                            <>
                                                <div className={styles.fileActionDownload} onClick={() => downloadFile(file)}>
                                                    <FormattedMessage id='general.download' />
                                                </div>
                                                {allowDelete && (file?.delete_check === undefined || file?.delete_check === null || file?.delete_check === true) && <div className={styles.fileActionDelete} onClick={() => warnDeletion(file)}>
                                                    <FormattedMessage id='general.delete' />
                                                </div>}
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            }
            </Spinner>
        </>
    )
};

export default FileTableExtended;
