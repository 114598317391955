import React, {useState, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Tag, Modal, Input, Form, DatePicker, Radio, Button, Checkbox} from 'antd';
import {config} from 'config/config';
import DefaultLayout from 'components/DefaultLayout';
import apiClient from 'utils/apiClient';
import coursesApiClient from 'utils/coursesApiClient';
import {connect} from 'react-redux';
import moment from 'moment';
import {Select, CustomerSelect, Switch} from 'components/Form';
import {EyeOutlined} from '@ant-design/icons';
import {Search, Table} from "ui";

import './styles.scss';
import FlexRow from "../../../components/FlexRow";

const mapStateToProps = (state: any) => {
    return {
        session: state.session,
        locale: state.locale.locale
    };
}

const EmailHistoryList: React.FC<any> = ({props, session, locale}: any) => {
    const [reload, setReload] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [filter, setFilter] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [isModalVisible, setISModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [filterBy, setFilterBy] = useState('customer');
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [isLearningManager, setIsLearningManager] = useState(false);
    const [ownerId, setOwnerId] = useState();
    const [ownerType, setOwnerType] = useState<any>();
    const [emailCategories, setEmailCategories] = useState<any>();

    const intl = useIntl();
    const [form] = Form.useForm();

    useEffect(() => {
        if (session.active_user_type === "CUSTOMER_ADMIN") {
            setIsLearningManager(true)
            setOwnerId(session.organization.organization_id)
            setOwnerType('CUSTOMER')
            form.setFieldsValue({
                type: ['sms', 'e-mail']
            });
        } else {
            form.setFieldsValue({
                filterBy: 'customer',
                type: ['sms', 'e-mail']
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl]);

    useEffect(() => {
        const loadEmailCategories = async () => {
            let categoriesResponse = await apiClient.request(`/api/v1/mail-templates/options/categories`, {}, 'GET');
            if (session.active_user_type !== 'SUPER_ADMIN') {
                Object.entries(categoriesResponse).map((el: any) => {
                    if (el[1] === 'system_events') {
                        delete categoriesResponse[el[0]]
                    }
                    return null;
                })
            }
            setEmailCategories(categoriesResponse);
        }
        loadEmailCategories();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const submitForm = async (values: any) => {
        setIsLoading(true)
        let parsedFilter = '';
        Object.entries(values).map((el: any) => {

            if (Array.isArray(el[1]) && el[1].length && el[1] && el[0]) {
                return (parsedFilter += `&filter[${el[0]}]=${el[1]}`);
            } else if (el[1] && moment.isMoment(el[1])) {
                return (parsedFilter += `&filter[${el[0]}]=${moment(el[1]).format('YYYY-MM-DD')}`);
            } else if (!Array.isArray(el[1]) && el[1] && el[0]) {
                return (parsedFilter += `&filter[${el[0]}]=${el[1]}`);
            }
        })

        setFilter(parsedFilter)
        setReload(true)
        setShowFilterModal(false)
        setIsLoading(false);
    }

    const columns = [
        {
            title: intl.formatMessage({id: 'emailing.history.email_scenarios'}),
            render: (record: any) => {
                return (
                    <>
                        <Tag>
                            <FormattedMessage id={`email_templates.categories.${record.category}`}/>
                        </Tag>
                        {record.title}
                    </>
                );
            }
        },
        {
            title: intl.formatMessage({id: 'emailing.history.receiver_name'}),
            dataIndex: 'fullName',
        },
        {
            title: intl.formatMessage({id: 'emailing.history.senders_address'}),
            dataIndex: 'from',
            render: (text: string, record: any) => {
                return (
                    <div className='history-sender'>
                        {record.from}
                        <>
                            {record.type === 'sms' ?
                                <Tag>
                                    <FormattedMessage id='users.export.table.smsSending'/>
                                </Tag>
                                : null
                            }
                        </>
                    </div>
                )
            }

        },
        {
            title: intl.formatMessage({id: 'emailing.history.receiver_address'}),
            dataIndex: 'to',
            render: (text: string, record: any) => {
                return record.type === 0 ? record.email : record.phoneNumber;
            }
        },
        {
            title: intl.formatMessage({id: 'general.date'}),
            dataIndex: 'date',
            key: 'date',
            render: (text: string, record: any) => moment(record.execution_time).format('HH:mm:ss DD.MM.YYYY')
        },
        {
            title: intl.formatMessage({id: 'general.body'}),
            dataIndex: 'body',
            key: 'body',
            render: (text: string, record: any) => {
                return <p
                    style={{color: '#19A9CE', cursor: 'pointer'}}
                    onClick={() => {
                        setModalTitle(intl.formatMessage({id: "emailing.history.email_body"}));
                        setModalContent(record.body);
                        setISModalVisible(true);
                    }}
                >
                    <FormattedMessage id={'general.show'}/> <EyeOutlined/>
                </p>
            }
        },
        {
            title: intl.formatMessage({id: 'general.status'}),
            dataIndex: 'status',
            render: (text: string, record: any, tag: any) => {
                let color;
                if (record.status) {
                    if (record.status === 'SENT') color = 'green';
                    if (record.status === 'SENDING') color = 'gold';
                    if (record.status === 'FAILED') color = 'red';
                    return (
                        <>
                            <Tag color={color} key={tag}>
                                {intl.formatMessage({id: `email_history.status.${record.status}`})}
                            </Tag>
                            {
                                record.status === 'FAILED'
                                    ? <p
                                        style={{color: '#19A9CE', cursor: 'pointer'}}
                                        onClick={() => {
                                            setModalTitle(intl.formatMessage({id: "general.error"}));
                                            setModalContent(record.errors);
                                            setISModalVisible(true);
                                        }}
                                    >
                                        <FormattedMessage id={'general.show'}/> <EyeOutlined/>
                                    </p>
                                    : null
                            }
                        </>
                    )
                } else {
                    return '-';
                }
            }
        },
    ];

    return (
        <DefaultLayout.PageLayout>
            <DefaultLayout.PageHeader title={intl.formatMessage({id: 'emailing.history.e_mailing_history'})}/>
            <DefaultLayout.PageContent>
                <FlexRow
                    left={
                        <Button type="primary" onClick={() => setShowFilterModal(true)}><FormattedMessage
                            id="general.filter"/></Button>
                    }
                    right={
                        <Search
                            setReload={setReload}
                            setSearchString={setSearchString}
                        />
                    }
                />
                <Table
                    columns={columns}
                    url={'/api/v1/mail-history'}
                    setReload={setReload}
                    reload={reload}
                    rowSelection={false}
                    filter={filter}
                    rowKey={'id'}
                    setTotalRecords={setTotalRecords}
                    search={searchString}
                    scroll={{x: true}}
                />
            </DefaultLayout.PageContent>
            <Modal
                title={modalTitle}
                open={isModalVisible}
                onOk={() => setISModalVisible(false)}
                onCancel={() => setISModalVisible(false)}
                cancelButtonProps={{style: {display: 'none'}}}
            >
                <div dangerouslySetInnerHTML={{__html: modalContent}}/>
            </Modal>
            <Modal
                className="history-list-modal"
                open={showFilterModal}
                title={intl.formatMessage({id: 'emailing.history.email_history_filter'})}
                onOk={() => form.submit()}
                onCancel={() => setShowFilterModal(false)}
                okText={intl.formatMessage({id: 'general.filter'})}
                cancelText={intl.formatMessage({id: 'general.cancel'})}
            >
                <Form form={form} onFinish={submitForm}>
                    <Form.Item
                        name="filterBy"
                        label="Filter by"
                        style={isLearningManager ? {display: 'none'} : {display: ''}}
                    >
                        <Radio.Group
                            onChange={(el: any) => {
                                setFilterBy(el.target.value);
                                if (el.target.value === 'customer') {
                                    form.setFieldsValue({
                                        filterBy: 'customer',
                                        userTypes: ['CUSTOMER_ADMIN', 'STUDENT'],
                                    });
                                } else {
                                    form.setFieldsValue({
                                        filterBy: 'organization',
                                        userTypes: ['ADMIN'],
                                    });
                                }
                                form.setFieldsValue({
                                    customer: [],
                                    organization: [],
                                    organizationalUnitId: []
                                });
                            }}
                        >
                            <Radio value="customer"><FormattedMessage id='general.customer'/></Radio>
                            <Radio value="organization"><FormattedMessage id='general.reseller'/></Radio>
                        </Radio.Group>
                    </Form.Item>
                    <div style={isLearningManager ? {display: 'none'} : {display: ''}}>
                        {
                            filterBy === 'customer'
                                ? <CustomerSelect
                                    showSearch
                                    name='customer'
                                    className='scenario-email-required'
                                    label={intl.formatMessage({id: "general.customer"})}
                                    url={config.api.routes.backend.organizationsCustomers}
                                    integerKey={true}
                                    customRules={[isLearningManager ? {required: false} : {
                                        required: true,
                                        message: intl.formatMessage({id: "validation.field_required"})
                                    }]}
                                    onChange={(value: any) => {
                                        setOwnerId(value)
                                        setOwnerType('CUSTOMER')
                                    }}
                                    isResponseArray={true}
                                    dataKey='customers'
                                    mapDataEntries={(el: any) => ({value: el.id, label: el.name})}
                                    valueKey='value'
                                    labelKey='label'
                                    customLayout={true}
                                    filterOption={(input: string, option: any) => {
                                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                    }}
                                />
                                : null
                        }
                        {
                            filterBy === 'organization'
                                ? <CustomerSelect
                                    showSearch
                                    className='scenario-email-required'
                                    name='organization'
                                    label={intl.formatMessage({id: "general.reseller"})}
                                    url={config.api.routes.backend.organizationsOptions}
                                    integerKey={true}
                                    customRules={[{
                                        required: true,
                                        message: intl.formatMessage({id: "validation.field_required"})
                                    }]}
                                    onChange={(value: any, option: any) => {
                                        setOwnerId(value)
                                        setOwnerType(option.customerType)
                                    }}
                                    isResponseArray={true}
                                    dataKey='organizations'
                                    mapDataEntries={(el: any) => ({value: el.id, customerType: el.type, label: el.name})}
                                    valueKey='value'
                                    labelKey='label'
                                    customLayout={true}
                                    filterOption={(input: string, option: any) => {
                                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                    }}
                                />
                                : null
                        }
                    </div>
                    <Form.Item name="startDate" className='scenario-email-required'
                               label={intl.formatMessage({id: "general.start_date"})}
                               rules={[{
                                   required: true,
                                   message: intl.formatMessage({id: "validation.field_required"})
                               }]}>
                        <DatePicker format="YYYY-MM-DD"/>
                    </Form.Item>
                    <Form.Item name="endDate" className='scenario-email-required'
                               label={intl.formatMessage({id: "general.end_date"})}
                               rules={[{
                                   required: true,
                                   message: intl.formatMessage({id: "validation.field_required"})
                               }]}>
                        <DatePicker format="YYYY-MM-DD"/>
                    </Form.Item>
                    <Select
                        className='scenario-email-required'
                        name='category'
                        label={intl.formatMessage({id: "emailing.scenarios.e_mail_category"})}
                        customLayout={true}
                        manualOptions={emailCategories}
                        integerKey={true}
                        customObjLabel={(el: string) => intl.formatMessage({id: `email_templates.categories.${el}`})}
                        withSystemName={true}
                    />
                    <Form.Item name="type" label={intl.formatMessage({id: 'general.type'})}>
                        <Checkbox.Group>
                            <Checkbox value="sms" style={{lineHeight: '32px'}}>
                                <FormattedMessage id='users.export.table.smsSending'/>
                            </Checkbox>
                            <Checkbox value="e-mail" style={{lineHeight: '32px'}}>
                                <FormattedMessage id='general.email'/>
                            </Checkbox>
                        </Checkbox.Group>
                    </Form.Item>
                </Form>
            </Modal>
        </DefaultLayout.PageLayout>
    );
}

export default connect(mapStateToProps)(EmailHistoryList);
