import React, {useEffect, useState} from 'react';
import MyLectures from "../../../Lectures/MyLectures/MyLectures";
import MySession from "../../../Lectures/MySession";

interface LectureTabProps {
    userTypeId: number;
}

const LectureTab: React.FC<LectureTabProps> = ({userTypeId}) => {
    const [view, setView] = useState('LECTURE');
    const [session, setSession] = useState(0);
    const [lecture, setLecture] = useState(0);

    const getView = (view: string) => {
        switch (view) {
            case 'LECTURE':
                return <MyLectures
                    adminView={true}
                    userTypeId={userTypeId}
                    setAdminSession={setSession}
                    setAdminView={setView}
                    setAdminLecture={setLecture}
                />
            case 'SESSION':
                return <MySession
                    adminView={true}
                    setAdminView={setView}
                    userTypeId={userTypeId}
                    adminSession={session}
                    adminLecture={lecture}
                    view={view}
                />
        }
    }

    return (
        <>
            {getView(view)}
        </>
    );
};

export default LectureTab;
