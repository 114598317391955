import React, {useState, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {message, Modal, Form, Button} from 'antd';
import Switch from 'components/Form/Switch'
import apiClient from 'utils/apiClient';
import Spinner from 'components/Spinner';
import {config} from 'config/config';
import useHandleError from "utils/useHandleError";
import AssignmentModal from "./AssignmentSubmission";
import AssessmentModal from "components/AssessmentActivity/AssessmentModal";
import './styles.scss';
import CertificateSubmission from "./CertificateSubmission";

interface GradingModelInterface {
    onCancel: any;
    onGraded: Function;
    attemptData: any;
}

const GradingModel: React.FC<GradingModelInterface> = ({
    onCancel,
    onGraded,
    attemptData
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [activityFormData, setActivityFormData] = useState<any>();
    const [canGrade, setCanGrade] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);

    const intl = useIntl();
    const [handleError] = useHandleError();

    useEffect(() => {
        setActivityFormData(null)
        if(attemptData !== null) {
            loadAssignment();
            setOpen(true)
        } else {
            setOpen(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attemptData]);

    const loadAssignment = async () => {
        try {
            setIsLoading(true);
            const response = await apiClient.request(`/api/v1/courses/grading/get-attempt/${attemptData.attemptId}`, {}, 'GET');
            setCanGrade(!response.programActivityAttempt.is_completed);
            setActivityFormData(response);
            setIsLoading(false);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    const renderContent = (type: string) => {
        switch (type) {
            case 'ASSIGNMENT':
                return <AssignmentModal 
                    open={open}
                    onGraded={onGraded}
                    onCancel={onCancel}
                    isLoading={isLoading}
                    activityFormData={activityFormData}
                    canGrade={canGrade}
                    setCanGrade={setCanGrade}
                />
            case 'ASSESSMENT':
                return <AssessmentModal 
                    open={open}
                    onGraded={onGraded}
                    onCancel={onCancel}
                    isLoading={isLoading}
                    activityFormData={activityFormData}
                    canGrade={canGrade}
                    setCanGrade={setCanGrade}
                    loadAssignment={loadAssignment}
                />
            case 'CERTIFICATE':
                return <CertificateSubmission
                    open={open}
                    onGraded={onGraded}
                    onCancel={onCancel}
                    isLoading={isLoading}
                    activityFormData={activityFormData}
                    canGrade={canGrade}
                    setCanGrade={setCanGrade}
                />
        
            default:
                break;
        }
    }
    return (
        <>
            {attemptData && renderContent(attemptData.type)}
        </>
    )
};

export default GradingModel;
