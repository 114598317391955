import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {connect} from 'react-redux';
import { message, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import Switch from 'components/Form/Switch';
import useHandleError from "utils/useHandleError";
import coursesApiClient from "utils/coursesApiClient";

const QuestionBankStatusSwitch: React.FC<any> = ({
    questionBank,
    onChange
}) => {
    const [isSwitchLoading, setIsSwitchLoading] = useState(false);

    const intl = useIntl();
    const [handleError] = useHandleError();
    const { confirm } = Modal;

    const onSwitchChange = async () => {
        try {
          setIsSwitchLoading(true);        
          if( questionBank.isOnline ) {
            const response = await coursesApiClient.request(`/api/v1/courses/question-pools/question-banks/${questionBank.id}/active-learning-tests`, {}, 'GET');
            if( response.titles.length > 0 ) {
              confirm({
                  title: intl.formatMessage({ id: 'general.attention' }),
                  content: intl.formatMessage({ 
                      id: 'question_pools.warning_bank_offline',
                      defaultMessage: 'Question bank is used in following tests: {learning_tests}. When status is set to offline, questions from the question bank will not be available for these tests. Do you want to proceed?'
                  }, {
                      learning_tests: response.titles.join(', ')
                  }),
                  icon: <ExclamationCircleOutlined />,
                  okText: intl.formatMessage({ id: 'general.yes' }),
                  cancelText: intl.formatMessage({ id: 'general.no' }),
      
                  onOk() {
                      updateQuestionBankStatus();
                  },
                  onCancel() {
                      setIsSwitchLoading(false);
                  }
              });
            } else {
                  updateQuestionBankStatus();
            }
          } else {
            updateQuestionBankStatus();
          }
          
        } catch (err) {
          console.error(err);
          message.error(intl.formatMessage({id: 'error.action_failed'}));
        } finally {
          setIsSwitchLoading(false);
        }
    }

    const updateQuestionBankStatus = async () => {
        try {
          setIsSwitchLoading(true);

          const status = !questionBank.isOnline;
    
          const response = await coursesApiClient.request(`/api/v1/courses/question-pools/question-banks/${questionBank.id}/update-status`, {status: status}, 'POST');
          onChange(response.question_bank)
          
          message.success(intl.formatMessage({id: 'question_pools.question_bank_status_updated'}), 7);
        } catch (err) {
          console.error(err);
          message.error(intl.formatMessage({id: 'error.action_failed'}));
        } finally {
          setIsSwitchLoading(false);
        }
    }

    return (
        <Switch
            key={`question-bank-switch-${questionBank.id}`}  
            onClick={() => onSwitchChange()} 
            checked={questionBank.isOnline} 
            disabled={isSwitchLoading || !questionBank.editAccess}
        />
    )
};

export default QuestionBankStatusSwitch;
