import React, {useState, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {connect} from 'react-redux';
import useHandleError from "utils/useHandleError";
import {message, Button, Form, Row, Col} from "antd";
import { PlusOutlined, ImportOutlined } from '@ant-design/icons';
import FileDownload from 'js-file-download';

import coursesApiClient from 'utils/coursesApiClient';
import FlexRow from "../../../components/FlexRow";
import Spinner from "../../../components/Spinner";
import QuestionsImportModal from '../QuestionsImportModal';
import CopyQuestionModel from '../CopyQuestionModel';

import QuestionsSidebar from './QuestionsSidebar';
import {TextArea, Input, Checkbox} from 'components/Form';
import QuestionAnswersList from "./QuestionAnswersList";
import QuestionListItem from "./QuestionListItem";
import debounce from "lodash/debounce";
import './styles.scss';

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale,
        session: state.session
    };
};
const formItemLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
};

interface QuestionsListInterface {
    questions: any;
    questionBankId: any,
    locale?: any;
    session?: any;
    handlePanel: Function;
    isLoadingQuestions: boolean;
    openQuestionId: number | null;
    onSelectQuestion: Function;
    selectedQuestions: any;
    questionTypes: Object;
}

const QuestionsList: React.FC<QuestionsListInterface> = ({ locale, session, questions, handlePanel, isLoadingQuestions, openQuestionId, onSelectQuestion, selectedQuestions, questionTypes }) => {
    const [isLoading, setIsLoading] = useState(false);

    const intl = useIntl();
    const [handleError] = useHandleError();

    return (
        <>
            <div className='questions-list'>
                <Spinner spinning={isLoadingQuestions}>
                    {questions && questions.map((question: any, key: number) => (
                        <div key={key}>
                            <QuestionListItem 
                                question={question} 
                                questionIndex={key}
                                handlePanel={handlePanel}
                                onSelectQuestion={onSelectQuestion}
                                openQuestionId={openQuestionId}
                                selectedQuestions={selectedQuestions}
                                questionTypes={questionTypes}
                            />
                        </div>
                    ))}
                </Spinner>
            </div>
        </>
    );
};

export default connect(mapStateToProps)(QuestionsList);
