import React, {ReactText, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Button, Dropdown, Menu, message} from 'antd';
import InstructionButton from "../VideoInstructions/InstructionButton";
import {DownOutlined} from '@ant-design/icons';
import {MenuInfo} from 'rc-menu/lib/interface';
import FileDownload from 'js-file-download';
import coursesApiClient from 'utils/coursesApiClient';
import {connect} from "react-redux";
import FlexRow from "../FlexRow";
import {DashboardModal} from "components/Modals";

interface DashboardStatisticsExportProps {
    customerUuid: string;
    locale?: string;
    setCustomerUuid: React.Dispatch<React.SetStateAction<string>>;
    session: any;
}

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale,
        session: state.session
    }
};

const DashboardStatisticsExport: React.FC<DashboardStatisticsExportProps> = ({
                                                                                 customerUuid,
                                                                                 locale,
                                                                                 setCustomerUuid,
                                                                                 session
                                                                             }) => {
    const [isDownloading, setIsDownloading] = useState(false);
    const [showCustomerSelect, setShowCustomerSelect] = useState<boolean>(false);
    const [customerName, setCustomerName] = useState('');

    const intl = useIntl();

    const downloadStatistics = async (key: ReactText) => {
        try {
            setIsDownloading(true);

            const urls: { [key: string]: string } = {
                'overall_campaigns_data': `/api/v1/courses/statistic/dashboard/campaigns/customer/${customerUuid}/export-campaigns-overall-data`,
                'overall_student_progress': `/api/v1/courses/statistic/dashboard/campaigns/customer/${customerUuid}/export-campaigns-details`,
                'e_learning_overview': `/api/v1/courses/statistic/dashboard/campaigns/customer/${customerUuid}/export-elearning-overview`,
                'overall_phishing_data': `/api/v1/courses/statistic/dashboard/campaigns/customer/${customerUuid}/export-phishing-overview`
            };

            const response = await coursesApiClient.request(urls[key], {language: locale}, 'GET', true, true);

            if (response.fileName) {
                FileDownload(response, response.fileName);
            } else {
                message.error(intl.formatMessage({id: 'error.data_load'}));
            }
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({id: 'error.data_load'}));
        } finally {
            setIsDownloading(false);
        }
    };

    const handleMenuClick = (e: MenuInfo) => downloadStatistics(e.key);

    const dropdownMenu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="overall_campaigns_data">
                <FormattedMessage id="campaign.overall_campaigns_data"/>
            </Menu.Item>
            <Menu.Item key="overall_student_progress">
                <FormattedMessage id="campaign.overall_student_progress"/>
            </Menu.Item>
            <Menu.Item key="e_learning_overview">
                <FormattedMessage id="dashboard.export.e_learning_overview"/>
            </Menu.Item>
            <Menu.Item key="overall_phishing_data">
                <FormattedMessage id="phishing.export_phishing_statistics"/>
            </Menu.Item>
        </Menu>
    );

    return (
        <div style={{marginBottom: 8}}>
            <FlexRow
                left={
                    <>
                        {session.active_user_type === 'DISTRIBUTOR_ADMIN' || session.active_user_type === 'RESELLER_ADMIN' || session.active_user_type === 'SUPER_ADMIN' ?
                            <>
                                <Button onClick={() => setShowCustomerSelect(true)} type="primary">
                                    <FormattedMessage id="general.filter"/>
                                </Button>
                                <div style={{fontWeight: 600, color: 'black'}}>
                                    {customerName}
                                </div>
                            </>
                            :
                            null
                        }
                    </>
                }
                right={
                    <>
                        {session.active_user_type === 'CUSTOMER_ADMIN' &&
                          <Dropdown overlay={dropdownMenu} trigger={['click']}>
                            <Button type="primary" loading={isDownloading}>
                              <i className="fal fa-file-download"/>
                              <span><FormattedMessage id="campaign.export_statistics"/></span>
                              <DownOutlined/>
                            </Button>
                          </Dropdown>
                        }
                        <InstructionButton position='DASHBOARDS_STATISTICS'/>
                    </>
                }
            />
            <DashboardModal
                showCustomerSelect={showCustomerSelect}
                setShowCustomerSelect={setShowCustomerSelect}
                setCustomerUuid={setCustomerUuid}
                setCustomerName={setCustomerName}
            />
        </div>
    );
};

export default connect(mapStateToProps)(DashboardStatisticsExport);
