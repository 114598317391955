import React, {useState, useEffect} from 'react';
import {useIntl} from 'react-intl';
import {useNavigate} from 'react-router-dom';
import {connect} from 'react-redux';
import useHandleError from "utils/useHandleError";
import {message} from "antd";
import Spinner from "components/Spinner";
import LandingPage from "./Landing";
import AttemptPage from "./Questions";
import apiClient from 'utils/apiClient';
import coursesApiClient from 'utils/coursesApiClient';
import {clearLearningTestAttemptData} from 'store/actions/learningTestAttemptData';

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale,
        session: state.session
    };
};

const mapDispatchToProps = (dispatch: any) => ({
  clearLearningTestAttemptData: () => dispatch(clearLearningTestAttemptData()),
});

interface TestAttemptProps {
    locale?: any;
    session?: any;
    learningActivityId?: number;
    programActivityAttemptId?: number;
    isFirstAttempt?: boolean;
    parentActivityId?: number;
    campaignId?: string;
    language?: string;
    version?: string;
    defaultLearningTestId?: any;
    defaultTestAttemptId?: any;
    isPreview?: boolean;
    isOverview?: boolean;
    isStudentAttempt?: boolean;
    clearLearningTestAttemptData: Function;
}

type ServerRequestType = {
    learningActivityId?: number;
    language?: string;
    version?: string;
}

const TestAttempt: React.FC<TestAttemptProps> = ({ 
    locale, 
    session,
    learningActivityId = 0, 
    programActivityAttemptId = 0,
    isFirstAttempt, 
    parentActivityId, 
    campaignId, 
    language,
    version,
    defaultLearningTestId = 0,
    defaultTestAttemptId = 0,
    isPreview = false,
    isOverview = false,
    isStudentAttempt = false,
    clearLearningTestAttemptData
}) => {
    const [currentPage, setCurrentPage] = useState<'LANDING'|'ATTEMPT'|'COMPLETED'>('LANDING');
    const [learningTestId, setLearningTestId] = useState<number|null>(defaultLearningTestId);
    const [learningTest, setLearningTest] = useState<any>();
    const [attemptId, setAttemptId] = useState<number>(defaultTestAttemptId);
    const [lastAttempt, setLastAttempt] = useState<any>();
    const [attempt, setAttempt] = useState<any>();

    const intl = useIntl();

    const [handleError] = useHandleError();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
      clearLearningTestAttemptData()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
      if(isStudentAttempt && programActivityAttemptId) {
        loadLearningTestActivity();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isStudentAttempt, programActivityAttemptId])

    useEffect(() => {
      if(attemptId) {
        loadAttempt()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[attemptId])

    useEffect(() => {
      if(learningTestId) {
        loadLearningTest();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[learningTestId])


    const loadLearningTestActivity = async () => {
        try {
          setIsLoading(true);
          setHasError(false);
    
          if (!learningActivityId) {
            navigate(-1);
          }
    
          const parsedValues: ServerRequestType = {
            learningActivityId,
          };
    
          if (language) {
            parsedValues.language = language
          }
    
          if (version) {
            parsedValues.version = version
          }
    
          const response = await coursesApiClient.request(`/api/v1/courses/learning-tests/test-attempts/${programActivityAttemptId}/start-attempt`, parsedValues, 'POST');
    
          if (response?.message) {
            setHasError(true);
            setErrorMessage(response.message)
            return
          }
            
          if (isFirstAttempt) {
            consumeLicenceAccess();
          }
          if( response.attempt ) {
            setAttemptId(response.attempt.id)
            setLastAttempt(response.attempt)
          }
          setLearningTestId(response.material.learning_test_id)
        } catch (err: any) {
          if (err.message === 'already_active_scorm') {
            message.error(intl.formatMessage({id: 'error.already_active_activity'}));
          } else {
            setHasError(true);
            setErrorMessage('campaign.learning_activity_not_found')
            message.error(intl.formatMessage({id: 'error.data_load'}));
          }
        } finally {
          setIsLoading(false);
        }
      }
    
      const consumeLicenceAccess = async () => {
        try {
          const values = {
            activityId: learningActivityId,
            userTypeId: session.active_user_type_id,
            customerId: session.organization.organization_id,
            parentActivityId: parentActivityId,
            campaignId: campaignId
          }
    
          return await apiClient.request('/api/v1/licences/consume', values, 'POST');
        } catch(error) {
          message.error(intl.formatMessage({ id: 'error.data_load' }));
          console.error(error);
        }
      }

    const loadLearningTest = async () => {

        setIsLoading(true);
        try {
            const response = await coursesApiClient.request(`/api/v1/courses/learning-tests/${learningTestId}/load-test`, [], 'GET');
            setLearningTest(response.learningTest)
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false);
        }
    }

    const loadAttempt = async () => {

      setIsLoading(true);
      try {
          const response = await coursesApiClient.request(`/api/v1/courses/learning-tests/test-attempts/${attemptId}`, [], 'GET');
          setAttempt(response.testAttempt)
      } catch (error) {
          handleError(error)
      } finally {
          setIsLoading(false);
      }
    }

    const startLearningTest = async (startNew: boolean = false) => {
      if(startNew) {
        setAttemptId(0)
      }
      clearLearningTestAttemptData()
      setCurrentPage('ATTEMPT')
    }

    const renderPage = (page: string) => {
        switch (page) {
            case 'LANDING':
                return <LandingPage 
                    startLearningTest={startLearningTest}
                    learningTest={learningTest}
                    isParentLoading={isLoading}
                    isPreview={isPreview}
                    isOverview={isOverview}
                    isStudentAttempt={isStudentAttempt}
                    lastAttempt={lastAttempt}
                    attempt={attempt}
                /> 
            case 'ATTEMPT':
                return <AttemptPage 
                    learningTest={learningTest}
                    isParentLoading={isLoading}
                    programActivityAttemptId={programActivityAttemptId}
                    isPreview={isPreview}
                    isOverview={isOverview}
                    isStudentAttempt={isStudentAttempt}
                    attemptId={attemptId}
                    setAttemptId={setAttemptId}
                /> 
        }
    }
    
    return (
      <>
        <Spinner spinning={isLoading}>
          {learningTest && renderPage(currentPage)}
        </Spinner>
      </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(TestAttempt);
