import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Table} from "ui";
import DefaultLayout from "components/DefaultLayout";
import {FilterModal} from "../../../../components/Modals";
import {Button, message} from "antd";
import {useResource} from "../../../../hooks/Resources/useResource";
import moment from "moment/moment";
import {connect} from "react-redux";
import FileDownload from "js-file-download";
import coursesApiClient from "../../../../utils/coursesApiClient";
import FlexRow from "../../../../components/FlexRow";

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale
    }
};

const ResourceBusyness: React.FC<any> = ({ locale }) => {
    const [reload, setReload] = useState<boolean>(false);
    const [visibleTooltip, setVisibleTooltip] = useState<number | null>(null);
    const [filter, setFilter] = useState('');
    const [showResourceFilterModal, setShowResourceFilterModal] = useState(false);
    const { resourceLoading } = useResource();
    const [exportLoading, setExportLoading] = useState(false);
    const intl = useIntl();
    const [totalRecords, setTotalRecords] = useState<number>(0);

    const handleVisibleChange = (visible: boolean, recordId: number) => {
        if (visible) {
            setVisibleTooltip(recordId);
        } else {
            setVisibleTooltip(null);
        }
    }

    useEffect(() => {
        setReload(true);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locale])

    const columns: any = [
        {
            title: intl.formatMessage({ id: 'system.group' }),
            dataIndex: 'resourceGroup',
            render: (title: string, record: any) => intl.formatMessage({ id: `system.resource.type.${record.resourceGroup}` }),
            key: 'resourceGroup',
            sorter: true,
        },
        {
            title: intl.formatMessage({ id: 'general.type' }),
            dataIndex: 'resourceType',
            render: (title: string, record: any) => record.resourceType,
            key: 'resourceType',
            sorter: true,
        },
        {
            title: intl.formatMessage({ id: 'general.title' }),
            dataIndex: 'title',
            render: (title: string, record: any) => record.title,
            key: 'title',
            sorter: true,
        },
        {
            title: intl.formatMessage({ id: 'general.reserved_from' }),
            dataIndex: 'reservedFrom',
            render: (_text: string, record: any) => record.reservedFrom ? (moment(record.reservedFrom).format('YYYY-MM-DD HH:mm')) : '-',
            key: 'reservedFrom',
            sorter: true,
        },
        {
            title: intl.formatMessage({ id: 'general.reserved_till' }),
            dataIndex: 'reservedTill',
            render: (_text: string, record: any) => record.reservedTill ? (moment(record.reservedTill).format('YYYY-MM-DD HH:mm')) : '-',
            key: 'reservedTill',
            sorter: true,
        },
        {
            title: intl.formatMessage({ id: 'general.busyness' }),
            dataIndex: 'busyHours',
            render: (title: string, record: any) => record.busyHours,
            key: 'busyness',
            sorter: true,
        },
    ];

    const exportResources = async () => {
        setExportLoading(true);
        try {
            const exportResponse = await coursesApiClient.request(`/api/v1/courses/resources/export/${locale}/&${filter}`, [], 'POST', true, true);
            if (exportResponse) {
                FileDownload(exportResponse, 'Resource_report.xlsx');
            } else {
                message.error(intl.formatMessage({ id: 'error.data_load' }));
            }
            setExportLoading(false);
        } catch (e) {
            message.error(intl.formatMessage({id: "error.data_load"}));
        }
        finally {
            setExportLoading(false);
        }
    };

    return (
        <DefaultLayout.PageLayout>
            <DefaultLayout.PageHeader title={intl.formatMessage({ id: 'system.resource_busyness' })} />
            <DefaultLayout.PageContent>
                <FlexRow
                    left={
                        <>
                            <Button type="default" onClick={() => setShowResourceFilterModal(true)}>
                                <FormattedMessage id="general.filter" />
                            </Button>
                            <Button icon={<i className="fal fa-file-export"/>} onClick={() => exportResources()}>
                                <span><FormattedMessage id="users.export.export"/></span>
                            </Button>
                        </>
                    }
                />

                <div className={'table-filter-modal'}>
                    <FilterModal
                        visible={showResourceFilterModal}
                        onCancel={setShowResourceFilterModal}
                        title={'general.filter_by'}
                        okProp={'general.filter_resources'}
                        modules={{
                            resourceGroup: {mode: 'multiple', visible: true},
                            resourceType: {mode: 'multiple', visible: true},
                            resourceName: {mode: 'multiple', visible: true},
                            reservedFrom: {visible: true},
                            reservedTill: {visible: true},
                            resourceBusyness: {mode: 'multiple', visible: true},
                        }}
                        setFilter={setFilter}
                        filter={filter}
                        load={setReload}
                        locale={locale}
                    />
                </div>

                <Table
                    columns={columns}
                    url={`api/v1/courses/resources/params/busyness/${locale}`}
                    setReload={setReload}
                    reload={reload}
                    filter={filter}
                    scroll={{ x: 800 }}
                    rowSelection={false}
                    setTotalRecords={setTotalRecords}
                />

                {totalRecords ?
                    <DefaultLayout.PageFooter
                        left={
                            <div>
                                <span>{totalRecords} </span>
                                <FormattedMessage id='users.rows_selected'/>
                            </div>
                        }
                    />
                    : null
                }
            </DefaultLayout.PageContent>

        </DefaultLayout.PageLayout>
    );
};

export default connect(mapStateToProps)(ResourceBusyness);