import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import BarChart from 'components/Charts/BarChart';
import {useProgramOverview} from "hooks/Program/useProgramOverview";

const TotalTimeSpentChart: React.FC<any> = ({ programId }) => {
    const {totalTimeSpentChart, isLoading} = useProgramOverview();
    const [data, setData] = useState({});

    const intl = useIntl();

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        setData(await totalTimeSpentChart(programId));
    }

    return (
        <BarChart
            data={data}
            title={intl.formatMessage({ id: 'campaign.total_time_spent_in_elearning' })}
            dataLabelAppend={` ${intl.formatMessage({ id: 'general.hours' }).toLowerCase()}`}
            loading={isLoading}
        />
    );
}

export default TotalTimeSpentChart;