import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {changeScormData} from 'store/actions/scromData';
import coursesApiClient from 'utils/coursesApiClient';
import {useParams, useNavigate} from 'react-router-dom';
import {Button} from "ui";
import useHandleError from "../../../utils/useHandleError";
import {message} from "antd";
import HandoutsModal from "./HandoutsModal";

const mapStateToProps = (state: any) => ({
    session: state.session,
    locale: state.locale.locale,
    activityList: state.campaignActivityList
});

const mapDispatchToProps = (dispatch: any) => ({
    changeScormData: (formData: any) => dispatch(changeScormData(formData))
});

const Buttons: React.FC<any> = ({material, changeScormData, locale}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isDownloadModalVisible, setIsDownloadModalVisible] = useState(false);

    const navigate = useNavigate();
    const params = useParams();
    const [handleError] = useHandleError();

    const intl = useIntl();

    const beginLearning = async (type: 'test' | 'learn') => {

        if (getLearningAccess() && type === 'learn') {
            return
        }
        if (getTestAccess() && type === 'test') {
            return
        }

        setIsLoading(true);
        try {
            const response = await coursesApiClient.request(`/api/v1/courses/student/learning-program/${material.id}/begin`, {type}, 'POST');

            changeScormData({
                learningActivityId: response.learningActivityId,
                activityAttemptId: response.activityAttemptId,
                programActivityAttemptId: response.programActivityAttemptId,
                materialType: response.materialType,
                type: type,
                language: locale,
                learningType: 'PROGRAM'
            })

            if (response?.message) {
                message.error(intl.formatMessage({ id: response.message }));
                return;
            }

            setTimeout(function () {
                window.open('/student/show-activity')
            }, 100);

        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false);
        }
    }

    const getTestButtonTitle = () => {
        let title = 'student.start_test';

        if (material.testType === 'LEARNING_TEST' && material.status === 'PASSED') {
            title = 'learning_tests.test_overview';
        }
        return title;
    }

    const getTestAccess = () => {
        if (material.testType === 'LEARNING_TEST') {

            if (material.status === 'PASSED' && !!material.allowTestReview && !!material.accessAfterPass) {
                return false
            }

            if (material.status === 'PASSED' && !material.allowTestReview) {
                return true
            }

            if (material.testRepeats <= material.learningTestAttempts) {
                return true
            }
        }

        if (material.testType === 'SCORM_TEST') {
            if (material.status === 'PASSED' && !material.accessAfterPass) {
                return true
            }

            if (material.testRepeats + material.lastAttempt <= material.testAttempt) {
                return true
            }
        }

        return material.buttonTest === 'disabled'
    }

    const getLearningAccess = () => {
        if (material.learningStatus === 'PASSED' && !material.accessAfterPass) {
            return true;
        }

        return material.buttonLearn === 'disabled'
    }

    return (
        <>
            {!!material?.skip &&
              <Button
                loading={isLoading} type={'primary'}
                onClick={() => setIsDownloadModalVisible(true)}>
                <FormattedMessage id={'courses.material'}/>
              </Button>
            }
            {!!material.hasLearning &&
                <Button
                    className={'learning-button'}
                    disabled={getLearningAccess()}
                    loading={isLoading} type={'primary'}
                    onClick={() => beginLearning('learn')}>
                    <FormattedMessage id={`${material?.learningType === 'ASSESSMENT' ? 'general.view' : 'student.start_learning'}`}/>
                </Button>
            }
            {!!material.hasTest &&
              <Button
                  className={'test-button'}
                  disabled={getTestAccess()}
                  loading={isLoading} type={'primary'}
                  onClick={() => beginLearning('test')}>
                <FormattedMessage id={getTestButtonTitle()}/>
              </Button>
            }

            {material?.skip ?
                <HandoutsModal visible={isDownloadModalVisible} onCancel={setIsDownloadModalVisible} files={material.files} />
                : null
            }
        </>
    );
};
export default connect(mapStateToProps, mapDispatchToProps)((Buttons));
