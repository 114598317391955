import React, {useState, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Form, message, Button, Modal} from 'antd';
import {NavLink, useParams, useNavigate} from 'react-router-dom';
import DefaultLayout from 'components/DefaultLayout';
import Spinner from 'components/Spinner';
import {Input, Switch, Select, TimeZoneInput} from 'components/Form';
import apiClient from 'utils/apiClient';
import {config} from 'config/config';
import {connect} from 'react-redux';
import methods from './methods';
import {ComponentAccessCheck} from "ui";
const mapStateToProps = (state: any) => {
    return {
        session: state.session,
    }
};

const CustomerForm: React.FC<any> = ({session, hideHeader = false}) => {
    // needs rework to optimize
    const [submitInProgress, setSubmitInProgress] = useState(false);
    const [customerLoading, setCustomerLoading] = useState(true);
    const [selectLanguageList, setSelectLanguageList] = useState({});
    const [statisticData, setStatisticData] = useState(false);
    const [customerIndustry, setCustomerIndustry] = useState();

    const [form] = Form.useForm();
    const intl = useIntl();
    const params = useParams();

    const customerId = params.id;
    const canEditCustomerAdmin = session.active_user_type === 'CUSTOMER_ADMIN' && session.special_rights && session.special_rights.includes('can_manage_customers');
    const activeUserRole = session.active_user_type;
    const navigate = useNavigate();

    useEffect(() => {
        if (!customerId) {
            setCustomerLoading(false);
        }
    }, [customerId]);

    const formItemLayout = {
        labelCol: {span: 8},
        wrapperCol: {span: 6},
    };

    useEffect(() => {
        const loadLanguages = async () => {

            let languages = await apiClient.request(`/api/v1/language`, {}, 'GET');
            let languageListForSelect: any = {};
            Object.values(languages.languages).map((value: any) => {
                if (value['publish']) {
                    languageListForSelect[value['code']] = value['name']
                }
                return languageListForSelect;
            });
            setSelectLanguageList(languageListForSelect);
        };
        const loadCustomer = async () => {
            try {
                setCustomerLoading(true);
                if (activeUserRole === 'CUSTOMER_ADMIN') {
                    let customerResponse = await apiClient.request(`/api/v1/user/${session.active_user_type_uuid}/organization/edit/${customerId}`, {}, 'GET');
                    form.setFieldsValue(methods.mapItemToValues(customerResponse.data));
                } else {
                    let customerResponse = await apiClient.request(`${config.api.routes.backend.customers}/${customerId}`, {}, 'GET');
                    form.setFieldsValue(methods.mapItemToValues(customerResponse.data));
                }
            } catch (err) {
                message.error(intl.formatMessage({id: "error.data_load"}));
            } finally {
                setCustomerLoading(false);
            }
        };

        if (customerId) {
            loadCustomer();
        }

        const loadIndustry = async () => {
            try {
                setCustomerLoading(true);
                let response = await apiClient.request(config.api.routes.enums.customerIndustryTypes, {}, 'GET');

                let industry: any = {};
                Object.keys(response).map((el: string) => {
                    return industry[el] = intl.formatMessage({id: `organizations.industry.${el.toLowerCase()}`});
                })

                setCustomerIndustry(industry);
            } catch (err) {
                message.error(intl.formatMessage({id: 'error.data_load'}));
            } finally {
                setCustomerLoading(false);
            }
        }

        if (activeUserRole === 'DISTRIBUTOR_ADMIN' || activeUserRole === 'SUPER_ADMIN') {
            loadIndustry();
            setStatisticData(true);
        }
        loadLanguages();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerId, intl, form]);

    form.setFieldsValue({
        phishingEscalationAddress: config.api.supportInfoEmail,
    });

    const submitForm = async (values: any) => {
        try {
            setSubmitInProgress(true);
            if (customerId) {
                if (activeUserRole === 'CUSTOMER_ADMIN') {
                    await apiClient.request(`/api/v1/user/${session.active_user_type_uuid}/organization/edit/${customerId}/update`, methods.mapValuesForSubmit(values), 'PUT');
                } else {
                    await apiClient.request(`${config.api.routes.backend.customers}/${customerId}`, methods.mapValuesForSubmit(values), 'PUT');
                }
            } else {
                await apiClient.request(config.api.routes.backend.customers, methods.mapValuesForSubmit(values), 'POST');
            }
            setSubmitInProgress(false);

            if (canEditCustomerAdmin) {
                navigate('/user/organizations');
            } else {
                navigate('/customers');
            }

        } catch (error: any) {
            message.error(intl.formatMessage({id: "general.submit_error"}));
            if (error.status === 422) {
                let errors = JSON.parse(error.message);
                form.setFields(methods.mapSubmitErrorsToForm(errors));
                Modal.error({
                    title: 'Error',
                    content: intl.formatMessage({id: "validation.customer_email_already_exists"})
                });
            } else {
                message.error(intl.formatMessage({id: "general.submit_error"}));    // TODO: Izmantot notification
            }
            setSubmitInProgress(false);
        }
    };

    return <DefaultLayout.PageLayout withoutPageLayout={hideHeader} withStickyFooter={!hideHeader}>
        {!hideHeader &&
          <DefaultLayout.PageHeader
                breadcrumb={[{
                    name: intl.formatMessage({id: 'general.customers'}),
                    path: activeUserRole === 'CUSTOMER_ADMIN' ? '/user/organizations' : '/customers'
                }]}
                title={intl.formatMessage({id: customerId ? 'organization.edit_customer' : 'organization.add_customer'})}
            />
        }
        <Spinner spinning={submitInProgress || customerLoading} opaque={customerLoading}>
            <DefaultLayout.PageContent withTopPadding={!hideHeader} >
                <Form
                    form={form}
                    onFinish={submitForm}
                >
                    <Input
                        name='name'
                        label={intl.formatMessage({id: 'organization.organization_name'})}
                        validation={{required: true}}
                    />
                    <Input
                        name='legalName'
                        label={intl.formatMessage({id: 'organization.legal_name'})}
                        validation={{required: true}}
                    />
                    <Select
                        showSearch
                        filterOption={(input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        sortOptions={(a: any, b: any) => a[1].localeCompare(b[1])}
                        classifierIsObject={true}
                        name='country'
                        label={intl.formatMessage({id: 'general.country'})}
                        validation={{required: true}}
                        url={config.api.routes.enums.countries}
                    />
                    <Input
                        name='legalAddress'
                        label={intl.formatMessage({id: 'organization.legal_address'})}
                        validation={{required: true}}
                    />
                    <Input
                        name='officeAddress'
                        label={intl.formatMessage({id: 'organization.office_address'})}
                    />
                    <Input
                        name='vatNo'
                        label={intl.formatMessage({id: 'organization.vat_number'})}
                    />
                    <Input
                        name='bankAccountNo'
                        label={intl.formatMessage({id: 'organization.bank_account_number'})}
                    />
                    <Input
                        name='contactEmail'
                        label={intl.formatMessage({id: 'organization.business_contact_email'})}
                        validation={{required: true, type: 'email'}}
                    />
                    <Input
                        name='invoiceEmail'
                        label={intl.formatMessage({id: 'organization.invoice_email'})}
                        validation={{type: 'email'}}
                    />
                    <ComponentAccessCheck permission={'view_phishing'}>
                        <Input
                            name='phishingEscalationAddress'
                            label={intl.formatMessage({id: 'organization.phishing_email_escalation_address'})}
                            validation={{required: true, type: 'email'}}
                        />
                    </ComponentAccessCheck>
                    <Input
                        name='contactTelephone'
                        label={intl.formatMessage({id: 'organization.contact_telephone'})}
                        validation={{required: true}}/>

                    <Select
                        {...formItemLayout}
                        name='defaultPrimaryLanguage'
                        label={intl.formatMessage({id: "organization.default_primary_language"})}
                        manualOptions={selectLanguageList}
                        onChange={(value: any, label: any) => {
                            form.setFieldsValue({firstLanguage: label.children})
                        }}
                        customRules={[{required: true, message: intl.formatMessage({id: "validation.field_required"})}]}
                    />
                    <Form.Item
                        {...formItemLayout}
                        name="defaultTimeZone"
                        label={intl.formatMessage({id: "organization.default_time_zone"})}
                        rules={[{required: true, message: intl.formatMessage({id: "validation.field_required"})}]}
                    >
                        <TimeZoneInput name='defaultTimeZone'
                                       label={intl.formatMessage({id: 'organization.default_time_zone'})}
                                       validation={{required: true}}/>
                    </Form.Item>
                    <Select
                        name='status'
                        disabled={activeUserRole === 'CUSTOMER_ADMIN'}
                        label={intl.formatMessage({id: 'organization.organization_status'})}
                        customRules={[{required: true, message: intl.formatMessage({id: "validation.field_required"})}]}
                        url={config.api.routes.enums.organizationStatuses}
                    />
                    {activeUserRole !== 'CUSTOMER_ADMIN' &&
                      <>
                        <Select
                          allowClear
                          name='managingOrganizationId'
                          label={intl.formatMessage({id: 'licensing.responsible_reseller'})}
                          url={config.api.routes.backend.selectOrganizations + '?status=ACTIVE'}
                          dataKey='organizations'
                          integerKey={true}
                          customRules={[{
                              required: true,
                              message: intl.formatMessage({id: "validation.field_required"})
                          }]}
                        />
                        <Switch
                          isFormItem
                          hasDefaultLayout
                          name='waitForPayment'
                          label={intl.formatMessage({id: "organization.wait_for_payment_before_licence_activation"})}
                        />
                        <Switch
                          isFormItem
                          hasDefaultLayout
                          name='isOrganizationManager'
                          label={intl.formatMessage({id: "organization.education_center"})}
                        />
                        <Input
                          name='lockAccessAfterDays'
                          label={intl.formatMessage({id: 'organization.in_case_of_payment_delay_lock_access_to_content_after_days'})}
                          customRules={[{pattern: /^\d+$/, message: intl.formatMessage({id: "validation.must_be_integer"}),}]}
                        />
                      </>
                    }
                    {statisticData &&
                      <Select
                          {...formItemLayout}
                          name='industry'
                          label={intl.formatMessage({id: 'organization.industry'})}
                          manualOptions={customerIndustry}
                      />
                    }

                </Form>
            </DefaultLayout.PageContent>
            <DefaultLayout.PageFooter
                right={
                    <div className="form-buttons">
                        {activeUserRole === 'CUSTOMER_ADMIN' ?
                            <NavLink to="/user/organizations">
                                <Button className='outlined-button' style={{marginRight: 8}}>
                                    <FormattedMessage id="general.back"/>
                                </Button>
                            </NavLink>
                            :
                            <NavLink to="/customers">
                                <Button className='outlined-button' style={{marginRight: 8}}>
                                    <FormattedMessage id="general.back"/>
                                </Button>
                            </NavLink>
                        }
                        <Button
                            type="primary"
                            onClick={() => form.submit()}
                            loading={submitInProgress}
                        >
                            <FormattedMessage id="general.submit"/>
                        </Button>
                    </div>
                }
            />
        </Spinner>
    </DefaultLayout.PageLayout>
};

export default connect(mapStateToProps)(CustomerForm);
