import React from 'react';
import { NavLink } from 'react-router-dom';
import { Breadcrumb, Button } from 'antd';
import './styles.scss';

interface BreadcrumbObject {
  path?: string,
  name: string,
  onClick?: any;
  state?: any;
}

interface PageHeaderProps {
  breadcrumb?: BreadcrumbObject[],
  title: string,
  showTitleInBreadcrumb?: boolean,
  showEditIcon?: boolean,
  onEdit?: React.MouseEventHandler<HTMLElement> | undefined,
  loading?: boolean
  noBottomMargin?: boolean
}

const PageHeader: React.FC<PageHeaderProps> = ({breadcrumb = [], title, loading, showTitleInBreadcrumb = true, showEditIcon = false, onEdit, noBottomMargin = false}) => {

  let className = 'page-header';

  if (loading) {
    className += ' loading';
  }

  if (noBottomMargin) {
    className += ' mb-0';
  }

  const backClick = (breadcrumbLastObject: any) => {
    if (breadcrumbLastObject.onClick) {
      breadcrumbLastObject.onClick()
    }
  }

  return (
    <div className={className}>
      <Breadcrumb>
        {
          breadcrumb.map((breadcrumb: BreadcrumbObject, index: number) => {
            return (
              <Breadcrumb.Item key={index}>
                {breadcrumb.path
                  ? (
                    <NavLink to={breadcrumb.path}>
                      {breadcrumb.name}
                    </NavLink>
                  )
                  : breadcrumb.name
                }
              </Breadcrumb.Item>
            )
          })
        }
        {showTitleInBreadcrumb && <Breadcrumb.Item>
          {title}
        </Breadcrumb.Item>}
      </Breadcrumb>
      <h1>
        {
          breadcrumb.length > 0
            ? <NavLink onClick={()=> backClick(breadcrumb[breadcrumb.length - 1]) } 
                to={breadcrumb[breadcrumb.length - 1].path || '/'}
                state={breadcrumb[breadcrumb.length - 1]?.state || null}
              >
                <i className="fal fa-arrow-left" />
              </NavLink>
            : null
        }
        {title} {showEditIcon && onEdit && <Button type='link' icon={<i className="fa-solid fa-pen-to-square"></i>} onClick={onEdit} />}
      </h1>
    </div>
  )
}

export default PageHeader;
