import React, {useState, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {message, Form, Checkbox, Button, Table} from 'antd';
import DefaultLayout from 'components/DefaultLayout';
import apiClient from 'utils/apiClient';
import Spinner from 'components/Spinner';
import './styles.scss';


type aclType = {
    superAdmin: number[]
    distributorAdmin: number[]
    resellerAdmin: number[]
    customerAdmin: number[]
    superVisor: number[]
    student: number[]
}

const UserPermissions: React.FC = () => {
    const [isPermissionsLoading, setIsPermissionsLoading] = useState(true);
    const [permissions, setPermissions] = useState([]);
    const [submitInProgress, setSubmitInProgress] = useState(false);
    const [permissionsArray, setPermissionsArray] = useState<any>([]);

    const [checkedAllAdmins, setCheckedAllAdmins] = useState(false);
    const [checkedAllDAdmins, setCheckedAllDAdmins] = useState(false);
    const [checkedAllCAmins, setCheckedAllCAmins] = useState(false);
    const [checkedAllSuperVision, setCheckedAllSuperVision] = useState(false);
    const [checkedAllRAdmins, setCheckedAllRAdmins] = useState(false);
    const [checkedAllStudents, setCheckedAllStudents] = useState(false);

    const [superAdminPermissions, setSuperAdminPermissions] = useState<number[]>([]);
    const [distributorAdminPermissions, setDistributorAdminPermissions] = useState<number[]>([]);
    const [resellerAdminPermissions, setResellerAdminPermissions] = useState<number[]>([]);
    const [customerAdminPermissions, setCustomerAdminPermissions] = useState<number[]>([]);
    const [superVisionPermissions, setSuperVisionPermissions] = useState<number[]>([]);
    const [studentPermissions, setStudentPermissions] = useState<number[]>([]);


    const [aclValues, setAclValues] = useState<aclType>();

    const [form] = Form.useForm();
    useEffect(() => {
        usersPermissions();
        permissionsDb();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const intl = useIntl();

    const permissionsDb = async () => {
        try {
            setIsPermissionsLoading(true);
            let aclResponse = await apiClient.request('/api/v1/acl', {}, 'GET');
            let acl = aclResponse.acl;

            setSuperAdminPermissions(acl.superAdmin);
            setDistributorAdminPermissions(acl.distributorAdmin);
            setResellerAdminPermissions(acl.resellerAdmin);
            setCustomerAdminPermissions(acl.customerAdmin);
            setSuperVisionPermissions(acl.superVisor);
            setStudentPermissions(acl.student);

            setAclValues(acl);
        } catch (err) {
            console.error(err);
            message.error(intl.formatMessage({id: "error.data_load"}));
        } finally {
            setIsPermissionsLoading(false);
        }
    };

    const usersPermissions = async () => {
        try {
            setIsPermissionsLoading(true);
            let aclResponse = await apiClient.request('/api/v1/acl/permissions', {}, 'GET');
            let permissions = aclResponse.permissions;
            setPermissions(permissions);

            if (permissionsArray.length === 0) {
                let allPermissions = permissions.map((permission: any) => permission.id);
                setPermissionsArray(allPermissions);
            }
        } catch (err) {
            console.error(err);
            message.error(intl.formatMessage({id: "error.data_load"}));
        } finally {
            setIsPermissionsLoading(false);
        }
    };

    const submitForm = async () => {
        try {

            setSubmitInProgress(true);
            let parsedValues = {
                SUPER_ADMIN: superAdminPermissions,
                DISTRIBUTOR_ADMIN: distributorAdminPermissions,
                RESELLER_ADMIN: resellerAdminPermissions,
                CUSTOMER_ADMIN: customerAdminPermissions,
                SUPERVISION: superVisionPermissions,
                STUDENT: studentPermissions
            };

            let submitResponse = await apiClient.request('/api/v1/acl/update', parsedValues, 'POST');

            if (submitResponse.status_code > 299) throw submitResponse.message;

            setSubmitInProgress(false);
            message.success('Permissions updated successfully');
        } catch (error) {
            message.error(intl.formatMessage({id: "general.submit_error"}));    // TODO: Izmantot notification
            setSubmitInProgress(false);
        }
    };

    const selectAllPermissions = (boolean: boolean, role: any) => {

        setPermissions([]);
        if (boolean) {

            switch (role) {
                case 'SUPER_ADMIN':
                    setSuperAdminPermissions(permissionsArray);
                    setCheckedAllAdmins(boolean);
                    break;
                case 'DISTRIBUTOR_ADMIN':
                    setDistributorAdminPermissions(permissionsArray);
                    setCheckedAllDAdmins(boolean);
                    break;
                case 'RESELLER_ADMIN':
                    setResellerAdminPermissions(permissionsArray);
                    setCheckedAllCAmins(boolean);
                    break;
                case 'CUSTOMER_ADMIN':
                    setCustomerAdminPermissions(permissionsArray);
                    setCheckedAllRAdmins(boolean);
                    break;
                case 'SUPERVISION':
                    setSuperVisionPermissions(permissionsArray);
                    setCheckedAllSuperVision(boolean);
                    break;
                case 'STUDENT':
                    setStudentPermissions(permissionsArray);
                    setCheckedAllStudents(boolean);
                    break;
            }
        } else {
            switch (role) {
                case 'SUPER_ADMIN':
                    setSuperAdminPermissions([]);
                    setCheckedAllAdmins(false);
                    break;
                case 'DISTRIBUTOR_ADMIN':
                    setDistributorAdminPermissions([]);
                    setCheckedAllDAdmins(false);
                    break;
                case 'RESELLER_ADMIN':
                    setResellerAdminPermissions([]);
                    setCheckedAllCAmins(false);
                    break;
                case 'CUSTOMER_ADMIN':
                    setCustomerAdminPermissions([]);
                    setCheckedAllRAdmins(false);
                    break;
                case 'SUPERVISION':
                    setSuperVisionPermissions([]);
                    setCheckedAllSuperVision(false);
                    break;
                case 'STUDENT':
                    setStudentPermissions([]);
                    setCheckedAllStudents(false);
                    break;
            }
        }
        usersPermissions();
    };

    const checkBox = (role: string, id: number, event: boolean) => {
        switch (role) {
            case 'SUPER_ADMIN':
                if (event) {
                    setSuperAdminPermissions((superAdminPermissions: any) => [...superAdminPermissions, id]);
                } else {
                    setSuperAdminPermissions(superAdminPermissions.filter((el: any) => el !== id))
                }
                break;
            case 'DISTRIBUTOR_ADMIN':
                if (event) {
                    setDistributorAdminPermissions((distributorAdminPermissions: any) => [...distributorAdminPermissions, id]);
                } else {
                    setDistributorAdminPermissions(distributorAdminPermissions.filter((el: any) => el !== id))
                }
                break;
            case 'RESELLER_ADMIN':
                if (event) {
                    setResellerAdminPermissions((resellerAdminPermissions: any) => [...resellerAdminPermissions, id]);
                } else {
                    setResellerAdminPermissions(resellerAdminPermissions.filter((el: any) => el !== id))
                }
                break;
            case 'CUSTOMER_ADMIN':
                if (event) {
                    setCustomerAdminPermissions((customerAdminPermissions: any) => [...customerAdminPermissions, id]);
                } else {
                    setCustomerAdminPermissions(customerAdminPermissions.filter((el: any) => el !== id))
                }
                break;
            case 'SUPERVISION':
                if (event) {
                    setSuperVisionPermissions((superVisionPermissions: any) => [...superVisionPermissions, id]);
                } else {
                    setSuperVisionPermissions(superVisionPermissions.filter((el: any) => el !== id))
                }
                break;
            case 'STUDENT':
                if (event) {
                    setStudentPermissions((studentPermissions: any) => [...studentPermissions, id]);
                } else {
                    setStudentPermissions(studentPermissions.filter((el: any) => el !== id))
                }
                break;
        }
    };

    const columns = [
        {
            title: intl.formatMessage({id: 'system.permissions.permissions'}),
            dataIndex: 'permissionTitle',
            width: 250,
            render: (_text: string, record: any) => {
                return (
                    <FormattedMessage id={`system.permissions.${record.title}`}/>
                )
            }
        },
        {
            title: <div>
                <div>
                    <Button type='primary' onClick={() => selectAllPermissions(!checkedAllAdmins, 'SUPER_ADMIN')}>
                        {checkedAllAdmins ? <FormattedMessage id="users.deselect_all"/> :
                            <FormattedMessage id="users.select_all"/>}
                    </Button>
                </div>
                <div>
                    {intl.formatMessage({id: 'general.super_admin'})}
                </div>
            </div>,
            dataIndex: 'super_admin',
            align: 'center' as 'center',
            render: (_text: string, record: any) => {
                return (
                    <Checkbox
                        defaultChecked={aclValues && superAdminPermissions.includes(record.id)}
                        onChange={(event: any) => checkBox('SUPER_ADMIN', record.id, event.target.checked)}
                    />
                )
            }
        },
        {
            title: <div>
                <div>
                    <Button type='primary'
                            onClick={() => selectAllPermissions(!checkedAllDAdmins, 'DISTRIBUTOR_ADMIN')}>
                        {checkedAllDAdmins ? <FormattedMessage id="users.deselect_all"/> :
                            <FormattedMessage id="users.select_all"/>}
                    </Button>
                </div>
                <div>
                    {intl.formatMessage({id: 'general.distributor_admin'})}
                </div>
            </div>,
            dataIndex: 'distributor_admin',
            align: 'center' as 'center',
            render: (_text: string, record: any) => {
                return (
                    <Checkbox
                        defaultChecked={aclValues && distributorAdminPermissions.includes(record.id)}
                        onChange={(event: any) => checkBox('DISTRIBUTOR_ADMIN', record.id, event.target.checked)}
                    />
                )
            }
        },
        {
            title: <div>
                <div>
                    <Button type='primary' onClick={() => selectAllPermissions(!checkedAllRAdmins, 'RESELLER_ADMIN')}>
                        {checkedAllRAdmins ? <FormattedMessage id="users.deselect_all"/> :
                            <FormattedMessage id="users.select_all"/>}
                    </Button>
                </div>
                <div>
                    {intl.formatMessage({id: 'general.reseller_admin'})}
                </div>
            </div>,
            dataIndex: 'reseller_admin',
            align: 'center' as 'center',
            render: (_text: string, record: any) => {
                return (
                    <Checkbox
                        defaultChecked={aclValues && resellerAdminPermissions.includes(record.id)}
                        onChange={(event: any) => checkBox('RESELLER_ADMIN', record.id, event.target.checked)}

                    />
                )
            }
        },
        {
            title: <div>
                <div>
                    <Button type='primary' onClick={() => selectAllPermissions(!checkedAllCAmins, 'CUSTOMER_ADMIN')}>
                        {checkedAllCAmins ? <FormattedMessage id="users.deselect_all"/> :
                            <FormattedMessage id="users.select_all"/>}
                    </Button>
                </div>
                <div>
                    {intl.formatMessage({id: 'general.learning_manager'})}
                </div>
            </div>,
            dataIndex: 'learning_manager',
            align: 'center' as 'center',
            render: (_text: string, record: any) => {
                return (
                    <Checkbox
                        defaultChecked={aclValues && customerAdminPermissions.includes(record.id)}
                        onChange={(event: any) => checkBox('CUSTOMER_ADMIN', record.id, event.target.checked)}

                    />
                )
            }
        },
        {
            title: <div>
                <div>
                    <Button type='primary' onClick={() => selectAllPermissions(!checkedAllSuperVision, 'SUPERVISION')}>
                        {checkedAllSuperVision ? <FormattedMessage id="users.deselect_all"/> :
                            <FormattedMessage id="users.select_all"/>}
                    </Button>
                </div>
                <div>
                    {intl.formatMessage({id: 'user_type.roles.SUPERVISION'})}
                </div>
            </div>,
            dataIndex: 'supervision',
            align: 'center' as 'center',
            render: (_text: string, record: any) => {
                return (
                    <Checkbox
                        defaultChecked={aclValues && superVisionPermissions.includes(record.id)}
                        onChange={(event: any) => checkBox('SUPERVISION', record.id, event.target.checked)}
                    />
                )
            }
        },
        {
            title: <div>
                <div>
                    <Button type='primary' onClick={() => selectAllPermissions(!checkedAllStudents, 'STUDENT')}>
                        {checkedAllStudents ? <FormattedMessage id="users.deselect_all"/> :
                            <FormattedMessage id="users.select_all"/>}
                    </Button>
                </div>
                <div>
                    {intl.formatMessage({id: 'general.student'})}
                </div>
            </div>,
            dataIndex: 'student',
            align: 'center' as 'center',
            render: (_text: string, record: any) => {
                return (
                    <Checkbox
                        defaultChecked={aclValues && studentPermissions.includes(record.id)}
                        onChange={(event: any) => checkBox('STUDENT', record.id, event.target.checked)}

                    />
                )
            }
        }
    ];

    return (
        <DefaultLayout.PageLayout>
            <DefaultLayout.PageHeader title={intl.formatMessage({id: 'system.permissions.user_permissions'})}/>
            <DefaultLayout.PageContent>
                <Spinner spinning={submitInProgress || isPermissionsLoading} opaque={isPermissionsLoading}>
                    <Form form={form} onFinish={submitForm}>
                        {aclValues &&
                          <Table
                            size='small'
                            locale={{emptyText: intl.formatMessage({id: "general.found_no_data"})}}
                            loading={submitInProgress || isPermissionsLoading}
                            rowKey={(record) => record}
                            style={{marginTop: 16}}
                            columns={columns}
                            dataSource={permissions}
                            scroll={{x: 800}}
                            pagination={{
                                defaultPageSize: 100,
                                pageSizeOptions: ['100'],
                            }}
                          />
                        }
                    </Form>
                </Spinner>
            </DefaultLayout.PageContent>
            <DefaultLayout.PageFooter
                right={
                    <Button
                        type="primary"
                        onClick={() => form.submit()}
                        loading={submitInProgress}
                        style={{
                            height: 'auto',
                            padding: 12,
                            lineHeight: 1,
                            backgroundColor: '#19A9CE',
                            borderRadius: 8,
                            border: 0
                        }}
                    >
                        <FormattedMessage id="general.submit"/>
                    </Button>
                }
            />
        </DefaultLayout.PageLayout>
    )
};

export default UserPermissions;
