import React from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';

const mapStateToProps = (state: any) => {
  return {
    session: state.session
  }
}

const PermissionRoute: React.FC<any> = ({session = {}, permissionSlug, specialRight, ...props}) => {
  if (session.loading) {
    return <></>;
  }

  if (session.active_user_type === 'SUPER_ADMIN') {
    return props.children
  }

  if (!permissionSlug) {
    return props.children
  } else if (session.permissions) {
    var permissionSlugs = permissionSlug.split(',');

    for (const permission of permissionSlugs) {
      if (session.permissions && session.permissions.includes(permission)) {

        if (typeof specialRight !== 'undefined') {
          if (session.special_rights && specialRight.includes(',')) {

            specialRight = specialRight.split(',');

            let access = false;

            specialRight.forEach((element: string) => {
              if (session.special_rights.includes(element)) {
                access = true;
              }
            });

            if (access) {
              return props.children
            }
          }
        } else if (session.special_rights && session.special_rights.includes(specialRight)) {
          return props.children
        }
        if (specialRight === 'can_edit_email_templates') {
          if (session.active_user_type === 'CUSTOMER_ADMIN') {
            return props.children
          }
        }
        if (specialRight === 'can_manage_learning_managers') {
          if (session.active_user_type !== 'CUSTOMER_ADMIN') {
            return props.children
          }
        }

        if (specialRight === 'can_manage_customer_connection') {
          if (session.active_user_type !== 'CUSTOMER_ADMIN') {
            return props.children
          }
        }

        if (specialRight === 'can_manage_customers') {
          if (session.active_user_type !== 'CUSTOMER_ADMIN') {
            return props.children
          }
        } else {
          return props.children
        }
      }
    }
  }
  if (session.special_rights && session.special_rights.includes('can_manage_customers')) {
    return props.children
  }


  return <Navigate to="/403" replace/>
}

export default connect(mapStateToProps)(PermissionRoute);
