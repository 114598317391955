import { useState } from 'react'
import useHandleError from 'utils/useHandleError';
import apiClient from 'utils/apiClient';
import { ResourceProps, ResourceType } from 'components/Resources/types';

export const useResource = () => {
    const [handleError] = useHandleError();
    const [resourceLoading, setResourceLoading] = useState<boolean>(false)

    const deleteResource = async (resourceId: number): Promise<boolean | null> => {
        setResourceLoading(true);

        try {
            const response = await apiClient.request(`/api/v1/courses/resources/${resourceId}`, [], 'DELETE');

            if ("error" in response) {
                throw response;
            };

            if ("deleted" in response) {

                if (!response.deleted) {
                    handleError();
                }

                return response.deleted;
            };

            return null;
        } catch (error) {
            handleError(error);
            return null;
        } finally {
            setResourceLoading(false);
        }
    }

    const saveResource = async (resourceData: ResourceProps): Promise<ResourceProps | null> => {
        const resourceId = resourceData.id;
        const resource = (({ id, ...object }) => object)(resourceData);

        setResourceLoading(true);
        try {
            const response = resourceId ?
                await apiClient.request(`api/v1/courses/resources/${resourceId}`, resource, 'PUT')
                : await apiClient.request(`api/v1/courses/resources`, resource, 'POST');

            if ("error" in response) {
                throw response;
            };

            if ("resource" in response) {
                return response.resource;
            };

            return null;
        } catch (error) {
            handleError(error);
            return null;
        } finally {
            setResourceLoading(false);
        }
    }

    const getResources = async (types: ResourceType[] = []): Promise<ResourceProps[]> => {
        setResourceLoading(true);
        try {
            const response = await apiClient.request(`api/v1/courses/resources${!!types.length ? '?types=' + types.join(',') : ''}`, [], 'GET');

            if ("error" in response) {
                throw response;
            };

            if ("data" in response) {
                return response.data;
            };

            return [];
        } catch (error) {
            handleError(error);
            return [];
        } finally {
            setResourceLoading(false);
        }
    }

    const saveResourceReservation = async (reservationData: any): Promise<any | null> => {
        const reservationId = reservationData.id;
        const reservation = (({ id, ...object }) => object)(reservationData)

        setResourceLoading(true);
        try {
            const response = reservationId ?
                await apiClient.request(`api/v1/courses/resource-reservations/${reservationId}`, reservation, 'PUT')
                : await apiClient.request(`api/v1/courses/resource-reservations`, reservation, 'POST');

            if ("error" in response) {
                throw response;
            };

            if ("reservation" in response) {
                return response.reservation;
            };

            return null;
        } catch (error) {
            handleError(error);
            return null;
        } finally {
            setResourceLoading(false);
        }
    }

    const getCalendarResourceReservations = async (filter: string = ''): Promise<any | null> => {
        setResourceLoading(true);
        try {
            let url = 'api/v1/courses/resource-reservations/calendar'

            if (filter) {
                url += filter
            }

            const response = await apiClient.request(url, [], 'GET');

            if ("error" in response) {
                throw response;
            };

            if ("data" in response) {
                return response.data;
            };

            return null;
        } catch (error) {
            handleError(error);
            return null;
        } finally {
            setResourceLoading(false);
        }
    }

    

    return { 
        getResources,
        saveResource, 
        deleteResource, 
        saveResourceReservation,
        getCalendarResourceReservations,
        resourceLoading 
    }
}
