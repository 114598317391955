import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import DefaultLayout from "components/DefaultLayout";
import { Tabs } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import { useSession } from "hooks/Lectures/useSession";
import { useLecture } from "hooks/Lectures/useLecture";
import Spinner from 'components/Spinner';
import { SessionProps, LectureProps } from 'components/Lectures/types';
import SessionDetails from 'components/Lectures/SessionTabs/SessionDetails';
import SessionWaitingList from 'components/Lectures/SessionTabs/SessionWaitingList';
import SessionAttendees from 'components/Lectures/SessionTabs/SessionAttendees';
import SessionNotifications from 'components/Lectures/SessionTabs/SessionNotifications';


const LectureSession: React.FC = () => {
    const intl = useIntl();
    const params = useParams();
    const lectureId = params.lecturesId ? +params.lecturesId : undefined;
    const sessionId = params.id && !isNaN(+params.id) && +params.id > 0 ? params.id : params.id === 'create' ? -1 : undefined
    const [lecture, setLecture] = useState<LectureProps>({});
    const [session, setSession] = useState<SessionProps>({});
    const [reload, setReload] = useState(false);
    const navigate = useNavigate();
    const { getSessionyById, sessionLoading } = useSession()
    const { getLectureById } = useLecture()

    useEffect(() => {
        if (lectureId) {
            loadlLecture()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lectureId, reload])

    const loadlLecture = async () => {
        if (!!lectureId) {
            const lecture = await getLectureById(lectureId);
            if (lecture && lecture.id) {
                setLecture(lecture);
            } else {
                navigate('/lectures');
            }
        } else {
            navigate('/lectures');
        }
    }

    const loadSession = async () => {
        if (lecture.id && !!sessionId) {
            if (+sessionId > 0) {
                const session = await getSessionyById(+lecture.id, +sessionId);
                if (session && session.id) {
                    setSession(session);
                } else {
                    navigate('/lectures');
                }
            }
        } else {
            navigate('/lectures');
        }
    }

    useEffect(() => {
        if (!!lecture && !!lecture.id) {
            loadSession();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lecture])

    const reloadSession = () => {
        setReload(!reload);
    }

    return (
        <>
            {!!lecture && <DefaultLayout.PageLayout style={{ marginBottom: 64 }}>
                <DefaultLayout.PageHeader
                    title={`${session.title ?? intl.formatMessage({ id: 'general.lectures.add_new_session' })}`}
                    breadcrumb={[{
                        name: intl.formatMessage({ id: 'general.lectures.catalogue' }),
                        path: '/lectures'
                    },
                    {
                        name: `${lecture.title ?? intl.formatMessage({ id: 'general.lectures.add_new_session' })}`,
                        path: `/lectures/${lectureId}`
                    }]}
                />
                <DefaultLayout.PageContent >
                    <Spinner spinning={sessionLoading} >
                        {session && <Tabs destroyInactiveTabPane
                            items={[
                                {
                                    label: intl.formatMessage({ id: 'general.lectures.session_details' }),
                                    key: 'details',
                                    children: <SessionDetails lecture={lecture} session={session} reloadSession={reloadSession} />,
                                },
                                {
                                    label: intl.formatMessage({ id: 'general.lectures.attendees' }),
                                    key: 'attendees',
                                    children: <SessionAttendees lecture={lecture} session={session} reloadSession={reloadSession} />,
                                    disabled: session && !session.id
                                },
                                {
                                    label: intl.formatMessage({ id: 'general.lectures.waiting_list' }),
                                    key: 'waiting',
                                    children: <SessionWaitingList lecture={lecture} session={session} reloadSession={reloadSession} />,
                                    disabled: session && !session.id
                                },
                                {
                                    label: intl.formatMessage({ id: 'general.notifications' }),
                                    key: 'notifications',
                                    children: <SessionNotifications lecture={lecture} session={session} reloadSession={reloadSession} />,
                                    disabled: session && !session.id
                                },
                            ]}
                        />}
                    </Spinner>
                </DefaultLayout.PageContent>
            </DefaultLayout.PageLayout>}
        </>
    );
};

export default LectureSession;