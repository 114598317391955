import React, {useEffect, useState} from 'react';
import {useUsers} from "hooks/Users/useUsers";
import FilterCheckBox from "../FilterCheckBox";
import FilterPopover from "../FilterPopover";
import {Button} from "ui";
import {FormattedMessage} from "react-intl";

interface OrganizationalUnitsFilterInterface {
    tagBuilder: Function;
    customers: string[];
    departments: string[];
    setOrgUnits: React.Dispatch<React.SetStateAction<string[]>>;
    setFilterValues: any;
    filterValues: any
    form: any;
    filterKey: string;
    setFilterKey: React.Dispatch<React.SetStateAction<string>>;
}

const OrganizationalUnitsFilter: React.FC<OrganizationalUnitsFilterInterface> = ({
                                                                                     filterKey,
                                                                                     setFilterKey,
                                                                                     form,
                                                                                     setFilterValues,
                                                                                     filterValues,
                                                                                     tagBuilder,
                                                                                     customers,
                                                                                     departments,
                                                                                     setOrgUnits
                                                                                 }) => {
    const {getOrganizationalUnits, loading} = useUsers();
    const [data, setData] = useState([]);
    const [initialValues, setInitialValues] = useState(undefined)

    const key = 'org-units'

    const onSearch = async (value: string = '') => {
        const response = await getOrganizationalUnits(value, customers, departments);
        setData(response);

        setFilterValues(
            {
                ...filterValues,
                orgUnit: {
                    ...filterValues.orgUnit,
                    ...response
                }
            }
        )
    };

    useEffect(() => {
        onSearch();
    }, [customers, departments]);

    const content = (
        <>
            <FilterCheckBox setData={setOrgUnits} data={data} name={'orgUnit'} onSearch={onSearch}/>
            <div className={'flex justify-between p-1'}>
                <Button type={'link'} onClick={() => handleClose()}>
                    <FormattedMessage id={'general.cancel'}/>
                </Button>
                <Button type={'primary'} onClick={() => {
                    setFilterKey('')

                    handleOpen(false)
                }}>
                    <FormattedMessage id={'general.select'}/>
                </Button>
            </div>
        </>
    );

    const handleClose = () => {
        form.setFieldsValue(initialValues);
        setFilterKey('')

    }

    const handleOpen = (open: boolean) => {
        if (open) {
            setInitialValues(form.getFieldsValue())
            setFilterValues(
                {
                    ...filterValues,
                    orgUnit: {
                        ...filterValues.orgUnit,
                        ...data
                    }
                }
            )
        } else {
            tagBuilder()
        }
    }

    return (
        <FilterPopover
            popoverKey={key}
            filterKey={filterKey}
            setOpen={setFilterKey}
            handleOpen={handleOpen}
            content={content}
            loading={loading}
            title={'general.search_by_org_unit'}
            buttonTitle={'general.org_unit'}
        />
    );
};

export default OrganizationalUnitsFilter;