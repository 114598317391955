import React, {useEffect, useState} from 'react';
import {useCustomer} from "hooks/Customers/useCustomer";
import {CustomerDepartmentsStructureProps} from "../types";
import {useParams} from "react-router-dom";
import Spinner from "components/Spinner";
import {Space, Table} from "antd";
import {FormattedMessage, useIntl} from "react-intl";
import {Button} from "ui";
import {RequestSelect} from "components/Form";
import './styles.scss';

interface CustomerOrganizationalStructureInterface {
}

const CustomerOrganizationalStructure: React.FC<CustomerOrganizationalStructureInterface> = ({}) => {
    const {getDepartments, saveResourcePlanner, loading} = useCustomer();
    const [structure, setStructure] = useState<CustomerDepartmentsStructureProps[]>([]);
    const [visibleTooltip, setVisibleTooltip] = useState<number | null>(null);
    const [selectedUser, setSelectedUser] = useState<number | undefined>(0);

    const params = useParams();
    const customerId = params.id;
    const intl = useIntl();

    useEffect(() => {
        if (customerId) {
            loadDepartments(customerId).then((data: CustomerDepartmentsStructureProps[]) => {
                setStructure(data);
            })
        }
    }, [])

    const loadDepartments = async (id: string) => {
        return await getDepartments(id);
    };

    const handleVisibleChange = (visible: boolean, recordId: number) => {
        if (visible) {
            setVisibleTooltip(recordId);
        } else {
            setVisibleTooltip(null);
        }
    };

    const handleFormSubmit = async (values: any) => {
        if (customerId) {
            const data = await saveResourcePlanner(customerId, values);
            setStructure(data);
            setVisibleTooltip(null);
        }
    };

    const columns = [ // ColumnsType<DataType>
        {
            title: intl.formatMessage({id: 'general.departments'}),
            dataIndex: 'title',
        },
        {
            title: intl.formatMessage({id: 'general.organizational_unit'}),
            dataIndex: 'unit_title',
        },
        {
            title: intl.formatMessage({id: 'general.resource_planner'}),
            dataIndex: 'unit_title',
            width: '55%',
            render: (text: string, record: any) => {
                return (
                    <>
                        {!(visibleTooltip === record.id) ?
                            <>
                                {record.user_id ?
                                    <div className={'flex justify-between align-center'}>
                                        <div className={'structure-tag'}>
                                            {record.email}
                                        </div>
                                        <Button type={'link'} onClick={() => handleVisibleChange(true, record.id) }>
                                            <FormattedMessage id={'general.edit'}/>
                                        </Button>
                                    </div>
                                    :
                                    <Button type={'dashed'} onClick={() => handleVisibleChange(true, record.id)}>
                                        <FormattedMessage id={'general.add'}/>
                                    </Button>
                                }
                            </>
                            :
                            <Space size={8}>
                                <RequestSelect
                                    isForm={false}
                                    defaultValue={record?.user_id ? record.type_id + '' : null}
                                    selectClassName='request-select'
                                    allowClear
                                    onChange={(typeId: number | undefined) => setSelectedUser(typeId)}
                                    url={`/api/v1/edu/users/mass-search`}
                                    initialValueData={record?.user_id ? {[record.type_id]: record.email} : null}
                                    param={[`customerId=${customerId}&key=type_id&bothRoles=false&onlyCustomer=true`]}
                                    column
                                    name={'managerReferenceId'}
                                    showSearch
                                />

                                <Button type={'link'} onClick={() => handleFormSubmit({
                                    ...record,
                                    user_type_id: selectedUser ? selectedUser : null
                                })}>
                                    <FormattedMessage id={'general.save'}/>
                                </Button>
                                {record.user_id &&
                                  <Button type={'link'} onClick={() => handleVisibleChange(false, record.id)}>
                                    <FormattedMessage id={'general.cancel'}/>
                                  </Button>
                                }
                            </Space>

                        }
                    </>

                )
            }
        },
    ];

    return (
        <Spinner spinning={loading}>
            <Table
                columns={columns}
                rowKey={'id'}
                pagination={false}
                dataSource={structure}
            />
        </Spinner>
    )
};

export default CustomerOrganizationalStructure;
