import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button, Space, Card, Table as AntTable, message, Modal, Form, DatePicker, Radio } from 'antd';
import DefaultLayout from 'components/DefaultLayout';
import apiClient from 'utils/apiClient';
import moment from 'moment';
import Table from 'components/Table';
import { paginatorQuery } from 'components/Table/methods';
import { EyeOutlined } from '@ant-design/icons';
import './styles.scss';
import Spinner from 'components/Spinner';
import { Select } from 'components/Form';

const mapStateToProps = (state: any) => ({
  organization: state.session.organization,
  session: state.session
});

interface AuditProps {
  organization: undefined | {
    organization_type: string;
    organization_uuid: string;
  };
  session:any
}

const Audit: React.FC<AuditProps> = ({ organization, session}) => {
  const intl = useIntl();

  const [resourceUrl, setResourceUrl] = useState<string>('');
  const [objectTypesUrl, setObjectTypesUrl] = useState<string>('');
  const [objectNamesUrl, setObjectNamesUrl] = useState<string>('');
  const [logUsersUrl, setLogUsersUrl] = useState<string>('');

  const pageSizeOptions = [10, 20]
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [currentPageSize, setCurrentPageSize] = useState<number>(pageSizeOptions[0]);
  const [loading, setLoading] = useState(false);
  const [isPreparingFilter, setIsPreparingFilter] = useState(false);

  const [isLogDetailsVisible, setIsLogDetailsVisible] = useState(false);
  const [logDetailsContent, setLogDetailsContent] = useState({country:null, ip:null, properties:{}, os:null, browser:null});
  const [logDetailsModalTitle, setLogDetailsModalTitle] = useState('');
  const [logsUsers, setLogsUsers] = useState<any>([]);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [auditFilter, setAuditFilter] = useState({});

  const [selectedOrg, setSelectedOrg] = useState<any>({org_type:null, organization_uuid:null});
  const [filterBy, setFilterBy] = useState('ORGANIZATION');

  const [logs, setLogs] = useState([]);
  const [form] = Form.useForm();

  const { RangePicker } = DatePicker;

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 20 }
  };

  useEffect(() => {
    if (session.active_user_type !== 'SUPER_ADMIN') {
      let requestUrl = setRequestParams(null, organization)

      loadData(requestUrl);
    } else if (selectedOrg.org_type == null) {
      setFilterBy('ORGANIZATION')
      setShowFilterModal(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization, session]);

  const loadData = async (requestUrl:string) => {
    try {
      setLoading(true);

      let query = paginatorQuery(1, currentPageSize)
      let logsResponse = await getRecords(requestUrl, query);

      setLogs(logsResponse.data);
      setTotalRecords(logsResponse.pagination.total)
      setCurrentPageSize(pageSizeOptions[0])
    } catch (err) {
      console.error(err);
      message.error(intl.formatMessage({id: "error.data_load"}));
    } finally {
      setLoading(false);
    }
  }

  const setRequestParams = (orgType = null, org:any) => {
      let requestUrl = ''
      let objectTypesRequestUrl = ''
      let objectNamesRequestUrl = ''
      let logUsersRequestUrl = ''

      let orgIdentity = (orgType != null) ? orgType : session.active_user_type

      switch (orgIdentity) {
        case 'SUPER_ADMIN':
          requestUrl = '/api/v1/audit/global/logs';
          objectTypesRequestUrl = '/api/v1/audit/global/object-types'
          objectNamesRequestUrl = `/api/v1/audit/global/object-names`
          logUsersRequestUrl = `/api/v1/audit/global/users`

          break;
        case 'DISTRIBUTOR_ADMIN':
        case 'RESELLER_ADMIN':
        case 'ORGANIZATION':
          requestUrl = `/api/v1/audit/organization/${org?.organization_uuid}/logs`;
          objectTypesRequestUrl = '/api/v1/audit/organization/object-types'
          objectNamesRequestUrl = `/api/v1/audit/organization/${org?.organization_uuid}/object-names`
          logUsersRequestUrl = `/api/v1/audit/organization/${org?.organization_uuid}/users`
          break;
        case 'CUSTOMER_ADMIN':
        case 'CUSTOMER':
          requestUrl = `/api/v1/audit/customer/${org?.organization_uuid}/logs`;
          objectTypesRequestUrl = '/api/v1/audit/customer/object-types'
          objectNamesRequestUrl = `/api/v1/audit/customer/${org?.organization_uuid}/object-names`
          logUsersRequestUrl = `/api/v1/audit/customer/${org?.organization_uuid}/users`
          break;
      }

      setResourceUrl(requestUrl)
      setObjectTypesUrl(objectTypesRequestUrl)
      setObjectNamesUrl(objectNamesRequestUrl)
      setLogUsersUrl(logUsersRequestUrl)
      setLogsUsers([])

      return requestUrl
  }

  const getRecords = async (resourceUrl: string, pageQuery: string, filter = {}) => {
    let query = (pageQuery !== '') ? '?' + pageQuery : ''

    if (Object.keys(filter).length === 0 && auditFilter.hasOwnProperty('filter')) {
      filter = auditFilter
    }

    let response = await apiClient.request(resourceUrl + query, filter, 'POST');

    setLogsUsers(response.users)

    return  {
      data: (response.activityLogs) ? response.activityLogs : {},
      pagination: (response.pagination) ? response.pagination : {}
    }
  }

  const formatUserName = (record: any) => {
    if (typeof logsUsers != 'undefined' && logsUsers.hasOwnProperty(record.user_type_uuid)) {
      let userData = logsUsers[record.user_type_uuid]

      return userData.name + ' ' + userData.surname
    } else {
      return record.user_type_uuid
    }
  }

  const formatEventProperties = (data: any) => {
    let source = []

    const dataNew = {...data}
    delete dataNew.properties

    source = Object.keys(dataNew).map(function(key) {
        return {
          key: key,
          value: data[key]
        }
    })

    return <AntTable
      columns={eventPropertiesColumns}
      dataSource={source}
      style={{marginTop: '20px'}}
      showHeader={false}
      pagination={{hideOnSinglePage: true}}
      size="small"
      className='event-properties-table'
      />;
  }

  const formatObjectToString = (obj:any) => {
    let content = Object.keys(obj).map(function(key) {
      return <p><b>{key}:</b> {(typeof obj[key] === 'object' && obj[key] !== null) ? <div><pre>{JSON.stringify(obj[key], null, 2) }</pre></div> : obj[key]}</p>
    })

    return content
  }

  const formatProperties = (json: any) => {
    if (typeof json === 'object') {
      return Object.keys(json).map(function(key) {
        switch (key) {
          case 'attributes':
            let attributes = json.attributes
            let changedFields = []

            changedFields = Object.keys(attributes).map(function(key) {
              let oldValue = ''

              if (json.hasOwnProperty('old')) {
                oldValue = json.old[key]
              }

              return {
                field: key,
                new: (typeof attributes[key] === 'object' && attributes[key] !== null) ? formatObjectToString(attributes[key]) : attributes[key],
                old: (typeof oldValue === 'object' && oldValue !== null) ? formatObjectToString(oldValue) : oldValue
              }
            })

            return <AntTable columns={changesTableColumns} dataSource={changedFields} style={{marginTop: '20px', maxWidth: '100%'}} />
          case 'error':
            return  <Card title={intl.formatMessage({id: 'general.error'})} bordered={false} >
              <p>{json.error}</p>
            </Card>
          case 'enrollment':
            if (json.enrollment.hasOwnProperty('removed')) {
              const content = json.enrollment.removed.map(function(item:any) {
                return <p><b>Removed student:</b> {item.student}</p>
              })

              return  <Card title={''} bordered={false} >{content}</Card>
            } else if (json.enrollment.hasOwnProperty('added')) {
              const content = json.enrollment.added.map(function(item:any) {
                return <p><b>Added student:</b> {item.student}</p>
              })

              return  <Card title={''} bordered={false} >{content}</Card>
            } else if (json.enrollment.hasOwnProperty('cancelled')) {
              const content = json.enrollment.cancelled.map(function(item:any) {
                return <p><b>Cancelled student:</b> {item.student}</p>
              })

              return  <Card title={''} bordered={false} >{content}</Card>
            } else {
              return ''
            }
          case 'courses':
            const content = Object.keys(json.courses).map(function(key) {
              return json.courses[key].map(function(item:any) {
                return <p><b>{key}</b> : {item.name}</p>
              })
            });

            return  <Card title={'Campaign courses'} bordered={false} >{content}</Card>
          case 'campaign_activities':
            const campaignLogContent = Object.keys(json.campaign_activities).map(function(key) {
              return json.campaign_activities[key].map(function(item:any) {
                return <p><b>{key}</b> : {item.name}</p>
              })
            });

            return  <Card title={'Campaign activities'} bordered={false} >{campaignLogContent}</Card>
          case 'usersList':
            const userListLogContent = Object.keys(json.usersList).map(function(key) {
              let item = json.usersList[key]

              return <p>ID: {item.id} {item.name} {item.surname} ({item.email})</p>
            });

            return  <Card title={'Seen users'} bordered={false} >{userListLogContent}</Card>
          default:
            return ''
        }
      })
    } else {
      return ''
    }
  }

  const columns = [
    {
      title: intl.formatMessage({id: 'system.audit.datetime'}),
      dataIndex: 'created_at',
      render: (value: string, record: any) =>
        moment(record.created_at).format('DD.MM.YYYY HH:mm:ss')
    },
    {
      title: intl.formatMessage({id: 'system.audit.log_name'}),
      key: 'log_name',
      render: (value: string, record: any) => record.log_name
    },
    {
      title: intl.formatMessage({id: 'system.audit.caused_by'}),
      key: 'caused_by',
      render: (value: string, record: any) => formatUserName(record)
    },
    {
      title: intl.formatMessage({id: 'system.audit.action_type'}),
      key: 'event',
      render: (value: string, record: any) => record.description
    },
    {
      title: intl.formatMessage({id: 'system.audit.object_name'}),
      key: 'object_name',
      render: (value: string, record: any) => record.object_name
    },
    {
      title: intl.formatMessage({id: 'system.audit.attributes'}),
      key: 'attributes',
      render: (text: string, record: any) => {
        return <p
            className='description-icon'
          onClick={() => {
            setLogDetailsModalTitle(intl.formatMessage({id: "system.audit.log_details_modal_title"}));
            setLogDetailsContent({
              country: record.country,
              ip: record.client_ip,
              properties: record.properties,
              os: record.client_os,
              browser: record.client_browser
            });
            setIsLogDetailsVisible(true);
          }}
          >
          <FormattedMessage id={'general.show'}/> <EyeOutlined />
        </p>
      }
    },
  ];

  const changesTableColumns = [
    {
      title: intl.formatMessage({id: 'general.field'}),
      dataIndex: 'field',
      render: (value: string, record: any) =>
        <b>{record.field}</b>
    },
    {
      title: intl.formatMessage({id: 'system.audit.new'}),
      dataIndex: 'new',
      render: (value: string, record: any) =>
        record.new
    },
    {
      title: intl.formatMessage({id: 'system.audit.old'}),
      dataIndex: 'old',
      textWrap: 'word-break',
      render: (value: string, record: any) =>
        record.old
    }
  ]

  const eventPropertiesColumns:any = [
    {
      title: intl.formatMessage({id: 'system.audit.key'}),
      dataIndex: 'key',
      align: 'right',
      render: (value: string, record: any) => <b>{record.key }</b>
    },
    {
      title: intl.formatMessage({id: 'system.audit.value'}),
      dataIndex: 'value',
      render: (value: string, record: any) =>
        record.value
    }
  ]

  const filterAudit = async (values: any) => {
    try {
      setIsPreparingFilter(true)
      setLoading(true)

      let parsedValues:any = {
        filter: []
      }

      Object.keys(values).map(function(key) {
        switch (key) {
          case 'period':
            if (typeof values[key] !== 'undefined' && values[key] !== null) {
              parsedValues.filter = [
                ...parsedValues.filter,
                {
                  field: 'period',
                  condition: 'period',
                  value: {
                    startDate: values.period[0].format('YYYY-MM-DD HH:mm:00'),
                    endDate: values.period[1].format('YYYY-MM-DD HH:mm:00')
                  }
                }
              ]
            }
            break
          case 'customer':
          case 'organization':
            break;
          default:
            if (typeof values[key] !== 'undefined') {
              parsedValues.filter = [
                ...parsedValues.filter,
                {
                  field: key,
                  condition: '=',
                  value: values[key]
                }
              ]
            }
        }
        return parsedValues;
      })

      setAuditFilter(parsedValues)

      let query = paginatorQuery(1, currentPageSize)
      let logsResponse = await getRecords(resourceUrl, query, parsedValues);

      setLogs(logsResponse.data);
      setCurrentPage(1)
      setTotalRecords(logsResponse.pagination.total)
    } catch (err) {
      console.error(err);
      message.error(intl.formatMessage({id: "error.data_load"}));
    } finally {
      setIsPreparingFilter(false)
      setLoading(false)
      setShowFilterModal(false)
    }
  }

  const openFilter = async () => {
    try {
      setShowFilterModal(true)

      setIsPreparingFilter(true)
    } catch (err) {
      console.error(err);
      message.error(intl.formatMessage({id: "error.data_load"}));
    } finally {
      setIsPreparingFilter(false)
    }
  }

  const selectOrg = (org:any) => {
    setRequestParams(org.org_type, org)
  }

  return (
    <DefaultLayout.PageLayout>
      <DefaultLayout.PageHeader title={intl.formatMessage({ id: 'system.audit.title' })} />
        <DefaultLayout.PageContent>
            <Space>
              <Button type="primary" onClick={() => {openFilter()}}>
                <FormattedMessage id="general.filter" />
              </Button>
            </Space>
            <Table
              resourceUrl = {resourceUrl}
              totalRecords = {totalRecords}
              loadedData = {logs}
              setLoadedData = {setLogs}
              setTotalRecords = {setTotalRecords}
              setCurrentPageSize = {setCurrentPageSize}
              currentPage={currentPage}
              setCurrentPage = {setCurrentPage}
              setLoading = {setLoading}
              loading = {loading}
              columns = {columns}
              pageSizeOptions = {pageSizeOptions}
              getRecords = {getRecords}
            />
            <Modal
              title={logDetailsModalTitle}
              open={isLogDetailsVisible}
              width={'75%'}
              onCancel={() => setIsLogDetailsVisible(false)}
              onOk={() => setIsLogDetailsVisible(false)}
              cancelButtonProps={{ style: { display: 'none' } }}
              okButtonProps={{ disabled: true, style: { display: 'none' } }}
              >
                {formatEventProperties(logDetailsContent)}
                {formatProperties(logDetailsContent.properties)}
            </Modal>
            <Modal
              className="audit-logs-modal"
              open={showFilterModal}
              title={intl.formatMessage({id: 'system.audit.audit_filter'})}
              onOk={()=> {form.submit()}}
              onCancel={() => {setShowFilterModal(false)}}
              okText={intl.formatMessage({id: 'general.filter'})}
              cancelText={intl.formatMessage({id: 'general.cancel'})}
             >
              <Spinner spinning={isPreparingFilter} opaque={isPreparingFilter}>
                <Form form={form} onFinish={filterAudit} {...formItemLayout}>
                  <Form.Item
                    label={intl.formatMessage({id: 'general.filter_by'})}
                    style={(session.active_user_type === 'SUPER_ADMIN' || session.active_user_type === 'DISTRIBUTOR_ADMIN' || session.active_user_type === 'RESELLER_ADMIN') ? { display: ''} : {display: 'none'}}
                  >
                    <Radio.Group
                      defaultValue={'ORGANIZATION'}
                      onChange={(el: any) => {
                        setFilterBy(el.target.value);

                        if (session.active_user_type !== 'SUPER_ADMIN' && el.target.value === 'ORGANIZATION') {
                          let org = {org_type: organization?.organization_type, organization_uuid: organization?.organization_uuid}

                          setSelectedOrg(org)
                          selectOrg(org)
                        } else {
                          let org = {org_type: null, organization_uuid: null}

                          setSelectedOrg(org)

                          form.setFieldsValue({customer: null})
                        }
                      }}
                    >
                      <Radio value="ORGANIZATION"><FormattedMessage id='general.reseller'/></Radio>
                      <Radio value="CUSTOMER"><FormattedMessage id='general.customer'/></Radio>
                    </Radio.Group>
                  </Form.Item>
                  {
                    filterBy === 'ORGANIZATION' && session.active_user_type === 'SUPER_ADMIN' ?
                    <Select
                      url='/api/v1/organizations/options/uuids'
                      showSearch
                      name="organization"
                      label={intl.formatMessage({id: 'general.reseller'})}
                      onChange={(value: any) => {
                        let org = {org_type: filterBy, organization_uuid: value}
                        setSelectedOrg(org)
                        selectOrg(org)
                      }}
                      defaultValue={organization?.organization_uuid}
                      allowClear
                      customLayout={formItemLayout}
                      filterOption={(input: string, option: any) => {
                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                      }}
                      customRules={[{ required: true, message: intl.formatMessage({id: "validation.field_required"})}]}
                    />
                    : ''
                  }
                  {
                    (filterBy === 'CUSTOMER') ?
                      <Select
                        url={(session.active_user_type === 'RESELLER_ADMIN' || session.active_user_type === 'DISTRIBUTOR_ADMIN') ? '/api/v1/organizations/' + organization?.organization_uuid + '/customers/options' : '/api/v1/customers/options/uuids'}
                        showSearch
                        name="customer"
                        label={intl.formatMessage({id: 'general.customer'})}
                        onChange={(value: any) => {
                          let org = {org_type: filterBy, organization_uuid: value}
                          setSelectedOrg(org)
                          selectOrg(org)
                        }}
                        allowClear
                        customLayout={formItemLayout}
                        filterOption={(input: string, option: any) => {
                          return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                        }}
                        customRules={[{ required: true, message: intl.formatMessage({id: "validation.field_required"})}]}
                      />
                    : ''
                  }
                  <Form.Item label={intl.formatMessage({id: "system.audit.period"})} name="period" {...formItemLayout}>
                    <RangePicker
                      showTime={{ format: 'HH:mm' }}
                      format="YYYY-MM-DD HH:mm"
                      onChange={() => {}}
                      onOk={() => {}}
                    />
                  </Form.Item>
                  <Select
                    url={logUsersUrl}
                    showSearch
                    name="user_uuid"
                    label={intl.formatMessage({id: 'system.audit.caused_by'})}
                    allowClear
                    filterOption={(input: string, option: any) => {
                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                    customLayout={formItemLayout}
                  />
                  <Select
                    url={objectTypesUrl}
                    showSearch
                    name="log_name"
                    label={intl.formatMessage({id: 'system.audit.log_name'})}
                    allowClear
                    customLayout={formItemLayout}
                  />
                  <Select
                    url={objectNamesUrl}
                    showSearch
                    name="object_name"
                    label={intl.formatMessage({id: 'system.audit.object_name'})}
                    allowClear
                    customLayout={formItemLayout}
                  />
                  <Select
                    url='/api/v1/audit/event-types'
                    showSearch
                    name="description"
                    label={intl.formatMessage({id: 'system.audit.action_type'})}
                    onChange={(value: any) => {form.setFieldsValue({value})}}
                    allowClear
                    customLayout={formItemLayout}
                  />
                </Form>
              </Spinner>
            </Modal>
        </DefaultLayout.PageContent>
    </DefaultLayout.PageLayout>
  );
};

export default connect(mapStateToProps)(Audit);
