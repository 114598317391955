import React, { useEffect, useState } from 'react';
import { Button, Table } from 'ui';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import { config } from 'config/config';
import { Checkbox, Divider, message, Popover, Tag } from 'antd';
import generalHelpers from 'utils/generalHelpers';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { connect } from 'react-redux';
import coursesApiClient from 'utils/coursesApiClient';
import FileDownload from 'js-file-download';

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale,
    };
};

const defaultFields: string[] = [
    'fullName',
    'personalCode',
    'email',
    'organization',
    'isCompleted',
    'lastActivity',
    'position',
    'hireDate',
    'organizationalUnit',
    'department',
    'accountStatus',
    'createdAt',
    'competenceId',
    'competenceTitle',
    'competenceStatus',
    'recertification',
    'programId',
    'title',
    'validityTerm',
    'enrollDate',
    'deadline',
    'status',
    'enrollmentType',
    'mandatory',
    'recertificationDate',
    'attemptCount',
    'timeSpent',
    'tableIndex',
    'typeUuid',
    'integrationId',
];

const LearningProgramReport: React.FC<any> = ({
    filter,
    locale = config.defaultEnvironmentLocale,
    load,
    setLoad,
    form,
}) => {
    const [reload, setReload] = useState(false);
    const [exportLoading, setExportLoading] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState<CheckboxValueType[]>(defaultFields);
    const [openPopover, setOpenPopover] = useState(false);
    const [displayColumns, setDisplayColumns] = useState<any>([]);

    const cacheKey = 'sessionColumns_PROGRAM';

    const intl = useIntl();

    useEffect(() => {
        if (filter !== null && load) {
            filterSession();
            setLoad(false);
        }

        const hasColumns = localStorage.getItem(cacheKey);

        if (hasColumns) {
            setDisplayColumns(hasColumns.split(','));
            setSelectedColumns(hasColumns.split(','));
        }
    }, [load]);

    const filterSession = () => {
        setReload(true);
    };

    const columns = [
        {
            title: intl.formatMessage({ id: 'general.fullname' }),
            dataIndex: 'fullName',
            key: 'fullName',
            sorter: true,
        },
        {
            title: intl.formatMessage({ id: 'general.personal_code' }),
            dataIndex: 'personalCode',
            key: 'personalCode',
        },
        {
            title: intl.formatMessage({ id: 'general.email' }),
            dataIndex: 'email',
            key: 'email',
            sorter: true,
        },
        {
            title: intl.formatMessage({ id: 'general.organization' }),
            dataIndex: 'organization',
            key: 'organization',
            sorter: true,
        },
        {
            title: intl.formatMessage({ id: 'general.position' }),
            dataIndex: 'position',
            key: 'position',
            sorter: true,
        },
        {
            title: intl.formatMessage({ id: 'users.form.commencement_date' }),
            dataIndex: 'hireDate',
            key: 'hireDate',
            sorter: true,
            render: (date: string) => (date ? moment(date).format(config.defaultDateFormat) : '-'),
        },
        {
            title: intl.formatMessage({ id: 'general.organizationalUnit' }),
            dataIndex: 'organizationalUnit',
            key: 'organizationalUnit',
            sorter: true,
        },
        {
            title: intl.formatMessage({ id: 'general.department' }),
            dataIndex: 'department',
            key: 'department',
            sorter: true,
        },
        {
            title: intl.formatMessage({ id: 'general.accountStatus' }),
            dataIndex: 'accountStatus',
            key: 'accountStatus',
            sorter: true,
            render: (_text: string, record: any, tag: any) => {
                let color;
                if (record.accountStatus === 'ACTIVE') color = 'green';
                if (record.accountStatus === 'BLOCKED') color = 'red';
                return (
                    <Tag color={color} key={tag} className={record.accountStatus}>
                        {intl.formatMessage({ id: `user.status.${record.accountStatus}` })}
                    </Tag>
                );
            },
        },
        {
            title: intl.formatMessage({ id: 'general.createdAt' }),
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: true,
            render: (date: string) => (date ? moment(date).format(config.defaultDateFormat) : '-'),
        },
        {
            title: intl.formatMessage({ id: 'competences.competence_id' }),
            dataIndex: 'competenceId',
            key: 'competenceId',
            sorter: true,
        },
        {
            title: intl.formatMessage({ id: 'competences.competence_title' }),
            dataIndex: 'competenceTitle',
            key: 'competenceTitle',
            sorter: true,
        },
        {
            title: intl.formatMessage({ id: 'general.status' }),
            dataIndex: 'competenceStatus',
            key: 'competenceStatus',
            sorter: true,
            render: (_text: string, record: any) => {
                if (!record.competenceStatus) {
                    return;
                }
                let color = 'red';
                let status = 'competences.status_not_valid';

                switch (record.competenceStatus) {
                    case 'VALID':
                        color = 'green';
                        status = 'competences.status_valid';
                        break;
                    case 'EXPIRING':
                        color = 'gold';
                        status = 'competences.status_expiring';
                        break;
                }
                return (
                    <Tag color={color}>
                        <FormattedMessage id={status} />
                    </Tag>
                );
            },
        },
        {
            title: intl.formatMessage({ id: 'program.recertification' }),
            dataIndex: 'recertification',
            key: 'recertification',
            sorter: true,
            render: (date: string) => (date ? moment(date).format(config.defaultDateFormat) : '-'),
        },
        {
            title: intl.formatMessage({ id: 'program.program_id' }),
            dataIndex: 'programId',
            key: 'programId',
            sorter: true,
        },
        {
            title: intl.formatMessage({ id: 'program.title' }),
            dataIndex: 'title',
            key: 'title',
            sorter: true,
            render: (_text: string, record: any) => {
                return record.title[locale]
                    ? record.title[locale]
                    : record.title[Object.keys(record.title)[0]];
            },
        },
        {
            title: intl.formatMessage({ id: 'program.validity_term' }),
            dataIndex: 'validityTerm',
            key: 'validityTerm',
            sorter: true,
            render: (date: string) => (date ? moment(date).format(config.defaultDateFormat) : '-'),
        },
        {
            title: intl.formatMessage({ id: 'program.enroll_date' }),
            dataIndex: 'enrollDate',
            key: 'enrollDate',
            sorter: true,
            render: (date: string) => (date ? moment(date).format(config.defaultDateFormat) : '-'),
        },
        {
            title: intl.formatMessage({ id: 'program.deadline' }),
            dataIndex: 'deadline',
            key: 'deadline',
            sorter: true,
            render: (date: string) => (date ? moment(date).format(config.defaultDateFormat) : '-'),
        },
        {
            title: intl.formatMessage({ id: 'program.status' }),
            dataIndex: 'status',
            key: 'status',
            sorter: true,
            render: (_text: string, record: any, tag: any) => {
                let color;
                if (record.status === 'NOT_STARTED') color = 'gold';
                if (record.status === 'IN_PROGRESS') color = 'blue';
                if (record.status === 'COMPLETED') color = 'green';
                if (record.status === 'FAILED') color = 'red';
                if (record.status === 'CANCELLED') color = 'red';

                return (
                    <Tag color={color} key={tag} className={record.status}>
                        {intl.formatMessage({
                            id: `general.${record.status.toLowerCase()}`,
                        })}
                    </Tag>
                );
            },
        },
        {
            title: intl.formatMessage({ id: 'general.enrollment_type' }),
            dataIndex: 'enrollmentType',
            render: (enrollmentType: any, record: any) => {
                switch (enrollmentType) {
                    case 'SELF_ENROLLMENT':
                    case 'INVITE_ENROLLMENT':
                    case 'MANUAL_ENROLLMENT':
                        return intl.formatMessage({
                            id: 'program.enrollment.' + enrollmentType.toLowerCase(),
                        });
                    case 'AUDIENCE_ENROLLMENT':
                        return generalHelpers.replaceAll(record.audienceEnrollment, ',', ';') + ';';
                    case 'ENROLLMENT_CANCELLED':
                        return '-';
                }
            },
        },
        {
            title: intl.formatMessage({ id: 'program.is_completed' }),
            dataIndex: 'isCompleted',
            key: 'isCompleted',
            sorter: true,
            render: (date: string) => (date ? moment(date).format(config.defaultDateFormat) : '-'),
        },
        {
            title: intl.formatMessage({ id: 'general.last_activity' }),
            dataIndex: 'lastActivity',
            key: 'lastActivity',
            sorter: true,
            render: (date: string) => (date ? moment(date).format(config.defaultDateFormat) : '-'),
        },
        {
            title: intl.formatMessage({ id: 'program.mandatory' }),
            dataIndex: 'mandatory',
            key: 'mandatory',
            sorter: true,
        },
        {
            title: intl.formatMessage({ id: 'program.attempt_count' }),
            dataIndex: 'attemptCount',
            key: 'attemptCount',
            sorter: true,
        },
        {
            title: intl.formatMessage({ id: 'student.time_spent' }),
            dataIndex: 'timeSpent',
            key: 'timeSpent',
            render: (time: string) => generalHelpers.secondsToHHmm(time),
        },
        {
            title: intl.formatMessage({ id: 'general.user_identifier' }),
            dataIndex: 'typeUuid',
            sorter: true,
        },
        {
            title: intl.formatMessage({ id: 'general.integration_user_identifier' }),
            dataIndex: 'integrationId',
        },
    ];

    const content = (
        <div>
            <Checkbox.Group
                value={selectedColumns}
                onChange={(checkboxGroupColumns: CheckboxValueType[]) =>
                    setSelectedColumns(checkboxGroupColumns)
                }
            >
                {Object.values(columns).map((el: any) => {
                    return (
                        <div key={el.dataIndex} className={'flex p-1 gap-5'}>
                            <Checkbox key={el.dataIndex} value={el.dataIndex} />
                            {el.title}
                        </div>
                    );
                })}
            </Checkbox.Group>
            <Divider plain className={'m-2'} />
            <div className={'flex justify-between p-1'}>
                <Button type={'link'} onClick={() => handleCancel()}>
                    <FormattedMessage id={'general.cancel'} />
                </Button>
                <Button type={'primary'} onClick={() => handleColumnSubmit()}>
                    <FormattedMessage id={'general.select'} />
                </Button>
            </div>
        </div>
    );

    const handleColumnSubmit = () => {
        setDisplayColumns(selectedColumns);
        localStorage.setItem(cacheKey, selectedColumns.toString());
        setOpenPopover(false);
    };

    const handleCancel = () => {
        const hasColumns = localStorage.getItem(cacheKey);

        if (hasColumns) {
            setSelectedColumns(hasColumns.split(','));
        } else {
            setSelectedColumns(defaultFields);
        }
        setOpenPopover(false);
    };

    const exportData = async () => {
        const query = filter.replace('&', '?');

        setExportLoading(true);
        try {
            const url = '/api/v1/courses/reports/export/PROGRAM' + query;
            const response = await coursesApiClient.request(url, [], 'GET', true, true);

            if (response.fileName) {
                FileDownload(response, response.fileName);
            } else {
                message.error(intl.formatMessage({ id: 'error.data_load' }));
            }
        } catch (error) {
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        } finally {
            setExportLoading(false);
        }
    };

    return (
        <div className={'reports-element'}>
            <div className={'flex justify-between'}>
                <Button loading={exportLoading} type={'outline'} onClick={() => exportData()}>
                    <FormattedMessage id={'general.export'} />
                </Button>
                <Popover
                    open={openPopover}
                    placement="bottomRight"
                    title={intl.formatMessage({ id: 'general.columns' })}
                    content={content}
                    trigger="click"
                >
                    <Button type={'outline'} onClick={() => setOpenPopover(!openPopover)}>
                        <i className="fa-solid fa-table-columns"></i>
                    </Button>
                </Popover>
            </div>
            <Table
                columns={columns.filter((el: any) => displayColumns.includes(el.dataIndex))}
                url={'/api/v1/courses/reports/learning-program'}
                reload={reload}
                setReload={setReload}
                rowKey={'tableIndex'}
                filter={filter}
                scroll={{ x: true }}
                rowSelection={false}
                showTotalResults={true}
                hasCustomFields={false}
            />
        </div>
    );
};

export default connect(mapStateToProps)(LearningProgramReport);
