import React from 'react';
import PageUnderConstruction from "../../PageUnderConstruction";
import CustomerForm from "../../Customers/CustomerForm";

interface CustomerSettingsInterface {
}

const CustomerSettings: React.FC<CustomerSettingsInterface> = ({}) => {

    return (
        <CustomerForm hideHeader={true} />
    )
};

export default CustomerSettings;
