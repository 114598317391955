import axios, { AxiosRequestConfig } from 'axios';
import { routes } from '../config/config';
import Cookies from 'js-cookie';

interface TokenResponse {
    access_token: string;
    expires_at: number;
}

let tokenPromise: Promise<string> | null = null;

export const axiosInstance = axios.create({
    baseURL: `${routes.api.baseUrl}`,
});

axiosInstance.interceptors.request.use(
    async (config: AxiosRequestConfig) => {
        const token: string | undefined = Cookies.get('jwt');
        const refreshToken: string | undefined = Cookies.get('jwt_refresh');

        if (!token && refreshToken) {
            if (!tokenPromise) {
                // Creating a new Promise to fetch a token if one doesn't exist
                tokenPromise = axios
                    .get<TokenResponse>(`${routes.api.baseUrl}/api/v1/refresh-token`, {
                        headers: {
                            Authorization: `Bearer ${refreshToken}`,
                        },
                    })
                    .then((response) => {
                        Cookies.set('jwt', response.data.access_token, { expires: response.data.expires_at / (24 * 60 * 60) });
                        return response.data.access_token;
                    })
                    .catch((error) => {
                        console.error('Error refreshing token:', error);
                        throw error;
                    })
                    .finally(() => {
                        // Resetting the tokenPromise once the request is complete
                        tokenPromise = null;
                    });
            }

            // Waiting for the tokenPromise to resolve or reject before proceeding
            await tokenPromise;

            if (!!config.headers) {
                config.headers['Authorization'] = `Bearer ${Cookies.get('jwt_refresh')}`;
            }
        } else {
            if (!!config.headers) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response.data?.error?.includes('Unauthenticated') || error.response?.status === 401) {
            Cookies.remove('jwt');
            Cookies.remove('jwt_refresh');
            return new Promise(() => {});
        }

        return Promise.reject(error);
    }
);
