import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Tabs} from 'antd';
import DefaultLayout from 'components/DefaultLayout';
import {useParams} from 'react-router-dom';
import {useRules} from "hooks/Rules/useRules";
import RulesResults from "../Results";
import RulesForm from "../Form";
import Spinner from "components/Spinner";

const Rule: React.FC<any> = ({}) => {
    const {getRule, loading} = useRules();
    const [rule, setRule] = useState<any>();

    const params = useParams();
    const ruleId = params.id;
    const intl = useIntl();

    useEffect(() => {
        if (ruleId) {
            loadRule();
        }
    }, [ruleId]);

    const loadRule = async () => {
        if (ruleId) {
            setRule(await getRule(ruleId))
        }
    }

    return (
        <DefaultLayout.PageLayout>
            <Spinner spinning={loading}>
                <DefaultLayout.PageHeader
                    breadcrumb={
                        [{name: intl.formatMessage({id: 'general.rules'}), path: '/rules'}]
                    }
                    title={rule?.id ? rule.title : intl.formatMessage({id: 'rule.rule'})}
                />
                <DefaultLayout.PageContent withBottomMargin>
                    <Tabs items={[
                        {
                            label: intl.formatMessage({id: 'general.settings'}),
                            key: 'details',
                            children: <RulesForm rule={rule}/>,
                        },
                        {
                            label: intl.formatMessage({id: 'rule.results'}),
                            key: 'results',
                            children: <RulesResults rule={rule}/>,
                            disabled: !ruleId
                        },
                    ]}
                    />
                </DefaultLayout.PageContent>
            </Spinner>
        </DefaultLayout.PageLayout>
    )
}

export default Rule;
