import React from 'react';
import { Form, Checkbox as AntDCheckbox } from 'antd';

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 6 },
};

const Checkbox: React.FC<any> = ({name, label, style, ...props}) => {
  return (
    <Form.Item
      {...formItemLayout}
      name={name}
      label={label}
      style={style}
    >
      <AntDCheckbox {...props} />
    </Form.Item>
  )
}

export default Checkbox;
