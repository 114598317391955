import React, {useEffect} from 'react';
import {useIntl, FormattedMessage} from 'react-intl';
import {Form, Button, Alert} from 'antd';
import {Input} from 'components/Form';
import {EnvironmentSettingsType,} from '../../types';
import Spinner from 'components/Spinner';
import DefaultLayout from 'components/DefaultLayout';
import useQueryApiClient from "utils/useApiClient";

interface VdocipherTabProps {
    type: EnvironmentSettingsType;
    selectedResellerId: number;
    selectedCustomerId: number;
}

const VdocipherTab: React.FC<VdocipherTabProps> = ({type, selectedResellerId, selectedCustomerId}) => {
    const intl = useIntl();
    const [form] = Form.useForm();

    useEffect(() => {
        if (selectedResellerId !== 0 || selectedCustomerId !== 0) {
            fetch()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedResellerId, selectedCustomerId]);

    const {fetch, isFetching} = useQueryApiClient({
        request: {
            disableOnMount: type !== 'global',
            url: type === 'global'? 'api/v1/environment/global/VDOCIPHER' : `api/v1/environment/${type}/${selectedCustomerId ? selectedCustomerId : selectedResellerId}/VDOCIPHER`,
        },
        onSuccess: (response) => {
            if (response?.noAppearanceSettingsFound) {
                form.resetFields();
            } else {
                form.setFieldsValue(response.settings);
            }
        },
    });

    const {appendData: submitForm, isLoading} = useQueryApiClient({
        request: {
            url: type === 'global'? 'api/v1/environment/global/VDOCIPHER' : `api/v1/environment/${type}/${selectedCustomerId ? selectedCustomerId : selectedResellerId}/VDOCIPHER`,
            method: 'POST',
        },
        onSuccess: () => {
        },
    });

    return (
        <>
            <Spinner spinning={isFetching || isLoading}>
                {
                    selectedCustomerId || selectedResellerId || type === 'global' ?
                        <>
                            <Form form={form} onFinish={submitForm}>
                                <Input name="vdocipherApi"
                                       label={intl.formatMessage({id: 'system.environment.vdocipher_api'})}
                                       validation={{required: true}}/>
                            </Form>
                            <DefaultLayout.PageFooter
                                right={
                                    <Button
                                        type="primary"
                                        loading={isLoading}
                                        onClick={form.submit}
                                    >
                                        <span><FormattedMessage id="general.save"/></span>
                                    </Button>
                                }
                            />
                        </>
                        :
                        <Alert
                            message={intl.formatMessage({id: `system.environment.please_select_a_${type === 'organization' ? 'reseller' : 'customer'}`})}
                            type="info"
                            showIcon
                        />
                }
            </Spinner>
        </>
    );
}

export default VdocipherTab;