import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { message, Modal, Form, Button } from 'antd';
import coursesApiClient from 'utils/coursesApiClient';
import useHandleError from "utils/useHandleError";

import Spinner from 'components/Spinner';
import {Table} from "ui";
import moment from "moment";
import "./styles.scss";

const formItemLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 18},
};

const QuestionVersionListModal: React.FC<any> = ({ open, onCancel, questionId, showVersionDetailPopup }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [reload, setReload] = useState(false);
    const [questions, setQuestions] = useState<any>([]);
    const [totalRecords, setTotalRecords] = useState<number>(0);

    const intl = useIntl();
    const [copyForm] = Form.useForm();
    const [handleError] = useHandleError();

    useEffect(() => {
        if(open) {
            setReload(true)
        }
    }, [open])

    const columns = [
        {
          title: intl.formatMessage({id: 'question_pools.version'}),
          dataIndex: 'versionNo',
          key: 'versionNo',
          render: (text: number, record: any) => {
            return <Button type='link' onClick={() => showVersionDetailPopup(record.id)}>{text}</Button>
          }
        },
        {
          title: intl.formatMessage({id: 'question_pools.modification_date'}),
          dataIndex: 'updatedAt',
          key: 'modification_date',
          render: (text: string, record: any) => {
            return moment(record.updatedAt).format('L')
          }
        },
        {
            title: intl.formatMessage({id: 'question_pools.modified_by'}),
            dataIndex: 'name',
            key: 'name',
        },
    ];
    
    return (
        <Modal
            className="question-version-list-modal"
            title={intl.formatMessage({ 
                id: 'question_pools.version_history_modal_title' 
            }, {
                id: questionId
            })}
            open={open}
            onCancel={onCancel}
            okButtonProps={{
                style: {
                    display: 'none',
                }
            }}
            cancelButtonProps={{
                style: {
                    display: 'none',
                }
            }}
        >
            <Spinner spinning={isLoading}>
                <Table
                    columns={columns}
                    url={`/api/v1/courses/question-pools/questions/${questionId}/get-versions`}
                    setReload={setReload}
                    reload={reload}
                    rowSelection={false}
                    rowKey={'id'}
                    // pagination={{
                    //     pageSize: 8
                    // }}
                    setTotalRecords={setTotalRecords}
                />
            </Spinner>
        </Modal>
    )
}

export default QuestionVersionListModal;
