import React, {useEffect, useState} from 'react';
import {FormattedMessage} from "react-intl";
import {Popover} from "antd";

interface CompetenceSwitchProps {
    possibleFilters: any,
    filtersSelected: any,
    setFiltersSelected: React.Dispatch<React.SetStateAction<any>>,
}

const CompetenceSwitch: React.FC<CompetenceSwitchProps> = ({possibleFilters, filtersSelected, setFiltersSelected}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedCompetence, setSelectedCompetence] = useState('all');

    useEffect(()=> {
       if (selectedCompetence === 'all') {
           setFiltersSelected((filtersSelected:any) => ({ ...filtersSelected, competences: undefined}));
       }
    },[selectedCompetence])

    useEffect(()=> {
        if (!Object.keys(filtersSelected).length) {
            setSelectedCompetence('all');
        }
    },[filtersSelected])

    const PopoverContent = () => {
        return (
            <>
                {
                    Object.entries(possibleFilters.competences).map((values:any, key) => {
                        if (values[0] !=selectedCompetence) {
                            return (
                                <div key={values[0]} className='select-item' onClick={() => {setSelectedCompetence(values[0]); setFiltersSelected((filtersSelected:any) => ({ ...filtersSelected, competences: values[0]})); setIsOpen(false)}}><FormattedMessage id={values[1]} /></div>
                            )
                        }
                    })
                }
            </>
        )
    };

    return <div>
        <Popover
            placement='bottom'
            content={<PopoverContent />}
            overlayClassName='session-switch-popover'
            arrowContent={false}
            visible={isOpen}
            trigger='click'
            onOpenChange={() => setIsOpen(!isOpen)}
        >
            <div className='filter-switch'>
                <span>
                    {possibleFilters.competences[selectedCompetence]}
                </span>
                <i className='fal fa-chevron-down' style={{fontSize: 10}} />
            </div>
        </Popover>
    </div>
}

export default CompetenceSwitch;