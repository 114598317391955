import React, { useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { message, Button, Form, Space, Upload, Empty } from 'antd';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import useHandleError from "utils/useHandleError";
import { FileTableExtended } from 'ui';
import Spinner from 'components/Spinner';
import coursesApiClient from 'utils/coursesApiClient';
import {useDate} from 'hooks/useDate';

interface AssessmentFilesInterface {
    activityFormData: any;
    assessmentSettings: any;
    submission: any;
    loadAssignment: Function;
    setIsSubmitLoading: React.Dispatch<React.SetStateAction<boolean>>;
    isSubmitLoading: boolean;
    getStatusTag: Function;
    session?: any;
}
const mapStateToProps = (state: any) => ({
    session: state.session,
});

const AssessmentFiles: React.FC<AssessmentFilesInterface> = ({
    activityFormData,
    assessmentSettings,
    submission,
    loadAssignment,
    setIsSubmitLoading,
    isSubmitLoading,
    getStatusTag,
    session
}) => {
    const [overviewRows, setOverviewRows] = useState<any[]>([]);
    const [isFilesLoading, setIsFilesLoading] = useState(false);
    const [attachments, setAttachments] = useState<any>();

    const intl = useIntl();
    const [handleError] = useHandleError();
    const [form] = Form.useForm();
    const { Dragger } = Upload;
    const {formatDate} = useDate();

    useEffect(() => {
        if(submission && activityFormData) {
            if (submission && activityFormData.attemptData.attachmentsData && activityFormData.attemptData.attachmentsData.length > 0) {
                const attachmentsArray = activityFormData.attemptData.attachmentsData.map((file: any) => {
                    file.date = formatDate(file.date);
                    return file;
                });
                
                setAttachments(attachmentsArray)
            } else {
                setAttachments([])
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[activityFormData])

    const uploadAttachment = async (file: any) => {
        setIsFilesLoading(true);
        setIsSubmitLoading(true);
        try {
            if (activityFormData) {
                let bodyFormData = new FormData();
                bodyFormData.append('file', file.file);
                const response = await coursesApiClient.request(`/api/v1/courses/assessment-results/upload-attachment/${submission.id}`, bodyFormData, 'POST');
                loadAssignment()
            }
        } catch (error) {
            handleError(error)
        } finally {
            setIsFilesLoading(false);
            setIsSubmitLoading(false);
        }
    }
    
    return (
        <div className='assessment-files'>
            {activityFormData.attemptData.canUploadAttachments && <div className='assignment-section'>
                <div className='assignment-title-bar'>
                    <div className='assignment-title flex'>
                        <h3><FormattedMessage id='general.upload_files' /></h3>
                    </div>
                </div>
                <Spinner spinning={isFilesLoading}>
                    <div className='assignment-upload-button'>
                        <Dragger 
                            fileList={[]}
                            customRequest={(file: any) => uploadAttachment(file)}
                            multiple
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-hint"><FormattedMessage id='general.drag_file_upload' /></p>
                        </Dragger>
                        <Upload
                            customRequest={(file: any) => uploadAttachment(file)}
                            multiple
                            fileList={[]} >
                            <Button type='ghost'><Space size={8}><UploadOutlined /><FormattedMessage id='general.upload_files' /></Space></Button>
                        </Upload>
                    </div>
                </Spinner>
            </div>}
            <div className='assignment-section'>
                <div className='assignment-title-bar'>
                    <div className='assignment-title flex'>
                        <h3><FormattedMessage id='general.files' /></h3>
                    </div>
                </div>
                <div className='resource-container'>
                    {attachments && !!attachments.length ? <FileTableExtended 
                        key={'attachments'} 
                        fileArray={attachments} 
                        showMenu={false} 
                        allowDelete={activityFormData.attemptData.canUploadAttachments} 
                        showLabels={false}
                        dateBelowTitle={true}
                        cols={['title', 'user', 'date', 'type', 'size']}
                        urls={{
                            download: `api/v1/courses/material/storage/{path}`,
                            delete: `/api/v1/courses/assessment-results/delete-attachment/${submission.id}`
                        }}
                        onDelete={() => loadAssignment()}
                        theme='with-bg'
                    /> : <Empty
                        description={intl.formatMessage({id: 'general.no_uploads'})}
                    />}
                </div>
            </div>
        </div >
    )
}
export default connect(mapStateToProps)(AssessmentFiles);