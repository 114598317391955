import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Form, Modal, Tooltip, Divider, message } from 'antd';
import moment from 'moment';
import { Select, Input } from 'components/Form';
import PlatformAccess from './PlatformAccess';
import ELearningContent from './ELearningContent';
import {config} from 'config/config';
import apiClient from 'utils/apiClient';
import AppearanceService from './AppearanceService';
import generalHelpers from "utils/generalHelpers";
import Spinner from 'components/Spinner';

const LicenceServiceModal = ({
  edit, setEdit, editService,
  userRole, user, organization,
  licenceServices, showAddNewService, setShowAddNewService,
  licenceId, platformAccessLicence, appearanceLicence,
  learningLicence,
  phishingLicence,
  serviceLicence,
  responsibleOrganizations, responsibleAdmins, loadLicence, session, customerId
}: any) => {
  const [submitInProgress, setSubmitInProgress] = useState<boolean>(false);
  const [serviceType, setServiceType] = useState<String|null>(null);
  const [servicesLoading, setServicesLoading] = useState<boolean>(false);
  const [services, setServices] = useState<[]>([]);
  const [serviceId, setServiceId] = useState<number|null>(null);
  const [serviceList, setServiceList] = useState<[]>([]);
  const [validFromDate, setValidFromDate] = useState<moment.Moment|null>(null);
  const [validToDate, setValidToDate] = useState<moment.Moment|null>(null);
  const [targetKeys, setTargetKeys] = useState([]);
  const [activitiesValid, setActivitiesValid] = useState<boolean>(false);
  const [activitiesHasErrors, setActivitiesHasErrors] = useState<boolean>(false);
  const [indefinite, setIndefinite] = useState<boolean>(false);
  const [organizationId, setOrganizationId] = useState<number|null>(null);
  const [organizationList, setOrganizationList] = useState([]);
  const [organizationsLoading, setOrganizationsLoading] = useState<boolean>(false);
  const [userList, setUserList] = useState([]);
  const [usersLoading, setUsersLoading] = useState<boolean>(false);
  const [responsibleOrganizationId, setResponsibleOrganizationId] = useState<number|null>(null);
  const [responsibleAdminId, setResponsibleAdminId] = useState<string|null>(null);
  const [adminId, setAdminId] = useState<string|null>(null);
  const [activitiesService, setActivitiesService] = useState([]);

  const intl = useIntl();
  const [form] = Form.useForm();

  useEffect(() => {
    if (platformAccessLicence && serviceType !== 'PLATFORM_ACCESS') {
      form.setFieldsValue({
        typeReadOnly: intl.formatMessage({ id: 'services.platform_access' }),
        type: 'PLATFORM_ACCESS'
      });
      if ('PLATFORM_ACCESS' !== serviceType) {
        setServiceType('PLATFORM_ACCESS');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platformAccessLicence, serviceType]);

  useEffect(() => {
    if (appearanceLicence && serviceType !== 'APPEARANCE_SERVICE') {
      form.setFieldsValue({
        typeReadOnly: intl.formatMessage({ id: 'services.appearance_service' }),
        type: 'APPEARANCE_SERVICE'
      });
      if ('APPEARANCE_SERVICE' !== serviceType) {
        setServiceType('APPEARANCE_SERVICE');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appearanceLicence, serviceType]);

  useEffect(() => {
    if (learningLicence && serviceType !== 'E_LEARNING_CONTENT') {
      form.setFieldsValue({
        typeReadOnly: intl.formatMessage({ id: 'services.e_learning_content' }),
        type: 'E_LEARNING_CONTENT'
      });
      if ('E_LEARNING_CONTENT' !== serviceType) {
        setServiceType('E_LEARNING_CONTENT');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [learningLicence, serviceType]);

  useEffect(() => {
    if (generalHelpers.hasPermissions(session, 'view_phishing') && phishingLicence && serviceType !== 'PHISHING_SERVICE') {
      form.setFieldsValue({
        typeReadOnly: intl.formatMessage({ id: 'services.phishing_service' }),
        type: 'PHISHING_SERVICE'
      });
      if ('PHISHING_SERVICE' !== serviceType) {
        setServiceType('PHISHING_SERVICE');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phishingLicence, serviceType]);

  useEffect(() => {
    if (edit) {
      const editServiceType = editService.type;

      form.setFieldsValue({
        typeReadOnly: intl.formatMessage({ id: `services.${editServiceType.toLowerCase()}` }),
        type: editServiceType
      });

      if (editServiceType !== serviceType) {
        setServiceType(editServiceType);
      }

      let validFrom;
      let validTo;

      if (editService.pivot) {
        validFrom = moment(editService.pivot.beginDate);
        if (editService.pivot.endDate) {
          validTo = moment(editService.pivot.endDate);
        } else {
          validTo = moment().add(2,'years')
        }

      } else {
        if (editService.validFrom) {
          validFrom = editService.validFrom ? moment(editService.validFrom) : null;
        } else {
          validFrom = editService.beginDate ? moment(editService.beginDate) : null;
        }

        validTo = editService.validTo ? moment(editService.validTo) : null;
      }

      setValidFromDate(validFrom);
      setValidToDate(validTo);

      const serviceData = editService.pivot ? editService.pivot : editService;
      let organizationId = serviceData.responsibleOrganizationId;
      let adminId = serviceData.responsibleAdminId;

        if (organizationId && adminId) {
            form.setFieldsValue({
                organization: (platformAccessLicence) ? serviceData.organizationName : responsibleOrganizations[organizationId],
                user: responsibleAdmins[adminId] ? responsibleAdmins[adminId].name : null,
                validFrom,
                validTo
            });

            setResponsibleOrganizationId(organizationId);
            setResponsibleAdminId(adminId);
        }

        if (!organizationId && !adminId && userRole !== 'SUPER_ADMIN') {
            organizationId = session.organization.organization_id;
            adminId = session.id;

            setResponsibleOrganizationId(organizationId);
            setResponsibleAdminId(adminId);
        }


      switch(editServiceType) {
        case 'E_LEARNING_CONTENT':
          const settings = JSON.parse(editService.pivot.settings);

          setTargetKeys(settings.learning_activities);

          form.setFieldsValue({
            service: editService.name,
            validFrom,
            validTo,
            maxLearningActivities: settings.max_learning_activities,
            maxAccess: settings.max_access,
            actionAfterLicenceEndTerm: settings.action_after_licence_end_term
          });
          setIndefinite(!!editService.hasEndDate);

          setServiceId(editService.id);
          break;
        case 'PLATFORM_ACCESS':
          setIndefinite(!editService.hasEndDate);

          form.setFieldsValue({
            validFrom,
            maxUsers: editService.maxUsers,
            indefinite: !editService.hasEndDate
          });
          break;
        case 'PHISHING_SERVICE':
          if (generalHelpers.hasPermissions(session, 'view_phishing')) {
            setIndefinite(!editService.hasEndDate);

            form.setFieldsValue({
              validFrom,
              maxUsers: editService.maxUsers,
              indefinite: !editService.hasEndDate
            });
          }

          break;

      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit]);

  useEffect(() => {
    const loadServices = async () => {
      try {
        setServicesLoading(true);

        const response = await apiClient.request(`/api/v1/services/type/${serviceType}/active`, {}, 'GET');

        let services = response.services;
        setActivitiesService(services);
        if (!edit) {
          // Filter out services that already exist on the licence
          services = response.services.filter((service: any) => {
            if ((licenceServices.findIndex((licenceService: any) => licenceService.uuid === service.uuid)) !== -1) {
              return false;
            }
            return true;
          });
        }

        setServices(services);

        const servicesObject: any = {};

        Object.values(services).map((service: any) => {
          return servicesObject[service['id']] = service['name'];
        });

        setServiceList(servicesObject);
      } catch (error) {
        message.error(intl.formatMessage({id: 'error.data_load'}));
      } finally {
        setServicesLoading(false);
      }
    }

    if (serviceType && serviceType !== 'PLATFORM_ACCESS') {
      if (session.active_user_type === 'SUPER_ADMIN' || session.active_user_type === 'RESELLER_ADMIN' || session.active_user_type === 'DISTRIBUTOR_ADMIN' ) {
        loadServices();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceType]);

  useEffect(() => {
    const hasActivities: boolean = !!targetKeys.length;

    setActivitiesValid(hasActivities);

    if (hasActivities) {
      setActivitiesHasErrors(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetKeys])

  const submitForm = async (values: any) => {
    try {
      setSubmitInProgress(true);

      let parsedValues: any;

      switch (serviceType) {
        case 'E_LEARNING_CONTENT':
          if (!activitiesValid) {
            setActivitiesHasErrors(true);
            return;
          };

          setActivitiesHasErrors(false);

          parsedValues = {
            licenceId: licenceId,
            serviceId: serviceId,
            settings: {
              learning_activities: [...targetKeys],
              max_learning_activities: values.maxLearningActivities,
              max_access: values.maxAccess,
              // action_after_licence_end_term: values.actionAfterLicenceEndTerm,
              action_after_licence_end_term: 'CONTINUE_TO_ALLOW_ACCESS_WITHIN_THE_LIMITS_OF_MAXIMUM_NUMBER_OF_ACCESSES',
            },
            splitLicenseServices: values.splitLicenseServices ? !!values.splitLicenseServices.length : !!values.splitLicenseServices,
            serviceType: serviceType,
            beginDate: moment(validFromDate).format('YYYY-MM-DD'),
            endDate: null,
            hasEndDate: false
          }

          if (!values.indefinite) {
            parsedValues.hasEndDate = true;
            parsedValues.endDate = moment(validToDate).format('YYYY-MM-DD');
          }

          if (edit) {
            if (session.active_user_type === 'DISTRIBUTOR_ADMIN' || session.active_user_type === 'RESELLER_ADMIN') {
              parsedValues.responsibleOrganizationId = session.organization.organization_id
              parsedValues.responsibleAdminId = session.id
            } else if (session.active_user_type === 'SUPER_ADMIN') {
              parsedValues.responsibleOrganizationId = responsibleOrganizationId ? responsibleOrganizationId : organizationId;
              parsedValues.responsibleAdminId = responsibleAdminId ? responsibleAdminId : adminId;
            }

            await apiClient.request(`/api/v1/licences/services/${editService.pivot.id}/edit`, parsedValues, 'PUT');
            message.success(intl.formatMessage({id: 'services.service_successfully_updated'}));
          } else {
            parsedValues.responsibleOrganizationId = userRole === 'SUPER_ADMIN' ? values.organization ? values.organization : organizationId : organization.id;
            parsedValues.responsibleAdminId = userRole === 'SUPER_ADMIN' ? values.user ? values.user : adminId : user.id;

            await apiClient.request('/api/v1/licences/services', parsedValues, 'POST');
            message.success(intl.formatMessage({id: 'services.service_successfully_added'}));
          }

          setShowAddNewService(false);
          break;
        case 'PLATFORM_ACCESS':
          parsedValues = {
            licenceId: licenceId,
            maxUsers: values.maxUsers,
            validFrom: moment(validFromDate).format('YYYY-MM-DD'),
            validTo: null,
            hasEndDate: false
          };

          if (!values.indefinite) {
            parsedValues.hasEndDate = true;
            parsedValues.validTo = moment(validToDate).format('YYYY-MM-DD');
          }

          if (edit) {
            parsedValues.responsibleOrganizationId = responsibleOrganizationId;
            parsedValues.responsibleAdminId = responsibleAdminId;

            await apiClient.request(`/api/v1/licences/platform-access/${editService.id}/edit`, parsedValues, 'PUT');
            message.success(intl.formatMessage({id: 'services.service_successfully_updated'}));
          } else {
            parsedValues.responsibleOrganizationId = userRole === 'SUPER_ADMIN' ? values.organization : organization.id;
            parsedValues.responsibleAdminId = userRole === 'SUPER_ADMIN' ? values.user : user.id;

            await apiClient.request('/api/v1/licences/platform-access', parsedValues, 'POST');
            message.success(intl.formatMessage({id: 'services.service_successfully_added'}));
          }

          setShowAddNewService(false);
          break;
        case 'PHISHING_SERVICE':
          if (!generalHelpers.hasPermissions(session, 'view_phishing')) {
            return
          }
          parsedValues = {
            licenceId: licenceId,
            maxUsers: values.maxUsers,
            beginDate: moment(validFromDate).format('YYYY-MM-DD'),
            validTo: null,
            hasEndDate: false
          };

          if (!values.indefinite) {
            parsedValues.hasEndDate = true;
            parsedValues.validTo = moment(validToDate).format('YYYY-MM-DD');
          }

          if (edit) {
            parsedValues.responsibleOrganizationId = responsibleOrganizationId;
            parsedValues.responsibleAdminId = responsibleAdminId;

            await apiClient.request(`/api/v1/licences/phishing-access/${editService.id}/edit`, parsedValues, 'PUT');
            message.success(intl.formatMessage({id: 'services.service_successfully_updated'}));
          } else {
            parsedValues.responsibleOrganizationId = userRole === 'SUPER_ADMIN' ? values.organization : organization.id;
            parsedValues.responsibleAdminId = userRole === 'SUPER_ADMIN' ? values.user : user.id;

            await apiClient.request('/api/v1/licences/phishing-access', parsedValues, 'POST');
            message.success(intl.formatMessage({id: 'services.service_successfully_added'}));
          }

          setShowAddNewService(false);
          break;
        case 'APPEARANCE_SERVICE':
          parsedValues = {
            licenceId: licenceId,
            beginDate: moment(validFromDate).format('YYYY-MM-DD'),
            validTo: null,
            hasEndDate: false
          };

          if (!values.indefinite) {
            parsedValues.hasEndDate = true;
            parsedValues.validTo = moment(validToDate).format('YYYY-MM-DD');
          }

          if (edit) {
            parsedValues.responsibleOrganizationId = responsibleOrganizationId;
            parsedValues.responsibleAdminId = responsibleAdminId;

            await apiClient.request(`/api/v1/licences/appearance-access/${editService.id}/edit`, parsedValues, 'PUT');
            message.success(intl.formatMessage({id: 'services.service_successfully_updated'}));
          } else {
            parsedValues.responsibleOrganizationId = userRole === 'SUPER_ADMIN' ? values.organization : organization.id;
            parsedValues.responsibleAdminId = userRole === 'SUPER_ADMIN' ? values.user : user.id;

            await apiClient.request('/api/v1/licences/appearance-access', parsedValues, 'POST');
            message.success(intl.formatMessage({id: 'services.service_successfully_added'}));
          }

          setShowAddNewService(false);
          break;
        default:
          break;
      }

      clearForm(true);
    } catch (error) {
      console.error(error);
      message.error(intl.formatMessage({id: 'error.data_load'}));
    } finally {
      loadLicence()
      setSubmitInProgress(false);
    }
  }

  const disabledDates = (current: moment.Moment, to: boolean = false) => {
    const service: any = services.find((service: any) => service.id === serviceId);

    const serviceValidFromDate = service ? moment(service.validFrom) : moment(null);
    const serviceValidToDate = service ? moment(service.validTo) : moment(null);

    if (!edit) {
      serviceValidToDate.add(1, 'day');
    }

    const validDate = to ? validFromDate : validToDate;

    if (validDate === null) {
      if (serviceType === 'E_LEARNING_CONTENT') {
        return (
          current < serviceValidFromDate
            || serviceValidToDate < current
            || moment().add(2, 'years') < current
            || current < moment().subtract(1, 'day')
          );
      }

      return (
        current < serviceValidFromDate
          || serviceValidToDate < current
          || current < moment().subtract(1, 'day')
      );
    }

    if (serviceType === 'E_LEARNING_CONTENT') {
      return (
        current < serviceValidFromDate
          || serviceValidToDate < current
          || (to ? moment(validDate).add(2, 'years') <= current : false)
          || (to ? validDate > current : current > validDate)
          || validDate.isSame(current, 'day')
          || current < moment().subtract(1, 'day')
      );
    }

    return (
      current < serviceValidFromDate
        || serviceValidToDate < current
        || (to ? validDate > current : current > validDate)
        || validDate.isSame(current, 'day')
        || current < moment().subtract(1, 'day')
    );
  };

  const formItemLayout = {
    labelCol: { span: 12 },
    wrapperCol: { span: 12 },
  };

  const renderTopInputs = () => {

    if (phishingLicence && generalHelpers.hasPermissions(session, 'view_phishing')) {
      <Input
          name="typeReadOnly"
          label={
            <span>
              {intl.formatMessage({id: 'licensing.service_category'})}
              <Tooltip title={intl.formatMessage({id: 'platform_access_licences_cant_include_services_of_other_types'})}>
                <i className='fal fa-question-circle header-item' />
              </Tooltip>
            </span>
          }
          readOnly
          customLayout={formItemLayout}
      />
    } else if (platformAccessLicence || appearanceLicence || learningLicence) {
      return (
        <Input
          name="typeReadOnly"
          label={
            <span>
              {intl.formatMessage({id: 'licensing.service_category'})}
              <Tooltip title={intl.formatMessage({id: 'platform_access_licences_cant_include_services_of_other_types'})}>
                <i className='fal fa-question-circle header-item' />
              </Tooltip>
            </span>
          }
          readOnly
          customLayout={formItemLayout}
        />
      );
    } else if (edit) {
      return (
        <Input
          name="typeReadOnly"
          label={intl.formatMessage({id: 'licensing.service_category'})}
          validation={{required: true}}
          readOnly
          customLayout={formItemLayout}
        />
      );
    } else {
      const enumRoutes = config.api.routes.enums;
      return (
        <Select
          name="type"
          label={intl.formatMessage({id: 'licensing.service_category'})}
          validation={{required: true}}
          url={serviceLicence ? enumRoutes.serviceTypes : enumRoutes.licenceServiceTypes+ `?customerId=${customerId}`}
          onChange={(value: any) => { clearForm(); setServiceType(value); }}
          customLayout={formItemLayout}
          translationModule={'services'}
        />
      );
    }
  }

  const renderInputs = () => {
    switch (serviceType) {
      case 'E_LEARNING_CONTENT':
        return (
          <ELearningContent
            formItemLayout={formItemLayout}
            servicesLoading={servicesLoading}
            services={services}
            serviceList={serviceList}
            activitiesService={activitiesService}
            serviceId={serviceId} setServiceId={setServiceId}
            disabledDates={disabledDates}
            setValidFromDate={setValidFromDate}
            setValidToDate={setValidToDate}
            targetKeys={targetKeys} setTargetKeys={setTargetKeys}
            activitiesHasErrors={activitiesHasErrors}
            edit={edit}
            form={form}
            indefinite={indefinite}
            setIndefinite={setIndefinite}
          />
        );
      case 'FEATURE':
        return null;
      case 'PROFESSIONAL_SERVICE':
        return null;
      case 'PHISHING_SERVICE':
        if (!generalHelpers.hasPermissions(session, 'view_phishing')) {
          return;
        }
        return (
            <PlatformAccess
                form={form}
                formItemLayout={formItemLayout}
                disabledDates={disabledDates}
                setValidFromDate={setValidFromDate}
                setValidToDate={setValidToDate}
                indefinite={indefinite}
                setIndefinite={setIndefinite}
            />
        );
      case 'PLATFORM_ACCESS':
        return (
          <PlatformAccess
            form={form}
            formItemLayout={formItemLayout}
            disabledDates={disabledDates}
            setValidFromDate={setValidFromDate}
            setValidToDate={setValidToDate}
            indefinite={indefinite}
            setIndefinite={setIndefinite}
          />
        );
      case 'APPEARANCE_SERVICE':
        return (
          <AppearanceService
            form={form}
            formItemLayout={formItemLayout}
            disabledDates={disabledDates}
            setValidFromDate={setValidFromDate}
            setValidToDate={setValidToDate}
            indefinite={indefinite}
            setIndefinite={setIndefinite}
          />
        );
    }
  }

  const clearForm = (full: boolean = false) => {
    if (full) {
      form.resetFields();
    } else {
      form.setFieldsValue({
        // All
        validFrom: null,
        validTo: null,
        indefinite: null,
        // E-learning content
        service: null,
        maxLearningActivities: null,
        maxAccess: null,
        actionAfterLicenceEndTerm: null,
        // Platform access
        maxUsers: null
      });
    }
    // All
    setValidFromDate(null);
    setValidToDate(null);
    setIndefinite(false);
    setEdit(false);
    // E-learning content

    if (serviceType === 'E_LEARNING_CONTENT') {
      setServiceId(null);
      setTargetKeys([]);
      setServiceType(null);
    }
  }

  useEffect(() => {
    const loadOrganizations = async () => {
      try {
        setOrganizationsLoading(true);

        const response = await apiClient.request(config.api.routes.backend.organizationsOptions, {}, 'GET');

        const organizationData: any = [];

        response.organizations.forEach((organization: any) => {
          organizationData[organization.id] = organization.name
        });

        setOrganizationList(organizationData);
      } catch(error) {
        console.error(error);
        message.error(intl.formatMessage({id: 'error.data_load'}));
      } finally {
        setOrganizationsLoading(false);
      }
    }

    if (userRole === 'SUPER_ADMIN') {
      loadOrganizations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const loadUsers = async () => {
      try {
        setUsersLoading(true);

        const response = await apiClient.request(`/api/v1/auth/organizations/${organizationId}/admins`, {}, 'GET');

        const userData: any = [];

        response.admins.forEach((admin: any) => {
          userData[admin.userId] = `${admin.name} ${admin.surname}`
        });

        setUserList(userData);
      } catch(error) {
        console.error(error);
        message.error(intl.formatMessage({id: 'error.data_load'}));
      } finally {
        setUsersLoading(false);
      }
    }

    if (userRole === 'SUPER_ADMIN' && organizationId) {
      loadUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  const renderResponsibleInputs = () => {
    if (userRole === 'SUPER_ADMIN') {
      return (
        <>
          <Select
            name="organization"
            label={intl.formatMessage({id: 'licensing.responsible_reseller'})}
            validation={{required: true}}
            manualOptions={organizationList}
            disabled={responsibleAdminId}
            loading={organizationsLoading}
            onChange={setOrganizationId}
            customLayout={formItemLayout}
          />
          <Select
            name="user"
            disabled={!(organizationId)}
            label={intl.formatMessage({id: 'licensing.responsible_salesperson'})}
            validation={{required: true}}
            manualOptions={userList}
            loading={usersLoading}
            customLayout={formItemLayout}
            onChange={setAdminId}
          />
        </>
      );
    } else {
      if (!edit) {
        form.setFieldsValue({
          organization: organization ? organization.name: null,
          user: user.name
        });
      }

      return (
        <>
          <Input
            name="organization"
            label={intl.formatMessage({id: 'licensing.responsible_reseller'})}
            readOnly
            customLayout={formItemLayout}
          />
          <Input
            name="user"
            label={intl.formatMessage({id: 'licensing.responsible_salesperson'})}
            readOnly
            customLayout={formItemLayout}
          />
        </>
      );
    }
  }

	return (
		<Modal
      open={showAddNewService}
      title={intl.formatMessage({id: edit ? 'licensing.edit_service' : 'licensing.add_new_service'})}
      okText={intl.formatMessage({id: 'general.submit'})}
      cancelText={intl.formatMessage({id: 'general.cancel'})}
      onOk={form.submit}
      onCancel={() => { setShowAddNewService(false); platformAccessLicence ? clearForm() : clearForm(true); }}
      confirmLoading={submitInProgress}
    >
      <Spinner spinning={servicesLoading} >
        <Form form={form} onFinish={submitForm}>
          {renderResponsibleInputs()}
          <Divider/>
          {renderTopInputs()}
          <Divider/>
          {renderInputs()}
        </Form>
      </Spinner>
    </Modal>
	);
}

export default LicenceServiceModal;
