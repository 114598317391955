import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import { Form, Select} from 'antd';
import {Modal} from 'ui';
import Spinner from 'components/Spinner';
import {useResource} from 'hooks/Resources/useResource';
import {ResourceProps, ResourceType} from '../types';
import {DefaultOptionType} from "antd/lib/select";

const formItemLayout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};

interface ResourceReservationFilterModalInterface {
    visible?: boolean | undefined;

    onCancel(cancel: boolean): any;
    setEvents: React.Dispatch<React.SetStateAction<any[]>>;
}


const ResourceReservationFilterModal: React.FC<ResourceReservationFilterModalInterface> = ({
                                                                                               visible = false,
                                                                                               onCancel,
                                                                                               setEvents
                                                                                           }) => {
    const {getCalendarResourceReservations, getResources, resourceLoading} = useResource();

    const [location, setLocation] = useState<DefaultOptionType[]>([]);
    const [device, setDevice] = useState<DefaultOptionType[]>([]);
    const [license, setLicense] = useState<DefaultOptionType[]>([]);

    const intl = useIntl();
    const [form] = Form.useForm();

    useEffect(() => {
        loadResources()
    }, [])

    const handleOnCancel = () => {
        form.resetFields();
        onCancel(false)
    }

    const handleFormSubmit = async (values: any) => {
        let filter = ''
        Object.entries(values).map((el: any) => {
                if (Array.isArray(el[1]) && el[1].length && el[1] && el[0]) {
                    filter = urlParser(filter)
                    return (filter += `[${el[0]}]=${el[1]}`);
                } else if (!Array.isArray(el[1]) && el[1] && el[0]) {
                    filter = urlParser(filter)
                    return (filter += `[${el[0]}]=${el[1]}`);
                }

        });
        const response = await getCalendarResourceReservations(filter);

        setEvents(response);

        onCancel(false);
    };

    const urlParser = (filter:string) => {
        if (!filter) {
            filter += '?filter'
        } else {
            filter += '&filter'
        }
        return filter;
    }

    const getFormattedTitle = (resourceType: string | undefined, title: string | undefined): string => {
        const typeTranslation = intl.formatMessage({id: `system.resource.type.${resourceType}`});

        return !!resourceType && resourceType !== ResourceType.Other ? `${typeTranslation}${!!title ? ' - ' + title : ''}` : title ?? ''
    }
    const formatResourceOptions = (resources: ResourceProps[]): DefaultOptionType[] => {
        const result: DefaultOptionType[] = [];

        resources.forEach((resource: ResourceProps) => {
            const label = getFormattedTitle(resource.resourceType, resource.title);

            const options = resource.children?.map((option: ResourceProps) => {
                return {
                    label: option.title,
                    value: option.id
                }
            });

            result.push({
                label, options
            })
        });

        return result;
    }

    const loadResources = async () => {
        setLocation(formatResourceOptions(await getResources([ResourceType.Location])));
        setDevice(formatResourceOptions(await getResources([ResourceType.Device, ResourceType.Other])));
        setLicense(formatResourceOptions(await getResources([ResourceType.License])));
    };

    return (
        <Modal
            destroyOnClose
            open={visible}
            confirmLoading={resourceLoading}
            onCancel={() => handleOnCancel()}
            title={intl.formatMessage({id: 'system.resource.reservations.filter'})}
            okText={intl.formatMessage({id: 'general.filter'})}
            cancelText={intl.formatMessage({id: 'general.cancel'})}
            onOk={form.submit}
        >
            <Spinner spinning={resourceLoading}>
                <Form form={form} onFinish={handleFormSubmit} {...formItemLayout} preserve={false}>
                    <Form.Item name="locations" label={intl.formatMessage({id: 'system.resource.type.LOCATION'})}>
                        <Select className='default-select'
                                mode="multiple"
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={location}
                                loading={resourceLoading}
                        />
                    </Form.Item>
                    <Form.Item name="resources" label={intl.formatMessage({id: 'general.lectures.resources'})}>
                        <Select className='default-select'
                                mode="multiple"
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={device}
                                loading={resourceLoading}
                        />
                    </Form.Item>

                    <Form.Item name="licenses" label={intl.formatMessage({id: 'general.lectures.licenses'})}>
                        <Select className='default-select'
                                mode="multiple"
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={license}
                                loading={resourceLoading}
                        />
                    </Form.Item>
                </Form>
            </Spinner>
        </Modal>
    )
};

export default ResourceReservationFilterModal;
