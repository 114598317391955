import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Modal, Checkbox, Form, Radio, DatePicker, Button} from 'antd';
import {CustomValuesSelect, RequestSelect, Select, Switch} from 'components/Form';
import useHandleError from 'utils/useHandleError';
import Spinner from 'components/Spinner';
import {config} from 'config/config';
import {connect} from "react-redux";
import apiClient from "../../../utils/apiClient";
import moment from "moment/moment";
import {useParams} from "react-router-dom";
import './styles.scss';
import {Input} from "../../Form";

interface FilterModalInterface {
    visible: boolean;
    onCancel: React.Dispatch<React.SetStateAction<boolean>>;
    modules: any;
    filter?: string;
    setFilter?: React.Dispatch<React.SetStateAction<string>>;
    load?: React.Dispatch<React.SetStateAction<boolean>>;
    session?: any;
    locale?: any;
    okProp?: string;
    title?: string;
    submit?: Function;
    isObject?: boolean;
}

const formItemLayout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};

const mapStateToProps = (state: any) => {
    return {
        session: state.session
    }
};

const FilterModal: React.FC<FilterModalInterface> = ({
                                                         visible,
                                                         onCancel,
                                                         modules,
                                                         setFilter,
                                                         filter,
                                                         submit,
                                                         isObject = false,
                                                         load,
                                                         session,
                                                         locale,
                                                         title = 'general.user_filter',
                                                         okProp = 'general.filter'
                                                     }) => {
    const intl = useIntl();
    const params = useParams();
    const programId = params.id;
    const [form] = Form.useForm();
    const [handleError] = useHandleError();
    const [isLoading, setIsLoading] = useState(false);
    const [filterBy, setFilterBy] = useState('');
    const [customerId, setCustomerId] = useState();
    const [organizationId, setOrganizationId] = useState();
    const [urlDepartments, setUrlDepartments] = useState('');
    const [urlOrgUnits, setUrlOrgUnits] = useState('');
    const [organizations, setOrganizations] = useState();
    const [customers, setCustomers] = useState();
    const [orgUnits, setOrgUnits] = useState();
    const [departmentId, setDepartmentId] = useState();
    const [directManager, setDirectManager] = useState();
    const [programName, setProgramName] = useState();
    const [studentPosition, setStudentPosition] = useState();
    const [studentName, setStudentName] = useState();
    const [resourceGroup, setResourceGroup] = useState();
    const [resourceType, setResourceType] = useState();
    const [resourceName, setResourceName] = useState();
    const [activity, setActivity] = useState();
    const [lecture, setLecture] = useState();
    const [competences, setCompetences] = useState();
    const [resourceBusyness, setResourceBusyness] = useState();
    const [selectedFilters, setSelectedFilters] = useState<any>({});
    const [customerIdList, setCustomerIdList] = useState<any>([]);
    const clientType = session.organization.organization !== 'CUSTOMER' ? 'ORGANIZATION' : 'CUSTOMER';

    useEffect(() => {
        setDefaultValues();
        loadCustomers();
    }, [])

    useEffect(() => {
        if (session.organization.organization_type === 'CUSTOMER') {
            setFilterBy('CUSTOMER');
        }
        if (modules?.departmentId?.visible) {
            let id = session.organization.organization_id;
            if (session.organization.organization_type === 'CUSTOMER') {
                setUrlDepartments(`/api/v1/departments?customerId=${id}`);
            } else {
                setUrlDepartments(`/api/v1/departments?organizationId=${id}`);
            }
        }

        if (modules?.organizationalUnitId?.visible) {
            let id = session.organization.organization_id;
            if (session.organization.organization_type === 'CUSTOMER') {
                setUrlOrgUnits(`/api/v1/organizational-units?customerId=${id}`);
            } else {
                setUrlOrgUnits(`/api/v1/organizational-units?organizationId=${id}`);
            }
        }

    }, [session])

    const setDefaultValues = () => {
        Object.entries(modules).map((field: any) => {
            if (field[1]?.default !== undefined || field[1]?.default !== null) {
                if (field[0] === 'filterBy') {
                    setFilterBy(field[1].default)
                }
                form.setFieldsValue({[field[0]]: field[1].default})
            }
        })
    }

    const submitForm = async (values: any) => {
        const defaultValues = [
            'organizationalUnitId', 'accountStatus', 'competenceStatus', 'validTo', 'userTypes', 'newUsers', 'recertification', 'customerId', 'organizationId',
            'filterBy', 'students', 'studentPosition', 'departmentId', 'directManager', 'programId', 'programName',
            'certificateType', 'certificateId', 'certificateIssueDate', 'certificateExpirationDate', 'resourceGroup',
            'resourceType', 'resourceName', 'reservedFrom', 'reservedTill', 'resourceBusyness', 'programStatus', 'activityStatus',
            'activity', 'lecture', 'directManagerDepartments', 'directManagerOrganizationalUnits', 'directManagerPrograms',
            'directManagerCompetences', 'directManagerProgramStatus', 'directManagerCompetenceStatus', 'delayed', 'expirationDate',
            'academyStatus', 'enrollmentType', 'createdAt', 'academyAvailableStatus'
        ];

        try {
            let filter = '';
            Object.entries(values).map((el: any) => {
                if (el[1] !== undefined) {
                    if (defaultValues.includes(el[0])) {
                        if (Array.isArray(el[1]) && el[1].length && el[1] && el[0]) {
                            if (el[0] === 'certificateIssueDate' || el[0] === 'certificateExpirationDate' || el[0] === 'validTo' || el[0] === 'expirationDate' || el[0] === 'createdAt') {
                                const range: string[] = [];
                                el[1].map((date: any) => {
                                    range.push(moment(date).format('YYYY-MM-DD'));
                                });
                                return (filter += `&filter[${el[0]}]=${range.join()}`);
                            } else if (el[0] === 'reservedFrom' || el[0] === 'reservedTill') {
                                const range: string[] = [];
                                el[1].map((date: any) => {
                                    range.push(moment(date).format('YYYY-MM-DD HH:mm'));
                                });
                                return (filter += `&filter[${el[0]}]=${range.join()}`);
                            } else {
                                return (filter += `&filter[${el[0]}]=${el[1]}`);
                            }
                        } else if (el[0] && typeof el[1] === 'boolean') {
                            return (filter += `&filter[${el[0]}]=${el[1]}`);
                        } else if (!Array.isArray(el[1]) && el[1] && el[0]) {
                            return (filter += `&filter[${el[0]}]=${el[1]}`);
                        }
                    } else {
                        if (Array.isArray(el[1]) && el[1].length && el[1] && el[0]) {
                            return (filter += `&customerFilter[${el[0]}]=${el[1]}`);
                        } else if (!Array.isArray(el[1]) && el[1] && el[0]) {
                            return (filter += `&customerFilter[${el[0]}]=${el[1]}`);
                        }
                    }
                }
            });

            if (setFilter) {
                setFilter(filter);
            }

            if (load) {
                load(true);
            }

            if (submit) {
                submit(isObject ? {filter: values} : filter)
            }

            onCancel(false);

        } catch (error) {
            handleError(error)
        }
    }

    const displayOrganizationSelect = (type: 'CUSTOMER' | 'ORGANIZATION') => {

        let display = false;
        switch (type) {
            case 'CUSTOMER':
                if (modules?.filterBy?.visible && modules?.customerSelect?.visible && filterBy === 'CUSTOMER') {
                    display = true;
                } else if (modules?.customerSelect?.visible && !modules?.filterBy?.visible) {
                    display = true;
                }
                break;
            case 'ORGANIZATION':
                if (modules?.filterBy?.visible && modules?.organizationSelect?.visible && filterBy === 'ORGANIZATION') {
                    display = true;
                } else if (modules?.organizationSelect?.visible && !modules?.filterBy?.visible) {
                    display = true;
                }
                break;
        }

        return display;
    }

    const getCustomerSelectMode = () => {
        if (modules.customerSelect?.mode) {
            return modules.customerSelect.mode;
        }
        return 'default';
    }

    const setSelectedCustomer = (id: any) => {
        if (modules.customerSelect?.set) {
            modules.customerSelect?.set(id);
            modules.customerSelect?.setType('CUSTOMER');
        }
    }

    const getOrgManagers = () => {
        if (session.organization.organization_type === 'CUSTOMER') {
            return `/api/v1/customer-users/${session.organization.organization_id}/direct-managers?key=referenceId`;
        } else {
            return `/api/v1/customer-users/${customerIdList}/direct-managers?key=referenceId`;
        }
    }

    const loadCustomers = async () => {
        const tempData: any = [];
        const response = await apiClient.request(config.api.routes.backend.selectCustomers, [], 'GET');
        const customers = response.customers
        Object.entries(customers).map((customer: any) => {
            tempData.push(customer[0])
        })
        setCustomerIdList(tempData);
    };

    interface FilterOptionType {
        [key: string]: string;
    }

    const filterOptions = {
        ORGANIZATION: 'general.reseller',
        CUSTOMER: 'general.customer',
        ADMIN: 'user.type.ADMIN',
        CUSTOMER_ADMIN: 'user.type.CUSTOMER_ADMIN',
        STUDENT: 'user.type.STUDENT',
        ACTIVE: 'user.status.ACTIVE',
        BLOCKED: 'user.status.BLOCKED',
        true: 'general.yes',
        false: 'general.no',
        SYSTEM: 'general.certificate_type_system',
        MANUAL: 'general.certificate_type_manual',
        certificateIssueDate: 'general.certificate_issue_date',
        createdAt: modules.createdAt?.label ? modules.createdAt.label : 'general.created_at',
        certificateExpirationDate: 'general.certificate_expiration_date',
        reservedFrom: 'general.reserved_from',
        reservedTill: 'general.reserved_till',
        COMPLETED: 'general.completed',
        IN_PROGRESS: 'general.in_progress',
        NOT_STARTED: 'general.not_started',
        UN_ENROLLED: 'general.un_enrolled',
        ATTENDED: 'general.attended',
        MISSED: 'general.missed',
        PASSED: 'general.passed',
        FAILED: 'general.failed',
        COMPLIANT: 'general.compliant',
        INVITE_ENROLLMENT: 'general.invite_enrollment',
        SELF_ENROLLMENT: 'general.self_enrollment',
        VALID: 'general.valid',
        COMPETENCE_VALID: 'competences.status_valid',
        COMPETENCE_COMPLIANT: 'competences.status_compliant',
        COMPETENCE_EXPIRING: 'competences.status_expiring',
        NOT_VALID: 'general.not_valid',
        expirationDate: 'general.valid_until',
        NOT_REGISTERED: 'general.not_registered',
    } as FilterOptionType

    useEffect(() => {
        selectedFilterList(filter);
    }, [filter])

    const selectedFilterList = (filter: any) => {
        setSelectedFilters({})

        let filterList = typeof filter === 'string' ? filter.split("&") : filter;

        if (filterList && Array.isArray(filterList)) {
            filterList.map((row: string) => {
                if (row !== '') {
                    var matches = row.match(/filter\[([A-Za-z]+)\]=([A-Za-z0-9,_ \-:+]+)/);
                    if (matches && Array.isArray(matches)) {
                        let key = matches[1];
                        let value: any = matches[2];
                        setSelectedFilters((selectedFilters: any) => ({...selectedFilters, [key]: value}));
                    }
                }
            })
        }
    }

    const renderSelectedFilters = (selectedFilters: any) => {
        return (
            <div>
                {filter === '' || filter === undefined || filter === `&filter[filterBy]=${clientType}&filter[${clientType.toLowerCase()}Id]=${session.organization.organization_id}` ? null
                    : <FormattedMessage id="general.filtered_by"/>}
                {Object.entries(selectedFilters).map((filter: any) => {
                    if (session.organization.organization !== 'CUSTOMER') {
                        if (filter[0] === 'customerId' && customers) {
                            let values = filter[1].split(',');
                            return (
                                <div>
                                    <FormattedMessage id='general.customer'/>
                                    {values.map((id: any) => {
                                        return (<div className="custom-filter-tags">{customers[id]}</div>)
                                    })}
                                </div>
                            )
                        }
                        if (filter[0] === 'organizationId' && organizations) {
                            return (
                                <div>
                                    <FormattedMessage id='general.reseller'/>
                                    <div className="custom-filter-tags">{organizations[filter[1]]}</div>
                                </div>
                            )
                        }
                    }

                    if (filter[0] === 'students' && studentName) {
                        let values = filter[1].split(',');
                        return (
                            <div>
                                <FormattedMessage id='general.student'/>
                                {values.map((id: any) => {
                                    return (<div className="custom-filter-tags">{studentName[id]}</div>)
                                })}
                            </div>
                        )
                    }

                    if (filter[0] === 'organizationalUnitId' && orgUnits) {
                        let values = filter[1].split(',');
                        return (
                            <div>
                                <FormattedMessage id='general.unit'/>
                                {values.map((id: any) => {
                                    return (<div className="custom-filter-tags">{orgUnits[id]}</div>)
                                })}
                            </div>
                        )
                    }

                    if (filter[0] === 'studentPosition' && studentPosition) {
                        let values = filter[1].split(',');
                        return (
                            <div>
                                <FormattedMessage id='general.position'/>
                                {values.map((id: any) => {
                                    return (<div className="custom-filter-tags">{studentPosition[id]}</div>)
                                })}
                            </div>
                        )
                    }

                    if (filter[0] === 'accountStatus') {
                        let values = filter[1].split(',');
                        return (
                            <div>
                                <FormattedMessage id='users.user_status'/>
                                {values.map((status: any) => {
                                    return (
                                        <div className="custom-filter-tags">
                                            <FormattedMessage id={filterOptions[status]}/>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    }

                    if (filter[0] === 'userTypes') {
                        let values = filter[1].split(',');
                        return (
                            <div>
                                <FormattedMessage id='users.user_type'/>
                                {values.map((role: any) => {
                                    return (
                                        <div className="custom-filter-tags">
                                            <FormattedMessage id={filterOptions[role]}/>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    }

                    if (filter[0] === 'newUsers') {
                        return (
                            <div>
                                <FormattedMessage id='users.new_users'/>
                                <div className="custom-filter-tags">
                                    <FormattedMessage id={filterOptions[filter[1]]}/>
                                </div>
                            </div>
                        )
                    }

                    if (filter[0] === 'recertification') {
                        let values = filter[1].split(',');
                        return (
                            <div>
                                <FormattedMessage id='program.recertification'/>
                                {values.includes('1') ?
                                    <div className="custom-filter-tags"><FormattedMessage id={'general.yes'}/>
                                    </div> : null}
                                {values.includes('0') ?
                                    <div className="custom-filter-tags"><FormattedMessage id={'general.no'}/>
                                    </div> : null}
                            </div>
                        )
                    }

                    if (filter[0] === 'delayed') {
                        return (
                            <div>
                                <FormattedMessage id='program.program_delayed'/>
                                <div className="custom-filter-tags">
                                    <FormattedMessage id={filterOptions[filter[1]]}/>
                                </div>
                            </div>
                        )
                    }

                    if (filter[0] === 'directManager' && directManager) {
                        let values = filter[1].split(',');
                        return (
                            <div>
                                <FormattedMessage id='general.direct_manager'/>
                                {values.map((id: any) => {
                                    return (<div className="custom-filter-tags">{directManager[id]}</div>)
                                })}
                            </div>
                        )
                    }

                    if (filter[0] === 'departmentId' && departmentId) {
                        let values = filter[1].split(',');
                        return (
                            <div>
                                <FormattedMessage id='general.department'/>
                                {values.map((id: any) => {
                                    return (<div className="custom-filter-tags">{departmentId[id]}</div>)
                                })}
                            </div>
                        )
                    }

                    if (filter[0] === 'certificateType') {
                        let values = filter[1].split(',');
                        return (
                            <div>
                                <FormattedMessage id='general.certificate_type'/>
                                {values.map((type: any) => {
                                    return (
                                        <div className="custom-filter-tags">
                                            <FormattedMessage id={filterOptions[type]}/>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    }

                    if (filter[0] === 'programName' && programName) {
                        let values = filter[1].split(',');
                        return (
                            <div>
                                <FormattedMessage id='general.learning_program'/>
                                {values.map((id: any) => {
                                    if (programName[id][locale]) {
                                        return (<div className="custom-filter-tags">{programName[id][locale]}</div>)
                                    } else {
                                        return (<div
                                            className="custom-filter-tags">{programName[id][Object.keys(programName[id])[0]]}</div>)
                                    }
                                })}
                            </div>
                        )
                    }

                    if (filter[0] === 'programId') {
                        let values = filter[1].split(',');
                        return (
                            <div>
                                <FormattedMessage id='general.learning_program_id'/>
                                {values.map((id: any) => {
                                    return (
                                        <div className="custom-filter-tags">
                                            {id}
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    }

                    if (filter[0] === 'certificateId') {
                        let values = filter[1].split(',');
                        return (
                            <div>
                                <FormattedMessage id='general.certificate_id'/>
                                {values.map((id: any) => {
                                    return (
                                        <div className="custom-filter-tags">
                                            {id}
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    }

                    if (filter[0] === 'competenceValidity' || filter[0] === 'certificateIssueDate' || filter[0] === 'certificateExpirationDate' || filter[0] === 'reservedFrom' || filter[0] === 'reservedTill' || filter[0] === 'validTo' || filter[0] === 'expirationDate' || filter[0] === 'createdAt') {
                        let values = filter[1].split(',');
                        return (
                            <div>
                                <FormattedMessage id={filterOptions[filter[0]]}/>
                                {values.map((date: any) => {
                                    return (
                                        <div className="custom-filter-tags">
                                            {date}
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    }


                    if (filter[0] === 'resourceGroup' && resourceGroup) {
                        let values = filter[1].split(',');
                        return (
                            <div>
                                <FormattedMessage id='system.group'/>
                                {values.map((id: any) => {
                                    return (<div className="custom-filter-tags"><FormattedMessage
                                        id={`system.resource.type.${resourceGroup[id]}`}/></div>)
                                })}
                            </div>
                        )
                    }
                    if (filter[0] === 'resourceType' && resourceType) {
                        let values = filter[1].split(',');
                        return (
                            <div>
                                <FormattedMessage id='general.type'/>
                                {values.map((id: any) => {
                                    return (<div className="custom-filter-tags">{resourceType[id]}</div>)
                                })}
                            </div>
                        )
                    }
                    if (filter[0] === 'resourceName' && resourceName) {
                        let values = filter[1].split(',');
                        return (
                            <div>
                                <FormattedMessage id='general.title'/>
                                {values.map((id: any) => {
                                    return (<div className="custom-filter-tags">{resourceName[id]}</div>)
                                })}
                            </div>
                        )
                    }
                    if (filter[0] === 'resourceBusyness' && resourceBusyness) {
                        let values = filter[1].split(',');
                        return (
                            <div>
                                <FormattedMessage id='general.busyness'/>
                                {values.map((id: any) => {
                                    return (<div className="custom-filter-tags">{resourceBusyness[id]}</div>)
                                })}
                            </div>
                        )
                    }
                    if (filter[0] === 'programStatus') {
                        let values = filter[1].split(',');
                        return (
                            <div>
                                <FormattedMessage id='general.program_status'/>
                                {values.map((status: any) => {
                                    return (
                                        <div className="custom-filter-tags">
                                            <FormattedMessage id={filterOptions[status]}/>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    }
                    if (filter[0] === 'competenceStatus') {
                        let values = filter[1].split(',');
                        return (
                            <div>
                                <FormattedMessage id='general.status'/>
                                {values.map((status: any) => {
                                    return (
                                        <div className="custom-filter-tags">
                                            <FormattedMessage id={filterOptions[status]}/>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    }
                    if (filter[0] === 'academyAvailableStatus') {
                        let values = filter[1].split(',');
                        return (
                            <div>
                                <FormattedMessage id='general.status'/>
                                {values.map((status: any) => {
                                    return (
                                        <div className="custom-filter-tags">
                                            <FormattedMessage id={filterOptions[status]}/>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    }
                    if (filter[0] === 'activityStatus') {
                        let values = filter[1].split(',');
                        return (
                            <div>
                                <FormattedMessage id='general.activity_status'/>
                                {values.map((status: any) => {
                                    return (
                                        <div className="custom-filter-tags">
                                            <FormattedMessage id={filterOptions[status]}/>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    }
                    if (filter[0] === 'activity' && activity) {
                        let values = filter[1].split(',');
                        return (
                            <div>
                                <FormattedMessage id='general.activity'/>
                                {values.map((id: any) => {
                                    if (activity[id][locale]) {
                                        return (<div className="custom-filter-tags">{activity[id][locale]}</div>)
                                    } else {
                                        return (<div
                                            className="custom-filter-tags">{activity[id][Object.keys(activity[id])[0]]}</div>)
                                    }
                                })}
                            </div>
                        )
                    }
                    if (filter[0] === 'directManagerDepartments' && departmentId) {
                        let values = filter[1].split(',');
                        return (
                            <div>
                                <FormattedMessage id='general.department'/>
                                {values.map((id: any) => {
                                    return (<div className="custom-filter-tags">{departmentId[id]}</div>)
                                })}
                            </div>
                        )
                    }
                    if (filter[0] === 'directManagerOrganizationalUnits' && orgUnits) {
                        let values = filter[1].split(',');
                        return (
                            <div>
                                <FormattedMessage id='general.unit'/>
                                {values.map((id: any) => {
                                    return (<div className="custom-filter-tags">{orgUnits[id]}</div>)
                                })}
                            </div>
                        )
                    }
                    if (filter[0] === 'directManagerPrograms' && programName) {
                        let values = filter[1].split(',');
                        return (
                            <div>
                                <FormattedMessage id='general.learning_program'/>
                                {values.map((id: any) => {
                                    if (programName[id][locale]) {
                                        return (<div className="custom-filter-tags">{programName[id][locale]}</div>)
                                    } else {
                                        return (<div
                                            className="custom-filter-tags">{programName[id][Object.keys(programName[id])[0]]}</div>)
                                    }
                                })}
                            </div>
                        )
                    }
                    if (filter[0] === 'directManagerCompetences' && competences) {
                        let values = filter[1].split(',');
                        return (
                            <div>
                                <FormattedMessage id='general.competences'/>
                                {values.map((id: any) => {
                                    return (<div className="custom-filter-tags">{competences[id]}</div>)
                                })}
                            </div>
                        )
                    }
                    if (filter[0] === 'directManagerProgramStatus') {
                        let values = filter[1].split(',');
                        return (
                            <div>
                                <FormattedMessage id='general.program_status'/>
                                {values.map((status: any) => {
                                    return (
                                        <div className="custom-filter-tags">
                                            <FormattedMessage id={filterOptions[status]}/>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    }
                    if (filter[0] === 'enrollmentType') {
                        let values = filter[1].split(',');
                        return (
                            <div>
                                <FormattedMessage id='general.enrollment_type'/>
                                {values.map((status: any) => {
                                    return (
                                        <div className="custom-filter-tags">
                                            <FormattedMessage id={filterOptions[status]}/>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    }
                    if (filter[0] === 'academyStatus') {
                        let values = filter[1].split(',');
                        return (
                            <div>
                                <FormattedMessage id='general.status'/>
                                {values.map((status: any) => {
                                    return (
                                        <div className="custom-filter-tags">
                                            <FormattedMessage id={filterOptions[status]}/>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    }
                    if (filter[0] === 'directManagerCompetenceStatus') {
                        let values = filter[1].split(',');
                        return (
                            <div>
                                <FormattedMessage id='general.competence_status'/>
                                {values.map((status: any) => {
                                    return (
                                        <div className="custom-filter-tags">
                                            <FormattedMessage id={filterOptions[status]}/>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    }

                })}
                {setFilter && load ?
                    filter === '' || filter === `&filter[filterBy]=${clientType}&filter[${clientType.toLowerCase()}Id]=${session.organization.organization_id}` ? null
                        :
                        <Button
                            className="clear-all-button"
                            onClick={() => {
                                setFilter(`&filter[filterBy]=${clientType}&filter[${clientType.toLowerCase()}Id]=${session.organization.organization_id}`)
                                form.resetFields();
                                setSelectedFilters({});
                                load(true);
                            }}>
                            <FormattedMessage id="users.clear_all"/>
                        </Button>
                    : null
                }
            </div>
        )
    }

    return (
        <>
            <Modal
                className="user-list-modal"
                open={visible}
                title={intl.formatMessage({id: title})}
                onOk={() => form.submit()}
                width={800}
                onCancel={() => onCancel(false)}
                okText={intl.formatMessage({id: okProp})}
                cancelText={intl.formatMessage({id: 'general.cancel'})}
            >
                <Form form={form} {...formItemLayout} onFinish={submitForm} className={'table-filter-modal'}>
                    {modules?.filterBy?.visible && <Form.Item
                        name="filterBy"
                        label={intl.formatMessage({id: 'general.filter_by'})}
                    >
                        <Radio.Group onChange={(el: any) => setFilterBy(el.target.value)}>
                            <Radio value="CUSTOMER"><FormattedMessage id='general.customer'/></Radio>
                            <Radio value="ORGANIZATION"><FormattedMessage id='general.reseller'/></Radio>
                        </Radio.Group>
                    </Form.Item>
                    }
                    {displayOrganizationSelect('CUSTOMER') &&
                        <Select
                            allowClear
                            mode={getCustomerSelectMode()}
                            showSearch
                            name='customerId'
                            dataKey='customers'
                            onChange={(id: any) => {
                                setCustomerId(id);
                                setSelectedCustomer(id)
                                setUrlDepartments(`/api/v1/departments?customerId=${id}`);
                                setUrlOrgUnits(`/api/v1/organizational-units?customerId=${id}`);
                            }}
                            label={intl.formatMessage({id: "general.customer"})}
                            url={config.api.routes.backend.selectCustomers}
                            integerKey={true}
                            customLayout={true}
                            filterOption={(input: string, option: any) => {
                                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                            }}
                            saveLoadedData={true}
                            setLoadedData={setCustomers}
                        />
                    }
                    {displayOrganizationSelect('ORGANIZATION') &&
                        <Select
                            allowClear
                            showSearch
                            name='organizationId'
                            dataKey='organizations'
                            onChange={(id: any) => {
                                setOrganizationId(id)
                                modules.organizationSelect?.set(id);
                                modules.organizationSelect?.setType('ORGANIZATION');
                            }}
                            label={intl.formatMessage({id: "general.reseller"})}
                            url={config.api.routes.backend.selectOrganizations}
                            integerKey={true}
                            customLayout={true}
                            filterOption={(input: string, option: any) => {
                                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                            }}
                            saveLoadedData={true}
                            setLoadedData={setOrganizations}
                        />
                    }
                    {modules?.students?.visible &&
                        <RequestSelect
                            mode={modules?.students?.mode}
                            name={'students'}
                            label={intl.formatMessage({id: 'general.student'})}
                            allowClear
                            showSearch
                            url={`api/v1/edu/users/search-student`}
                            param={['key=id' + modules?.students?.params ? `${modules?.students?.params}` : '']}
                            isForm
                            customLayout={true}
                            dropdownStyle={{maxWidth: "300px"}}
                            saveData={setStudentName}
                        />
                    }
                    {modules?.studentPosition?.visible &&
                        <Select
                            mode={modules?.studentPosition?.mode}
                            allowClear
                            showSearch
                            name='studentPosition'
                            label={intl.formatMessage({id: 'general.position'})}
                            dataKey='positions'
                            url={'api/v1/customer-users/positions/list'}
                            customLayout={true}
                            saveLoadedData={true}
                            setLoadedData={setStudentPosition}
                        />
                    }
                    {modules?.organizationalUnitId?.visible &&
                        <Select
                            mode={modules?.organizationalUnitId?.mode}
                            allowClear
                            showSearch
                            name='organizationalUnitId'
                            dataKey='organizationalUnits'
                            label={intl.formatMessage({id: "general.unit"})}
                            url={urlOrgUnits}
                            integerKey={true}
                            customLayout={true}
                            saveLoadedData={true}
                            setLoadedData={setOrgUnits}
                        />
                    }
                    {modules?.departmentId?.visible &&
                        <Select
                            mode={modules?.departmentId?.mode}
                            allowClear
                            showSearch
                            name='departmentId'
                            label={intl.formatMessage({id: 'general.department'})}
                            dataKey='departments'
                            url={urlDepartments}
                            integerKey={true}
                            customLayout={true}
                            saveLoadedData={true}
                            setLoadedData={setDepartmentId}
                        />
                    }
                    {modules?.directManager?.visible &&
                        <Select
                            mode={modules?.directManager?.mode}
                            allowClear
                            showSearch
                            name='directManager'
                            label={intl.formatMessage({id: 'general.direct_manager'})}
                            url={getOrgManagers()}
                            integerKey={true}
                            customLayout={true}
                            saveLoadedData={true}
                            setLoadedData={setDirectManager}
                        />
                    }
                    {modules?.accountStatus?.visible &&
                        <Form.Item name="accountStatus" label={intl.formatMessage({id: 'users.user_status'})}>
                            <Checkbox.Group>
                                <Checkbox value="ACTIVE" style={{lineHeight: '32px'}}>
                                    <FormattedMessage id='user.status.ACTIVE'/>
                                </Checkbox>
                                <Checkbox value="BLOCKED" style={{lineHeight: '32px'}}>
                                    <FormattedMessage id='user.status.BLOCKED'/>
                                </Checkbox>
                            </Checkbox.Group>
                        </Form.Item>
                    }
                    {modules?.competenceStatus?.visible &&
                        <Form.Item name="competenceStatus" label={intl.formatMessage({id: 'general.status'})}>
                            <Radio.Group>
                                <Radio value="COMPETENCE_VALID"><FormattedMessage
                                    id='competences.status_valid'/></Radio>
                                <Radio value="COMPETENCE_COMPLIANT"><FormattedMessage
                                    id='competences.status_compliant'/></Radio>
                                <Radio value="COMPETENCE_EXPIRING"><FormattedMessage id='competences.status_expiring'/></Radio>
                            </Radio.Group>
                        </Form.Item>
                    }
                    {modules?.academyAvailableStatus?.visible &&
                        <Form.Item name="academyAvailableStatus" label={intl.formatMessage({id: 'general.status'})}>
                            <Checkbox.Group>
                                <Checkbox value="NOT_STARTED"><FormattedMessage id='general.not_started'/></Checkbox>
                                <Checkbox value="IN_PROGRESS"><FormattedMessage id='general.in_progress'/></Checkbox>
                                <Checkbox value="COMPLETED"><FormattedMessage id='general.completed'/></Checkbox>
                                <Checkbox value="NOT_REGISTERED"><FormattedMessage id='general.not_registered'/></Checkbox>
                            </Checkbox.Group>
                        </Form.Item>
                    }
                    {modules?.competenceValidity?.visible &&
                        <Form.Item {...formItemLayout} name='validTo'
                                   label={intl.formatMessage({id: 'general.valid_to'})}>
                            <DatePicker.RangePicker/>
                        </Form.Item>
                    }
                    {modules?.expirationDate?.visible &&
                        <Form.Item {...formItemLayout} name='expirationDate'
                                   label={intl.formatMessage({id: 'general.valid_until'})}>
                            <DatePicker.RangePicker/>
                        </Form.Item>
                    }
                    {modules?.programId?.visible &&
                        <Select
                            name='programId'
                            allowClear
                            showSearch
                            label={intl.formatMessage({id: 'general.learning_program_id'})}
                            mode={modules?.programName?.mode}
                            dataKey='programIds'
                            url={'api/v1/courses/learning-program'}
                            integerKey={true}
                            customObjLabel={(el: any) => el.toString()}
                            customLayout={true}
                        />
                    }
                    {modules?.programName?.visible &&
                        <Select
                            name='programName'
                            allowClear
                            showSearch
                            label={intl.formatMessage({id: "general.learning_program"})}
                            mode={modules?.programName?.mode}
                            dataKey='programs'
                            url={'api/v1/courses/learning-program'}
                            customObjLabel={(el: any) => el[locale] ? el[locale] : el[Object.keys(el)[0]]}
                            integerKey={true}
                            customLayout={true}
                            saveLoadedData={true}
                            setLoadedData={setProgramName}
                        />
                    }
                    {modules?.certificateType?.visible &&
                        <Form.Item name="certificateType" label={intl.formatMessage({id: 'general.certificate_type'})}>
                            <Checkbox.Group>
                                <Checkbox value="SYSTEM" style={{lineHeight: '32px'}}>
                                    <FormattedMessage id='general.certificate_type_system'/>
                                </Checkbox>
                                <Checkbox value="MANUAL" style={{lineHeight: '32px'}}>
                                    <FormattedMessage id='general.certificate_type_manual'/>
                                </Checkbox>
                            </Checkbox.Group>
                        </Form.Item>
                    }
                    {modules?.certificateId?.visible &&
                        <Input
                            name='certificateId'
                            customLayout
                            label={intl.formatMessage({id: 'general.certificate_id'})}
                        />
                    }
                    {modules?.certificateIssueDate?.visible &&
                        <Form.Item {...formItemLayout} name='certificateIssueDate'
                                   label={intl.formatMessage({id: 'general.certificate_issue_date'})}>
                            <DatePicker.RangePicker/>
                        </Form.Item>
                    }
                    {modules?.certificateExpirationDate?.visible &&
                        <Form.Item {...formItemLayout} name='certificateExpirationDate'
                                   label={intl.formatMessage({id: 'general.certificate_expiration_date'})}>
                            <DatePicker.RangePicker/>
                        </Form.Item>
                    }
                    {modules?.createdAt?.visible &&
                        <Form.Item {...formItemLayout} name='createdAt'
                                   label={intl.formatMessage({id: modules.createdAt.label})}>
                            <DatePicker.RangePicker/>
                        </Form.Item>
                    }
                    {modules?.userTypes?.visible &&
                        <Form.Item
                            name="userTypes"
                            label={intl.formatMessage({id: 'users.user_type'})}
                        >
                            <Checkbox.Group>
                                {filterBy === 'ORGANIZATION' &&
                                    <Checkbox value="ADMIN" style={{lineHeight: '32px'}}>
                                        <FormattedMessage id='user.type.ADMIN'/>
                                    </Checkbox>
                                }
                                {filterBy === 'CUSTOMER' &&
                                    <Checkbox value="CUSTOMER_ADMIN" style={{lineHeight: '32px'}}>
                                        <FormattedMessage id='user.type.CUSTOMER_ADMIN'/>
                                    </Checkbox>
                                }
                                {filterBy === 'CUSTOMER' &&
                                    <Checkbox value="STUDENT" style={{lineHeight: '32px'}}>
                                        <FormattedMessage id='user.type.STUDENT'/>
                                    </Checkbox>
                                }
                            </Checkbox.Group>
                        </Form.Item>
                    }
                    {modules?.newUsers?.visible &&
                        <Switch isFormItem name='newUsers' label={intl.formatMessage({id: 'users.new_users'})}/>
                    }
                    {modules?.recertification?.visible &&
                        <Form.Item name={'recertification'} {...formItemLayout}
                                   label={intl.formatMessage({id: 'program.recertification'})}>
                            <Checkbox.Group>
                                <Checkbox value={1}><FormattedMessage id='general.yes'/></Checkbox>
                                <Checkbox value={0}><FormattedMessage id='general.no'/></Checkbox>
                            </Checkbox.Group>
                        </Form.Item>
                    }
                    {modules?.resourceGroup?.visible &&
                        <Select
                            mode={modules?.resourceGroup?.mode}
                            allowClear
                            showSearch
                            name='resourceGroup'
                            label={intl.formatMessage({id: 'system.group'})}
                            dataKey='resourceGroup'
                            url='/api/v1/courses/resources/params/groups'
                            customLayout={true}
                            saveLoadedData={true}
                            setLoadedData={setResourceGroup}
                            customObjLabel={(el: any) => intl.formatMessage({id: `system.resource.type.${el}`})}
                        />
                    }
                    {modules?.resourceType?.visible &&
                        <Select
                            mode={modules?.resourceType?.mode}
                            allowClear
                            showSearch
                            name='resourceType'
                            label={intl.formatMessage({id: 'general.type'})}
                            dataKey='resourceType'
                            url='/api/v1/courses/resources/params/types'
                            integerKey={true}
                            customLayout={true}
                            saveLoadedData={true}
                            setLoadedData={setResourceType}
                        />
                    }
                    {modules?.resourceName?.visible &&
                        <Select
                            mode={modules?.resourceName?.mode}
                            allowClear
                            showSearch
                            name='resourceName'
                            label={intl.formatMessage({id: 'general.title'})}
                            dataKey='resourceName'
                            url='/api/v1/courses/resources/params/names'
                            integerKey={true}
                            customLayout={true}
                            saveLoadedData={true}
                            setLoadedData={setResourceName}
                        />
                    }
                    {modules?.reservedFrom?.visible &&
                        <Form.Item {...formItemLayout} name='reservedFrom'
                                   label={intl.formatMessage({id: 'general.reserved_from'})}>
                            <DatePicker.RangePicker
                                showTime={{format: 'HH:mm'}}
                                format="YYYY-MM-DD HH:mm"
                            />
                        </Form.Item>
                    }
                    {modules?.reservedTill?.visible &&
                        <Form.Item {...formItemLayout} name='reservedTill'
                                   label={intl.formatMessage({id: 'general.reserved_till'})}>
                            <DatePicker.RangePicker
                                showTime={{format: 'HH:mm'}}
                                format="YYYY-MM-DD HH:mm"
                            />
                        </Form.Item>
                    }
                    {modules?.resourceBusyness?.visible &&
                        <Select
                            mode={modules?.resourceBusyness?.mode}
                            allowClear
                            showSearch
                            name='resourceBusyness'
                            label={intl.formatMessage({id: 'general.busyness'})}
                            dataKey='busyHours'
                            url={`/api/v1/courses/resources/params/hours/${locale}`}
                            integerKey={true}
                            customLayout={true}
                            saveLoadedData={true}
                            setLoadedData={setResourceBusyness}
                        />
                    }
                    {modules?.programStatus?.visible &&
                        <Form.Item name={'programStatus'} {...formItemLayout}
                                   label={intl.formatMessage({id: 'general.program_status'})}>
                            <Checkbox.Group>
                                <Checkbox value="COMPLETED"><FormattedMessage id='general.completed'/></Checkbox>
                                <Checkbox value="IN_PROGRESS"><FormattedMessage id='general.in_progress'/></Checkbox>
                                <Checkbox value="NOT_STARTED"><FormattedMessage id='general.not_started'/></Checkbox>
                                <Checkbox value="UN_ENROLLED"><FormattedMessage id='general.un_enrolled'/></Checkbox>
                            </Checkbox.Group>
                        </Form.Item>
                    }
                    {modules?.activity?.visible &&
                        <Select
                            mode={modules?.activity?.mode}
                            allowClear
                            showSearch
                            name='activity'
                            label={intl.formatMessage({id: 'general.activity'})}
                            url={`/api/v1/courses/learning-program/${programId}/activities`}
                            integerKey={true}
                            customLayout={true}
                            saveLoadedData={true}
                            setLoadedData={setActivity}
                            customObjLabel={(el: any) => el[locale] ? el[locale] : el[Object.keys(el)[0]]}
                        />
                    }
                    {modules?.lecture?.visible &&
                        <Select
                            mode={modules?.lecture?.mode}
                            allowClear
                            showSearch
                            name='lecture'
                            label={intl.formatMessage({id: 'general.lecture'})}
                            url={`/api/v1/courses/learning-program/${programId}/lectures`}
                            integerKey={true}
                            customLayout={true}
                            saveLoadedData={true}
                            setLoadedData={setLecture}
                        />
                    }
                    {modules?.activityStatus?.visible &&
                        <Form.Item name={'activityStatus'} {...formItemLayout}
                                   label={intl.formatMessage({id: 'general.activity_status'})}>
                            <Checkbox.Group>
                                <Checkbox value="ATTENDED"><FormattedMessage id='general.attended'/></Checkbox>
                                <Checkbox value="MISSED"><FormattedMessage id='general.missed'/></Checkbox>
                                <Checkbox value="PASSED"><FormattedMessage id='general.passed'/></Checkbox>
                                <Checkbox value="FAILED"><FormattedMessage id='general.failed'/></Checkbox>
                            </Checkbox.Group>
                        </Form.Item>
                    }

                    {modules?.directManagerDepartments?.visible &&
                        <Select
                            mode={modules?.directManagerDepartments?.mode}
                            allowClear
                            showSearch
                            name='directManagerDepartments'
                            label={intl.formatMessage({id: 'general.department'})}
                            dataKey='data'
                            url={'/api/v1/subordinate/departments'}
                            integerKey={true}
                            customLayout={true}
                            saveLoadedData={true}
                            setLoadedData={setDepartmentId}
                        />
                    }
                    {modules?.directManagerOrganizationalUnits?.visible &&
                        <Select
                            mode={modules?.directManagerOrganizationalUnits?.mode}
                            allowClear
                            showSearch
                            name='directManagerOrganizationalUnits'
                            dataKey='data'
                            label={intl.formatMessage({id: "general.unit"})}
                            url={'api/v1/subordinate/org-units'}
                            integerKey={true}
                            customLayout={true}
                            saveLoadedData={true}
                            setLoadedData={setOrgUnits}
                        />
                    }
                    {modules?.directManagerPrograms?.visible &&
                        <Select
                            name='directManagerPrograms'
                            allowClear
                            showSearch
                            label={intl.formatMessage({id: "general.learning_program"})}
                            mode={modules?.directManagerPrograms?.mode}
                            dataKey='programs'
                            url={'/api/v1/courses/department/programs'}
                            customObjLabel={(el: any) => el[locale] ? el[locale] : el[Object.keys(el)[0]]}
                            integerKey={true}
                            customLayout={true}
                            saveLoadedData={true}
                            setLoadedData={setProgramName}
                        />
                    }
                    {modules?.directManagerCompetences?.visible &&
                        <Select
                            name='directManagerCompetences'
                            allowClear
                            showSearch
                            label={intl.formatMessage({id: "general.competence"})}
                            mode={modules?.directManagerCompetences?.mode}
                            dataKey='competences'
                            url={'/api/v1/courses/department/competences'}
                            integerKey={true}
                            customLayout={true}
                            saveLoadedData={true}
                            setLoadedData={setCompetences}
                        />
                    }
                    {modules?.directManagerProgramStatus?.visible &&
                        <Form.Item name={'directManagerProgramStatus'} {...formItemLayout}
                                   label={intl.formatMessage({id: 'general.program_status'})}>
                            <Checkbox.Group>
                                <Checkbox value="COMPLETED"><FormattedMessage id='general.completed'/></Checkbox>
                                <Checkbox value="IN_PROGRESS"><FormattedMessage id='general.in_progress'/></Checkbox>
                                <Checkbox value="NOT_STARTED"><FormattedMessage id='general.not_started'/></Checkbox>
                            </Checkbox.Group>
                        </Form.Item>
                    }
                    {modules?.academyStatus?.visible &&
                        <Form.Item name={'academyStatus'} {...formItemLayout}
                                   label={intl.formatMessage({id: 'general.status'})}>
                            <Checkbox.Group>
                                <Checkbox value="NOT_STARTED"><FormattedMessage id='general.not_started'/></Checkbox>
                                <Checkbox value="IN_PROGRESS"><FormattedMessage id='general.in_progress'/></Checkbox>
                                <Checkbox value="COMPLETED"><FormattedMessage id='general.completed'/></Checkbox>
                                <Checkbox value="COMPLIANT"><FormattedMessage id='general.compliant'/></Checkbox>
                            </Checkbox.Group>
                        </Form.Item>
                    }
                    {modules?.enrollmentType?.visible &&
                        <Form.Item name={'enrollmentType'} {...formItemLayout}
                                   label={intl.formatMessage({id: 'general.enrollment_type'})}>
                            <Checkbox.Group>
                                <Checkbox value="INVITE_ENROLLMENT"><FormattedMessage
                                    id='general.invite_enrollment'/></Checkbox>
                                <Checkbox value="SELF_ENROLLMENT"><FormattedMessage
                                    id='general.self_enrollment'/></Checkbox>
                            </Checkbox.Group>
                        </Form.Item>
                    }
                    {modules?.directManagerCompetenceStatus?.visible &&
                        <Form.Item name={'directManagerCompetenceStatus'} {...formItemLayout}
                                   label={intl.formatMessage({id: 'general.competence_status'})}>
                            <Checkbox.Group>
                                <Checkbox value="VALID"><FormattedMessage id='general.valid'/></Checkbox>
                                <Checkbox value="NOT_VALID"><FormattedMessage id='general.not_valid'/></Checkbox>
                            </Checkbox.Group>
                        </Form.Item>
                    }
                    {modules?.delayed?.visible &&
                        <Switch isFormItem name='delayed'
                                label={intl.formatMessage({id: 'program.program_delayed'})}/>
                    }
                    <Spinner spinning={isLoading}>
                        {modules?.customerFilter?.visible &&
                            <CustomValuesSelect
                                customerId={modules?.customerSelect ? customerId : modules?.customerFilter.customerId}
                                allowClear
                                showArrow
                                mode={modules.customerFilter.mode}
                                setLoading={setIsLoading}
                                customLayout={true}
                                setCustomFilterValues
                                filterOption={(input: string, option: any) => {
                                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                }}
                            />
                        }
                    </Spinner>
                </Form>
            </Modal>
            {renderSelectedFilters(selectedFilters)}
        </>
    )
};

export default connect(mapStateToProps)(FilterModal);
