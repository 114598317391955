import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { NavLink, useNavigate } from 'react-router-dom';
import { Modal, Popover } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Search, Table, Button } from "ui";
import FlexRow from "components/FlexRow";
import { useSession } from "hooks/Lectures/useSession";
import { LectureProps, LectureSessionType } from '../types';
import SessionStatusBadge from '../SessionStatusBadge';

export interface LectureSessionsListProps {
    lecture: LectureProps,
    reloadLecture(): any,
}

const LectureSessionsList: React.FC<LectureSessionsListProps> = ({ lecture, reloadLecture }) => {
    const [reload, setReload] = useState<boolean>(false);
    const [searchString, setSearchString] = useState<string>('');
    const [visibleTooltip, setVisibleTooltip] = useState<number | null>(null);
    const { deleteSession } = useSession();

    const navigate = useNavigate();
    const intl = useIntl();
    const { confirm } = Modal;

    const handleVisibleChange = (visible: boolean, recordId: number) => {
        if (visible) {
            setVisibleTooltip(recordId);
        } else {
            setVisibleTooltip(null);
        }
    };

    const columns = [
        {
            key: 'title',
            title: intl.formatMessage({ id: 'general.lectures.session' }),
            render: (record: any) => {
                return (
                    <NavLink to={`/lectures/${lecture.id}/sessions/${record.id}`}>
                        {record.title}
                    </NavLink>
                )
            }
        },
        {
            key: 'lectors',
            title: intl.formatMessage({ id: 'general.lectures.instructor' }),
            render: (record: any) => record.lectors
        },
        {
            key: 'dateTime',
            title: intl.formatMessage({ id: 'general.lectures.date_and_time' }),
            render: (record: any) => `${record.date} ${record.start ?? ''}`
        },
        {
            key: 'type',
            title: intl.formatMessage({ id: 'general.lectures.session_type' }),
            render: (record: any) => {
                switch (record.type) {
                    case LectureSessionType.Online:
                        return <FormattedMessage id='general.lectures.online' />;
                    case LectureSessionType.ClassRoom:
                        return <FormattedMessage id='general.lectures.classroom' />
                }
            }
        },
        {
            key: 'capacity',
            title: intl.formatMessage({ id: 'general.lectures.capacity' }),
            render: (record: any) => `${record.attendees ?? 0}/${record.capacity ?? 0}`
        },
        // {
        //     key: 'sign-up-status',
        //     title: intl.formatMessage({ id: 'general.lectures.sign_up_status' }),
        //     render: (record: any) => record.signUpStatus
        // },
        {
            key: 'status',
            title: intl.formatMessage({ id: 'general.lectures.session_status' }),
            render: (record: any) => <SessionStatusBadge status={record.status} />
        },
        {
            key: 'actions',
            render: (_text: string, record: any) => {
                const content = (
                    <>
                        <div className="popover-item" onClick={() => {
                            navigate(`/lectures/${lecture.id}/sessions/create/?copy=${record.id}`);
                        }}>
                            <FormattedMessage id="general.lectures.duplicate" />
                        </div>
                        <div className="popover-item" onClick={() => {
                            warnDeletion(record.id);
                            setVisibleTooltip(null);
                        }}>
                            <FormattedMessage id="general.delete" />
                        </div>
                    </>
                );

                return (
                    <Popover
                        open={record.id === visibleTooltip}
                        content={content}
                        trigger="click"
                        placement="bottomRight"
                        arrowPointAtCenter
                        onOpenChange={(visible) => handleVisibleChange(visible, record.id)}
                    >
                        <div style={{ width: '100%', cursor: 'pointer', textAlign: 'center' }}>
                            <i className="fal fa-ellipsis-v" style={{ fontSize: '16px' }} />
                        </div>
                    </Popover>
                )
            },
        }
    ];

    const handleDeleteSession = async (id: number) => {
        if (await deleteSession(lecture.id, id)) {
            setReload(!reload);
        }
    }

    const warnDeletion = async (id: number) => {
        confirm({
            title: intl.formatMessage({ id: 'general.lectures.session' }),
            content: intl.formatMessage({ id: 'general.lectures.question_delete_session' }),
            icon: <ExclamationCircleOutlined />,
            okText: intl.formatMessage({ id: 'general.yes' }),
            cancelText: intl.formatMessage({ id: 'general.no' }),

            onOk() {
                handleDeleteSession(id);
            }
        });
    }

    return (
        <>
            <FlexRow
                left={
                    <Button type='primary' onClick={() => { navigate(`/lectures/${lecture.id}/sessions/create`); }}>
                        <FormattedMessage id='general.lectures.add_new_session' />
                    </Button>
                }
                right={
                    <Search setSearchString={setSearchString} setReload={setReload} />
                }
            />
            <Table
                columns={columns}
                url={`/api/v1/courses/lectures/${lecture.id}/sessions`}
                setReload={setReload}
                reload={reload}
                rowSelection={false}
                rowKey={'id'}
                search={searchString}
            />
        </>
    );
};

export default LectureSessionsList;