import React from 'react';
import { useIntl } from 'react-intl';
import { Form } from 'antd';
import { Modal } from 'ui';
import { Input } from 'components/Form';
import { useLecture } from "hooks/Lectures/useLecture";

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

interface LectureCreationModaltInterface {
    programId?: number  | undefined
    visible?: boolean | undefined;
    onCancel(cancel: boolean): any;
    onSubmit(id: number): any;
}

const LectureCreationModal: React.FC<LectureCreationModaltInterface> = ({ programId, visible = false, onCancel, onSubmit }) => {
    const { saveLecture, lectureLoading } = useLecture();
    const intl = useIntl();
    const [form] = Form.useForm();
    
    const handleOnCancel = () => {
        form.resetFields();
        onCancel(false)
    }

    const handleFormSubmit = async (values: any) => {
        if (!!programId) {
            values.programId = programId;
        }

        const lecture = await saveLecture(values);

        if (lecture && lecture.id) {
            form.resetFields();
            onSubmit(lecture.id);
        }
    };

    return (
        <Modal
            open={visible}
            isLoading={lectureLoading}
            onCancel={() => handleOnCancel()}
            title={intl.formatMessage({ id: 'general.lectures.add_new_lecture' })}
            okText={intl.formatMessage({ id: 'general.create' })}
            cancelText={intl.formatMessage({ id: 'general.cancel' })}
            onOk={form.submit}
        >
            <Form form={form} onFinish={handleFormSubmit} {...formItemLayout}>
                <Input
                    name='title'
                    label={intl.formatMessage({ id: 'general.lectures.title' })}
                    customRules={[{ required: true, message: intl.formatMessage({ id: 'validation.field_required' }) }]}
                    customLayout={true} {...formItemLayout}
                />
            </Form>
        </Modal>
    )
};
export default LectureCreationModal;
