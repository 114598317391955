import React, {useState} from 'react';
import {NavLink} from 'react-router-dom';
import {FormattedMessage, useIntl} from 'react-intl';
import {Button, Popover, Tag, Tooltip} from 'antd';
import moment from 'moment';
import DefaultLayout from 'components/DefaultLayout';
import InstructionButton from "components/VideoInstructions/InstructionButton";
import {Switch} from 'components/Form';
import FlexRow from 'components/FlexRow';
import {Search, Table} from "ui";
import useHandleError from "utils/useHandleError";
import {usePhishing} from "hooks/Phishing/usePhishing";

const PhishingCampaignList: React.FC = ({}: any) => {
    const {saveCampaign} = usePhishing();
    const [reload, setReload] = useState(false);
    const [visibleTooltip, setVisibleTooltip] = useState<number | null>(null);
    const [dataSource, setDataSource] = useState<any>([]);
    const [scenarioUpdate, setScenarioUpdate] = useState(false);
    const [filter, setFilter] = useState('&filter[status]=ONGOING,DRAFT')
    const [searchString, setSearchString] = useState('');

    const intl = useIntl();
    const [handleError] = useHandleError();

    const handleVisibleChange = (visible: boolean, rowIndex: number) => {
        if (visible) {
            setVisibleTooltip(rowIndex);
        } else {
            setVisibleTooltip(null);
        }
    }

    const columns = [
        {
            title: intl.formatMessage({id: 'phishing.campaign_name'}),
            dataIndex: 'campaignName',
            render: (campaignName: string, record: any) => {
                return (
                    <NavLink to={`/phishing/campaign/${record.id}/settings`}>
                        {record.title}
                    </NavLink>
                );
            },
        },
        {
            title: intl.formatMessage({id: 'phishing.launch_date'}),
            sorter: true,
            key: 'begin_date',
            render: (beginDate: string, record: any) => record.beginDate ? moment(record.beginDate).format('DD.MM.YYYY') : '-',
        },
        {
            title: intl.formatMessage({id: 'phishing.completed_date'}),
            key: 'deadline',
            sorter: true,
            render: (completionDate: string, record: any) => record.deadline ? moment(record.deadline).format('DD.MM.YYYY') : '-',
        },
        {
            title: intl.formatMessage({id: 'general.type'}),
            dataIndex: 'type',
            key: 'type',
            render: (type: string) => intl.formatMessage({id: `phishing.${type}`}),
        },
        {
            title: intl.formatMessage({id: 'phishing.target_users'}),
            sorter: true,
            dataIndex: 'targetEnrollments',
            key: 'targetEnrollments',
        },
        {
            title: (
                <span>
                  {intl.formatMessage({id: 'phishing.phish_e_mails_delivered'})}
                    <Tooltip title={intl.formatMessage({id: 'phishing.results_hint'})}>
                      <i className='fal fa-question-circle header-item'/>
                  </Tooltip>
                </span>),
            dataIndex: 'phishingResult',
            key: 'result',
        },
        {
            title:
                <div style={{textAlign: 'center', whiteSpace: 'nowrap'}}>
                    {intl.formatMessage({id: 'phishing.turn_on_off'})}
                    <Tooltip title={intl.formatMessage({id: 'phishing.turn_on_off_hint'})}>
                        <i className='fal fa-question-circle header-item'/>
                    </Tooltip>
                </div>,
            align: 'center',
            render: (text: string, record: any) => {
                return (
                    <Switch
                        checked={!!record.phishing}
                        loading={scenarioUpdate}
                        onClick={() => updatePhishing(record, !record.phishing)}
                    />
                );
            },
        },
        {
            title: intl.formatMessage({id: 'general.status'}),
            dataIndex: 'status',
            render: (status: 'ONGOING' | 'DRAFT' | 'FINISHED') => {
                const statusArray = {
                    ONGOING: 'blue',
                    DRAFT: 'gold',
                    FINISHED: 'green'
                }

                return (
                    <div className="phishing-campaign-tags">
                        <Tag color={statusArray[status]} className={`phishing.${status}`}>
                            <FormattedMessage id={`gophish.status.${status}`}/>
                        </Tag>
                    </div>
                );
            },
        },
        {
            key: 'actions',
            render: (text: string, record: any, index: any) => {
                const content = (
                    <>
                        <NavLink to={`/phishing/campaign/${record.id}/settings`}>
                            <div className="popover-item">
                                <FormattedMessage id="general.view"/>
                            </div>
                        </NavLink>
                    </>
                );

                return (
                    <Popover
                        open={index === visibleTooltip}
                        content={content}
                        trigger="click"
                        placement="bottomRight"
                        arrowPointAtCenter
                        onOpenChange={(visible) => handleVisibleChange(visible, index)}
                    >
                        <div style={{width: '100%', cursor: 'pointer', textAlign: 'center'}}>
                            <i className="fal fa-ellipsis-v" style={{fontSize: '16px'}}/>
                        </div>
                    </Popover>
                );
            }
        },
    ];

    const updatePhishing = async (record: any, phishing: boolean) => {
        setScenarioUpdate(true)
        let key: any = Object.entries(dataSource).filter((el: any) => el[1].learningCompetenceProgram === record.learningCompetenceProgram);

        try {
            dataSource[key[0][0]].phishing = !record.phishing
            setDataSource(dataSource);
            await saveCampaign({phishing}, record.id);
        } catch (error) {
            handleError(error)
        } finally {
            setScenarioUpdate(false)
        }
    }

    const handleFilterSwitch = async (status: boolean) => {
        setFilter(`&filter[status]=${(status ? 'ONGOING,DRAFT' : 'ONGOING,DRAFT,FINISHED')}`)
        setReload(true)
    }

    return (
        <>
            <DefaultLayout.PageLayout>
                <DefaultLayout.PageHeader title={intl.formatMessage({id: 'phishing.phishing_campaign'})}/>
                <DefaultLayout.PageContent>
                    <FlexRow
                        left={
                            <>
                                <NavLink to={'/phishing/campaign/add'}>
                                    <Button type="primary">
                                        <FormattedMessage id="phishing.create_campaign"/>
                                    </Button>
                                </NavLink>
                            </>
                        }
                        right={
                            <>
                                <div className={'flex gap-10 flex-column align-end'}>
                                    <div className={'flex gap-10'}>
                                        <FormattedMessage id='campaigns.dont_show_finished'/>
                                        <Switch defaultChecked={true} onClick={(value: boolean) => handleFilterSwitch(value)} />
                                    </div>
                                </div>
                                <div className="search">
                                    <Search setSearchString={setSearchString} setReload={setReload}/>
                                </div>
                                <InstructionButton position='PHISHING'/>
                            </>
                        }
                    />
                    <Table
                        columns={columns}
                        url={'/api/v1/courses/phishing/campaign'}
                        setReload={setReload}
                        reload={reload}
                        rowSelection={false}
                        search={searchString}
                        manualDataUpdate={scenarioUpdate}
                        updateData={dataSource}
                        filter={filter}
                        saveData={setDataSource}
                        rowKey={'id'}
                        scroll={{x: 800}}
                    />
                </DefaultLayout.PageContent>
            </DefaultLayout.PageLayout>
            {/*<PhishingCampaignFilterModal*/}
            {/*    visible={showCampaignFilterModal}*/}
            {/*    onCancel={() => setShowCampaignFilterModal(false)}*/}
            {/*    getRecords={getRecords}*/}
            {/*    resourceUrl={resourceUrl}*/}
            {/*    currentPageSize={currentPageSize}*/}
            {/*    setPhishingCampaigns={setPhishingCampaigns}*/}
            {/*    setTotalRecords={setTotalRecords}*/}
            {/*    setLoading={setIsCampaignsLoading}*/}

            {/*/>*/}
            {/*<Modal*/}
            {/*    className="filter-phishing"*/}
            {/*    open={showPhishingCampaignModal}*/}
            {/*    onCancel={() => setShowPhishingCampaignModal(false)}*/}
            {/*    title={intl.formatMessage({id: 'phishing.campaign'})}*/}
            {/*    okText={intl.formatMessage({id: 'general.create'})}*/}
            {/*    cancelText={intl.formatMessage({id: 'general.cancel'})}*/}
            {/*    onOk={() => createCampaign()}*/}
            {/*>*/}
            {/*    <Form form={modalForm}>*/}
            {/*        {*/}
            {/*            session.active_user_type === 'SUPER_ADMIN' ?*/}
            {/*                <Select*/}
            {/*                    showSearch*/}
            {/*                    name='customer'*/}
            {/*                    label={intl.formatMessage({id: "general.select_customer"})}*/}
            {/*                    url={config.api.routes.backend.organizationsCustomers}*/}
            {/*                    integerKey={true}*/}
            {/*                    customRules={[{ required: true, message: intl.formatMessage({id: "validation.field_required"}) }]}*/}
            {/*                    onChange={(value: any) => {*/}
            {/*                        setNewCampaignCustomer(value);*/}
            {/*                    }}*/}
            {/*                    isResponseArray={true}*/}
            {/*                    dataKey='customers'*/}
            {/*                    mapDataEntries={(el: any) => ({value: el.uuid + ',' + el.id, label: el.name})}*/}
            {/*                    valueKey='value'*/}
            {/*                    labelKey='label'*/}
            {/*                    customLayout={true}*/}
            {/*                    filterOption={(input: string, option: any) => {*/}
            {/*                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;*/}
            {/*                    }}*/}
            {/*                />*/}
            {/*                : null*/}
            {/*        }*/}
            {/*        <Form.Item*/}
            {/*            labelCol={{ span: 8 }}*/}
            {/*            wrapperCol={{ span: 6 }}*/}
            {/*            style={{marginRight:'-25%'}}*/}
            {/*            name="campaignType"*/}
            {/*            label={intl.formatMessage({id: "phishing.choose_scenario"})}*/}
            {/*        >*/}
            {/*            <Radio.Group onChange={(el:any)=>setSelectedCampaignType(el.target.value)}>*/}
            {/*                <Radio value={'DYNAMIC'}><FormattedMessage id='phishing.dynamic_scenario'/></Radio>*/}
            {/*                <Radio value={'STRICTLY'}><FormattedMessage id='phishing.strictly_scenario'/></Radio>*/}
            {/*            </Radio.Group>*/}
            {/*        </Form.Item>*/}
            {/*    </Form>*/}
            {/*</Modal>*/}
        </>
    )
};

export default PhishingCampaignList;
