import React, {useEffect, useState} from 'react';
import FilterCheckBox from '../FilterCheckBox';
import FilterPopover from "../FilterPopover";
import {Button} from "ui";
import {FormattedMessage, useIntl} from "react-intl";
import {useLecture} from "hooks/Lectures/useLecture";
import {useAudience} from "../../../../hooks/Audiences/useAudience";
import {Form, Radio, Space} from "antd";
import {LectureSessionType} from "../../../../components/Lectures/types";

interface RegisterTypeFilterInterface {
    tagBuilder: Function;
    session?: any;
    setFilterValues: any;
    filterValues: any
    form: any;
    filterKey: string;
    setFilterKey: React.Dispatch<React.SetStateAction<string>>;
}

const RegisterTypeFilter: React.FC<RegisterTypeFilterInterface> = ({
                                                                       filterKey,
                                                                       setFilterKey,
                                                                       form,
                                                                       tagBuilder,
                                                                       setFilterValues,
                                                                       filterValues
                                                                   }) => {
    const {searchAudiences, audienceLoading: isLoading} = useAudience()
    const [data, setData] = useState([]);
    const [initialValues, setInitialValues] = useState(undefined)

    const key = 'audience-enrollment'
    const intl = useIntl();

    useEffect(() => {
        onSearch()
    }, [])

    const onSearch = async (value: string = '') => {
        const response = await searchAudiences(value)
        setData(response);
        setFilterValues(
            {
                ...filterValues,
                audienceEnrollment: {
                    ...filterValues.audienceEnrollment,
                    ...response
                }
            }
        )
    };

    const content = (
        <>
            <Form.Item
                name='enrollmentTypeStatus'
                label={intl.formatMessage({id: 'general.enrollment_type'})}
            >
                <Radio.Group>
                    <Space>
                        <Radio value={'MANUAL_ENROLLMENT'}>
                            <FormattedMessage id='general.enrollment.manual_enrollment'/>
                        </Radio>
                        <Radio value={'ENROLLMENT_CANCELLED'}>
                            <FormattedMessage id='general.enrollment.enrollment_cancelled'/>
                        </Radio>
                    </Space>
                </Radio.Group>
            </Form.Item>
            <FilterCheckBox data={data} name={'audienceEnrollment'} onSearch={onSearch}/>
            <div className={'flex justify-between p-1'}>
                <Button type={'link'} onClick={() => handleClose()}>
                    <FormattedMessage id={'general.cancel'}/>
                </Button>
                <Button type={'primary'} onClick={() => {
                    setFilterKey('')
                    handleOpen(false)
                }}>
                    <FormattedMessage id={'general.select'}/>
                </Button>
            </div>
        </>
    );

    const handleClose = () => {
        form.setFieldsValue(initialValues);
        setFilterKey('');
    }

    const handleOpen = (open: boolean) => {
        if (open) {
            setInitialValues(form.getFieldsValue())
            setFilterValues(
                {
                    ...filterValues,
                    audienceEnrollment: {
                        ...filterValues.audienceEnrollment,
                        ...data
                    }
                }
            )
        } else {
            tagBuilder()
        }
    }

    return (
        <FilterPopover
            popoverKey={key}
            filterKey={filterKey}
            setOpen={setFilterKey}
            handleOpen={handleOpen}
            content={content}
            loading={isLoading}
            title={'general.search_by_register_type'}
            buttonTitle={'general.register_type'}
        />
    );
};

export default RegisterTypeFilter;