import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import DoughnutChart from 'components/Charts/DoughnutChart';
import coursesApiClient from 'utils/coursesApiClient';
import {useParams} from "react-router-dom";
import useHandleError from "utils/useHandleError";

const SuccessPieChart: React.FC<any> = ({ }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState({
        data: {},
        inner: {},
    });
    const params = useParams();
    const [handleError] = useHandleError();

    const learningTestId = params?.id ? parseInt(params?.id) : 0;

    const intl = useIntl();

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        try {
            setIsLoading(true);

            const url = `/api/v1/courses/learning-tests/${learningTestId}/review/success-rate`;
            const response = await coursesApiClient.request(url);
            const responseData = response.data;

            const data = {
                data: [
                    responseData.success,
                    responseData.failed,
                ],
                total: responseData.total,
            };

            const parsedData = {
                data: {
                    labels: [
                        intl.formatMessage({ id: 'learning_tests.success' }),
                        intl.formatMessage({ id: 'learning_tests.failed' }),
                    ],
                    datasets: [{
                        data: data.data,
                        backgroundColor: [
                            'rgb(33, 208, 160)',
                            'rgb(244, 98, 100)',
                        ],
                        hoverBackgroundColor: [
                            'rgb(13, 188, 140)',
                            'rgb(224 ,68, 80)',
                        ],
                    }],
                },
                inner: {
                    text: intl.formatMessage({ id: 'learning_tests.total_tests' }),
                    number: data.total,
                },
            };

            setData(parsedData);
        } catch (error) {
            handleError(error);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <DoughnutChart
            data={data.data}
            title={intl.formatMessage({ id: 'learning_tests.success_rate' })}
            showLabelValues={false}
            inner={data.inner}
            loading={isLoading}
        />
    );
}

export default SuccessPieChart;