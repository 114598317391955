import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Table, Radio} from 'antd';
import {Switch} from "components/Form";

const PhishingTop: React.FC = ({}: any) => {
    const intl = useIntl();
    const [size, setSize] = useState('thisMonth');

    const [data, setData] = useState(
        [
            {
                name: 'Vitālijs Ševčenko',
                sentMails: 3,
                points: 20,
                active: 0

            },
            {
                name: 'Jānis Bērziņš',
                sentMails: 1,
                points: 0,
                active: 1

            },
            {
                name: 'Ērika Ciguze',
                sentMails: 1,
                points: 0,
                active: 0

            },
        ]
    )

    const columns = [
        {
            title: intl.formatMessage({id: 'general.name'}),
            dataIndex: 'name'
        },
        {
            title: intl.formatMessage({id: 'general.sent_mails'}),
            dataIndex: 'sentMails'
        },
        {
            title: intl.formatMessage({id: 'general.points'}),
            dataIndex: 'points'
        },
        {
            title: intl.formatMessage({id: 'general.anonymous'}),
            dataIndex: 'active',
            render: (active: number, record: any) => {
                return (
                    <Switch checked={!!active}/>
                )
            }
        },
    ];

    return (
        <>
            <div className={'flex justify-end'}>
                <Radio.Group value={size} onChange={e => setSize(e.target.value)}>
                    <Radio.Button value="thisMonth">
                        <FormattedMessage id='general.this_month'/>
                    </Radio.Button>
                    <Radio.Button value="thisQuarter">
                        <FormattedMessage id='general.this_quarter'/>
                    </Radio.Button>
                    <Radio.Button value="thisYear">
                        <FormattedMessage id='general.this_year'/>
                    </Radio.Button>
                </Radio.Group>
            </div>

            <Table columns={columns} dataSource={data}/>
        </>
    )
};

export default PhishingTop;
