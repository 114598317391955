import { FormInstance } from 'antd/lib/form';
import { MailScenarioStatusType } from 'pages/Emailing/types';

export enum AudienceStatusTypes {
    Online = 'ONLINE',
    Offline = 'OFFLINE',
}

export enum AudienceTypes {
    Strict = 'STRICT',
    Dynamic = 'DYNAMIC',
}

export enum AudienceRuleOperator {
    And = 'AND',
    Or = 'OR',
}

export enum AudienceUserMembership {
    Manual = 'MANUAL',
    Automatic = 'AUTOMATIC',
}

export enum AudienceRuleData {
    UserPosition = 'USER_POSITION',
    UserCustomer = 'USER_CUSTOMER',
    UserOrganizationalUnit = 'USER_ORGANIZATIONAL_UNIT',
    UserCompetence = 'USER_COMPETENCE',
    UserAudience = 'USER_AUDIENCE',
    CompletedCurriculum = 'COMPLETED_CURRICULUM',
    NewUser = 'NEW_USER',
    Competence = 'COMPETENCE',
    Department = 'DEPARTMENT',
}

export enum CourseScenarioType {
    Certification = 'CERTIFICATION',
    TimedEvent = 'TIMED_EVENT',
}

export enum PhishingType {
    Dynamic = "DYNAMIC",
    Strictly = "STRICTLY",
}

export enum AudienceRuleFunction {
    Contains = 'CONTAINS',
    DoesNotContain = 'DOES_NOT_CONTAIN',
    EqualTo = 'EQUAL_TO',
    NotEqualTo = 'NOT_EQUAL_TO',
    Empty = 'EMPTY',
    StartsWith = 'STARTS_WITH',
    EndsWith = 'ENDS_WITH',
    Before = 'BEFORE',
    After = 'AFTER',
    Boolean = 'BOOLEAN',
}

export enum AudienceNotificationScheduleCron {
    Daily = "0 0 * * *",
    Weekly =  "0 0 * * SUN",
    Monthly = "0 0 1 * *",
}

export interface AudienceTabProps {
    audience: any,
    reloadAudience(): any,
    session?: any,
    locale?: string,
}

export interface AudienceRuleSetProps {
    session?: any,
    audience: any,
    ruleSet: AudienceRuleSetModel,
    index: number,
    form?: FormInstance
    reloadAudience?(): any
}

export interface AudienceRuleSetModel {
    id: number,
    name: string,
    operator: string,
    rules?: Array<AudienceRuleModel>,
}

export interface AudienceRuleModel {
    id?: number,
    data: string,
    function: string,
    value: string,
}

export interface AudienceNotificationProps {
    id: number,
    template_id: number,
    cron_expression: AudienceNotificationScheduleCron,
    status: MailScenarioStatusType,
    description: string,
}




