import React from 'react';
import {useIntl} from 'react-intl';
import {connect} from 'react-redux';
import DefaultLayout from "components/DefaultLayout";
import {Tabs} from "antd";
import PendingGrading from "components/Grading/PendingGrading";
import GradedGrading from "components/Grading/GradedGrading";

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale
    };
};
const GradingList: React.FC<any> = ({}) => {
    const intl = useIntl();

    return (
        <DefaultLayout.PageLayout>
            <DefaultLayout.PageHeader title={intl.formatMessage({id: 'grading.grading'})}/>
            <DefaultLayout.PageContent withBottomMargin>
                <Tabs defaultActiveKey='pending_grading' destroyInactiveTabPane={true}>
                    <Tabs.TabPane key='pending_grading' tab={intl.formatMessage({id: 'grading.pending_grading'})}>
                        <PendingGrading/>
                    </Tabs.TabPane>
                    <Tabs.TabPane key='graded' tab={intl.formatMessage({id: 'grading.graded'})}>
                        <GradedGrading/>
                    </Tabs.TabPane>
                </Tabs>
            </DefaultLayout.PageContent>
        </DefaultLayout.PageLayout>
    );
};

export default connect(mapStateToProps)(GradingList);
