import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Form, Tabs, Transfer} from 'antd';
import {connect} from 'react-redux';
import {Title} from "ui";
import Users from '../Users';
import Groups from '../Groups';
import apiClient from "utils/apiClient";
import {config} from "config/config";
import generalHelpers from "utils/generalHelpers";
import './styles.scss'
import useHandleError from "../../../utils/useHandleError";

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale,
        session: state.session
    }
};

interface RecordType {
    key: string;
    name: string;
}

interface AccessFormInterface {
    locale?: string;
    transferTitle: string;
    userKey?: string;
    tableTitle: string;
    initialTargetKeys?: string[];
    url: {
        organizationUpdate?: string;
        C: string;
        R: string;
        U: string;
        D: string;
    };
    hasOrgAccess?: boolean;
    customerUuid?: boolean;
    hasAdminPermission?: boolean;
    disabled?: boolean;
}

const AccessForm: React.FC<AccessFormInterface> = ({
                                                       url,
                                                       initialTargetKeys = [],
                                                       hasOrgAccess = true,
                                                       userKey,
                                                       transferTitle,
                                                       tableTitle,
                                                       disabled = false,
                                                       customerUuid = false,
                                                       hasAdminPermission = false
                                                   }) => {
    const [customers, setCustomer] = useState<RecordType[]>([]);
    const [targetKeys, setTargetKeys] = useState<string[]>([]);
    const [transferData, setTransferData] = useState([])
    const [reload, setReload] = useState(false);

    const intl = useIntl();
    const [handleError] = useHandleError();

    useEffect(() => {
        loadCustomers();
    }, []);

    useEffect(() => {
        if (initialTargetKeys.length) {
            setTargetKeys(initialTargetKeys)
        }

    }, [initialTargetKeys]);

    const loadCustomers = async () => {

        let url = config.api.routes.backend.selectCustomers;

        if (customerUuid) {
            url = config.api.routes.backend.selectCustomersUuid
        }
        const tempData: any = [];
        const response = await apiClient.request(url, [], 'GET');
        const customers = response.customers

        Object.entries(customers).map((customer: any) => {
            const data = {
                key: customer[0].toString(),
                name: customer[1]
            };

            tempData.push(data);
        })

        setCustomer(tempData);
    };

    const handleChange = async (newTargetKeys: string[]) => {

        if (url?.organizationUpdate) {
            try {
                await apiClient.request(url.organizationUpdate, {organizations: newTargetKeys}, 'POST');
                setReload(true)
            } catch (error) {
                handleError(error)
            }
        }

        setTargetKeys(newTargetKeys);
    };

    return (
        <>
            {hasOrgAccess &&
                <>
                    <Title title={transferTitle} className='f-14 bold f-left'/>
                    <Form.Item label=' ' colon={false} className={'access-transfer'}>
                        <Transfer
                            dataSource={customers}
                            showSearch
                            targetKeys={targetKeys}
                            onChange={handleChange}
                            render={item => item.name}
                            disabled={disabled}
                        />
                    </Form.Item>
                </>
            }
            <Title title={tableTitle} className='f-14 bold f-left'/>
            <Tabs defaultActiveKey='users'>
                <Tabs.TabPane key='users' tab={intl.formatMessage({id: 'access.users'})}>
                    <Users
                        userKey={userKey}
                        customerUuid={customerUuid}
                        reloadTable={reload}
                        customers={targetKeys}
                        setUsers={setTransferData}
                        url={url}
                        disabled={disabled}
                        hasAdminPermission={!!hasAdminPermission}
                    />
                </Tabs.TabPane>
            </Tabs>
        </>
    )
};

export default connect(mapStateToProps)(AccessForm);
