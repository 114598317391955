import * as ActionTypes from 'store/actionTypes';

const defaultState = {}

const activityCreationModalActions = (state = defaultState, action: any) => {
    switch(action.type) {
        case ActionTypes.SAVE_ACTIVITY_CREATION_SETTINGS:
            let ActivityCreationSettings = action.payload;
            return ({...state, ...ActivityCreationSettings});
        case ActionTypes.CLEAR_ACTIVITY_CREATION_SETTINGS:
            return (defaultState);
        default:
            return state;
    }
}

export default activityCreationModalActions;