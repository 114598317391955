import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {NavLink} from 'react-router-dom';
import {Button, Modal, Popover} from 'antd';
import DefaultLayout from "components/DefaultLayout";
import {Space} from "antd";
import {Table, Search} from 'ui'
import FlexRow from "components/FlexRow";
import coursesApiClient from "../../../utils/coursesApiClient";
import useHandleError from "../../../utils/useHandleError";
import {ExclamationCircleOutlined} from "@ant-design/icons";

const CompetencesList: React.FC = ({}: any) => {
    const [filter, setFilter] = useState('');
    const [reload, setReload] = useState(false);
    const [search, setSearch] = useState('');
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [expandableRows, setExpandableRows] = useState<any>([]);
    const [visibleTooltip, setVisibleTooltip] = useState<number | null>(null);
    const [handleError] = useHandleError();
    const { confirm } = Modal;

    const intl = useIntl();

    const handleVisibleChange = (visible: boolean, recordId: number) => {
        if (visible) {
            setVisibleTooltip(recordId);
        } else {
            setVisibleTooltip(null);
        }
    }

    const getExpandableRowIds = (response:any) => {
        const rowIds: any[] = [];
        Object.values(response.data).map((row:any) => {
            rowIds.push(row.id);
        });
        setExpandableRows(rowIds)
    }

    const loadDelete = async (id:any) => {
        try {
            await coursesApiClient.request(`/api/v1/competences/${id}`, [], 'DELETE');
        } catch (error) {
            handleError(error);
        } finally {
            setReload(true);
        }
    }

    const warnDeletion = async (id: number) => {
        confirm({
            title: intl.formatMessage({ id: 'general.attention' }),
            content: intl.formatMessage({ id: 'confirmation.are_you_sure' }),
            icon: <ExclamationCircleOutlined />,
            okText: intl.formatMessage({ id: 'general.yes' }),
            cancelText: intl.formatMessage({ id: 'general.no' }),

            onOk() {
                loadDelete(id);
            }
        });
    }

    const PopoverContent = (row:any) => {
        return (
            <div className="custom-field-popover">
                <div className="popover-button" key='delete' onClick={() => {
                    warnDeletion(row.data.id);
                    setVisibleTooltip(null);
                }}><FormattedMessage id={'general.delete'} /></div>
            </div>
        )
    }

    const columns = [
        {
            title: intl.formatMessage({id: 'general.id'}),
            dataIndex: 'id',
            render: (title: string, record: any) => {
                if (record.parentId) {
                    return (
                        record.id
                    )
                }
            }
        },
        {
            title: intl.formatMessage({id: 'general.title'}),
            dataIndex: 'title',
            render: (title: string, record: any) => {
                if (record.parentId) {
                    return (
                        <NavLink to={`/competences/${record.id}`}>
                            {title}
                        </NavLink>
                    )
                } else {
                    return title
                }
            }
        },
        {
            title: intl.formatMessage({id: 'courses.sequence_priority'}),
            dataIndex: 'sequence',
            render: (title: string, record: any) => {
                if (record.parentId) {
                    return title
                }
            }
        },
        {
            title: intl.formatMessage({id: 'general.is_required'}),
            dataIndex: 'isRequired',
            render: (title: string, record: any) => {
                if (record.parentId) {
                    return (
                        !!record.isRequired ? intl.formatMessage({id: 'general.yes'}) : intl.formatMessage({id: 'general.no'})
                    )
                }
            }
        },
        {
            title: intl.formatMessage({id: 'general.users'}),
            dataIndex: 'users',
            render: (title: string, record: any) => {
                if (record.parentId) {
                    return (
                        record.users
                    )
                }
            }
        },
        {
            title: intl.formatMessage({id: 'system.action'}),
            dataIndex: 'actions',
            render: (title: string, record: any) => {
                return (
                    <Popover
                        open={record.id === visibleTooltip}
                        onOpenChange={(visible) => handleVisibleChange(visible, record.id)}
                        placement='bottom'
                        content={<PopoverContent data={record} />}
                        arrowContent={false}
                        trigger='click'
                    >
                        <i className="fal fa-ellipsis-v" style={{ fontSize: '16px' }} ></i>
                    </Popover>
                )
            }
        },
    ];

    return (
        <DefaultLayout.PageLayout>
            <DefaultLayout.PageHeader
                title={intl.formatMessage({id: 'general.competences'})}
            />
            <DefaultLayout.PageContent>
                <FlexRow
                    left={<>
                        <NavLink to='/competences/create'>
                            <Button type='primary'>
                                <Space size={5}>
                                    <i className="fa-solid fa-plus-large"></i>
                                    <FormattedMessage id='general.new_competence'/>
                                </Space>
                            </Button>
                        </NavLink>
                    </>}
                    right={
                        <>
                            <Search
                                setReload={setReload}
                                setSearchString={setSearch}
                            />
                        </>
                    }
                />
                <Table
                    columns={columns}
                    url='api/v1/competences'
                    setReload={setReload}
                    reload={reload}
                    rowKey='id'
                    setTotalRecords={setTotalRecords}
                    filter={filter}
                    search={search}
                    rowSelection={false}
                    onLoadFunction={getExpandableRowIds}
                    expandable={{ defaultExpandedRowKeys: expandableRows }}
                    scroll={{ x: 800 }}
                />
            </DefaultLayout.PageContent>
        </DefaultLayout.PageLayout>
    )
};

export default CompetencesList;
