import React from 'react';
import {Tabs} from 'antd';
import PhishingEvents from "./Events";
import {useIntl} from "react-intl";
import DefaultLayout from "components/DefaultLayout";
import PhishingTop from "./Top";


const PhishingAnalytics: React.FC = () => {
    const intl = useIntl();

    return (
        <DefaultLayout.PageLayout>
            <DefaultLayout.PageHeader title={intl.formatMessage({id: 'general.analytics'})}/>
            <DefaultLayout.PageContent>
                <Tabs items={[
                    {
                        label: intl.formatMessage({id: 'phishing.reported_events'}),
                        key: 'reported_events',
                        children: <PhishingEvents/>,
                    },
                    {
                        label: intl.formatMessage({id: 'phishing.user_top'}),
                        key: 'user_top',
                        children: <PhishingTop/>,
                    },
                ]}
                />
            </DefaultLayout.PageContent>
        </DefaultLayout.PageLayout>
    )
};

export default PhishingAnalytics;
