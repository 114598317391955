import React from 'react';
import { useIntl } from 'react-intl';
import { CustomerSelect } from 'components/Form';
import { EnvironmentType } from '../../types';
import {config} from 'config/config';

interface SelectProps {
  type: Omit<EnvironmentType, 'GLOBAL'>;
  setSelectedResellerId: React.Dispatch<React.SetStateAction<number>>;
  setSelectedCustomerId: React.Dispatch<React.SetStateAction<number>>;
}

const Select: React.FC<SelectProps> = ({ type, setSelectedResellerId, setSelectedCustomerId }) => {
  const intl = useIntl();

  const isReseller = type === 'RESELLER';

  interface EntryChild {
    id: number;
    type: string;
    name: string;
  }

  interface Entry {
    organizations: EntryChild;
    customers: EntryChild;
  }

  const mapDataEntries = (entry: any) => {
    return {
      value: entry.id,
      customerType: entry.type,
      label: entry.name,
    };
  };

  const handleSelectChange = (value: number) => {
    const setValue = isReseller ? setSelectedResellerId : setSelectedCustomerId;

    setValue(value);
  };

  return (
    <div style={{ maxWidth: 300, marginTop: 8, padding: '0 16px' }}>
      <CustomerSelect
        url={config.api.routes.backend[isReseller ? 'organizationsOptions' : 'organizationsCustomers']}
        label={intl.formatMessage({ id: `general.${type.toLowerCase()}` })}
        dataKey={isReseller ? 'organizations' : 'customers'}
        valueKey="value"
        labelKey="label"
        mapDataEntries={(entry: Entry) => mapDataEntries(entry)}
        customLayout={{
          labelCol: { span: 8 },
          wrapperCol: { span: 16 },
        }}
        onChange={handleSelectChange}
        isResponseArray
        showSearch
        filterOption={(input: string, option: any) => (
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        )}
      />
    </div>
  );
};

export default Select;