import React from 'react';
import { useIntl } from 'react-intl';
import { Form, InputNumber as AntDInputNumber } from 'antd';
import { constructValidationRules } from './validations';
import './styles.scss';

const InputNumber = ({error, className, name, label, validation = {}, customRules, validateTrigger, customLayout, initialValue = 0, style, ...props}: any) => {
  const intl = useIntl();

  return (
    <Form.Item
      initialValue={initialValue}
      labelCol={customLayout ? undefined : { span: 8 }}
      wrapperCol={customLayout ? undefined : { span: 6 }}
      name={name}
      label={label}
      rules={customRules ? customRules : constructValidationRules(validation, intl)}
      validateTrigger={validateTrigger}
      style={style}
    >
      <AntDInputNumber autoComplete="off" {...props} className={"default-input-field " + (className || '')} />
    </Form.Item>
  )
}

export default InputNumber;
