import React, {useEffect, useState} from 'react';
import {DatePicker, Divider, Form} from "antd";
import FilterPopover from "../FilterPopover";
import {Button} from "ui";
import {FormattedMessage} from "react-intl";

interface DateFilterInterface {
    name: string;
    tagBuilder: Function;
    title: string;
    buttonTitle: string;
    form: any,
    filterKey: string;
    setFilterKey: React.Dispatch<React.SetStateAction<string>>;
}

const DateFilter: React.FC<DateFilterInterface> = ({
                                                       filterKey,
                                                       setFilterKey,
                                                       form,
                                                       name,
                                                       tagBuilder,
                                                       title,
                                                       buttonTitle
                                                   }) => {
    const [initialValues, setInitialValues] = useState(undefined)

    const key = name

    const content = (
        <div className={'flex flex-column p-1 checkbox-wrapper'}>
            <Form.Item className={'mb-0'} name={name}>
                <DatePicker.RangePicker/>
            </Form.Item>
            <Divider plain className={'m-2'}/>
            <div className={'flex justify-between p-1'}>
                <Button type={'link'} onClick={() => handleClose()}>
                    <FormattedMessage id={'general.cancel'}/>
                </Button>
                <Button type={'primary'} onClick={() => {
                                        setFilterKey('')

                    handleOpen(false)
                }}>
                    <FormattedMessage id={'general.select'}/>
                </Button>
            </div>
        </div>
    );

    const handleClose = () => {
        form.setFieldsValue(initialValues);
                            setFilterKey('')
;
    }

    const handleOpen = (open: boolean) => {
        if (open) {
            setInitialValues(form.getFieldsValue())
        } else {
            tagBuilder()
        }
    }


    return (
        <FilterPopover
            popoverKey={key}
            filterKey={filterKey}
            setOpen={setFilterKey}
            handleOpen={handleOpen}
            content={content}
            title={title}
            buttonTitle={buttonTitle}
        />
    )
}

export default DateFilter;