import React, {useState, useEffect} from 'react';
import {Modal, Table, Button} from "ui";
import {FormattedMessage, useIntl} from "react-intl";
import './styles.scss';

interface ScenarioUsersTableInterface {
    visible: boolean;
    onCancel: React.Dispatch<React.SetStateAction<boolean>>;
    setTotalRecords: React.Dispatch<React.SetStateAction<number>>;
    filter: string;
}

const ScenarioUsersTable: React.FC<ScenarioUsersTableInterface> = ({
                                                                       visible,
                                                                       onCancel,
                                                                       setTotalRecords,
                                                                       filter
                                                                   }) => {
    const [reload, setReload] = useState(false);
    const intl = useIntl();

    useEffect(() => {

        if (filter) {
            setReload(true)
        }

    }, [filter])

    const columns = [
        {
            title: intl.formatMessage({id: 'general.name'}),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: intl.formatMessage({id: 'general.surname'}),
            dataIndex: 'surname',
        },
        {
            title: intl.formatMessage({id: 'general.email'}),
            dataIndex: 'email',
            key: 'email',
        },
    ];

    return (
        <Modal
            forceRender={true}
            width={'75%'}
            className="user-list-modal"
            open={visible}
            title={intl.formatMessage({id: 'general.users'})}
            onCancel={() => onCancel(false)}
            footer={
                <Button onClick={() => onCancel(false)}>
                    <FormattedMessage id="general.cancel"/>
                </Button>
            }
        >
            {filter &&
              <Table
                columns={columns}
                url={'api/v1/users/list'}
                setReload={setReload}
                setTotalRecords={setTotalRecords}
                reload={reload}
                rowKey={'typeUuid'}
                rowSelection={false}
                filter={filter}
                scroll={{x: 800}}
              />
            }
        </Modal>
    )
}

export default ScenarioUsersTable;
