import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { LectureProps, LectureSessionFilterType, LectureSessionType, MySessionsListData, SessionLectorProps, SessionProps } from '../types';
import { useSession } from 'hooks/Lectures/useSession';
import Spinner from 'components/Spinner';
import SessionStatusBadge from 'components/Lectures/SessionStatusBadge';
import { List } from 'antd';
import { Button, Title } from 'ui';
import moment from 'utils/momentWithLocales';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './styles.scss'

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale,
    }
};

export interface MySessionsListProps {
    type: LectureSessionFilterType,
    lecture?: LectureProps,
    reload?: boolean,
    locale?: string;
    adminView?: boolean;
    userTypeId?: number;
    setAdminSession?: React.Dispatch<React.SetStateAction<number>>;
    setAdminLecture?: React.Dispatch<React.SetStateAction<number>>;
    setAdminView?: React.Dispatch<React.SetStateAction<string>>;
}

const MySessionsList: React.FC<MySessionsListProps> = ({
                                                           locale,
                                                           type,
                                                           lecture,
                                                           reload,
                                                           adminView,
                                                           userTypeId,
                                                           setAdminSession,
                                                           setAdminLecture,
                                                           setAdminView
                                                        }) => {
    const [listData, setListData] = useState<MySessionsListData[]>();
    const { getMySessions, sessionLoading } = useSession();
    const navigate = useNavigate();
    moment.locale(locale ?? 'en');

    useEffect(() => {
        loadMySessions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload, lecture]);

    const loadMySessions = async () => setListData(await getMySessions(type, lecture?.id, adminView, userTypeId));

    const renderButton = (session: SessionProps) => {
        if (!session?.access) {
          return Boolean(session.lecturesId && session.id);
        }
      
        if (type === LectureSessionFilterType.Past) {
          return session.access.includes('1');
        }
      
        return Boolean(session.lecturesId && session.id);
    };

    const ListItem: React.FC<{ session: SessionProps }> = ({ session }) => {
        const date = moment(session.date);

        const renderType = (type?: LectureSessionType) => {
            if (!type) {
                return '';
            }

            switch (type) {
                case LectureSessionType.Online:
                    return <FormattedMessage id='general.lectures.online' />;
                case LectureSessionType.ClassRoom:
                    return <FormattedMessage id='general.lectures.classroom' />
            }
        }

        return (
            <div className="my-session-list-item">
                <div className="my-session-list-item--head">
                    <div>
                        <span>{date.format('ddd')}</span>
                        <span>{date.format('DD')}</span>
                    </div>
                    <div><SessionStatusBadge status={session.status}/></div>
                </div>
                <div className="my-session-list-item--info">
                    <div> 
                        {session.title}
                    </div>
                    <div>
                        <span>{session.start?.slice(0, -3)} - {session.end?.slice(0, -3)}</span>
                        <span>{renderType(session.type)}</span>
                        <span>{session.lectors?.map((lector: SessionLectorProps) => !!lector.name ? lector.name : '---').join(', ')}</span>
                    </div>
                </div>
                <div className="my-session-list-item--status">
                    <SessionStatusBadge status={session.status}/>
                </div>
                <div className="my-session-list-item--cta">
                    {renderButton(session) && <Button block onClick={() => {
                        if (
                            adminView &&
                            setAdminSession &&
                            setAdminLecture &&
                            setAdminView &&
                            session?.id &&
                            session?.lecturesId
                        ) {
                            setAdminLecture(session.lecturesId);
                            setAdminSession(session.id);
                            setAdminView('SESSION');
                        } else {
                            navigate(`/my-lectures/${session.lecturesId}/session/${session.id}`)
                        }
                    }}>
                        <FormattedMessage id='general.view' />
                    </Button>}
                </div>
            </div>
        );
    };

    return (
        <Spinner spinning={sessionLoading}>
            {!!listData && listData.map((data: MySessionsListData) => {
                const date = moment(`${data.name}`, "YYYYMM")
                const title = `${date.format('MMMM').charAt(0).toUpperCase() + date.format('MMMM').slice(1)} ${date.year()}`
                return (
                    <List
                        header={<Title withLine title={title} />}
                        dataSource={data.sessions}
                        renderItem={(item) => <ListItem session={item} />}
                    />
                )
            })}
        </Spinner>
    );
};

export default connect(mapStateToProps)(MySessionsList);
