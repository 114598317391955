import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {NavLink, Navigate  } from "react-router-dom";
import {connect} from 'react-redux';
import {Button, Layout, Form, Row, Col, Card, message, Checkbox} from 'antd';
import {FormProps} from 'antd/lib/form/Form';
import {changeLocale} from 'store/actions/locale';
import Spinner from 'components/Spinner';
import Input from "components/Form/Input";
import Select from "components/Form/Select";
import axios, {AxiosRequestConfig} from "axios";
import apiClient from "utils/apiClient";
import {NoAuthCustomerFormInterface} from './types';
import AppearanceStyler from 'components/NoAuthHelper/AppearanceStyler';
import {config} from 'config/config';
import './styles.scss';

const {Header, Footer, Content} = Layout;

const mapStateToProps = (state: any) => {
    return {
        title: state.appearanceSettings.title,
        locale: state.locale.locale,
        languageDictionary: state.languageDictionary,
        globalTheme: state.publicAppearanceReducer
    }
};

const mapDispatchToProps = (dispatch: any) => ({
    changeLocale: (locale: string) => dispatch(changeLocale(locale))
});

const NoAuthCustomerForm: React.FC<NoAuthCustomerFormInterface> = ({languageDictionary, locale, changeLocale, globalTheme}) => {
    const [submitInProgress, setSubmitInProgress] = useState(false);
    const [countries, setCountries] = useState<string[]>([]);
    const [checkedTerms, setCheckedTerms] = useState(false);
    const [appearanceIsLoading, setAppearanceIsLoading] = useState(!!!globalTheme);

    const [form] = Form.useForm();
    const intl = useIntl();

    useEffect(() => {

        const countries = async () => {
            const requestConfig: AxiosRequestConfig = {
                url: '/v2/all',
                method: 'GET',
                baseURL: 'https://restcountries.com',
                responseType: 'json',
                headers: {}
            };

            const response = await axios.request(requestConfig);

            form.setFieldsValue({
                country: config.api.defaultAppLanguage
            });

            setCountries(response.data);
        };

        countries();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl]);

    const SubmitForm: FormProps['onFinish'] = async (values) => {

        if (!checkedTerms) {
            return message.error(intl.formatMessage({id: "error.tick_terms"}));
        }

        let parsedValues = {
            name: values.name,
            lastName: values.lastName,
            position: values.position,
            email: values.email,
            organizationName: values.organizationName,
            country: values.country,
            terms: checkedTerms
        };

        try {
            setSubmitInProgress(true);
            let response = await apiClient.request('api/v1/customer/create', parsedValues, 'POST');
            setSubmitInProgress(false);

            if (response.success) {
                Navigate({to: '/registration-complete', replace: true});
            }

        } catch (e) {
            console.error(e);
            setSubmitInProgress(false);
            return message.error(intl.formatMessage({id: "error.user_not_created"}));
        }
    };

    const formItemLayout = {
        labelCol: {span: 24},
        wrapperCol: {span: 24},
    };

    const changeLanguage = (language: string) => {
        changeLocale(language.toUpperCase());
    };

    return (
        <Spinner spinning={appearanceIsLoading} opaque>
            <Layout style={{minHeight: '100vh'}}>
                <AppearanceStyler setAppearanceIsLoading={setAppearanceIsLoading}/>
                <Header style={{ backgroundColor: globalTheme && globalTheme.theme.themeBackground ? globalTheme.theme.themeBackground : '#463368' , height: 90}}>
                </Header>
                <Content style={{ backgroundColor: globalTheme && globalTheme.theme.themeBackground ? globalTheme.theme.themeBackground : '#463368' , display:'flex', flexDirection: 'column' }} >
                    <Card className='customer-no-auth'>
                        <Spinner spinning={appearanceIsLoading} size="small">
                        {globalTheme && globalTheme.logo 
                            ? <img className='center-img' src={globalTheme.logo ? URL.createObjectURL(globalTheme.logo) : undefined} alt={globalTheme.title} />
                            : <img className='center-img' src="/cs-logo.svg" alt={'CloudStudy'} />
                        }
                        </Spinner>
                        <div className='register-rc'>
                            <FormattedMessage id='general.register'/>
                        </div>
                        <Form layout="vertical" form={form} onFinish={SubmitForm}>
                            <Row>
                                <Col span={11}>
                                    <Input
                                        name='name'
                                        label={intl.formatMessage({id: 'general.name'})}
                                        validation={{required: true}}
                                        customLayout={formItemLayout}
                                    />
                                </Col>
                                <Col span={2}>
                                </Col>
                                <Col span={11}>
                                    <Input
                                        name='lastName'
                                        label={intl.formatMessage({id: 'general.last_name'})}
                                        validation={{required: true}}
                                        customLayout={formItemLayout}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={11}>
                                    <Input
                                        name='position'
                                        label={intl.formatMessage({id: 'general.position'})}
                                        validation={{required: true}}
                                        customLayout={formItemLayout}
                                    />
                                </Col>
                                <Col span={2}>
                                </Col>
                                <Col span={11}>
                                    <Input
                                        name="email"
                                        customLayout={formItemLayout}
                                        label={intl.formatMessage({id: 'general.email'})}
                                        validation={{required: true}}
                                        customRules={[
                                            {
                                                type: 'email',
                                                message: intl.formatMessage({id: "validation.email_required"})
                                            },
                                            {
                                                required: true,
                                                message: intl.formatMessage({id: "validation.field_required"})
                                            }
                                        ]}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={11}>
                                    <Input
                                        name='organizationName'
                                        label={intl.formatMessage({id: 'system.organization_name'})}
                                        validation={{required: true}}
                                        customLayout={formItemLayout}
                                    />
                                </Col>
                                <Col span={2}>
                                </Col>
                                <Col span={11}>
                                    <Select
                                        showSearch
                                        integerKey={true}
                                        isResponseArray={true}
                                        name='country'
                                        label={intl.formatMessage({id: 'general.country'})}
                                        validation={{required: true}}
                                        manualOptions={countries}

                                        valueKey='alpha2Code'
                                        labelKey='name'
                                        customLayout={formItemLayout}
                                        filterOption={(input: string, option: { children: string, key: string, value: string }) => {
                                            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Form.Item name='terms'>
                                <Checkbox className='checkbox-group-rc' onChange={() => setCheckedTerms(!checkedTerms)}
                                          style={{borderRadius: '4px', lineHeight: '32px'}}><FormattedMessage
                                    id='general.accept_terms'/></Checkbox>
                                <span className='terms-conditions-rc'
                                      onClick={() => window.open('terms-and-conditions')} style={{color: '#000000'}}>
                                            <FormattedMessage id="general.form_terms_conditions"/>
                                        </span>
                            </Form.Item>

                        </Form>
                        <Button
                            type="primary"
                            className='form-button-rc'
                            onClick={() => form.submit()}
                            loading={submitInProgress}
                        >
                            <FormattedMessage id='general.register_user'/>
                        </Button>
                    </Card>
                    <div className='language-switch-rc'>
                        {
                            Object.entries(languageDictionary).map((entries: [string, any]) => {
                                if (entries[0] !== 'loading' && entries[0] !== 'failed') {
                                    return (
                                        <Button className={"locale-switch" + (locale === entries[0] ? " active" : "")}
                                                onClick={() => changeLanguage(entries[0])}>{entries[0]}</Button>
                                    )
                                }
                                return null;
                            })
                        }
                    </div>
                </Content>
                <Footer style={{ backgroundColor: globalTheme && globalTheme.theme.themeBackground ? globalTheme.theme.themeBackground : '#463368' , textAlign: 'center'}}>
                    <ul className="footer-list">
                        <li>© CloudStudy Ltd</li>
                        <li>
                            <NavLink to='terms-and-conditions' style={{color: '#FFFFFF'}}>
                                <FormattedMessage id="login.terms_and_conditions"/>
                            </NavLink>
                        </li>
                        <li><FormattedMessage id="login.cookies"/></li>
                    </ul>
                </Footer>
            </Layout>
        </Spinner>
    )
};


export default connect(mapStateToProps, mapDispatchToProps)(NoAuthCustomerForm);

