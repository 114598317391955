import moment from "moment/moment";
import {config} from "../config/config";

const onlyUnique = (value: any, index: any, self: any) => {
    return self.indexOf(value) === index;
}


const pluck = (arr: any, key: string) => arr.map((i: any) => i[key]);


const getFileType = (fileName: string) => {
    const fileNameArray = fileName.split('.');

    return fileNameArray[fileNameArray.length - 1].toUpperCase();
}

const getFileSize = (size: number) => {
    var units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    var i = 0;
    while (size >= 1024) {
        size /= 1024;
        ++i;
    }
    return size.toFixed(1) + ' ' + units[i];
};

const isEmpty = (obj: any) => Object.keys(obj).length === 0;

const isAdmin = (userRole: string) => {
    if (userRole === 'SUPER_ADMIN' || userRole === 'DISTRIBUTOR_ADMIN' || userRole === 'RESELLER_ADMIN') {
        return true;
    }
    return false;
}

const isPlatformAdmin = (userRole: string) => {
    if (userRole === 'SUPER_ADMIN' || userRole === 'DISTRIBUTOR_ADMIN') {
        return true;
    }
    return false;
}

const isCustomerAdmin = (userRole: string) => {
    if (userRole === 'CUSTOMER_ADMIN') {
        return true;
    }
    return false;
}

const isStudent = (userRole: string) => {
    if (userRole === 'STUDENT') {
        return true;
    }
    return false;
}


const stringifyArray = (numberArray: number[]) => {
    return numberArray.map((num: number) => {
        return String(num);
    });
}

const formUrl = (url: string, variables: any) => {
    let formedUrl = url

    Object.entries(variables).map((variable: any) => {
        return formedUrl = formedUrl.replace(`{${variable[0]}}`, variable[1])
    })

    return formedUrl;
}
const hasPermissions = (session: any, permission: string) => {
    return session.permissions.includes(permission);
}

const hasSpecialPermissions = (session: any, permission: string) => {
    return session.special_rights.includes(permission);
}

const urlBuilder = (requestUrl: string, value: any, key: string) => {
    if ((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value)) {
        if (requestUrl.includes('?')) {
            requestUrl += `&${key}=` + value.toString();
        } else {
            requestUrl += `?${key}=` + value.toString();
        }
    }

    return requestUrl;
}

const toHoursAndMinutes = (minutes: number) => {
    let hours = Math.floor(minutes / 60);
    let mins = minutes % 60;

    let formattedHours = hours < 10 ? "0" + hours : hours;
    let formattedMins = mins < 10 ? "0" + mins : mins;

    return formattedHours + ":" + formattedMins;
}

const firstLower = (string: string) => {
    return string.replace(/(?:^|\s)\S/g, function (a: string) {

        return a.toLowerCase();
    });

}

const camelize = (str: string) => {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '').replace('_', '');
}


const replaceCharacters = (str: string) => {
    let title;

    const characterMap = {
        ē: 'e',
        ū: 'u',
        ī: 'i',
        ā: 'a',
        š: 's',
        ķ: 'k',
        ģ: 'g',
        ļ: 'l',
        ž: 'z',
        č: 'c',
        ņ: 'n',
    };

    function replace(title: string) {
        let output = title.toLowerCase();
        for (const [char, replacement] of Object.entries(characterMap)) {
            const regex = new RegExp(char, 'g'); // Create a regular expression to match all occurrences
            output = output.replace(regex, replacement);
        }
        return output;
    }

    title = replace(str);

    title = title.replace(/ /g, '_');

    return title
};


const filterParser = (values: any) => {
    let filterQuery = '';

    Object.entries(values).map((el: any) => {
        if (el[0].toLowerCase().includes('count') && el[1]) {
            filterQuery += `&filter[${el[0]}]=${el[1]}`
        } else if (el[0].toLowerCase().includes('bool')) {
            filterQuery += `&filter[${el[0]}]=${el[1] ? 1 : 0}`
        } else if (el[0].toLowerCase().includes('date') && el[1] && el[1].length) {
            filterQuery += `&filter[${el[0]}]=${moment(el[1][0]).format(config.defaultDateFormat)},${moment(el[1][1]).format(config.defaultDateFormat)}`
        } else if (el[1] && el[1].length) {
            filterQuery += `&filter[${el[0]}]=${el[1].toString()}`
        }
    })

    return filterQuery;
}


const secondsToHHmm = (seconds: string | number): string => {
    if (!seconds) {
        return '0 h 0 min'
    }

    seconds = (typeof seconds === 'string') ? parseInt(seconds) : seconds
        const hours = Math.floor(seconds / 3600);
        const remainingSeconds = seconds % 3600;
        const minutes = Math.floor(remainingSeconds / 60);

        return `${hours ? hours + 'h' : ''} ${minutes}min`;
    }

const convertSecondsToHHMMSS = (totalSeconds: number) =>  {
    // Calculate hours, minutes, and seconds
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    // Pad the values to ensure two digits
    const paddedHours = String(hours).padStart(2, '0');
    const paddedMinutes = String(minutes).padStart(2, '0');
    const paddedSeconds = String(seconds).padStart(2, '0');

    // Format the result as HH:mm:ss
    return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
}

const replaceAll = (str: string, find: string, replace: string) => {
    return str.replace(new RegExp(find, 'g'), replace);
}

const arrayUnique = (array: any[]) => {
    return array.filter((item: any, index) => array.indexOf(item) === index);
}

const getLectureTimeSpent = (lecture: any) => {
    const array = lecture.date.split(' ')

    const startTimeString = array[1];
    const endTimeString = array[3];

    const startTimeParts = startTimeString.split(':');
    const endTimeParts = endTimeString.split(':');

    const startHours = parseInt(startTimeParts[0], 10);
    const startMinutes = parseInt(startTimeParts[1], 10);
    const endHours = parseInt(endTimeParts[0], 10);
    const endMinutes = parseInt(endTimeParts[1], 10);

    const totalStartMinutes = startHours * 60 + startMinutes;
    const totalEndMinutes = endHours * 60 + endMinutes;
    const timeDifferenceMinutes = totalEndMinutes - totalStartMinutes;

    return timeDifferenceMinutes;
}

const exportedObject = {
    getLectureTimeSpent,
    onlyUnique,
    pluck,
    replaceCharacters,
    replaceAll,
    getFileType,
    getFileSize,
    isEmpty,
    isAdmin,
    stringifyArray,
    isPlatformAdmin,
    formUrl,
    isCustomerAdmin,
    hasPermissions,
    hasSpecialPermissions,
    isStudent,
    urlBuilder,
    toHoursAndMinutes,
    firstLower,
    camelize,
    filterParser,
    secondsToHHmm,
    convertSecondsToHHMMSS,
    arrayUnique
};

export default exportedObject;