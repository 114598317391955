import React, {useState, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {NavLink, useNavigate, useParams} from 'react-router-dom';
import {config} from 'config/config';
import useQueryApiClient from "utils/useApiClient";
import Spinner from 'components/Spinner';

import DefaultLayout from "components/DefaultLayout";
// import './styles.scss';

import useHandleError from "utils/useHandleError";
import {Table} from "ui";
import moment from "moment/moment";
import {message, Modal, Popover, Form, Button} from "antd";
import { PlusOutlined } from '@ant-design/icons';
import coursesApiClient from 'utils/coursesApiClient';
import FlexRow from "../../../components/FlexRow";
import { Input, Select } from '../../../components/Form';
const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale
    };
};
const formItemLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
};

const QuestionPoolList: React.FC<any> = ({ locale }) => {
    const [reload, setReload] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [visibleTooltip, setVisibleTooltip] = useState<number | null>(null);
    const [showAddNewQuestionPool, setShowAddNewQuestionPool] = useState(false);
    const [isSubmitProgress, setIsSubmitProgress] = useState(false);

    const intl = useIntl();
    const params = useParams();
    const [handleError] = useHandleError();
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const handleVisibleChange = (visible: boolean, recordId: number) => {
        if (visible) {
            setVisibleTooltip(recordId);
        } else {
            setVisibleTooltip(null);
        }
    };

    const submitForm = async (values: any) => {
      setIsSubmitProgress(true);

      let parsedValues = {
        ...values,
      }

      try {
        let responese = await coursesApiClient.request('/api/v1/courses/question-pools/store', parsedValues, 'POST');
        navigate(`/question-pools/${responese.question_pool.id}/edit`);
      } catch (error) {
        handleError(error)
      } finally {
        setIsSubmitProgress(false);
      }
    }

    const {appendData: handleDelete, isLoading: isDeleting} = useQueryApiClient({
      request: {
          disableOnMount: true,
          url: '/api/v1/courses/question-pools/:id/delete',
          method: 'DELETE',
      },
      onSuccess: (): void => {
          setReload(true);
          setVisibleTooltip(null);
      },
    });

    const columns = [
      {
        title: intl.formatMessage({id: 'question_pools.question_pool_title'}),
        dataIndex: 'name',
        key: 'name',
        render: (text: string, record: any) => {
          return (
              <NavLink
                to={
                    `/question-pools/${record.id}/edit`
                }
              >
                {record.title}
              </NavLink>
            )
        }
      },
      {
        title: intl.formatMessage({id: 'question_pools.question_banks'}),
        dataIndex: 'questionBanksCount ',
        key: 'questionBanksCount ',
        render: (text: string, record: any) => record.questionBanksCount
      },
      {
        key: 'actions',
        render: (text: string, record: any, index: number) => {
          const content = <>
            <NavLink
              to={
                `/question-pools/${record.id}/edit`
              }
            >
              <div className="popover-item">
                <FormattedMessage id="general.edit"/>
              </div>
            </NavLink>
            <div onClick={()=> handleDelete({}, {id: record.id})} className="popover-item">
            <Spinner spinning={isDeleting}>
                <FormattedMessage id="general.delete"/>
            </Spinner>
            </div>
          </>;

          return (
            <Popover
              open={index === visibleTooltip}
              content={content}
              trigger="click"
              placement="bottomRight"
              arrowPointAtCenter
              onOpenChange={(visible) => handleVisibleChange(visible, index)}
            >
              <div style={{width: '100%', cursor: 'pointer', textAlign: 'center'}}>
                <i className="fal fa-ellipsis-v" style={{fontSize: '16px'}} />
              </div>
            </Popover>
          )
        },
      }
    ];

    return (
        <DefaultLayout.PageLayout>
            <DefaultLayout.PageHeader 
              title={intl.formatMessage({id: 'question_pools.question_pools'})}
            />
            <DefaultLayout.PageContent>
                <FlexRow
                    left={
                      <Button type="primary" icon={<PlusOutlined/>} onClick={() => setShowAddNewQuestionPool(true)}>
                        <FormattedMessage id="question_pools.add_new_question_pool" />
                      </Button>
                    }
                />

                <Modal
                  width={530}
                  className="add-new-question-pool"
                  open={showAddNewQuestionPool}
                  onCancel={() => setShowAddNewQuestionPool(false)}
                  title={intl.formatMessage({ id: 'question_pools.add_new_question_pool' })}
                  okText={intl.formatMessage({ id: 'general.submit' })}
                  cancelText={intl.formatMessage({ id: 'general.back' })}
                  onOk={form.submit}
                  okButtonProps={{disabled: isSubmitProgress}}
                >
                  <Form form={form} onFinish={submitForm}>
                    <Input customLayout={formItemLayout} name="title" label={intl.formatMessage({ id: 'question_pools.question_pool_title' })} validation={{ required: true }}/>
                  </Form>
                </Modal>
                <Table
                    columns={columns}
                    url={'/api/v1/courses/question-pools'}
                    setReload={setReload}
                    reload={reload}
                    rowSelection={false}
                    rowKey={'id'}
                    setTotalRecords={setTotalRecords}
                    search={searchString}
                />
            </DefaultLayout.PageContent>

        </DefaultLayout.PageLayout>
    );
};

export default connect(mapStateToProps)(QuestionPoolList);
