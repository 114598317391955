import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {HorizontalStackedBarChart} from 'components/Charts/HorizontalStackedBarChart';
import {useProgramOverview} from "hooks/Program/useProgramOverview";

const OrganizationalUnitChart: React.FC<any> = ({programId}) => {
    const {organizationalUnitChart, isLoading} = useProgramOverview();
    const [data, setData] = useState({});

    const intl = useIntl();

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        setData(await organizationalUnitChart(programId));
    }

    return (
        <HorizontalStackedBarChart
            data={data}
            title={intl.formatMessage({id: 'campaign.learning_progress_by_org_unit'})}
            loading={isLoading}
        />
    );
}

export default OrganizationalUnitChart;