import React from 'react';
import SuccessPieChart from "./SuccessPieChart";
import CompletionOverTimeChart from "./CompletionOverTimeChart";
import TestUsage from "./TestUsage";
import {Row, Col, Card} from "antd";

interface ReviewInterface {
}

const Review: React.FC<ReviewInterface> = ({}) => {
    return (
    <Row gutter={[16, 16]}>
        <Col span={12}>
            <Card size="small" className="chart-card">
                <SuccessPieChart/>
            </Card>
            <Card size="small" className="chart-card adaptive">
                <TestUsage/>
            </Card>
        </Col>
        <Col span={12}>
            <Card size="small" className="chart-card">
                <CompletionOverTimeChart/>
            </Card>
        </Col>
    </Row>
    )
};

export default Review;
