import React, {useState, useEffect} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { message, Modal, Form, Row, Col, Button } from 'antd';
import apiClient from 'utils/apiClient';
import { Input } from 'components/Form';
import { Editor } from '@tinymce/tinymce-react';
import htmlTemplateParser from 'utils/htmlTemplateParser';
import {config} from 'config/config';
import { connect } from 'react-redux';
import './styles.scss';

const formItemLayout = {
  wrapperCol: { span: 8 },
};


const mapStateToProps = (state: any) => {
  return {
    session: state.session
  }
};
  const VARIABLE_NODE_CLASS_NAME = 'email_template.variable';

const EditHtmlTemplateModal: React.FC<any> = ({visible, onCancel, templateHtml, language, templateType, onSubmit, template, templateSubject, session}) => {
    const [richTextEditor, setRichTextEditor] = useState<any>();
    const [variables, setVariables] = useState([]);

    const intl = useIntl();
    const [form] = Form.useForm();

    useEffect(() => {
      let templateHtmlParsed:any;

      if (templateHtml) {
        templateHtmlParsed =  templateHtml;
        let allUsedVariables = templateHtmlParsed.match(/{{\w*}}/g) || [];
        allUsedVariables.forEach((el: any) => {
          el = el.replace('{{', '');
          el = el.replace('}}', '');
          switch(el) {
            case ('managing_organization_support_email') :
              templateHtmlParsed = templateHtmlParsed.replace(
                /{{[^}]*}}/,
                `<span data-variable="${el}" class="${VARIABLE_NODE_CLASS_NAME}">{_{${intl.formatMessage({id: "email_template.variable.managing_organization_support_email"}, {reseller: intl.formatMessage({id: 'emailing.reseller'})})}}_}</span>`
              );
              break;
            case ('managing_organization_support_phone') :
              templateHtmlParsed = templateHtmlParsed.replace(
                /{{[^}]*}}/,
                `<span data-variable="${el}" class="${VARIABLE_NODE_CLASS_NAME}">{_{${intl.formatMessage({id: "email_template.variable.managing_organization_support_phone"}, {reseller: intl.formatMessage({id: 'emailing.reseller'})})}}_}</span>`
              );
              break;
            case ('distributor_support_phone') :
              templateHtmlParsed = templateHtmlParsed.replace(
                /{{[^}]*}}/,
                `<span data-variable="${el}" class="${VARIABLE_NODE_CLASS_NAME}">{_{${intl.formatMessage({id: "email_template.variable.distributor_support_phone"}, {distributor: intl.formatMessage({id: 'emailing.distributor'})})}}_}</span>`
              );
              break;
            default :
              templateHtmlParsed = templateHtmlParsed.replace(
                /{{[^}]*}}/,
                `<span data-variable="${el}" class="${VARIABLE_NODE_CLASS_NAME}">{_{${intl.formatMessage({id: "email_template.variable." + el})}}_}</span>`
              );
              break;
          }
        });
        templateHtmlParsed = templateHtmlParsed.replace(/{_{/g, '{{');
        templateHtmlParsed = templateHtmlParsed.replace(/}_}/g, '}}');
      }

      form.setFieldsValue({
        htmlTemplate: templateHtmlParsed,
        subject: templateSubject
      });

      const loadVariables = async() => {
        if (session.active_user_type === 'SUPER_ADMIN' && template) {
          let allVariables = await apiClient.request(config.api.routes.backend.mailVariables, {}, 'GET');
          let mailable = template.mailable;
          mailable = mailable.split('\\');
          if (!allVariables.standard[mailable[mailable.length - 1]]) {
            let variablesResponse = await apiClient.request('/api/v1/mail-templates/custom/UserDefinedTemplateMail/variables', {}, 'GET');
            setVariables(variablesResponse)
          } else {
            setVariables(allVariables.standard[mailable[mailable.length - 1]]);
          }
        } else {
          let variablesResponse = await apiClient.request('/api/v1/mail-templates/custom/UserDefinedTemplateMail/variables', {}, 'GET');
          setVariables(variablesResponse)
        }
      };

      loadVariables();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[language, template]);

    useEffect(()=> {

    },[template]);

  const submitForm = async (values:any) => {
    let submitResponse;
    let htmlTemplateForSubmit;
    let html;
    let subject = template.subject;


    if (template.html_template) {
      html = template.html_template;
    } else {
      html = JSON.parse(template.html);
    }

    if (typeof values.htmlTemplate === 'string') {
      htmlTemplateForSubmit =  values.htmlTemplate ;
    } else if (typeof values.htmlTemplate === 'object') {
      htmlTemplateForSubmit = values.htmlTemplate.level.content;
    } else if (values.htmlTemplate === undefined) {
      htmlTemplateForSubmit ='';
    }

    html[language] = htmlTemplateParser.htmlTemplateParse(htmlTemplateForSubmit);
    subject[language] = values.subject;

    let parsedValues: any = {
      name: template.name,
      subject: template.subject,
      templateType: template.template_type,
      htmlTemplate: html,
      categoryId: template.category_id,
      from: template.from,
      language: template.language,
    };

    try {
        switch (templateType) {
          case 'STANDARD':
            let strippedMailable = (template.mailable).replace('App\\Mail\\Standard\\', '');
            parsedValues = {
              ...parsedValues,
              mailable: strippedMailable
            };
            submitResponse = await apiClient.request(`/api/v1/mail-templates/${template.id}`, parsedValues, 'PUT');
            break;
          case 'ORGANIZATION':
            if (template.organization_id) {
              parsedValues = {
                ...parsedValues,
                organizationId : template.organization_id
              }
            }

            if (template.default_template_id) {
              parsedValues = {
                ...parsedValues,
                defaultTemplateId : template.default_template_id
              }
            }

            submitResponse = await apiClient.request(`/api/v1/organization-mail-templates/${template.id}`, parsedValues, 'PUT');
            break;
          case 'CUSTOMER':
            parsedValues = {
              ...parsedValues,
              customerId: template.customer_id
            };
            if (template.organization_template_id) {
              parsedValues = {
                ...parsedValues,
                organizationTemplateId : template.organization_template_id
              }
            }

            submitResponse = await apiClient.request(`/api/v1/customer-mail-templates/${template.id}`, parsedValues, 'PUT');
            break;
        }
    } catch (error) {
      message.error(intl.formatMessage({id: "general.submit_error"}));
    }
    if (submitResponse) {
      message.success(intl.formatMessage({id: "general.submit_success"}));
    }
    form.setFieldsValue([]);
  };

  return (
    <Modal
      className="mail-template-editor-modal"
      title={intl.formatMessage({id: 'emailing.edit_mail_template'})}
      open={visible}
      onOk={() => {form.submit();
        if(onSubmit){
          onSubmit();
        }
      }}
      onCancel={()=> {form.setFieldsValue([]);onCancel()}}
      okText={intl.formatMessage({id: 'general.submit'})}
      cancelText={intl.formatMessage({id: 'general.cancel'})}
      width={'75%'}
    >
    <Form form={form} onFinish={submitForm}>

    <Input className='html-template-edit-input' name="subject" label={intl.formatMessage({id: "emailing.templates.subject"})} validation={{required: true}} />
      <Row>
        <Col span={16}>
        <Form.Item name='htmlTemplate' className='template-editor-modal' >
        <Editor
          apiKey={config.api.tinymceApiKey}
          init={{
            height: 500,
            menubar: false,
            relative_urls: false,
            remove_script_host: false,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | code | help'
          }}
          onInit={(event, editor) => setRichTextEditor(editor) }
          onKeyUp={(event, editor) => {
            if (event.keyCode === 46) {
              let currentNode = editor.selection.getNode();
              if (currentNode.className === VARIABLE_NODE_CLASS_NAME) {
                currentNode.remove();
              }
            }
            if (event.keyCode === 13) {
              richTextEditor.insertContent('<br>')
            }
          }}
        />
      </Form.Item>
        </Col>
        <Col span={8}>
        <Form.Item
        {...formItemLayout}
        className='template-editor-variables'
      >
        {variables && variables.length > 0
            ? variables.map((variable: string) => {
              return (
                <Button
                  key={variable}
                  className='outlined-button'
                  size="small"
                  style={{marginRight: 8, marginBottom: 4}}
                  onClick={() => {richTextEditor.insertContent(`<span data-variable="${variable}" class="${VARIABLE_NODE_CLASS_NAME}">{{${intl.formatMessage({id: "email_template.variable." + variable})}}}</span> `)}}
                >
                  <FormattedMessage id={"email_template.variable." + variable}/>
                </Button>
              )
            })
            : <p style={{color: 'red', marginBottom: 0}}><FormattedMessage id={"emailing.templates.choose_mailable"}/></p>
        }
      </Form.Item>
        </Col>
      </Row>

  </Form>
    </Modal>
  )
};

export default connect(mapStateToProps)(EditHtmlTemplateModal);
