import { combineReducers } from 'redux';

import campaignActivityList from 'store/reducers/campaignActivityList';
import campaignCustomerFilter from 'store/reducers/campaignCustomerFilter';
import campaignData from 'store/reducers/campaignData';
import customerUserFilter from 'store/reducers/customerUserFilter';
import environment from 'store/reducers/environment';
import filterdOrganizationOptions from 'store/reducers/filterdOrganizationOptions';
import languageDictionary from 'store/reducers/languageDictionary';
import licenceFilter from 'store/reducers/licenceFilter';
import locale from 'store/reducers/locale';
import mailTemplateCustomerOptions from 'store/reducers/mailTemplateCustomerOptions';
import mailTemplateFilterOptions from 'store/reducers/mailTemplateFilterOptions';
import mailTemplateOrganizationOptions from 'store/reducers/mailTemplateOrganizationOptions';
import resetPasword from 'store/reducers/resetPasword';
import scormData from 'store/reducers/scormData';
import phishingCampaignView from 'store/reducers/phishingCampaignView';
import session from 'store/reducers/session';
import sidebarCollapsed from 'store/reducers/sidebarCollapsed';
import userTableFilter from 'store/reducers/userTableFilter';
import phishingSimulationTemplateView from 'store/reducers/phishingSimulationTemplateView';
import appearanceSettingsReducer from 'store/reducers/appearanceSettingsReducer';
import phishingCampaignReducer from 'store/reducers/phishingCampaignReducer';
import publicAppearanceReducer from 'store/reducers/publicAppearanceReducer';
import studentViewDataReducer from 'store/reducers/studentViewDataReducer';
import customCampaignActivityReducer from 'store/reducers/customCampaignActivityReducer';
import activityCreationModalActions from "./reducers/activityCreationModalActions";
import learningTestAttemptData from "./reducers/learningTestAttemptData";
export default combineReducers({
  campaignActivityList,
  campaignCustomerFilter,
  campaignData,
  customerUserFilter,
  environment,
  filterdOrganizationOptions,
  languageDictionary,
  licenceFilter,
  locale,
  mailTemplateCustomerOptions,
  mailTemplateFilterOptions,
  mailTemplateOrganizationOptions,
  resetPasword,
  scormData,
  phishingCampaignView,
  session,
  sidebarCollapsed,
  userTableFilter,
  phishingSimulationTemplateView,
  appearanceSettings: appearanceSettingsReducer,
  phishingCampaignReducer,
  publicAppearanceReducer,
  studentViewDataReducer,
  customCampaignActivityReducer,
  activityCreationModalActions,
  learningTestAttemptData
});
