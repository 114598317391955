import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { Tabs } from 'antd';
import DefaultLayout from 'components/DefaultLayout';
import StandardTemplates from 'pages/Emailing/StandardTemplates';
import OrganizationTemplates from 'pages/Emailing/OrganizationTemplates';
import CustomerTemplates from 'pages/Emailing/CustomerTemplates';
import { clearCampaignData } from 'store/actions/campaignData';
import EditHtmlTemplateModal from 'components/EditHtmlTemplateModal';


const mapStateToProps = (state: any) => {
  return {
    session: state.session,
    campaignData: state.campaignData
  };
}

const mapDispatchToProps = (dispatch: any) => ({
  clearCampaignData: () => dispatch(clearCampaignData())
});

const EmailTemplateList: React.FC<any> = ({props, session, clearCampaignData, campaignData}:any) => {
  const [displayStandardTab, setDisplayStandardTab ] = useState(false);
  const [displayOrganizationTab, setDisplayOrganizationTab ] = useState(false);
  const [displayCustomerTab, setDisplayCustomerTab ] = useState(false);
  const [organizationType, setOrganizationType] = useState('');
  const [showEditModal, setShowEditModal] = useState(false);
  const [languageEditRecord, setLanguageEditRecord] = useState<any>();
  const [selectedLanguageKey, setSelectedLanguageKey] = useState();
  const [selecteTemplate, setSelecteTemplate] = useState();
  const [selectedTemplatesType, setSelectedTemplatesType] = useState('');
  const [templateSubject, setTemplateSubject] = useState();

  const intl = useIntl();

  useEffect(() => {
    const loadTabs = () => {
      if (!Object.keys(campaignData).length) {
        clearCampaignData();
      }

      switch (session.active_user_type) {
        case 'SUPER_ADMIN':
          setDisplayCustomerTab(true);
          setDisplayOrganizationTab(true);
          setDisplayStandardTab(true);
          setOrganizationType('organization');
          setSelectedTemplatesType('STANDARD');
          break;
        case 'CUSTOMER_ADMIN':
          setDisplayStandardTab(false)
          setDisplayOrganizationTab(false)
          setDisplayCustomerTab(true)
          setSelectedTemplatesType('CUSTOMER');
        break;
        case 'DISTRIBUTOR_ADMIN':
          setDisplayCustomerTab(true);
          setDisplayOrganizationTab(true);
          setOrganizationType('distributor');
          setDisplayStandardTab(false)
          setSelectedTemplatesType('ORGANIZATION');
        break;          
        case 'RESELLER_ADMIN':
          setOrganizationType('reseller');
          setDisplayStandardTab(false)
          setDisplayOrganizationTab(true)
          setDisplayCustomerTab(true)
          setSelectedTemplatesType('ORGANIZATION');
        break;      
      }
    }

    loadTabs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl]);

  return (
    <>
    <DefaultLayout.PageLayout>
      <DefaultLayout.PageHeader title={intl.formatMessage({ id: 'emailing.templates.manage_templates' })} />
      <Tabs
        type="card"
        defaultActiveKey={session.active_user_type === 'SUPER_ADMIN' ? 'standard_templates': session.active_user_type === 'DISTRIBUTOR_ADMIN' ? 'reseller_templates' : 'customer_templates'}
        tabBarStyle={{ marginBottom: 0, marginLeft: -1 }}
      >
        {displayStandardTab
          ? <Tabs.TabPane tab={intl.formatMessage({ id: 'emailing.template.standard_templates' })} key="standard_templates">
              <StandardTemplates
                setLanguageEditRecord={setLanguageEditRecord}
                setSelecteTemplate={setSelecteTemplate}
                setSelectedLanguageKey={setSelectedLanguageKey}
                setShowEditModal={setShowEditModal}
                setTemplateSubject={setTemplateSubject}
              />
            </Tabs.TabPane>
          : null
        }
        {
        displayOrganizationTab ?
            <Tabs.TabPane tab={intl.formatMessage({id: `emailing.template.${organizationType}_templates`})} key="reseller_templates" >
              <OrganizationTemplates 
                setLanguageEditRecord={setLanguageEditRecord}
                setSelecteTemplate={setSelecteTemplate}
                setSelectedLanguageKey={setSelectedLanguageKey}
                setShowEditModal={setShowEditModal}
                setTemplateSubject={setTemplateSubject}
            />
            </Tabs.TabPane>
            : null
        }
        {
          displayCustomerTab ? 
          <Tabs.TabPane tab={intl.formatMessage({id: 'emailing.template.customer_templates'})} key="customer_templates" >
            <CustomerTemplates 
              setLanguageEditRecord={setLanguageEditRecord}
              setSelecteTemplate={setSelecteTemplate}
              setSelectedLanguageKey={setSelectedLanguageKey}
              setShowEditModal={setShowEditModal}
              setTemplateSubject={setTemplateSubject}
            />
          </Tabs.TabPane>
          : null
        }
      </Tabs>
    </DefaultLayout.PageLayout>
    <EditHtmlTemplateModal
        key={'html-modal'}
        visible={showEditModal}
        onCancel={() => setShowEditModal(false)}
        language={selectedLanguageKey}
        templateType={selectedTemplatesType}
        onSubmit={() => setShowEditModal(false)}
        templateHtml={languageEditRecord}
        templateSubject={templateSubject}
        template={selecteTemplate}
      />
    </>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplateList);
