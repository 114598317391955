import React, {useRef, useEffect, useState} from "react";
import Plyr, {APITypes, PlyrInstance} from "plyr-react";
import {Button, Modal} from 'antd';
import {FormattedMessage, useIntl} from 'react-intl';
import "plyr-react/plyr.css";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {config} from "../../config/config";

const PlyrComponent = ({video, endVideoSession, endLoading, finish}: any) => {
    const ref = useRef<APITypes>(null);
    const intl = useIntl();
    const {confirm} = Modal;
    const [key, setKey] = useState(0)
    const [display, setDisplay] = useState(false)

    useEffect(() => {
        document.addEventListener("visibilitychange", function () {
            if (video.settings.pause_on_blur) {
                if (document.visibilityState === 'hidden') {
                    (ref.current?.plyr as Plyr)?.pause();
                } else {
                    (ref.current?.plyr as Plyr)?.play();
                }
            }
        });
    })

    const rewind = (sec: number) => {
        (ref.current?.plyr as Plyr)?.rewind(sec);
    };

    const forward = (sec: number) => {
        (ref.current?.plyr as Plyr)?.forward(sec);
    };

    const pause = () => {
        (ref.current?.plyr as Plyr)?.togglePlay();
    };

    const pauseVideo = () => {
        (ref.current?.plyr as Plyr)?.pause();
    };

    const plyrVideo =
        video.videoId && video.videoProvider ? (
            <Plyr
                ref={ref}
                source={{
                    type: "video",
                    sources: [
                        {
                            src: video.videoId,
                            provider: video.videoProvider
                        }
                    ]
                }}
                options={
                    {
                        disableContextMenu: true,
                        controls: video.settings.video_progress ? undefined : ['play-large', 'play', 'progress', 'mute', 'volume', 'fullscreen'],
                        autoplay: true,

                    }
                }
            />
        ) : null;

    const handleFinish = () => {
        // url check not needed for now
        // const sourceUrl = (ref.current?.plyr as Plyr).source + '';
        // if (sourceUrl.includes(video.videoId)) {
        // }

        if (video.settings.view_all_to_complete) {
            if (Math.floor((ref.current?.plyr as Plyr)?.currentTime) >= Math.floor((ref.current?.plyr as Plyr)?.duration) - 3) {
                endVideoSession()
            } else {
                pauseVideo()

                confirm({
                    title: intl.formatMessage({id: 'general.attention'}),
                    content: intl.formatMessage({id: 'student.video_warning'}),
                    icon: <ExclamationCircleOutlined/>,
                    okText: intl.formatMessage({id: 'general.yes'}),
                    cancelText: intl.formatMessage({id: 'general.cancel'}),
                    onOk() {
                        endVideoSession(false)
                    },
                    onCancel() {
                        pause()
                    }
                });
            }
        } else {
            endVideoSession()
        }
    }

    return (
        <div key={key}>
            <Button className={'rerender-button '+ (display ? 'hidden': '')} onClick={() => {setKey(key+1); setDisplay(true)}}>
                <FormattedMessage id='general.reload'/>
            </Button>
            {plyrVideo}
            <div className='buttons-container'>
                <div className='button-wrapper'>
                    <Button onClick={() => rewind(5)}>
                        <i className="fa-solid fa-backward"></i>
                        <FormattedMessage id='courses.rewind_video'/>
                    </Button>
                    <Button onClick={() => pause()}>
                        <i className="fa-solid fa-play-pause"></i>
                    </Button>
                    {config.api.environment === 'development' &&
                        <Button onClick={() => forward(5)}>
                            <i className="fa-solid fa-forward"></i>
                        </Button>
                    }
                </div>
                <Button loading={endLoading} onClick={() => handleFinish()}>
                    <FormattedMessage id='courses.finish'/>
                </Button>
            </div>
        </div>
    );
};

export default PlyrComponent;
