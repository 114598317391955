import React, {useState} from 'react';
import {connect} from 'react-redux';
import {useIntl, FormattedMessage} from 'react-intl';
import {Button, Statistic } from 'antd';
import coursesApiClient from 'utils/coursesApiClient';
import Spinner from 'components/Spinner';
import useHandleError from "utils/useHandleError";
import {config} from "config/config";
import './styles.scss';

const mapStateToProps = (state: any) => ({session: state.session});

interface EmbeddedDocMaterialPlayerProps {
    activityAttemptId?: number;
    isPreview?: boolean
    language?: string;
    version?: string;
    session?: any
}

const EmbeddedDocMaterialPlayer: React.FC<EmbeddedDocMaterialPlayerProps> = ({
                                                                                 activityAttemptId,
                                                                                 isPreview = false,
                                                                                 language,
                                                                                 version,
                                                                                 session
                                                                             }) => {
    const [source, setSource] = useState<string | null>(null);
    const [beginActivity, setBeginActivity] = useState(false);
    const [isMaterialLoading, setIsMaterialLoading] = useState(false);
    const [countdown, setCountdown] = useState<number>(0);
    const [countdownFinished, setCountdownFinished] = useState(false);
    const [handleError] = useHandleError();

    const intl = useIntl();

    const { Countdown } = Statistic;
    const onFinish = () => {
        setCountdownFinished(true);
        setCountdown(0);
    };

    const startActivity = async () => {
        setIsMaterialLoading(true)
        try {
            if (isPreview && session.active_user_type !== 'STUDENT') {

                let url = `${config.api.previewEmbedded}/${activityAttemptId}`

                if (language) {
                    url += '?language=' + language
                }

                if (version) {
                    url += '&version=' + version
                }

                setSource(url);
            } else {
                setSource(`${config.api.serveEmbeddedUrl}/${activityAttemptId}`);

            }
            setBeginActivity(true)
            loadSettings();
        } catch (error) {
            handleError(error)
        } finally {
            setIsMaterialLoading(false)
        }
    }

    const loadSettings = async () => {
        let embeddedFileSettings = await coursesApiClient.request(`/api/v1/courses/embedded/${activityAttemptId}/settings`, {}, 'GET')
        if (embeddedFileSettings && embeddedFileSettings.secondsOnFile) {
            setCountdown(Date.now() + 1000 * embeddedFileSettings.secondsOnFile);
        } else {
            setCountdownFinished(true);
        }
    }

    const finishActivity = async () => {
        if (!countdown && countdownFinished) {
            try {
                if (!isPreview) {
                    await coursesApiClient.request(`/api/v1/courses/embedded/${activityAttemptId}/finish`, {}, 'POST');
                    window.close();

                    window.onbeforeunload = function() {
                        return intl.formatMessage({ id: 'general.close_tab_warning' });
                    };
                }
            } catch (error) {
                handleError(error)
            }
        }
    }

    return (
        <>
            {beginActivity ?
                <Spinner spinning={isMaterialLoading}>
                    {source ? <iframe className="embedded-player" title="Embedded" src={source}/> : null}
                    {!isPreview &&
                        <div className={'flex justify-end'}>
                            <Button type={'primary'} onClick={() => finishActivity()} disabled={countdown && !countdownFinished ? true : false}>
                                {countdown ?
                                    <span className={'embedded-file-countdown'}><Countdown value={countdown} onFinish={onFinish} /></span>
                                    : <FormattedMessage id={'course.finish_activity'}/>}
                            </Button>
                        </div>
                    }
                </Spinner>
                :
                <div className={'flex justify-center'}>
                    <Button type={'primary'} onClick={() => startActivity()}>
                        <FormattedMessage id={'course.start_activity'}/>
                    </Button>
                </div>
            }
        </>
    );
}

export default connect(mapStateToProps)(EmbeddedDocMaterialPlayer);
