import React, {useState} from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import {connect} from 'react-redux';
import {FormattedMessage, useIntl} from 'react-intl';
import {Popover, Form, Modal, Button, Space} from 'antd';
import {PlusOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import DefaultLayout from 'components/DefaultLayout';
import {Select} from 'components/Form';
import apiClient from 'utils/apiClient';
import {config} from 'config/config';
import {Table} from 'ui'
import useHandleError from "utils/useHandleError";
import generalHelpers from "utils/generalHelpers";

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale,
        session: state.session
    }
}

const OrganizationTemplates: React.FC<any> = ({session, locale}) => {
    const [reload, setReload] = useState(false);
    const [search, setSearch] = useState('');
    const [visibleTooltip, setVisibleTooltip] = useState<number | null>(null);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [filter, setFilter] = useState('')

    const intl = useIntl();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [handleError] = useHandleError();

    const handleVisibleChange = (visible: boolean, recordId: number) => {
        setVisibleTooltip(visible ? recordId : null);
    }

    const columns = [
        {
            title: intl.formatMessage({id: 'emailing.templates.template_name'}),
            dataIndex: 'name',
            key: 'name',
            render: (text: string, record: any) => {
                const translations = record.translations;
                let titleObject = translations.filter((el: any) => el.language === locale)[0];

                if (!titleObject) {
                    titleObject = translations[Object.keys(translations)[0]]
                }
                return (
                    <NavLink
                        to={`/e-mailing/manage-templates/organization/${record.id}/edit`}>
                        {titleObject.title}
                    </NavLink>
                )
            }
        },
        {
            title: intl.formatMessage({id: 'emailing.templates.template_type'}),
            dataIndex: 'templateType',
            key: 'templateType',
            render: (text: string, record: any) => intl.formatMessage({id: `email_templates.template_type.${record.templateType}`})
        },
        {
            title: intl.formatMessage({id: 'emailing.templates.template_category'}),
            dataIndex: 'categoryCode',
            key: 'categoryCode',
            render: (text: string, record: any) => intl.formatMessage({id: `email_templates.categories.${record.categoryKey}`})
        },
        // {
        //   title: intl.formatMessage({id: 'general.language'}),
        //   dataIndex: 'language',
        //   key: 'language',
        //   render: (text: string, record: any) => {
        //     let html;
        //     if (record.html_template) {
        //       html = record.html_template
        //     } else {
        //       html = record.html
        //     }
        //     return (
        //           <Radio.Group>
        //             { html ?
        //               Object.entries(html).map((el:any, index:any)=>{
        //                 return (
        //                     <>
        //                       {el ?
        //                         <Radio.Button style={{ textTransform: 'uppercase'}} value={el[0]} onClick={() => {
        //                           if (record.html_template) {
        //                             setLanguageEditRecord(record.html_template[el[0]]);
        //                           } else {
        //                             setLanguageEditRecord(record.html[el[0]]);
        //                           }
        //                           setTemplateSubject(record.subject_translations[el[0]])
        //                           setSelecteTemplate(record);
        //                           setSelectedLanguageKey(el[0]);
        //                           setShowEditModal(true)}}
        //                         >
        //                           {el[0]}
        //                         </Radio.Button>
        //                         : null
        //                       }
        //                     </>
        //                   )
        //               })
        //               : null
        //             }
        //           </Radio.Group>
        //
        //     )
        //   }
        // },
        {
          key: 'actions',
          render: (text: string, record: any) => {

            const deleteButton = (
                <div onClick={() => {confirmTemplateDeletion(record.id); setVisibleTooltip(null)}} className="popover-item">
                    <FormattedMessage id="general.delete"/>
                </div>
            );

            const content = <>
              <NavLink to={`/e-mailing/manage-templates/organization/${record.id}/edit`}>
                <div className="popover-item" >
                  <FormattedMessage id="general.edit"/>
                </div>
              </NavLink>
                {record.category_code !== 'system_events' && ['DISTRIBUTOR_ADMIN', 'RESELLER_ADMIN'].includes(session.active_user_type) &&
                  deleteButton
                }
                {session.active_user_type === 'SUPER_ADMIN' &&
                  deleteButton
                }
            </>;

            return (
              <Popover
                open={record.id === visibleTooltip}
                content={content}
                trigger="click"
                placement="bottomRight"
                arrowPointAtCenter
                onOpenChange={(visible: boolean) => handleVisibleChange(visible, record.id)}
              >
                <div style={{width: '100%', cursor: 'pointer', textAlign: 'center'}}>
                  <i className="fal fa-ellipsis-v" style={{fontSize: '16px'}} />
                </div>
              </Popover>
            );
          },
        }
    ];

    const confirmTemplateDeletion = (id: number) => {
        Modal.confirm({
            title: intl.formatMessage({id: 'general.attention'}),
            icon: <ExclamationCircleOutlined/>,
            content: intl.formatMessage({id: 'emailing.templates.begin_deletion_org'}),
            okText: intl.formatMessage({id: 'general.delete'}),
            okType: 'danger',
            cancelText: intl.formatMessage({id: 'general.cancel'}),
            onOk() {
                return deleteTemplate(id);
            }
        })
    };

    const deleteTemplate = async (id: number) => {
        try {
            await apiClient.request(`/api/v1/mail-templates/organization/${id}/delete`, {}, 'DELETE');
            setReload(true)
        } catch (error) {
            handleError(error);
        }
    };

    const selectOwner = async (value: any) => {
        try {
            setFilter('organizationId=' + value.organizationId);
            setReload(true)
            setShowFilterModal(false)
        } catch (error) {
            handleError(error);
        }
    }

    const checkOwner = () => {
        if (session.active_user_type === 'SUPER_ADMIN') {
            setShowFilterModal(true);
        } else {
            navigate('/e-mailing/manage-templates/organization/add')
        }
    }

    return (
        <DefaultLayout.PageContent>
            <Space size={10}>
                <Button type="primary" onClick={checkOwner}>
                    <PlusOutlined/> <FormattedMessage id='general.add'/>
                </Button>
                {generalHelpers.isPlatformAdmin(session.active_user_type) &&
                  <Button
                    type="primary"
                    onClick={() => {
                        setShowFilterModal(true)
                    }}
                  >
                    <FormattedMessage id="general.filter"/>
                  </Button>
                }
            </Space>
            <Table
                columns={columns}
                url='/api/v1/mail-templates/organization'
                setReload={setReload}
                reload={reload}
                filter={filter}
                rowKey='id'
                search={search}
            />
            {generalHelpers.isPlatformAdmin(session.active_user_type) &&
              <Modal
                className="user-list-modal"
                open={showFilterModal}
                title={intl.formatMessage({id: 'emailing.templates.select_organization'})}
                onOk={() => form.submit()}
                onCancel={() => setShowFilterModal(false)}
                okText={intl.formatMessage({id: 'general.select'})}
                cancelText={intl.formatMessage({id: 'general.cancel'})}
              >
                <Form form={form} onFinish={selectOwner}>
                  <Select
                    allowClear
                    showSearch
                    name='organizationId'
                    dataKey='organizations'
                    label={intl.formatMessage({id: "general.reseller"})}
                    url={config.api.routes.backend.selectOrganizations}
                    integerKey={true}
                    customLayout={true}
                    dropdownStyle={{minWidth: "385px"}}
                    filterOption={(input: string, option: any) => {
                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                  />
                </Form>
              </Modal>
            }
        </DefaultLayout.PageContent>
    );
}

export default connect(mapStateToProps)(OrganizationTemplates);
