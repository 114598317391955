import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {NavLink, useNavigate, useParams} from 'react-router-dom';
import UserFormDisplay from 'components/UsersComponents/UsersForm';
import DefaultLayout from 'components/DefaultLayout';
import { connect } from 'react-redux';
import UserInfo from 'components/UsersComponents/UserInfo';
import apiClient from 'utils/apiClient';
import { Button, Form, message, Modal, Tabs } from 'antd';
import { UserFormInterface } from 'components/UsersComponents/types';
import StudiesTab from '../../Customers/CustomerUsersEdit/StudiesTab';
import { clearStudentViewData } from 'store/actions/saveStudentViewDataActions';
import methods from 'components/UsersComponents/UsersForm/methods';
import Spinner from 'components/Spinner';
import jwt from 'utils/jwt';
import './styles.scss';
import LectureTab from "../../Customers/CustomerUsersEdit/LectureTab";
import CertificatesTab from "../../Customers/CustomerUsersEdit/CertificatesTab";
import moment from "moment";
import {config} from "../../../config/config";
import PhishingTab from "../../Customers/CustomerUsersEdit/PhishingTab";
import EventsTab from '../../Customers/CustomerUsersEdit/EventsTab';

const { TabPane } = Tabs;

const mapStateToProps = (state: any) => {
    return {
        session: state.session,
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    clearStudentViewData: () => dispatch(clearStudentViewData())
});


const UserForm: React.FC<UserFormInterface> = ({ session, location, clearStudentViewData }: any) => {
    const fromCampaign = location?.state?.fromCampaign;
    const campaignId = location?.state?.campaignId;
    const enrollmentId = location?.state?.enrollmentId;
    const campaignTitle = location?.state?.campaignTitle;

    const [submitInProgress, setSubmitInProgress] = useState(false);

    const initialTabKey = fromCampaign ? 'studies' : 'user_data';
    const [displayStudentFields, setDisplayStudentFields] = useState<boolean>(false);
    const [customerUser, setCustomerUser] = useState<any>([]);
    const [user, setUser] = useState<any>({});
    const [disabledFields, setDisabledFields] = useState(false);
    const [studiesTabKey, setStudiesTabKey] = useState(0);
    const [activeTabKey, setActiveTabKey] = useState(initialTabKey);
    const [backToUserCampaignList, setBackToUserCampaignList] = useState<boolean>(false);
    const [activeUserIsMe, setActiveUserIsMe] = useState(false);
    const [organizationalUnits, setOrganizationalUnits] = useState<[]>([]);

    const [newCustomValue, setNewCustomValue] = useState<any>([]);
    const [addOrganizationalUnit, setAddOrganizationalUnit] = useState<any>([]);

    const [customerParams, setCustomerParams] = useState();
    const [customer, setCustomer] = useState<any>({});

    //loaders
    const [isUserLoading, setIsUserLoading] = useState(true);

    const intl = useIntl();
    const [form] = Form.useForm();
    const params = useParams();

    const userUuid = params.uuid;
    const role: any = params.role;

    const customerId: string | undefined = params.customerId;
    const backPath = params.backPath;
    const navigate = useNavigate();

    useEffect(() => {
        if (!fromCampaign) {
            clearStudentViewData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialTabKey])

    useEffect(() => {
        if (role && role === 'learning-managers' && session.active_user_type === 'CUSTOMER_ADMIN') {
            if (!session.special_rights.includes('can_manage_learning_managers')) {
                navigate('/403')
            }
        }

        const loadCustomer = async () => {
            try {

                let url = '/api/v1/customers/options/values';

                if (session.active_user_type === 'SUPER_ADMIN' || session.active_user_type === 'RESELLER_ADMIN' || session.active_user_type === 'DISTRIBUTOR_ADMIN') {
                    url = url + '?customerId=' + customerId;
                }

                if (session.special_rights && session.special_rights.includes('can_manage_customers')) {
                    url = url + '?customerId=' + customerId;
                }

                let customerDefaultValuesResponse = await apiClient.request(url, [], 'GET');

                setCustomer(customerDefaultValuesResponse.customer);

                setCustomerParams(customerDefaultValuesResponse.customer.params);
            } catch (err) {
                console.error(err);
                message.error(intl.formatMessage({ id: 'error.data_load' }));
            } finally {
                setIsUserLoading(false);
            }
        };

        loadCustomer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (role && role === 'students') {
            setDisplayStudentFields(true);
        }
    }, [role]);

    useEffect(() => {
        const loadCustomerUser = async () => {
            try {
                setIsUserLoading(true);
                let userResponse = await apiClient.request(`/api/v1/customer-users/${userUuid}`, [], 'GET');
                let customerUser = userResponse.user;

                if (customerUser.isActiveLearningManagerRole) {
                    if (session.active_user_type === 'CUSTOMER_ADMIN') {
                        if (!session.special_rights.includes('can_manage_learning_managers')) {
                            navigate('/403')
                        }
                    }
                }

                setCustomerUser(customerUser);
                let user = userResponse.user;
                setUser(user);
                setActiveUserIsMe(userResponse.user.email === session.email);

            } catch (err: any) {

                if (err.message === 'user_does_not_belongs_to_customer') {
                    navigate('/403')
                }

                if (err.message === 'action_is_not_permitted') {

                }

                if (err.error === 'dont_have_permission_to_access') {
                    navigate('/403');
                }

                message.error(intl.formatMessage({ id: 'error.data_load' }));
            } finally {
                setIsUserLoading(false);
            }
        };

        if (session.active_user_type === 'SUPERVISION') {
            setDisabledFields(true);
        }
        if (userUuid) {
            loadCustomerUser();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userUuid]);

    const getBreadcrumbs = () => {
        if (session.active_user_type === 'SUPERVISION') {
            return [
                {
                    name: intl.formatMessage({ id: 'general.users' }),
                    path: '/users'
                },
                {
                    name: `${customer.name}  ${intl.formatMessage({ id: 'general.users' })}`,
                    path: '/users'
                },
            ]
        }

        if (session.active_user_type === 'SUPER_ADMIN' || session.active_user_type === 'RESELLER_ADMIN' || session.active_user_type === 'DISTRIBUTOR_ADMIN') {
            return customerId
                ?
                [
                    {
                        name: intl.formatMessage({ id: 'general.customers' }),
                        path: '/customers',
                    },
                    {
                        name: `${customer.name}  ${intl.formatMessage({ id: 'general.users' })}`,
                        path: getBackPath(),
                    },
                ]
                :
                [
                    {
                        name: intl.formatMessage({ id: 'general.users' }),
                        path: getBackPath(),
                    },
                ];
        }

        if (session.active_user_type === 'CUSTOMER_ADMIN') {
            return [
                {
                    name: intl.formatMessage({ id: 'general.customers' }),
                    path: '/customers',
                },
                {
                    name: `${customer.name}  ${intl.formatMessage({id: 'general.users'})}`,
                    path: getBackPath()
                },
            ]
        }
    };

    const getBackPath = () => {
        let url = '';

        if (backPath === 'org') {
            url = `/customers/${customerId}/users`
        } else if (backPath && backPath.includes('aud_')) {
            const audience = backPath.split('_')
            url = '/audiences/' + audience[1]
        } else {
            url = `/customers/${customerId}/users`

        }
        return url
    }

    const handleTabClick = (key: string) => {
        setActiveTabKey(key);

        if (key === 'studies') {
            setStudiesTabKey(studiesTabKey + 1);
        }
    };

    const submitForm = async (values: any) => {
        const existingFields = [
            'name', 'surname', 'personalCode', 'email', 'position', 'phoneNumber', 'organizationalUnitId',
            'managerReferenceId', 'primaryLanguage', 'timeZone', 'accountStatus', 'departmentId', 'resourcePlanner',
            'phishingAllowed', 'enableLector', 'canManageLearningManagers', 'absence', 'canManageCustomers', 'ignoreAccessPermissions',
            'isActiveSuperVision', 'isActiveElearningAccount', 'sendEmail', 'canAddOwnContent', 'smsSending',
            'canManageCustomerConnection', 'code', 'canManageGeneralMessaging', 'commencementDate'
        ];

        const manager = parseInt(values.managerReferenceId);

        const planner = parseInt(values.resourcePlanner);

        let parsedValues: any = {
            name: values.name,
            surname: values.surname,
            email: values.email,
            personalCode: values.personalCode,
            position: values.position,
            commencementDate: values.commencementDate ? moment(values.commencementDate).format('YYYY-MM-DD') : null,
            phoneNumber: `${values.code} ${values.phoneNumber}`,
            organizationalUnitId: values.organizationalUnitId,
            departmentId: values.departmentId,
            managerReferenceId: isNaN(manager) ? null : manager,
            resourcePlanner: isNaN(planner) ? null: planner,
            primaryLanguage: values.primaryLanguage,
            timeZone: values.timeZone,
            accountStatus: values.accountStatus,
            phishingAllowed: !!values.phishingAllowed,
            canManageLearningManagers: values.canManageLearningManagers,
            canAddOwnContent: values.canAddOwnContent,
            canManageCustomerConnection: values.canManageCustomerConnection,
            canManageCustomers: values.canManageCustomers,
            ignoreAccessPermissions: values.ignoreAccessPermissions,
            isActiveSuperVision: values.isActiveSuperVision,
            enableLector: !!values.enableLector,
            isActiveElearningAccount: values.isActiveElearningAccount,
            absence: values.absence,
            customerId: customerId,
            smsSending: values.smsSending,
            canManageGeneralMessaging: values.canManageGeneralMessaging,
        };

        if (!userUuid) {
            parsedValues.sendWelcomeMail = values.sendEmail;
        }

        let orgUnitExists = false;

        organizationalUnits.map((orgUnit: [string, string]) => {
            if (orgUnit[0] === values.organizationalUnitId) {
                orgUnitExists = true;
            }
            return orgUnitExists;
        });

        if (orgUnitExists) {
            parsedValues.organizationalUnitId = values.organizationalUnitId;
        } else {
            parsedValues.newOrganizationalUnit = values.organizationalUnitId;
            parsedValues.organizationalUnitId = customerUser.organizationalUnitId;
        }

        let customFields: any = {};
        let customFiltersExist = false;

        Object.entries(values).map((el: any) => {
            if (!existingFields.includes(el[0])) {
                customFields[el[0]] = el[1];
                customFiltersExist = true;
            }
            return customFields;
        });

        if (customFiltersExist) {
            parsedValues.customFields = customFields;
        }

        if (role === 'learning-managers') {
            parsedValues.isActiveLearningManagerRole = true;
        }

        try {
            setSubmitInProgress(true);
            if (userUuid) {
                await apiClient.request(`/api/v1/customer-users/${userUuid}`, parsedValues, 'PUT');
            } else {
                await apiClient.request('/api/v1/customer-users', parsedValues, 'POST');

                if (role === 'learning-managers') {
                    message.success(intl.formatMessage({ id: 'users.learning_manager_created_created' }));
                } else if (role === 'students') {
                    message.success(intl.formatMessage({ id: 'users.form.user_successfully_created' }));
                }
            }

            setSubmitInProgress(false);
            navigate(-1);
            if (values.email === session.email) {
                jwt.switchUserRole(session.active_user_type_id);
            }

        } catch (error: any) {
            console.error(error);
            if (error.message === 'student_email_already_exists') {
                Modal.error({
                    title: 'Error',
                    content: `There already is one user within {} account with such email!`,
                });
            } else if (error.message === 'customer_admin_email_already_exists') {
                Modal.error({
                    title: 'Error',
                    content: intl.formatMessage({ id: 'validation.customer_email_already_exists' }),
                });
            } else if (error.message === intl.formatMessage({ id: 'validation.the_given_data_was_invalid' })) {
                Modal.error({
                    title: 'Error',
                    content: intl.formatMessage({ id: 'validation.the_given_data_was_invalid' }),
                });
            } else if (error.message === 'max_users_limit_exceeded') {
                Modal.error({
                    title: 'Error',
                    content: intl.formatMessage({ id: 'error.' + error.message }),
                });
            } else if (error.status === 422) {
                let errors = JSON.parse(error.message);
                form.setFields(methods.mapSubmitErrorsToForm(errors));
                Modal.error({
                    title: 'Error',
                    content: intl.formatMessage({ id: 'validation.customer_email_already_exists' })
                });
            } else {
                message.error(intl.formatMessage({ id: 'general.submit_error' }));    // TODO: Izmantot notification
            }

            setSubmitInProgress(false);
        } finally {
            setNewCustomValue([]);
            setAddOrganizationalUnit([]);
        }
    };

    const backToCampaign = () => {
        if (backPath?.includes("campaign")) {
            navigate(`/${backPath.replace(/,/g, '/')}`);
        } else {
            setBackToUserCampaignList(true)
        }
    }

    const backToCampaignList = <Button onClick={() => backToCampaign()}><FormattedMessage id="general.back" /></Button>

    return (
        <Spinner spinning={isUserLoading}>
            <DefaultLayout.PageLayout withStickyFooter>
                <DefaultLayout.PageHeader
                    loading={userUuid ? isUserLoading : false}
                    breadcrumb={getBreadcrumbs()}
                    title={fromCampaign ? campaignTitle : session.active_user_type !== 'SUPERVISION' ? intl.formatMessage({ id: 'users.edit_user' }) : intl.formatMessage({ id: 'users.view_user' })}
                />
                <Spinner spinning={isUserLoading} >
                    <DefaultLayout.PageContent >
                        {userUuid &&
                            <UserInfo user={user} />
                        }
                        {!userUuid &&
                            <UserFormDisplay
                                displayStudentFields={displayStudentFields}
                                disabledFields={disabledFields}
                                customerUser={customerUser}
                                customerParams={customerParams}
                                form={form}
                                submitForm={submitForm}
                                addOrganizationalUnit={addOrganizationalUnit}
                                setAddOrganizationalUnit={setAddOrganizationalUnit}
                                setNewCustomValue={setNewCustomValue}
                                newCustomValue={newCustomValue}
                                userUuid={userUuid}
                                role={role}
                                customerId={customerId}
                                organizationalUnits={organizationalUnits}
                                setOrganizationalUnits={setOrganizationalUnits}
                            />
                        }
                        {userUuid &&
                            <>
                                <Tabs className='user-view-tabs' type='card' onTabClick={handleTabClick} activeKey={activeTabKey} tabBarExtraContent={activeTabKey === 'studies' ? backToCampaignList : null}>
                                    <TabPane tab={intl.formatMessage({ id: 'general.user_data' })} key='user_data'>
                                        <UserFormDisplay
                                            displayStudentFields={displayStudentFields}
                                            disabledFields={disabledFields}
                                            customerUser={customerUser}
                                            customerParams={customerParams}
                                            form={form}
                                            submitForm={submitForm}
                                            addOrganizationalUnit={addOrganizationalUnit}
                                            setAddOrganizationalUnit={setAddOrganizationalUnit}
                                            setNewCustomValue={setNewCustomValue}
                                            newCustomValue={newCustomValue}
                                            userUuid={userUuid}
                                            role={role}
                                            organizationalUnits={organizationalUnits}
                                            setOrganizationalUnits={setOrganizationalUnits}
                                            customerId={customerId}
                                        />
                                    </TabPane>
                                    {customerUser.studentUserTypeUuid && (
                                        <>
                                            <TabPane tab={intl.formatMessage({ id: 'courses.studies' })} key='studies'>
                                                <StudiesTab
                                                    userTypeId={customerUser.studentUserType.id}
                                                    back={backToUserCampaignList}
                                                    setBack={setBackToUserCampaignList}
                                                    campaignId={campaignId}
                                                    enrollmentId={enrollmentId}
                                                    key={studiesTabKey}
                                                    userUuid={customerUser.studentUserTypeUuid}
                                                    activeUserIsMe={activeUserIsMe}
                                                />
                                            </TabPane>
                                            <TabPane tab={intl.formatMessage({ id: 'general.lectures' })} key='lectures'>
                                                <LectureTab userTypeId={customerUser.studentUserType.id} />
                                            </TabPane>
                                            <TabPane tab={intl.formatMessage({ id: 'general.certificates' })} key='certificates'>
                                                <CertificatesTab userTypeId={customerUser.studentUserType.id} />
                                            </TabPane>
                                            {config.api.environment === 'development' &&
                                                <TabPane tab={intl.formatMessage({ id: 'phishing.phishing' })} key='phishing'>
                                                    <PhishingTab userTypeId={customerUser.studentUserType.id} />
                                                </TabPane>
                                            }
                                            <TabPane tab={intl.formatMessage({ id: 'general.event_feed' })} key='events'>
                                                <EventsTab userTypeId={customerUser.studentUserType.id} />
                                            </TabPane>
                                        </>
                                    )}
                                </Tabs>
                            </>
                        }
                    </DefaultLayout.PageContent>
                </Spinner>
            </DefaultLayout.PageLayout>
            {activeTabKey === 'user_data' && (
                <>
                    {session.active_user_type !== 'SUPERVISION' &&
                        <DefaultLayout.PageFooter
                            right={
                                <>
                                    <NavLink to='/users'>
                                        <Button>
                                            <FormattedMessage id='general.back' />
                                        </Button>
                                    </NavLink>
                                    <Button type='primary' htmlType='submit' loading={submitInProgress} onClick={form.submit}>
                                        <FormattedMessage id='general.submit' />
                                    </Button>
                                </>
                            }
                        />
                    }
                </>
            )}
        </Spinner>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
