import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {
    Button,
    DatePicker,
    Form,
    Radio,
    Tabs,
    Tooltip,
    TimePicker,
    Modal,
    Space
} from 'antd';
import DefaultLayout from 'components/DefaultLayout';
import {Input, InputNumber, RequestSelect, Select} from 'components/Form';
import {useParams, useNavigate} from 'react-router-dom';
import {Table} from 'ui';
import {usePhishing} from "hooks/Phishing/usePhishing";
import moment from "moment/moment";
import {ExclamationCircleOutlined, MenuOutlined, PlusOutlined} from "@ant-design/icons";
import PhishingSimulationsModal from "components/FormCampaign/PhishingSimulationsModal";
import {SortableContainer, SortableContainerProps, SortableElement, SortableHandle, SortEnd} from "react-sortable-hoc";
import arrayMove from "array-move";
import coursesApiClient from "utils/coursesApiClient";
import useHandleError from "utils/useHandleError";
import {config} from "config/config";
import './styles.scss';
import PhishingTestMailModal from "../../Modals/PhishingTestMailModal";
import {connect} from "react-redux";

const formItemLayout = {
    labelCol: {span: 8},
    wrapperCol: {span: 8},
};

const SortableItem = SortableElement((props: React.HTMLAttributes<HTMLTableRowElement>) => (
    <tr {...props} />
));
const SortableBody = SortableContainer((props: React.HTMLAttributes<HTMLTableSectionElement>) => (
    <tbody {...props} />
));


const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale
    };
};

const PhishingCampaignSettingsForm: React.FC<any> = ({launchButton, campaign, locale = config.api.defaultAppLanguage, setCampaign}) => {
    const {saveCampaign, deleteSimulation, saveSimulationSchedule, loading} = usePhishing();
    const [campaignType, setCampaignType] = useState<'DYNAMIC' | 'STRICTLY'>('DYNAMIC');
    const [escalationManager, setEscalationManager] = useState<boolean>(false);
    const [showPhishingSimulationModal, setShowPhishingSimulationModal] = useState(false);
    const [reload, setReload] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [orderMaterials, setOrderMaterials] = useState(false);
    const [handicap, setHandicap] = useState('EVENLY')
    const [visibleTooltip, setVisibleTooltip] = useState<number | null>(null);
    const [phishingDate, setPhishingDate] = useState<string>('');
    const [showTestMailModal, setShowTestMailModal] = useState(false);
    const [simulation, setSimulation] = useState({})

    const [handleError] = useHandleError();
    const navigate = useNavigate();
    const params = useParams();
    const {confirm} = Modal;
    const [form] = Form.useForm();
    const campaignId = params.id;
    const intl = useIntl();

    useEffect(() => {
        if (campaign) {
            loadCampaign();
        }
    }, [campaign])

    useEffect(() => {
        setCampaignType('DYNAMIC')
    }, [])

    const loadCampaign = async () => {
        setCampaignType(campaign.type);
        setHandicap(campaign.sendingHandicap)
        form.setFieldsValue({
            ...campaign,
            deadline: campaign.deadline ? moment(campaign.deadline, "YYYY-MM-DD") : moment(),
            time: [
                campaign.deliveryFrom ? moment(campaign.deliveryFrom, "HH:00") : moment(),
                campaign.deliveryTo ? moment(campaign.deliveryTo, "HH:00") : moment().add(1, 'hours')
            ],
        });
    }

    const submitForm = async (values: any) => {

        values = {
            ...values,
            deadline: values.deadline ? values.deadline.format('YYYY-MM-DD') : null,
            deliveryFrom: !!values.time && values.time.length ? values.time[0].format('HH:mm') : undefined,
            deliveryTo: !!values.time && values.time.length ? values.time[1].format('HH:mm') : undefined,
        }

        const response = await saveCampaign(values, campaignId ? campaignId : null);
        setCampaign(response);
        navigate(`/phishing/campaign/${response.id}/settings`)

    };

    const disabledDates = (current: moment.Moment, to: boolean = false) => {
        const validDate = to ? moment() : moment().add('3', 'M');
        if (validDate === null) {
            return current < moment().add(1, 'day');
        }

        return (
            (to ? validDate > current : current > validDate) ||
            validDate.isSame(current, 'day') ||
            current < moment().add(1, 'day')
        );
    };

    const handleVisibleChange = (visible: boolean, recordId: number) => {
        if (visible) {
            setVisibleTooltip(recordId);
        } else {
            setVisibleTooltip(null);
        }
    };

    const handleFormSubmit = async (values: any) => {
        if (campaignId) {
            await saveSimulationSchedule(campaignId, values);
            setVisibleTooltip(null);
            setReload(true);
        }
    };

    const columns = [
        {
            title: intl.formatMessage({id: 'phishing.nr'}),
            render: (text: string, record: any, index: any) => index + 1
        },
        {
            title: intl.formatMessage({id: 'phishing.template_name'}),
            dataIndex: 'title',
        },
        {
            title: intl.formatMessage({id: 'phishing.difficulty'}),
            dataIndex: 'difficulty',
            render: (text: string) => intl.formatMessage({id: `phishing.${text.toLowerCase()}`})
        },
        {
            title: campaignType === 'STRICTLY' ? intl.formatMessage({id: 'phishing.date_picker'}) : null,
            className: campaignType === 'DYNAMIC' ? 'hidden' : '',
            render: (text: string, record: any) => {
                return (
                    <>
                        {!(visibleTooltip === record.id) ?
                            <>
                                {record.next_scheduled_phishing ?
                                    <div className={'flex justify-between align-center'}>
                                        {moment(record.next_scheduled_phishing).format(config.defaultDateFormat)}
                                        <Button type={'link'} onClick={() => handleVisibleChange(true, record.id)}>
                                            <FormattedMessage id={'general.edit'}/>
                                        </Button>
                                    </div>
                                    :
                                    <Button type={'dashed'} onClick={() => handleVisibleChange(true, record.id)}>
                                        <FormattedMessage id={'general.add'}/>
                                    </Button>
                                }
                            </>
                            :
                            <Space size={8}>
                                <DatePicker
                                    format="YYYY-MM-DD"
                                    onChange={(el: any, dateString: string) => handleDateChange(dateString)}
                                    placeholder={phishingDate ? moment(phishingDate).format("YYYY-MM-DD") : ''}
                                    disabledDate={(current: any) => disabledDates(current, true)}
                                />
                                <Button type={'link'} onClick={() =>
                                    handleFormSubmit({
                                        ...record,
                                        next_scheduled_phishing: phishingDate ? phishingDate : null
                                    })
                                }>
                                    <FormattedMessage id={'general.save'}/>
                                </Button>
                                {record.next_scheduled_phishing &&
                                  <Button type={'link'} onClick={() => handleVisibleChange(false, record.id)}>
                                    <FormattedMessage id={'general.cancel'}/>
                                  </Button>
                                }
                            </Space>
                        }
                    </>

                )
            }
        },
        {
            width: 50,
            minWidth: 20,
            render: (text: string, record: any) => {
                return (
                    <Button className='send-test-email-button' onClick={() => {
                        setSimulation(record);
                        setShowTestMailModal(true)
                    }}>
                        <FormattedMessage id="phishing.send_test_phishing_button"/>
                    </Button>
                )
            }
        },
        {
            title: '',
            className: 'drag-visible ' + (campaignType === 'STRICTLY' ? 'hidden' : ''),
            render: () => {
                return (
                    campaign && campaign.status === 'DRAFT' ?
                        <DragHandle/>
                        : null
                )
            }
        },
        {
            title: '',
            className: 'drag-visible',
            render: (_text: string, record: any) => {
                return (
                    campaign && campaign.status === 'DRAFT' ?

                        <i onClick={() => warnDeletion(record)} className="fa-regular fa-trash pointer"></i>
                        : null
                )
            }
        }
    ];

    const handleDateChange = (dateString: string) => {
        setPhishingDate(dateString);
    };

    const warnDeletion = (record: any) => {
        confirm({
            title: intl.formatMessage({id: 'general.attention'}),
            content: intl.formatMessage({id: 'phishing.simulation_deletion'}),
            icon: <ExclamationCircleOutlined/>,
            okText: intl.formatMessage({id: 'general.yes'}),
            cancelText: intl.formatMessage({id: 'general.no'}),
            onOk() {
                deleteCampaignSimulation(record);
            }
        });
    }

    const deleteCampaignSimulation = async (record: any) => {
        await deleteSimulation(record.id, campaignId);
        setReload(true)
    }

    const onSortEnd = ({oldIndex, newIndex}: SortEnd) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMove(dataSource, oldIndex, newIndex)
            const sortIds = newData.map((item: any, key: number) => {
                return item.id
            })
            setDataSource(newData);
            updateOrder(sortIds)
            setOrderMaterials(true)
        }
    };

    const updateOrder = async (orderIds: any) => {
        try {
            await coursesApiClient.request(`/api/v1/courses/phishing/campaign/${campaignId}/simulations/order`, {orderIds}, 'PUT');
            setOrderMaterials(false)
        } catch (error) {
            handleError(error)
        }
    }

    const DraggableContainer = (props: SortableContainerProps) => (
        <SortableBody
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    );

    const DraggableBodyRow: React.FC<any> = ({className, style, ...restProps}) => {
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = dataSource.findIndex((x: any) => x.id === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };

    const DragHandle = SortableHandle(() => <MenuOutlined style={{cursor: 'grab', color: '#999'}}/>);

    return (
        <>
            <Form form={form} onFinish={submitForm} {...formItemLayout}>
                <Tabs>
                    <Tabs.TabPane tab={intl.formatMessage({id: 'general.main_settings'})} key="main_settings">
                        <Form.Item initialValue={'DYNAMIC'} name="type"
                                   label={intl.formatMessage({id: "phishing.choose_scenario"})}>
                            <Radio.Group onChange={(el: any) => setCampaignType(el.target.value)}>
                                <Radio value={'DYNAMIC'}><FormattedMessage id='phishing.dynamic_scenario'/></Radio>
                                <Radio value={'STRICTLY'}><FormattedMessage id='phishing.strictly_scenario'/></Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Input
                            name="title"
                            label={intl.formatMessage({id: 'phishing.campaign_name'})}
                            validation={{required: true}}
                        />
                        <Select
                            disabled={campaign.status === 'ONGOING'}
                            allowClear
                            showSearch
                            label={intl.formatMessage({id: 'general.learning_program'})}
                            dataKey='programs'
                            name={'programId'}
                            url={'/api/v1/courses/learning-program/options/list'}
                            integerKey={true}
                            customLayout={true}
                            customObjLabel={(el: any) => el[locale] ? el[locale] : el[Object.keys(el)[0]]}
                            dropdownStyle={{minWidth: "385px"}}
                            filterOption={(input: string, option: any) => {
                                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                            }}
                        />
                        {campaignType === 'DYNAMIC' &&
                          <Form.Item
                            initialValue={''}
                            label={
                                <span>
                                        {intl.formatMessage({id: 'phishing.finish_campaign_on'})}
                                    <Tooltip title={intl.formatMessage({id: 'phishing.finish_campaign_on_hint'})}>
                                            <i className='fal fa-question-circle header-item'/>
                                        </Tooltip>
                                    </span>
                            }
                            name='deadline'
                            rules={[{
                                required: true,
                                message: intl.formatMessage({id: "validation.field_required"})
                            }]}
                          >
                            <DatePicker
                              format="YYYY-MM-DD"
                              disabledDate={(current: any) => disabledDates(current, true)}
                              placeholder=""
                            />
                          </Form.Item>
                        }
                        <Form.Item
                            label={intl.formatMessage({id: 'phishing.deliver_phishing_time'})}
                            name='time'
                            rules={[{
                                required: true,
                                message: intl.formatMessage({id: "validation.field_required"})
                            }]}
                        >
                            <TimePicker.RangePicker popupClassName={'time-picker'} format={'HH:00'}/>
                        </Form.Item>
                        <InputNumber
                            initialValue={1}
                            name="mailPerMinute"
                            label={intl.formatMessage({id: 'phishing.maximum_emails_per_minute'})}
                            customRules={[{
                                required: true,
                                pattern: /^[1-9]\d*$/,
                                message: intl.formatMessage({id: "validation.must_be_integer"})
                            }]}
                            min={1}
                            max={60}
                        />
                        <Form.Item initialValue={'RANDOMIZED'} name="phishDelivery"
                                   label={intl.formatMessage({id: "phishing.phish_delivery"})}>
                            <Radio.Group>
                                <Radio value={'RANDOMIZED'}><FormattedMessage id='phishing.custom_randomized'/></Radio>
                                <Radio value={'SEQUENTIAL'}><FormattedMessage id='phishing.custom_sequential'/></Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            initialValue={false}
                            name='notifyManager'
                            label={
                                <span>
                                    {intl.formatMessage({id: 'phishing.notify_manager_on_fail'})}
                                    <Tooltip title={intl.formatMessage({id: 'phishing.notify_manager_on_fail_hint'})}>
                                        <i className='fal fa-question-circle header-item'/>
                                    </Tooltip>
                                </span>
                            }
                        >
                            <Radio.Group onChange={(event: any) => setEscalationManager(event.target.value)}>
                                <Radio value={true}><FormattedMessage id='phishing.yes'/></Radio>
                                <Radio value={false}><FormattedMessage id='phishing.no'/></Radio>
                            </Radio.Group>
                        </Form.Item>
                        {escalationManager &&
                          <RequestSelect
                            selectClassName='request-select'
                            allowClear
                            url={`/api/v1/edu/users/mass-search`}
                            param={[`customerUuid=${campaign.ownerUuid}&key=type_id&bothRoles=false&onlyCustomer=true`]}
                            column
                            label={intl.formatMessage({id: 'phishing.escalation_manager'})}
                            name={'escalationManager'}
                            showSearch
                          />
                        }
                        <Form.Item
                            initialValue={false}
                            name='notifyStudent'
                            label={
                                <span>
                                    {intl.formatMessage({id: 'phishing.notify_student_on_fail'})}
                                    <Tooltip title={intl.formatMessage({id: 'phishing.notify_student_on_fail_hint'})}>
                                        <i className='fal fa-question-circle header-item'/>
                                    </Tooltip>
                                </span>
                            }
                        >
                            <Radio.Group>
                                <Radio value={true}><FormattedMessage id='phishing.yes'/></Radio>
                                <Radio value={false}><FormattedMessage id='phishing.no'/></Radio>
                            </Radio.Group>
                        </Form.Item>

                        {(campaign && campaign.status === 'DRAFT') || !campaignId ?
                            <Button disabled={!campaignId} className='add-new-template-button' type="primary"
                                    onClick={() => setShowPhishingSimulationModal(true)}>
                                <PlusOutlined/> <FormattedMessage id="phishing.add_new"/>
                            </Button> : null
                        }

                        <Table
                            columns={columns}
                            url={`/api/v1/courses/phishing/campaign/${campaignId}/simulations`}
                            setReload={setReload}
                            reload={reload}
                            rowSelection={false}
                            rowKey={'id'}
                            manualDataUpdate={orderMaterials}
                            updateData={dataSource}
                            saveData={setDataSource}
                            components={{
                                body: {
                                    wrapper: DraggableContainer,
                                    row: DraggableBodyRow,
                                },
                            }}
                            scroll={{x: 800}}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane forceRender disabled={campaignType === 'STRICTLY'}
                                  tab={intl.formatMessage({id: 'phishing.advanced_settings'})} key="advanced_settings">
                        <div className='phishing-input-required'>
                            <InputNumber
                                initialValue={7}
                                name='startAfterDays'
                                label={
                                    <span>
                                {intl.formatMessage({id: 'phishing.begin_phishing_simulation_delivery'})}
                                        <Tooltip
                                            title={intl.formatMessage({id: 'phishing.begin_phishing_simulation_hint'})}>
                                        <i className='fal fa-question-circle header-item'/>
                                    </Tooltip>
                                </span>
                                }
                                customRules={[{
                                    required: true,
                                    message: intl.formatMessage({id: "validation.must_be_integer"})
                                }]}
                                min={1}
                                max={30}
                            />
                        </div>
                        <div className='phishing-input-required'>
                            <InputNumber
                                initialValue={7}
                                name='sendAfterFailDays'
                                label={
                                    <span>
                                {intl.formatMessage({id: 'phishing.if_user_failed_phishing'})}
                                        <Tooltip
                                            title={intl.formatMessage({id: 'phishing.user_failed_phishing_hint'})}>
                                    <i className='fal fa-question-circle header-item'/>
                                </Tooltip>
                                </span>
                                }
                                customRules={[{
                                    required: true,
                                    message: intl.formatMessage({id: "validation.must_be_integer"})
                                }]}
                                min={1}
                                max={60}
                            />
                        </div>
                        <div className='phishing-fields'>
                            <Form.Item
                                name="sendingHandicap"
                                initialValue={'EVENLY'}
                                label={intl.formatMessage({id: "phishing.if_user_passed_phishing"})}
                            >
                                <Radio.Group onChange={(el: any) => setHandicap(el.target.value)}>
                                    <Radio value={'EVENLY'}>
                                        <FormattedMessage id='phishing.evenly_spread'/>
                                        <span>
                                            <Tooltip title={intl.formatMessage({id: 'phishing.evenly_spread_hint'})}>
                                                <i className='fal fa-question-circle header-item'/>
                                            </Tooltip>
                                        </span>
                                    </Radio>
                                    <Radio value={'STRICTLY'}>
                                        <FormattedMessage id='phishing.strictly'/>
                                        <span>
                                                <Tooltip title={intl.formatMessage({id: 'phishing.strictly_hint'})}>
                                                    <i className='fal fa-question-circle header-item'/>
                                                </Tooltip>
                                            </span>
                                    </Radio>
                                </Radio.Group>
                            </Form.Item>
                            {handicap === 'STRICTLY' ?
                                <div className='days-input'>
                                    <InputNumber
                                        initialValue={1}
                                        name='handicapDays'
                                        label={intl.formatMessage({id: 'phishing.days'})}
                                        customRules={[{
                                            required: true,
                                            pattern: /^[1-9]\d*$/,
                                            message: intl.formatMessage({id: "validation.must_be_integer"})
                                        }]}
                                        min={1}
                                        max={60}
                                    />
                                </div>
                                : null
                            }
                            {config.api.environment === 'development' &&
                                <Form.Item
                                    name="incresingDifficulty"
                                    initialValue={'DEFAULT'}
                                    label={intl.formatMessage({id: "phishing.difficulty_change"})}
                                >
                                    <Radio.Group onChange={(el: any) => setHandicap(el.target.value)}>
                                        <Radio value={'DECREASES'}>
                                            <FormattedMessage id='phishing.diff_decreases'/>
                                        </Radio>
                                        <Radio value={'DEFAULT'}>
                                            <FormattedMessage id='phishing.diff_default'/>
                                        </Radio>
                                        <Radio value={'INCREASES'}>
                                            <FormattedMessage id='phishing.diff_increases'/>
                                        </Radio>
                                    </Radio.Group>
                                </Form.Item>
                            }
                            {config.api.environment === 'development' &&
                                <Form.Item
                                    name="incresingIfReportsDifficulty"
                                    initialValue={'DEFAULT'}
                                    label={intl.formatMessage({id: "phishing.difficulty_change_if_reports"})}
                                >
                                    <Radio.Group onChange={(el: any) => setHandicap(el.target.value)}>
                                        <Radio value={'DECREASES'}>
                                            <FormattedMessage id='phishing.diff_decreases'/>
                                        </Radio>
                                        <Radio value={'DEFAULT'}>
                                            <FormattedMessage id='phishing.diff_default'/>
                                        </Radio>
                                        <Radio value={'INCREASES'}>
                                            <FormattedMessage id='phishing.diff_increases'/>
                                        </Radio>
                                    </Radio.Group>
                                </Form.Item>
                            }
                        </div>
                    </Tabs.TabPane>
                </Tabs>
            </Form>
            <DefaultLayout.PageFooter
                right={
                    <>
                        <Button loading={loading} onClick={form.submit} className='save-button'>
                            <FormattedMessage id="general.submit"/>
                        </Button>
                        {launchButton}
                    </>
                }
            />
            <PhishingSimulationsModal
                visible={showPhishingSimulationModal}
                onCancel={() => setShowPhishingSimulationModal(false)}
                reloadCampaignSimulations={setReload}
            />
            <PhishingTestMailModal
                visible={showTestMailModal}
                onCancel={() => setShowTestMailModal(false)}
                simulation={simulation}
            />
        </>
    )
}

export default connect(mapStateToProps)(PhishingCampaignSettingsForm);
