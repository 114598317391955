import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Form, Modal } from 'antd';
import { LearningMaterialForm, LearningTestMaterialForm, TestMaterialForm, AssignmentForm, AssessmentForm, FileForm } from './MaterialForm';
import './styles.scss';
import CertificateForm from "./MaterialForm/CertificateForm";

interface MaterialModalInterface {
    visible: boolean;
    onCancel: any;
    type: 'SCORM_LEARNING' | 'SCORM_TEST' | 'LEARNING_TEST' | 'FILE' | 'ASSIGNMENT' | 'ASSESSMENT' | 'EMBEDDED_DOC' | '';
    language: string;
    activityId: number;
    material: any;
    setMaterial: React.Dispatch<React.SetStateAction<any>>;
    reload: Function;
}

const MaterialModal: React.FC<MaterialModalInterface> = ({ visible, onCancel, type, language, activityId, material, setMaterial, reload }) => {
    const modalWidth = {
        SCORM_LEARNING: '750px',
        SCORM_TEST: '750px',
        EMBEDDED_DOC: '750px',
        LEARNING_TEST: '750px',
        CERTIFICATE: '750px',
        VIDEO_MATERIAL: '750px',
        FILE: '750px',
        ASSIGNMENT: '90%',
        ASSESSMENT: '80%',
    }
    const intl = useIntl();
    const [form] = Form.useForm();

    const materialForm = (type: string) => {
        switch (type) {
            case 'SCORM_LEARNING':
            case 'EMBEDDED_DOC':
            case 'PDF_MATERIAL':
            case 'VIDEO_MATERIAL':
                return <LearningMaterialForm language={language} form={form} activityId={activityId} viewMaterial={material} onCancel={onCancel} reload={reload} />
            case 'SCORM_TEST':
                return <TestMaterialForm language={language} form={form} activityId={activityId} viewMaterial={material} onCancel={onCancel} reload={reload} />
            case 'FILE':
                return <FileForm language={language} form={form} activityId={activityId} onCancel={onCancel} reload={reload} />
            case 'ASSIGNMENT':
                return <AssignmentForm language={language} form={form} activityId={activityId} viewMaterial={material} onCancel={onCancel} reload={reload} />
            case 'LEARNING_TEST':
                return <LearningTestMaterialForm language={language} form={form} activityId={activityId} viewMaterial={material} onCancel={onCancel} reload={reload} />
            case 'ASSESSMENT':
                return <AssessmentForm language={language} form={form} activityId={activityId} viewMaterial={material} onCancel={onCancel} reload={reload} />
            case 'CERTIFICATE':
                return <CertificateForm language={language} form={form} activityId={activityId} viewMaterial={material} onCancel={onCancel} reload={reload} />
            default:
                break;
        }
    }

    const handleClose = () => {
        form.resetFields();
        onCancel();
        setMaterial(null);
    }

    const getTitle = (type: string) => {
        switch (type) {
            case 'SCORM_LEARNING':
            case 'PDF_MATERIAL':
            case 'EMBEDDED_DOC':
            case 'VIDEO_MATERIAL':
                return <div>
                    <i className="fa-solid fa-book"></i> <FormattedMessage id='courses.learning_file' />
                </div>
            case 'SCORM_TEST':
                return <div>
                    <i className="fa-solid fa-check-double"></i> <FormattedMessage id='courses.learning_file' />
                </div>
            case 'FILE':
                return <div>
                    <i className="fa-solid fa-file-lines"></i> <FormattedMessage id='courses.material' />
                </div>
            case 'ASSIGNMENT':
                return <div>
                    <i className="fa-solid fa-file-signature"></i> <FormattedMessage id='courses.individual_work' />
                </div>
            case 'LEARNING_TEST':
                return <div>
                    <i className="fa-solid fa-check-double"></i> <FormattedMessage id='learning_tests.learning_test' />
                </div>
            case 'ASSESSMENT':
                return <div>
                    <i className="fa-solid fa-file-user"></i> <FormattedMessage id='assessment.practical_assessment' />
                </div>
            case 'CERTIFICATE':
                return <div>
                    <i className="fa-solid fa-file-user"></i> <FormattedMessage id='courses.certificate' />
                </div>
            default:
                break;
        }
    }

    return (
        <Modal
            width={type && modalWidth[type]}
            open={visible}
            onCancel={() => handleClose()}
            onOk={() => form.submit()}
            title={getTitle(type)}
            cancelText={intl.formatMessage({ id: 'general.cancel' })}
            okText={intl.formatMessage({ id: 'general.submit' })}
            destroyOnClose={true}
            footer={type === 'LEARNING_TEST' ? null : undefined}
            className='material-modal-wrapper'
        >
            {materialForm(type)}
        </Modal >
    );
}

export default MaterialModal;
