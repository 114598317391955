import {useState} from 'react'
import useHandleError from 'utils/useHandleError';
import apiClient from 'utils/apiClient';
import {useIntl} from "react-intl";
import {config} from "config/config";
import generalHelpers from "utils/generalHelpers";

export const useOrganizations = () => {
    const [handleError] = useHandleError();
    const [loading, setLoading] = useState<boolean>(false)
    const intl = useIntl();

    const searchOrganizations = async (q: string) => {
        setLoading(true)
        try {
            let requestUrl = config.api.routes.backend.selectCustomersUuid

            requestUrl = generalHelpers.urlBuilder(requestUrl, q, 'q')


            const response = await apiClient.request(requestUrl, [], 'GET');

            if (response) {
                if (response?.customers) {
                    return response.customers;
                } else {
                    return response;
                }
            }
        } catch (error) {
            handleError(error);
        } finally {
            setLoading(false)
        }
    }

    return {
        loading,
        searchOrganizations,
    }
}