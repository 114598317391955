import React, {useState, useEffect} from 'react';
import {useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import DefaultLayout from "components/DefaultLayout";
import useHandleError from "utils/useHandleError";
import {Modal, Tabs} from "antd";
import {LearningTestSettings, LearningTestReview} from "components/LearningTest/index";
import coursesApiClient from "utils/coursesApiClient";
import Spinner from "components/Spinner";
import Access from "../../../components/LearningTest/Access";

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale
    };
};

const LearningTestEdit: React.FC<any> = ({ locale }) => {
    const [learningTest, setLearningTest] = useState<any>();

    const intl = useIntl();

    const params = useParams();
    const [handleError] = useHandleError();
    const {confirm} = Modal;
    const learningTestId = params.id;
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(!!learningTestId);


    useEffect(() => {
        if (learningTestId) {
            loadLearningTest()
        }
    }, [learningTestId])

    const loadLearningTest = async () => {

        setIsLoading(true);
        try {
            const response = await coursesApiClient.request(`/api/v1/courses/learning-tests/${learningTestId}`, [], 'GET');
            setLearningTest(response.test)
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <DefaultLayout.PageLayout>
            <DefaultLayout.PageHeader
                loading={isLoading}
                breadcrumb={[
                    {
                        name: intl.formatMessage({id: 'learning_tests.learning_tests'}), 
                        path: '/learning/tests'
                    }
                ]}
                title={learningTest ? learningTest.title : intl.formatMessage({id: 'learning_tests.learning_tests'})}
                />
            <DefaultLayout.PageContent withBottomMargin>
                <Spinner spinning={isLoading}>
                    <Tabs defaultActiveKey='settings'>
                        <Tabs.TabPane
                            key='settings'
                            tab={intl.formatMessage({id: 'learning_tests.settings'})}
                            >
                            <LearningTestSettings learningTest={learningTest} loadLearningTest={loadLearningTest}/>
                        </Tabs.TabPane>
                        <Tabs.TabPane key='review' tab={intl.formatMessage({id: 'general.review'})}>
                            <LearningTestReview />
                        </Tabs.TabPane>
                        <Tabs.TabPane key='access' tab={intl.formatMessage({id: 'program.access'})}>
                            <Access test={learningTest} />
                        </Tabs.TabPane>
                    </Tabs>
                </Spinner>
            </DefaultLayout.PageContent>
        </DefaultLayout.PageLayout>
    );
};

export default connect(mapStateToProps)(LearningTestEdit);
