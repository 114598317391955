import React, {useEffect, useState} from 'react';
import FilterPopover from "../FilterPopover";
import FilterCheckBox from "../FilterCheckBox";
import {useCompetences} from "hooks/Competences/useCompetences";
import {FormattedMessage} from "react-intl";
import {Button} from "ui";

interface CompetenceBlockFilterInterface {
    tagBuilder: Function;
    setFilterValues: any;
    filterValues: any
    form: any;
    filterKey: string;
    setFilterKey: React.Dispatch<React.SetStateAction<string>>;
}

const CompetenceBlockFilter: React.FC<CompetenceBlockFilterInterface> = ({
                                                                             filterKey,
                                                                             setFilterKey,
                                                                             form,
                                                                             tagBuilder,
                                                                             setFilterValues,
                                                                             filterValues
                                                                         }) => {
    const {searchCompetenceBlock, loading} = useCompetences();
    const [data, setData] = useState([]);
    const [initialValues, setInitialValues] = useState(undefined)

    const key = 'competence-blocks'

    useEffect(() => {
        onSearch()
    }, [])

    const onSearch = async (value: string = '') => {
        const response = await searchCompetenceBlock(value);
        setData(response);

        setFilterValues(
            {
                ...filterValues,
                competenceBlockId: {
                    ...filterValues.competenceBlockId,
                    ...response
                }
            }
        )
    };

    const content = (
        <>
            <div className={'flex flex-column p-1 checkbox-wrapper'}>
                {/*<div className={'p-1 checkbox-wrapper'}>*/}
                {/*    <div className={'bold'}>*/}
                {/*        <FormattedMessage id={'general.status'}/>*/}
                {/*    </div>*/}
                {/*    <Form.Item className={'mb-0'} name='competenceStatus'>*/}
                {/*        <Checkbox.Group>*/}
                {/*            <Checkbox value="VALID">*/}
                {/*                <FormattedMessage id='competences.status_valid'/>*/}
                {/*            </Checkbox>*/}
                {/*            <Checkbox value="EXPIRING">*/}
                {/*                <FormattedMessage id='competences.status_expiring'/>*/}
                {/*            </Checkbox>*/}
                {/*            <Checkbox value="NOT_VALID">*/}
                {/*                <FormattedMessage id='competences.status_not_valid'/>*/}
                {/*            </Checkbox>*/}
                {/*        </Checkbox.Group>*/}
                {/*    </Form.Item>*/}
                {/*    <Divider plain className={'m-2'}/>*/}
                {/*</div>*/}
                <FilterCheckBox data={data} name={'competenceBlockId'} onSearch={onSearch}/>
            </div>
            <div className={'flex justify-between p-1'}>
                <Button type={'link'} onClick={() => handleClose()}>
                    <FormattedMessage id={'general.cancel'}/>
                </Button>
                <Button type={'primary'} onClick={() => {
                                        setFilterKey('')

                    handleOpen(false)
                }}>
                    <FormattedMessage id={'general.select'}/>
                </Button>
            </div>
        </>
    );

    const handleClose = () => {
        form.setFieldsValue(initialValues);
                            setFilterKey('')
;
    }

    const handleOpen = (open: boolean) => {
        if (open) {
            setInitialValues(form.getFieldsValue())
            setFilterValues(
                {
                    ...filterValues,
                    competenceBlockId: {
                        ...filterValues.competenceBlockId,
                        ...data
                    }
                }
            )
        } else {
            tagBuilder()
        }
    }

    return (
        <FilterPopover
            popoverKey={key}
            filterKey={filterKey}
            setOpen={setFilterKey}
            handleOpen={handleOpen}
            content={content}
            loading={loading}
            title={'general.search_by_competences_blocks'}
            buttonTitle={'general.competences_blocks'}
        />
    )
}

export default CompetenceBlockFilter;