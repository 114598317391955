import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import DefaultLayout from "components/DefaultLayout";
import Spinner from 'components/Spinner';
import StudentNavBar from 'components/StudentNavBar';
import '../styles.scss';
import { Tabs } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { LectureProps, LectureSessionAttendeeStatus, SessionProps } from 'components/Lectures/types';
import { useSession } from 'hooks/Lectures/useSession';
import { useLecture } from "hooks/Lectures/useLecture";
import SessionAttendees from 'components/Lectures/SessionTabs/SessionAttendees';
import SessionWaitingList from 'components/Lectures/SessionTabs/SessionWaitingList';
import { connect } from 'react-redux';
import MySessionDetails from 'components/Lectures/MySessionDetails';
import {ArrowLeftOutlined} from "@ant-design/icons";

const mapStateToProps = (state: any) => {
    return {
        session: state.session
    }
};

export interface MyLecturesProps {
    session?: any;
    adminView?: boolean;
    userTypeId?: number
    adminSession?: number;
    adminLecture?: number;
    setAdminView?: React.Dispatch<React.SetStateAction<string>>;
    view?: string;

}

const MySession: React.FC<MyLecturesProps> = (props) => {
    const [session, setSession] = useState<SessionProps>({});
    const [lecture, setLecture] = useState<LectureProps>({});
    const [reload, setReload] = useState(false);
    const { getSessionyById, sessionLoading } = useSession();
    const { getLectureById } = useLecture();


    const intl = useIntl();
    const params = useParams();
    const navigate = useNavigate();
    const lectureId = props.adminView ? props.adminLecture : params.lecturesId ? +params.lecturesId : undefined;
    const sessionId = props.adminView ? props.adminSession : params.id ? +params.id : undefined;

    useEffect(() => {
        if (lectureId) {
            loadlLecture()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lectureId, reload])

    const loadlLecture = async () => {
        if (!!lectureId) {
            const lecture = await getLectureById(lectureId);
            if (lecture && lecture.id) {
                setLecture(lecture);
            } else {
                navigate('/my-lectures');
            }
        } else {
            navigate('/my-lectures');
        }
    }

    const loadSession = async () => {
        if (!!sessionId && !!lectureId) {
            if (+sessionId > 0) {
                const session: SessionProps | null = await getSessionyById(+lectureId, +sessionId, props.adminView, props.userTypeId);

                if (session && session?.status && ['ATTENDED', 'MISSED'].includes(session.status) && !session.access?.includes('1')) {
                    navigate('/my-lectures');
                }

                if (session && session.id) {
                    setSession(session);
                } else {
                    navigate('/my-lectures');
                }
            }
        } else {
            navigate('/my-lectures');
        }
    }

    useEffect(() => {
        loadSession();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload])

    const reloadSession = () => {
        setReload(!reload);
    }

    const renderContent = () => {
        if (!session) {
            return <></>;
        }

        if (props.session.active_user_type === 'STUDENT' || props.adminView) {
            return <MySessionDetails session={session} reloadSession={reloadSession} />;
        }

        return <Tabs destroyInactiveTabPane
            items={[
                {
                    label: intl.formatMessage({ id: 'general.lectures.session_details' }),
                    key: 'details',
                    children: <MySessionDetails session={session} reloadSession={reloadSession} />,
                },
                {
                    label: intl.formatMessage({ id: 'general.lectures.attendees' }),
                    key: 'attendees',
                    children: <SessionAttendees lecture={lecture} session={session} reloadSession={reloadSession} />,
                    disabled: session && !session.id
                },
                {
                    label: intl.formatMessage({ id: 'general.lectures.waiting_list' }),
                    key: 'waiting',
                    children: <SessionWaitingList lecture={lecture} session={session} reloadSession={reloadSession} />,
                    disabled: session && !session.id
                },
            ]}
        />
    }

    return (
        <DefaultLayout.PageLayout withoutPageLayout>
            <Spinner spinning={sessionLoading}>
                {!!session && <div className='content-wrapper'>
                    {props.adminView ?
                        <div onClick={() => props?.setAdminView ? props.setAdminView('LECTURE'): null} className="flex align-center title pointer gap-5">
                            <div>
                                <ArrowLeftOutlined className="arrow"/>
                            </div>
                            <h1 className={'mb-0'}>{session.title ?? ''}</h1>
                        </div>
                        :
                        <DefaultLayout.PageHeader
                        title={session.title ?? ''}
                        breadcrumb={[{
                        name: intl.formatMessage({ id: 'general.lectures.my_lectures' }),
                        path: '/my-lectures'
                    }]}
                        />
                    }

                    <DefaultLayout.PageContent>
                        {renderContent()}
                    </DefaultLayout.PageContent>
                </div>}
            </Spinner>
        </DefaultLayout.PageLayout>

    )
};

export default connect(mapStateToProps)(MySession);

