import React, {useState, useEffect} from 'react';
import {useIntl} from 'react-intl';
import {Form, Select as AntDSelect, Tooltip} from 'antd';
import apiClient from 'utils/apiClient';
import {constructValidationRules} from './validations';
import debounce from 'lodash/debounce';
import {connect} from "react-redux";
import useHandleError from "utils/useHandleError";

interface FilterValues {
    input: string;
    campaignId?: number;
}

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale
    };
};

const RequestSelect: React.FC<any> = ({
                                          url,
                                          campaignId,
                                          valueKey,
                                          labelKey,
                                          mapDataEntries,
                                          name,
                                          label,
                                          validation = {},
                                          customRules,
                                          customLayout,
                                          disabled = false,
                                          help,
                                          customObjLabel,
                                          visible = true,
                                          className,
                                          selectClassName,
                                          hint,
                                          saveData,
                                          useLocale = false,
                                          translationModule,
                                          initialValue,
                                          initialParam = null,
                                          initialValueData,
                                          isForm = true,
                                          param,
                                          locale,
                                          ...props
                                      }) => {
    const [options, setOptions] = useState<any>([]);
    const [isOptionsLoading, setIsOptionsLoading] = useState(false);
    const [handleError] = useHandleError();

    const intl = useIntl();

    // Fix for google autofill poping up in select input
    // https://github.com/ant-design/ant-design/issues/7659
    useEffect(() => {
        const fixAutocomplete = () => {
            document.querySelectorAll(".ant-select-selector input").forEach((e) => {
                e.setAttribute("autocomplete", "noAutocomplete");
                //you can put any value but NOT "off" or "false" because they DO NOT work
            })
        };

        fixAutocomplete();
    }, []);

    useEffect(() => {
        if (!!initialValue) {
            loadInitialValues()
        }
    }, [initialValue])

    useEffect(() => {
        if (!!initialValueData) {
            setOptions(initialValueData);

        }
    }, [initialValueData])


    let labelCol: any = {span: 8};
    let wrapperCol: any = {span: 6};

    if (customLayout) {
        if (typeof customLayout === 'object') {
            labelCol = customLayout.labelCol || undefined;
            wrapperCol = customLayout.wrapperCol || undefined;
        } else {
            labelCol = undefined;
            wrapperCol = undefined;
        }
    }

    const loadInitialValues = async () => {
        setIsOptionsLoading(true);

        try {
            let requestUrl = url

            let value = initialValue
            if (initialParam !== null && initialValue[initialParam]) {
                value = initialValue[initialParam].id
            }

            if (hasQueryParams(url)) {
                requestUrl += '&uuid=' + value;
            } else {
                requestUrl += '?uuid=' + value;
            }

            if (param) {
                param.map((el: any) => {
                    requestUrl += '&' + el
                })
            }

            requestUrl += '&initial=true'

            let response = await apiClient.request(requestUrl, [], 'GET');
            if (response) {
                setOptions(response);
                if (saveData) {
                    saveData(response);
                }
            } else {
                setOptions([]);
            }
        } catch (error) {
            handleError(error)
        } finally {
            setIsOptionsLoading(false);
        }
    }

    const hasQueryParams = (url: string) => {
        return url.includes('?');
    }

    const loadOptions = async (input: string) => {
        setIsOptionsLoading(true);
        if (!input) {
            return;
        }

        try {

            let requestUrl = url

            if (hasQueryParams(url)) {
                requestUrl += '&q=' + input;
            } else {
                requestUrl += '?q=' + input;
            }

            if (param) {
                param.map((el: any) => {
                    requestUrl += '&' + el
                })
            }

            let optionsResponse = await apiClient.request(requestUrl, [], 'GET');

            if (optionsResponse) {
                setOptions(optionsResponse);
                if (saveData) {
                    saveData(optionsResponse);
                }
            } else {
                setOptions([]);
            }
        } catch (error) {
            handleError(error)
        } finally {
            setIsOptionsLoading(false);
        }
    };

    const loadOptionsTimeOut = debounce(loadOptions, 800);

    const select = () => <AntDSelect
        className={selectClassName}
        disabled={disabled}
        loading={isOptionsLoading}
        filterOption={false}
        maxTagCount={1}
        onSearch={loadOptionsTimeOut}
        dropdownMatchSelectWidth={400}
        {...props}
    >
        {
            Object.entries(options).map((option: any) => {
                if (useLocale) {
                    const label = option[1][locale] ? option[1][locale] : option[1][Object.keys(option[1])[0]];
                    return (
                        <AntDSelect.Option key={option[0]} value={option[0]} label={label}>
                            {label}
                        </AntDSelect.Option>
                    )
                }

                if (!useLocale) {
                    return (
                        <AntDSelect.Option key={option[0]} value={option[0]} label={option[1]}>
                            {option[1]}
                        </AntDSelect.Option>
                    );
                }
            })
        }
    </AntDSelect>

    return (<>

            {isForm ?
                <Form.Item
                    className={className}
                    labelCol={labelCol}
                    wrapperCol={wrapperCol}
                    name={name}
                    label={
                        hint
                            ? <span>
                        {intl.formatMessage({id: label})}
                                <Tooltip title={intl.formatMessage({id: hint})}>
                            <i className='fal fa-question-circle header-item'/>
                        </Tooltip>
                    </span>
                            : label
                    }
                    rules={customRules || constructValidationRules(validation, intl)}
                    help={help}
                    style={{display: visible ? '' : 'none'}}
                >
                    {select()}
                </Form.Item>
                :
                <>
                    {select()}
                </>
            }
        </>
    )
};

export default connect(mapStateToProps)(RequestSelect);
