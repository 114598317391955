import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Alert, Card, Col, Row} from 'antd';
import {useParams} from 'react-router-dom';
import {usePhishing} from "hooks/Phishing/usePhishing";
import Spinner from "components/Spinner";
import DoughnutChart from "components/Charts/DoughnutChart";
import StackedBarChart from "components/Charts/StackedBarChart";

const PhishingCampaignSummary: React.FC<any> = ({campaign}) => {
    const {getSummary, getResultsByOrgUnit, loading} = usePhishing();
    const [doughnutChart, setDoughnutChart] = useState<any>([])
    const [stackedBar, setStackedBar] = useState<any>([])

    const params = useParams();
    const campaignId = params.id;
    const intl = useIntl();

    useEffect(() => {
        if (!!campaign?.status && campaign.status !== 'DRAFT') {
            loadSummaryData(campaign.id)
            getResults(campaign.id)
        }
    }, [campaign])

    const loadSummaryData = async (id: string) => {
        const data = await getSummary(id)
        setDoughnutChart(data)
    }

    const getResults = async (id: string) => {
        const data = await getResultsByOrgUnit(id)
        setStackedBar(data)
    }

    return (
        <>
            {campaignId && !!campaign?.status && campaign.status !== 'DRAFT' ?
                <Row style={{marginLeft: -8, marginRight: -8, marginTop: 16}} gutter={[16, 16]}>
                    <Col xs={24} md={12} span={8}>
                        <Card bordered={true} className="dashboard-card">
                            <Spinner spinning={loading}>
                                {doughnutChart ?
                                    <DoughnutChart
                                        title={intl.formatMessage({id: 'phishing.fail_rate'})}
                                        data={doughnutChart}
                                        cutoutPercentage={0}
                                    />
                                    : null
                                }
                            </Spinner>
                        </Card>
                    </Col>
                    <Col xs={24} md={12} span={8}>
                        <Card bordered={true} className="dashboard-card">
                            <Spinner spinning={loading}>
                                <div className='phishing-bar-chart'>
                                    {stackedBar ?
                                        <StackedBarChart
                                            title={intl.formatMessage({id: 'phishing.results_in_cross_section'})}
                                            data={stackedBar}
                                        />
                                        : null
                                    }
                                </div>
                            </Spinner>
                        </Card>
                    </Col>
                </Row>
                :
                <Alert className='smartdephish-alert' message={intl.formatMessage({id: "general.found_no_data"})}
                       type="info" showIcon style={{marginBottom: 24}}/>
            }
        </>
    )
}

export default PhishingCampaignSummary;
