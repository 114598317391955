import ThemeSwitcher from 'react-css-vars';


const ThemeSwitch = ({ theme }) => {
    return (
        <ThemeSwitcher theme={theme} />
    );
}

export default ThemeSwitch;
