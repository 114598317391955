import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {Modal, Tooltip} from 'antd';
import {Table} from 'ui';
import moment from 'moment';
import {useParams} from "react-router-dom";

const EnrollmentResultsModal = ({visible, onCancel, enrollment}: any) => {
    const [reload, setReload] = useState(false);
    const intl = useIntl();
    const params = useParams();
    const campaignId = params.id;

    const columns = [
        {
            title: intl.formatMessage({id: 'phishing.phishing_template'}),
            dataIndex: 'templateTitle',
        },
        {
            title: intl.formatMessage({id: 'phishing.email'}),
            dataIndex: 'email',
            render: () => {
                return enrollment.email
            }
        },
        {
            title: intl.formatMessage({id: 'phishing.phish_delivered_on'}),
            dataIndex: 'send_date',
            key: 'send_date',
            render: (date: string, record: any) => {
                if (!date) {
                    return moment(record.originalLaunch).format('DD.MM.YYYY HH:mm')
                }
                return moment(date).format('DD.MM.YYYY HH:mm')
            }
        },
        {
            title: (
                <span>
                  {intl.formatMessage({id: 'phishing.event'})}
                    <Tooltip title={intl.formatMessage({id: 'phishing.event_hint'})}>
                        <i className='fal fa-question-circle header-item'/>
                    </Tooltip>
                </span>
            ),
            dataIndex: 'status',
        },
        {
            title: intl.formatMessage({id: 'phishing.user_action_date'}),
            dataIndex: 'modified_date',
            key: 'modified_date',
            render: (last_action: string) => {

                if (!last_action) {
                    return '-';
                } else {
                    return moment(last_action).format('DD.MM.YYYY HH:mm')
                }
            }
        },
        {
            title: intl.formatMessage({id: 'phishing.reported'}),
            dataIndex: 'reported',
            key: 'reported',
            render: (text: string, record: any) => {
                return intl.formatMessage({id: record.reported === 'true' ? 'general.yes' : 'general.no'})
            }
        }
    ];

    return (
        <Modal
            className="results-modal"
            title={intl.formatMessage({id: 'phishing.results_modal'})}
            open={visible}
            onCancel={() => onCancel(false)}
            cancelText={intl.formatMessage({id: 'general.close'})}
            destroyOnClose
            footer={null}
        >
            {enrollment &&
              <Table
                columns={columns}
                url={`/api/v1/courses/phishing/campaign/${campaignId}/results/${enrollment.enrollmentId}`}
                setReload={setReload}
                size={'small'}
                rowSelection={false}
                showTotalResults={true}
                reload={reload}
                rowKey={'id'}
              />
            }
        </Modal>
    )
}
export default EnrollmentResultsModal;