import {MailScenarioStatusType} from 'pages/Emailing/types';
import {Table} from "ui";
import {Switch} from 'components/Form';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {connect} from 'react-redux';
import useHandleError from "utils/useHandleError";
import {SessionTabProps} from '../../types';
import coursesApiClient from "utils/coursesApiClient";
import './styles.scss'

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale,
    }
};

const SessionNotifications: React.FC<SessionTabProps> = ({lecture, session, reloadSession, locale}) => {
    const intl = useIntl();
    const [handleError] = useHandleError();
    const [scenarioUpdate, setScenarioUpdate] = useState(false);
    const [dataSource, setDataSource] = useState<any>([]);
    const [reload, setReload] = useState(false);

    const columns = [
        {
            key: 'title',
            title: intl.formatMessage({id: 'emailing.templates.template_name'}),
            render: (record: any) => {
                const translations = record.translations;
                let titleObject = translations.filter((el: any) => el.language === locale)[0];

                if (!titleObject) {
                    titleObject = translations[Object.keys(translations)[0]]
                }
                return titleObject.title
            }
        },
        {
            key: 'status',
            title: intl.formatMessage({id: 'emailing.notifications.allow_notifications'}),
            render: (_text: string, record: any) => {
                return <Switch onChange={() => handleSwitch(record, 'status')}
                               defaultChecked={record.status === MailScenarioStatusType.Active}/>

            }
        },
    ];

    const handleSwitch = async (record: any, type: 'status' | 'cc') => {
        let key: any = Object.entries(dataSource).filter((el: any) => el[1].scenarioId === record.scenarioId);
        try {
            let data;
            let url = '';

            switch (type) {
                case "cc":
                    data = !record.cc
                    dataSource[key[0][0]].cc = data
                    setDataSource(dataSource);

                    url = '/set-cc'
                    break;
                case "status":
                    data = record.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';
                    dataSource[key[0][0]].status = data
                    setDataSource(dataSource);

                    url = '/set-status'
                    break;
                default:
                    return;
            }

            setScenarioUpdate(true)

            await coursesApiClient.request(`/api/v1/mail-scenarios/${record.scenarioId}${url}`, {[type]: data}, 'POST');

            setScenarioUpdate(false)
        } catch (error) {
            handleError(error)
        }
    }

    return (
        <Table
            columns={columns}
            url={`/api/v1/lecture/session/${session.id}/scenarios`}
            setReload={setReload}
            reload={reload}
            manualDataUpdate={scenarioUpdate}
            updateData={dataSource}
            saveData={setDataSource}
            rowSelection={false}
            rowKey={'scenarioId'}
        />);
};

export default connect(mapStateToProps)(SessionNotifications);