import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, Button, Divider, Form, Input, message, Modal, Spin } from 'antd';
import { Store } from 'rc-field-form/lib/interface';
import { loadAppearanceSettings } from 'store/actions/appearanceSettingsActions';
import DefaultLayout from 'components/DefaultLayout';
import FileInputWithPreview from './FileInputWithPreview';
import NoSettingsFound from './NoSettingsFound';
import { Title } from 'components/Form';
import ColorPicker from 'components/ColorPicker';
import { AppearanceSettings, AppearanceSettingsData } from 'interfaces/redux';
import { EnvironmentType, CurrentImageState } from '../../types';
import apiClient from 'utils/apiClient';
import coursesApiClient from 'utils/coursesApiClient';
import {config} from 'config/config';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal;

const formLayout = {
  labelCol: { span: 6, offset: 2 },
  wrapperCol: { span: 6 },
};

const initialCurrentImageState: CurrentImageState = {
  environmentName: undefined,
  settingsId: undefined,
  path: undefined,
  fileName: undefined,
  file: undefined,
  isSet: false,
  isLoading: false,
};

const mapStateToProps = (state: any) => ({
  organization: state.session.organization,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  loadAppearanceSettings: (data: AppearanceSettingsData) => dispatch(loadAppearanceSettings(data)),
});

interface AppearanceSettingsTabProps {
  organization: undefined|{ organization_id: string, organization_type: string };
  loadAppearanceSettings: (data: AppearanceSettingsData) => void;
  type: EnvironmentType;
  selectedResellerId: number;
  selectedCustomerId: number;
}

const AppearanceSettingsTab: React.FC<AppearanceSettingsTabProps> = ({
  organization,
  loadAppearanceSettings,
  type,
  selectedResellerId,
  selectedCustomerId,
}) => {
  const [hasSettings, setHasSettings] = useState<boolean | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [currentLogo, setCurrentLogo] = useState(initialCurrentImageState);
  const [currentFavicon, setCurrentFavicon] = useState(initialCurrentImageState);

  const intl = useIntl();
  const [form] = Form.useForm();

  const typeIsResellerOrCustomer = (type === 'RESELLER' || type === 'CUSTOMER');

  const themeProperties = {
    global: [
      { key: 'themeColor', label: 'system.environment.theme.global.theme_color' },
      { key: 'themeBackground', label: 'system.environment.theme.global.theme_background' },
    ],
    navigation: [
      { key: 'navTextColor', label: 'system.environment.theme.navigation.text_color' },
      { key: 'navActiveTextColor', label: 'system.environment.theme.navigation.active_text_color' },
      { key: 'navBackgroundColor', label: 'system.environment.theme.navigation.background_color' },
      { key: 'navSubmenuBackgroundColor', label: 'system.environment.theme.navigation.submenu_background_color' },
      { key: 'navActiveBackgroundColor', label: 'system.environment.theme.navigation.active_background_color' },
    ],
  };

  const getUrl = useCallback(() => {
    const appearanceSettingsRoutes = config.api.routes.backend.appearanceSettings;

    switch (type) {
      case 'GLOBAL':
        return appearanceSettingsRoutes.global;
      case 'RESELLER':
        return appearanceSettingsRoutes.reseller.replace('{id}', (selectedResellerId).toString());
      case 'CUSTOMER':
        return appearanceSettingsRoutes.customer.replace('{id}', (selectedCustomerId).toString());
    }
  }, [type, selectedResellerId, selectedCustomerId]);

  const getSelectedId = useCallback(() => {
    switch (type) {
      case 'RESELLER':
        return selectedResellerId;
      case 'CUSTOMER':
        return selectedCustomerId;
    }
  }, [type, selectedResellerId, selectedCustomerId]);

  const getAppearanceSettingsData = useCallback(() => {
    if (organization) {
      if (organization.organization_type === 'ORGANIZATION') {
        return { resellerId: parseInt(organization.organization_id) }
      }

      return { customerId: parseInt(organization.organization_id) }
    }

    return {};
  }, [organization]);

  const currentSettingsAreSameAsSaved = useCallback(() => {
    if (type === 'GLOBAL' && !organization) {
      return true;
    }

    if (organization?.organization_id) {
      if (type === 'RESELLER'
        && organization?.organization_type === 'ORGANIZATION'
        && parseInt(organization.organization_id) === selectedResellerId
      ) {
        return true;
      }

      if (type === 'CUSTOMER'
        && organization?.organization_type === 'CUSTOMER'
        && parseInt(organization.organization_id) === selectedCustomerId
      ) {
        return true;
      }
    }

    return false;
  }, [type, organization, selectedResellerId, selectedCustomerId]);

  const getImageFile = useCallback(async (path: string) => {
    try {
      return await apiClient.request(`api/v1${path}`, {}, 'GET', true, true);
    } catch (error) {
      console.error(error);
      message.error(intl.formatMessage({ id: 'error.data_load' }));

      return undefined;
    }
  }, [intl]);

  const setValues = useCallback(async (response: AppearanceSettings) => {
    const setCurrentImage = async (path: string, type: string) => {
      // First regex extracts the filename, the second regex removes the timestamp
      const fileName = path
        .replace(/^.*[\\/]/, '')
        .replace(/^[^_]*_/, '');

      const attributes = {
        environmentName: response.title,
        settingsId: response.id,
        path: path,
        fileName: fileName,
        file: undefined,
        isSet: false,
        isLoading: true,
      };

      const setImage = type === 'logo' ? setCurrentLogo : setCurrentFavicon;

      setImage(attributes);

      await getImageFile(path).then((value) => attributes.file = value);

      attributes.isSet = true;
      attributes.isLoading = false;

      // Without spreading into a new object React does not detect that the object changed
      setImage({ ...attributes });
    };

    ['logo', 'favicon'].forEach((type) => {
      const path = type === 'logo' ? response.logoPath : response.faviconPath;

      if (path) {
        setCurrentImage(path, type);
      }
    });

    const theme = response.theme;

    form.setFieldsValue({
      title: response.title,
      logo: null,
      favicon: null,
      themeColor: theme.themeColor,
      themeBackground: theme.themeBackground,
      themeTextColor: theme.themeTextColor,
      navTextColor: theme.navTextColor,
      navActiveTextColor: theme.navActiveTextColor,
      navBackgroundColor: theme.navBackgroundColor,
      navSubmenuBackgroundColor: theme.navSubmenuBackgroundColor,
      navActiveBackgroundColor: theme.navActiveBackgroundColor,
    });
  }, [form, getImageFile]);

  const loadSettings = useCallback(async () => {
    try {
      setIsLoading(true);
      setHasSettings(undefined);

      const response = await apiClient.request(getUrl());

      if (!response.noAppearanceSettingsFound) {
        setHasSettings(true);
        await setValues(response);
      } else {
        setHasSettings(false);
      }
    } catch (error) {
      console.error(error);
      message.error(intl.formatMessage({ id: 'error.data_load' }));
    } finally {
      setIsLoading(false);
    }
  }, [intl, getUrl, setValues]);

  const fileInputWithPreviewProps = {
    currentSettingsAreSameAsSaved: currentSettingsAreSameAsSaved,
    getAppearanceSettingsData: getAppearanceSettingsData,
    loadAppearanceSettings: loadAppearanceSettings,
    initialState: initialCurrentImageState,
    loadSettings: loadSettings,
  };

  useEffect(() => {
    if (!typeIsResellerOrCustomer || (typeIsResellerOrCustomer && getSelectedId())) {
      loadSettings().then();
    }
  }, [loadSettings, selectedResellerId, selectedCustomerId, typeIsResellerOrCustomer, type, getSelectedId]);

  const loadBaseSettings = async () => {
    try {
      setIsLoading(true);

      const url = config.api.routes.backend.appearanceSettings.base;
      const response = await apiClient.request(url);

      await setValues(response);

      message.success(`${intl.formatMessage({ id: 'system.environment.base_settings_loaded' })}!`);
    } catch (error) {
      console.error(error);
      message.error(intl.formatMessage({ id: 'error.data_load' }));
    } finally {
      setIsLoading(false);
    }
  };

  const submitForm = async (values: Store) => {
    const parsedAppearanceSettings: { [key: string]: any } = {
      type: type,
      title: values.title,
      logo: values.logo ? values.logo[0].originFileObj : null,
      favicon: values.favicon ? values.favicon[0].originFileObj : null,
      theme: {
        themeColor: values.themeColor,
        themeBackground: values.themeBackground,
        themeTextColor: values.themeTextColor,
        navTextColor: values.navTextColor,
        navActiveTextColor: values.navActiveTextColor,
        navBackgroundColor: values.navBackgroundColor,
        navSubmenuBackgroundColor: values.navSubmenuBackgroundColor,
        navActiveBackgroundColor: values.navActiveBackgroundColor,
      },
    };

    const formData = new FormData();

    Object.keys(parsedAppearanceSettings).forEach((key) => {
      const value = parsedAppearanceSettings[key];

      if (value !== null) {
        formData.append(key, key === 'theme' ? JSON.stringify(value): value);
      }
    });

    try {
      setIsSubmitting(true);

      await coursesApiClient.request(getUrl(), formData, 'POST', true, true, {}, 'json');

      message.success(`${intl.formatMessage({ id: 'system.environment.settings_updated' })}!`);

      if (currentSettingsAreSameAsSaved()) {
        loadAppearanceSettings(getAppearanceSettingsData());
      }

      await loadSettings();
    } catch (error) {
      console.error(error);
      message.error(intl.formatMessage({ id: 'error.data_load' }));
    } finally {
      setIsSubmitting(false);
    }
  };

  const showDeleteConfirm = () => {
    const deleteAppearanceSettings = async () => {
      try {
        setIsDeleting(true);

        await apiClient.request(getUrl(), {}, 'DELETE');

        message.success(`${intl.formatMessage({ id: 'system.environment.settings_deleted' })}!`);

        if (currentSettingsAreSameAsSaved()) {
          loadAppearanceSettings(getAppearanceSettingsData());
        }

        await loadSettings();
      } catch (error) {
        console.error(error);
        message.error(intl.formatMessage({ id: 'error.data_load' }));
      } finally {
        setIsDeleting(false);
      }
    };

    confirm({
      title: intl.formatMessage({ id: 'system.environment.settings_deletion_confirmation' }),
      icon: <ExclamationCircleOutlined />,
      okText: intl.formatMessage({ id: 'general.yes' }),
      cancelText: intl.formatMessage({ id: 'general.no' }),
      onOk: deleteAppearanceSettings,
    });
  };

  const renderColorPicker = (property: { key: string, label: string }) => (
    <ColorPicker
      key={property.key}
      initialColor={form.getFieldValue(property.key)}
      form={form}
      name={property.key}
      label={intl.formatMessage({ id: property.label })}
    />
  );

  return (
    <>
      <Spin spinning={isLoading}>
        <div style={{ minHeight: 50 }}>
          {(typeIsResellerOrCustomer && !getSelectedId())
            ? (
              <Alert
                message={intl.formatMessage({ id: `system.environment.please_select_a_${type === 'RESELLER' ? 'reseller' : 'customer'}` })}
                type="info"
                showIcon
              />
            )
            : (
              <div style={{ marginTop: 8, padding: '0 16px' }}>
                {hasSettings === false && (
                  <NoSettingsFound
                    currentSettingsAreSameAsSaved={currentSettingsAreSameAsSaved}
                    getAppearanceSettingsData={getAppearanceSettingsData}
                    loadAppearanceSettings={loadAppearanceSettings}
                    getUrl={getUrl}
                    loadSettings={loadSettings}
                  />
                )}
                {hasSettings === true && (
                  <Form
                    form={form}
                    onFinish={submitForm}
                    {...formLayout}
                  >
                    <Form.Item
                      name="title"
                      label={intl.formatMessage({ id: 'system.environment.name' })}
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </Form.Item>

                    <Divider />

                    <FileInputWithPreview
                      {...fileInputWithPreviewProps}
                      name="logo"
                      label={intl.formatMessage({ id: 'system.environment.logo' })}
                      previewState={currentLogo}
                      setPreviewState={setCurrentLogo}
                    />

                    <Divider />

                    <FileInputWithPreview
                      {...fileInputWithPreviewProps}
                      name="favicon"
                      label={intl.formatMessage({ id: 'system.environment.favicon' })}
                      previewState={currentFavicon}
                      setPreviewState={setCurrentFavicon}
                    />

                    <Divider />

                    <Title align="center">{intl.formatMessage({ id: 'general.global' })}</Title>
                    {themeProperties.global.map((property) => renderColorPicker(property))}

                    <Divider />

                    <Title align="center">{intl.formatMessage({ id: 'system.environment.navigation' })}</Title>
                    {themeProperties.navigation.map((property) => renderColorPicker(property))}
                  </Form>
                )}
              </div>
            )
          }
        </div>
      </Spin>

      {hasSettings && (
        <DefaultLayout.PageFooter
          left={
            <>
              <Button
                disabled={isLoading || isSubmitting || isDeleting}
                onClick={loadBaseSettings}
              >
                <span><FormattedMessage id="system.environment.load_base_settings" /></span>
              </Button>
              {typeIsResellerOrCustomer && (
                <Button
                  disabled={isLoading || isDeleting}
                  onClick={showDeleteConfirm}
                  danger
                >
                  <span><FormattedMessage id="system.environment.delete_settings" /></span>
                </Button>
              )}
            </>
          }
          right={
            <Button
              type="primary"
              disabled={isLoading || isDeleting}
              loading={isSubmitting}
              onClick={form.submit}
            >
              <span><FormattedMessage id="general.save" /></span>
            </Button>
          }
        />
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AppearanceSettingsTab);