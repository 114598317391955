import React, { useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button, Empty } from 'antd';
import { connect } from 'react-redux';
import { FileTableExtended } from 'ui';
import {useDate} from 'hooks/useDate';
import './styles.scss';


interface AssignmentDetailInterface {
    activityFormData: any;
    assignmentSettings: any;
    submission: any;
    files: any;
    session?: any;
    getStatusTag: Function;
    setActiveTabKey: React.Dispatch<React.SetStateAction<string>>;
    disableSubmit: boolean;
}
const mapStateToProps = (state: any) => ({
    session: state.session,
});

const AssignmentDetail: React.FC<AssignmentDetailInterface> = ({
    activityFormData,
    assignmentSettings,
    submission,
    files,
    session, 
    getStatusTag,
    setActiveTabKey,
    disableSubmit
}) => {
    const intl = useIntl();
    const [formatedFiles, setFormatedFiles] = useState<any>();
    const {formatDate} = useDate();

    useEffect(() => {
        if (files && files.length > 0) {
            const filesArray = Array.isArray(files) ? files : Object.values(files);
            const formatedFiles = filesArray.map((file: any) => {
                file.date = formatDate(file.date);
                return file;
            });
            
            setFormatedFiles(formatedFiles)
        }
    }, [files])

    return (
        <div className='assignment-detail'>
            <div className='assignment-section'>
                <div className='assignment-title-bar'>
                    <div className='flex align-center flex-wrap'>
                        <div>
                            <div className='assignment-title flex flex-wrap'>
                                <h3>{assignmentSettings.title ? <>{assignmentSettings.title}</> : <FormattedMessage id='courses.individual_work' />}</h3>
                                <span className='status-bar-value'>
                                    {getStatusTag()}
                                </span>
                            </div>
                            <div className='assignment-subtitle flex'>
                                <span className='status-bar-value'>
                                    <FormattedMessage id='courses.due_date' />:
                                </span>
                                <span className='status-bar-value'>
                                    {formatDate(submission.deadline)}
                                </span>
                            </div>
                        </div>
                        {!disableSubmit && <Button className='toggle-submission' type='primary' onClick={() => setActiveTabKey('assignment_submission')}>
                            <FormattedMessage id={assignmentSettings && !!assignmentSettings.file_upload ? 'courses.add_submission' : 'courses.confirm_read'} />
                        </Button>}
                    </div>
                </div>
                <div className='assignment-description' dangerouslySetInnerHTML={{ __html: assignmentSettings.description }}></div>
            </div>
            <div className='assignment-section'>
                <div className='assignment-title-bar'>
                    <div className='assignment-title flex'>
                        <h3><FormattedMessage id='general.files' /></h3>
                    </div>
                </div>
                <div className='resource-container'>
                    {formatedFiles && !!formatedFiles.length ? <FileTableExtended 
                        key={'assignment_materials'} 
                        fileArray={formatedFiles} 
                        showMenu={false} 
                        allowDelete={false} 
                        showLabels={false}
                        dateBelowTitle={true}
                        cols={['title', 'date', 'type', 'size']}
                        urls={{
                            download: `api/v1/courses/material/storage/{path}`,
                            delete: `/api/v1/courses/assignment-results/delete-file/${activityFormData.programActivityAttempt.id}`
                        }}
                        theme='with-bg'
                    /> : <Empty
                        description={intl.formatMessage({id: 'general.no_uploads'})}
                    />}
                </div>
            </div>
        </div >
    )
}
export default connect(mapStateToProps)(AssignmentDetail);