import React, {useState} from 'react';
import {connect} from "react-redux";
import Spinner from "../../components/Spinner";
import DefaultLayout from 'components/DefaultLayout';
import {Button, Search, Table} from "ui";
import {Progress, Tag, Space, Card} from "antd";
import {FormattedMessage, useIntl} from "react-intl";
import './styles.scss';
import moment from "moment";
import {config} from "../../config/config";
import {FilterModal} from "../../components/Modals";
import useQueryApiClient from "../../utils/useApiClient";
import FileDownload from "js-file-download";
import generalHelpers from "../../utils/generalHelpers";
import FlexRow from 'components/FlexRow';

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale,
        session: state.session
    }
}

const tagColors = {
    NOT_STARTED: 'gold',
    IN_PROGRESS: 'blue',
    COMPLETED: 'green',
    MISSED: 'red',
    CANCELLED: 'red',
    PLANNED: 'geekblue',
    ATTENDED: 'green',
    VALID: 'green',
    NOT_VALID: 'red',
}

const MyDepartment: React.FC<any> = ({locale, session}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [reload, setReload] = useState(false);
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState('');
    const [visibleTooltip, setVisibleTooltip] = useState<number | null>(null);
    const [showDepartmentFilterModal, setShowDepartmentFilterModal] = useState(false);
    const [dataSource, setDataSource] = useState<any>([]);
    const [scenarioUpdate, setScenarioUpdate] = useState(false);
    const intl = useIntl();

    const {appendData} = useQueryApiClient({
        request: {
            disableOnMount: true,
            url: '/api/v1/courses/learning-program/:id/student-programs',
        },
        onSuccess: (response, passOnSuccess) => {
            setScenarioUpdate(true)
            if (passOnSuccess.expanded) {
                let key: any = Object.entries(dataSource).filter((el: any) => el[1].id === passOnSuccess.record.id);
                dataSource[key[0][0]].data = response
                setDataSource(dataSource);
                setScenarioUpdate(false)
            }
        }
    });

    const innerExpandable = {
        onExpand: (expanded: boolean, record: any) => appendData(filter, {'id': record.id}, {expanded, record}),
        expandedRowRender: (record: any) => {
            if (record?.data) {
                return (
                    <div className={'programs-inner-wrapper'}>
                        {Object.values(record.data).map((data: any) => {
                            return <div className={'flex flex-between h-14 p-6 program-row'}>
                                <div className={'w-70 flex'}>
                                    <div
                                        className={'title'}>{data.title[locale] ? data.title[locale] : data.title[Object.keys(data.title)[0]]}</div>
                                    {/*todo: add correct percent*/}
                                    <Progress percent={0} size="small" showInfo={false} strokeColor={'#87d068'}
                                              style={{width: 100}}/>
                                </div>
                                <div
                                    className={(data?.deadline ? moment(data.deadline).startOf('date').isBefore(moment().endOf('date')) : moment(getDeadline(data)).startOf('date').isBefore(moment().endOf('date'))) ? 'red' : ''}>
                                    <Space size={24}>
                                        {
                                            data.status !== 'COMPLETED' &&
                                            <>
                                                {data.deadline ?
                                                    <FormattedMessage id={"student.deadline"}
                                                                      values={{date: moment(data.deadline).format('DD.MM.YYYY')}}/>
                                                    :
                                                    <FormattedMessage id={"student.deadline"}
                                                                      values={{date: moment(getDeadline(data)).format('DD.MM.YYYY')}}/>
                                                }
                                            </>
                                        }
                                        <span>{programTagBuilder(data.status, false, data)}</span>
                                    </Space>
                                </div>

                            </div>
                        })}
                    </div>
                )
            }
            return <Spinner spinning={true}/>

        },
    };

    const getDeadline = (record: any) => {
        let enrollDate;

        switch (record.type) {
            case ('TIMED_EVENT'):
                let deadline;

                if (moment(record.createdAt) > moment(record.beginDate)) {
                    enrollDate = moment(record.createdAt);
                } else {
                    enrollDate = moment(record.beginDate);
                }

                if (moment(enrollDate).add(record.maxCompletionTerm, 'days') > moment(record.endDate)) {
                    deadline = moment(enrollDate).add(record.termLimitValue, 'days');
                } else {
                    deadline = moment(enrollDate).add(record.maxCompletionTerm, 'days');
                }

                return deadline.format(config.defaultDateFormat);
            case ('CERTIFICATION'):

                if (moment(record.createdAt) > moment(record.beginDate)) {
                    enrollDate = moment(record.createdAt)
                } else {
                    enrollDate = moment(record.beginDate)
                }

                return moment(enrollDate).add(record.maxCompletionTerm, 'days').format(config.defaultDateFormat);
        }
    }

    const tagBuilder = (status: 'NOT_STARTED' | 'IN_PROGRESS' | 'COMPLETED' | 'MISSED' | 'CANCELLED' | 'VALID' | 'NOT_VALID' | null, isCancelled = false) => {

        let tagStatus = status ? status : 'NOT_STARTED'

        if (isCancelled) {
            tagStatus = 'CANCELLED';
        }

        return (
            <Tag color={tagColors[tagStatus]}>
                {intl.formatMessage({id: `general.${tagStatus.toLowerCase()}`})}
            </Tag>
        )
    }

    const programTagBuilder = (status: 'NOT_STARTED' | 'IN_PROGRESS' | 'COMPLETED' | 'MISSED' | 'CANCELLED' | 'VALID' | 'NOT_VALID' | null, isCancelled = false, data: any = []) => {
        let tagStatus = status ? status : 'NOT_STARTED'

        if (isCancelled) {
            tagStatus = 'CANCELLED';
        }

        if (data &&
            data?.status !== 'COMPLETED' &&
            data?.status !== 'FAILED' &&
            (data?.deadline ? moment(data.deadline).isBefore(moment()) : moment(getDeadline(data)).isBefore(moment()))) {
            tagStatus = 'MISSED'
        }

        return (
            <Tag color={tagColors[tagStatus]}>
                {intl.formatMessage({id: `general.${tagStatus.toLowerCase()}`})}
            </Tag>
        )
    }
    const columns = [
        {
            title: intl.formatMessage({id: 'general.name'}),
            sorter: true,
            dataIndex: 'name'
        },
        {
            title: intl.formatMessage({id: 'general.surname'}),
            sorter: true,
            dataIndex: 'surname'
        },
        {
            title: intl.formatMessage({id: 'general.email'}),
            dataIndex: 'email'
        },
        {
            title: intl.formatMessage({id: 'general.position'}),
            dataIndex: 'position',
            render: (_text: string, record: any) => record.position ? _text : '-',
        },
        {
            title: intl.formatMessage({id: 'general.department'}),
            sorter: true,
            dataIndex: 'department',
            render: (_text: string, record: any) => record.department ? _text : '-',
        },
        {
            title: intl.formatMessage({id: 'general.organizational_unit'}),
            sorter: true,
            dataIndex: 'organizationalUnit',
            render: (_text: string, record: any) => record.organizationalUnit ? _text : '-',
        },
        {
            title: intl.formatMessage({id: 'general.organization'}),
            sorter: true,
            dataIndex: 'organizationName',
            render: (_text: string, record: any) => record.organizationName ? _text : '-',
        },
        {
            title: intl.formatMessage({id: 'general.status'}),
            render: (_text: string, record: any) => tagBuilder(record.learningStatus)
        },
        {
            title: intl.formatMessage({id: 'general.last_activity'}),
            dataIndex: 'lastActivity',
            render: (deadline: string, record: any) => record.lastActivity ? moment(record.lastActivity).format('DD.MM.YYYY h:mm') : ''
        },
        {
            render: (_: string, record: any) => (
                <div onClick={() => exportPrograms([], {'id': record.id}, {record})} className={'hyperlink-tag pointer'}>
                    <FormattedMessage id={'general.export'}/>
                </div>
            )
        },
    ];

    const {appendData: exportPrograms} = useQueryApiClient({
        request: {
            url: '/api/v1/courses/department/:id/export',
            multipart: true,
            disableOnMount: true,
        },
        onSuccess: (response, passOnSuccess) => {
            FileDownload(response, generalHelpers.replaceCharacters(passOnSuccess.record.fullName) + '_' + moment().format('MM.DD.YYYY') + '.xlsx');
        },
    });

    const {appendData: handleProgramExport, isLoading: prepareExport} = useQueryApiClient({
        request: {
            url: '/api/v1/courses/department/export' + (filter ? ('?' + filter) : ''),
            multipart: true,
            disableOnMount: true,
        },
        onSuccess: (response) => {
            FileDownload(response, `${session.name}_${session.surname}_department_ ${moment().format('DD_MM_YY')}.xlsx`);
        },
    });

    return (
        <Spinner spinning={isLoading}>
            <DefaultLayout.PageLayout withoutPageLayout>
                <div className="program-list-wrapper">
                    <Card>
                    <FlexRow
                            left={
                                <>
                                    <Button
                                        type="default"
                                        onClick={() => setShowDepartmentFilterModal(true)}
                                    >
                                        <FormattedMessage id="general.filter" />
                                    </Button>
                                    <Button
                                        loading={prepareExport}
                                        type="default"
                                        onClick={() => handleProgramExport()}
                                    >
                                        <FormattedMessage id="general.export" />
                                    </Button>
                                </>
                            }
                            right={<Search setReload={setReload} setSearchString={setSearch} />}
                        />
                        <div className={'table-filter-modal'}>
                            {session?.organization &&
                                <FilterModal
                                    visible={showDepartmentFilterModal}
                                    onCancel={setShowDepartmentFilterModal}
                                    title={'general.filter_by'}
                                    okProp={'general.filter_students'}
                                    modules={{
                                        directManagerDepartments: {mode: 'multiple', visible: true},
                                        directManagerOrganizationalUnits: {mode: 'multiple', visible: true},
                                        directManagerPrograms: {mode: 'multiple', visible: true},
                                        directManagerProgramStatus: {mode: 'multiple', visible: true},
                                        delayed: {default: false, visible: true},
                                    }}
                                    setFilter={setFilter}
                                    filter={filter}
                                    load={setReload}
                                    locale={locale}
                                />
                            }
                        </div>

                        <div className={'my-department-outer-table'}>
                            <Table
                                expandable={innerExpandable}

                                columns={columns}
                                url={'/api/v1/subordinate/students'}
                                setReload={setReload}
                                reload={reload}
                                rowKey={'id'}
                                filter={filter}
                                search={search}
                                searchKey={'searchOptions'}

                                manualDataUpdate={scenarioUpdate}
                                updateData={dataSource}
                                saveData={setDataSource}

                                rowSelection={false}
                                isLoading={isLoading}
                                scroll={{x: 800}}
                            />
                        </div>
                    </Card>
                </div>
            </DefaultLayout.PageLayout>
        </Spinner>
    )
}
export default connect(mapStateToProps)(MyDepartment);