export const useMail = () => {

    const getCategoryMailable = (categories: any, categoryId: any, mailableArray: any) => {
        let mailable: any = [];

        switch (categories[categoryId]) {
            case 'system_events':
                mailable =
                    {
                        [mailableArray.indexOf('LicenceEndNotification')]: 'LicenceEndNotification',
                        [mailableArray.indexOf('NewUserRegistrationMail')]: 'NewUserRegistrationMail',
                        [mailableArray.indexOf('PasswordResetMail')]: 'PasswordResetMail',
                        [mailableArray.indexOf('SelfOnBoardingMail')]: 'SelfOnBoardingMail',
                        [mailableArray.indexOf('WelcomeMail')]: 'WelcomeMail'
                    }
                break
            case 'learning_program':
                mailable =
                    {
                        [mailableArray.indexOf('NotificationAboutRecertificationProcess')]: 'NotificationAboutRecertificationProcess',
                        [mailableArray.indexOf('ProgramCertificate')]: 'ProgramCertificate',
                        [mailableArray.indexOf('ProgramCertificateWithAttachment')]: 'ProgramCertificateWithAttachment',
                        [mailableArray.indexOf('ProgramEnroll')]: 'ProgramEnroll',
                        [mailableArray.indexOf('ProgramReminder')]: 'ProgramReminder',
                        [mailableArray.indexOf('ProgramDelay')]: 'ProgramDelay',
                        [mailableArray.indexOf('RecertificationMail')]: 'RecertificationMail',
                    }
                break
            case 'evaluation':
                mailable =
                    {
                        [mailableArray.indexOf('AssignmentEvaluatedNotification')]: 'AssignmentEvaluatedNotification',
                        [mailableArray.indexOf('AssignmentSubmittedNotification')]: 'AssignmentSubmittedNotification',
                    }
                break
            case 'audiences':
                mailable =
                    {
                        [mailableArray.indexOf('AudienceMembershipReport')]: 'AudienceMembershipReport',
                    }
                break
            case 'lectures':
                mailable =
                    {
                        [mailableArray.indexOf('FinishedSessionReminder')]: 'FinishedSessionReminder',
                        [mailableArray.indexOf('LectorEnrolledToSession')]: 'LectorEnrolledToSession',
                        [mailableArray.indexOf('MissedSessionReminder')]: 'MissedSessionReminder',
                        [mailableArray.indexOf('SessionCanceledNotification')]: 'SessionCanceledNotification',
                        [mailableArray.indexOf('SessionChangedNotification')]: 'SessionChangedNotification',
                        [mailableArray.indexOf('SessionIncreaseCapacityNotification')]: 'SessionIncreaseCapacityNotification',
                        [mailableArray.indexOf('StudentEnrolledToSession')]: 'StudentEnrolledToSession',
                        [mailableArray.indexOf('UpcomingSessionReminder')]: 'UpcomingSessionReminder',
                    }
                break
            case 'phishing':
                mailable =
                    {
                        [mailableArray.indexOf('GophishMailReported')]: 'GophishMailReported',
                        [mailableArray.indexOf('NewSubscriptionInquiry')]: 'NewSubscriptionInquiry',
                        [mailableArray.indexOf('PhishingFailManagerNotification')]: 'PhishingFailManagerNotification',
                        [mailableArray.indexOf('PhishingFailStudentNotification')]: 'PhishingFailStudentNotification',
                        [mailableArray.indexOf('PhishingMailReported')]: 'PhishingMailReported',
                        [mailableArray.indexOf('PhishingStatusEscalationMail')]: 'PhishingStatusEscalationMail',
                        [mailableArray.indexOf('PhishingStatusReportMail')]: 'PhishingStatusReportMail',
                    }
                break
            case 'other':
                mailable =
                    {
                        [mailableArray.indexOf('CertificationDeliveryMail')]: 'CertificationDeliveryMail',
                        [mailableArray.indexOf('CourseCompletion')]: 'CourseCompletion',
                        [mailableArray.indexOf('CourseCompletionWithAttachment')]: 'CourseCompletionWithAttachment',
                        [mailableArray.indexOf('EnrollmentIntoCourse')]: 'EnrollmentIntoCourse',
                        [mailableArray.indexOf('LearningProgressEscalationMail')]: 'LearningProgressEscalationMail',
                        [mailableArray.indexOf('LearningProgressReportMail')]: 'LearningProgressReportMail',
                        [mailableArray.indexOf('NewCourseAssignedMail')]: 'NewCourseAssignedMail',
                    }
                break
            case 'academy':
                mailable =
                    {
                        [mailableArray.indexOf('AcademyCompletion')]: 'AcademyCompletion',
                        [mailableArray.indexOf('AcademyEnroll')]: 'AcademyEnroll',
                        [mailableArray.indexOf('AcademyReminder')]: 'AcademyReminder',
                    }
                break
        }
        return mailable;
    }

    return {
        getCategoryMailable,
    }
}