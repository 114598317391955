import React from 'react';

import ScenariosForm from 'components/ScenariosForm';
import DefaultLayout from 'components/DefaultLayout';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

const EmailScenarioForm: React.FC<any> = () => {

  const intl = useIntl();
  const params = useParams();

  const scenarioId = params.id;

  return (
    <>
      <DefaultLayout.PageLayout withStickyFooter>
        <DefaultLayout.PageHeader
          breadcrumb={[{name: intl.formatMessage({id: 'emailing.scenarios.e_mailing_center'}), path: '/e-mailing/email-scenarios'}]}
          title={intl.formatMessage({id: scenarioId ? 'emailing.scenarios.edit_mail_scenario' : 'emailing.scenarios.send_new_e_mail'})}
        />
        <ScenariosForm/>
      </DefaultLayout.PageLayout>
     
    </>
    )
}

export default EmailScenarioForm;
