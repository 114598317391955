import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Form, message, Modal } from 'antd';

import { CustomerSelect } from 'components/Form';
import coursesApiClient from 'utils/coursesApiClient';
import {config} from 'config/config';
import './styles.scss';

interface ServicesFilterModalInterface {
    visible: boolean;
    onCancel: any;
    setServices:any;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const ServicesFilterModal: React.FC<ServicesFilterModalInterface> = ({ visible, onCancel, setServices, setIsLoading }) => {
    const [organizationType, setOrganizationType] = useState('');

    const [form] = Form.useForm();
    const intl = useIntl();

    const submitForm = async (values: any) => {
        setIsLoading(true);
        onCancel();
        try {
            let response = await coursesApiClient.request(`/api/v1/services/filter/${values.organization}/${organizationType}`, {}, 'GET');
            setServices(response.services);
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Modal
            className='add-new-course'
            open={visible}
            onCancel={onCancel}
            title={intl.formatMessage({ id: 'courses.add_new_course' })}
            okText={intl.formatMessage({ id: 'general.filter' })}
            cancelText={intl.formatMessage({ id: 'general.back' })}
            onOk={form.submit}
        >
            <Form form={form} onFinish={submitForm}>
                <CustomerSelect
                    saveLoadedData={false}
                    showSearch
                    name='organization'
                    label={intl.formatMessage({ id: 'general.reseller' })}
                    customRules={[{
                        required: true,
                        message: intl.formatMessage({ id: 'validation.field_required' })
                    }]}
                    url={config.api.routes.backend.organizationsOptions}
                    integerKey={true}
                    onChange={(_value: string, options: any) => {
                        setOrganizationType(options.customerType);
                        form.setFieldsValue({
                            organizationalUnitId: [],
                            customer: []
                        });
                    }}
                    isResponseArray={true}
                    dataKey='organizations'
                    mapDataEntries={(el: any) => ({
                        value: el.uuid,
                        label: el.name,
                        customerType: el.type,

                    })}
                    valueKey='value'
                    labelKey='label'
                    customLayout={true}
                    filterOption={(input: string, option: any) => {
                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                />
            </Form>
        </Modal>
    );
}

export default ServicesFilterModal;
