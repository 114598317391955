import React from 'react';
import {useIntl} from 'react-intl';
import {Form, Select} from 'antd';

const formItemLayout = {
    labelCol: {span: 8},
    wrapperCol: {span: 6},
};

interface IconSelectInterface {
    name: string[] | string;
    customRules?: any
    label: any
    disabled?: boolean
}

const IconSelect: React.FC<IconSelectInterface> = ({name, customRules = {}, label, disabled = false}) => {
    const {Option} = Select;
    const intl = useIntl();

    return (
        <Form.Item
            {...formItemLayout}
            label={label}
            name={name}
            rules={customRules}
        >
            <Select disabled={disabled} dropdownMatchSelectWidth={false}>
                <Option value="fa-question-circle" className="itemStyle"><i
                    className='fal fa-question-circle header-item'/></Option>
                <Option value="fa-home" className="itemStyle"><i className='fal fa-home header-item'/></Option>
                <Option value="fa-alarm-clock" className="itemStyle"><i
                    className='fal fa-alarm-clock header-item'/></Option>
                <Option value="fa-ambulance" className="itemStyle"><i
                    className='fal fa-ambulance header-item'/></Option>
                <Option value="fa-analytics" className="itemStyle"><i
                    className='fal fa-analytics header-item'/></Option>
                <Option value="fa-atlas" className="itemStyle"><i className='fal fa-atlas header-item'/></Option>
                <Option value="fa-biohazard"><i className='fal fa-biohazard header-item'/></Option>
                <Option value="fa-cogs" className="itemStyle"><i className='fal fa-cogs header-item'/></Option>
                <Option value="fa-coins" className="itemStyle"><i className='fal fa-coins header-item'/></Option>
                <Option value="fa-shield-alt" className="itemStyle"><i
                    className='fal fa-shield-alt header-item'/></Option>
                <Option value="fa-eye" className="itemStyle"><i className='fal fa-eye header-item'/></Option>
                <Option value="fa-print-search" className="itemStyle"><i
                    className='fal fa-print-search header-item'/></Option>
                <Option value="fa-fire-alt" className="itemStyle"><i className='fal fa-fire-alt header-item'/></Option>
                <Option value="fa-id-card"><i className='fal fa-id-card header-item'/></Option>
                <Option value="fa-knife-kitchen" className="itemStyle"><i className='fal fa-knife-kitchen header-item'/></Option>
                <Option value="fa-mouse-pointer" className="itemStyle"><i className='fal fa-mouse-pointer header-item'/></Option>
                <Option value="fa-pencil" className="itemStyle"><i className='fal fa-pencil header-item'/></Option>
                <Option value="fa-shield-check" className="itemStyle"><i
                    className='fal fa-shield-check header-item'/></Option>
                <Option value="fa-calendar-alt" className="itemStyle"><i
                    className='fal fa-calendar-alt header-item'/></Option>
                <Option value="fa-tachometer-alt-fastest" className="itemStyle"><i
                    className='fal fa-tachometer-alt-fastest header-item'/></Option>
                <Option value="fa-list"><i className='fal fa-list header-item'/></Option>
                <Option value="fa-traffic-light" className="itemStyle"><i
                    className='fal fa-traffic-light-stop header-item'/></Option>
                <Option value="fa-unlock" className="itemStyle"><i className='fal fa-unlock header-item'/></Option>
                <Option value="fa-user-graduate" className="itemStyle"><i className='fal fa-user-graduate header-item'/></Option>
                <Option value="fa-chart-network" className="itemStyle"><i className='fal fa-chart-network header-item'/></Option>
                <Option value="fa-chart-pie-alt" className="itemStyle"><i className='fal fa-chart-pie-alt header-item'/></Option>
                <Option value="fa-user-shield" className="itemStyle"><i
                    className='fal fa-user-shield header-item'/></Option>
                <Option value="fa-shovel"><i className='fal fa-shovel header-item'/></Option>
                <Option value="fa-wrench" className="itemStyle"><i className='fal fa-wrench header-item'/></Option>
            </Select>
        </Form.Item>
    )
}

export default IconSelect;
