import {AudienceTabProps} from 'components/Audiences/types';
import React, {useState} from 'react';
import {Table} from "../../../../ui";
import audience from "../../../../pages/Audiences/Audience";
import moment from "moment";
import {useIntl} from "react-intl";

const EventFeedTab: React.FC<AudienceTabProps> = ({audience}) => {

    const intl = useIntl();
    const [reload, setReload] = useState(false);

    const columns = [
        {
            title: intl.formatMessage({id: 'general.event_type'}),
            dataIndex: 'event_type'
        },
        {
            title: intl.formatMessage({id: 'general.event_subject_type'}),
            dataIndex: 'event_subject_type'
        },
        {
            title: intl.formatMessage({id: 'general.event_object_type'}),
            dataIndex: 'event_object_type'
        },
        {
            title: intl.formatMessage({id: 'general.event_initiator_type'}),
            dataIndex: 'event_initiator_type'
        },
        {
            title: intl.formatMessage({id: 'general.event_initiator'}),
            dataIndex: 'fullName'
        },
        {
            title: intl.formatMessage({id: 'general.created_at'}),
            render: (value: string, record: any) => moment(record.created_at).format('DD.MM.YYYY HH:mm:ss')
        },
    ];

    return (
        <Table
            columns={columns}
            url={`api/v1/audiences/${audience.id}/events`}
            setReload={setReload}
            rowSelection={false}
            reload={reload}
            rowKey={'id'}
            scroll={{x: 800}}
        />
    );
};

export default EventFeedTab;