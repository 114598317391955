import React from 'react';
import { useIntl } from 'react-intl';
import { Form, FormInstance, message } from 'antd';
import coursesApiClient from 'utils/coursesApiClient';
import { FileUpload } from 'ui';

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

interface FileFormInterface {
    language: string;
    form: FormInstance;
    activityId: number;
    onCancel: any;
    reload: Function;
}

const FileForm: React.FC<FileFormInterface> = ({
    language,
    form,
    activityId,
    onCancel,
    reload
}) => {

    const intl = useIntl();

    const submitForm = async (values: any) => {
        try {

            let bodyFormData = new FormData();

            bodyFormData.append('type', 'FILE');
            bodyFormData.append('language', language);

            const fileList = values.materials;

            for (let i = 0; i < fileList.length; i++) {
                if (fileList[i].originFileObj) {
                    bodyFormData.append('file_' + i, fileList[i].originFileObj);
                } else {
                    bodyFormData.append('file_' + i, fileList[i]);
                }
            }

            await coursesApiClient.request(`/api/v1/courses/material/${activityId}/add-material`, bodyFormData, 'POST');

            onCancel();
            form.resetFields();
            reload(activityId);
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        }
    }

    return (
        <Form form={form} onFinish={submitForm}>
            <FileUpload multiple={true} layout={formItemLayout} name="materials" label="courses.activity_files" buttonLabel='general.upload_files' />
        </Form>
    )
}
export default FileForm;
