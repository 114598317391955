import React, {useState, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Form, message, Modal, Tooltip} from 'antd';
import apiClient from 'utils/apiClient';
import {connect} from 'react-redux';
import {Input, Select} from 'components/Form';
import Spinner from "components/Spinner";
import {InstructionCreateFormType, LanguageArrayType} from '../types';
import './styles.scss'

const mapStateToProps = (state: any) => {
    return {
        session: state.session,
        locale: state.locale.locale
    }
};

const formItemLayout = {
    labelCol: {span: 8},
    wrapperCol: {span: 12},
};

const VideoInstructionsCreateModal = ({visible, onCancel, instructionId = null, locale, afterSubmit, positions, reloadInstructions}: any) => {
    const [languageList, setLanguageList] = useState<any>({});
    const [selectedLanguage, setSelectedLanguage] = useState<any>();
    const [instructionFieldTitle, setInstructionFieldTitle] = useState<any>();
    const [instructionFieldVideoUrl, setInstructionFieldVideoUrl] = useState<any>();
    const [instruction, setInstruction] = useState<any>();
    const [instructionLanguages, setInstructionLanguages] = useState<any>([]);
    const [instructionSettingsLoading, setInstructionSettingsLoading] = useState(false);
    const [displayLength, setDisplayLength] = useState(false);

    const intl = useIntl();
    const [form] = Form.useForm();

    useEffect(() => {
        const loadLanguages = async () => {
            let languages = await apiClient.request(`/api/v1/language`, {}, 'GET');
            let languageListForSelect: any = {};

            Object.values(languages.languages).map((value: any) => {
                return languageListForSelect[value['code']] = value['name'];
            });

            setLanguageList(languageListForSelect);
        };

        loadLanguages();
    }, [intl]);

    useEffect(() => {
        const loadInstruction = async () => {
            try {
                setInstructionSettingsLoading(true);
                const instruction = await apiClient.request(`/api/v1/instruction/${instructionId}/${locale}`, [], 'GET');

                setInstructionLanguages(instruction.instruction.languages);

                setInstructionFieldTitle(instruction.instruction.title);
                setInstructionFieldVideoUrl(instruction.instruction.videoUrl);
                setDisplayLength(true);

                form.setFieldsValue({
                    position: instruction.instruction.position.toString(),
                    videoUrl: instruction.instruction.videoUrl,
                    title: instruction.instruction.title,
                    videoLength: instruction.instruction.videoLength,
                    language: instruction.instruction.language
                });

                setInstruction(instruction.instruction);

                setInstructionSettingsLoading(false);
            } catch (error) {
                console.error(error);
                message.error(intl.formatMessage({id: 'error.data_load'}));
                setInstructionSettingsLoading(false);
            }
        };

        const setFormValues = () => {
            setInstructionLanguages(instruction.languages);

            setInstructionFieldTitle(instruction.title);
            setInstructionFieldVideoUrl(instruction.videoUrl);
            setDisplayLength(true);

            form.setFieldsValue({
                position: instruction.position.toString(),
                videoUrl: instruction.videoUrl,
                title: instruction.title,
                language: instruction.language,
                videoLength: instruction.videoLength
            });
        };

        if (visible && instructionId && !instruction) {
            loadInstruction();
        } else if (visible && instruction && instructionId !== instruction.id) {
            loadInstruction();
        } else if (visible && instruction) {
            setFormValues();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [instructionId, visible]);

    const formSubmit = async (values: InstructionCreateFormType) => {
        setInstructionSettingsLoading(true);

        let parsedValues: InstructionCreateFormType = {
            language: values.language,
            position: values.position,
            title: values.title,
            videoUrl: values.videoUrl,
        };

        try {
            if (instructionId) {
                let response = await apiClient.request(`/api/v1/instruction/${instructionId}/update`, parsedValues, 'PUT');
                setInstruction(response.instruction);
                message.success(intl.formatMessage({id: 'system.instruction_updated'}));
            } else {
                await apiClient.request('/api/v1/instruction/create', parsedValues, 'POST');
                message.success(intl.formatMessage({id: 'system.instruction_added'}));
            }

            form.setFieldsValue({
                position: [],
                videoUrl: [],
                title: [],
            });

            afterSubmit();
            setInstructionSettingsLoading(false);
            reloadInstructions();

        } catch (error: any) {
            setInstructionSettingsLoading(false);
            if (error['status'] === 422) {
                console.error(error);
                message.error(intl.formatMessage({id: 'error.position_already_exists'}));
            } else {
                console.error(error);
                message.error(intl.formatMessage({id: 'error.data_load'}));
            }
        }
    };

    const languageChange = async (language: string) => {
        const values = form.getFieldsValue();

        if (instructionId) {
            setInstructionSettingsLoading(true);
            if (
                instructionFieldTitle !== values.title ||
                instructionFieldVideoUrl !== values.videoUrl
            ) {
                const parsedValues = {
                    language: selectedLanguage ? selectedLanguage : locale,
                    position: values.position,
                    title: values.title,
                    videoUrl: values.videoUrl,
                    videoLength: values.videoLength
                };

                if (!values.title || !values.videoUrl || !values.position) {
                    setInstructionSettingsLoading(false);
                    return;
                }

                await apiClient.request(`/api/v1/instruction/${instructionId}/update`, parsedValues, 'PUT');
                setInstructionSettingsLoading(false);

                setInstructionLanguages((instructionLanguages: LanguageArrayType) => [...instructionLanguages, selectedLanguage]);
                reloadInstructions();
            }

            loadContentTranslations(language);
        }

        setSelectedLanguage(language);
    };

    const loadContentTranslations = async (language: string) => {
        if (instructionLanguages.includes(language)) {
            setInstructionSettingsLoading(true);

            let translation = await apiClient.request(`/api/v1/instruction/${instructionId}/${language}/translation`, [], 'GET');

            form.setFieldsValue({
                title: translation.instructionTranslation.title,
                videoUrl: translation.instructionTranslation.videoUrl,
                videoLength: translation.instructionTranslation.videoLength
            });

            setInstructionFieldTitle(translation.instructionTranslation.title);
            setInstructionFieldVideoUrl(translation.instructionTranslation.videoUrl);
            setDisplayLength(true);
            setInstructionSettingsLoading(false);

        } else {
            form.setFieldsValue({
                title: '',
                videoUrl: '',
            });
            setDisplayLength(false);
            setInstructionFieldTitle('');
            setInstructionFieldVideoUrl('');
        }
        setInstructionSettingsLoading(false);
    };

    return (
        <>
            <Modal
                className='video-instruction-create-modal'
                title={
                    instructionId ?
                        intl.formatMessage({id: 'system.create_video_instruction'})
                        :
                        intl.formatMessage({id: 'system.update_video_instruction'})
                }
                open={visible}
                onCancel={onCancel}
                onOk={() => form.submit()}
                cancelText={intl.formatMessage({id: 'general.cancel'})}
                okText={instructionId ? intl.formatMessage({id: 'general.update'}) : intl.formatMessage({id: 'general.create'})}
            >
                <Spinner spinning={instructionSettingsLoading}>
                    <Form form={form} onFinish={formSubmit}>
                        <Input
                            name='title'
                            customLayout={formItemLayout}
                            label={intl.formatMessage({id: 'system.video_instruction_name'})}
                            validation={{required: true}}
                        />
                        <Select
                            name="position"
                            customLayout={formItemLayout}
                            label={intl.formatMessage({id: 'system.video_instruction_position'})}
                            validation={{required: true}}
                            manualOptions={positions}
                            loading={instructionSettingsLoading}
                        />
                        <Input
                            name='videoUrl'
                            customLayout={formItemLayout}
                            label={
                                <span>
                                  {intl.formatMessage({id: 'system.video_instruction_url'})}
                                    <Tooltip overlayClassName='instructions-tooltip' title={
                                        <span>
                                            <FormattedMessage id='system.video_instruction_url_hint'/>
                                            <br/>
                                            1) https://youtu.be/...
                                            <br/>
                                            2) https://www.youtube.com/watch?v=...
                                            <br/>
                                            3) https://www.youtube.com/embed/...
                                        </span>
                                    }>
                                        <i className='fal fa-question-circle header-item'/>
                                    </Tooltip>
                                </span>
                            }
                            validation={{required: true}}
                        />

                        <Select
                            customLayout={formItemLayout}
                            name='language'
                            label={intl.formatMessage({id: "general.language"})}
                            manualOptions={languageList}
                            onChange={(el: string) => languageChange(el)}
                        />

                        {displayLength && instruction &&
                            <Input
                                className='video-length-input'
                                name='videoLength'
                                customLayout={formItemLayout}
                                label={intl.formatMessage({id: 'system.video_instruction_length'})}
                                defaultValue={instruction.videoLength}
                                disabled={true}
                                bordered={false}
                            />
                        }
                    </Form>
                </Spinner>
            </Modal>
        </>
    )
};

export default connect(mapStateToProps)(VideoInstructionsCreateModal);
