import React, {useEffect, useState} from 'react';
import FilterCheckBox from '../FilterCheckBox';
import FilterPopover from "../FilterPopover";
import {Button} from "ui";
import {FormattedMessage, useIntl} from "react-intl";
import {useLecture} from "hooks/Lectures/useLecture";
import {useAudience} from "../../../../hooks/Audiences/useAudience";
import {InputNumber, Switch} from "../../../../components/Form";
import {Divider} from "antd";

interface AttemptsFilterInterface {
    tagBuilder: Function;
    session?: any;
    setFilterValues: any;
    filterValues: any
    form: any;
    filterKey: string;
    setFilterKey: React.Dispatch<React.SetStateAction<string>>;
}

const AttemptsFilter: React.FC<AttemptsFilterInterface> = ({
                                                               filterKey,
                                                               setFilterKey,
                                                               form,
                                                               tagBuilder,
                                                               setFilterValues,
                                                               filterValues
                                                           }) => {
    const [initialValues, setInitialValues] = useState(undefined)

    const key = 'attempt';
    const intl = useIntl();

    const content = (
        <>
            <div className={'flex flex-column p-1 attempts-wrapper'}>
                <div className={'bold'}>
                    <FormattedMessage id={'general.attempts'}/>
                </div>
                <InputNumber
                    name='attemptsCount'
                    label={''}
                    customLayout={true}
                    min={1}
                    initialValue={null}
                />
                <Divider className={'m-2'}/>
                <Switch isFormItem
                        name='attemptsHistoryBool'
                        label={intl.formatMessage({id: 'general.attempts_history'})}/>

            </div>
            <div className={'flex justify-between p-1'}>
                <Button type={'link'} onClick={() => handleClose()}>
                <FormattedMessage id={'general.cancel'}/>
                </Button>
                <Button type={'primary'} onClick={() => {
                    setFilterKey('')
                    handleOpen(false)
                }}>
                    <FormattedMessage id={'general.select'}/>
                </Button>
            </div>
        </>
    );

    const handleClose = () => {
        form.setFieldsValue(initialValues);
        setFilterKey('')
        ;
    }

    const handleOpen = (open: boolean) => {
        if (open) {
            setInitialValues(form.getFieldsValue())
            setFilterValues(
                {
                    ...filterValues,
                    attemptsCount: {
                        ...filterValues.attemptsCount,

                    }
                }
            )
        } else {
            tagBuilder()
        }
    }

    return (
        <FilterPopover
            popoverKey={key}
            filterKey={filterKey}
            setOpen={setFilterKey}
            className={'attempts-popover'}
            handleOpen={handleOpen}
            content={content}
            loading={false}
            title={'general.search_by_attempts'}
            buttonTitle={'general.attempts'}
        />
    );
};

export default AttemptsFilter;