import React, {useState, useEffect} from 'react';
import {useIntl} from 'react-intl';
import {useParams} from 'react-router-dom';
import DefaultLayout from "components/DefaultLayout";
import {Tabs, Form} from "antd";
import useHandleError from "utils/useHandleError";
import apiClient from "utils/apiClient";
import Spinner from "components/Spinner";
import CompetencesAccessRights from "../../../components/Competences/CompetencesAccessRights";
import './styles.scss';
import {
    CompetencesStudents,
    CompetencesSettings,
    CompetencesLearningProgram
} from "components/Competences";

const formItemLayout = {
    labelCol: {span: 5},
    wrapperCol: {span: 12},
};

const CompetencesForm: React.FC = ({}: any) => {
    const [competence, setCompetence] = useState<any>();
    const [isLoading, setIsLoading] = useState(true);

    const intl = useIntl();
    const [handleError] = useHandleError();
    const [form] = Form.useForm();
    const {TabPane} = Tabs;
    const params = useParams();
    const competenceId = params.id;

    useEffect(() => {
        if (competenceId) {
            loadCompetence()
        } else {
            setIsLoading(false)
        }
    }, [])

    const loadCompetence = async () => {
        try {
            const response = await apiClient.request('/api/v1/competences/' + competenceId, [], 'GET');
            setCompetence(response.competence)
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <DefaultLayout.PageLayout>
            <DefaultLayout.PageHeader
                title={competence ? competence?.title : intl.formatMessage({id: 'general.competences'})}
                breadcrumb={[{
                    name: intl.formatMessage({id: 'general.competences'}),
                    path: '/competences'
                }]}
            />
            <DefaultLayout.PageContent withBottomMargin>
                <Spinner spinning={isLoading}>
                    <Tabs>
                        <TabPane tab={intl.formatMessage({id: 'general.settings'})} key='settings'>
                            <Form form={form} {...formItemLayout}>
                                <CompetencesSettings form={form} competence={competence}/>
                            </Form>
                        </TabPane>
                        <TabPane tab={intl.formatMessage({id: 'general.learning_programs'})} key='learning_programs'>
                            <CompetencesLearningProgram competence={competence}/>
                        </TabPane>
                        <TabPane tab={intl.formatMessage({id: 'general.competence_students'})} key='students'>
                            <CompetencesStudents/>
                        </TabPane>
                        <TabPane tab={intl.formatMessage({id: 'general.access_rights'})} key='rights'>
                            <CompetencesAccessRights competenceId={competenceId} competence={competence}/>
                        </TabPane>
                    </Tabs>
                </Spinner>
            </DefaultLayout.PageContent>
        </DefaultLayout.PageLayout>
    )
};

export default CompetencesForm;