import React, {useState, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {NavLink, useNavigate, useParams} from 'react-router-dom';
import {config} from 'config/config';

import DefaultLayout from "components/DefaultLayout";
// import './styles.scss';

import useHandleError from "utils/useHandleError";
import {Table} from "ui";
import moment from "moment/moment";
import {message, Modal, Popover, Form, Button} from "antd";
import { PlusOutlined } from '@ant-design/icons';
import coursesApiClient from 'utils/coursesApiClient';
import FlexRow from "../../../components/FlexRow";
import { Input, Select } from '../../../components/Form';
import Switch from 'components/Form/Switch';

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale
    };
};
const formItemLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
};

const LearningTestList: React.FC<any> = ({ locale }) => {
    const [reload, setReload] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [visibleTooltip, setVisibleTooltip] = useState<number | null>(null);
    const [showAddNewQuestionPool, setShowAddNewQuestionPool] = useState(false);
    const [isSubmitProgress, setIsSubmitProgress] = useState(false);
    const [isSwitchLoading, setIsSwitchLoading] = useState(false);

    const intl = useIntl();
    const params = useParams();
    const [handleError] = useHandleError();
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const handleVisibleChange = (visible: boolean, recordId: number) => {
        if (visible) {
            setVisibleTooltip(recordId);
        } else {
            setVisibleTooltip(null);
        }
    };

    const submitForm = async (values: any) => {
      setIsSubmitProgress(true);

      let parsedValues = {
        ...values,
      }

      try {
        let responese = await coursesApiClient.request(`/api/v1/courses/learning-tests/store`, parsedValues, 'POST');
        navigate(`/learning/tests/${responese.test.id}/edit`);
      } catch (error) {
          handleError(error)
      } finally {
        setIsSubmitProgress(false);
      }
    }

    const onSwitchChange = async (record: any) => {
      try {
        setIsSwitchLoading(true);
  
        record.isOnline = !record.isOnline;
  
        await coursesApiClient.request(`/api/v1/courses/learning-tests/${record.id}/update`, record, 'PUT');
  
        const messageId = 'learning_tests.learning_test_status_updated';
  
        message.success(intl.formatMessage({id: messageId}), 7);
      } catch (err) {
        console.error(err);
        message.error(intl.formatMessage({id: 'error.action_failed'}));
      } finally {
        setIsSwitchLoading(false);
      }
    }
  
    const columns = [
      {
        title: intl.formatMessage({id: 'learning_tests.learning_test_title'}),
        dataIndex: 'name',
        key: 'name',
        render: (text: string, record: any) => {
          return (
              <NavLink
                to={
                    `/learning/tests/${record.id}/edit`
                }
              >
                {record.title}
              </NavLink>
            )
        }
      },
      {
        title: intl.formatMessage({id: 'learning_tests.id'}),
        dataIndex: 'id',
      },
      {
        title: intl.formatMessage({id: 'learning_tests.questions'}),
        dataIndex: 'totalIncludedQuestions ',
        key: 'totalIncludedQuestions ',
        render: (text: string, record: any) => record.totalIncludedQuestions
      },
      {
        title: intl.formatMessage({id: 'learning_tests.question_banks'}),
        dataIndex: 'questionBankTitles ',
        key: 'questionBankTitles ',
        render: (text: string, record: any) => record.questionBankTitles
      },
      {
        title: intl.formatMessage({id: 'general.online'}),
        dataIndex: 'isOnline',
        key: 'isOnline',
        render: (_text: string, record: any) => {
          return (<Switch key={`learning-test-switch-${record.id}`}  onChange={() => onSwitchChange(record)} defaultChecked={record.isOnline} disabled={isSwitchLoading}/>)
        }
      },
      {
        title: intl.formatMessage({id: 'general.actions'}),
        key: 'actions',
        render: (text: string, record: any, index: number) => {
          const content = <>
            <NavLink
              to={
                `/learning/tests/${record.id}/edit`
              }
            >
              <div className="popover-item">
                <FormattedMessage id="general.edit"/>
              </div>
            </NavLink>
              <div className="popover-item" onClick={() => handleDelete(record.id)}>
                  <FormattedMessage id="general.delete"/>
              </div>
          </>;

          return (
            <Popover
              open={index === visibleTooltip}
              content={content}
              trigger="click"
              placement="bottomRight"
              arrowPointAtCenter
              onOpenChange={(visible) => handleVisibleChange(visible, index)}
            >
              <div style={{width: '100%', cursor: 'pointer', textAlign: 'center'}}>
                <i className="fal fa-ellipsis-v" style={{fontSize: '16px'}} />
              </div>
            </Popover>
          )
        },
      }
    ];

    const handleDelete = async (id: any) =>{
        try {
            const response = await coursesApiClient.request(`/api/v1/courses/learning-tests/${id}/delete`, [], 'DELETE');
            setVisibleTooltip(null);

            if (!response.deleted) {
                message.error(intl.formatMessage({id: 'error.learning_test_in_use'}));
            } else {
                setReload(true)
            }
        } catch (error) {
            handleError(error)
        }
    }

    return (
        <DefaultLayout.PageLayout>
            <DefaultLayout.PageHeader 
              title={intl.formatMessage({id: 'learning_tests.learning_tests'})}
            />
            <DefaultLayout.PageContent>
                <FlexRow
                    left={
                      <Button type="primary" icon={<PlusOutlined/>} onClick={() => setShowAddNewQuestionPool(true)}>
                        <FormattedMessage id="learning_tests.add_new_learning_test" />
                      </Button>
                    }
                />

                <Modal
                  width={530}
                  className="add-new-learning-test"
                  open={showAddNewQuestionPool}
                  onCancel={() => setShowAddNewQuestionPool(false)}
                  title={intl.formatMessage({ id: 'learning_tests.add_new_learning_test' })}
                  okText={intl.formatMessage({ id: 'general.submit' })}
                  cancelText={intl.formatMessage({ id: 'general.back' })}
                  onOk={form.submit}
                  okButtonProps={{disabled: isSubmitProgress}}
                >
                  <Form form={form} onFinish={submitForm}>
                    <Input customLayout={formItemLayout} name="title" label={intl.formatMessage({ id: 'learning_tests.learning_test_title' })} validation={{ required: true }}/>
                  </Form>
                </Modal>
                <Table
                    columns={columns}
                    url={'/api/v1/courses/learning-tests'}
                    setReload={setReload}
                    reload={reload}
                    rowSelection={false}
                    rowKey={'id'}
                    setTotalRecords={setTotalRecords}
                    search={searchString}
                />
            </DefaultLayout.PageContent>

        </DefaultLayout.PageLayout>
    );
};

export default connect(mapStateToProps)(LearningTestList);
