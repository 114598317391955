import React from 'react';
import { useIntl } from 'react-intl';
import { Form, DatePicker as AntDDatePicker } from 'antd';
import { constructValidationRules } from './validations';
import {connect} from 'react-redux';
import moment from 'moment';
import './styles.scss';


const mapStateToProps = (state: any) => {
  return {
    locale: state.locale.locale
  }
};
const DatePicker: React.FC<any> = ({name, locale, label, validation = {}, defaultValue, className, customRules, validateTrigger, customLayout, ...props}) => {
  const intl = useIntl();

  moment.locale(locale ?? 'en', {
    week: {
      dow: 1,
    },
  });

  let labelCol: any = {span: 8};
  let wrapperCol: any = {span: 6};

  if (customLayout) {
    if (typeof customLayout === 'object') {
      labelCol = customLayout.labelCol || undefined;
      wrapperCol = customLayout.wrapperCol || undefined;
    } else {
      labelCol = undefined;
      wrapperCol = undefined;
    }
  }

  return (
    <Form.Item
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      name={name}
      label={label}
      rules={customRules ? customRules : constructValidationRules(validation, intl)}
      validateTrigger={validateTrigger}
    >
      <AntDDatePicker
        suffixIcon={<i className='fal fa-calendar-alt' />}
        defaultValue={defaultValue}
        {...props}
        className={'default-input-field ' + (className || '')}
      />
    </Form.Item>
  )
}

export default connect(mapStateToProps)(DatePicker);
