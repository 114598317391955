import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import DefaultLayout from "components/DefaultLayout";
import { LectureCompletedType, LectureProps } from '../types';
import Button from 'ui/Button';
import FileUploadList from 'ui/FileUploadList';
import {DatePicker, Input, InputNumber, Select, Switch} from 'components/Form';
import { useLecture } from "hooks/Lectures/useLecture";
import {Checkbox, Form, Input as AntdInput, UploadFile} from 'antd';
import { Title } from 'ui';
import { RcFile } from 'antd/lib/upload';
import TemplateEditor from 'components/TemplateEditor';
import FileDownload from "js-file-download";
import moment from "moment/moment";
import coursesApiClient from "../../../utils/coursesApiClient";
import {config} from "../../../config/config";
import useHandleError from "../../../utils/useHandleError";
import './styles.scss';

export interface LectureSettingsProps {
    lecture: LectureProps,
    reloadLecture(): any,
}

const LectureSettings: React.FC<LectureSettingsProps> = ({ lecture, reloadLecture }) => {
    const { saveLecture, getLectureFiles, storeLectureFile, deleteLectureFile, lectureLoading, downloadLectureFile } = useLecture();
    const [isFormChanged, setIsFormChanged] = useState(false);
    const [fileList, setFileList] = useState<UploadFile[]>();
    const [setEditor] = useState<any>();
    const [description, setDescription] = useState<string>();
    const [customFields, setCustomFields] = useState<any>([]);
    const [handleError] = useHandleError();

    const intl = useIntl();
    const [form] = Form.useForm();

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 12 },
    };

    useEffect(() => {
        form.resetFields()
        form.setFieldsValue({
            title: lecture.title,
            completedType: lecture.completedType,
        });
        setDescription(lecture.description);
        fetchFiles();

        if (lecture.customFields && Object.keys(lecture.customFields).length > 0) {
            if (lecture.customFieldValues) {
                Object.entries(lecture.customFieldValues).map((field: any) => {
                    const fieldOptions = lecture.customFields.filter((el: any) => el.uuid === field[0])
                    if (fieldOptions[0].field_type === 'MULTIPLE_CHOICE') {
                        form.setFieldsValue({
                            customFields: {
                                [field[0]]: field[1]
                            }
                        })
                    } else if (fieldOptions[0].field_type === 'DATE') {
                        form.setFieldsValue({
                            customFields: {
                                [field[0]]: moment(field[1])
                            }
                        })
                    } else {
                        form.setFieldsValue({
                            customFields: {
                                [field[0]]: field[1]
                            }
                        })
                    }
                });
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lecture])

    const loadCustomFields = async () => {
        try {
            const customFields = await coursesApiClient.request('/api/v1/courses/lectures/custom-fields/lecture-custom-fields', [], 'GET');
            setCustomFields(customFields.fields);
        } catch (error) {
            handleError(error)
        } finally {
        }
    }

    useEffect(() => {
        loadCustomFields();
    }, []);

    useEffect(() => {
        form.setFieldsValue({
            files: fileList,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileList])

    useEffect(() => {
        onValuesChange({ description: description });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [description])

    const fetchFiles = async () => {
        if (lecture && lecture.id) {
            setFileList(await getLectureFiles(lecture.id));
        }
    }

    const onValuesChange = (changedValues: any) => {
        setIsFormChanged(true);
    }

    const handleFormSubmit = async (values: any) => {
        if (!isFormChanged) {
            return;
        }

        interface Fields {
            customFields: {
                [key: string]: string;
            };
        }

        const parsedValues: Fields = {
            customFields: {},
        };

        if (values.customFields !== undefined) {
            parsedValues['customFields'] = values.customFields;
            Object.entries(parsedValues.customFields).map((field: any) => {
                if ((field[1] === null) || (field[1] == undefined) || (field[1] == '')) {
                    delete parsedValues.customFields[field[0]];
                } else {
                    const fieldOptions = customFields.filter((el: any) => el.uuid === field[0])
                    if (fieldOptions[0].field_type === 'DATE') {
                        parsedValues.customFields[field[0]] = field[1].format(config.DBDateFormat);
                    }
                }
            });
        }

        if (await saveLecture({
            id: lecture.id,
            title: values.title,
            description: description ?? undefined,
            completedType: values.completedType,
            customFields: values.customFields,
        } as LectureProps)) {
            setIsFormChanged(false);
            reloadLecture();
            // fetchFiles();
        }
    };

    const completedTypeOptions = {
        [LectureCompletedType.Automatic]: intl.formatMessage({ id: 'general.lectures.automatically' }),
        [LectureCompletedType.Manual]: intl.formatMessage({ id: 'general.lectures.manually' }),
    }

    const handleAddFile = async (file: RcFile, callBack: (id?: number) => void) => {
        if (lecture && lecture.id) {
            callBack(await storeLectureFile(lecture.id, file));
        }
    }

    const handleRemoveFile = async (id: number): Promise<boolean> => {
        if (lecture && lecture.id) {
            return await deleteLectureFile(lecture.id, id);
        }

        return false;
    }

    const handleDownload = async (file: any) => {
        if (lecture && lecture.id) {
            const download = file.originFileObj ? file.originFileObj : await downloadLectureFile(lecture.id, +file.uid)

            if (download) {
                FileDownload(download, file.name);
            }

        }
    }

    return (<>
        <Form form={form} onFinish={handleFormSubmit} {...formItemLayout} onValuesChange={onValuesChange}>
            <Title withLine title="general.lectures.description_and_files" />

            <Input
                name='title'
                label={intl.formatMessage({ id: 'general.lectures.title' })}
                customRules={[{ required: true, message: intl.formatMessage({ id: 'validation.field_required' }) }]}
            />

            {typeof description !== 'undefined' && <TemplateEditor height={200}
                label={intl.formatMessage({ id: 'general.lectures.lecture_description' })}
                html={description}
                setHtml={(value: any) => setDescription(value ?? '')}
                setEditor={setEditor}
            />}

            <Form.Item
                label={intl.formatMessage({ id: 'general.upload_files' })}
                name="files"
            >
                <FileUploadList
                    multiple={true}
                    onAdd={handleAddFile}
                    onDelete={handleRemoveFile}
                    onDownload={handleDownload}
                    showUploadList={{
                        showDownloadIcon: true,
                    }}
                />
            </Form.Item>

            <Title withLine title="general.lectures.activity_completion_setting" />

            <Select
                showArrow
                name='completedType'
                label={intl.formatMessage({ id: 'general.lectures.mark_activity_completed' })}
                manualOptions={completedTypeOptions}
                customRules={[{ required: true, message: intl.formatMessage({ id: "validation.field_required" }) }]}
            />

            {
                customFields.length > 0 ?
                    <>
                        <Title withLine title="general.custom_fields" />
                        {
                            customFields.map((
                                field: {
                                    uuid: string,
                                    field_type: 'TEXT' | 'INTEGER' | 'DATE' | 'SWITCH' | 'MULTIPLE_CHOICE' | 'HYPERLINK',
                                    title: string,
                                    multiple_choice_options: string[]
                                }) => {

                                switch (field.field_type) {
                                    case 'TEXT':
                                        return (
                                            <Input name={['customFields', field.uuid]} label={field.title}></Input>
                                        )
                                    case 'INTEGER':
                                        return (
                                            <InputNumber name={['customFields', field.uuid]}
                                                         label={field.title}></InputNumber>
                                        )
                                    case 'DATE':
                                        return (
                                            <DatePicker name={['customFields', field.uuid]} label={field.title}
                                                        format={config.defaultDateFormat}></DatePicker>
                                        )
                                    case 'SWITCH':
                                        return (
                                            <Switch name={['customFields', field.uuid]} isFormItem
                                                    label={field.title}></Switch>
                                        )
                                    case 'MULTIPLE_CHOICE':
                                        return (
                                            <div className={'multiple-choice-fields'}>
                                                <Form.Item label={field.title} name={['customFields', field.uuid]}>
                                                    <Checkbox.Group>
                                                        {
                                                            field.multiple_choice_options.map((el:string)=>{
                                                                return (
                                                                    <Checkbox value={el}>
                                                                        {el}
                                                                    </Checkbox>
                                                                )
                                                            })
                                                        }
                                                    </Checkbox.Group>
                                                </Form.Item>
                                            </div>
                                        )
                                    case 'HYPERLINK':
                                        return (
                                            <Form.Item className={'vertical-custom-filer'} label={field.title}
                                                       name={['customFields', field.uuid]}>
                                                <AntdInput.Group compact>
                                                    <Form.Item
                                                        className={'vertical-form-item'}
                                                        label={intl.formatMessage({id: 'users.custom_filter_display_name'})}
                                                        name={['customFields', field.uuid, 'displayName']}
                                                    >
                                                        <AntdInput/>
                                                    </Form.Item>
                                                    <Form.Item
                                                        className={'vertical-form-item'}
                                                        label={intl.formatMessage({id: 'users.custom_filter_hyperlink'})}
                                                        name={['customFields', field.uuid, 'hyperlink']}
                                                    >
                                                        <AntdInput/>
                                                    </Form.Item>
                                                </AntdInput.Group>
                                            </Form.Item>
                                        )
                                    default:
                                        return;
                                }
                            })
                        }
                    </>
                    :
                    null
            }

        </Form>
        {!!lecture.isEditable && <DefaultLayout.PageFooter right={
            <>
                <Button disabled={!isFormChanged} isLoading={lectureLoading}
                    onClick={reloadLecture}>
                    <FormattedMessage id='general.cancel' />
                </Button>
                <Button type='primary' disabled={!isFormChanged} isLoading={lectureLoading}
                    onClick={() => { form.submit() }}>
                    <FormattedMessage id='general.submit' />
                </Button>
            </>
        } />}
    </>);
};

export default LectureSettings;