import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {Button, Dropdown, Modal, Popover, Space} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useResource } from "hooks/Resources/useResource";
import { Search, Table, Link } from "ui";
import DefaultLayout from "components/DefaultLayout";
import FlexRow from "components/FlexRow";
import ResourceCreationModal from 'components/Resources/ResourceCreationModal';
import { ColumnsType } from 'antd/lib/table';
import { ResourceType } from 'components/Resources/types'
import './styles.scss'
import {FilterModal} from "../../../../components/Modals";

interface DataType {
    key: React.ReactNode;
    title: string;
    color: string;
    children?: DataType[];
    parentId?: Number,
    resourceType?: ResourceType,
}

const List: React.FC = () => {
    const [reload, setReload] = useState<boolean>(false);
    const [tableData, setTableData] = useState<any[]>([]);
    const [searchString, setSearchString] = useState<string>('');
    const [visibleTooltip, setVisibleTooltip] = useState<number | null>(null);
    const [showCreateModal, setCreateModal] = useState<boolean>(false);
    const [resourceData, setResourceData] = useState<any | null>(null);
    const { deleteResource, resourceLoading } = useResource();
    const [showResourceFilterModal, setShowResourceFilterModal] = useState(false);
    const [filter, setFilter] = useState('');

    const intl = useIntl();
    const { confirm } = Modal;

    useEffect(() => {

        if (!showCreateModal) {
            setResourceData(null);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showCreateModal])

    const handleVisibleChange = (visible: boolean, recordId: number) => {
        if (visible) {
            setVisibleTooltip(recordId);
        } else {
            setVisibleTooltip(null);
        }
    }

    const columns: any = [
        {
            title: intl.formatMessage({ id: 'general.title' }),
            dataIndex: 'title',
            render: (title: string, record: any) => {
                return getFormattedTitle(record.resourceType, title);
            }
        },
        {
            dataIndex: 'resourceType',
            render: (title: string, record: any) => record.resourceType,
            hidden: true
        },
        {
            title: intl.formatMessage({ id: 'system.resource.color' }),
            dataIndex: 'color',
            render: (color: string, record: any) => {
                if (!record.parentId) {
                    return;
                }

                return <div className="color-circle" style={{ backgroundColor: color }}></div>
            }
        },
        {
            title: intl.formatMessage({ id: 'system.resource.count' }),
            dataIndex: 'qty',
            render: (color: string, record: any) => record.children?.length
        },
        {
            title: intl.formatMessage({ id: 'system.resource.reservations' }),
            dataIndex: 'reservations',
            render: (color: string, record: any) => record.reservations
        },
        {
            key: 'actions',
            render: (_text: string, record: any) => {
                const content = (
                    <>
                        <div className="popover-item" onClick={() => {
                            openEditResourceModal(record.id);
                            setVisibleTooltip(null);
                        }}>
                            <FormattedMessage id="general.edit" />
                        </div>
                        {!record.children?.length && <div className="popover-item" onClick={() => {
                            warnDeletion(record.id);
                            setVisibleTooltip(null);
                        }}>
                            <FormattedMessage id="general.delete" />
                        </div>}
                    </>
                );

                return (
                    <Popover
                        open={record.id === visibleTooltip}
                        content={content}
                        trigger="click"
                        placement="bottomRight"
                        arrowPointAtCenter
                        onOpenChange={(visible) => handleRowActionVisibleChange(visible, record.id)}
                    >
                        <div style={{ width: '100%', cursor: 'pointer', textAlign: 'center' }}>
                            <i className="fal fa-ellipsis-v" style={{ fontSize: '16px' }} />
                        </div>
                    </Popover>
                )
            },
        }
    ];

    const getFormattedTitle = (resourceType: string | null, title: string | null): string => {
        const typeTranslation = intl.formatMessage({ id: `system.resource.type.${resourceType}`});
        
        return !!resourceType && resourceType !== ResourceType.Other ? `${typeTranslation}${!!title ? ' - ' + title : ''}` : title ?? ''
    }

    const handleRowActionVisibleChange = (visible: boolean, recordId: number) => {
        if (visible) {
            setVisibleTooltip(recordId);
        } else {
            setVisibleTooltip(null);
        }
    };

    const openEditResourceModal = (id: number) => {
        const child = findChildById(id);

        if (child) {
            setResourceData(
                {
                    isGroup: false,
                    classifier: child
                }
            );
            setCreateModal(true);
        } else {
            const group = tableData.find((item: any) => item.id === id)
            setResourceData({
                isGroup: true,
                classifier: group
            });
            setCreateModal(true);
        }
    }

    const openAddGroupModal = () => {
        setResourceData({
            isGroup: true,
        });
        setCreateModal(true);
    }

    const openAddResourceModal = () => {
        setResourceData({
            isGroup: false,
        });
        setCreateModal(true);
    }

    const findChildById = (id: number) => {
        for (const row of tableData) {
            const result = row.children.find((item: any) => item.id === id);
            if (result) {
                return result;
            }
        }
    }

    const handleAddResource = () => {
        setCreateModal(false);
        setReload(!reload);
    }

    const warnDeletion = async (id: number) => {
        confirm({
            title: intl.formatMessage({ id: 'general.attention' }),
            content: intl.formatMessage({ id: 'system.resource.confirm_delete' }),
            icon: <ExclamationCircleOutlined />,
            okText: intl.formatMessage({ id: 'general.yes' }),
            cancelText: intl.formatMessage({ id: 'general.no' }),

            onOk() {
                handleDeleteResource(id);
            }
        });
    }

    const handleDeleteResource = async (id: number) => {
        if (await deleteResource(id)) {
            setReload(!reload);
        }
    }

    const customExpandIcon = (props: any) => {
        if (!props.record.children?.length) {
            return;
        }

        if (props.expanded) {
            return <Link size="small"
                style={{ color: 'black', verticalAlign: 1 }}
                onClick={(e: any) => props.onExpand(props.record, e)}
                icon={<i className="fa-solid fa-sort-down" />} />
        }
        return <Link size="small"
            style={{ color: 'black' }}
            onClick={(e: any) => props.onExpand(props.record, e)}
            icon={<i className="fa-solid fa-caret-right" />} />
    }

    const getParentOptions = () => {
        return tableData.map((row) => {
            return {
                id: row.id, 
                resourceType: row.resourceType, 
                title: getFormattedTitle(row.resourceType, row.title),
            }
        });
    }

    const saveTableData = (data: []) => {
        setTableData(data);
    }

    return (
        <DefaultLayout.PageLayout>
            <DefaultLayout.PageHeader title={intl.formatMessage({ id: 'system.resource.classifiers' })} />
            <DefaultLayout.PageContent>
                <FlexRow
                    left={
                        <Space.Compact block>
                            <Dropdown.Button trigger={['click']}
                                placement="bottomRight"
                                type="primary"
                                menu={{
                                    items: [{
                                        label: <FormattedMessage id='system.resource.group.create_new' />,
                                        key: '1',
                                    }],
                                    onClick: () => openAddGroupModal(),
                                }} onClick={() => openAddResourceModal()}>
                                <FormattedMessage id='system.resource.create_new' />
                            </Dropdown.Button>
                        </Space.Compact>

                    }
                    right={
                        <>
                            <Button type="default" onClick={() => setShowResourceFilterModal(true)}>
                                <FormattedMessage id="general.filter" />
                            </Button>
                            <Search setSearchString={setSearchString} setReload={setReload} />
                        </>
                    }
                />
                <div className={'table-filter-modal'}>
                    <FilterModal
                        visible={showResourceFilterModal}
                        onCancel={setShowResourceFilterModal}
                        title={'general.filter_by'}
                        okProp={'general.filter_resources'}
                        modules={{
                            resourceGroup: {mode: 'multiple', visible: true},
                            resourceType: {mode: 'multiple', visible: true},
                            resourceName: {mode: 'multiple', visible: true},
                            resourceStartDate: {visible: true},
                            resourceEndDate: {visible: true},
                            resourceBusyness: {mode: 'multiple', visible: true},
                        }}
                        setFilter={setFilter}
                        filter={filter}
                        load={setReload}
                    />
                </div>
                <Table size="small"
                    isLoading={resourceLoading}
                    rowSelection={false}
                    columns={columns.filter((col: any) => col.hidden !== true)}
                    url='api/v1/courses/resources'
                    setReload={setReload}
                    reload={reload}
                    rowKey='id'
                    search={searchString}
                    expandIcon={(props: any) => customExpandIcon(props)}
                    saveData={(data: []) => saveTableData(data)}
                    filter={filter}
                />
            </DefaultLayout.PageContent>

            <ResourceCreationModal
                parentOptions={getParentOptions()}
                visible={showCreateModal}
                onCancel={() => setCreateModal(false)}
                onSubmit={() => handleAddResource()}
                resourceData={resourceData}
            />


        </DefaultLayout.PageLayout>
    );
};

export default List;