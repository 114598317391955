import React from 'react';
import './styles.scss';

const PageSidebar: React.FC<any> = ({withTopPadding, withBottomMargin, ...props}) => {
  let className = 'page-sidebar';

  if (withTopPadding) {
    className += ' with-top-padding';
  }

  if (withBottomMargin) {
    className += ' with-bottom-margin';
  }

  return (
    <div className={className} {...props} />
  )
}

export default PageSidebar;
