import React, {useState, useEffect} from 'react';
import DefaultLayout from 'components/DefaultLayout';
import {FormattedMessage, useIntl} from 'react-intl';
import {Spin, message, Form, Upload, Tooltip, DatePicker, Popover, Radio, Modal} from 'antd';
import {DeleteOutlined, ExclamationCircleOutlined, EyeOutlined, UploadOutlined} from '@ant-design/icons';
import {Input, Select, InputNumber, RemoveConfirmSelect} from 'components/Form';
import ContentEditor from "components/Content/ContentEditor";
import Spinner from 'components/Spinner';
import {Button} from 'ui';
import {NavLink, useParams, useNavigate} from 'react-router-dom';
import apiClient from 'utils/apiClient';
import moment from 'moment';
import {config} from 'config/config';
import methods from './methods';
import {connect} from 'react-redux';

const formItemLayout = {
    labelCol: {span: 8},
    wrapperCol: {span: 6}
};

const formLayout = {
    labelCol: {span: 6, offset: 2},
    wrapperCol: {span: 6},
};

const mapStateToProps = (state: any) => {
    return {
        session: state.session,
        locale: state.locale.locale
    }
};

const ContentForm: React.FC<any> = ({locale}: any) => {
    // Translations
    const [translationsNameArray, setTranslationsNameArray] = useState<any>({});
    const [translationsProductDescriptionArray, setTranslationsProductDescriptionArray] = useState<any>({});
    const [translationsContentDescriptionArray, setTranslationsContentDescriptionArray] = useState<any>({});
    const [translationsPriceCommentArray, setTranslationsPriceCommentArray] = useState<any>({});
    const [templateLanguages, setTemplateLanguages] = useState<any>();
    const [selectLanguageList, setSelectLanguageList] = useState<any>({});
    const [selectedLanguageList, setSelectedLanguageList] = useState<any>();
    const [selectedLanguage, setSelectedLanguage] = useState<string>();
    const [languageLoading, setLanguageLoading] = useState(false);

    // Content Translations
    const [name, setName] = useState<string>('');
    const [initialProductDescription, setInitialProductDescription] = useState('');
    const [initialContentDescription, setInitialContentDescription] = useState('');
    const [initialPriceComment, setInitialPriceComment] = useState('');
    const [productDescription, setProductDescription] = useState<any>('');
    const [contentDescription, setContentDescription] = useState<any>('');
    const [priceComment, setPriceComment] = useState<any>('');

    // Content settings
    const [organizations, setOrganizations] = useState();
    const [customers, setCustomers] = useState();
    const [validFromDate, setValidFromDate] = useState<moment.Moment | null>(null);
    const [validToDate, setValidToDate] = useState<moment.Moment | null>(null);
    const [contentStatus, setContentStatus] = useState();
    const [service, setService] = useState();
    const [categories, setCategories] = useState();

    // Loaders
    const [isLoading, setIsLoading] = useState(true);
    const [loadedServiceType, setServiceType] = useState();
    const [loadService, setLoadService] = useState(false);
    const [loadContentStatus, setLoadContentStatus] = useState(false);
    const [organizationsLoading, setOrganizationsLoading] = useState(false);
    const [customersLoading, setCustomersLoading] = useState(false);
    const [isTranslationLoading, setIsTranslationLoading] = useState(false);

    // images
    const [fileList, setFileList] = useState<any>([]);
    const [contentImages, setContentImages] = useState([]);
    const [previewFile, setPreviewFile] = useState<any>();

    const [form] = Form.useForm();
    const intl = useIntl();
    const params = useParams();

    const serviceType = params.type;
    const contentUuid = params.id;
    const {confirm} = Modal;
    const navigate = useNavigate();

    useEffect(() => {
        const loadContent = async () => {
            setIsLoading(true);
            let response;

            try {
                response = await apiClient.request(`/api/v1/content/${contentUuid}/settings`, [], 'GET');
                if (response.content.serviceType === 'E_LEARNING_CONTENT') {
                    loadServices(response.content.serviceType, response.content.serviceId);
                }
            } catch (error: any) {
                if (error.status === 403) {
                    message.error(intl.formatMessage({id: 'error.dont_have_permission_to_access'}));
                    navigate('/content');
                    return;
                } else if (error.status === 404) {
                    message.error(intl.formatMessage({id: 'error.' + error.message}));
                    navigate('/content');
                    return;
                }

                console.error(error);
                message.error(intl.formatMessage({id: 'error.data_load'}));
            }

            form.setFieldsValue({
                categories: response.content.categories.length > 1 ? response.content.categories.split(',') : response.content.categories,
                priority: response.content.priority,
                validFrom: moment(response.content.validFrom),
                validTo: moment(response.content.validTo),
                price: response.content.price,
                status: response.content.status,
                organizations: response.content.supportedOrganizations ? response.content.supportedOrganizations.split(',') : [],
                customers: response.content.supportedCustomers ? response.content.supportedCustomers.split(',') : [],
            });

            setServiceType(response.content.serviceType);

            loadContentLanguage();

            if (response.content.imgPath) {
                loadPreviewImage(response.content.imgPath);
            }

            if (response.content.contentImages) {

                Object.entries(response.content.contentImages).map((imagepath: any) => {
                    return loadContentPreviewImages(imagepath[1], imagepath[0]);
                })
            }
            setTemplateLanguages(response.content.languages);
            setSelectedLanguageList(response.content.languages);
            setIsLoading(false);
        };

        const loadPreviewImage = async (path: string) => {
            const image = await apiClient.request(`api/v1${path}`, {}, 'GET', true, true);

            setPreviewFile({
                imgPath: path,
                imgName: path.split("_").pop(),
                imgBlob: URL.createObjectURL(image),
                imgId: 'title'
            });
        };

        const loadContentPreviewImages = async (path: string, id: number) => {
            let image;

            image = await apiClient.request(`api/v1${path}`, {}, 'GET', true, true);

            let imgArray: any = contentImages;

            if (Promise.resolve(image) !== image && image !== null) {
                let url: string = URL.createObjectURL(image);

                if (typeof url === 'string') {
                    imgArray.push({
                        imgPath: path,
                        imgName: path.split("_").pop(),
                        imgBlob: url,
                        imgId: id
                    });
                }

                if (imgArray.length) {
                    let blobs = imgArray.filter(function (el: any) {
                        return el != null;
                    });

                    setContentImages(blobs);
                }
            }
        };

        const loadContentLanguage = async () => {
            setIsLoading(true);
            setIsTranslationLoading(true);
            const response = await apiClient.request(`/api/v1/content/${contentUuid}/settings/${locale}`, [], 'GET');

            setSelectedLanguage(response.language.language);

            form.setFieldsValue({
                name: response.language.name,
                productDescription: response.language.productDescription,
                contentDescription: response.language.contentDescription,
                priceComment: response.language.priceComment
            });

            setProductDescription(response.language.productDescription);
            setContentDescription(response.language.contentDescription);
            setPriceComment(response.language.priceComment);

            setName(response.language.name);
            setInitialProductDescription(response.language.productDescription);
            setInitialContentDescription(response.language.contentDescription);
            setInitialPriceComment(response.language.priceComment);
            setIsLoading(false);
            setIsTranslationLoading(false);

        };

        const loadOrganizations = async () => {
            setOrganizationsLoading(true);
            let organizationsResponse = await apiClient.request(config.api.routes.backend.organizationsOptions, {}, 'GET');
            let organizationsArray: any = {};

            Object.values(organizationsResponse.organizations).map((el: any) => {
                return organizationsArray[el.uuid] = el.name;
            });

            setOrganizations(organizationsArray);
            setOrganizationsLoading(false);
        };

        const loadCustomers = async () => {
            setCustomersLoading(true);
            let customersResponse = await apiClient.request(config.api.routes.backend.organizationsCustomers, {}, 'GET');
            let customersArray: any = {};

            Object.values(customersResponse.customers).map((el: any) => {
                return customersArray[el.uuid] = el.name;
            });

            setCustomers(customersArray);
            setCustomersLoading(false);
        };

        const loadServices = async (contentType: string = '', serviceId: number = 0) => {
            try {
                setLoadService(true);
                let response = await apiClient.request(`/api/v1/services/type/${serviceType || contentType}/active`, {}, 'GET');

                let servicesArray: any = {};

                Object.values(response.services).map((service: any) => {
                    return servicesArray[service.id] = service.name;
                });

                setService(servicesArray);

                if (serviceId !== 0) {
                    form.setFieldsValue({
                        service: serviceId.toString(),
                    });
                }

            } catch (error) {
                message.error(intl.formatMessage({id: 'error.data_load'}));
            } finally {
                setLoadService(false);
            }
        };

        const loadContentStatus = async () => {
            setLoadContentStatus(true);
            let customersResponse = await apiClient.request(config.api.routes.enums.contentStatus, {}, 'GET');
            setContentStatus(customersResponse);
            setLoadContentStatus(false);
        };

        const loadContentCategories = async () => {
            setIsLoading(true);
            const response = await apiClient.request('/api/v1/content/categories', {}, 'GET');

            let categories: any = {};
            Object.values(response.categories).map((el: any) => {
                if (el.name !== 'all_categories') {
                    categories[el.id] = intl.formatMessage({id: `licensing.content.${el.name}`});
                }
                return null;
            });

            setCategories(categories);
            setIsLoading(false);
        };

        const loadLanguages = async () => {
            let languages = await apiClient.request(`/api/v1/language`, {}, 'GET');
            let languageListForSelect: any = {};

            Object.values(languages.languages).map((value: any) => {
                return languageListForSelect[value['code']] = value['name'];
            });

            setSelectLanguageList(languageListForSelect);
            setIsLoading(false);
        };

        if (!contentUuid && serviceType === 'E_LEARNING_CONTENT') {
            loadServices();
        }

        loadLanguages();
        loadOrganizations();
        loadCustomers();
        loadContentCategories();
        loadContentStatus();

        if (contentUuid) {
            loadContent();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl]);

    const submitForm = async (values: any) => {
        let fileSize = 0;

        if (values.titlePicture) {
            fileSize = values.titlePicture.file.size / 1024 / 1024;
        }

        let tempLanguage;

        if (selectedLanguage) {
            tempLanguage = selectedLanguage;
        } else if (!selectedLanguage) {
            tempLanguage = templateLanguages ? templateLanguages[0] : selectedLanguageList[0];
        }

        let supportedOrganizations;
        let supportedCustomers;

        if (values.organizations) {
            supportedOrganizations = values.organizations.join();
        }

        if (values.customers) {
            supportedCustomers = values.customers.join();
        }

        if (supportedOrganizations === "") {
            supportedOrganizations = null;
        }

        if (supportedCustomers === "") {
            supportedCustomers = null;
        }

        let contentName;
        let contentProductDescription;
        let contentDescription;
        let contentPriceComment;


        if (!contentUuid) {
            let tempName = translationsNameArray;
            let tempProductDescription = translationsProductDescriptionArray;
            let tempDescription = translationsContentDescriptionArray;
            let tempPriceComment = translationsPriceCommentArray;

            tempName[tempLanguage] = values.name;

            const prodDesc = methods.getEditorContent(values.productDescription);
            const contentDesc = methods.getEditorContent(values.contentDescription);
            const priceComm = methods.getEditorContent(values.priceComment);

            tempProductDescription[tempLanguage] = prodDesc;
            tempDescription[tempLanguage] = contentDesc;
            tempPriceComment[tempLanguage] = priceComm;

            contentName = tempName;
            contentProductDescription = tempProductDescription;
            contentDescription = tempDescription;
            contentPriceComment = tempPriceComment;

        } else {
            contentName = values.name;

            contentProductDescription = methods.getEditorContent(values.productDescription);
            contentDescription = methods.getEditorContent(values.contentDescription);
            contentPriceComment = methods.getEditorContent(values.priceComment);
        }

        const parsedAppearanceSettings: { [key: string]: any } = {
            // settings
            categories: typeof values.categories === 'string' ? values.categories : values.categories.join(),
            priority: values.priority,
            imgPath: values.titlePicture ? values.titlePicture.file : null,
            supportedOrganizations: supportedOrganizations ? supportedOrganizations : null,
            supportedCustomers: supportedCustomers ? supportedCustomers : null,
            status: values.status,
            price: values.price,
            validFrom: moment(values.validFrom).format('YYYY-MM-DD'),
            validTo: moment(values.validTo).format('YYYY-MM-DD'),
            serviceType: serviceType ? serviceType : loadedServiceType,

            // translations
            language: tempLanguage,
            name: contentName,
            productDescription: contentProductDescription,
            contentDescription: contentDescription,
            priceComment: contentPriceComment,
        };

        if ('E_LEARNING_CONTENT' === serviceType || 'E_LEARNING_CONTENT' === loadedServiceType) {
            parsedAppearanceSettings.serviceId = parseInt(values.service);
        }

        const formData = new FormData();

        Object.keys(parsedAppearanceSettings).forEach((key) => {
            const value = parsedAppearanceSettings[key];

            if (value !== null) {

                formData.append(key, key === 'name' || key === 'productDescription' || key === 'contentDescription' || key === 'priceComment' ? JSON.stringify(value) : value);

            }
        });

        if (fileList.length > 0) {
            let i = 0;
            fileList[fileList.length - 1].forEach((image: any) => {
                formData.append('contentImages' + i, image.originFileObj);
                fileSize = fileSize + image.originFileObj.size / 1024 / 1024;
                i = i + 1;
            });
        }

        if (fileSize > 8) {
            return message.error(intl.formatMessage({id: "error.images_size_to_large"}));
        }

        try {
            setIsLoading(true);
            if (contentUuid) {
                await apiClient.request(`/api/v1/content/${contentUuid}`, formData, 'POST', true, true, {}, 'json');
                await languageChange(tempLanguage);
            } else {
                await apiClient.request('/api/v1/content', formData, 'POST', true, true, {}, 'json');
            }

            navigate('/content');
            setIsLoading(false);
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({id: "error.server_error"}));
            setIsLoading(false);
        }
    };

    const disabledDates = (current: moment.Moment, to: boolean = false) => {
        const validDate = to ? validFromDate : validToDate;

        if (validDate === null) {
            return current < moment().subtract(1, 'day');
        }

        return (
            (to ? validDate > current : current > validDate) ||
            validDate.isSame(current, 'day') ||
            current < moment().subtract(1, 'day')
        );
    };

    const showDeleteConfirm = (path: string, imgId: string, type: 'title' | 'content') => {
        const deleteImage = async () => {
            let parsedValues = {
                contentUuid: contentUuid,
                imageType: type,
                imagePath: path
            };

            try {
                await apiClient.request('/api/v1/content/delete-image', parsedValues, 'POST');

                switch (type) {
                    case 'title':
                        setPreviewFile({});
                        break;
                    case 'content':
                        setContentImages(contentImages.filter((el: any) => el.imgId !== imgId));
                        break;
                }

                message.success(`${intl.formatMessage({id: `success.image_deleted`})}!`);

            } catch (error) {
                console.error(error);
                message.error(intl.formatMessage({id: 'error.data_load'}));
            }
        };

        confirm({
            title: intl.formatMessage({id: `licensing.content.delete_image`}),
            icon: <ExclamationCircleOutlined/>,
            content: intl.formatMessage({id: `licensing.content.delete_image_text`}),
            okText: intl.formatMessage({id: 'general.yes'}),
            cancelText: intl.formatMessage({id: 'general.no'}),
            onOk: deleteImage,
        });
    };

    const popoverContent = (
        <Spin spinning={!!!previewFile}>
            {previewFile &&
              <div style={{padding: 16, width: 300}}>
                <img
                  alt=""
                  src={previewFile.imgBlob ? previewFile.imgBlob : undefined}
                  style={{width: '100%', display: 'block', margin: '0 auto'}}
                />
              </div>
            }
        </Spin>
    );

    const popoverContentMultipleImages = (img: string) => (
        <Spin spinning={!!!img}>
            <div style={{padding: 16, width: 300}}>
                <img
                    alt=""
                    src={img}
                    style={{width: '100%', display: 'block', margin: '0 auto'}}
                />
            </div>
        </Spin>
    );

    const languageChange = async (language: string) => {
        const values = form.getFieldsValue();

        if (!contentUuid) {
            let tempLanguage: string;

            if (selectedLanguage) {
                tempLanguage = selectedLanguage;
            } else if (!selectedLanguage) {
                tempLanguage = selectedLanguageList[0];
            }

            const prodDesc = methods.getEditorContent(values.productDescription);
            const contentDesc = methods.getEditorContent(values.contentDescription);
            const priceComm = methods.getEditorContent(values.priceComment);

            setTranslationsNameArray((translationsNameArray: any) => ({
                ...translationsNameArray,
                [tempLanguage]: values.name
            }));
            setTranslationsProductDescriptionArray((translationsProductDescriptionArray: any) => ({
                ...translationsProductDescriptionArray,
                [tempLanguage]: prodDesc
            }));
            setTranslationsContentDescriptionArray((translationsContentDescriptionArray: any) => ({
                ...translationsContentDescriptionArray,
                [tempLanguage]: contentDesc
            }));
            setTranslationsPriceCommentArray((translationsPriceCommentArray: any) => ({
                ...translationsPriceCommentArray,
                [tempLanguage]: priceComm
            }));

            form.setFieldsValue({
                name: translationsNameArray[language] ? translationsNameArray[language] : [],
                productDescription: translationsProductDescriptionArray[language] ? translationsProductDescriptionArray[language] : '',
                contentDescription: translationsContentDescriptionArray[language] ? translationsContentDescriptionArray[language] : '',
                priceComment: translationsPriceCommentArray[language] ? translationsPriceCommentArray[language] : ''
            });
        }

        if (contentUuid) {
            setLanguageLoading(true);
            const prodDesc = methods.getEditorContent(values.productDescription);
            const contentDesc = methods.getEditorContent(values.contentDescription);
            const priceComm = methods.getEditorContent(values.priceComment);

            if (
                name !== values.name ||
                productDescription !== prodDesc ||
                contentDescription !== contentDesc ||
                priceComment !== priceComm
            ) {
                const parsedValues = {
                    name: values.name,
                    productDescription: prodDesc,
                    contentDescription: contentDesc,
                    priceComment: priceComm,
                    language: selectedLanguage
                };

                apiClient.request(`/api/v1/content/${contentUuid}/language`, parsedValues, 'PUT');

                setTemplateLanguages((templateLanguages: any) => [...templateLanguages, selectedLanguage]);
            }

            loadContentTranslations(language);
        }

        setSelectedLanguage(language);
    };

    const loadContentTranslations = async (language: string) => {
        if (templateLanguages.includes(language)) {
            setIsTranslationLoading(true);
            const response = await apiClient.request(`/api/v1/content/${contentUuid}/settings/${language}`, [], 'GET');
            setIsTranslationLoading(false);
            form.setFieldsValue({
                name: response.language.name,
                productDescription: response.language.productDescription,
                contentDescription: response.language.contentDescription,
                priceComment: response.language.priceComment
            });

            setName(response.language.name);

            setProductDescription(response.language.productDescription);
            setContentDescription(response.language.contentDescription);
            setPriceComment(response.language.priceComment);

            setInitialProductDescription(response.language.productDescription);
            setInitialContentDescription(response.language.contentDescription);
            setInitialPriceComment(response.language.priceComment);
        } else {
            form.setFieldsValue({
                name: '',
                productDescription: '',
                contentDescription: '',
                priceComment: ''
            });

            setName('');
            setInitialProductDescription('');
            setInitialContentDescription('');
            setInitialPriceComment('');

        }
        setLanguageLoading(false);
    };

    const deleteContentLanguage = (language: string) => {

        const deleteLanguage = async () => {

            if (contentUuid) {
                try {
                    await apiClient.request(`/api/v1/content/${contentUuid}/language/${language}/delete`, [], 'DELETE');
                } catch (error) {
                    console.error(error);
                    message.error(intl.formatMessage({id: 'error.data_load'}));
                }

                setTemplateLanguages(templateLanguages.filter((el: any) => el !== language));
                setSelectedLanguageList(selectedLanguageList.filter((el: any) => el !== language));
            } else {
                let deleteKey = language;
                delete translationsNameArray[deleteKey];
                delete translationsProductDescriptionArray[deleteKey];
                delete translationsContentDescriptionArray[deleteKey];
                delete translationsPriceCommentArray[deleteKey];

                setSelectedLanguageList(selectedLanguageList.filter((el: any) => el !== deleteKey));
            }
        };

        confirm({
            title: intl.formatMessage({id: 'licensing.content.language_delete'}),
            icon: <ExclamationCircleOutlined/>,
            content: intl.formatMessage({id: 'licensing.content.language_delete_text'}),
            okText: intl.formatMessage({id: 'general.yes'}),
            cancelText: intl.formatMessage({id: 'general.no'}),
            onOk: deleteLanguage,
        });
    };

    const handleChange = (value: any) => {
        let file = value.fileList;
        setFileList([...fileList, file]);
    };

    return (
        <DefaultLayout.PageLayout>
            <DefaultLayout.PageHeader
                breadcrumb={[{
                    name: name ? name : intl.formatMessage({id: 'licensing.content.content'}),
                    path: contentUuid ? `/content/${contentUuid}/view` : '/content'
                }]}
                title={intl.formatMessage({id: 'licensing.content.add_content'})}
            />
            <Spinner spinning={isLoading}>
                <DefaultLayout.PageContent withTopPadding>
                    <Form form={form} onFinish={submitForm} {...formLayout}>
                        <RemoveConfirmSelect
                            name='supportedLanguages'
                            label={intl.formatMessage({id: "emailing.supported_languages"})}
                            valueKey='value'
                            labelKey='label'
                            manualOptions={selectLanguageList}
                            showSearch
                            style={{marginRight: 6, marginBottom: 0}}
                            customRules={[{
                                required: true,
                                message: intl.formatMessage({id: "validation.field_required_for_language"})
                            }]}
                            onChange={(e: any) => {
                                setSelectedLanguageList(e);
                            }}
                            selectedOptions={selectedLanguageList}
                            setValueAfterDelete={languageChange}
                            setSelectedOptions={setSelectedLanguageList}
                            removeFunction={deleteContentLanguage}

                            confirmModalOK={intl.formatMessage({id: "general.delete"})}
                            confirmModalTitle={intl.formatMessage({id: "emailing.delete_this_template_language"})}
                            confirmModalCancel={intl.formatMessage({id: "general.cancel"})}
                        />
                        {selectedLanguageList && selectedLanguageList.length > 1 ?
                            <Spin style={{marginBottom: '5px'}} size='small'
                                  spinning={languageLoading} {...formItemLayout}>
                                <Radio.Group className='radio-buttons' {...formItemLayout}>
                                    {
                                        Object.values(selectedLanguageList).map((el: any) => {
                                            return (
                                                <>
                                                    {el ?
                                                        <Radio.Button style={{textTransform: 'uppercase'}} value={el}
                                                                      onClick={() => {
                                                                          languageChange(el);
                                                                      }}
                                                        >
                                                            {el}
                                                        </Radio.Button>
                                                        : null
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </Radio.Group>
                            </Spin>
                            : null
                        }
                        <Select
                            name='categories'
                            label={intl.formatMessage({id: 'licensing.content.categories'})}
                            manualOptions={categories}
                            customRules={[{
                                required: true,
                                message: intl.formatMessage({id: "licensing.content.categories_required"})
                            }]}
                            mode="multiple"
                        />
                        <InputNumber
                            name='priority'
                            label={
                                <span>
                                  {intl.formatMessage({id: 'licensing.content.content_priority'})}
                                    <Tooltip
                                        title={intl.formatMessage({id: 'licensing.content.content_priority_hint'})}>
                                    <i className='fal fa-question-circle header-item'/>
                                  </Tooltip>
                                </span>
                            }
                            customRules={[{
                                required: true,
                                pattern: /^[1-9]\d*$/,
                                message: intl.formatMessage({id: "validation.must_be_integer"})
                            }]}
                            min={1}
                            max={1000}
                        />
                        <Form.Item
                            required
                            name='titlePicture'
                            label={intl.formatMessage({id: 'licensing.content.title_picture'})}
                        >
                            <Upload beforeUpload={() => false}>
                                <Button icon={<UploadOutlined/>}>
                                    <FormattedMessage id="general.upload_no_colon"/>
                                </Button>
                            </Upload>
                        </Form.Item>
                        {(previewFile) && (
                            <Form.Item label={intl.formatMessage({id: 'general.image'})}>
                                <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
                                    <Popover
                                        placement="top"
                                        content={popoverContent}
                                    >
                                        <Button style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: 'calc(100% - 38px)'
                                        }}>
                                            <span style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                                                {previewFile.imgName}
                                            </span>
                                            <EyeOutlined style={{lineHeight: 0}}/>
                                        </Button>
                                    </Popover>
                                    <Button
                                        onClick={() => showDeleteConfirm(previewFile.imgPath, previewFile.imgId, 'title')}
                                        style={{marginLeft: 6}}
                                    >
                                        <DeleteOutlined/>
                                    </Button>
                                </div>
                            </Form.Item>
                        )}
                        <Input
                            name='name'
                            label={intl.formatMessage({id: 'licensing.content.content_name'})}
                            validation={{required: true}}
                        />
                        <Select
                            name='organizations'
                            label={intl.formatMessage({id: 'licensing.content.supported_organizations'})}
                            manualOptions={organizations}
                            loading={organizationsLoading}
                            mode='multiple'
                        />
                        <Select
                            name='customers'
                            label={intl.formatMessage({id: 'licensing.content.supported_customers'})}
                            manualOptions={customers}
                            loading={customersLoading}
                            mode='multiple'
                        />
                        <Select
                            name='status'
                            label={intl.formatMessage({id: 'licensing.content.status'})}
                            manualOptions={contentStatus}
                            loading={loadContentStatus}
                            customRules={[{
                                required: true,
                                message: intl.formatMessage({id: "licensing.content.status_required"})
                            }]}
                        />
                        <Input
                            name='price'
                            label={intl.formatMessage({id: 'licensing.content.price'})}
                            validation={{required: true}}
                        />
                        {serviceType === 'E_LEARNING_CONTENT' || loadedServiceType === 'E_LEARNING_CONTENT' ?
                            <Select
                                name='service'
                                label={intl.formatMessage({id: 'licensing.service'})}
                                loading={loadService}
                                manualOptions={service}
                                customRules={[{
                                    required: true,
                                    message: intl.formatMessage({id: "licensing.service.required"})
                                }]}
                            />
                            : null
                        }
                        <Form.Item
                            name='validFrom'
                            label={intl.formatMessage({id: 'licensing.valid_from'})}
                            required={true}
                        >
                            <DatePicker
                                format='DD/MM/YYYY'
                                disabledDate={(current: any) => disabledDates(current)}
                                placeholder=''
                                onChange={(value: any) => setValidFromDate(value)}
                            />
                        </Form.Item>
                        <Form.Item
                            name='validTo'
                            label={intl.formatMessage({id: 'licensing.valid_to'})}
                            required={true}
                        >
                            <DatePicker
                                format='DD/MM/YYYY'
                                disabledDate={(current: any) => disabledDates(current, true)}
                                placeholder=''
                                onChange={(value: any) => setValidToDate(value)}
                            />
                        </Form.Item>
                        <ContentEditor
                            name='productDescription'
                            label={intl.formatMessage({id: 'licensing.content.product_description'})}
                            htmlTemplate={initialProductDescription}
                            setHtmlTemplate={setInitialProductDescription}
                            loading={isTranslationLoading}
                        />
                        <ContentEditor
                            name='contentDescription'
                            label={intl.formatMessage({id: 'licensing.content.content_description'})}
                            htmlTemplate={initialContentDescription}
                            setHtmlTemplate={setInitialContentDescription}
                            loading={isTranslationLoading}

                        />
                        <ContentEditor
                            name='priceComment'
                            label={intl.formatMessage({id: 'licensing.content.price_comment'})}
                            htmlTemplate={initialPriceComment}
                            setHtmlTemplate={setInitialPriceComment}
                            loading={isTranslationLoading}
                        />

                        <Form.Item
                            name='contentImages'
                            label={intl.formatMessage({id: 'licensing.content.content_images'})}
                            labelCol={formItemLayout.labelCol}
                            wrapperCol={formItemLayout.wrapperCol}
                            style={{marginBottom: 12}}
                        >
                            <Upload
                                listType="text"
                                beforeUpload={() => false}
                                multiple
                                onChange={handleChange}
                            >
                                <Button style={{marginBottom: 25}} icon={<UploadOutlined/>}>
                                    <FormattedMessage id="general.upload_no_colon"/>
                                </Button>
                            </Upload>
                            {contentImages &&
                                contentImages.map((img: any) => {
                                    return (
                                        <Form.Item>
                                            <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
                                                <Popover
                                                    placement="top"
                                                    content={popoverContentMultipleImages(img.imgBlob)}
                                                >
                                                    <Button style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: 'calc(100% - 38px)'
                                                    }}>
                                                    <span style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                                                      {img.imgName}
                                                    </span>
                                                        <EyeOutlined style={{lineHeight: 0}}/>
                                                    </Button>
                                                </Popover>
                                                <Button
                                                    onClick={() => showDeleteConfirm(img.imgPath, img.imgId, 'content')}
                                                    style={{marginLeft: 6}}
                                                >
                                                    <DeleteOutlined/>
                                                </Button>
                                            </div>
                                        </Form.Item>
                                    );
                                })
                            }
                        </Form.Item>
                        <br/>
                    </Form>
                </DefaultLayout.PageContent>
                <DefaultLayout.PageFooter right={
                    <div className='form-buttons'>
                        <NavLink to='/content'>
                            <Button type='outline' style={{marginRight: 8}}>
                                <FormattedMessage id='general.back'/>
                            </Button>
                        </NavLink>
                        <Button
                            type='primary'
                            onClick={() => form.submit()}
                            loading={isLoading}
                        >
                            <FormattedMessage id='general.submit'/>
                        </Button>
                    </div>
                }/>
            </Spinner>
        </DefaultLayout.PageLayout>
    );
};

export default connect(mapStateToProps)(ContentForm);
