export enum ResourceType {
    Device = "DEVICE",
    Location =  "LOCATION",
    License =  "LICENSE",
    Other =  "OTHER",
}

export interface ResourceProps {
    id?: number,
    title?: string,
    parent_id?: number,
    collor?: boolean,
    children?: [],
    resourceType?: string,
}