import {useState} from 'react'
import useHandleError from 'utils/useHandleError';
import apiClient from 'utils/apiClient';
import generalHelpers from "utils/generalHelpers";

export const useCompetences = () => {
    const [handleError] = useHandleError();
    const [loading, setLoading] = useState<boolean>(false)

    const searchCompetence = async (q: string) => {
        setLoading(true)
        try {
            let requestUrl: string = '/api/v1/competences/options/list'

            requestUrl = generalHelpers.urlBuilder(requestUrl, q, 'q')

            const response = await apiClient.request(requestUrl, [], 'GET');

            if (response) {
                if (response?.competences) {
                    return response.competences;
                } else {
                    return response;
                }
            }
        } catch (error) {
            handleError(error);
        } finally {
            setLoading(false)
        }
    }

    const searchCompetenceBlock = async (q: string) => {
        setLoading(true)
        try {
            let requestUrl: string = '/api/v1/competences/options/block-list'

            requestUrl = generalHelpers.urlBuilder(requestUrl, q, 'q')

            const response = await apiClient.request(requestUrl, [], 'GET');

            if (response) {
                if (response?.competences) {
                    return response.competences;
                } else {
                    return response;
                }
            }
        } catch (error) {
            handleError(error);
        } finally {
            setLoading(false)
        }
    }


    return {
        loading,
        searchCompetence,
        searchCompetenceBlock
    }
}